import { useMemo } from 'react';

import { useTheme } from '../../../../../context/ThemeProvider';

import Calendar from '../CommonComponents/Calendar';
import Cell from '../CommonComponents/Cell';
import Header from './Components/Header';

import { calendarTypes } from '../../../../../constants';

import styles from './MonthsCalendar.module.css';

const MonthsCalendar = ({
  valueInformation,
  minValue,
  maxValue,
  calendar,
  setCalendar,
  months,
  setValue
}) => {

  const isTheme = useTheme();

  const table = useMemo(() => {
    const table = [];

    for (let i = 0; i < 12; i+=3) {
      table.push([]);

      for (let j = 0; j < 3; j++) {
        const d = new Date(valueInformation.year, i+j);

        table[table.length - 1].push({
          number: i+j,
          active: d.valueOf() >= minValue.valueOf() && d.valueOf() <= maxValue.valueOf(),
          current: i+j === valueInformation.month
        });
      }
    }

    return table;
  }, [valueInformation, minValue, maxValue]);

  const setMonthHandler = (month) => {
    const { year, day } = valueInformation;

    setValue(new Date(year, month, day));
    setCalendar(calendarTypes.DAYS);
  };

  return (
    <Calendar active={ calendar === calendarTypes.MONTHS }>
      <Header
        valueInformation={ valueInformation }
        minValue={ minValue }
        maxValue={ maxValue }
        setValue={ setValue }
      />
      <table className={ styles.table }>
        <tbody>
          { table.map((row, index) => {
            return (
              <tr key={ index }>
                { row.map((column, index) => {
                  const props = {
                    key: index,
                    className: styles.td,
                    disabled: !column.active,
                    onClick: column.active ? () => setMonthHandler(column.number) : undefined,
                    styles: isTheme.styles
                  };

                  if (column.current) {
                    props.active = true;
                  }

                  return (
                    <Cell { ...props }>
                      { months[column.number] }
                    </Cell>
                  );
                }) }
              </tr>
            );
          }) }
        </tbody>
      </table>
    </Calendar>
  );
};

export default MonthsCalendar;