import React, { useReducer, useState } from 'react';

import { UiIcon } from '@compassplus/ui-mobicash';
import { observer } from 'mobx-react-lite';
import i18n from '../../../i18n';

import {
  ImageCover,
} from '../../TemplateStyle';
import {
  ImageCard, ImageSubsection,
} from '../ImageUpload/styles';
import { MainImageText } from './style';
import ItemStore from '../../../stores/ItemStore';
import LinkToColor from './LinkToColor';
import MiniImageCards from './MiniImageCards';

const ImageUpload = observer(() => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const { images } = ItemStore;
  const setImages = (value) => { ItemStore.images = value; };
  const [selectedImage, setSelectedImage] = useState(0);

  const onSetMain = (index) => {
    const prevImages = images;

    prevImages.forEach((el, elIndex) => {
      el.isMain = Boolean(Number(elIndex) === Number(index));
    });
    setImages(prevImages);

    forceUpdate();
  };

  const renderImageCard = () => (
    <>
      {(images && images.length > 0 && images[selectedImage]) ? (
        <ImageCard>
          <LinkToColor images={images} selectedImage={selectedImage} />
          <ImageCover
            src={images[selectedImage].type === 'url' || images[selectedImage].type === 'file' ? images[selectedImage].url : URL.createObjectURL(images[selectedImage].url)}
          />
          {images[selectedImage].isMain ? (
            <MainImageText>
              {i18n.t('Cover')}
              <UiIcon setWidth="1.4rem" setHeight="1rem" view="brand" icon="apply" />
            </MainImageText>
          ) : (
            <MainImageText
              style={{
                cursor: 'pointer', padding: '0.25rem',
              }}
              onClick={() => { onSetMain(selectedImage); }}
            >
              {i18n.t('SetCover')}
            </MainImageText>
          )}
        </ImageCard>
      ) : null}
    </>
  );

  return (
    <ImageSubsection title={i18n.t('Image')}>
      <MiniImageCards images={images} setSelectedImage={setSelectedImage} />
      {renderImageCard()}
    </ImageSubsection>
  );
});

export default ImageUpload;
