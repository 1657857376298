import styled from "styled-components";

export const StyledMarketSearchInput = styled.input`
  border: 1px solid #A3AEBF;
  border-radius: 0.25rem;
  margin: 0.25rem 0;
  width: 100%;
  height: 1.625rem;
  padding: 0.25rem;
  font-family: 'Roboto';
  font-size: 0.75rem;
  
  :focus {
    border: 1px solid #A3AEBF;
    outline: none;
  }
`;

export const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`;