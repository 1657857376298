import React, { useEffect, useReducer, useState } from 'react';
import {
  UiCard, UiInput, UiDots, UiSection, UiSwitch, UiIcon,
} from '@compassplus/ui-mobicash';
import { observer } from 'mobx-react-lite';
import i18n from '../../../../i18n';
import DeliveryStore from '../../../../stores/DeliveryStore';
import ActionSheet from '../../ActionSheet';
import { DeleteSection } from '../../../TemplateStyle';

const PersonalDataFields = observer((props) => {
  const { language } = props;
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [openSelection, setOpenSelection] = useState(new Array(DeliveryStore.form.personalData.length));

  useEffect(() => {
    openSelection.fill(false);
  }, []);

  const onChangeOpenSelection = (index) => {
    if (index !== 0) {
      openSelection.fill(false, 0, index - 1);
      openSelection.fill(false, index + 1);
    } else {
      openSelection.fill(false, 1);
    }

    if (index === 0) {
      setOpenSelection([!openSelection[index], ...openSelection.slice(index + 1, openSelection.length)]);
    } else if (index === openSelection.length) {
      setOpenSelection([...openSelection.slice(0, index - 1), !openSelection[index]]);
    } else {
      setOpenSelection([...openSelection.slice(0, index), !openSelection[index], ...openSelection.slice(index + 1, openSelection.length)]);
    }
  };

  useEffect(() => {
    setOpenSelection(new Array(DeliveryStore.form.personalData.length));
    openSelection.fill(false);
  }, [DeliveryStore.form.personalData.length]);

  const onDelete = (index) => {
    DeliveryStore.form.deletePersonalField(index);
    forceUpdate();
  };

  const getField = (field, index) => (
    <UiCard style={{ margin: '0.25rem 0', position: 'relative' }}>
      <UiInput
        description={i18n.t('Title')}
        placeholder={i18n.t('Title')}
        value={field.title[language]}
        onChange={(event) => { field.setTitle(language, event.target.value); forceUpdate(); }}
        rightElement={<UiDots onClick={() => onChangeOpenSelection(index)} />}
        active={openSelection && openSelection[index]}
        bottomDivider
      />
      <ActionSheet
        open={openSelection[index]}
        setOpen={() => { onChangeOpenSelection(index); }}
      >
        {
          index !== 0 ? (
            <UiSection
              title={i18n.t('RaiseField')}
              onClick={() => {
                onChangeOpenSelection(index);
                DeliveryStore.form.raisePersonalDataPoint(index);
                forceUpdate();
              }}
              view="link"
              bottomDivider
            />
          ) : null
        }
        {
          index !== DeliveryStore.form.personalData.length - 1 ? (
            <UiSection
              title={i18n.t('PutDownField')}
              onClick={() => {
                onChangeOpenSelection(index);
                DeliveryStore.form.putDownPersonalDataPoint(index);
                forceUpdate();
              }}
              view="link"
              bottomDivider
            />
          ) : null
        }
        <DeleteSection
          title={i18n.t('DeleteField')}
          onClick={() => { onChangeOpenSelection(index); onDelete(index); }}
        />
      </ActionSheet>
      <UiSection
        title={i18n.t('Required')}
        rightElement={(
          <UiSwitch
            checked={field.required}
            onClick={() => {
              field.setRequired(!field.required);
              forceUpdate();
            }}
          />
        )}
      />
    </UiCard>
  );

  return (
    <>
      {
        DeliveryStore.form.personalData.map((field, index) => (
          <React.Fragment key={`${field.title.en}-${index}`}>
            {getField(field, index)}
          </React.Fragment>
        ))
      }
      <UiCard style={{ margin: '0.25rem 0' }}>
        <UiSection
          leftElement={<UiIcon setColor="#037AFF" icon="legacy-plus" />}
          title={i18n.t('AddField')}
          view="link"
          onClick={() => {
            DeliveryStore.form.addPersonalField();
            forceUpdate();
          }}
        />
      </UiCard>
    </>
  );
});

export default PersonalDataFields;
