import PropTypes from 'prop-types';
import cn from 'classnames';
import styled from 'styled-components';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

import styles from './UiFavorite.module.css';

const StyledElement = styled(Element)`
  filter: drop-shadow(${ (props) => props.styles.shadows.fav });
`;

/**
 * Element component
 * @public
 * @version 0.0.90
 * @param {String} className
 * @param {Boolean} [active=false]
 * @param {Function} onClick
 * @param {Boolean} [propagation=true]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiFavorite />
 */
const UiFavorite = ({
  className,
  active,
  onClick,
  propagation,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <StyledElement
      { ...props }
      className={ cn(styles.favorite, className) }
      propagation={ propagation }
      onClick={ onClick }
      innerRef={ innerRef }
      width="1rem"
      height="1rem"
      viewBox="0 0 16 16"
      styles={ isTheme.styles }
    >
      <path
        d="M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8Z"
        fill={ active ? isTheme.styles.colors.favoriteActiveBackground : isTheme.styles.colors.favoriteNotActiveBackground }
      />
      <path
        d="M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8Z"
        fill="white" fillOpacity="0.1"
      />
      <path
        d="M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8Z"
        stroke={ active ? isTheme.styles.colors.favoriteActiveBorderBackground : isTheme.styles.colors.favoriteNotActiveBorderBackground }
      />
      <path
        fillRule="evenodd" clipRule="evenodd"
        d="M9.76253 5.33986L8.00204 2.5L6.23849 5.3379C6.15782 5.46867 6.02404 5.56138 5.86883 5.59456L2.5 6.31673L4.77822 8.79648C4.8834 8.90968 4.93344 9.05997 4.91914 9.21123L4.59645 12.4971L7.76922 11.1913C7.77943 11.1874 7.78862 11.1835 7.79985 11.1796C7.93771 11.1328 8.08986 11.1357 8.22466 11.1913L11.3964 12.5L11.0768 9.21416C11.0615 9.0629 11.1135 8.91261 11.2187 8.7994L13.5 6.32258L10.1302 5.59652C9.97596 5.56334 9.84218 5.47063 9.76253 5.33986"
        fill={ active ? isTheme.styles.colors.favoriteActiveStar : isTheme.styles.colors.favoriteNotActiveStar }
      />
    </StyledElement>
  );
};

UiFavorite.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Сделать карту выбранной */
  active: PropTypes.bool,
  /** Обработчик события при клике */
  onClick: PropTypes.func,
  /** Разрешить/запретить всплытие обработчика onClick */
  propagation: PropTypes.bool,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

UiFavorite.defaultProps = {
  active: false,
  disabled: false,
  propagation: true
};

export default UiFavorite;