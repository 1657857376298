import React from 'react';
import {CartSection} from "../../../pages/market/ShoppingCartPage/style";
import ShoppingCartStore from "../../../stores/ShoppingCartStore";
import {UiCard, UiIcon, UiInput, useOptions} from "@compassplus/ui-mobicash";
import i18n from "../../../i18n";
import {getCurrency} from "../../../Utils/requestUtil";
import ItemCount from "../../../pages/market/ShoppingCartPage/components/ItemCount";
import {AnimationCard} from "../../TemplateStyle";

const ShoppingCartItem = ({ product, index, onImageClick, onDelete, style, shadow, disabled }) => {
  const isOptions = useOptions();

  if (product) {
    return (
      <AnimationCard
        style={style ? style :{ margin: '0.25rem 0', boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.13)' }}
        id={index}
        shadow={shadow}
      >
        <CartSection
          type="marketplace-card"
          leftElement={
            <div style={(product.amount === 0 && product.totalAmount === 0 && !product.isInfiniteAmount) || !product.availability ? { width: '70px', height: '70px', opacity: '0.6' } : { width: '70px', height: '70px' }}>
              <img
                src={product.defaultImg?.url}
                style={{
                  width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer',
                }}
                onClick={onImageClick}
              />
            </div>
          }
          title={product.title[isOptions.language]}
          description={ShoppingCartStore.getOrderDescription(product.attributes, isOptions.language)}
          rightElement={onDelete ? <UiIcon icon="delete" setColor="#037AFF" onClick={onDelete} /> : null}
          order={(product.amount === 0 && product.totalAmount === 0 && !product.isInfiniteAmount) || !product.availability ? `${i18n.t('OUTOFSTOCK')}` : `${(Number(product.price) * (product.amount)).toFixed(2)} ${i18n.t(getCurrency())}`}
          bottomDivider
        />
        {
          (product.amount === 0 && product.totalAmount === 0 && !product.isInfiniteAmount) || !product.availability ? (
            <UiInput
              required
              description={i18n.t('Count')}
              placeholder={i18n.t('ProductIsOutOfStock')}
              readOnly
            />
          ) : (
            <UiInput
              required
              description={i18n.t('Count')}
              placeholder={`${product.amount} x ${Number(product.price).toFixed(2)}`}
              rightElement={!disabled ? (<ItemCount item={product} />) : null}
              readOnly
            />
          )
        }
      </AnimationCard>
    )
  }
  return null;
}

export default ShoppingCartItem;