import React from 'react';
import { ImagesContainer, Slide, Wrapper } from "../market/OrderImages/style";
import { LoadingImg } from "./style";

const LoadingImagesCarousel = () => {
  const images = [1, 2, 3];
  return (
    <ImagesContainer margin={ '0.25rem 0' }>
      <Wrapper>
        <Slide>
          <LoadingImg />
        </Slide>
      </Wrapper>
      <div style={{
        margin: '0.75rem 0', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center',
      }}
      >
        {
          images.length > 1 && images.map((index) => (
            <div key={index} style={{ width: '4.375rem', height: '4.375rem', margin: '0 0.25rem' }}>
              <LoadingImg />
            </div>
          ))
        }
      </div>
      <div>
        {
          images.map((index) => (
            <input
              key={index}
              checked={index === 1}
              readOnly
              className="image-button"
              style={{ margin: '0.5rem 0.25rem 0' }}
              name="img"
              type="radio"
              id={index}
            />
          ))
        }
      </div>
    </ImagesContainer>
  );
}

export default LoadingImagesCarousel;