import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../../context/ThemeProvider';

import styles from './FSubstrate.module.css';

export const StyledSubstrate = styled.div`
  ${ (props) => css`
    background: ${ props.styles.colors.sectionBackground };
    box-shadow: ${ props.styles.shadows.action };
  ` }

  ${ (props) => {
    if (props.disabled) {
      return css`
        opacity: 0.2;
      `;
    } else {
      return css`
        cursor: pointer;
      `;
    }
  } }
  
  ${ (props) => props.active && css`
    &:after {
      background: ${ props.styles.colors.textTitle };
    }
  ` }
`;

const FSubstrate = ({
  className,
  disabled,
  onClick,
  children,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  const [active, setActive] = useState(false);

  const enableActiveState = () => {
    setActive(true);
  };

  const disableActiveState = () => {
    setActive(false);
  };

  const substrateProps = useMemo(() => {
    if (!disabled) {
      return {
        active,
        onClick,
        onMouseDown: enableActiveState,
        onMouseUp: disableActiveState,
        onTouchStart: enableActiveState,
        onTouchEnd: disableActiveState,
      };
    } else {
      return {};
    }
  }, [disabled, active, onClick]);

  return (
    <StyledSubstrate
      { ...props }
      { ...substrateProps }
      className={ cn(styles.substrate, className ) }
      disabled={ disabled }
      styles={ isTheme.styles }
      ref={ innerRef }
    >
      { children }
    </StyledSubstrate>
  );
};

FSubstrate.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};

FSubstrate.defaultProps = {
  disabled: false
};

export default FSubstrate