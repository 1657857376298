import PropTypes from 'prop-types';
import cn from 'classnames';
import styled from 'styled-components';

import { useTheme } from '../../../context/ThemeProvider';

import styles from './UiSwitch.module.css';

const StyledSpan = styled.span`
  background-color: ${ (props) => props.styles.colors.textDisable };

  &::before {
    background-color: ${ (props) => props.styles.colors.sectionBackground };
    box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.1), 0 0.125rem 0.125rem rgba(0, 0, 0, 0.05);
  }

  input:disabled + & {
    background-color: ${ (props) => props.styles.colors.sectionBackground };
    border: 0.031rem solid ${ (props) => props.styles.colors.textDisable };
    cursor: default;
  }

  input:disabled + &::before {
    background-color: ${ (props) => props.styles.colors.applicationBackground };
    border: 0.031rem solid rgba(0, 0, 0, 0.1);
    cursor: default;
  }

  input:checked + & {
    background-color: ${ (props) => props.styles.colors.applicationPrimaryColor };
  }

  input:checked + & {
    background-color: ${ (props) => props.styles.colors.applicationPrimaryColor };
  }

  input:checked:disabled + & {
    background-color: ${ (props) => props.styles.colors.sectionBackground };
    cursor: default;
  }

  input:checked:disabled + &::before {
    background-color: ${ (props) => props.styles.colors.applicationPrimaryColor_zero_point_five };
    border: 0.031rem solid rgba(0, 0, 0, 0.2);
    cursor: default;
  }
`;

/**
 * Switch component
 * @public
 * @version 0.0.90
 * @param {String} className
 * @param {String} name
 * @param {Boolean} [checked=false]
 * @param {Boolean} [defaultChecked=false]
 * @param {Boolean} [disabled=false]
 * @param {Boolean} [required=false]
 * @param {Function} onClick
 * @param {Function} onChange
 * @param {Function} onFocus
 * @param {Function} onBlur
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiSwitch />
 */
const UiSwitch = ({
  className,
  name,
  checked,
  defaultChecked,
  disabled,
  required,
  onClick,
  onChange,
  onFocus,
  onBlur,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <label className={ styles.container }>
      <input
        { ...props }
        type="checkbox"
        className={ styles.switch }
        name={ name }
        checked={ defaultChecked ? undefined : checked }
        defaultChecked={ undefined }
        disabled={ disabled }
        required={ required }
        onChange={ onChange }
        onFocus={ onFocus }
        onBlur={ onBlur }
        onClick={ onClick }
        ref={ innerRef }
      />
      <StyledSpan className={ cn(styles.button, className) } styles={ isTheme.styles } />
    </label>
  );
};

UiSwitch.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Уникальное имя элемента формы */
  name: PropTypes.string,
  /** Определяет, помечен ли заранее чекбокс */
  checked: PropTypes.bool,
  /** Делает чекбокс неуправляемым компонентом */
  defaultChecked: PropTypes.bool,
  /** Делает чекбокс неактивным */
  disabled: PropTypes.bool,
  /** Чекбокс обязателен для выделения */
  required: PropTypes.bool,
  /** Слушатель на клик по чекбоксу */
  onClick: PropTypes.func,
  /** Слушатель при смене состояния чекбокса */
  onChange: PropTypes.func,
  /** Слушатель по фокусу */
  onFocus: PropTypes.func,
  /** Слушатель по потере фокуса */
  onBlur: PropTypes.func,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

UiSwitch.defaultProps = {
  checked: false,
  defaultChecked: false,
  disabled: false,
  required: false,
  onChange: () => {}
};

export default UiSwitch;