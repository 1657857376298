import React, { useEffect, useRef, useState } from 'react';
import { UiIcon } from '@compassplus/ui-mobicash';
import { observer } from 'mobx-react-lite';
import {
  ImagesContainer, MiniImageIcon, Wrapper, Slide,
} from './style';
import ModalSlider from './ModalSlider';
import MarketStore from '../../../stores/MarketStore';

const Images = observer(({ isActive, images }) => {
  const { selectedImageIndex } = MarketStore;
  const [modalOpen, setModalOpen] = useState(false);

  const slideRef = useRef();
  const [xPosition, setXPosition] = useState(0);

  const imageWidth = 288;

  useEffect(() => {
    if (selectedImageIndex <= images.length - 1) {
      setXPosition(selectedImageIndex * -imageWidth);
    } else {
      setXPosition(0);
    }
  }, [selectedImageIndex]);

  const onImageClick = (index) => {
    MarketStore.selectedImageIndex = index;
    setXPosition((index) * -imageWidth);
  };

  const iconsCount = 3;

  const renderMiniImageIcons = (img, index) => {
    if (images.length === iconsCount
      || (selectedImageIndex === 0 && index < iconsCount)
      || (selectedImageIndex !== 0 && index >= selectedImageIndex - 1 && index <= selectedImageIndex + 1)
      || (selectedImageIndex === images.length - 1 && index >= selectedImageIndex - 2)
    ) {
      return (
        <div style={{ width: '4.375rem', height: '4.375rem', margin: '0 0.25rem' }}>
          <MiniImageIcon
            src={img.url}
            style={index === selectedImageIndex ? { border: '1px solid #BE1E24' } : {}}
            alt="..."
            key={index}
            onClick={() => { onImageClick(index); }}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <ImagesContainer style={!isActive ? { opacity: '0.6' } : {}}>
      <Wrapper style={!isActive ? { opacity: '0.6' } : {}}>
        <Slide xPosition={xPosition} ref={slideRef}>
          {images.map((img, i) => (
            <div key={img.url} style={{ position: 'relative' }}>
              <img src={img.url} alt="..." key={i} />
              <div style={{
                position: 'absolute', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', top: 0,
              }}
              >
                <UiIcon icon="find" setColor="#FFF" onClick={() => { setModalOpen(true); }} />
              </div>
            </div>
          ))}
        </Slide>
      </Wrapper>
      <div style={{
        margin: '0.75rem 0', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center',
      }}
      >
        {
          images.length > 1 && images.map((img, index) => (
            <>
              {renderMiniImageIcons(img, index)}
            </>
          ))
      }
      </div>
      <div>
        {
          images.length > 1 && images.map((img, index) => (
            <input
              key={img.url}
              checked={index === selectedImageIndex}
              className="image-button"
              style={{ margin: '0.5rem 0.25rem 0', cursor: 'pointer' }}
              name="img"
              type="radio"
              id={index}
              onClick={() => {
                onImageClick(index);
              }}
            />
          ))
        }
      </div>
      <ModalSlider isActive={isActive} images={images} index={selectedImageIndex} open={modalOpen} setOpen={setModalOpen} />
    </ImagesContainer>
  );
});

export default Images;
