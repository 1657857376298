import { useMemo } from 'react';

import { setRef } from '../utils/reactUtils';

/**
 * Пользовательский хук для объединения двух ссылок одного узла DOM
 * @public
 * @version 1.6.1
 * @param {Array} refs
 * @constructor
 * @example
 * import { useEffect, useRef } from 'react';
 *
 * import { useForkRef } from '@compassplus/ui-mobicash';
 *
 * const App = () => {
 *   const ref1 = useRef(null);
 *   const ref2 = useRef(null);
 *   const refs = useForkRef([ref1, ref2]);
 *
 *   useEffect(() => {
 *     console.log(ref1.current);
 *     console.log(ref2.current);
 *     // Обе ссылки следят за одним и тем же узлом DOM
 *   }, []);
 *
 *   return (
 *     <input ref={ refs } />
 *   );
 * };
 */
export default function useForkRef(refs = []) {
  return useMemo(() => {
    if (refs.length < 1) {
      return null;
    }

    return (refValue) => {
      for (const ref of refs) {
        setRef(ref, refValue);
      }
    };
  }, [refs]);
}