import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Element component
 * @public
 * @version 0.0.90
 * @param {Function} onClick
 * @param {Boolean} [disabled=false]
 * @param {Boolean} [propagation=true]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiInformation />
 */
const UiInformation = ({
  onClick,
  disabled,
  propagation,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      color={ isTheme.styles.colors.textLink }
      disabled={ disabled }
      propagation={ propagation }
      onClick={ onClick }
      innerRef={ innerRef }
      width="1rem"
      height="1rem"
      viewBox="0 0 16 16"
    >
      <path
        d="M8 0C12.4184 0 16 3.58165 16 8C16 12.4184 12.4184 16 8 16C3.58165 16 0 12.4184 0 8C0 3.58165 3.58165 0 8 0ZM8 15.0588C11.8922 15.0588 15.0588 11.8922 15.0588 8C15.0588 4.10776 11.8922 0.941176 8 0.941176C4.10776 0.941176 0.941176 4.10776 0.941176 8C0.941176 11.8922 4.10776 15.0588 8 15.0588ZM7.42485 6.72231C7.42485 6.38913 7.69496 6.11854 8.02861 6.11854C8.36179 6.11854 8.63191 6.38866 8.63191 6.72184L8.63426 11.8668C8.63473 12.2004 8.36414 12.4705 8.03096 12.4705H8.02861C7.69496 12.4705 7.42485 12.2004 7.42485 11.8668V6.72231ZM7.99991 4.83595C7.55755 4.83595 7.19755 4.50748 7.19755 4.06513C7.19755 3.62278 7.55755 3.29431 7.99991 3.29431C8.44226 3.29431 8.80226 3.62278 8.80226 4.06513C8.80226 4.50748 8.44226 4.83595 7.99991 4.83595Z" />
    </Element>
  );
};

UiInformation.propTypes = {
  /** Обработчик события при клике */
  onClick: PropTypes.func,
  /** Компонент неактивен */
  disabled: PropTypes.bool,
  /** Разрешить/запретить всплытие обработчика onClick */
  propagation: PropTypes.bool,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

UiInformation.defaultProps = {
  disabled: false,
  propagation: true
};

export default UiInformation;