import React from 'react';
import {
  UiArrow, UiCard, UiInput, useOptions,
} from '@compassplus/ui-mobicash';
import i18n from '../../../../i18n';
import DeliveryStore from '../../../../stores/DeliveryStore';
import { getCurrency } from '../../../../Utils/requestUtil';

const AttributeCard = ({ attribute, onCardClick }) => {
  const isOptions = useOptions();

  const getPlaceholder = () => {
    let placeholder;
    let i;
    switch (attribute.type) {
      case 'EntryField':
        return i18n.t(attribute.title[isOptions.language]);
      case 'Phone':
        return DeliveryStore.form.phone ? i18n.t('Required') : i18n.t('NotRequired');
      case 'Birthday':
        return DeliveryStore.form.birthday ? i18n.t('Required') : i18n.t('NotRequired');
      case 'PersonalData':
        placeholder = '';
        i = 0;
        for (i; i < DeliveryStore.form.personalData.length - 1; i++) {
          placeholder += `${DeliveryStore.form.personalData[i].title[isOptions.language]}, `;
        }
        placeholder += `${DeliveryStore.form.personalData[i].title[isOptions.language]}`;
        return placeholder;
      case 'ClientAddress':
        placeholder = '';
        i = 0;
        for (i; i < DeliveryStore.form.addressData.fields.length - 1; i++) {
          placeholder += `${DeliveryStore.form.addressData.fields[i].title[isOptions.language]}, `;
        }
        placeholder += `${DeliveryStore.form.addressData.fields[i].title[isOptions.language]}`;
        return placeholder;
      case 'CostOfDelivery':
        placeholder = '';
        i = 0;
        if (DeliveryStore.form.cost.costDependencies.length === 1) {
          placeholder = `${DeliveryStore.form.cost.costDependencies[0].deliveryPrice} `;
          if (placeholder === '0.00 ') {
            placeholder = i18n.t('Free');
          } else if (DeliveryStore.form.cost.costDependencies[0].deliveryPriceType === 'value') {
            placeholder += `${i18n.t(getCurrency())}`;
          } else {
            placeholder += '%';
          }
        } else {
          for (i; i < DeliveryStore.form.cost.costDependencies.length - 1; i++) {
            placeholder += `${i18n.t('from')} ${DeliveryStore.form.cost.costDependencies[i].fromOrderPrice} ${i18n.t('to')} ${DeliveryStore.form.cost.costDependencies[i].toOrderPrice}, `;
          }
          placeholder += `${i18n.t('from')} ${DeliveryStore.form.cost.costDependencies[i].fromOrderPrice} ${i18n.t('to')} ${DeliveryStore.form.cost.costDependencies[i].toOrderPrice} `;
        }
        return placeholder;
      case 'DeliveryDate':
        return `${i18n.t(DeliveryStore.form.date.type)} ${i18n.t(DeliveryStore.form.date.subtype)}`;
      default:
        return i18n.t(attribute.title[isOptions.language]);
    }
  };

  return (
    <UiCard
      style={{ margin: '0.25rem 0' }}
      shadow
    >
      <UiInput
        description={i18n.t(attribute.type)}
        placeholder={getPlaceholder()}
        readOnly
        rightElement={<UiArrow />}
        onClick={onCardClick}
      />
    </UiCard>
  );
};

export default AttributeCard;
