import styled from 'styled-components';
import { UiSidebar } from '@compassplus/ui-mobicash';
import { Layout, Menu } from 'antd';

import { ThemeTitle, ThemeText, LoadingTitle } from '../../TemplateStyle';



const { Sider } = Layout;
const { SubMenu } = Menu;

export const CustSidebar = styled(UiSidebar)` 
      & div[class*='BackgroundModal-module__background'] { display: none }
`;