import React from 'react';
import { UiCard, UiCardSection, UiDropdown, UiText } from '@compassplus/ui-mobicash';
import { Text } from '../TemplateStyle';
import i18n from '../../i18n';

import { LoadingTitle } from './style';

const LoadingDeliverySettings = () => (
  <>
    <Text style={{ textTransform: 'uppercase' }} view="readonly" typography="section-description-semibold">{i18n.t('OrderDetails')}</Text>
    <UiCard shadow style={{ margin: '0.5rem 0 0' }}>
      <UiCardSection
        title={<UiText style={{ fontWeight: 600 }} typography="note">{i18n.t('DeliveryMethod')}</UiText>}
        description={<LoadingTitle margin="0.25rem 0 0" />}
        rightElement={<UiDropdown />}
      />
    </UiCard>
  </>
);

export default LoadingDeliverySettings;
