import styled from 'styled-components';
import { UiIcon } from '@compassplus/ui-mobicash';
import { Dialog } from '../../TemplateStyle';

export const MainImageIcon = styled(UiIcon)`
  position: absolute;
  right: 0;
`;

export const MainImageText = styled.div`
  color: ${(props) => props.theme.textReadOnly};
  padding: 0.25rem 0 0.25rem 0.25rem;
  background: #fff;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const LinkToColorSection = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 20px !important;
  background: #fff;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ColumnButtonsDialog = styled(Dialog)`
    & > div > div:last-child > div:last-child {
      flex-direction: column
    }
    & > div > div:last-child > div:last-child > div:first-child {
      border-bottom: 0.063rem solid #EBEBEB;
    }
    & > div > div:last-child > div:nth-child(2) {
      margin-bottom: 0;
    }
`;

export const DialogButton = styled.button`
    width: 100%;
    border: none;
    outline: none;
    background: none;
    flex: 1 1;
    cursor: pointer;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 2rem;
    color: #037AFF;
   
    
    :hover {
      opacity: 0.5;
    }
`;