import React, { useEffect } from 'react';
import { useOptions } from '@compassplus/ui-mobicash';
import { useHistory, useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import { action } from 'mobx';
import { MContentDiv } from '../../../components/TemplateStyle';

import Market from './Market';
import GlobalStateStore from '../../../stores/GlobalStateStore';
import ModalStore from '../../../stores/ModalStore';
import Merchant from '../../../stores/Merchant';
import i18n from '../../../i18n';
import { removeCart } from '../../../Utils/requestUtil';

const MarketPage = observer(() => {
  const isOptions = useOptions();
  const history = useHistory();
  const params = useParams();

  let searchString = window.location.search;
  const hashString = window.location.hash;

  useEffect(action(async () => {
    if (isOptions.screen === 'mobile') {
      GlobalStateStore.sidebarClassName = 'upper';
    } else {
      GlobalStateStore.sidebarClassName = '';
    }
    if (searchString !== '' && Merchant.guid !== '') {
      searchString = searchString.replace('?', '');
      searchString = searchString.split('=');
      if (searchString[0] === 'success') {
        ModalStore.openWithMessage(i18n.t('SuccessPayment'));
        const [cms, orderId] = hashString.split('=');
        if (cms === '#Webasyst') {
          fetch('/api/v1/public/webasyst/pay', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              orderId,
              merchantGuid: Merchant.guid,
            }),
          });
        }
        removeCart(Merchant.guid);
        if (Merchant.successLink && Merchant.successLink !== '') {
          if (Merchant.successLink.includes(window.location.origin)) {
            window.history.replaceState({}, '', Merchant.successLink);
          } else {
            window.location.href = Merchant.successLink;
          }
        } else {
          history.replace(Merchant.marketLink);
        }
      } else {
        ModalStore.openErrorWithMessage(i18n.t('UnexpectedError'));
        history.replace(Merchant.marketLink);
      }
    }
  }), [Merchant.guid]);

  return (
    <>
      <MContentDiv style={isOptions.screen === 'desktop' ? { width: '40.5rem', maxWidth: '40.5rem', padding: '0' } : {}}>
        <Market groupId={params.id} />
      </MContentDiv>
    </>
  );
});

export default MarketPage;
