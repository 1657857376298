import { useEffect, useState } from 'react';

export const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return {
    value,
    setValue,
    onChange: handleChange,
  };
};

export const getLocale = (language, value) => {
  if (!value) {
    return '';
  }

  if (Object.keys(value).includes('en')) {
    switch (language) {
      case 'ru': return value.ru || value.en;
      case 'en': return value.en;
      default: return value.en;
    }
  } else {
    return value;
  }
};

export const CheckIsMobile = () => {
  const [width, setWidth] = useState(document.documentElement.clientWidth);
  const [isMobile, setIsMobile] = useState(width < 664);

  const handleResize = () => {
    setWidth(document.documentElement.clientWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    if (width < 664) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  });

  return isMobile;
};
