import PropTypes from 'prop-types';
import cn from 'classnames';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../../../context/ThemeProvider';

import styles from './TextArea.module.css';

const StyledTextArea = styled.textarea`
  color: ${ (props) => props.styles.colors.textLink };
  ${ (props) => props.styles.typography.SectionValue }
  
  ${ (props) => props.warning && css`
    color: ${ (props) => props.styles.colors.warningColor };
  ` }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${ (props) => props.styles.colors.textDisable };
  }

  :-moz-placeholder {
    color: ${ (props) => props.styles.colors.textDisable };
    opacity: 1;
  }

  ::-moz-placeholder {
    color: ${ (props) => props.styles.colors.textDisable };
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: ${ (props) => props.styles.colors.textDisable };
  }
`;

const StyledText = styled.span`
  color: ${ (props) => props.styles.colors.textReadOnly };
  ${ (props) => props.styles.typography.SectionValue };
`;

const TextArea = ({
  className,
  readOnly,
  disabled,
  warning,
  value,
  onClick,
  onChange,
  onBlur,
  onFocus,
  placeholder,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    readOnly ?
      <StyledText
        className={ cn(styles.textarea, styles.default, onClick && styles.pointer, className) }
        styles={ isTheme.styles }
      >
        { value }
      </StyledText>
      :
      <StyledTextArea
        { ...props }
        className={ cn(styles.textarea, onClick && styles.pointer, className) }
        warning={ warning }
        disabled={ disabled }
        value={ value }
        placeholder={ placeholder }
        onChange={ onChange }
        onBlur={ onBlur }
        onFocus={ onFocus }
        ref={ innerRef }
        styles={ isTheme.styles }
      />
  );
};

TextArea.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  warning: PropTypes.bool,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

export default TextArea;