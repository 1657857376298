import PropTypes from 'prop-types';

import RightDivider from '../../../../Functional/RightDivider';

import styles from './RightElement.module.css';

const RightElement = ({
  rightElement,
  rightDivider
}) => {

  return (
    <div className={ styles.rightElement }>
      { rightDivider && <RightDivider /> }
      { rightElement }
    </div>
  );
};

RightElement.propTypes = {
  rightElement: PropTypes.element,
  rightDivider: PropTypes.bool
};

export default RightElement;