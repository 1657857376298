import React from 'react'
import { UiButton, useOptions } from "@compassplus/ui-mobicash";
import i18n from "../../../../i18n";
import DeliveryStore from "../../../../stores/DeliveryStore";
import ModalStore from "../../../../stores/ModalStore";
import { useHistory } from "react-router";

const SaveAttributeButton = ({ attributeType, modeIsModify, location }) => {
  const isOptions = useOptions();
  const history = useHistory();

  const checkPickupPointFields = () => {
    let err = false;
    const title = DeliveryStore.form.addressData.points[location.state.id].title
    if (title.ru === '' && title.en === '') {
      DeliveryStore.form.addressData.points[location.state.id].title.ru = `Центр выдачи ${DeliveryStore.form.addressData.points.length + 1}`;
      DeliveryStore.form.addressData.points[location.state.id].title.en = `Pickup point ${DeliveryStore.form.addressData.points.length + 1}`;
    }
    if (title.ru === '' && title.en !== '') {
      DeliveryStore.form.addressData.points[location.state.id].title.ru = DeliveryStore.form.addressData.points[location.state.id].title.en;
    }
    if (title.ru !== '' && title.en === '') {
      DeliveryStore.form.addressData.points[location.state.id].title.en = DeliveryStore.form.addressData.points[location.state.id].title.ru;
    }

    for (const attr of DeliveryStore.form.addressData.points[location.state.id].attributes) {
      if ((attr.value.ru === '') && (attr.value.en === '')) {
        err = true;
        ModalStore.openWithMessage(`${i18n.t('NecessaryToFillField')} ${attr.title[isOptions.language]} `);
      }
      if ((attr.value.ru === '') && (attr.value.en !== '')) {
        attr.value.ru = attr.value.en;
      }
      if ((attr.value.ru !== '') && (attr.value.en === '')) {
        attr.value.en = attr.value.ru;
      }
    }

    return err;
  }

  const savePickupPoint = () => {
    const isError = checkPickupPointFields();

    if (!isError) {
      DeliveryStore.form.addAttribute(attributeType, DeliveryStore.form.getEntryFieldTitle());
      history.goBack();
    }
  }

  const saveAttribute = () => {
    if (attributeType === 'EntryField' && !modeIsModify) {
      DeliveryStore.form.addAttribute(attributeType, DeliveryStore.form.getEntryFieldTitle());
    }

    if (attributeType !== 'EntryField' && !modeIsModify) {
      DeliveryStore.form.addAttribute(attributeType, '');
    }

    DeliveryStore.changedFlag = true;

    if (location.state.deliveryId) {
      history.push(`/administration/delivery/${location.state.deliveryId}`, {
        deliveryForm: DeliveryStore.form,
        id: location.state.deliveryId
      });
    } else {
      history.push(`/administration/delivery`, {
        deliveryForm: DeliveryStore.form,
      });
    }
  }

  const onSave = () => {
    switch (attributeType) {
      case 'PickupPoint':
        savePickupPoint();
        break;
      case 'Birthday':
      case 'Phone':
      case 'PersonalData':
      case 'EntryField':
      case 'ClientAddress':
      case 'CostOfDelivery':
      case 'DeliveryDate':
        saveAttribute();
        break;
    }
  }

  return (
    <div style={isOptions.screen === 'mobile' ? { width: '100%', padding: '0 1rem 0' } : { width: '100%', padding: '0 0 0 6px' }}>
      <UiButton
        style={{ marginTop: '1rem' }}
        title={i18n.t('Save')}
        onClick={onSave}
      />
    </div>
  )
}

export default SaveAttributeButton;