import React, { useState } from 'react';
import {
  UiArrow, UiCard, UiDots, UiSection, useOptions
} from '@compassplus/ui-mobicash';
import { observer } from 'mobx-react-lite';
import { MerchantLogo, MerchantSection } from '../../../market/MarketPage/style';
import i18n from '../../../../i18n';
import {
  getLogoColor,
  getLogoSymbol, getMerchantId,
  getPayeeList,
  getPhoto, getRequestData,
  getTitle, removeCashierData, request,
  setLogoColor,
  setLogoSymbol,
  setMerchantId,
  setPhoto,
  setTitle,
} from '../../../../Utils/requestUtil';
import ShareLink from '../../../../components/Modals/ShareLink';
import ActionSheet from '../../../../components/admin/ActionSheet';
import AdminMarketStore from '../../../../stores/AdminMarketStore';
import GlobalStateStore from "../../../../stores/GlobalStateStore";
import ModalStore from "../../../../stores/ModalStore";
import {useHistory} from "react-router";

const AdminMerchantCard = observer(({ shareLinkOpened }) => {
  const isOptions = useOptions();
  const history = useHistory();
  const [isShareLinkOpen, setIsShareLinkOpen] = useState(shareLinkOpened);
  const [actionSheetOpen, setActionSheetOpen] = useState(false);
  const [showPayeeList, setShowPayeeList] = useState(false);
  const payeeList = getPayeeList();

  const hasRootGroup = () => new Promise((resolve, reject) => {
    request('/api/v1/group', 'GET').then(
      (groups) => resolve(groups.length > 0 ? groups : false),
      (error) => reject(error),
    );
  });

  const checkRootGroup = (settings) => {
    hasRootGroup().then(
      (rootGroups) => {
        if (!rootGroups) {
          const requestRootData = {
            title: { en: settings.Name, ru: settings.Name },
            merchantGuid: settings.Rid,
            image: null,
          };

          request('/api/v1/group/', 'POST', requestRootData).then(
            (rootGroup) => {
              history.push('/main');
            },
            (error) => {
              ModalStore.openErrorWithMessage(i18n.t(error));
            },
          );
        } else if (rootGroups.length > 0) {
          history.push('/main');
        }
      },
      (rootError) => {
        ModalStore.openErrorWithMessage(i18n.t(rootError));
      },
    );
  };

  const getSettingsRequest = (merchantRid) => new Promise((resolve, reject) => {
    const reqBody = getRequestData();
    reqBody.language = '';
    reqBody.MerchantRid = merchantRid;
    request('/api/v1/settings/getSettings', 'POST', reqBody).then(
      (settings) => resolve(settings),
      (getSettingsError) => reject(getSettingsError),
    );
  });

  const getSettings = (merchantRid) => new Promise((resolve) => {
    getSettingsRequest(merchantRid).then(
      (settings) => {
        setMerchantId(settings.Rid);
        setTitle(JSON.stringify({ en: settings.Name, ru: settings.NameLocal }));
        setPhoto(settings.Photo !== undefined ? `data:image/png;base64,${settings.Photo}` : '');
        setLogoSymbol(settings.LogoSymbol);
        setLogoColor(settings.LogoColor);
        removeCashierData();

        GlobalStateStore.headerTitle = isOptions.language === 'ru' ? settings.NameLocal : settings.Name;
        GlobalStateStore.cms = settings?.cms || '';
        AdminMarketStore.email = settings?.email || '';
        GlobalStateStore.accessToken = settings?.accessToken || '';

        AdminMarketStore.setCMS(settings?.cms || '')

        resolve(settings);
      },
      (settingsError) => {
        ModalStore.openErrorWithMessage(i18n.t(settingsError));
      },
    );
  });

  const changeMerchant = (merchantRid) => {
    getSettings(merchantRid).then(
      (settings) => {
        checkRootGroup(settings);
      },
    );
  }

  const renderDropdownOptions = () => {
    const payeeListWithoutCurrent = payeeList.filter((el) => el.Rid !== getMerchantId())
    if (showPayeeList) {
      return (
        <>
          {
            payeeListWithoutCurrent.map((payee, index) => (
              <UiSection
                title={isOptions.language === 'ru' ? payee.NameLocal : payee.Name}
                key={payee.Rid}
                onClick={() => { changeMerchant(payee.Rid) }}
                bottomDivider={index !== payeeListWithoutCurrent.length - 1}
                view="link"
                rightElement={<UiArrow />}
              />
            ))
          }
        </>
      );
    }

    return (
      <>
        <UiSection
          title={i18n.t('MarketLink')}
          onClick={() => { setIsShareLinkOpen(true); }}
          bottomDivider
          view="link"
          rightElement={<UiArrow />}
        />
        {
          payeeList && payeeList.length > 1 ? (
            <UiSection
              title={i18n.t('ChangeMerchant')}
              onClick={(event) => { event.stopPropagation(); setShowPayeeList(!showPayeeList); }}
              view="link"
              rightElement={<UiArrow />}
            />
          ) : null
        }
      </>
    );
  };

  return (
    <>
      <UiCard shadow style={{ margin: '0 0 0.25rem', position: 'relative' }}>
        <MerchantSection
          style={{ alignItems: 'center' }}
          title={getTitle(i18n.language)}
          description={AdminMarketStore.cmsTitle}
          active={actionSheetOpen}
          leftElement={(
            <>
              {
                getPhoto() !== '' ? (
                  <img style={{ width: '48px', height: '48px' }} src={getPhoto()} />
                ) : (
                  <MerchantLogo
                    style={{ background: getLogoColor() }}
                  >
                    {getLogoSymbol()}
                  </MerchantLogo>
                )
              }
            </>
          )}
          rightElement={(<UiDots onClick={() => { setActionSheetOpen(!actionSheetOpen); setShowPayeeList(false); }} />)}
        />
        <ActionSheet
          open={actionSheetOpen}
          setOpen={() => setActionSheetOpen(false)}
        >
          {renderDropdownOptions()}
        </ActionSheet>
      </UiCard>
      <ShareLink active={isShareLinkOpen} setActive={setIsShareLinkOpen} type="market" />
    </>
  );
});

export default AdminMerchantCard;
