import React, { useEffect, useState } from 'react';

import {
  useOptions
} from '@compassplus/ui-mobicash';

import {Dialog, Text} from '../../../components/TemplateStyle';
import i18n from '../../../i18n';
import { getCurrency } from '../../../Utils/requestUtil';

import ShoppingCart from "../../../stores/ShoppingCartStore";
import Merchant from "../../../stores/Merchant";
import { observer } from "mobx-react-lite";

import OrderStore from "../../../stores/OrderStore";
import CheckoutSelections from "./components/CheckoutSelections";
import DeliveryFields from "./components/DeliveryFields";
import PaymentDetails from "./components/PaymentDetails";
import LoadingDeliverySettings from "../../../components/loadingCards/LoadingDeliverySettings";
import { useHistory } from "react-router";
import MerchantGuidsWithoutEInvoicingRedirecting from "../../../WebasystConfig";

const Checkout = observer(() => {
  const isOptions = useOptions();
  const history = useHistory();

  const [createBillDialogOpen, setCreateBillOpenDialog] = useState(false);

  useEffect(() => {
    OrderStore.getMerchantDeliverySettings();
  }, [Merchant.guid]);

  useEffect(() => {
    OrderStore.setSelectedDelivery();
  }, [OrderStore.selectedDeliveryType])

  const renderDeliverySettings = () => (
    <div style={{ position: 'relative', margin: '0.5rem 0 0', width: '100%' }}>
      {
        OrderStore.isLoading ? (
          <LoadingDeliverySettings />
        ) : (
          <>
            <CheckoutSelections />
            <DeliveryFields />
            <PaymentDetails setCreateBillOpenDialog={setCreateBillOpenDialog} />
          </>
        )
      }
    </div>
  )

  const getPersonalDataValue = () => {
    let personalData = '';
    if (OrderStore.isAttributeUsed('PersonalData')) {
      for (let field of OrderStore.selectedDelivery.personalData) {
        personalData += `${field.value[isOptions.language]} `;
      }
    }

    return personalData
  };

  return (
    <>
      {renderDeliverySettings()}
      {
        createBillDialogOpen && (
          <Dialog
            icon="pay-the-bill"
            title={i18n.t('IssueInvoice')}
            buttons={[
              {
                text: i18n.t('IssueInvoice'),
                mode: 'bold',
                action: () => { OrderStore.makeOrder(isOptions.language, history); setCreateBillOpenDialog(false); },
              },
              {
                text: i18n.t('Cancel'),
                mode: 'normal',
                action: () => { setCreateBillOpenDialog(false); },
              },
            ]}
          >
            <div style={{ textAlign: 'center', margin: '0.5rem 1rem' }}>
              <Text view='readonly' typography='note' position="center" style={{ margin: '0 0 0.5rem' }}>{Merchant.name}</Text>
              <Text view='readonly' typography='note' position="center">{i18n.t('CreateBillNotification')}</Text>
              <Text view='readonly' typography='note' position='center'>{`${i18n.t('TotalCount')}: ${ShoppingCart.totalCount}`}</Text>
              <Text view='readonly' typography='note' position="center">{`${i18n.t('OrderAmount')}: ${ShoppingCart.totalCost} ${i18n.t(getCurrency())}`}</Text>

              <>
                <Text view='readonly' typography='note' position="center">
                  {`${i18n.t('DeliveryMethod')}: ${OrderStore.selectedDelivery.title[isOptions.language]}`}
                </Text>
                {
                  (OrderStore.isAttributeUsed('PersonalData')) && (
                    <Text view='readonly' typography='note' position="center">
                      {`${i18n.t('PersonalData')}: ${getPersonalDataValue()}`}
                    </Text>
                  )
                }
                {
                  (OrderStore.isAttributeUsed('Phone')) && (
                    <Text view='readonly' typography='note' position="center">
                      {`${i18n.t('Phone')}: ${OrderStore.phoneNumber.value}`}
                    </Text>
                  )
                }
              </>

              { (OrderStore.selectedDelivery?.type === 'Pickup') || OrderStore.selectedDeliveryType === -1
                ? (
                  <Text position="center" style={{ margin: '0.5rem 0' }}>
                    {`${ShoppingCart.totalCost} ${i18n.t(getCurrency())}`}
                  </Text>
                ) : (
                  <Text position="center" style={{ margin: '0.5rem 0' }}>
                    {`${(Number(ShoppingCart.totalCost) + Number(OrderStore.deliveryCostObj.dependence.deliveryPrice)).toFixed(2)} ${i18n.t(getCurrency())}`}
                  </Text>
                )
              }

              {
                MerchantGuidsWithoutEInvoicingRedirecting.indexOf(Merchant.guid) === -1 ? (
                  <Text view='readonly' typography='note' position="center" style={{ margin: '0 0 0.5rem' }}>{Merchant.messageBeforeBillCreation}</Text>
                ) : null
              }
            </div>
          </Dialog>
        )
      }
    </>
  );
});

export default Checkout;
