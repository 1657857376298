import PropTypes from 'prop-types';
import cn from 'classnames';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../context/ThemeProvider';

import styles from './UiText.module.css';

const StyledContainerText = styled.span`
  color: ${ (props) => {
    switch (props.view) {
      case 'title':
        return props.styles.colors.textTitle;
      case 'readonly':
        return props.styles.colors.textReadOnly;
      case 'disable':
        return props.styles.colors.textDisable;
      case 'link':
        return props.styles.colors.textLink;
      case 'warning':
        return props.styles.colors.warningColor;
      case 'primary':
        return props.styles.colors.applicationPrimaryColor;
      case 'section':
        return props.styles.colors.sectionBackground;
      default:
        return props.styles.colors.textTitle;
    }
  } };

  ${ (props) => props.color && css`
    color: ${ props.color };
  ` }

  ${ (props) => {
    switch (props.typography) {
      case 'text':
        return props.styles.fonts.regular;
      case 'top-navbar-title':
        return props.styles.typography.TopNavbarTitle;
      case 'button':
        return props.styles.typography.CTAButton;
      case 'button-bold':
        return props.styles.typography.CTAButtonBold;
      case 'section-value':
        return props.styles.typography.SectionValue;
      case 'section-value-bold':
        return props.styles.typography.SectionValueBold;
      case 'section-amount':
        return props.styles.typography.SectionAmount;
      case 'section-title':
        return props.styles.typography.SectionTitle;
      case 'section-second-string':
        return props.styles.typography.SectionSecondString;
      case 'section-description':
        return props.styles.typography.SectionDescription;
      case 'section-description-semibold':
        return props.styles.typography.SectionDescriptionSemibold;
      case 'section-description-bold':
        return props.styles.typography.SectionDescriptionBold;
      case 'subsection-title':
        return props.styles.typography.SubsectionTitle;
      case 'dashboard-name':
        return props.styles.typography.DashboardName;
      case 'dashboard-description':
        return props.styles.typography.DashboardDescription;
      case 'note':
        return props.styles.typography.Note;
      case 'remark':
        return props.styles.typography.Remark;
      case 'dialog-title':
        return props.styles.typography.DialogTitle;
      case 'dialog-text':
        return props.styles.typography.DialogText;
      case 'memPad-digit':
        return props.styles.typography.MemPadDigit;
      case 'keyboard':
        return props.styles.typography.Keyboard;
      default:
        return props.styles.fonts.regular;
    }
  } }

  ${ (props) => {
    switch (props.position) {
      case 'center':
        return css`
          justify-content: center;
          text-align: center;
        `;
      case 'right':
        return css`
          justify-content: flex-end;
          text-align: end;
        `;
      default:
        return css`
          justify-content: flex-start;
          text-align: start;
        `;
    }
  } };
`;

/**
 * Text component
 * @public
 * @version 0.0.90
 * @param {String} className
 * @param {'title'|'primary'|'readonly'|'disable'|'link'|'warning'|'section'} [view=title]
 * @param {String} color
 * @param {'text'|'top-navbar-title'|'button'|'button-bold'|'section-value'|'section-value-bold'|'section-title'|'section-second-string'|'section-description'|'section-description-semibold'|'section-description-bold'|'subsection-title'|'note'|'dashboard-name'|'dashboard-description'|'remark'|'dialog-title'|'dialog-text'|'section-amount'|'memPad-digit'|'keyboard'} [typography=text]
 * @param {'left'|'center'|'right'} [position=left]
 * @param {*} children
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiText>{ children }</UiText>
 */
const UiText = ({
  className,
  view,
  color,
  typography,
  position,
  children,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <StyledContainerText
      { ...props }
      className={ cn(styles.container, className) }
      view={ view }
      color={ color }
      typography={ typography }
      position={ position }
      styles={ isTheme.styles }
    >
      { children }
    </StyledContainerText>
  );
};

UiText.propTypes = {
  /** Определяет цвет отображения текста */
  view: PropTypes.oneOf(['title', 'primary', 'readonly', 'disable', 'link', 'warning', 'section']),
  /** Переопределяет дефолтные цвета отображения текста */
  color: PropTypes.string,
  /** Определяет оформление отображения текста */
  typography: PropTypes.oneOf([
    'text',
    'top-navbar-title',
    'button',
    'button-bold',
    'section-value',
    'section-value-bold',
    'section-amount',
    'section-title',
    'section-second-string',
    'section-description',
    'section-description-semibold',
    'section-description-bold',
    'subsection-title',
    'note',
    'dashboard-name',
    'dashboard-description',
    'remark',
    'dialog-title',
    'dialog-text',
    'keyboard',
    'memPad-digit'
  ]),
  /** Определяет выравнивание текста */
  position: PropTypes.oneOf(['left', 'center', 'right'])
};

UiText.defaultProps = {
  view: 'title',
  typography: 'text',
  position: 'left'
};

export default UiText;