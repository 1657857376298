import styled from 'styled-components';
import { UiTab } from '@compassplus/ui-mobicash';

export const StyledTab = styled(UiTab)`
  & > div:last-child {
     position: relative;
  }
   
  & > div > ol {
    margin-bottom: 0;
  }
  
  @media(max-width: 663px) {
    & {
      margin: 0 -0.5rem;
      width: 100%;
    }
    & > div:last-child {
      position: relative;
      padding: 1rem 0.5rem 0;
    }
  }
`;
