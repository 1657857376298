import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams, useHistory } from 'react-router';
import {
  UiButton, useOptions,
} from '@compassplus/ui-mobicash';
import { MContentDiv } from '../../../components/TemplateStyle';

import GlobalStateStore from '../../../stores/GlobalStateStore';
import DeliveryModify from './DeliveryModify';
import i18n from '../../../i18n';
import { getMerchantId, request } from '../../../Utils/requestUtil';

import DeliveryStore from '../../../stores/DeliveryStore';
import DeliveryForms from '../../../stores/DeliveryForms';
import DeleteDeliveryButton from './components/DeleteDeliveryButton';

const DeliveryModifyPage = observer((props) => {
  const history = useHistory();
  const isOptions = useOptions();
  const [modeIsModify, setModeIsModify] = useState(false);
  const params = useParams();

  const checkFormIsValid = () => Object.keys(DeliveryStore.form).length !== 0;

  useEffect(() => {
    GlobalStateStore.setHeaderProperties({
      title: 'IssuingMethod',
      isBack: true,
      onBack: () => { history.push('/administration/'); },
    });

    if (params.id !== undefined) {
      setModeIsModify(true);
    }

    if (!checkFormIsValid()) {
      history.push('/administration');
    }
  }, []);

  const uploadDeliveryForm = () => {
    const requestData = {
      merchantGuid: getMerchantId(),
      ...DeliveryStore.form,
    };

    if (modeIsModify) {
      request('/api/v1/delivery/modify', 'POST', requestData)
        .then(() => {
          DeliveryForms.getFormFromDB();
        });
    } else {
      request('/api/v1/delivery/add', 'POST', requestData)
        .then(() => {
          DeliveryForms.getFormFromDB();
        });
    }
  };

  return (
    <>
      <MContentDiv>
        <DeliveryModify {...props} />
        {
          modeIsModify && (
            <div style={isOptions.screen === 'mobile'
              ? { padding: '0 1rem 0', marginTop: '1rem', width: '100%' }
              : { padding: '0 0 0 6px', marginTop: '1rem', width: '100%' }}
            >
              <DeleteDeliveryButton />
            </div>
          )
        }
        <div style={isOptions.screen === 'mobile' ? { width: '100%', padding: '0 1rem 0' } : { width: '100%', padding: '0 0 0 6px' }}>
          <UiButton
            style={{ marginTop: '1rem' }}
            title={i18n.t('Save')}
            onClick={() => {
              DeliveryStore.form.checkTitleIsNotEmpty();
              uploadDeliveryForm();
              history.push('/administration', { modify: true });
            }}
          />
        </div>
      </MContentDiv>
    </>
  );
});

export default DeliveryModifyPage;
