import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { UiCalendar, UiInput } from '@compassplus/ui-mobicash';
import { action } from 'mobx';
import i18n from '../../../../i18n';
import OrderStore from '../../../../stores/OrderStore';

const Birthday = observer(() => {
  const [openCalendar, setOpenCalendar] = useState(false);
  const setBirthday = action((value) => {
    OrderStore.birthday = value;
  });

  return (
    <>
      {
       OrderStore.selectedDelivery.birthday !== undefined ? (
         <>
           <UiCalendar
             days={['Пн','Вт','Ср','Чт','Пт','Сб','Вс']}
             minDate={'1970-10-10'}
             isOpened={openCalendar}
             onClose={() => setOpenCalendar(false)}
             setDate={setBirthday}
           />
           <UiInput
             description={i18n.t('Birthday')}
             onClick={() => { setOpenCalendar(true); }}
             value={OrderStore.birthday !== '' ? new Date(OrderStore.birthday.getTime() + 5 * 60 * 60 * 1000).toISOString().split('T')[0] : ''}
             placeholder={new Date().toISOString().split('T')[0]}
             required={OrderStore.selectedDelivery.birthday}
             readOnly
           />
         </>
       ) : null
      }
    </>
  );
});

export default Birthday;
