import React from 'react';
import styled from 'styled-components';
import { UiCard, UiCardSection } from '@compassplus/ui-mobicash';
import { LoadingCardImage, LoadingDescription, LoadingTitle } from './style';

const GroupCard = styled(UiCard)`
  & {
    max-width: 20rem;
    @media (max-width: 664px) {
      max-width: 100%;
    }
  }
`;

const LoadingMarketGroupCard = () => (
  <GroupCard>
    <UiCardSection
      title={<LoadingTitle />}
      leftElement={<LoadingCardImage />}
      description={<LoadingDescription />}
      style={{ boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.13)', margin: '0.25rem 0' }}
    />
  </GroupCard>
);

export default LoadingMarketGroupCard;
