import React, { useState } from 'react';
import { Text } from "../../../../components/TemplateStyle";
import i18n from '../../../../i18n';
import { UiButton, UiCard, UiInputDate } from '@compassplus/ui-mobicash';
import OrdersRecordsStore from '../../../../stores/OrdersRecordsStore';
import { SubContentStiky } from '../../ModifyAttributePage/style';
import { useHistory } from 'react-router';

const OrderDeliveryDate = () => {
  const { modifyingOrder } = OrdersRecordsStore;
  const history = useHistory();
  const [deliveryDate, setDeliveryDate] = useState({ value: modifyingOrder?.deliveryData.deliveryDate });

  const renderDeliveryDateInput = () => (
    <>
      <UiInputDate
        description={i18n.t('DeliveryDate')}
        record="partial"
        state={deliveryDate}
        setState={setDeliveryDate}
        defaultValue={deliveryDate.value}
      />
    </>
  )

  return (
    <>
      <Text
        style={{ textTransform: 'uppercase', fontWeight: 600 }}
        view="readonly"
        typography="note"
      >
        {i18n.t('DeliveryDate')}
      </Text>
      <UiCard
        style={{ margin: '0.5rem 0 0.25rem' }}
        shadow
      >
        {renderDeliveryDateInput()}
      </UiCard>
      <SubContentStiky>
        <UiButton
          title={i18n.t('Save')}
          onClick={() => {
            OrdersRecordsStore.modifyDeliveryData({ deliveryDate: deliveryDate.value.split(' ')[0] });
            history.replace(`/modifyOrder/${modifyingOrder._id}`);
          }}
        />
      </SubContentStiky>
    </>
  )
}

export default OrderDeliveryDate;