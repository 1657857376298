import styled from 'styled-components';
import { UiCardSection } from '@compassplus/ui-mobicash';
import {AnimationCardSection} from "../../../components/TemplateStyle";

export const CartSection = styled(UiCardSection)`
& > div:first-child {
  min-width: 70px;
  max-width: 70px;
  min-height: 70px;
  max-height: 70px;
  border-radius: 0;
}
  & > div:nth-child(2) > div > span {
    color: #000;
    font-size: 1.125rem;
    line-height: 1.25rem;
    font-weight: 600;
    margin: 10px 0 0;
  }
`

export const CheckoutCartSection = styled(AnimationCardSection)`
  & > div > span:first-child {
    text-transform: uppercase;
  }
  
  & > div > span:nth-child(2) {
    font-size: 0.75rem;
  }
  
  & > div > div:last-child > span {
    font-size: 1rem;
    font-weight: 700;
    color: #7F7F7F;
  }
  & > div > div:last-child {
    margin: 0.425rem 0 0.125rem;
  }
`;