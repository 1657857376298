import React from 'react';
import { useOptions } from '@compassplus/ui-mobicash';
import {
  Table, TableContainer, TableHeader, TableRow,
} from './style';
import AttributeTableCell from './AttributeTableCell';
import FirstColumnHeader from './FirstColumnHeader';

const AttributesTable = ({ separatedCountAttributes, style }) => {
  const isOptions = useOptions();

  let firstAttribute = [];
  let secondAttribute = [];

  if (separatedCountAttributes[0].type === 'color') {
    firstAttribute = separatedCountAttributes[0].complexValue;
    secondAttribute = separatedCountAttributes[1].complexValue;
  } else {
    firstAttribute = separatedCountAttributes[1].complexValue;
    secondAttribute = separatedCountAttributes[0].complexValue;
  }

  const renderHeader = (attribute, index) => (
    <TableHeader key={`${attribute.title.en}`}>
      {attribute.title[isOptions.language]}
    </TableHeader>
  );

  const renderBody = (firstAttribute) => (
    <TableRow key={firstAttribute.title.en}>
      <FirstColumnHeader attribute={firstAttribute} />
      {
        secondAttribute.map((secondAttribute, index) => {
          const fullAttributeName = `${secondAttribute.title.en};${firstAttribute.title.en}`;
          return (
            <AttributeTableCell
              fullAttributeName={fullAttributeName}
            />
          );
        })
      }
    </TableRow>
  );

  return (
    <TableContainer style={style}>
      <Table>
        <thead>
          <TableRow>
            <th style={{ position: 'sticky', left: 0, background: '#fff' }} />
            {secondAttribute.map(renderHeader)}
          </TableRow>
        </thead>
        <tbody>
          {firstAttribute.map(renderBody)}
        </tbody>
      </Table>
    </TableContainer>
  );
};

export default AttributesTable;
