import React, { useEffect } from 'react';
import { UiIcon, useOptions } from '@compassplus/ui-mobicash';
import { useHistory, useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import { StyledHeader, CartItemsCount } from './style';
import MerchantCard from '../MerchantCard';
import { getCurrency } from '../../../Utils/requestUtil';
import i18n from '../../../i18n';
import ShoppingCart from '../../../stores/ShoppingCartStore';
import Merchant from '../../../stores/Merchant';
import { Text } from '../../TemplateStyle';

const MarketHeader = observer(() => {
  const isOptions = useOptions();
  const history = useHistory();
  const params = useParams();
  const merchantURL = params.merchant;

  useEffect(() => {
    const getRes = async function () {
      const res = await Merchant.setMerchantData(params.merchant, isOptions.language);
      if (!res) {
        history.push('/merchants');
      }
    };
    getRes();
  }, [params]);

  useEffect(async () => {
    await fetch('/api/v1/reserv/checkTime', { method: 'GET' });
    if (Merchant.guid !== '') {
      ShoppingCart.setCartByGuid(Merchant.guid);
    }
  }, [Merchant.guid]);

  const renderCartIcon = () => (
    <div
      style={{
        position: 'relative', display: 'flex', alignItems: 'center', cursor: 'pointer',
      }}
      onClick={() => { history.push(`/${merchantURL}/shoppingCart`); }}
    >
      <Text view="readonly" typography="note">
        {`${i18n.t('Cart')} (${ShoppingCart.totalCount} / ${ShoppingCart.totalCost} ${i18n.t(getCurrency())})`}
      </Text>
      <div style={{ display: 'flex' }}>
        <UiIcon
          icon="cart"
          view="brand"
          onClick={() => { history.push(`/${merchantURL}/shoppingCart`); }}
        />
        <CartItemsCount>
          {ShoppingCart.totalCount}
        </CartItemsCount>
      </div>
    </div>
  );

  return (
    <StyledHeader
      text=""
      left={isOptions.screen === 'desktop' ? <MerchantCard type="header" /> : null}
      right={renderCartIcon()}
    />
  );
});

export default MarketHeader;
