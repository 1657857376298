import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Element component
 * @public
 * @version 0.0.101
 * @param {Function} onClick
 * @param {Boolean} [disabled=false]
 * @param {Boolean} [propagation=true]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiCancelForPlates />
 */
const UiCancelForPlates = ({
  onClick,
  disabled,
  propagation,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      disabled={ disabled }
      propagation={ propagation }
      onClick={ onClick }
      innerRef={ innerRef }
      width="0.75rem"
      height="0.75rem"
      viewBox="0 0 12 12"
    >
      <circle cx="6" cy="6" r="6" fill={ isTheme.styles.colors.sectionBackground } />
      <path
        d="M8.82841 4.58577C9.21893 4.19524 9.21893 3.56208 8.82841 3.17156C8.43789 2.78103 7.80472 2.78103 7.4142 3.17156L5.99998 4.58577L4.58577 3.17156C4.19524 2.78103 3.56208 2.78103 3.17156 3.17156C2.78103 3.56208 2.78103 4.19524 3.17156 4.58577L4.58577 5.99998L3.17156 7.4142C2.78103 7.80472 2.78103 8.43788 3.17156 8.82841C3.56208 9.21893 4.19524 9.21893 4.58577 8.82841L5.99998 7.4142L7.4142 8.82841C7.80472 9.21893 8.43789 9.21893 8.82841 8.82841C9.21893 8.43789 9.21893 7.80472 8.82841 7.4142L7.4142 5.99998L8.82841 4.58577Z"
        fill={ isTheme.styles.colors.textDisable }
      />
    </Element>
  );
};

UiCancelForPlates.propTypes = {
  /** Обработчик события при клике */
  onClick: PropTypes.func,
  /** Компонент неактивен */
  disabled: PropTypes.bool,
  /** Разрешить/запретить всплытие обработчика onClick */
  propagation: PropTypes.bool,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

UiCancelForPlates.defaultProps = {
  disabled: false,
  propagation: true
};

export default UiCancelForPlates;