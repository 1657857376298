import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Element component
 * @public
 * @version 0.0.90
 * @param {Function} onClick
 * @param {Boolean} [disabled=false]
 * @param {Boolean} [propagation=true]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiDots />
 */
const UiDots = ({
  onClick,
  disabled,
  propagation,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      color={ isTheme.styles.colors.textLink }
      disabled={ disabled }
      propagation={ propagation }
      onClick={ onClick }
      innerRef={ innerRef }
      width="1rem"
      height="1rem"
      viewBox="0 0 16 16"
    >
      <rect x="6" width="3.99998" height="3.99998" rx="1.99999" />
      <rect x="6" y="6" width="3.99998" height="3.99998" rx="1.99999" />
      <rect x="6" y="12" width="3.99998" height="3.99998" rx="1.99999" />
    </Element>
  );
};

UiDots.propTypes = {
  /** Обработчик события при клике */
  onClick: PropTypes.func,
  /** Компонент неактивен */
  disabled: PropTypes.bool,
  /** Разрешить/запретить всплытие обработчика onClick */
  propagation: PropTypes.bool,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

UiDots.defaultProps = {
  disabled: false,
  propagation: true
};

export default UiDots;