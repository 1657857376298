import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Element component
 * @public
 * @version 0.0.90
 * @param {Function} onClick
 * @param {Boolean} [disabled=false]
 * @param {Boolean} [propagation=true]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiReload />
 */
const UiReload = ({
  onClick,
  disabled,
  propagation,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      color={ isTheme.styles.colors.textLink }
      disabled={ disabled }
      propagation={ propagation }
      onClick={ onClick }
      innerRef={ innerRef }
      width="1rem"
      height="1rem"
      viewBox="0 0 16 16"
    >
      <path
        d="M8.5 6.09092V4.18182C6.22438 4.18182 4.375 5.89365 4.375 8.00001C4.375 8.64274 4.54688 9.25365 4.85625 9.78184L3.8525 10.7109C3.31625 9.9282 3 8.99911 3 8.00001C3 5.18728 5.46125 2.90909 8.5 2.90909V1L11.25 3.54546L8.5 6.09092Z" />
      <path
        d="M12.1438 6.21816L13.1475 5.28907C13.6838 6.0718 14 7.00089 14 7.99999C14 10.8127 11.5388 13.0909 8.5 13.0909V15L5.75 12.4545L8.5 9.90908V11.8182C10.7756 11.8182 12.625 10.1064 12.625 7.99999C12.625 7.35726 12.4463 6.75271 12.1438 6.21816Z" />
    </Element>
  );
};

UiReload.propTypes = {
  /** Обработчик события при клике */
  onClick: PropTypes.func,
  /** Компонент неактивен */
  disabled: PropTypes.bool,
  /** Разрешить/запретить всплытие обработчика onClick */
  propagation: PropTypes.bool,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

UiReload.defaultProps = {
  disabled: false,
  propagation: true
};

export default UiReload;