import React from 'react';
import i18n from "../../../../../i18n";
import { Text } from "../../../../../components/TemplateStyle";
import { UiArrow, UiSection, UiCard } from "@compassplus/ui-mobicash";
import SuccessOrderLink from "./SuccessOrderLink";
import { useHistory } from "react-router";
import MarketLinkSettings from "./MarketLinkSettings";
import {observer} from "mobx-react-lite";
import GlobalStateStore from "../../../../../stores/GlobalStateStore";

const LinksSettings = observer(({ location }) => {
  const history = useHistory();
  return (
    <>
      <Text
        style={{ textTransform: 'uppercase', margin: '0 0 0.25rem' }}
        view="readonly"
        typography="section-description-semibold"
      >
        {i18n.t('LinksSettings')}
      </Text>
      {
        !GlobalStateStore.isCashierAuth ? (
          <UiCard style={{ margin: '0.25rem 0 0.25rem' }} shadow>
            <UiSection
              title={i18n.t('FillInCashierData')}
              rightElement={<UiArrow />}
              onClick={() => {
                history.push('/administration/cashierAuth', { ...location.state });
              }}
            />
          </UiCard>
        ) : (
          <>
            <MarketLinkSettings />
            <SuccessOrderLink />
          </>
        )
      }
    </>
  );
});

export default LinksSettings;