import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Plate awards icon
 * @public
 * @version 1.3.0
 * @param {String} width
 * @param {String} height
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiAwardsIcon />
 */
const UiAwardsIcon = ({
  width,
  height,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      innerRef={ innerRef }
      width={ width }
      height={ height }
      viewBox="0 0 52 52"
    >
      <path fillRule="evenodd" clipRule="evenodd"
        d="M29.3688 27.3239C29.5568 26.6059 29.6608 25.8589 29.6608 25.0929V24.7079L29.8138 24.7109C31.3628 24.7919 32.5738 25.1839 33.2508 25.8339C33.6978 26.2629 33.8838 26.7749 33.8268 27.3239H29.3688ZM12.5998 31.0259C10.9728 30.6209 8.83183 29.9069 7.86583 28.8059C6.90883 27.7159 6.86383 26.6329 7.74083 25.8339C8.42883 25.2069 9.64883 24.8269 11.1988 24.7279V25.0929C11.1988 27.4369 12.1388 29.6149 13.6908 31.2629C13.3208 31.1869 12.9448 31.1119 12.5998 31.0259ZM43.6818 27.3239H34.8838C34.9398 26.6629 34.7038 26.0399 34.1528 25.5119C33.3258 24.7179 31.4018 24.1709 30.1118 24.1109C29.7778 24.0989 29.6608 24.1039 29.6608 24.1039V23.2659H11.1988V24.0999C9.23783 24.2049 7.68383 24.6969 6.78983 25.5119C5.71083 26.4929 5.73883 27.7939 6.86483 29.0779C7.94883 30.3129 10.2388 31.1029 11.9698 31.5479C12.9638 31.8029 13.8858 31.9769 14.5788 32.0909C15.4118 32.7739 16.3658 33.3279 17.4158 33.7039C19.3318 34.4869 19.5518 35.0059 19.5488 35.9159L19.5478 36.1699H17.5888C16.1708 36.1699 15.0208 36.8439 15.0208 37.6759C15.0208 37.7079 15.0338 37.7379 15.0368 37.7689H14.0688C13.4098 37.7689 12.8758 38.0819 12.8758 38.4689V39.5769C12.8758 39.9639 13.4098 40.2769 14.0688 40.2769H26.7908C27.4498 40.2769 27.9838 39.9639 27.9838 39.5769V38.9099H35.3098H36.8698V40.2769L38.1998 39.7569C38.4708 39.6509 38.8228 39.6509 39.0938 39.7569L40.4228 40.2769V38.9099H41.9138H43.6818C44.7888 38.9099 45.6868 38.3839 45.6868 37.7349V28.4999C45.6868 27.8499 44.7888 27.3239 43.6818 27.3239Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M34.9025 35.9714H22.4935C21.4255 35.9714 20.5605 35.1064 20.5605 34.0384V18.8554C20.5605 17.7874 21.4255 16.9224 22.4935 16.9224H42.9715C44.0395 16.9224 44.9045 17.7874 44.9045 18.8554V34.0384C44.9045 35.1064 44.0395 35.9714 42.9715 35.9714H41.2675"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M42.9713 36.4714H41.2673C40.9913 36.4714 40.7673 36.2474 40.7673 35.9714C40.7673 35.6954 40.9913 35.4714 41.2673 35.4714H42.9713C43.7613 35.4714 44.4043 34.8284 44.4043 34.0384V18.8554C44.4043 18.0654 43.7613 17.4224 42.9713 17.4224H22.4933C21.7033 17.4224 21.0603 18.0654 21.0603 18.8554V34.0384C21.0603 34.8284 21.7033 35.4714 22.4933 35.4714H34.9023C35.1793 35.4714 35.4023 35.6954 35.4023 35.9714C35.4023 36.2474 35.1793 36.4714 34.9023 36.4714H22.4933C21.1523 36.4714 20.0603 35.3804 20.0603 34.0384V18.8554C20.0603 17.5134 21.1523 16.4224 22.4933 16.4224H42.9713C44.3133 16.4224 45.4043 17.5134 45.4043 18.8554V34.0384C45.4043 35.3804 44.3133 36.4714 42.9713 36.4714Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M36.406 31.0593V38.2173L37.688 37.3633C37.949 37.1893 38.288 37.1893 38.549 37.3633L39.831 38.2173V31.0593"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M40.0808 38.6847L38.4108 37.5707C38.2348 37.4537 38.0048 37.4537 37.8268 37.5707L36.1558 38.6847V31.0597H36.6558V37.7497L37.5488 37.1547C37.8958 36.9237 38.3418 36.9247 38.6878 37.1547L39.5808 37.7497V31.0597H40.0808V38.6847Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M32.1858 30.5988H23.4968C23.3588 30.5988 23.2468 30.4868 23.2468 30.3488C23.2468 30.2108 23.3588 30.0988 23.4968 30.0988H32.1858C32.3238 30.0988 32.4358 30.2108 32.4358 30.3488C32.4358 30.4868 32.3238 30.5988 32.1858 30.5988Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M32.1856 28.279H28.4326C28.2946 28.279 28.1826 28.167 28.1826 28.029C28.1826 27.891 28.2946 27.779 28.4326 27.779H32.1856C32.3236 27.779 32.4356 27.891 32.4356 28.029C32.4356 28.167 32.3236 28.279 32.1856 28.279Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M28.4328 29.4391H23.8018C23.6628 29.4391 23.5518 29.3271 23.5518 29.1891C23.5518 29.0511 23.6628 28.9391 23.8018 28.9391H28.4328C28.5708 28.9391 28.6828 29.0511 28.6828 29.1891C28.6828 29.3271 28.5708 29.4391 28.4328 29.4391Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M32.1858 29.4391H29.5588C29.4208 29.4391 29.3088 29.3271 29.3088 29.1891C29.3088 29.0511 29.4208 28.9391 29.5588 28.9391H32.1858C32.3238 28.9391 32.4358 29.0511 32.4358 29.1891C32.4358 29.3271 32.3238 29.4391 32.1858 29.4391Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M41.8367 20.9284H24.8267C24.6887 20.9284 24.5767 20.8164 24.5767 20.6784C24.5767 20.5404 24.6887 20.4284 24.8267 20.4284H41.8367C41.9747 20.4284 42.0867 20.5404 42.0867 20.6784C42.0867 20.8164 41.9747 20.9284 41.8367 20.9284Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M38.6711 22.2223H33.3701C33.2321 22.2223 33.1201 22.1103 33.1201 21.9723C33.1201 21.8343 33.2321 21.7223 33.3701 21.7223H38.6711C38.8091 21.7223 38.9211 21.8343 38.9211 21.9723C38.9211 22.1103 38.8091 22.2223 38.6711 22.2223Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M11.6633 10.2502V13.2532C11.6633 19.6162 14.1193 25.2502 17.6553 27.4122C19.5023 28.6982 19.7133 29.5522 19.7113 31.0482L19.7103 31.4662H21.4103L21.4093 31.0482C21.4073 29.5522 21.6183 28.6982 23.4653 27.4122C27.0013 25.2502 29.4573 19.6162 29.4573 13.2532V10.2502H11.6633Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M19.9611 31.216H21.1591V31.049C21.1571 29.404 21.4571 28.506 23.3221 27.207C26.8481 25.051 29.2081 19.446 29.2081 13.254V10.5H11.9131V13.254C11.9131 19.446 14.2731 25.051 17.7861 27.199C19.6641 28.506 19.9631 29.404 19.9611 31.049V31.216ZM21.4101 31.716H19.7111C19.6441 31.716 19.5811 31.69 19.5341 31.643C19.4861 31.595 19.4611 31.532 19.4611 31.466V31.048C19.4631 29.608 19.2791 28.848 17.5121 27.617C13.8691 25.39 11.4131 19.614 11.4131 13.254V10.25C11.4131 10.112 11.5251 10 11.6631 10H29.4581C29.5961 10 29.7081 10.112 29.7081 10.25V13.254C29.7081 19.614 27.2521 25.39 23.5961 27.626C21.8411 28.848 21.6571 29.608 21.6591 31.048L21.6601 31.466C21.6601 31.532 21.6331 31.596 21.5861 31.643C21.5401 31.69 21.4761 31.716 21.4101 31.716Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M12.6896 23.009C11.2016 22.342 9.24261 21.168 8.35861 19.359C7.48361 17.567 7.44161 15.785 8.24461 14.473C8.89661 13.405 10.0636 12.759 11.5576 12.627L11.5376 11.605C9.68061 11.75 8.21361 12.57 7.37561 13.942C6.38861 15.557 6.41361 17.695 7.44361 19.806C8.43461 21.836 10.5306 23.135 12.1136 23.867C13.2236 24.381 14.2556 24.701 14.9016 24.875C14.6366 24.465 14.3846 24.032 14.1456 23.578C13.6396 23.405 13.1496 23.216 12.6896 23.009Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M11.2927 11.8796C9.64674 12.0726 8.33774 12.8456 7.58874 14.0716C6.64674 15.6126 6.67574 17.6636 7.66874 19.6956C8.62674 21.6606 10.6717 22.9246 12.2187 23.6396C13.0017 24.0026 13.7477 24.2686 14.3487 24.4546C14.2197 24.2366 14.0937 24.0116 13.9707 23.7826C13.4797 23.6116 13.0147 23.4286 12.5877 23.2366C11.0617 22.5536 9.05074 21.3446 8.13474 19.4686C7.22074 17.5976 7.18374 15.7296 8.03074 14.3426C8.68574 13.2716 9.84174 12.5876 11.3027 12.4026L11.2927 11.8796ZM14.9017 25.1246C14.8797 25.1246 14.8577 25.1216 14.8367 25.1156C14.1617 24.9346 13.1237 24.6096 12.0087 24.0936C10.3887 23.3446 8.24274 22.0116 7.21974 19.9156C6.15074 17.7256 6.12974 15.5016 7.16174 13.8116C8.03774 12.3796 9.58474 11.5066 11.5177 11.3556C11.5827 11.3476 11.6537 11.3726 11.7057 11.4196C11.7557 11.4656 11.7857 11.5306 11.7877 11.5996L11.8077 12.6216C11.8097 12.7536 11.7097 12.8636 11.5797 12.8756C10.1497 13.0026 9.06974 13.6006 8.45774 14.6036C7.70074 15.8416 7.74674 17.5346 8.58374 19.2496C9.43474 20.9906 11.3417 22.1306 12.7917 22.7806H12.7927C13.2317 22.9786 13.7147 23.1676 14.2267 23.3406C14.2867 23.3616 14.3367 23.4046 14.3667 23.4616C14.6037 23.9116 14.8547 24.3406 15.1117 24.7386C15.1677 24.8256 15.1647 24.9386 15.1037 25.0226C15.0557 25.0876 14.9807 25.1246 14.9017 25.1246Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M28.4722 23.009C29.9602 22.342 31.9192 21.168 32.8032 19.359C33.6782 17.567 33.7202 15.785 32.9172 14.473C32.2652 13.405 31.0982 12.759 29.6052 12.627L29.6242 11.605C31.4812 11.75 32.9492 12.57 33.7862 13.942C34.7742 15.557 34.7482 17.695 33.7182 19.806C32.7272 21.836 30.6322 23.135 29.0482 23.867C27.9382 24.381 26.9062 24.701 26.2612 24.875C26.5252 24.465 26.7772 24.032 27.0162 23.578C27.5232 23.405 28.0122 23.216 28.4722 23.009Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M27.1912 23.7824C27.0682 24.0114 26.9422 24.2364 26.8142 24.4544C27.4152 24.2684 28.1602 24.0024 28.9432 23.6394C30.4902 22.9254 32.5352 21.6604 33.4942 19.6964C34.4862 17.6624 34.5152 15.6124 33.5732 14.0724C32.8242 12.8454 31.5152 12.0734 29.8692 11.8794L29.8592 12.4024C31.3212 12.5884 32.4772 13.2714 33.1312 14.3424C33.9792 15.7294 33.9412 17.5974 33.0272 19.4694C32.1112 21.3444 30.1002 22.5534 28.5742 23.2374C28.1462 23.4294 27.6812 23.6124 27.1912 23.7824ZM26.2612 25.1244C26.1822 25.1244 26.1072 25.0874 26.0592 25.0224C25.9982 24.9384 25.9952 24.8264 26.0512 24.7394C26.3082 24.3404 26.5592 23.9094 26.7952 23.4614C26.8252 23.4044 26.8752 23.3614 26.9362 23.3414C27.4462 23.1674 27.9282 22.9794 28.3702 22.7814C29.8202 22.1314 31.7282 20.9904 32.5782 19.2494C33.4152 17.5344 33.4612 15.8414 32.7042 14.6034C32.1012 13.6144 30.9922 13.0014 29.5832 12.8754C29.4522 12.8644 29.3532 12.7534 29.3552 12.6214L29.3752 11.5994C29.3762 11.5314 29.4062 11.4664 29.4572 11.4194C29.5082 11.3724 29.5712 11.3474 29.6442 11.3554C31.5782 11.5074 33.1252 12.3794 34.0002 13.8114C35.0322 15.5004 35.0122 17.7254 33.9432 19.9154C32.9202 22.0124 30.7742 23.3454 29.1532 24.0944C28.0392 24.6094 27.0012 24.9344 26.3262 25.1154C26.3042 25.1214 26.2822 25.1244 26.2612 25.1244Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M23.2947 36.4167H17.8227C16.4557 36.4167 15.3477 35.3087 15.3477 33.9407C15.3477 32.5737 16.4557 31.4657 17.8227 31.4657H23.2947C24.6617 31.4657 25.7697 32.5737 25.7697 33.9407C25.7697 35.3087 24.6617 36.4167 23.2947 36.4167Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M17.8229 31.716C16.5959 31.716 15.5969 32.714 15.5969 33.941C15.5969 35.168 16.5959 36.167 17.8229 36.167H23.2949C24.5219 36.167 25.5199 35.168 25.5199 33.941C25.5199 32.714 24.5219 31.716 23.2949 31.716H17.8229ZM23.2949 36.667H17.8229C16.3199 36.667 15.0969 35.444 15.0969 33.941C15.0969 32.439 16.3199 31.216 17.8229 31.216H23.2949C24.7969 31.216 26.0199 32.439 26.0199 33.941C26.0199 35.444 24.7969 36.667 23.2949 36.667Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M26.6915 38.2175H14.4295C13.7945 38.2175 13.2795 37.7025 13.2795 37.0685V35.2455C13.2795 34.6105 13.7945 34.0955 14.4295 34.0955H26.6915C27.3265 34.0955 27.8405 34.6105 27.8405 35.2455V37.0685C27.8405 37.7025 27.3265 38.2175 26.6915 38.2175Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M14.4293 34.3454C13.9333 34.3454 13.5293 34.7494 13.5293 35.2454V37.0684C13.5293 37.5644 13.9333 37.9674 14.4293 37.9674H26.6913C27.1873 37.9674 27.5913 37.5644 27.5913 37.0684V35.2454C27.5913 34.7494 27.1873 34.3454 26.6913 34.3454H14.4293ZM26.6913 38.4674H14.4293C13.6573 38.4674 13.0293 37.8394 13.0293 37.0684V35.2454C13.0293 34.4734 13.6573 33.8454 14.4293 33.8454H26.6913C27.4633 33.8454 28.0913 34.4734 28.0913 35.2454V37.0684C28.0913 37.8394 27.4633 38.4674 26.6913 38.4674Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M41.8366 27.6144C41.8366 29.6674 40.1716 31.3324 38.1186 31.3324C36.0656 31.3324 34.4006 29.6674 34.4006 27.6144C34.4006 25.5604 36.0656 23.8964 38.1186 23.8964C40.1716 23.8964 41.8366 25.5604 41.8366 27.6144Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M38.1184 24.1462C36.2064 24.1462 34.6504 25.7022 34.6504 27.6142C34.6504 29.5262 36.2064 31.0822 38.1184 31.0822C40.0314 31.0822 41.5864 29.5262 41.5864 27.6142C41.5864 25.7022 40.0314 24.1462 38.1184 24.1462ZM38.1184 31.5822C35.9304 31.5822 34.1504 29.8022 34.1504 27.6142C34.1504 25.4262 35.9304 23.6462 38.1184 23.6462C40.3064 23.6462 42.0864 25.4262 42.0864 27.6142C42.0864 29.8022 40.3064 31.5822 38.1184 31.5822Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M38.1182 29.9718C36.8182 29.9718 35.7612 28.9138 35.7612 27.6148C35.7612 26.3138 36.8182 25.2568 38.1182 25.2568C38.2562 25.2568 38.3682 25.3678 38.3682 25.5068C38.3682 25.6448 38.2562 25.7568 38.1182 25.7568C37.0942 25.7568 36.2612 26.5898 36.2612 27.6148C36.2612 28.6388 37.0942 29.4718 38.1182 29.4718C39.1422 29.4718 39.9752 28.6388 39.9752 27.6148C39.9752 27.4758 40.0872 27.3648 40.2252 27.3648C40.3642 27.3648 40.4752 27.4758 40.4752 27.6148C40.4752 28.9138 39.4182 29.9718 38.1182 29.9718Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M25.012 17.2604C25.012 19.7204 23.018 21.7144 20.559 21.7144C18.099 21.7144 16.105 19.7204 16.105 17.2604C16.105 14.8014 18.099 12.8074 20.559 12.8074C23.018 12.8074 25.012 14.8014 25.012 17.2604Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd" d="M21.9025 15.1554H19.2205L20.5675 17.5584L21.9025 15.1554Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd" d="M20.6787 18.5158H23.3607L22.0137 16.1138L20.6787 18.5158Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd" d="M17.7566 18.5158H20.4386L19.0916 16.1138L17.7566 18.5158Z"
        fill={ isTheme.styles.colors.sectionBackground } />
    </Element>
  );
};

UiAwardsIcon.propTypes = {
  /** Определяет ширину иконки */
  width: PropTypes.string,
  /** Определяет высоту иконки */
  height: PropTypes.string
};

UiAwardsIcon.defaultProps = {
  width: '3.25rem',
  height: '3.25rem'
};

export default UiAwardsIcon;