import { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import FCell from '../../Functional/FKeyboard/FCell';
import FBackspace from '../../Functional/FKeyboard/FBackspace';

import { isFunction } from '../../../utils/typeUtils';

import styles from './UiDigiPad.module.css';

/**
 * Компонент цифровой клавиатуры
 * @public
 * @version 0.0.107
 * @param {String} [className]
 * @param {String} [userValue:value='']
 * @param {Function} [onValueChange]
 * @param {Number} [maxLength]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiDigiPad />
 */
const UiDigiPad = ({
  className,
  value: userValue,
  onValueChange,
  maxLength,
  innerRef,
  ...props
}) => {

  const keys = useMemo(() => {
    return [
      ['1', '2', '3'],
      ['4', '5', '6'],
      ['7', '8', '9'],
      [undefined, '0', undefined]
    ];
  }, []);

  const clickHandler = (V) => {
    const value = `${ userValue }${ V }`;

    if (value.length > maxLength) {
      return;
    }

    if (isFunction(onValueChange)) {
      onValueChange(value);
    }
  };

  const backspaceHandler = () => {
    const value = userValue.slice(0, -1);

    if (isFunction(onValueChange)) {
      onValueChange(value);
    }
  };

  return (
    <div { ...props } className={ cn(styles.container, className) } ref={ innerRef }>
      { keys.map((row) => {
        return row.map((column) => {
          if (column === undefined) {
            return null;
          }

          return (
            <FCell
              key={ column }
              text={ column }
              onClick={ () => clickHandler(column) }
            />
          );
        });
      }) }
      <FBackspace
        disabled={ !userValue }
        onClick={ backspaceHandler }
      />
    </div>
  );
};

UiDigiPad.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Значение числа */
  value: PropTypes.string,
  /** Обработчик изменения значения */
  onValueChange: PropTypes.func,
  /** Максимальное количество символов, которое может ввести пользователь */
  maxLength: PropTypes.number,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

UiDigiPad.defaultProps = {
  value: ''
};

export default UiDigiPad;