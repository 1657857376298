import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import ReactDOM from 'react-dom';
import { OptionsProvider, ThemeProvider } from '@compassplus/ui-mobicash';
import App from './components/App';

import * as serviceWorker from './serviceWorker';
import '@compassplus/ui-mobicash/dist/index.css';

import './i18n';
import './index.css';

ReactDOM.render(
  <OptionsProvider language="ru">
    <ThemeProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
    </ThemeProvider>
  </OptionsProvider>,
  document.getElementById('root'),
);
serviceWorker.unregister();
