import { useMemo, useState } from 'react';

import { isValidEmail } from '../utils/emailUtils';

/**
 * Кастомных хук для работы с почтовым адресом
 * @param {String|Function} [initialValue=]
 * @return {[{valueValid: boolean, value: *},{setEmail: function(String): void, isValidEmail: function(String): boolean}]}
 */
const useEmail = (initialValue = '') => {

  const [state, setState] = useState(() => {
    if (typeof initialValue === 'function') {
      initialValue = initialValue();
    }

    return {
      value: initialValue,
      valueValid: isValidEmail(initialValue)
    }
  });

  const action = useMemo(() => {
    /**
     * Устанавливает почтовый адрес
     * @public
     * @version 1.4.0
     * @param {String} email
     * @constructor
     * @example
     * import { useEffect } from 'react';
     *
     * import { useEmail } from '@compassplus/ui-mobicash';
     *
     * const App = () => {
     *   const [email, { setEmail }] = useEmail();
     *
     *   useEffect(() => {
     *     console.log(email);
     *   }, [email]);
     *
     *   useEffect(() => {
     *     setEmail('i.petrikov@compassplus.com');
     *   }, [setEmail]);
     * }; */
    function setEmail(email) {
      setState((state) => {
        const nextState = {
          value: email,
          valueValid: isValidEmail(email)
        };

        if (nextState.value !== state.value) {
          return nextState;
        }

        return state;
      });
    }

    return { setEmail, isValidEmail };
  }, []);

  return [state, action];
};

export default useEmail;