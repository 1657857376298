import React from 'react';
import { useOptions } from '@compassplus/ui-mobicash';
import { ColorCircle, TableColor, TableSection } from './style';

const FirstColumnHeader = ({ attribute }) => {
  const isOptions = useOptions();
  if (attribute.code) {
    return (
      <td key={attribute.code}>
        <TableColor
          description={attribute.code}
          placeholder={attribute.title ? attribute.title[isOptions.language] : ''}
          leftElement={(
            <ColorCircle code={attribute.code} />
          )}
          readOnly
        />
      </td>
    );
  }
  return (
    <td>
      <TableSection
        title={attribute.title[isOptions.language]}
      />
    </td>
  );
};

export default FirstColumnHeader;
