import React, {useState} from 'react';
import { Text } from "../../../../components/TemplateStyle";
import i18n from '../../../../i18n';
import { SubContentStiky } from "../../ModifyAttributePage/style";
import {UiButton, UiCard, UiInputMobile, useMobile} from "@compassplus/ui-mobicash";
import OrdersRecordsStore from "../../../../stores/OrdersRecordsStore";
import {useHistory} from "react-router";

const OrderPhoneNumber = () => {
  const { modifyingOrder } = OrdersRecordsStore;
  const history = useHistory();

  const [phoneNumber, { setPhoneNumber }] = useMobile(modifyingOrder?.customer?.phoneNumber);

  const renderPhoneInput = () => (
    <UiInputMobile
      description={i18n.t('PhoneNumber')}
      state={phoneNumber}
      setState={setPhoneNumber}
      defaultValue={phoneNumber.value}
    />
  )

  return (
    <>
      <Text
        style={{ textDecoration: 'uppercase', fontWeight: 600 }}
        view="readonly"
        typography="note"
      >
        {i18n.t('PhoneNumber')}
      </Text>
      <UiCard
        style={{ margin: '0.5rem 0 0.25rem' }}
        shadow
      >
        {renderPhoneInput()}
      </UiCard>
      <SubContentStiky>
        <UiButton
          title={i18n.t('Save')}
          onClick={() => {
            OrdersRecordsStore.modifyCustomerData({ phoneNumber: phoneNumber.value, beautyPhone: phoneNumber.valueFormat });
            history.replace(`/modifyOrder/${modifyingOrder._id}`);
          }}
        />
      </SubContentStiky>
    </>
  )
}

export default OrderPhoneNumber