import PropTypes from 'prop-types';
import cn from 'classnames';
import styled from 'styled-components';

import { useOptions } from '../../../../../../context/OptionsProvider';
import { useTheme } from '../../../../../../context/ThemeProvider';

import { validateDateFormat } from '../../../../../../utils/dateUtils';

import styles from './DateComponent.module.css';

const StyledText = styled.span`
  ${ (props) => props.styles.typography.Remark };
  color: ${ (props) => props.styles.colors.textReadOnly };
`;

const DateComponent = ({
  className,
  text,
  date
}) => {

  const isOptions = useOptions();
  const isTheme = useTheme();

  return (
    <div className={ cn(styles.container, className) }>
      { text &&
        <StyledText
          className={ styles.text }
          styles={ isTheme.styles }
        >
          { text }
        </StyledText>
      }
      { date &&
        <StyledText
          className={ styles.date }
          styles={ isTheme.styles }
        >
          &nbsp;{ validateDateFormat(date, isOptions.language).string }
        </StyledText>
      }
    </div>
  );
};

DateComponent.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  text: PropTypes.string,
  date: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.number
  ])
};

export default DateComponent;