import { escapeRegExp } from './regExpUtils';

export function replaceDecimalSeparator(value = '', decimalSeparator = '.', isNegative = false) {
  if (decimalSeparator && decimalSeparator !== '.') {
    value = value.replace(RegExp(escapeRegExp(decimalSeparator), 'g'), '.');

    if (isNegative && decimalSeparator === '-') {
      value = `-${ value.slice(1) }`;
    }
  }

  return value;
}

export function removeInvalidChars(value = '', validChars = []) {
  const chars = escapeRegExp(validChars.join(''));
  const reg = new RegExp(`[^\\d${chars}]`, 'gi');

  return value.replace(reg, '');
}