import BottomDivider from '../../../../Functional/BottomDivider';

import styles from './MenuItem.module.css';

const MenuItem = ({ item, bottomDivider }) => {

  return (
    <>
      { item }
      { bottomDivider &&
        <BottomDivider className={ styles.divider } />
      }
    </>
  );
};

export default MenuItem;