import { makeAutoObservable } from 'mobx'
import DeliveryFormClass from "../controllers/delivery/DeliveryFormClass";
import {getAuth, getMerchantId, request} from "../Utils/requestUtil";

class DeliveryForms {
  forms = [];

  constructor() {
    this.getFormFromDB();
    makeAutoObservable(this);
  }

  getFormFromDB() {
    if (getAuth()) {
      fetch(`/api/v1/public/deliveryObj/${getMerchantId()}`, {
        method: 'GET'
      })
        .then((response) => response.json())
        .then((response) => {
          if (response) {
            this.setForms(response);
          }
        });
    }
  }

  setForms(forms) {
    this.forms = forms.map((form) => {
      return new DeliveryFormClass(form);
    });
  }
}

export default new DeliveryForms();