import React, { useReducer, useState } from 'react';
import { UiIcon } from '@compassplus/ui-mobicash';
import { observer } from 'mobx-react-lite';
import DropZone from 'react-dropzone';
import {
  AddCard, AddText, DeleteButton, ImageSubsection, MiniImageCard,
} from '../ImageUpload/styles';
import i18n from '../../../i18n';
import { ColumnButtonsDialog, DialogButton, MainImageIcon } from './style';
import { Dialog, DialogInput, ImageCover } from '../../TemplateStyle';
import ItemStore from '../../../stores/ItemStore';
import { DropzoneDiv } from '../../../Utils/UtilsStyle';
import MinusIcon from "../../icons/MinusIcon";

const MiniImageCards = observer(({ setSelectedImage }) => {
  const { images } = ItemStore;
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [isAddImageOpen, setIsAddImageOpen] = useState(false);
  const setImages = (value) => { ItemStore.images = value; };

  const [imageUpload, setImageUpload] = useState('');
  const [imageTypeUpload, setImageTypeUpload] = useState('');
  const [addByUrlOpen, setAddByUrlOpen] = useState(false);

  const changeImageUpload = (img, type) => {
    setImageUpload(img);
    setImageTypeUpload(type);
  };

  const onDelete = (index) => {
    const prevImages = images;
    prevImages.splice(index, 1);

    if (prevImages.length > 0 && !prevImages.find((el) => el.isMain)) {
      prevImages[0].isMain = true;
    }

    if (index >= 1) {
      setSelectedImage(index - 1);
    } else {
      setSelectedImage(0);
    }
    setImages(prevImages);

    forceUpdate();
  };

  const onOkButton = () => {
    const newImage = {
      url: imageUpload,
      type: imageTypeUpload,
      isMain: images.length === 0,
    };

    const prevImages = images;
    prevImages.push(newImage);
    setImages(prevImages);

    setAddByUrlOpen(false);
    forceUpdate();
  };

  const onDrop = (files) => {
    const file = files[0];
    changeImageUpload(file, file.type);

    const newImage = {
      url: file,
      type: file.type,
      isMain: images.length === 0,
    };

    const prevImages = images;
    prevImages.push(newImage);
    setImages(prevImages);

    setAddByUrlOpen(false);
    setIsAddImageOpen(false);
    forceUpdate();
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <AddCard onClick={() => { setIsAddImageOpen(true); }}>
        <UiIcon icon="legacy-plus" setWidth="3.2rem" setHeight="3.2rem" setColor="#D6D6D6" />
        <AddText>{i18n.t('Add')}</AddText>
      </AddCard>
      {
        images && images.length > 0 && (
          images.map((image, index) => (
            <MiniImageCard
              key={index}
              style={(((index - 2) % 4 === 0) || (index === 2)) ? { margin: '0.5rem 0 0' } : { margin: '0.5rem 0.5rem 0 0' }}
              onClick={() => { setSelectedImage(index); }}
            >
              <DeleteButton onClick={() => { onDelete(index); }}>
                <MinusIcon />
              </DeleteButton>
              {image.isMain && (<MainImageIcon setWidth="1rem" setHeight="1rem" view="brand" icon="apply" />)}
              <ImageCover
                src={image.type === 'url' || image.type === 'file' ? image.url : URL.createObjectURL(image.url)}
              />
            </MiniImageCard>
          ))
        )
      }
      {
        isAddImageOpen && (
          <ColumnButtonsDialog
            title={i18n.t('AddImage')}
            text={i18n.t('AddImageLink')}
            buttons={[
              {
                text: i18n.t('AddLink'),
                mode: '',
                action: () => { setAddByUrlOpen(true); setIsAddImageOpen(false); },
              },
              {
                text: i18n.t('Cancel'),
                mode: 'bold',
                action: () => { setIsAddImageOpen(false); },
              },
            ]}
          >
            <DropZone multiple={false} maxSize={8000000000} onDrop={onDrop} accept="image/*">
              {({ getRootProps, getInputProps }) => (
                <DropzoneDiv {...getRootProps()}>
                  <input {...getInputProps()} />
                  <DialogButton>
                    {i18n.t('AddFile')}
                  </DialogButton>
                </DropzoneDiv>
              )}
            </DropZone>
          </ColumnButtonsDialog>
        )
      }
      {
        (addByUrlOpen && (
          <Dialog
            title={i18n.t('AddImage')}
            text={i18n.t('AddImageLink')}
            buttons={[
              {
                text: i18n.t('Add'),
                mode: 'bold',
                action: () => { onOkButton(); },
              },
              {
                text: i18n.t('Cancel'),
                mode: '',
                action: () => { setAddByUrlOpen(false); },
              },
            ]}
          >
            <DialogInput onChange={(event) => changeImageUpload(event.currentTarget.value, 'url')} value={imageUpload} />
          </Dialog>
        ))
      }
    </div>
  );
});

export default MiniImageCards;
