import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import UiAction from '../../../UiAction';

import { useForkRef } from '../../../../../hooks';

import styles from './Action.module.css';

const Action = ({
  className,
  type,
  active,
  action,
  innerRef,
  ...props
}) => {

  const [top, setTop] = useState(-6);
  const actionRef = useRef(null);
  const refs = useForkRef([actionRef, innerRef]);

  useEffect(() => {
    if (actionRef.current) {
      if (active) {
        setTop(-(actionRef.current.clientHeight + 6));
      } else {
        setTop(-6);
      }
    }
  }, [active]);

  return (
    <UiAction
      { ...props }
      className={ cn(styles.action, className) }
      active={ active }
      onClick={ action }
      style={ { top } }
      innerRef={ refs }
    />
  );
};

Action.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Сделать компонент активным */
  active: PropTypes.bool,
  /** Обработчик действия (комбинирует обработчики onKeyUp (при нажатии на "Enter") и onClick) */
  action: PropTypes.func,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

export default Action;