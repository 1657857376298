import { useRef } from 'react';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../../../../../context/ThemeProvider';

import Input from '../../../../../../Functional/Input';
import { StyledBlockedInput, StyledInputBackground } from '../Styled';

import { useForkRef } from '../../../../../../../hooks';

import { isFunction } from '../../../../../../../utils/typeUtils';

const StyledCardholder = styled(Input)`
  ${ (props) => props.styles.typography.Keyboard }
  width: 100%;
  padding: 0.125rem 0.25rem;
  text-overflow: ellipsis;

  ${ (props) => {
    if (props.disabled) {
      return css`
        ${ StyledBlockedInput };
      `;
    } else {
      return css`
        ${ StyledInputBackground };
      `;
    }
  } }
`;

const Cardholder = ({
  disabled,
  innerRef,
  onBlur,
  ...props
}) => {

  const isTheme = useTheme();

  const cardholderRef = useRef(null);
  const refs = useForkRef([innerRef, cardholderRef]);

  const blurHandler = (event) => {
    const { target } = event;

    target.setSelectionRange(0, 0);

    if (isFunction(onBlur)) {
      onBlur(event);
    }
  };

  return (
    <StyledCardholder
      { ...props }
      type="text"
      disabled={ disabled }
      onBlur={ blurHandler }
      innerRef={ refs }
      styles={ isTheme.styles }
    />
  );
};

export default Cardholder;