import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useOptions } from '../../../context/OptionsProvider';

import UiInput from '../../Ui/UiInput';
import UiCalendar from '../../Ui/UiCalendar';

import { isValidDate, validateDate, validateDateFormat } from '../../../utils/dateUtils';

/**
 * Input date component
 * @public
 * @version 0.0.90
 * @param {String} [id]
 * @param {Object[]} [forms]
 * @param {String} forms[].text
 * @param {String} forms[].value
 * @param {Function} [setForm]
 * @param {Object} [state]
 * @param {String} state.text
 * @param {String} state.value
 * @param {Function} [setState]
 * @param {Date|String|Number|Function} [maxDate]
 * @param {Date|String|Number|Function} [minDate]
 * @param {String[]} [days]
 * @param {String[]} [months]
 * @param {Date|String|Number|Function} [defaultValue]
 * @param {'full'|'partial'} [record=full]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiInputDate />
 */
const UiInputDate = ({
  id,
  forms,
  setForm,
  state,
  setState,
  maxDate,
  minDate,
  days,
  months,
  defaultValue,
  record,
  ...props
}) => {

  const isOptions = useOptions();

  const [inputValue, setInputValue] = useState('');
  const [calendar, setCalendar] = useState(false);

  useEffect(() => {
    if (Array.isArray(forms) && typeof setForm === 'function' && !!id) {
      const form = forms.find((item) => item.id === id);

      if (typeof form === 'object' && form.text !== undefined && form.value !== undefined) {
        setInputValue(form.text);
      } else {
        if (isValidDate(defaultValue)) {
          const dateFormat = validateDateFormat(defaultValue, isOptions.language);
          const recordString = record === 'partial' ?  dateFormat.dateString : dateFormat.string;

          setForm(id, { text: recordString, value: validateDate(defaultValue) });
          setInputValue(recordString);
        } else {
          setForm(id, { text: '', value: '' });
          setInputValue('');
        }
      }
    } else if (typeof state === 'object' && typeof setState === 'function') {
      if (state.text !== undefined && state.value !== undefined) {
        setInputValue(state.text);
      } else {
        if (isValidDate(defaultValue)) {
          const dateFormat = validateDateFormat(defaultValue, isOptions.language);
          const recordString = record === 'partial' ?  dateFormat.dateString : dateFormat.string;

          setState({ text: recordString, value: validateDate(defaultValue) });
          setInputValue(recordString);
        } else {
          setState({ text: '', value: '' });
          setInputValue('');
        }
      }
    } else if (isValidDate(defaultValue)) {
      const dateFormat = validateDateFormat(defaultValue, isOptions.language);
      const recordString = record === 'partial' ?  dateFormat.dateString : dateFormat.string;

      setInputValue(recordString);
    } else {
      setInputValue('');
    }
  }, [id, forms, setForm, state, setState, defaultValue, isOptions.language, record]);

  return (
    <>
      <UiInput
        { ...props }
        active={ calendar }
        readOnly={ true }
        flag={ undefined }
        value={ inputValue }
        onClick={ () => setCalendar(true) }
      />
      <UiCalendar
        isOpened={ calendar }
        onClose={ () => setCalendar(false) }
        setDate={ (newDate) => {
          const dateFormat = validateDateFormat(newDate, isOptions.language);
          const recordString = record === 'partial' ?  dateFormat.dateString : dateFormat.string;

          if (typeof setForm === 'function') {
            setForm(id, { text: recordString, value: validateDate(newDate) });
          } else if (typeof setState === 'function') {
            setState({ text: recordString, value: validateDate(newDate) });
          } else {
            setInputValue(recordString);
          }
        } }
        maxDate={ maxDate }
        minDate={ minDate }
        days={ days }
        months={ months }
      />
    </>
  );
};

UiInputDate.propTypes = {
  /** Определяет уникальный идентификатор */
  id: PropTypes.string,
  /** Определяет массив объектов хука useForm (UI) */
  forms: PropTypes.array,
  /** Функция обработчик хука useForm (UI) */
  setForm: PropTypes.func,
  /** Определяет объект хука useState (React) */
  state: PropTypes.object,
  /** Функция обработчик хука useState (React) */
  setState: PropTypes.func,
  /** Определяет максимально допустимую дату (UiCalendar) */
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string, PropTypes.number, PropTypes.func]),
  /** Определяет минимально допустимую дату (UiCalendar) */
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string, PropTypes.number, PropTypes.func]),
  /** Массив лексем дней недели (понедельник-воскресенье) */
  days: PropTypes.arrayOf(PropTypes.string),
  /** Массив лексем месяцев (январь-декабрь) */
  months: PropTypes.arrayOf(PropTypes.string),
  /** Определяет значение по умолчанию */
  defaultValue: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string, PropTypes.number, PropTypes.func]),
  /** Определяет запись форматированной даты в объект с локализованными полями */
  record: PropTypes.oneOf(['full', 'partial'])
};

UiInputDate.defaultProps = {
  record: 'full'
};

export default UiInputDate;