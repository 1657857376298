import React from 'react';
import i18n from '../../../../i18n';
import { StyledSearchInput } from '../styles';
import AdminMarketStore from '../../../../stores/AdminMarketStore';

const SearchInput = ({
  allGroups, allItems, style,
}) => {
  const onSearchChange = (event) => {
    const searchString = event.target.value;
    if (searchString !== '') {
      let searchArr = searchString.split(' ');
      searchArr = searchArr.filter((el) => el.length > 1);

      const filteredItems = AdminMarketStore.allItems.filter((el) => {
        if (el.title.ru && el.title.en) {
          return searchArr.every((search) => el.title.ru.toLowerCase().includes(search.toLowerCase())
            || el.title.en.toLowerCase().includes(search.toLowerCase()));
        }

        return searchArr.every((search) => el.title.toLowerCase().includes(search.toLowerCase()));
      });

      const filteredGroups = AdminMarketStore.allGroups.filter((el) => {
        if (el.title.ru && el.title.en) {
          return searchArr.every((search) => el.title.ru.toLowerCase().includes(search.toLowerCase())
            || el.title.en.toLowerCase().includes(search.toLowerCase()));
        }

        return searchArr.every((search) => el.title.toLowerCase().includes(search.toLowerCase()));
      });

      AdminMarketStore.currentItems = filteredItems;
      AdminMarketStore.currentGroups = filteredGroups;
    } else {
      AdminMarketStore.currentGroups = allGroups;
      AdminMarketStore.currentItems = allItems;
    }
  };

  return (
    <StyledSearchInput
      placeholder={i18n.t('Search')}
      onChange={onSearchChange}
      style={style}
    />
  );
};

export default SearchInput;
