import PropTypes from 'prop-types';
import cn from 'classnames';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../context/ThemeProvider';

import styles from './Input.module.css';

const StyledInput = styled.input`
  ${ (props) => css`
    ${ props.styles.typography.SectionValue };
    color: ${ props.styles.colors.textLink };
    
    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${ props.styles.colors.textDisable };
    }
  
    :-moz-placeholder {
      color: ${ props.styles.colors.textDisable };
      opacity: 1;
    }
  
    ::-moz-placeholder {
      color: ${ props.styles.colors.textDisable };
      opacity: 1;
    }
  
    :-ms-input-placeholder {
      color: ${ props.styles.colors.textDisable };
    }
  `}

  ${ (props) => props.warning && css`
    color: ${ props.styles.colors.warningColor };
  ` }
`;

const Input = ({
  className,
  warning,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <StyledInput
      { ...props }
      className={ cn(styles.input, className) }
      warning={ warning }
      ref={ innerRef }
      styles={ isTheme.styles }
    />
  );
};

Input.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Предупреждение (текст окрашивается в предупреждающий цвет) */
  warning: PropTypes.bool,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

Input.defaultProps = {
  warning: false
};

export default Input;