import { makeAutoObservable } from 'mobx';
import DeliveryFormClass from '../controllers/delivery/DeliveryFormClass';

class DeliveryStore {
  constructor() {
    this.form = {};
    this.changedFlag = false;
    makeAutoObservable(this);
  }

  setForm(form) {
    this.form = new DeliveryFormClass(form);
  }

  clearForm() {
    this.form = new DeliveryFormClass({ type: 'Custom' });
  }
}

export default new DeliveryStore();
