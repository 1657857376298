import { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../context/ThemeProvider';

import Modal from '../../Functional/Modal';
import BackgroundModal from '../../Functional/BackgroundModal';
import UiCard from '../UiCard';
import UiIcon from '../UiIcon';
import UiText from '../UiText';
import UiSimpleButton from '../UiSimpleButton';

import styles from './UiDialog.module.css';

const StyledDialogContent = styled.div`
  ${ (props) => props.divider && css`
    border-top: 0.063rem solid ${ props.styles.colors.applicationBackground };
  ` };
`;

const StyledDialogActions = styled.div`
  ${ (props) => css`
    border-top: 0.063rem solid ${ props.styles.colors.applicationBackground };
  ` };

  ${ (props) => {
    if (props.direction === 'column') {
      return css`
        flex-direction: column;

        & > div {
          border-bottom: 0.063rem solid ${ props.styles.colors.applicationBackground };
        }

        & > div:last-child {
          border-bottom: none;
        }
      `;
    } else {
      return css`
        flex-direction: row;

        & > div {
          border-right: 0.063rem solid ${ props.styles.colors.applicationBackground };
        }

        & > div:last-child {
          border-right: none;
        }
      `;
    }
  } };
`;

/**
 * Dialog component
 * @public
 * @version 0.0.90
 * @param {String} [className]
 * @param {'normal'|'warning'} [mode=normal]
 * @param {String} [icon=security]
 * @param {String} [title]
 * @param {String} [text]
 * @param {Boolean} [divider=true]
 * @param {Object[]} [buttons]
 * @param {String} buttons[].text
 * @param {'normal'|'bold'} buttons[].mode
 * @param {Function} buttons[].action
 * @param {*} [children]
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiDialog />
 */
const UiDialog = ({
  className,
  mode,
  icon,
  title,
  text,
  divider,
  buttons,
  children
}) => {

  const isTheme = useTheme();

  const direction = useMemo(() => buttons?.length > 2 ? 'column' : 'row', [buttons]);

  return (
    <Modal>
      <BackgroundModal className={ cn(styles.container, className) }>
        <div className={ styles.content }>
          <div className={ styles.contentIcon }>
            <span className={ styles.contentIconBackground }>
              <svg className={ styles.iconBackground } viewBox="0 0 50 54" fill="none"
                xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_6371_11849)">
                <path
                  d="M4 18V32C4 34.8891 5.61306 37.5295 8.09756 39L20.3902 46C23.4049 47.3872 26.5951 47.3872 29.6098 46L41.9024 39C44.3869 37.5295 46 34.8891 46 32V18C46 15.1108 44.3868 12.4699 41.9024 11L29.6098 4C26.5951 2.61275 23.4049 2.61275 20.3902 4L8.09756 11C5.61321 12.4699 4 15.1108 4 18Z"
                  fill={ isTheme.styles.colors.sectionBackground } />
              </g>
              <defs>
                <filter id="filter0_d_6371_11849" x="0" y="0.959564" width="50" height="52.0809"
                  filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha" />
                  <feOffset dy="2" />
                  <feGaussianBlur stdDeviation="2" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6371_11849" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6371_11849" result="shape" />
                </filter>
              </defs>
            </svg>
            </span>
            <UiIcon
              className={ cn(styles.contentIconBackground, styles.icon) }
              setColor={ mode === 'warning' ? isTheme.styles.colors.warningColor : isTheme.styles.colors.readOnly }
              type="icon"
              icon={ icon }
            />
          </div>
          <UiCard className={ styles.body } shadow={ true }>
            <div className={ styles.bodyContent }>
              { title &&
                <UiText
                  className={ styles.title }
                  view={ mode === 'warning' ? mode : 'readonly' }
                  typography="dialog-title"
                  position="center"
                >
                  { title }
                </UiText>
              }

              { text &&
                <UiText
                  className={ styles.text }
                  view={ mode === 'warning' ? mode : 'readonly' }
                  typography="dialog-text"
                  position="center"
                >
                  { text }
                </UiText>
              }
            </div>
            { children &&
              <StyledDialogContent
                className={ styles.children }
                divider={ divider }
                styles={ isTheme.styles }
              >
                { children }
              </StyledDialogContent>
            }
            { buttons &&
              <StyledDialogActions
                className={ styles.actions }
                direction={ direction }
                styles={ isTheme.styles }
              >
                { buttons.map((button, index) => {
                  return <div className={ styles.action } key={ index }>
                    <UiSimpleButton
                      key={ index }
                      mode={ button.mode }
                      title={ button.text }
                      onClick={ !!button.action ? (event) => button.action(event) : undefined }
                    />
                  </div>;
                }) }
              </StyledDialogActions>
            }
          </UiCard>
        </div>
      </BackgroundModal>
    </Modal>
  );
};

UiDialog.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Определяет цвет отображения */
  mode: PropTypes.oneOf(['normal', 'warning']),
  /** Определяет иконку */
  icon: PropTypes.string,
  /** Определяет заголовок диалогового окна */
  title: PropTypes.string,
  /** Определяет текст диалогового окна */
  text: PropTypes.string,
  /** Добавляет разделительную линию между дефолтным и пользовательским контентом */
  divider: PropTypes.bool,
  /** Определяет набор кнопок-действий */
  buttons: PropTypes.arrayOf(PropTypes.shape({
    /** Текст кнопки */
    text: PropTypes.string,
    /** Определяет выделение текста кнопки */
    mode: PropTypes.oneOf(['normal', 'bold']),
    /** Функция обработчик при нажатии на кнопку */
    action: PropTypes.func
  })),
  /** Пользовательский контент */
  children: PropTypes.any
};

UiDialog.defaultProps = {
  mode: 'normal',
  icon: 'security',
  divider: true
};

export default UiDialog;