import styled from 'styled-components';

export const Breadcrumbs = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
`;

export const BreadcrumbLink = styled.a`
  text-decoration: none;
  color: #037AFF;
  font-weight: 400;
  font-size: 0.75rem;
  cursor: pointer;
`

export const BreadcrumbSeparator = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  color: #7F7F7F;
  margin: 0 0.5rem;
`;

export const BreadcrumbText = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  color: #7F7F7F;
`;

