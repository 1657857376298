import { Children, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import cn from 'classnames';

import { useTheme } from '../../../context/ThemeProvider';

import UiQuestions from '../UiQuestions';
import Element from '../../Functional/Element';

import styles from './UiStepper.module.css';

const StyledHead = styled.div`
  &:after, &:before {
    background: ${ (props) => props.styles.colors.applicationPrimaryColor };
  }
`;

const StyledLabel = styled.span`
  ${ (props) => css`
    ${ props.styles.typography.Badge };
  ` };

  ${ (props) => {
    if (props.status === 'active') {
      return css`
        color: ${ props.styles.colors.sectionBackground };
        background: ${ props.styles.colors.applicationPrimaryColor };
        border: 0.063rem solid ${ props.styles.colors.applicationPrimaryColor };
      `;
    } else {
      return css`
        color: ${ props.styles.colors.applicationPrimaryColor };
        background: ${ props.styles.colors.applicationBackground };
        border: 0.063rem solid ${ props.styles.colors.applicationPrimaryColor };
      `;
    }
  } };
`;

/**
 * Stepper component
 * @public
 * @version 0.0.90
 * @param {String} className
 * @param {Number} [activeIndex=0]
 * @param {Boolean} [help=false]
 * @param {Function} onHelp
 * @param {Function|Object} [innerRef]
 * @param {*} [children=<></><></>]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiStepper>{ children }</UiStepper>
 */
const UiStepper = ({
  className,
  activeIndex,
  help,
  onHelp,
  innerRef,
  children,
  ...props
}) => {

  const isTheme = useTheme();

  const [isActiveIndex, setIsActiveIndex] = useState(0);
  const StepperRef = useRef(null);
  const ListRef = useRef(null);

  useEffect(() => {
    setIsActiveIndex(activeIndex);
  }, [activeIndex]);

  const handlerResize = useCallback(() => {
    const listHeight = ListRef.current.clientHeight;
    const activeStep = ListRef.current.children[isActiveIndex];
    const activeContent = activeStep?.children[1];

    if (activeContent) {
      const activeContentHeight = activeContent.clientHeight + 8;

      StepperRef.current.style.setProperty('height', `${ listHeight + activeContentHeight }px`);
    }
  }, [isActiveIndex]);

  useEffect(() => {
    const mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach(() => {
        handlerResize();
      });
    });

    mutationObserver.observe(ListRef.current, {
      attributes: true,
      characterData: true,
      childList: true,
      subtree: true,
      attributeOldValue: false,
      characterDataOldValue: false
    });

    handlerResize();

    return () => {
      mutationObserver.disconnect();
    }
  }, [handlerResize]);

  return (
    <div
      { ...props }
      className={ cn(styles.stepper, className) }
      ref={ StepperRef }
    >
      <ul className={ styles.list } ref={ ListRef }>
        { Children.toArray(children).map((item, index) => {
          let status = 'inactive';

          if (isActiveIndex > index) {
            status = 'completed';
          } else if (isActiveIndex === index) {
            status = 'active';
          }

          return <li key={ `stepper-step-${ index }` } className={ styles.step }>
            <StyledHead
              className={ styles.head }
              styles={ isTheme.styles }
            >
              { status === 'completed' ?
                <Element
                  className={ styles.default }
                  color={ isTheme.styles.colors.applicationPrimaryColor }
                  width="1rem"
                  height="1rem"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8ZM15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8ZM11.9884 5.40388L5.81818 11.5232L2.92064 8.64956L3.62481 7.93953L5.83351 10.13L11.2843 4.69385L11.9884 5.40388Z" />
                </Element>
                :
                <StyledLabel
                  className={ cn(styles.label, styles.default) }
                  status={ status }
                  styles={ isTheme.styles }
                >
                  { index + 1 }
                </StyledLabel>
              }
            </StyledHead>
            { status === 'active' &&
              <div className={ styles.content }>
                { item }
              </div>
            }
          </li>;
        }) }
      </ul>
      { help &&
        <UiQuestions
          className={ styles.help }
          onClick={ onHelp }
        />
      }
    </div>
  );
};

UiStepper.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Определяет индекс активного шага */
  activeIndex: PropTypes.number,
  /** Добавление кнопки помощи */
  help: PropTypes.bool,
  /** Обработчик клика на кнопку помощи */
  onHelp: PropTypes.func,
  /** Пользовательский контент */
  children: function (props, propName, componentName) {
    const contentLength = Children.toArray(props.children).length;
    if (contentLength < 2) {
      return new Error(`Invalid prop "${ propName }" of length "${ contentLength }" supplied to "${ componentName }", expected "length > 2".`);
    }
  },
}

UiStepper.defaultProps = {
  activeIndex: 0,
  help: false,
  onHelp: () => {},
  children: [
    <></>,
    <></>
  ]
}

export default UiStepper;