import { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Modal from '../../Functional/Modal';
import BackgroundModal from '../../Functional/BackgroundModal';
import UiCard from '../UiCard';
import DaysCalendar from './Components/DaysCalendar';
import MonthsCalendar from './Components/MonthsCalendar';

import useDate from '../../../hooks/use-date';

import { dateParser, getInformationFromDate } from '../../../utils/dateUtils';

import { calendarTypes } from '../../../constants';

import styles from './UiCalendar.module.css';

/**
 * Компонент календаря
 * @public
 * @version 0.0.90
 * @param {String} [className]
 * @param {Boolean} [isOpened=false]
 * @param {Date|String|Number|Function} [date]
 * @param {Function} [setDate]
 * @param {Date|String|Number|Function} [maxDate]
 * @param {Date|String|Number|Function} [minDate]
 * @param {String[]} [days=['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']]
 * @param {String[]} [months=['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']]
 * @param {Function} [onClose]
 * @return {JSX.Element|null}
 * @constructor
 * @example
 * import { useState } from 'react';
 *
 * import { UiCalendar, useDate } from '@compassplus/ui-mobicash';
 *
 * const Calendar = () => {
 *   const [date, { setDate }] = useDate();
 *   const [show, setShow] = useState(true);
 *
 *   return (
 *     <UiCalendar
 *       isOpened={ show }
 *       date={ date }
 *       setDate={ setDate }
 *       onClose={ () => setShow(false) }
 *     />
 *   );
 * };
 *
 * export default Calendar; */
const UiCalendar = ({
  className,
  isOpened,
  date,
  setDate,
  maxDate,
  minDate,
  days,
  months,
  onClose
}) => {

  const [calendar, setCalendar] = useState(calendarTypes.DAYS);
  const [value, { setDate: setValue }] = useDate(date);
  const dateInformation = useMemo(() => getInformationFromDate(date), [date]);
  const minValue = useMemo(() => dateParser(minDate), [minDate]);
  const maxValue = useMemo(() => dateParser(maxDate), [maxDate]);
  const valueInformation = useMemo(() => {
    if (value.date.valueOf() <= minValue.valueOf()) {
      return getInformationFromDate(minValue);
    } else if (value.date.valueOf() >= maxValue.valueOf()) {
      return getInformationFromDate(maxValue);
    } else {
      return getInformationFromDate(value.date)
    }
  }, [value.date, minValue, maxValue]);
  const calendarRef = useRef();

  useEffect(() => {
    setValue(date);
  }, [date, setValue]);

  if (!isOpened) return null;

  const closeHandler = (event) => {
    if (calendarRef.current === event.target) {
      if (typeof onClose === 'function') {
        onClose();
      }

      if (calendar === calendarTypes.MONTHS) {
        setCalendar(calendarTypes.DAYS);
      }
    }
  };

  const commonProps = {
    valueInformation,
    minValue,
    maxValue,
    calendar,
    setCalendar,
    months,
    setValue
  };

  return (
    <Modal>
      <BackgroundModal className={ cn(styles.container, className) } onClick={ closeHandler } innerRef={ calendarRef }>
        <UiCard className={ styles.content } shadow={ true }>
          <MonthsCalendar
            { ...commonProps }
          />
          <DaysCalendar
            { ...commonProps }
            dateInformation={ dateInformation }
            days={ days }
            setDate={ setDate }
            onClose={ onClose }
          />
        </UiCard>
      </BackgroundModal>
    </Modal>
  );
};

UiCalendar.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Состояние отображения */
  isOpened: PropTypes.bool,
  /** Исходное значение даты */
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string, PropTypes.number, PropTypes.func]),
  /** Обработчик изменения даты */
  setDate: PropTypes.func,
  /** Максимальное значение даты */
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string, PropTypes.number, PropTypes.func]),
  /** Минимальное значение даты */
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string, PropTypes.number, PropTypes.func]),
  /** Массив лексем дней недели (понедельник-воскресенье) */
  days: PropTypes.arrayOf(PropTypes.string),
  /** Массив лексем месяцев (январь-декабрь) */
  months: PropTypes.arrayOf(PropTypes.string),
  /** Обработчик закрытия */
  onClose: PropTypes.func
};

UiCalendar.defaultProps = {
  isOpened: false,
  date: new Date(),
  days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  maxDate: new Date(new Date().getFullYear(), 11, 31),
  minDate: '1970, 1, 1'
};

export default UiCalendar;