import { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import FCell from '../../Functional/FKeyboard/FCell';
import FBackspace from '../../Functional/FKeyboard/FBackspace';

import { cleanValue } from '../../../utils/amountUtils';
import { isFunction } from '../../../utils/typeUtils';

import styles from './UiNumPad.module.css';

/**
 * Компонент цифровой клавиатуры
 * @public
 * @version 1.7.0
 * @param {String} [className]
 * @param {String} [userValue:value='']
 * @param {Function} [onValueChange]
 * @param {Number} [maxLength]
 * @param {Boolean} [allowDecimals=true]
 * @param {Number} [decimalsLimit=2]
 * @param {'.'|','} [decimalSeparator]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiNumPad />
 */
const UiNumPad = ({
  className,
  value: userValue,
  onValueChange,
  maxLength,
  allowDecimals,
  decimalsLimit,
  decimalSeparator,
  innerRef,
  ...props
}) => {

  const keys = useMemo(() => {
    return [
      ['1', '2', '3'],
      ['4', '5', '6'],
      ['7', '8', '9'],
      [decimalSeparator, '0', undefined]
    ];
  }, [decimalSeparator]);

  const cleanValueOptions = {
    allowDecimals,
    allowNegativeValue: false,
    decimalsLimit: decimalsLimit,
    decimalSeparator,
    groupSeparator: ''
  };

  const clickHandler = (V) => {
    const value = `${ userValue }${ V }`;
    let stringValue = cleanValue({ value, ...cleanValueOptions });

    if (stringValue === decimalSeparator) {
      stringValue = `0${ stringValue }`;
    }

    if (maxLength && stringValue.length > maxLength) {
      return;
    }

    if (isFunction(onValueChange)) {
      onValueChange(stringValue);
    }
  };

  const backspaceHandler = () => {
    let value = userValue.slice(0, -1);

    if (value.slice(-1) === decimalSeparator) {
      value = value.slice(0, -1);
    }

    if (isFunction(onValueChange)) {
      onValueChange(value);
    }
  };

  return (
    <div { ...props } className={ cn(styles.container, className) } ref={ innerRef }>
      { keys.map((row) => {
        return row.map((column) => {
          if (column === undefined) {
            return null;
          }

          const disabled = !allowDecimals ? column === decimalSeparator : false;

          return (
            <FCell
              key={ column }
              text={ column }
              disabled={ disabled }
              onClick={ () => clickHandler(column) }
            />
          );
        });
      }) }
      <FBackspace
        disabled={ !userValue }
        onClick={ backspaceHandler }
      />
    </div>
  );
};

UiNumPad.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Значение числа */
  value: PropTypes.string,
  /** Обработчик изменения значения */
  onValueChange: PropTypes.func,
  /** Максимальное количество символов, которое может ввести пользователь */
  maxLength: PropTypes.number,
  /** Разрешить десятичные дроби */
  allowDecimals: PropTypes.bool,
  /** Ограничить допустимую длину десятичных знаков */
  decimalsLimit: PropTypes.number,
  /** Разделитель между целой частью и дробной частью значения */
  decimalSeparator: PropTypes.oneOf(['.', ',']),
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

UiNumPad.defaultProps = {
  value: '',
  allowDecimals: true,
  decimalsLimit: 2,
  decimalSeparator: '.'
};

export default UiNumPad;