import { useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../../../context/ThemeProvider';

import Path from './Components/Path';
import PathWithShadow from './Components/PathWithShadow';

import { isFunction } from '../../../../../utils/typeUtils';

const StyledG = styled.g`
  ${ (props) => {
    if (props.disabled) {
      return css`
        opacity: 0.2;
      `;
    } else {
      return css`
        cursor: pointer;
      `;
    }
  } }
`;

const StyledText = styled.text`
  ${ (props) => props.styles.fonts.regular }
  font-size: 26px;
  line-height: 31px;
`;

const Cell = ({
  mode,
  active,
  disabled,
  C,
  V,
  position,
  coordinates,
  value: userValue,
  onValueChange,
  maxLength,
  cells,
  setCells,
  stackCells,
  setStackCells,
  ...props
}) => {

  const isTheme = useTheme();

  const [moving, setMoving] = useState(false);
  const gRef = useRef(null);

  const moveHandler = (event) => {
    if (gRef.current) {
      const element = gRef.current;
      const svg = element.parentElement;
      const parentBoundingClientRect = svg.getBoundingClientRect();
      const transform = element.style.transform;

      setMoving(true);
      svg.append(element);
      moveAt(event);

      function moveAt(event) {
        const X = (event.type === 'touchmove' ? event.touches[0].pageX : event.pageX) - parentBoundingClientRect.x - 28;
        const Y = (event.type === 'touchmove' ? event.touches[0].pageY : event.pageY) - parentBoundingClientRect.y - 23;

        element.style.transform = `translate(${ X }px, ${ Y }px)`;
      }

      function onMove(event) {
        if (event.cancelable) {
          event.preventDefault();
          moveAt(event);
        }
      }

      function onUp(event) {
        element.style.transform = transform;

        let foundParentElement;

        if (event.type === 'touchend') {
          foundParentElement = document.elementFromPoint(event.changedTouches[0].pageX, event.changedTouches[0].pageY)?.parentElement;
        } else {
          foundParentElement = document.elementFromPoint(event.pageX, event.pageY)?.parentElement;
        }

        if (foundParentElement && svg.contains(foundParentElement) && foundParentElement.localName === 'g' && !element.contains(foundParentElement)) {
          const eRIndex = Number(element.dataset.row);
          const eCIndex = Number(element.dataset.column);
          const fRIndex = Number(foundParentElement.dataset.row);
          const fCIndex = Number(foundParentElement.dataset.column);

          setStackCells(stackCells.concat([cells]));
          setCells((cells) => {
            const leftValue = cells[eRIndex][eCIndex];
            const rightValue = cells[fRIndex][fCIndex];

            return cells.map((row, rIndex) => {
              return row.map((column, cIndex) => {
                if (rIndex === eRIndex && cIndex === eCIndex) {
                  column = {
                    ...rightValue,
                    position: column.position,
                    coordinates: column.coordinates
                  };
                }

                if (rIndex === fRIndex && cIndex === fCIndex) {
                  column = {
                    ...leftValue,
                    position: column.position,
                    coordinates: column.coordinates
                  };
                }

                return column;
              });
            });
          });
        }

        setMoving(false);

        if (event.type === 'touchend') {
          document.removeEventListener('touchmove', onMove);
          document.removeEventListener('touchend', onUp);
        } else {
          document.removeEventListener('mousemove', onMove);
          document.removeEventListener('mouseup', onUp);
        }
      }

      if (event.type === 'touchstart') {
        document.addEventListener('touchmove', onMove, { passive: false });
        document.addEventListener('touchend', onUp);
      } else {
        document.addEventListener('mousemove', onMove);
        document.addEventListener('mouseup', onUp);
      }
    }
  };

  const downHandler = () => {
    let [value = '', hash = ''] = userValue.split('/');
    const hashes = hash.match(/.{1,2}/g) || [];

    if (maxLength && value.length > maxLength) {
      return;
    }

    const element = gRef.current;
    const svg = element.parentElement;
    const transform = element.style.transform;

    element.style.transform = `translate(${ coordinates.x - 4 }px, ${ coordinates.y }px)`;
    svg.append(element);
    setMoving(true);

    const upHandler = () => {
      element.style.transform = transform;

      if (isFunction(onValueChange) && !hashes.includes(position)) {
        value = `${ value + V }/${ hash + position }`;
        onValueChange(value === '/' ? '' : value);
      }

      setMoving(false);

      document.removeEventListener('mouseup', upHandler);
    };

    document.addEventListener('mouseup', upHandler);
  };

  const textProps = {
    x: moving ? 24.93335 : 20.9417,
    y: 32.25,
    styles: isTheme.styles
  }

  return (
    <StyledG
      { ...props }
      disabled={ disabled }
      onMouseDown={ active ? moveHandler : mode === 'default' ? downHandler : undefined }
      onTouchStart={ active ? moveHandler : undefined }
      style={ { transform: `translate(${ coordinates.x }px, ${ coordinates.y }px)` } }
      ref={ gRef }
    >
      { moving ?
        <PathWithShadow
          fill={ disabled ? isTheme.styles.colors.textDisable : isTheme.styles.colors.memPad.colors[C] }
        />
        :
        <Path
          fill={ disabled ? isTheme.styles.colors.textDisable : isTheme.styles.colors.memPad.colors[C] }
        />
      }
      <StyledText { ...textProps }>
        { V }
      </StyledText>
    </StyledG>
  );
};

export default Cell;