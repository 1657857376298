import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Иконка оформления доставки
 * @public
 * @version 1.5.0
 * @param {String} [width="3.25rem"]
 * @param {String} [height="3.25rem"]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * import { UiPickupIcon, UiPlates, UiPlate } from '@compassplus/ui-mobicash';
 *
 * const App = () => {
 *
 *   return (
 *     <>
 *       <UiPickupIcon />
 *       <UiPlates>
 *         <UiPlate view="2x1" image={ <UiPickupIcon width="100%" height="100%" /> } />
 *       </UiPlates>
 *     </>
 *   );
 * }; */
const UiPickupIcon = ({
  width,
  height,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      innerRef={ innerRef }
      width={ width }
      height={ height }
      viewBox="0 0 52 52"
    >
      <mask id="mask0_82_15497" style={ { maskType: 'alpha' } } maskUnits="userSpaceOnUse" x="8" y="29" width="37" height="17">
        <path d="M8 29.3313H44.5826V45.7613H8V29.3313Z" fill={ isTheme.styles.colors.sectionBackground } />
      </mask>
      <g mask="url(#mask0_82_15497)">
        <path fillRule="evenodd" clipRule="evenodd"
          d="M22.191 30.2153C23.178 30.2153 23.977 30.5543 23.977 30.9733H20.406C20.406 30.5543 21.205 30.2153 22.191 30.2153ZM43.034 39.5233H36.382V32.1833C36.382 31.5153 35.106 30.9733 33.531 30.9733H29.805C29.781 30.6483 29.157 30.3863 28.385 30.3863H25.971C25.869 30.3863 25.779 30.3603 25.738 30.3203C25.143 29.7383 23.779 29.3313 22.191 29.3313C20.603 29.3313 19.239 29.7383 18.645 30.3203C18.604 30.3603 18.514 30.3863 18.412 30.3863H15.997C15.225 30.3863 14.601 30.6483 14.577 30.9733H10.851C9.276 30.9733 8 31.5153 8 32.1833V43.6593C8 44.3273 9.276 44.8693 10.851 44.8693H27.962V45.1043C27.962 45.4663 28.656 45.7613 29.511 45.7613H43.034C43.89 45.7613 44.583 45.4663 44.583 45.1043V40.1803C44.583 39.8173 43.89 39.5233 43.034 39.5233Z"
          fill={ isTheme.styles.colors.textDisable } />
      </g>
      <path fillRule="evenodd" clipRule="evenodd"
        d="M27.9541 9.93121H33.2581C34.7571 9.93121 35.9731 11.1472 35.9731 12.6462V38.3892C35.9731 39.8892 34.7571 41.1042 33.2581 41.1042H11.6571C10.1581 41.1042 8.94214 39.8892 8.94214 38.3892V12.6462C8.94214 11.1472 10.1581 9.93121 11.6571 9.93121H16.2301"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M33.2579 41.6045H11.6569C9.88487 41.6045 8.44287 40.1625 8.44287 38.3895V12.6465C8.44287 10.8735 9.88487 9.43149 11.6569 9.43149H16.2309V10.4315H11.6569C10.4359 10.4315 9.44287 11.4245 9.44287 12.6465V38.3895C9.44287 39.6105 10.4359 40.6045 11.6569 40.6045H33.2579C34.4789 40.6045 35.4729 39.6105 35.4729 38.3895V12.6465C35.4729 11.4245 34.4789 10.4315 33.2579 10.4315H27.9539V9.43149H33.2579C35.0299 9.43149 36.4729 10.8735 36.4729 12.6465V38.3895C36.4729 40.1625 35.0299 41.6045 33.2579 41.6045Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M30.6369 37.0347H14.2789C13.2379 37.0347 12.3909 36.1877 12.3909 35.1477V14.9487C12.3909 13.9077 13.2379 13.0607 14.2789 13.0607C14.4169 13.0607 14.5289 13.1727 14.5289 13.3107C14.5289 13.4497 14.4169 13.5607 14.2789 13.5607C13.5129 13.5607 12.8909 14.1827 12.8909 14.9487V35.1477C12.8909 35.9127 13.5129 36.5347 14.2789 36.5347H30.6369C31.4019 36.5347 32.0239 35.9127 32.0239 35.1477V14.9487C32.0239 14.1827 31.4019 13.5607 30.6369 13.5607C30.4989 13.5607 30.3869 13.4497 30.3869 13.3107C30.3869 13.1727 30.4989 13.0607 30.6369 13.0607C31.6779 13.0607 32.5239 13.9077 32.5239 14.9487V35.1477C32.5239 36.1877 31.6779 37.0347 30.6369 37.0347Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M17.7833 22.9102C17.6103 22.9102 17.4483 22.8202 17.3573 22.6722L16.2383 20.8512C16.0933 20.6172 16.1673 20.3082 16.4023 20.1642C16.6363 20.0182 16.9453 20.0922 17.0903 20.3282L17.7483 21.3982L19.2133 18.5952C19.3413 18.3502 19.6433 18.2552 19.8873 18.3832C20.1333 18.5112 20.2273 18.8132 20.0993 19.0572L18.2263 22.6412C18.1433 22.8012 17.9803 22.9042 17.8013 22.9102C17.7953 22.9102 17.7893 22.9102 17.7833 22.9102Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M22.4577 11.6318C21.5187 11.6318 20.7577 10.8708 20.7577 9.93079C20.7577 8.99279 21.5187 8.23079 22.4577 8.23079C23.3967 8.23079 24.1587 8.99279 24.1587 9.93079C24.1587 10.8708 23.3967 11.6318 22.4577 11.6318ZM26.0577 8.61579C25.9597 8.61579 25.8747 8.55679 25.8357 8.46679C25.2697 7.16179 23.9707 6.24979 22.4577 6.24979C20.9447 6.24979 19.6457 7.16179 19.0807 8.46679C19.0407 8.55679 18.9567 8.61579 18.8577 8.61579H16.5587C15.8097 8.61579 15.2017 9.22379 15.2017 9.97279V15.1948C15.2017 15.3328 15.3137 15.4448 15.4517 15.4448H29.4637C29.6017 15.4448 29.7127 15.3328 29.7127 15.1948V9.97279C29.7127 9.22379 29.1057 8.61579 28.3557 8.61579H26.0577Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M22.4577 8.481C21.6577 8.481 21.0077 9.131 21.0077 9.931C21.0077 10.731 21.6577 11.382 22.4577 11.382C23.2577 11.382 23.9087 10.731 23.9087 9.931C23.9087 9.131 23.2577 8.481 22.4577 8.481ZM22.4577 11.882C21.3827 11.882 20.5077 11.007 20.5077 9.931C20.5077 8.856 21.3827 7.981 22.4577 7.981C23.5337 7.981 24.4087 8.856 24.4087 9.931C24.4087 11.007 23.5337 11.882 22.4577 11.882ZM16.5587 8.866C15.9487 8.866 15.4517 9.363 15.4517 9.973V15.195H29.4637L29.4627 9.973C29.4627 9.363 28.9667 8.866 28.3557 8.866H26.0577C25.8627 8.866 25.6867 8.749 25.6067 8.566C25.0617 7.311 23.8257 6.5 22.4577 6.5C21.0897 6.5 19.8537 7.311 19.3097 8.566C19.2297 8.749 19.0537 8.866 18.8577 8.866H16.5587ZM29.4637 15.695H15.4517C15.1757 15.695 14.9517 15.471 14.9517 15.195V9.973C14.9517 9.087 15.6727 8.366 16.5587 8.366H18.8577C19.4747 6.929 20.8907 6 22.4577 6C24.0237 6 25.4397 6.929 26.0647 8.366H28.3557C29.2427 8.366 29.9627 9.087 29.9627 9.973V15.195C29.9627 15.471 29.7387 15.695 29.4637 15.695Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M28.2301 21.8311H22.3091C22.0331 21.8311 21.8091 21.6071 21.8091 21.3311C21.8091 21.0551 22.0331 20.8311 22.3091 20.8311H28.2301C28.5061 20.8311 28.7301 21.0551 28.7301 21.3311C28.7301 21.6071 28.5061 21.8311 28.2301 21.8311Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M17.7935 29.7197C17.6205 29.7197 17.4585 29.6297 17.3675 29.4817L16.2495 27.6607C16.1045 27.4257 16.1775 27.1177 16.4135 26.9737C16.6475 26.8277 16.9555 26.9017 17.1005 27.1377L17.7585 28.2077L19.2235 25.4047C19.3515 25.1587 19.6545 25.0647 19.8975 25.1927C20.1435 25.3207 20.2375 25.6227 20.1095 25.8667L18.2365 29.4507C18.1535 29.6107 17.9905 29.7137 17.8115 29.7197C17.8055 29.7197 17.7995 29.7197 17.7935 29.7197Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M28.2408 28.6406H22.3198C22.0428 28.6406 21.8198 28.4166 21.8198 28.1406C21.8198 27.8646 22.0428 27.6406 22.3198 27.6406H28.2408C28.5168 27.6406 28.7408 27.8646 28.7408 28.1406C28.7408 28.4166 28.5168 28.6406 28.2408 28.6406Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M29.4289 29.1108H42.3079C43.1219 29.1108 43.7829 29.7708 43.7829 30.5858V41.6298C43.7829 42.4438 43.1219 43.1048 42.3079 43.1048H29.4289C28.6149 43.1048 27.9539 42.4438 27.9539 41.6298V30.5858C27.9539 29.7708 28.6149 29.1108 29.4289 29.1108Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M29.4289 29.3608C28.7539 29.3608 28.2039 29.9098 28.2039 30.5858V41.6298C28.2039 42.3048 28.7539 42.8548 29.4289 42.8548H42.3079C42.9829 42.8548 43.5329 42.3048 43.5329 41.6298V30.5858C43.5329 29.9098 42.9829 29.3608 42.3079 29.3608H29.4289ZM42.3079 43.3548H29.4289C28.4779 43.3548 27.7039 42.5808 27.7039 41.6298V30.5858C27.7039 29.6338 28.4779 28.8608 29.4289 28.8608H42.3079C43.2589 28.8608 44.0329 29.6338 44.0329 30.5858V41.6298C44.0329 42.5808 43.2589 43.3548 42.3079 43.3548Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M33.6437 36.5674L35.5667 35.1174C35.7437 34.9844 35.9937 34.9844 36.1697 35.1174L38.0927 36.5674C38.4107 36.8074 38.8797 36.5914 38.8797 36.2044V29.3314H32.8567V36.2054C32.8567 36.5914 33.3257 36.8074 33.6437 36.5674Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M40.7285 33.6416C40.5905 33.6416 40.4785 33.5296 40.4785 33.3916V30.5946C40.4785 30.4556 40.5905 30.3446 40.7285 30.3446C40.8665 30.3446 40.9785 30.4556 40.9785 30.5946V33.3916C40.9785 33.5296 40.8665 33.6416 40.7285 33.6416Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M42.312 36.918C42.174 36.918 42.062 36.806 42.062 36.668V30.594C42.062 30.456 42.174 30.344 42.312 30.344C42.45 30.344 42.562 30.456 42.562 30.594V36.668C42.562 36.806 42.45 36.918 42.312 36.918Z"
        fill={ isTheme.styles.colors.textTitle } />
    </Element>
  );
};

UiPickupIcon.propTypes = {
  /** Определяет ширину иконки */
  width: PropTypes.string,
  /** Определяет высоту иконки */
  height: PropTypes.string
};

UiPickupIcon.defaultProps = {
  width: '3.25rem',
  height: '3.25rem'
};

export default UiPickupIcon;