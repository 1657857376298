import AddressData from './AddressData';
import Field from './Field';
import DateData from './DateData';
import DeliveryCostData from './DeliveryCostData';
import DeliveryStore from "../../stores/DeliveryStore";

const deliveryTypes = [
  'Pickup',
  'Courier',
  'Post',
  'TransportCompany',
  'Custom',
];

const attributesTypes = [
  {
    id: 0,
    type: 'PersonalData',
  },
  {
    id: 1,
    type: 'Birthday',
  },
  {
    id: 2,
    type: 'Phone',
  },
  {
    id: 3,
    type: 'ClientAddress',
  },
  {
    id: 4,
    type: 'CostOfDelivery',
  },
  {
    id: 5,
    type: 'DeliveryDate',
  },
  {
    id: 6,
    type: 'EntryField',
  },
];

const arrayMove = (arr, index, indexTo) => {
  const elem = arr[index];
  arr.splice(index, 1);
  arr.splice(indexTo, 0, elem);
}

class DeliveryFormClass {
  constructor(newDeliveryForm) {
    this._id = (newDeliveryForm._id !== undefined) ? newDeliveryForm._id : undefined;
    this.active = (newDeliveryForm.active !== undefined) ? newDeliveryForm.active : true;
    this.title = (newDeliveryForm.title !== undefined) ? newDeliveryForm.title : { ru: '', en: '' };
    this.type = (newDeliveryForm.type !== undefined) ? newDeliveryForm.type : deliveryTypes[4];
    this.description = (newDeliveryForm.description !== undefined) ? newDeliveryForm.description : { ru: '', en: '' };
    this.personalData = (newDeliveryForm.personalData !== undefined) ? this.setPersonalData(newDeliveryForm.personalData) : this.setPersonalData([
      { title: { ru: 'Фамилия', en: 'Surname' }, required: true },
      { title: { ru: 'Имя', en: 'Name' }, required: true },
      { title: { ru: 'Отчество', en: 'Patronymic' }, required: false },
    ]);
    this.addressData = (newDeliveryForm.addressData !== undefined) ? new AddressData(newDeliveryForm.addressData) : new AddressData({});
    this.date = (newDeliveryForm.date !== undefined) ? new DateData(newDeliveryForm.date) : new DateData({});
    this.cost = (newDeliveryForm.cost !== undefined) ? new DeliveryCostData(newDeliveryForm.cost) : new DeliveryCostData({});
    this.entryField = (newDeliveryForm.entryField !== undefined) ? newDeliveryForm.entryField : { title: { ru: 'Поле ввода', en: 'Text field' }, type: 'String' };
    this.phone = (newDeliveryForm.phone !== undefined) ? newDeliveryForm.phone : true;
    this.birthday = (newDeliveryForm.birthday !== undefined) ? newDeliveryForm.birthday : true;
    this.attributes = (newDeliveryForm.attributes !== undefined) ? newDeliveryForm.attributes : [
      {
        type: 'PersonalData',
      },
      {
        type: 'Phone',
      }
    ];

    if (this.type === 'Pickup') {
      this.addressData.setType('points');
    }
  }

  addAttribute(type, title) {
    this.attributes.push({ type, title });
  }

  deleteAttribute(index) {
    this.attributes.splice(index, 1);
  }

  setType(id) {
    this.type = deliveryTypes[id];
    if (id === 0) {
      this.addressData.setType('points');
      this.attributes = [
        {
          type: 'PersonalData',
        },
        {
          type: 'Phone',
        },
      ];
    }
    if (id === 1 || id === 2 || id === 3) {
      this.attributes = [
        {
          type: 'PersonalData',
        },
        {
          type: 'Phone',
        },
        {
          type: 'ClientAddress',
        },
        {
          type: 'CostOfDelivery',
        },
        {
          type: 'DeliveryDate',
        },
        {
          type: 'EntryField',
          title: { ru: 'Комментарий к заказу', en: 'Comment on the order' },
        },
      ];
    }
  }

  getType() {
    return this.type;
  }

  getAllTypes() {
    return deliveryTypes;
  }

  getAllAttributesType() {
    const tmp = attributesTypes.map((typeObj) => typeObj.type);
    return tmp;
  }

  getAllTypesWithId() {
    return deliveryTypes;
  }

  setPersonalData(personalData) {
    const getFieldsArr = [];
    personalData.forEach((data) => {
      getFieldsArr.push(new Field(data));
    });
    return getFieldsArr;
  }

  raisePersonalDataPoint(index) {
    arrayMove(this.personalData, index, index - 1);
  }

  putDownPersonalDataPoint(index) {
    arrayMove(this.personalData, index, index + 1)
  }

  changeActive() {
    this.active = !this.active;
  }

  setTitle(language, title) {
    this.title[language] = title;
  }

  setDescription(language, description) {
    this.description[language] = description;
  }

  addPersonalField() {
    this.personalData = [...this.personalData, new Field({ title: { ru: `Название${this.personalData.length}`, en: `Title${this.personalData.length}` } })];
  }

  deletePersonalField(index) {
    this.personalData.splice(index, 1);
  }

  onChangeDateType(newValue) {
    if (newValue === 'setByClient') {
      this.date.subtype = 'clientNone';
    }
    if (newValue === 'autoSet') {
      this.date.subtype = 'autoDate';
    }
  }

  changeCost(type, newValue) {
    this.cost[type] = newValue;
  }

  getEntryFieldTitle() {
    return this.entryField.title;
  }

  getEntryFieldType() {
    return this.entryField.type;
  }

  setEntryFieldType(value) {
    this.entryField.type = value;
  }

  setEntryFieldTitle(language, value) {
    this.entryField.title[language] = value;
  }

  setBirthdayRequired(value) {
    this.birthday = value;
  }

  setPhoneRequired(value) {
    this.phone = value;
  }

  checkTitleIsNotEmpty() {
    if (this.title.en === '' && this.title.ru === '') {
      this.title.en = 'Custom';
      this.title.ru = 'Пользовательский';
    }
    if (this.title.en !== '' && this.title.ru === '') {
      this.title.ru = this.title.en;
    }
    if (this.title.en === '' && this.title.ru !== '') {
      this.title.en = this.title.ru;
    }
  }
}

export default DeliveryFormClass;
