const height = 46;
const width = 56;
const xDeviation = 16;
const yDeviation = 25;
const yDeviationBackspace = 11;

export function getKeyboardSize(rowCount = 9, columnCount = 7) {
  const maxColumnCount = Math.ceil(columnCount / 2);

  return {
    width: (maxColumnCount - 1) * 2 * (width - xDeviation) + width,
    height: (rowCount - 1) * (height - yDeviation) + height + yDeviationBackspace
  };
}

function createPoints(row, column) {
  const coordinates = {
    x: 0,
    y: (row - 1) * (height - yDeviation)
  };

  if (row % 2 === 0) {
    coordinates.x = (column * 2 - 1) * (width - xDeviation);
  } else {
    coordinates.x = (column - 1) * 2 * (width - xDeviation);
  }

  return coordinates;
}

/**
 * Создать сетку MemPad
 * @public
 * @version 1.7.0
 * @param {Number|String} value
 * @param {Number} rowCount=9
 * @param {Number} columnCount=7
 * @return {Object[][{ active: Boolean, number: Number, position: String, coordinates: { x: Number, y: Number }, C: String, T: 'v'|'c', V: String }]}
 * @constructor
 * @example
 * const cells = createMemPad('1234', { rowCount = 9, columnCount = 7  });
 *
 * console.log(cells);
 * // Object[][{ active: Boolean, number: Number, position: String, coordinates: { x: Number, y: Number }, C: String, T: 'v'|'c', V: String }]
 */
export function createMemPad(value = '', { rowCount = 9, columnCount = 7 } = {}) {
  const cells = [];
  const chars = String(value).split('');
  const maxRowCount =  rowCount % 2 === 0 ? rowCount  - 1 : rowCount;
  let position = 0;

  for (let row = 1; row <= rowCount; row++) {
    cells[row - 1] = [];

    const maxColumnCount = row % 2 === 0 ? Math.ceil(columnCount / 2) - 1 : Math.ceil(columnCount / 2);

    for (let column = 1; column <= maxColumnCount; column++) {
      cells[row - 1].push({
        active: chars.length !== 0,
        number: position + 1,
        position: ('0' + position++).slice(-2),
        coordinates: createPoints(row, column),
        C: Math.floor(Math.random() * 7).toString(),
        T: ((row === rowCount || row === maxRowCount) && column === maxColumnCount) ? 'c' : 'v',
        V: chars.shift() || Math.floor(Math.random() * 9).toString()
      });
    }
  }

  return cells;
}

function horizontalSimplicityCheck(items) {
  for (let index = 0; index < items.length - 1; index++) {
    if (items[index].y !== items[index + 1].y) {
      return false;
    }
  }

  return true;
}

function verticalSimplicityCheck(items) {
  for (let index = 0; index < items.length - 1; index++) {
    if (items[index].x !== items[index + 1].x) {
      return false;
    }
  }

  return true;
}

function diagonalSimplicityCheck(items) {
  for (let index = 0; index < items.length - 1; index++) {
    if (
      Math.abs(items[index].x - items[index + 1].x) !== 1 &&
      Math.abs(items[index].x - items[index + 1].x) !== 1
    ) {
      return false;
    }
  }

  return true;
}

/**
 * Проверить требования безопасности (запрещенные номера и порядок ячеек для установки PIN) сетки MemPad
 * @param {Object[][]} cells
 * @param {Boolean} cells[][].active
 * @param {Number} cells[][].number
 * @param {String} cells[][].position
 * @param {Object} cells[][].coordinates
 * @param {Number} cells[][].coordinates.x
 * @param {Number} cells[][].coordinates.y
 * @param {String} cells[][].C
 * @param {'v'|'c'} cells[][].T
 * @param {String} cells[][].V
 * @return {boolean}
 */
export function isValidMemPad(cells) {
  const items = [];

  cells.forEach((row, rIndex) => {
    row.forEach((column, cIndex) => {
      cIndex++;

      if (column.active) {
        items.push({ x: rIndex % 2 === 0 ? 2 * (cIndex - 1) : 1 + 2 * (cIndex - 1), y: rIndex });
      }
    });
  });

  return !horizontalSimplicityCheck(items) &&
    !verticalSimplicityCheck(items) &&
    !diagonalSimplicityCheck(items);
}