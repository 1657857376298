import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Plate user group icon
 * @public
 * @version 1.3.0
 * @param {String} width
 * @param {String} height
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiUserGroupIcon />
 */
const UiUserGroupIcon = ({
  width,
  height,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      innerRef={ innerRef }
      width={ width }
      height={ height }
      viewBox="0 0 52 52"
    >
      <path
        d="M43.6928 29.94V36.3432C43.6868 37.5137 42.7427 38.4593 41.5803 38.4593H35.2975C35.1791 39.7091 34.1364 40.6635 32.8895 40.6635H19.0434C17.8007 40.6579 16.7644 39.705 16.6463 38.4593H10.287C9.74803 38.4622 9.23017 38.2487 8.84805 37.866C8.46593 37.4833 8.25109 36.9631 8.2511 36.4204V29.8298C8.10216 30.2661 8.02453 30.724 8.02124 31.1854V38.7569C8.03327 39.8908 8.95281 40.8019 10.079 40.7958H16.5259C16.655 42.0491 17.7045 43.0011 18.9558 43H32.9661C34.2175 43.0011 35.267 42.0491 35.3961 40.7958H41.7664C42.336 40.8166 42.8898 40.6048 43.3021 40.2084C43.7143 39.812 43.9499 39.2647 43.9555 38.6907V31.1854C43.925 30.7601 43.8366 30.341 43.6928 29.94Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path
        d="M38.1878 14.0036C36.6452 14.0036 35.2549 14.9405 34.6666 16.3764C34.0784 17.8122 34.4083 19.4636 35.5022 20.5587C36.5961 21.6539 38.238 21.9766 39.6607 21.376C41.0833 20.7754 42.0057 19.3701 41.9969 17.8169C41.9848 15.7073 40.2829 14.0036 38.1878 14.0036Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path
        d="M13.823 14.0036C12.2794 13.9992 10.8857 14.9332 10.294 16.3687C9.7023 17.8043 10.0295 19.4574 11.1226 20.5549C12.2156 21.6523 13.8584 21.977 15.2824 21.3771C16.7064 20.7771 17.6299 19.3712 17.6211 17.8169C17.6091 15.7116 15.9139 14.0096 13.823 14.0036Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path
        d="M25.9944 9.55109C22.6696 9.55109 19.9744 12.265 19.9744 15.6127C19.9744 18.9604 22.6696 21.6743 25.9944 21.6743C29.3192 21.6743 32.0145 18.9604 32.0145 15.6127C32.0084 12.2675 29.3167 9.55715 25.9944 9.55109Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path
        d="M39.0199 24.0438H37.4218C35.5856 24.0405 33.9167 25.1176 33.1531 26.7991C34.5595 28.3504 35.3401 30.3745 35.3422 32.4749V38.1949C35.3422 38.261 35.3422 38.3382 35.3422 38.4043H41.6249C42.7874 38.4043 43.7314 37.4587 43.7374 36.2883V28.7498C43.7316 27.4959 43.2313 26.2956 42.3466 25.413C41.4619 24.5305 40.2653 24.0379 39.0199 24.0438Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path
        d="M16.6033 32.4749C16.6064 30.3746 17.3869 28.3509 18.7924 26.799C18.0266 25.1195 16.3591 24.0432 14.5236 24.0437H12.9256C10.3365 24.0376 8.23106 26.1428 8.21899 28.7497V36.3653C8.22502 37.4932 9.13475 38.4042 10.2549 38.4042H16.6142C16.6142 38.3381 16.6142 38.261 16.6142 38.1948L16.6033 32.4749Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path
        d="M38.1879 21.9499C40.4399 21.9499 42.2666 20.1138 42.2706 17.8464C42.2746 15.5789 40.4544 13.7363 38.2025 13.7282C35.9505 13.7201 34.1173 15.5495 34.1052 17.817C34.1023 18.9102 34.5309 19.9598 35.2965 20.7349C36.0622 21.5099 37.1022 21.947 38.1879 21.9499ZM38.1879 14.2351C40.1376 14.2351 41.7193 15.8245 41.7233 17.7877C41.7273 19.7508 40.1521 21.3467 38.2024 21.3548C36.2528 21.3628 34.6646 19.7801 34.6525 17.817C34.6646 15.8596 36.2439 14.2792 38.1879 14.2792V14.2351Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M13.823 21.9499C16.075 21.9499 17.9017 20.1138 17.9057 17.8464C17.9097 15.5789 16.0895 13.7363 13.8376 13.7282C11.5856 13.7201 9.75243 15.5495 9.74029 17.817C9.73452 18.9111 10.1621 19.9624 10.9284 20.7381C11.6947 21.5138 12.7364 21.9499 13.823 21.9499ZM13.823 14.2351C15.7727 14.2351 17.3544 15.8245 17.3584 17.7877C17.3624 19.7508 15.7872 21.3467 13.8375 21.3548C11.8879 21.3628 10.2997 19.7801 10.2876 17.817C10.2997 15.8596 11.879 14.2792 13.823 14.2792V14.2351Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M25.9943 9C22.3673 9 19.427 11.9606 19.427 15.6127C19.427 19.2647 22.3673 22.2253 25.9943 22.2253C29.6214 22.2253 32.5617 19.2647 32.5617 15.6127C32.5617 11.9606 29.6214 9 25.9943 9ZM25.9943 21.1232C22.9718 21.1232 20.5216 18.6561 20.5216 15.6127C20.5216 12.5693 22.9718 10.1021 25.9943 10.1021C29.0169 10.1021 31.4671 12.5693 31.4671 15.6127C31.4671 18.6561 29.0169 21.1232 25.9943 21.1232Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M39.0198 23.7682H37.4109C35.4987 23.7787 33.7621 24.8929 32.9451 26.6337L33.3391 27.0635C34.0271 25.4154 35.6354 24.3488 37.4109 24.3634H39.0198C41.4379 24.3634 43.3981 26.3371 43.3981 28.7718V36.3102C43.392 37.3285 42.5705 38.1508 41.5592 38.1508H35.2984V38.2169C35.3151 38.3781 35.3151 38.5406 35.2984 38.7018H41.6139C42.9275 38.7018 43.994 37.6328 44.0001 36.3102V28.7498C43.988 25.9907 41.76 23.7621 39.0198 23.7682Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M14.5783 23.7682H12.9693C10.2333 23.7682 8.01205 25.9949 8 28.7497V36.3653C8 36.9811 8.24366 37.5714 8.6771 38.0058C9.11053 38.4401 9.698 38.6827 10.3095 38.6798H16.7017C16.685 38.5185 16.685 38.356 16.7017 38.1948V38.1287H10.3642C9.39524 38.1287 8.60802 37.341 8.60201 36.3653V28.7497C8.60201 26.315 10.5622 24.3413 12.9802 24.3413H14.5892C16.3647 24.3268 17.973 25.3934 18.661 27.0415C18.7923 26.8982 18.9127 26.7549 19.055 26.6116C18.2299 24.8759 16.4894 23.7704 14.5783 23.7682Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M26.9684 24.0438H25.0311C20.4091 24.0498 16.6637 27.821 16.6577 32.4749V38.1949C16.6577 38.8408 16.9126 39.4604 17.3662 39.9171C17.8199 40.3739 18.4351 40.6305 19.0767 40.6305H32.9228C34.2588 40.6305 35.3418 39.54 35.3418 38.1949V32.4749C35.3357 27.821 31.5904 24.0498 26.9684 24.0438Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
    </Element>
  );
};

UiUserGroupIcon.propTypes = {
  /** Определяет ширину иконки */
  width: PropTypes.string,
  /** Определяет высоту иконки */
  height: PropTypes.string
};

UiUserGroupIcon.defaultProps = {
  width: '3.25rem',
  height: '3.25rem'
};

export default UiUserGroupIcon;