import styled from 'styled-components';
import InputMask from 'react-input-mask';
import { UiStiky, UiButton, UiText } from '@compassplus/ui-mobicash';

export const TextWithMargin = styled(UiText)`
  width: 100%;
  margin: 0.25rem 0;
`;

export const Stiky = styled(UiStiky)`
  & {
    background: #FFF;
    box-shadow: none;
  }
`

export const DisabledButton = styled(UiButton)`
  & {
    color: #000;
    background: #D6D6D6;
  }
`

export const Description = styled.h3`
  color: ${(props) => props.theme.textTitle} !important;
  @media(max-width: 400px){
    font-size: 14px;
  }
`;

export const Phone = styled(InputMask)`
    width: 450px;
    height: 45px;
    max-height: 45px;
    margin: auto;
    font-size: 1.25rem;
    text-align: center;
    border: 1px solid black;
    border-radius: 5px;
    resize: none;
  
  @media (max-width: 550px) {
    width: 90%;
  }
`;