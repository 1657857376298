import cn from 'classnames';

import UiText from '../../../UiText';

import styles from '../../UiCardSection.module.css';

const TableSection = ({
  title,
  description,
  leftElement,
  rightElement,
  elements
}) => {

  return (
    <>
      { leftElement &&
        <div className={ styles.leftElement }>
          { leftElement }
        </div>
      }
      <div className={ styles.contentTable }>
        <div>
          <UiText className={ styles.text } view="title" typography="section-title">
            { title }
          </UiText>
          <UiText className={ cn(styles.text, styles.description) } view="readonly" typography="section-description">
            { description }
          </UiText>
        </div>
        { elements }
      </div>
      { rightElement &&
        <div className={ styles.rightElement }>
          { rightElement }
        </div>
      }
    </>
  );
};

export default TableSection;