import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Element component
 * @public
 * @version 0.0.90
 * @param {Function} onClick
 * @param {Boolean} [disabled=false]
 * @param {Boolean} [propagation=true]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiDropdown />
 */
const UiDropdown = ({
  onClick,
  disabled,
  propagation,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      color={ isTheme.styles.colors.textLink }
      disabled={ disabled }
      propagation={ propagation }
      onClick={ onClick }
      innerRef={ innerRef }
      width="1rem"
      height="1rem"
      viewBox="0 0 16 16"
    >
      <path fillRule="evenodd" clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM12.5 7.5L8 12L7.5 11.5L3.5 7.5C3.22386 7.22386 3.22386 6.77614 3.5 6.5C3.77614 6.22386 4.22386 6.22386 4.5 6.5L8 10L11.5 6.5C11.7761 6.22386 12.2239 6.22386 12.5 6.5C12.7761 6.77614 12.7761 7.22386 12.5 7.5Z" />
    </Element>
  );
};

UiDropdown.propTypes = {
  /** Обработчик события при клике */
  onClick: PropTypes.func,
  /** Компонент неактивен */
  disabled: PropTypes.bool,
  /** Разрешить/запретить всплытие обработчика onClick */
  propagation: PropTypes.bool,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

UiDropdown.defaultProps = {
  disabled: false,
  propagation: true
};

export default UiDropdown;