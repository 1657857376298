import React from 'react';
import {Text} from "../TemplateStyle";
import i18n from "../../i18n";
import {UiCard} from "@compassplus/ui-mobicash";
import {CheckoutCartSection} from "../../pages/market/ShoppingCartPage/style";
import {LoadingTitle} from "./style";

const LoadingShoppingCart = () => {
  const renderLoadingCartItem = (bottomDivider) => {
    return (
      <CheckoutCartSection
        type="marketplace-card"
        title={<LoadingTitle margin={'0.25rem 0'} />}
        description={<LoadingTitle margin={'0.25rem 0'} />}
        bottomDivider={bottomDivider || false}
      />
    )
  }
  return (
    <>
      <Text view="readonly" typography="subsection-title">{i18n.t('Cart')}</Text>
      <LoadingTitle />
      <UiCard style={{ margin: '0.5rem 0 0', boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.13)' }}>
        {renderLoadingCartItem(true)}
        {renderLoadingCartItem(true)}
        {renderLoadingCartItem(true)}
        {renderLoadingCartItem()}
      </UiCard>
    </>
  )
}

export default LoadingShoppingCart