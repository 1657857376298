import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { UiButton } from '@compassplus/ui-mobicash';
import { useHistory } from 'react-router';
import DaysOfWeek from './components/DaysOfWeek';
import CategoryStore from '../../../stores/CategoryStore';
import Dates from './components/Dates';
import Time from './components/Time';
import { SubContentStiky } from '../ModifyAttributePage/style';
import i18n from '../../../i18n';
import ModalStore from '../../../stores/ModalStore';

const CategoryActivePeriod = observer(() => {
  const [attributes] = useState(JSON.parse(JSON.stringify(CategoryStore.activePeriods)));
  const history = useHistory();

  const saveActivePeriods = () => {
    if (CategoryStore.checkActivePeriods(attributes)) {
      CategoryStore.activePeriods = attributes;
      history.goBack();
    } else {
      ModalStore.openWithMessage(i18n.t('FillAllRequiredFields'));
    }
  };

  return (
    <>
      <div style={{ width: '100%', margin: '0 0 4.25rem' }}>
        <DaysOfWeek attribute={attributes.daysOfWeek} />
        <Dates attribute={attributes.dates} />
        <Time attribute={attributes.time} />
      </div>
      <SubContentStiky>
        <UiButton
          title={i18n.t('Save')}
          onClick={() => saveActivePeriods()}
        />
      </SubContentStiky>
    </>
  );
});

export default CategoryActivePeriod;
