import { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../context/ThemeProvider';

import UiIcon from '../UiIcon';
import UiCardSection from '../UiCardSection';
import UiSection from '../UiSection';
import UiExpand from '../UiExpand';
import SubsectionContainer from '../UiSubsection/SubsectionContainer';
import BackgroundModal from '../../Functional/BackgroundModal';

import styles from './UiSidebar.module.css';

const StyledContainer = styled.div`
  color: #FFFFFF;
  background: ${ (props) => props.styles.colors.sidemenuBackground };
`;

const StyledCardSection = styled(UiCardSection)`
  & > div > span:first-child {
    color: #FFFFFF;
  }
`;

const StyledSection = styled(UiSection)`

  & > div > div > span {
    color: #FFFFFF;
  }

  &:active {
    background: ${ (props) => props.styles.colors.applicationPrimaryColor };
  }

  ${ (props) => props.active && css`
    background: ${ props.styles.colors.applicationPrimaryColor };
  ` }
`;

const StyledExpand = styled(UiExpand)`
  color: #FFFFFF;
`;

const StyledFooter = styled.div`
  ${ (props) => props.styles.typography.Remark };
  color: ${ (props) => props.styles.colors.textReadOnly };
`;

/**
 * Sidebar component
 * @public
 * @version 0.0.90
 * @param {String} className
 * @param {Boolean} [open=false]
 * @param {Function} setOpen
 * @param {Object} user
 * @param {String} user.title
 * @param {String} user.description
 * @param {JSX.Element} user.icon
 * @param {Object[]} [menuList=[]]
 * @param {String} menuList[].title
 * @param {String} menuList[].link
 * @param {String} menuList[].icon
 * @param {Function} menuList[].action
 * @param {Boolean} menuList[].subactive
 * @param {Object[]} menuList[].sublist
 * @param {String} menuList[].sublist[].title
 * @param {String} menuList[].sublist[].link
 * @param {String} menuList[].sublist[].icon
 * @param {Function} menuList[].sublist[].action
 * @param {Boolean} menuList[].sublist[].subactive
 * @param {Object[]} menuList[].sublist[].sublist
 * @param {Function} menuList[].sublist[].action
 * @param {*} [footer=Powered by MobiCash.\nCopyright © 1998-[YYYY]\nCompass Plus Ltd. All rights reserved.]
 * @param {*} children
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiSidebar />
 */
const UiSidebar = ({
  className,
  open,
  setOpen,
  user,
  menuList,
  footer,
  children
}) => {

  const isTheme = useTheme();

  const handlerActive = (link) => {
    if (!link) {
      return false;
    }

    let loc = document.location.pathname.split('/');
    link = link.split('/');
    let flag = false;
    let active = false;
    loc.forEach((item, index) => {
      if (link.length - 1 <= index) {
        if (link[index] === loc[index]) {
          active = !flag;
        } else {
          flag = true;
        }
      }
    });
    return active;
  }

  const MenuListItem = ({ item }) => {

    const [active, setActive] = useState(item.subactive);
    const [rotate, setRotate] = useState(item.subactive ? 180 : 0);

    const handlerSublist = () => {
      setActive(!active);
      setRotate(rotate + 180);
    }

    return (
      <div>
        <StyledSection
          className={ styles.section }
          active={ handlerActive(item.link) }
          title={ item.title }
          leftElement={ <UiIcon setColor="#FFFFFF" type="icon" icon={ item.icon } /> }
          rightElement={
            item.sublist ?
              <StyledExpand
                onClick={ handlerSublist }
                propagation={ false }
                style={ { transition: '0.5s', transform: `rotate(${ rotate }deg)` } }
              />
              :
              undefined
          }
          onClick={ (event) => {
            setOpen(false)
            item.action && item.action(event)
          } }
          styles={ isTheme.styles }
        />
        { item.sublist &&
          <SubsectionContainer active={ active }>
            { item.sublist.map((item, index) => {
              return <MenuListItem key={ `sublist-item-${ index }` } item={ item } />
            }) }
          </SubsectionContainer>
        }
      </div>
    );
  }

  return (
    <div className={ cn(styles.sidebar, className) }>
      { open &&
        <BackgroundModal onClick={ () => setOpen(false) } />
      }
      <StyledContainer className={ cn(styles.container, open && styles.open) } styles={ isTheme.styles }>
        <div className={ styles.content }>
          <div className={ styles.body }>
            { user &&
              <StyledCardSection
                className={ styles.user }
                type="two-strings"
                title={ user.title }
                description={ user.description || '' }
                leftElement={ user.icon || undefined }
                // onClick={ user.action }
              />
            }

            { menuList.map((item, index) => {
              return <MenuListItem key={ `list-item-${ index }` } item={ item } />
            }) }

            { children }
          </div>

          <StyledFooter className={ styles.footer } styles={ isTheme.styles }>
            { footer }
          </StyledFooter>
        </div>

        <div
          className={ styles.action }
          onClick={ open ? () => setOpen(false) : () => setOpen(true) }
          onMouseEnter={ open ? undefined : () => setOpen(true) }
        >
          <UiIcon
            setColor="#FFFFFF"
            type="icon"
            icon={ open ? 'cancel' : 'menu' }
          />
        </div>
      </StyledContainer>
    </div>
  );
};

UiSidebar.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Определяет состояние меню (открыто/закрыто) */
  open: PropTypes.bool,
  /** Функция обработчик состояния меню */
  setOpen: PropTypes.func,
  /** Определяет объект карточки пользователя */
  user: PropTypes.shape({
    /** Заголовок карточки пользователя */
    title: PropTypes.string,
    /** Подзаголовок карточки пользователя */
    description: PropTypes.string,
    /** Иконка карточки */
    icon: PropTypes.element
    // action: PropTypes.func
  }),
  /** Определяет массив элементов меню */
  menuList: PropTypes.arrayOf(PropTypes.shape({
    /** Заголовок элемента */
    title: PropTypes.string,
    /** URL адрес */
    link: PropTypes.string,
    /** Иконка */
    icon: PropTypes.string,
    /** Функция обработчик нажатия */
    action: PropTypes.func,
    /** Начальное состояние подменю (открыто/закрыто) */
    subactive: PropTypes.bool,
    /** Массив элементов подменю */
    sublist: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
      icon: PropTypes.string,
      action: PropTypes.func
    }))
  })),
  /** Определяет footer меню */
  footer: PropTypes.any,
  /** Определяет пользовательский контент меню */
  children: PropTypes.any
};

UiSidebar.defaultProps = {
  open: false,
  setOpen: () => {},
  menuList: [],
  footer: 'Powered by MobiCash.\nCopyright © 1998-[YYYY]\nCompass Plus Ltd. All rights reserved.'
};

export default UiSidebar;