import Attribute from "../controllers/Attribute";
import {makeAutoObservable} from "mobx";
import ItemStore from "./ItemStore";

class AttributesStore {
  itemId = null;
  attributes = [];
  modifyAttribute = {};
  countDependencies = {};
  isCountDependsOnAttributes = false;
  separatedCountAttributes = [];
  modeIsModify = false;
  isModified = false;
  id = -1;

  selectedAttributesNamesArr = [];
  requiredSelectedFlag = false;
  fullAttributeNameCountDependencies = [];

  constructor() {
    makeAutoObservable(this)
  }

  setAllRadioFalse = () => {
    const color = this.getAttributesByType('color');
    const radios = this.getAttributesByType('radio');

    if (color.length !== 0) {
      color[0].changeValue(0, false);
    }
    if (radios.length !== 0) {
      radios.forEach((radio) => {
        radio.changeValue(0, false)
      })
    }
  }

  setAllCheckboxesFalse = () => {
    const checkboxes = this.getAttributesByType('checkbox');

    if (checkboxes.length !== 0) {
      checkboxes.forEach((checkbox) => {
        checkbox.complexValue.forEach((cValue, index) => {
          checkbox.changeValue(index, false);
        })
      })
    }
  }

  getNested = (obj, keys) => keys.reduce((p, c) => p?.hasOwnProperty(c) ? p[c] : null, obj);

  getObjectValue = (attrFullName) => {
    const nameArr = attrFullName.split(';').filter((el) => el !== '');
    return this.getNested(this.countDependencies, nameArr)
  }

  getArrValue = (attrFullName) => {
    const nameArr = attrFullName.split(';').filter((el) => el !== '');
    return this.fullAttributeNameCountDependencies.find((el) => nameArr.every((name) => el.en.includes(name)))?.value
  }

  getAttributesByType(type) {
    return this.attributes.filter((el) => el.type === type);
  }

  getSeparatedCountAttributes() {
    return this.attributes.filter((el) => { if (el.isSeparateCount) return el.complexValue.map((complex) => complex.title) });
  }

  setItemId(id) {
    this.itemId = id;
  }

  setAttributes(attributes) {
    this.attributes = [];
    attributes.forEach((item) => {
      if (item) {
        const attribute = new Attribute(item);
        this.attributes.push(attribute);
      }
    });
    this.countDependencies = {};
    this.fullAttributeNameCountDependencies = [];
    this.separatedCountAttributes = this.getSeparatedCountAttributes();
    this.requiredSelectedFlag = this.getAttributesByType('radio').length === 0 && this.getAttributesByType('color').length === 0 && this.getAttributesByType('list').length === 0;
  }

  addAttribute(type) {
    this.modifyAttribute = new Attribute({type})
    this.modeIsModify = false;
    this.id = -1;
  }

  setModifyAttribute(attribute, id) {
    this.modifyAttribute = attribute;
    this.modeIsModify = true;
    this.id = id;
  }

  saveAttribute() {
    this.modifyAttribute.fillUndefinedFields();
    if (this.id !== undefined && this.id !== -1) {
      this.attributes[this.id] = this.modifyAttribute;
    } else {
      if (!this.modeIsModify) {
        this.attributes.push(this.modifyAttribute);
      }
      this.modifyAttribute = {};
    }
  }

  deleteModifyingAttribute() {
    if (this.id !== undefined && this.id !== -1 && this.modeIsModify) {
      this.attributes.splice(this.id, 1);
    }
  }

  setDefaultValues = (object, value) => {
    for (const key in object) {
      if (typeof (object[key]) === 'object') {
        this.setDefaultValues(object[key], value);
      } else {
        object[key] = value;
      }
    }
  };

  getObjFromArr = (arr) => {
    const obj = {};
    for (const value of arr) {
      obj[value] = '';
    }
    return obj;
  };

  getAttributeString = (arr, index) => {
    const separatedCountAttributes = this.getSeparatedCountAttributes();
    if (index < separatedCountAttributes.length) {
      for (const attribute of separatedCountAttributes[index].complexValue) {
        arr.push(attribute.title);
        this.getAttributeString(arr, index + 1);
        arr.pop();
      }
    } else {
      const ru = arr.map((el) => el.ru);
      const en = arr.map((el) => el.en);
      this.fullAttributeNameCountDependencies.push({
        ru: ru.join(';'),
        en: en.join(';'),
        value: this.getObjectValue(en.join(';'))
      });
    }
  };

  getFullNameAttributesArr = () => {
    const attributesSet = [];
    this.fullAttributeNameCountDependencies = [];
    this.getAttributeString(attributesSet, 0);
  };

  setDefaultCountDependencies = () => {
    const separatedCountAttributes = this.getSeparatedCountAttributes();
    if (this.itemId !== ItemStore.article || Object.keys(this.countDependencies).length === 0 || this.separatedCountAttributes.length !== separatedCountAttributes.length) {
      if (separatedCountAttributes.length !== 0) {
        this.countDependencies = this.getObjFromArr(separatedCountAttributes[0].complexValue.map((attr) => attr.title.en));
        for (let i = 1; i < separatedCountAttributes.length; i++) {
          this.setDefaultValues(this.countDependencies, this.getObjFromArr(separatedCountAttributes[i].complexValue.map((attr) => attr.title.en)));
        }
        this.setDefaultValues(this.countDependencies, { min: 1, max: 0 });
        this.getFullNameAttributesArr();
        this.separatedCountAttributes = separatedCountAttributes;
      } else {
        this.currentLanguageDependenciesObj = {};
        this.countDependencies = {};
        this.fullAttributeNameCountDependencies = [];
      }
    }
  }

  getAttributesTotalAmount = () => {
    let amount = 0;
    this.fullAttributeNameCountDependencies.forEach((attribute) => {
      amount += this.getObjectValue(attribute.en).max
    });
    ItemStore.totalAmount = amount;
  }

  getActiveAttributes = () => {
    const color = this.getAttributesByType('color');
    const radios = this.getAttributesByType('radio');

    if (this.selectedAttributesNamesArr.length === 0) {
      this.setDefaultActive();
      return;
    }
    const allActiveAttributes = this.fullAttributeNameCountDependencies.filter((el) => this.selectedAttributesNamesArr.every((name) => el.en.includes(name)) && el.value.max > 0);


    if (color.length !== 0) {
      if (color[0].complexValue.every((el) => {
        return this.selectedAttributesNamesArr[this.selectedAttributesNamesArr.length - 1] !== el.title.en
      })) {
        this.getActiveAttributeFields(color[0], allActiveAttributes);
      }
    }
    if (radios.length !== 0) {
      radios.forEach((radio) => {
        if (radio.complexValue.every((el) => {
          return this.selectedAttributesNamesArr[this.selectedAttributesNamesArr.length - 1] !== el.title.en
        })) {
          this.getActiveAttributeFields(radio, allActiveAttributes);
        }
      })
    }
  }

  setDefaultActive = () => {
    const color = this.getAttributesByType('color');
    const radios = this.getAttributesByType('radio');
    const allActiveAttributes = this.fullAttributeNameCountDependencies.filter((el) => el.value.max > 0);

    if (color.length !== 0) {
      this.getActiveAttributeFields(color[0], allActiveAttributes);
    }
    if (radios.length !== 0) {
      radios.forEach((radio) => {
        this.getActiveAttributeFields(radio, allActiveAttributes);
      })
    }
  }

  getActiveAttributeFields = (attribute, allActiveAttributes) => {
    attribute.complexValue = attribute.complexValue.map((el) => {
      if (this.fullAttributeNameCountDependencies.length === 0) {
        el.active = true;
        el.disabled = false;
      } else {
        if (this.selectedAttributesNamesArr.length === 0) {
          el.disabled = true;
        }
        el.active = false;
        if (allActiveAttributes.some((name) => name.en.includes(el.title.en))) {
          el.active = true;
          el.disabled = false;
        }
      }
      return el;
    });
  }

  checkAllRequiredAttributesSelected = () => {
    let flag = true;

    this.attributes.forEach((attribute) => {
      switch (attribute.type) {
        case 'color':
        case 'radio':
          let complexSelected = false;
          attribute.complexValue.forEach((cValue) => {
            if (cValue.value) {
              complexSelected = true;
            }
          })
          if (!complexSelected) {
            flag = false;
          }
          break;
        case 'list':
          if (attribute.value.length === 0) {
            flag = false;
          }
          attribute.value.forEach((listValue) => {
            if (listValue === '') {
              flag = false;
            }
          })
          break;
        default:
          break;
      }
    });

    this.requiredSelectedFlag = flag;
    return flag;
  }
}

export default new AttributesStore();