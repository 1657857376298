import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Иконка документов
 * @public
 * @version 1.5.0
 * @param {String} [width="3.25rem"]
 * @param {String} [height="3.25rem"]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * import { UiDocumentsIcon, UiPlates, UiPlate } from '@compassplus/ui-mobicash';
 *
 * const App = () => {
 *
 *   return (
 *     <>
 *       <UiDocumentsIcon />
 *       <UiPlates>
 *         <UiPlate view="2x1" image={ <UiDocumentsIcon width="100%" height="100%" /> } />
 *       </UiPlates>
 *     </>
 *   );
 * }; */
const UiDocumentsIcon = ({
  width,
  height,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      innerRef={ innerRef }
      width={ width }
      height={ height }
      viewBox="0 0 52 52"
    >
      <g clipPath="url(#clip0_3270_12186)">
        <path
          d="M39.8359 24.8954V11.6305C39.8359 11.392 39.7426 11.1632 39.5766 10.9946C39.4106 10.8259 39.1854 10.7312 38.9507 10.7312H37.9116V35.9413L32.1388 42.0117H12.3861C12.0723 42.0117 11.7713 41.885 11.5493 41.6596C11.3274 41.4341 11.2027 41.1283 11.2027 40.8094V23.2629C11.2027 23.2111 11.1824 23.1614 11.1463 23.1247C11.1102 23.088 11.0613 23.0674 11.0102 23.0674H10.2405C10.1895 23.0674 10.1406 23.088 10.1045 23.1247C10.0684 23.1614 10.0481 23.2111 10.0481 23.2629L10.1058 43.7908C10.1058 44.3773 10.8948 44.8563 11.8665 44.8563H12.3861V45.2473C12.3861 45.6481 12.9153 45.9609 13.5695 45.9609H39.7781C40.4324 45.9609 40.9615 45.6481 40.9615 45.2473V25.609C41 25.218 40.4805 24.9052 39.8359 24.8954Z"
          fill={ isTheme.styles.colors.textDisable } />
        <path
          d="M12.6363 12.1193C12.7422 12.1193 12.8673 12.1779 12.9827 12.2072H13.2521C13.5582 12.1707 13.8396 12.0189 14.0409 11.7818C14.2422 11.5448 14.3487 11.2396 14.3393 10.9267V9.71458C14.3393 9.11829 14.3393 8.52199 14.3393 7.9257C14.3393 7.66645 14.2379 7.41783 14.0575 7.23451C13.8771 7.05119 13.6324 6.94819 13.3772 6.94819C13.122 6.94819 12.8773 7.05119 12.6969 7.23451C12.5164 7.41783 12.4151 7.66645 12.4151 7.9257V10.4575C12.4247 10.5007 12.4486 10.5392 12.4826 10.5669C12.5167 10.5945 12.5591 10.6096 12.6027 10.6096C12.6463 10.6096 12.6886 10.5945 12.7226 10.5669C12.7567 10.5392 12.7806 10.5007 12.7903 10.4575C12.795 10.4251 12.795 10.3921 12.7903 10.3597C12.7903 9.75365 12.7903 8.0821 12.7903 7.85727C12.7928 7.70769 12.8524 7.56496 12.9565 7.45917C13.0606 7.35338 13.2011 7.29284 13.3484 7.29032C13.4258 7.289 13.5026 7.30352 13.5744 7.33301C13.6462 7.36251 13.7115 7.4064 13.7662 7.46203C13.821 7.51767 13.8642 7.58392 13.8932 7.65686C13.9222 7.72981 13.9365 7.80793 13.9352 7.8866C13.9352 8.86411 13.9352 10.9658 13.9352 11.0049C13.9212 11.2356 13.8175 11.4512 13.647 11.6043C13.4765 11.7574 13.2531 11.8354 13.026 11.8211C12.7989 11.8069 12.5867 11.7015 12.436 11.5283C12.2854 11.355 12.2086 11.1281 12.2226 10.8974V7.46627C12.2226 7.15776 12.3433 6.86188 12.558 6.64373C12.7727 6.42558 13.0639 6.30302 13.3676 6.30302C13.6712 6.30302 13.9625 6.42558 14.1772 6.64373C14.3919 6.86188 14.5125 7.15776 14.5125 7.46627V8.0919H14.8974V7.44673C14.8968 7.11172 14.7883 6.78612 14.5885 6.5196C14.3888 6.25308 14.1086 6.06029 13.7909 5.97067L13.5023 5.90224H13.1944C12.826 5.94725 12.4863 6.12675 12.2385 6.40728C11.9907 6.68781 11.8517 7.05025 11.8474 7.42716C11.8474 8.5676 11.8474 9.7113 11.8474 10.8583C11.8263 11.127 11.8919 11.3954 12.0342 11.6229C12.1766 11.8504 12.3879 12.0246 12.6363 12.1193Z"
          fill={ isTheme.styles.colors.textTitle } />
        <path
          d="M38.9506 10.4868H38.3926V9.91006C38.3926 9.46156 38.2173 9.03141 37.9051 8.71427C37.593 8.39713 37.1695 8.21898 36.7281 8.21898H23.4218V9.1965H36.7377C36.924 9.1965 37.1027 9.27167 37.2344 9.4055C37.3661 9.53932 37.44 9.72081 37.44 9.91006V35.4526H33.3895C33.1701 35.4513 32.9527 35.4941 32.7496 35.5785C32.5466 35.6629 32.3619 35.7873 32.2063 35.9444C32.0507 36.1016 31.9273 36.2885 31.843 36.4943C31.7588 36.7001 31.7154 36.9208 31.7154 37.1437V41.523H12.3476C12.1613 41.523 11.9827 41.4478 11.851 41.314C11.7192 41.1801 11.6452 40.9987 11.6452 40.8094V21.1906H10.6831V40.8094C10.6831 41.2579 10.8585 41.688 11.1706 42.0052C11.4828 42.3223 11.9061 42.5005 12.3476 42.5005H12.8383V43.1359C12.8408 43.4384 12.9602 43.7278 13.1708 43.9418C13.3813 44.1557 13.6662 44.277 13.964 44.2796H38.9795C39.2781 44.2796 39.5644 44.1591 39.7755 43.9446C39.9866 43.7301 40.1052 43.4392 40.1052 43.1359V11.6305C40.1052 11.4778 40.0752 11.3267 40.0168 11.186C39.9585 11.0452 39.873 10.9178 39.7654 10.8112C39.6578 10.7046 39.5302 10.621 39.3903 10.5653C39.2503 10.5096 39.1009 10.4829 38.9506 10.4868ZM33.3798 36.4301L37.2284 36.5083L32.8507 41.1418L32.6968 37.1437C32.6967 36.9578 32.768 36.7792 32.8956 36.6459C33.0232 36.5126 33.1969 36.4352 33.3798 36.4301ZM39.5953 43.1359C39.5953 43.3096 39.5274 43.4762 39.4065 43.599C39.2856 43.7218 39.1216 43.7908 38.9506 43.7908H13.9351C13.7642 43.7908 13.6002 43.7218 13.4793 43.599C13.3584 43.4762 13.2905 43.3096 13.2905 43.1359V42.5005H32.3888C32.527 42.5017 32.664 42.4727 32.7901 42.4153C32.9163 42.358 33.0288 42.2736 33.12 42.1681L38.1905 36.6354C38.3077 36.5003 38.3728 36.3267 38.3734 36.1466V10.9756H38.9314C39.1023 10.9756 39.2663 11.0446 39.3872 11.1674C39.5081 11.2902 39.5761 11.4568 39.5761 11.6305L39.5953 43.1359Z"
          fill={ isTheme.styles.colors.textTitle } />
        <path
          d="M22.3538 25.1789H35.3041C35.3679 25.1789 35.4291 25.1531 35.4742 25.1073C35.5193 25.0615 35.5447 24.9993 35.5447 24.9345C35.5447 24.8697 35.5193 24.8076 35.4742 24.7617C35.4291 24.7159 35.3679 24.6901 35.3041 24.6901H22.3538C22.29 24.6901 22.2289 24.7159 22.1838 24.7617C22.1386 24.8076 22.1133 24.8697 22.1133 24.9345C22.1133 24.9993 22.1386 25.0615 22.1838 25.1073C22.2289 25.1531 22.29 25.1789 22.3538 25.1789Z"
          fill={ isTheme.styles.colors.textTitle } />
        <path
          d="M13.4734 24.9345C13.4734 24.9993 13.4987 25.0615 13.5439 25.1073C13.589 25.1531 13.6501 25.1789 13.7139 25.1789H18.7459C18.8097 25.1789 18.8709 25.1531 18.916 25.1073C18.9611 25.0615 18.9865 24.9993 18.9865 24.9345C18.9865 24.8697 18.9611 24.8076 18.916 24.7617C18.8709 24.7159 18.8097 24.6901 18.7459 24.6901H13.7139C13.6501 24.6901 13.589 24.7159 13.5439 24.7617C13.4987 24.8076 13.4734 24.8697 13.4734 24.9345Z"
          fill={ isTheme.styles.colors.textTitle } />
        <path
          d="M28.1458 32.4321H35.3426C35.4064 32.4321 35.4675 32.4063 35.5126 32.3605C35.5577 32.3147 35.5831 32.2525 35.5831 32.1877C35.5831 32.1229 35.5577 32.0607 35.5126 32.0149C35.4675 31.969 35.4064 31.9433 35.3426 31.9433H28.1458C28.082 31.9433 28.0209 31.969 27.9757 32.0149C27.9306 32.0607 27.9053 32.1229 27.9053 32.1877C27.9053 32.2525 27.9306 32.3147 27.9757 32.3605C28.0209 32.4063 28.082 32.4321 28.1458 32.4321Z"
          fill={ isTheme.styles.colors.textTitle } />
        <path
          d="M24.2106 32.1877C24.2106 32.1229 24.1854 32.0607 24.1402 32.0149C24.0951 31.969 24.0339 31.9433 23.9701 31.9433H13.8003C13.7366 31.9433 13.6754 31.969 13.6303 32.0149C13.5852 32.0607 13.5598 32.1229 13.5598 32.1877C13.5598 32.2525 13.5852 32.3147 13.6303 32.3605C13.6754 32.4063 13.7366 32.4321 13.8003 32.4321H24.0182C24.0734 32.4206 24.1228 32.3899 24.158 32.3453C24.1931 32.3006 24.2117 32.2449 24.2106 32.1877Z"
          fill={ isTheme.styles.colors.textTitle } />
        <path
          d="M26.9239 12.7644H35.2752C35.3389 12.7644 35.4002 12.7387 35.4453 12.6928C35.4904 12.647 35.5157 12.5849 35.5157 12.52C35.5157 12.4552 35.4904 12.3931 35.4453 12.3472C35.4002 12.3014 35.3389 12.2757 35.2752 12.2757H26.9239C26.8601 12.2757 26.7989 12.3014 26.7538 12.3472C26.7087 12.3931 26.6833 12.4552 26.6833 12.52C26.6833 12.5849 26.7087 12.647 26.7538 12.6928C26.7989 12.7387 26.8601 12.7644 26.9239 12.7644Z"
          fill={ isTheme.styles.colors.textTitle } />
        <path
          d="M26.8951 13.9765H35.2465C35.3103 13.9765 35.3714 13.9508 35.4165 13.905C35.4616 13.8591 35.487 13.797 35.487 13.7322C35.487 13.6673 35.4616 13.6052 35.4165 13.5593C35.3714 13.5135 35.3103 13.4878 35.2465 13.4878H26.8951C26.8313 13.4878 26.7701 13.5135 26.725 13.5593C26.6799 13.6052 26.6545 13.6673 26.6545 13.7322C26.6545 13.797 26.6799 13.8591 26.725 13.905C26.7701 13.9508 26.8313 13.9765 26.8951 13.9765Z"
          fill={ isTheme.styles.colors.textTitle } />
        <path
          d="M35.5157 16.6061C35.5157 16.5412 35.4904 16.4791 35.4453 16.4332C35.4002 16.3874 35.3389 16.3617 35.2752 16.3617H26.9239C26.8601 16.3617 26.7989 16.3874 26.7538 16.4332C26.7087 16.4791 26.6833 16.5412 26.6833 16.6061C26.6833 16.6709 26.7087 16.733 26.7538 16.7789C26.7989 16.8247 26.8601 16.8504 26.9239 16.8504H35.2752C35.3389 16.8504 35.4002 16.8247 35.4453 16.7789C35.4904 16.733 35.5157 16.6709 35.5157 16.6061Z"
          fill={ isTheme.styles.colors.textTitle } />
        <path
          d="M35.0926 18.1994H29.4256C29.3618 18.1994 29.3006 18.2252 29.2555 18.271C29.2104 18.3168 29.1851 18.379 29.1851 18.4438C29.1851 18.5086 29.2104 18.5708 29.2555 18.6166C29.3006 18.6624 29.3618 18.6882 29.4256 18.6882H35.0926C35.1564 18.6882 35.2175 18.6624 35.2626 18.6166C35.3078 18.5708 35.3331 18.5086 35.3331 18.4438C35.3307 18.3798 35.3047 18.319 35.2601 18.2737C35.2155 18.2284 35.1556 18.2018 35.0926 18.1994Z"
          fill={ isTheme.styles.colors.textTitle } />
        <path
          d="M35.2753 27.1144H27.9151C27.8513 27.1144 27.79 27.1401 27.7449 27.1859C27.6998 27.2318 27.6746 27.2939 27.6746 27.3587C27.6746 27.4236 27.6998 27.4857 27.7449 27.5316C27.79 27.5774 27.8513 27.6031 27.9151 27.6031H35.2753C35.3391 27.6031 35.4004 27.5774 35.4455 27.5316C35.4906 27.4857 35.5159 27.4236 35.5159 27.3587C35.5159 27.2939 35.4906 27.2318 35.4455 27.1859C35.4004 27.1401 35.3391 27.1144 35.2753 27.1144Z"
          fill={ isTheme.styles.colors.textTitle } />
        <path
          d="M13.6849 27.6031H22.8828C22.9466 27.6031 23.0079 27.5774 23.053 27.5316C23.0981 27.4857 23.1234 27.4236 23.1234 27.3587C23.1234 27.2939 23.0981 27.2318 23.053 27.1859C23.0079 27.1401 22.9466 27.1144 22.8828 27.1144H13.6849C13.6211 27.1144 13.5599 27.1401 13.5148 27.1859C13.4697 27.2318 13.4443 27.2939 13.4443 27.3587C13.4443 27.4236 13.4697 27.4857 13.5148 27.5316C13.5599 27.5774 13.6211 27.6031 13.6849 27.6031Z"
          fill={ isTheme.styles.colors.textTitle } />
        <path
          d="M35.2753 29.5191H31.6385C31.5747 29.5191 31.5135 29.5448 31.4684 29.5907C31.4233 29.6365 31.3979 29.6986 31.3979 29.7634C31.3979 29.8283 31.4233 29.8904 31.4684 29.9363C31.5135 29.9821 31.5747 30.0078 31.6385 30.0078H35.2753C35.3391 30.0078 35.4003 29.9821 35.4454 29.9363C35.4906 29.8904 35.5158 29.8283 35.5158 29.7634C35.5158 29.6986 35.4906 29.6365 35.4454 29.5907C35.4003 29.5448 35.3391 29.5191 35.2753 29.5191Z"
          fill={ isTheme.styles.colors.textTitle } />
        <path
          d="M29.9259 29.7634C29.9259 29.6986 29.9005 29.6365 29.8554 29.5907C29.8103 29.5448 29.7491 29.5191 29.6853 29.5191H19.5733C19.5095 29.5191 19.4483 29.5448 19.4032 29.5907C19.3581 29.6365 19.3328 29.6986 19.3328 29.7634C19.3328 29.8283 19.3581 29.8904 19.4032 29.9363C19.4483 29.9821 19.5095 30.0078 19.5733 30.0078H29.6853C29.7491 30.0078 29.8103 29.9821 29.8554 29.9363C29.9005 29.8904 29.9259 29.8283 29.9259 29.7634Z"
          fill={ isTheme.styles.colors.textTitle } />
        <path
          d="M18.8613 36.6354H13.6753C13.6116 36.6354 13.5504 36.6611 13.5053 36.707C13.4602 36.7528 13.4348 36.8149 13.4348 36.8798C13.4348 36.9446 13.4602 37.0067 13.5053 37.0526C13.5504 37.0984 13.6116 37.1241 13.6753 37.1241H18.8613C18.9251 37.1241 18.9862 37.0984 19.0313 37.0526C19.0764 37.0067 19.1018 36.9446 19.1018 36.8798C19.1018 36.8149 19.0764 36.7528 19.0313 36.707C18.9862 36.6611 18.9251 36.6354 18.8613 36.6354Z"
          fill={ isTheme.styles.colors.textTitle } />
        <path
          d="M18.8421 38.3363H13.6658C13.602 38.3363 13.5409 38.362 13.4958 38.4078C13.4507 38.4537 13.4253 38.5158 13.4253 38.5807C13.4253 38.6455 13.4507 38.7076 13.4958 38.7534C13.5409 38.7993 13.602 38.825 13.6658 38.825H18.8421C18.9059 38.825 18.9671 38.7993 19.0122 38.7534C19.0573 38.7076 19.0827 38.6455 19.0827 38.5807C19.0827 38.5158 19.0573 38.4537 19.0122 38.4078C18.9671 38.362 18.9059 38.3363 18.8421 38.3363Z"
          fill={ isTheme.styles.colors.textTitle } />
        <path
          d="M13.0403 11.826C13.2574 11.8383 13.4713 11.768 13.6402 11.6289C13.8092 11.4898 13.9211 11.2917 13.9542 11.0733C13.9542 11.0733 13.9542 9.23559 13.9542 8.18965H12.8382V10.4281C12.8429 10.4606 12.8429 10.4935 12.8382 10.5259C12.8285 10.5691 12.8047 10.6077 12.7706 10.6353C12.7365 10.6629 12.6942 10.678 12.6506 10.678C12.607 10.678 12.5647 10.6629 12.5306 10.6353C12.4965 10.6077 12.4726 10.5691 12.4629 10.5259V8.23852H12.2705V11.0147C12.2851 11.22 12.3701 11.4137 12.5107 11.562C12.6514 11.7102 12.8388 11.8036 13.0403 11.826Z"
          fill={ isTheme.styles.colors.applicationPrimaryColor } />
        <path
          d="M14.9164 8.18966H14.3392V10.9169C14.3485 11.2298 14.242 11.535 14.0407 11.7721C13.8395 12.0091 13.558 12.1609 13.252 12.1975H12.9826C12.8671 12.1975 12.742 12.1486 12.6362 12.1095C12.409 12.0175 12.2142 11.8585 12.077 11.6528C11.9398 11.4472 11.8665 11.2045 11.8665 10.956V8.17987H10.6157V21.1808H23.4217V8.17987L14.9164 8.18966ZM16.4847 16.0098H21.4975C21.5613 16.0098 21.6224 16.0355 21.6675 16.0814C21.7126 16.1272 21.738 16.1894 21.738 16.2542C21.738 16.319 21.7126 16.3811 21.6675 16.427C21.6224 16.4728 21.5613 16.4986 21.4975 16.4986H16.4655C16.4017 16.4986 16.3405 16.4728 16.2954 16.427C16.2503 16.3811 16.225 16.319 16.225 16.2542C16.225 16.1894 16.2503 16.1272 16.2954 16.0814C16.3405 16.0355 16.4017 16.0098 16.4655 16.0098H16.4847ZM21.536 18.6197H16.504C16.4402 18.6197 16.379 18.594 16.3339 18.5482C16.2888 18.5024 16.2635 18.4402 16.2635 18.3754C16.2635 18.3106 16.2888 18.2484 16.3339 18.2026C16.379 18.1567 16.4402 18.131 16.504 18.131H21.4975C21.5613 18.131 21.6224 18.1567 21.6675 18.2026C21.7126 18.2484 21.738 18.3106 21.738 18.3754C21.738 18.4402 21.7126 18.5024 21.6675 18.5482C21.6224 18.594 21.5613 18.6197 21.4975 18.6197H21.536Z"
          fill={ isTheme.styles.colors.applicationPrimaryColor } />
      </g>
      <defs>
        <clipPath id="clip0_3270_12186">
          <rect width="31" height="40" fill={ isTheme.styles.colors.sectionBackground } transform="translate(10 6)" />
        </clipPath>
      </defs>
    </Element>
  );
};

UiDocumentsIcon.propTypes = {
  /** Определяет ширину иконки */
  width: PropTypes.string,
  /** Определяет высоту иконки */
  height: PropTypes.string
};

UiDocumentsIcon.defaultProps = {
  width: '3.25rem',
  height: '3.25rem'
};

export default UiDocumentsIcon;