import { useTheme } from '../../../context/ThemeProvider';

import UiBadge from '../../Ui/UiBadge';
import UiIcon from '../../Ui/UiIcon';

/**
 * Компонент значка MobiCash
 * @public
 * @version 0.0.101
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiMobiCashBadge />
 */
const UiMobiCashBadge = (props) => {

  const isTheme = useTheme();

  const Icon = (props) => {

    return (
      <UiIcon { ...props } setWidth="0.75rem" setHeight="0.75rem" viewBox="0 0 12 12">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.625 3.1875H3.375L6.0108 6.9375L8.625 3.1875Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M6 8.8125H11.25L8.61437 4.4375L6 8.8125Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0.75 8.8125H6L3.36437 4.4375L0.75 8.8125Z" />
      </UiIcon>
    );
  };

  return (
    <UiBadge
      { ...props }
      icon={ <Icon /> }
      color={ isTheme.styles.colors.applicationPrimaryColor }
    >
    </UiBadge>
  );
};

export default UiMobiCashBadge;