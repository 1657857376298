export const changeStorage = {

  setItem: (name, item) => {
    localStorage.setItem(name, item);
  },

  setObject: (name, object) => {
    localStorage.setItem(name, JSON.stringify(object));
  },

  getItem: (name) => {
    return localStorage.getItem(name);
  },

  getObject: (name) => {
    const object = localStorage.getItem(name);

    try {
      if (object) {
        return JSON.parse(object);
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  }
}