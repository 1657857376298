import styled from 'styled-components';
import InputMask from 'react-input-mask';

import { ColorsContainer } from "../../../Utils/UtilsStyle";

export const DefaultColorsContainer = styled(ColorsContainer)`
  width: 240px;
  margin: 1rem auto;
  border-right: none !important;
`

export const NewColorDisabled = styled.div`
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0);
    z-index: 999;
`;

export const NewColorActive = styled(NewColorDisabled)`
    transform: scale(1);
`;

export const Title = styled.h3`
    color: ${(props) => props.theme.textTitle}
`;

export const ColorPickerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
`;

export const ColorInput = styled(InputMask)`
  font-size: 0.75rem;
  margin: 0.5rem 1rem 0;
  background: #fff;
  border: 1px solid #A3AEBF;
  width: 18.8125rem;
  height: 1.625rem;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  
  :focus {
    border: 1px solid #A3AEBF;
    outline: none;
  }
`;
