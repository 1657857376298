import PropTypes from 'prop-types';
import cn from 'classnames';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../context/ThemeProvider';

import Input from '../UiInput/Components/Input';

import styles from './UiSimpleInput.module.css';

const StyledContainer = styled.div`
  ${ (props) => css`
    background: ${ props.styles.colors.sectionBackground };
    border: 0.063rem solid ${ props.styles.colors.keysBackgroundColor };
  ` }

  ${ (props) => props.disabled && css`
    pointer-events: none;
  ` }
`;

/**
 * Simple input component
 * @public
 * @version 1.2.0
 * @param {String} className
 * @param {'text'|'password'|'readonly'} [type=text]
 * @param {Boolean} [readOnly=false]
 * @param {Boolean} [disabled=false]
 * @param {Boolean} [warning=false]
 * @param {String} value
 * @param {String} placeholder
 * @param {Function} onClick
 * @param {Function} onChange
 * @param {Function} onFocus
 * @param {Function} onBlur
 * @param {JSX.Element} leftElement
 * @param {JSX.Element} rightElement
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiSimpleInput />
 */
const UiSimpleInput = ({
  className,
  type,
  readOnly,
  disabled,
  warning,
  value,
  placeholder,
  onClick,
  onChange,
  onFocus,
  onBlur,
  leftElement,
  rightElement,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <StyledContainer
      className={ cn(styles.container, className) }
      disabled={ disabled }
      styles={ isTheme.styles }
    >
      { leftElement &&
        <div className={ styles.element }>
          { leftElement }
        </div>
      }
      <Input
        { ...props }
        className={ styles.input }
        type={ type }
        readOnly={ readOnly }
        disabled={ disabled }
        typography="dialogText"
        warning={ warning }
        value={ value }
        placeholder={ placeholder }
        onClick={ onClick }
        onChange={ onChange }
        onFocus={ onFocus }
        onBlur={ onBlur }
        innerRef={ innerRef }
      />
      { rightElement &&
        <div className={ styles.element }>
          { rightElement }
        </div>
      }
    </StyledContainer>
  );
};

UiSimpleInput.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Определяет тип поля ввода */
  type: PropTypes.oneOf(['text', 'password', 'readonly']),
  /** Определяет блокировку поля ввода для изменения */
  readOnly: PropTypes.bool,
  /** Определяет блокировку компонента */
  disabled: PropTypes.bool,
  /** Определяет стили невалидного значения поля ввода (окрашивается в красный цвет) */
  warning: PropTypes.bool,
  /** Определяет значение поля ввода */
  value: PropTypes.string,
  /** Определяет описание поля ввода */
  placeholder: PropTypes.string,
  /** Определяет обработчик нажатия на поле ввода */
  onClick: PropTypes.func,
  /** Определяет обработчик на изменение значения value */
  onChange: PropTypes.func,
  /** Определяет обработчик получения фокуса на поле ввода */
  onFocus: PropTypes.func,
  /** Определяет обработчик потери фокуса на поле ввода */
  onBlur: PropTypes.func,
  /** Определяет левый элемент компонента */
  leftElement: PropTypes.element,
  /** Определяет правый элемент компонента */
  rightElement: PropTypes.element,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

UiSimpleInput.defaultProps = {
  type: 'text',
  readOnly: false,
  disabled: false,
  warning: false,
  placeholder: 'Default',
  onChange: () => {}
};

export default UiSimpleInput;