import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import cn from 'classnames';

import { useTheme } from '../../../context/ThemeProvider';

import styles from './UiSimpleLink.module.css';

const StyledLink = styled.a`
  ${ (props) => {
    switch (props.view) {
      case 'normal':
        return props.styles.typography.Note;
      case 'legal':
        return props.styles.typography.Remark;
      default:
        return props.styles.typography.Note;
    }
  } }
  color: ${ (props) => props.styles.colors.textLink };

  &:hover {
    opacity: 0.4;
  }

  ${ (props) => props.disabled && css`
    color: ${ (props) => props.styles.colors.textDisable };
    cursor: not-allowed;
    opacity: 1;
    pointer-events: none;
  ` }
`;

/**
 * Simple link component
 * @public
 * @version 0.0.90
 * @param {String} className
 * @param {'normal'|'legal'} [view=normal]
 * @param {String} [title=Link]
 * @param {String} [url=]
 * @param {Boolean} [disabled=false]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiSimpleLink />
 */
const UiSimpleLink = ({
  className,
  view,
  title,
  url,
  disabled,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <StyledLink
      { ...props }
      view={ view }
      href={ url }
      disabled={ disabled }
      className={ cn(styles.link, className) }
      styles={ isTheme.styles }
    >
      { title }
    </StyledLink>
  );
};

UiSimpleLink.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Определяет вид отображения ссылки */
  view: PropTypes.oneOf(['normal', 'legal']),
  /** Определяется текст ссылки */
  title: PropTypes.string,
  /** Определяет адрес перехода */
  url: PropTypes.string,
  /** Делает элемент неактивным */
  disabled: PropTypes.bool
};

UiSimpleLink.defaultProps = {
  view: 'normal',
  title: 'Link',
  url: '',
  disabled: false
};

export default UiSimpleLink;