import PropTypes from 'prop-types';

import styles from './LeftElement.module.css';

const LeftElement = ({
  leftElement
}) => {

  return (
    <div className={ styles.elements }>
      { leftElement }
    </div>
  );
};

LeftElement.propTypes = {
  leftElement: PropTypes.element
};

export default LeftElement;