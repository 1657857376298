import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import UiInput from '../../Ui/UiInput';

import useEmail from '../../../hooks/use-email';

import { isValidEmail } from '../../../utils/emailUtils';

/**
 * Input email component
 * @public
 * @version 0.0.90
 * @param {String} id
 * @param {Object[]} forms
 * @param {String} forms[].text
 * @param {String} forms[].value
 * @param {Boolean} forms[].valid
 * @param {Function} setForm
 * @param {Object} state
 * @param {String} state.text
 * @param {String} state.value
 * @param {Boolean} state.valid
 * @param {Function} setState
 * @param {String} defaultValue
 * @param {Boolean} [warning=false]
 * @param {Function} onFocus
 * @param {Function} onBlur
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiInputEmail />
 */
const UiInputEmail = ({
  id,
  forms,
  setForm,
  state,
  setState,
  defaultValue,
  warning,
  onFocus,
  onBlur,
  ...props
}) => {

  const [email, { setEmail }] = useEmail(defaultValue);
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    if (Array.isArray(forms) && typeof setForm === 'function' && !!id) {
      const form = forms.find((item) => item.id === id);

      if (typeof form === 'object' && form.text !== undefined && form.value !== undefined) {
        setEmail(form.text);
      } else {
        setForm(id, { text: defaultValue, value: defaultValue, valid: isValidEmail(defaultValue) });
        setEmail(defaultValue);
      }
    } else if (typeof state === 'object' && typeof setState === 'function') {
      if (state.text !== undefined && state.value !== undefined) {
        setEmail(state.text);
      } else {
        setState({ text: defaultValue, value: defaultValue, valid: isValidEmail(defaultValue) });
        setEmail(defaultValue);
      }
    } else {
      setEmail(defaultValue);
    }
  }, [id, forms, setForm, state, setState, defaultValue, setEmail]);

  return (
    <UiInput
      { ...props }
      flag={ undefined }
      value={ email.value }
      warning={ !focus && (!email.valueValid || warning) }
      onChange={ (event) => {
        const email = event.target.value;

        if (typeof setForm === 'function') {
          setForm(id, { text: email, value: email, valid: isValidEmail(email) });
        } else if (typeof setState === 'function') {
          setState({ text: email, value: email, valid: isValidEmail(email) });
        } else {
          setEmail(email);
        }
      } }
      onFocus={ (event) => {
        setFocus(true);

        if (typeof onFocus === 'function') {
          onFocus(event);
        }
      } }
      onBlur={ (event) => {
        setFocus(false);

        if (typeof onBlur === 'function') {
          onBlur(event);
        }
      } }
    />
  );
};

UiInputEmail.propTypes = {
  /** Определяет уникальный идентификатор */
  id: PropTypes.string,
  /** Определяет массив объектов хука useForm (UI) */
  forms: PropTypes.array,
  /** Функция обработчик хука useForm (UI) */
  setForm: PropTypes.func,
  /** Определяет объект хука useState (React) */
  state: PropTypes.object,
  /** Функция обработчик хука useState (React) */
  setState: PropTypes.func,
  /** Определяет значение по умолчанию */
  defaultValue: PropTypes.string,
  /** Текст (value) и примечание (notes) становятся красными */
  warning: PropTypes.bool,
  /** Обработчик получения фокуса на элементе */
  onFocus: PropTypes.func,
  /** Обработчик потери фокуса на элементе */
  onBlur: PropTypes.func
};

UiInputEmail.defaultProps = {
  defaultValue: ''
};

export default UiInputEmail;