import React, { useState } from 'react';
import {
  UiCard, UiDropdown, UiInput, UiSection, useOptions,
} from '@compassplus/ui-mobicash';
import i18n from '../../../i18n';
import ActionSheet from '../../../components/admin/ActionSheet';

const LanguageSelector = () => {
  const isOptions = useOptions();
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const setLanguage = (language) => {
    i18n.changeLanguage(language);
    isOptions.setLanguage(language);
    localStorage.setItem('language', language);
    setIsSelectOpen(false);
  };

  return (
    <UiCard style={{ position: 'relative', margin: '0.25rem 0 1rem' }}>
      <UiInput
        description={i18n.t('Language')}
        value={i18n.t(i18n.language.toUpperCase())}
        rightElement={<UiDropdown />}
        onClick={() => { setIsSelectOpen(!isSelectOpen); }}
        active={isSelectOpen}
        readOnly
      />
      <ActionSheet
        open={isSelectOpen}
        setOpen={() => { setIsSelectOpen(false); }}
      >
        <UiSection
          title={i18n.t('Russian')}
          onClick={() => { setLanguage('ru'); }}
          view="link"
          bottomDivider
        />
        <UiSection
          title={i18n.t('English')}
          view="link"
          onClick={() => { setLanguage('en'); }}
        />
      </ActionSheet>
    </UiCard>
  );
};

export default LanguageSelector;
