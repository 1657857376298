import { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../context/ThemeProvider';

import UiCard from '../UiCard';
import UiCancelForPlates from '../UiCancelForPlates';
import UiImage from '../UiImage';
import UiText from '../UiText';

import styles from './UiShortcut.module.css';

const StyledCard = styled(UiCard)`
  ${ (props) => !props.activeElement && css`
    &:active {
      background: ${ (props) => props.styles.colors.keyboardBackgroundColor };
    }
  ` }

  ${ (props) => props.active && css`
    background: ${ props.styles.colors.keyboardBackgroundColor };
  ` }

  ${ (props) => props.disabled && css`
    cursor: default;
    pointer-events: none;
    -moz-user-select: none;
    user-select: none;

    & > img {
      filter: grayscale(100%);
      opacity: 0.2;
    }

    & > div {
      color: ${ (props) => props.styles.colors.textDisable };
    }
  ` }
`;

/**
 * Shortcut component
 * @public
 * @version 0.0.101
 * @param {String} className
 * @param {Boolean} [active=false]
 * @param {String} [title=Title]
 * @param {String} image
 * @param {Function} onClick
 * @param {Function} onCancel
 * @param {Boolean} [disabled=false]
 * @param {JSX.Element} badge
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiShortcut />
 */
const UiShortcut = ({
  className,
  active,
  title,
  image,
  onClick,
  onCancel,
  disabled,
  badge,
  ...props
}) => {

  const isTheme = useTheme();

  const [activeElement, setActiveElement] = useState(false);

  return (
    <StyledCard
      { ...props }
      className={ cn(styles.shortcut, className) }
      active={ active }
      activeElement={ activeElement }
      shadow={ true }
      onClick={ onClick }
      disabled={ disabled }
      styles={ isTheme.styles }
    >
      <UiImage className={ styles.image } src={ image } />
      <UiText className={ styles.title } view="primary" position="center" typography="dashboard-name">
        { title }
      </UiText>
      { onCancel &&
        <UiCancelForPlates
          className={ styles.element }
          propagation={ false }
          onClick={ onCancel }
          onMouseDown={ () => setActiveElement(true) }
          onMouseUp={ () => setActiveElement(false) }
        />
      }
      { badge &&
        <div className={ styles.badge }>
          { badge }
        </div>
      }
    </StyledCard>
  );
};

UiShortcut.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Определяет контейнер как активный элемент */
  active: PropTypes.bool,
  /** Текстовая надпись на ярлыке */
  title: PropTypes.string,
  /** Изображение на ярлыке */
  image: PropTypes.string,
  /** Делает элемент неактивным */
  disabled: PropTypes.bool,
  /** Функция обработчик клика по ярлыку */
  onClick: PropTypes.func,
  /** Функция обработчик закрытия ярлыка */
  onCancel: PropTypes.func,
  /** Бейдж в правой части компонента */
  badge: PropTypes.element,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

UiShortcut.defaultProps = {
  active: false,
  title: 'Title',
  disabled: false
};

export default UiShortcut;