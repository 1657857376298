import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Plate wallet icon
 * @public
 * @version 1.3.0
 * @param {String} width
 * @param {String} height
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiWalletIcon />
 */
const UiWalletIcon = ({
  width,
  height,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      innerRef={ innerRef }
      width={ width }
      height={ height }
      viewBox="0 0 52 52"
    >
      <path fillRule="evenodd" clipRule="evenodd"
        d="M48.1712 26.8063H44.1082L42.0322 19.9943C41.7642 19.1133 40.6942 18.5813 39.6442 18.8063L34.8242 19.8393L34.8282 19.8353C34.7032 19.7663 34.5642 19.7103 34.4132 19.6723L22.8172 16.7633C22.1892 16.6053 21.5112 16.6623 20.9332 16.9213L2.53824 25.1633C1.58124 25.5923 1.22024 26.5893 1.73224 27.3913L9.50724 39.5893C10.0182 40.3913 11.2082 40.6943 12.1642 40.2653L12.7292 40.0133L12.8812 40.5123C13.1502 41.3933 14.2192 41.9253 15.2692 41.7003L15.7892 41.5883L15.8342 41.5653V42.2843C15.8342 43.1933 16.7132 43.9303 17.7972 43.9303H48.1712C49.2552 43.9303 50.1352 43.1933 50.1352 42.2843V28.4523C50.1352 27.5433 49.2552 26.8063 48.1712 26.8063Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M12.6276 36.0867L12.0676 36.3857C11.1206 36.8927 9.94163 36.5347 9.43463 35.5867L1.72963 21.1677C1.22263 20.2197 1.58063 19.0417 2.52763 18.5347L20.7576 8.79267C21.3306 8.48567 22.0016 8.41867 22.6246 8.60567L34.1156 12.0447C34.2656 12.0887 34.4036 12.1547 34.5266 12.2367"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M11.1551 37.115C10.2811 37.115 9.43407 36.646 8.99407 35.823L1.28807 21.404C0.653067 20.214 1.10307 18.729 2.29207 18.094L20.5221 8.35095C21.2161 7.97995 22.0121 7.89995 22.7681 8.12595L34.2591 11.565C34.4531 11.623 34.6361 11.709 34.8031 11.821C35.0341 11.973 35.0961 12.283 34.9441 12.513C34.7911 12.743 34.4801 12.806 34.2511 12.654C34.1651 12.596 34.0711 12.553 33.9721 12.523L22.4811 9.08395C21.9821 8.93495 21.4541 8.98895 20.9931 9.23295L2.76407 18.975C2.06107 19.351 1.79507 20.229 2.17007 20.932L9.87607 35.351C10.2511 36.054 11.1291 36.322 11.8321 35.945L12.3921 35.646C12.6341 35.516 12.9391 35.607 13.0691 35.851C13.1991 36.094 13.1071 36.398 12.8631 36.528L12.3031 36.826C11.9381 37.022 11.5441 37.115 11.1551 37.115Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M15.66 37.9494L15.145 38.0814C14.104 38.3464 13.044 37.7184 12.779 36.6774L8.73204 20.8374C8.46704 19.7964 9.09504 18.7364 10.136 18.4704L39.3 11.0214C40.341 10.7554 41.401 11.3834 41.666 12.4254L43.723 20.4764"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M14.6643 38.3919C13.6843 38.3919 12.7893 37.7309 12.5363 36.7399L8.49031 20.8989C8.19031 19.7259 8.90131 18.5279 10.0743 18.2279L39.2373 10.7789C39.8063 10.6329 40.3963 10.7189 40.9013 11.0179C41.4063 11.3169 41.7633 11.7949 41.9083 12.3629L43.9653 20.4149C43.9993 20.5489 43.9193 20.6849 43.7853 20.7189C43.6503 20.7549 43.5153 20.6729 43.4813 20.5389L41.4243 12.4869C41.3123 12.0479 41.0363 11.6789 40.6463 11.4479C40.2573 11.2159 39.8003 11.1529 39.3613 11.2639L10.1983 18.7129C9.29231 18.9439 8.74331 19.8699 8.97431 20.7749L13.0203 36.6159C13.2513 37.5209 14.1753 38.0689 15.0833 37.8389L15.5983 37.7069C15.7333 37.6739 15.8683 37.7539 15.9023 37.8879C15.9363 38.0209 15.8553 38.1579 15.7223 38.1919L15.2073 38.3239C15.0253 38.3699 14.8433 38.3919 14.6643 38.3919Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M39.7863 16.3773C39.3323 14.5723 38.0053 14.0753 37.7823 14.1653C36.9893 14.4843 35.1173 15.9403 35.1963 16.2563L35.8003 18.6553C35.8793 18.9703 38.2133 19.3673 39.0653 19.2743C39.3053 19.2483 40.2403 18.1813 39.7863 16.3773Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M31.3699 20.4769C30.9829 20.1299 30.5879 19.5759 30.3789 18.7449C29.9249 16.9399 30.8589 15.8739 31.0969 15.8479C31.9469 15.7529 34.2849 16.1499 34.3649 16.4659L34.9679 18.8649C35.0249 19.0909 34.0819 19.9019 33.2499 20.4609"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M36.5158 17.1994C36.7128 17.9844 36.2368 18.7804 35.4528 18.9774C34.6678 19.1744 33.8718 18.6984 33.6748 17.9144C33.4778 17.1294 33.9538 16.3334 34.7378 16.1364C35.5228 15.9394 36.3188 16.4144 36.5158 17.1994Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M18.6442 16.2806C18.6292 16.2806 18.6142 16.2796 18.5992 16.2766C18.4632 16.2526 18.3732 16.1226 18.3972 15.9866L19.0732 12.2346C19.0982 12.0986 19.2272 12.0086 19.3632 12.0326C19.4992 12.0576 19.5892 12.1876 19.5652 12.3236L18.8902 16.0746C18.8682 16.1956 18.7632 16.2806 18.6442 16.2806Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M13.2255 17.9109C13.1335 17.9109 13.0455 17.8599 13.0015 17.7719C12.9085 17.5829 12.8535 17.3809 12.8405 17.1699C12.8135 16.7509 12.9525 16.3469 13.2305 16.0329C13.5075 15.7179 13.8915 15.5299 14.3105 15.5039C14.7255 15.4779 15.1325 15.6149 15.4475 15.8929C15.7625 16.1709 15.9505 16.5549 15.9765 16.9739C15.9855 17.1109 15.8805 17.2299 15.7425 17.2389C15.6045 17.2479 15.4855 17.1419 15.4775 17.0049C15.4595 16.7189 15.3315 16.4579 15.1175 16.2679C14.9025 16.0789 14.6265 15.9859 14.3415 16.0029C14.0565 16.0199 13.7945 16.1489 13.6055 16.3629C13.4155 16.5779 13.3215 16.8529 13.3395 17.1389C13.3485 17.2829 13.3855 17.4209 13.4495 17.5499C13.5105 17.6739 13.4605 17.8239 13.3365 17.8849C13.3005 17.9029 13.2635 17.9109 13.2255 17.9109Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M47.0876 40.717H18.3126C16.8716 40.717 15.7046 39.5491 15.7046 38.1091V23.0851C15.7046 21.6441 16.8716 20.4771 18.3126 20.4771H47.0876C48.5276 20.4771 49.6956 21.6441 49.6956 23.0851V38.1091C49.6956 39.5491 48.5276 40.717 47.0876 40.717Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M18.3123 20.9769C17.1503 20.9769 16.2043 21.9229 16.2043 23.0849V38.1089C16.2043 39.2709 17.1503 40.2169 18.3123 40.2169H47.0873C48.2503 40.2169 49.1963 39.2709 49.1963 38.1089V23.0849C49.1963 21.9229 48.2503 20.9769 47.0873 20.9769H18.3123ZM47.0873 41.2169H18.3123C16.5983 41.2169 15.2043 39.8229 15.2043 38.1089V23.0849C15.2043 21.3709 16.5983 19.9769 18.3123 19.9769H47.0873C48.8013 19.9769 50.1963 21.3709 50.1963 23.0849V38.1089C50.1963 39.8229 48.8013 41.2169 47.0873 41.2169Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M33.3635 35.22H19.7305C19.5925 35.22 19.4805 35.108 19.4805 34.97C19.4805 34.832 19.5925 34.72 19.7305 34.72H33.3635C33.5015 34.72 33.6135 34.832 33.6135 34.97C33.6135 35.108 33.5015 35.22 33.3635 35.22Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M26.7165 37.7689H19.5745C19.4365 37.7689 19.3245 37.6569 19.3245 37.5189C19.3245 37.3809 19.4365 37.2689 19.5745 37.2689H26.7165C26.8545 37.2689 26.9665 37.3809 26.9665 37.5189C26.9665 37.6569 26.8545 37.7689 26.7165 37.7689Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M44.5277 37.7689H29.2397C29.1017 37.7689 28.9897 37.6569 28.9897 37.5189C28.9897 37.3809 29.1017 37.2689 29.2397 37.2689H44.5277C44.6657 37.2689 44.7777 37.3809 44.7777 37.5189C44.7777 37.6569 44.6657 37.7689 44.5277 37.7689Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M24.2991 31.0402H22.2301C21.1811 31.0402 20.3311 30.1902 20.3311 29.1422V27.4362C20.3311 26.3872 21.1811 25.5372 22.2301 25.5372H24.2991C25.3471 25.5372 26.1971 26.3872 26.1971 27.4362V29.1422C26.1971 30.1902 25.3471 31.0402 24.2991 31.0402Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M22.2298 25.7874C21.3208 25.7874 20.5818 26.5264 20.5818 27.4354V29.1414C20.5818 30.0514 21.3208 30.7904 22.2298 30.7904H24.2988C25.2078 30.7904 25.9468 30.0514 25.9468 29.1414V27.4354C25.9468 26.5264 25.2078 25.7874 24.2988 25.7874H22.2298ZM24.2988 31.2904H22.2298C21.0448 31.2904 20.0818 30.3264 20.0818 29.1414V27.4354C20.0818 26.2514 21.0448 25.2874 22.2298 25.2874H24.2988C25.4838 25.2874 26.4468 26.2514 26.4468 27.4354V29.1414C26.4468 30.3264 25.4838 31.2904 24.2988 31.2904Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M22.1965 27.7966H20.3315C20.1935 27.7966 20.0815 27.6846 20.0815 27.5466C20.0815 27.4086 20.1935 27.2966 20.3315 27.2966H22.1965C22.3345 27.2966 22.4465 27.4086 22.4465 27.5466C22.4465 27.6846 22.3345 27.7966 22.1965 27.7966Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M22.1965 29.236H20.3315C20.1935 29.236 20.0815 29.124 20.0815 28.986C20.0815 28.848 20.1935 28.736 20.3315 28.736H22.1965C22.3345 28.736 22.4465 28.848 22.4465 28.986C22.4465 29.124 22.3345 29.236 22.1965 29.236Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M26.1629 27.8538H24.2979C24.1599 27.8538 24.0479 27.7418 24.0479 27.6038C24.0479 27.4658 24.1599 27.3538 24.2979 27.3538H26.1629C26.3009 27.3538 26.4129 27.4658 26.4129 27.6038C26.4129 27.7418 26.3009 27.8538 26.1629 27.8538Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M26.1629 29.2933H24.2979C24.1599 29.2933 24.0479 29.1813 24.0479 29.0433C24.0479 28.9053 24.1599 28.7933 24.2979 28.7933H26.1629C26.3009 28.7933 26.4129 28.9053 26.4129 29.0433C26.4129 29.1813 26.3009 29.2933 26.1629 29.2933Z"
        fill={ isTheme.styles.colors.textTitle } />
    </Element>
  );
};

UiWalletIcon.propTypes = {
  /** Определяет ширину иконки */
  width: PropTypes.string,
  /** Определяет высоту иконки */
  height: PropTypes.string
};

UiWalletIcon.defaultProps = {
  width: '3.25rem',
  height: '3.25rem'
};

export default UiWalletIcon;