import React from 'react';
import { UiIcon } from '@compassplus/ui-mobicash';
import { useHistory, useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import LoadingMarketItemCard from '../../../../components/loadingCards/LoadingMarketItemCard';
import MarketStore from '../../../../stores/MarketStore';
import {
  CardDescription, CardImage, CardPrice, CardText, CardTitle, InCartBage, ItemsContainer, MarketCard,
} from '../style';
import ItemStore from '../../../../stores/ItemStore';
import ShoppingCart from '../../../../stores/ShoppingCartStore';
import i18n from '../../../../i18n';

const Items = observer(() => {
  const history = useHistory();
  const { currentItems, displayedItemsCount, isLoading } = MarketStore;
  const params = useParams();
  const merchantURL = params.merchant;

  const onCardClick = (item) => {
    if (item.article !== ItemStore.article) {
      ItemStore.isLoading = true;
    }
    history.push(`/${merchantURL}/order/${item.article}`, { goBack: true });
  };

  const renderItemCard = (item, index) => {
    const isActive = item.maxvalue > 0 || (item.isInfiniteAmount && item.availability && item.activeByTime);
    return (
      <React.Fragment key={`${item.title}-${index}`}>
        <MarketCard
          shadow
          onClick={() => onCardClick(item)}
        >
          { ShoppingCart.checkItemInCartById(item.article) ? (
            <InCartBage type="text" color="#1560BD" text={i18n.t('InCart')} />
          ) : null}
          <div style={{ width: '148px', height: '148px' }}>
            <CardImage style={!isActive ? { opacity: '0.6' } : { opacity: '1' }} src={item.defaultImg.url} />
          </div>
          <CardText>
            <CardTitle typography="section-title" style={isActive ? { textOverflow: 'ellipsis' } : { textOverflow: 'ellipsis', opacity: '0.6' }}>{item.title}</CardTitle>
            <CardDescription style={!isActive ? { opacity: '0.6' } : { opacity: '1' }}>
              {item.description.length > 80 ? `${item.description.substr(0, 80)}...` : item.description}
            </CardDescription>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <CardPrice
                style={!isActive ? { opacity: '0.6' } : { opacity: '1' }}
              >
                {isActive ? `${(item.price / 100).toFixed(2)} ${i18n.t(item.currency)}` : i18n.t('OutOfStock')}
              </CardPrice>
              {
                isActive && (
                  <UiIcon icon="cart" setColor="#037AFF" />
                )
              }
            </div>
          </CardText>
        </MarketCard>
      </React.Fragment>
    );
  };

  if (isLoading) {
    return (
      <ItemsContainer>
        <LoadingMarketItemCard />
        <LoadingMarketItemCard />
        <LoadingMarketItemCard />
        <LoadingMarketItemCard />
        <LoadingMarketItemCard />
        <LoadingMarketItemCard />
        <LoadingMarketItemCard />
        <LoadingMarketItemCard />
      </ItemsContainer>
    );
  }

  return (
    <ItemsContainer>
      {currentItems && currentItems.map((item, index) => (
        <>
          {item && index < displayedItemsCount ? renderItemCard(item) : null}
        </>
      ))}
    </ItemsContainer>
  );
});

export default Items;
