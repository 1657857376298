import React, { useState } from 'react';
import {
  UiText, UiCard, UiSection, UiIcon, UiArrow,
} from '@compassplus/ui-mobicash';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react-lite';
import DeliveryFormClass from '../../../controllers/delivery/DeliveryFormClass';
import i18n from '../../../i18n';
import DeliveryForms from '../../../stores/DeliveryForms';
import DeliveryStore from '../../../stores/DeliveryStore';
import { Text } from '../../TemplateStyle';

const DeliverySettings = observer(() => {
  const history = useHistory();
  const [currentLanguage] = useState(i18n.language);

  return (
    <div style={{ marginTop: '0.5rem' }}>
      <Text style={{ textTransform: 'uppercase' }} view="readonly" typography="section-description-semibold">{i18n.t('IssuingMethods')}</Text>
      {
        DeliveryForms.forms.length > 0 && (
          <UiCard style={{ margin: '0.25rem 0' }}>
            {
              DeliveryForms.forms.map((form, index) => (
                <React.Fragment key={`${form.title.en}-${index}`}>
                  <UiSection
                    title={form.title[currentLanguage]}
                    rightElement={<UiArrow />}
                    onClick={() => {
                      DeliveryStore.setForm(new DeliveryFormClass(form));
                      DeliveryStore.changedFlag = true;
                      history.push(`/administration/delivery/${form._id}`);
                    }}
                    bottomDivider={index !== DeliveryForms.forms.length - 1}
                  />
                </React.Fragment>
              ))
            }
          </UiCard>
        )
      }
      <UiCard style={{ margin: '0.75rem 0 0' }}>
        <UiSection
          leftElement={<UiIcon setColor="#037AFF" icon="legacy-plus" />}
          title={i18n.t('AddIssuingMethod')}
          view="link"
          onClick={() => { DeliveryStore.clearForm(); history.push('/administration/delivery/'); }}
        />
      </UiCard>
    </div>
  );
});

export default DeliverySettings;
