import React, { useState, useEffect } from 'react';

import {
  UiCard,
  UiInput,
  UiSwitch,
  UiButton,
  UiScrollArrow,
} from '@compassplus/ui-mobicash';
import ImagesUpload from '../../../components/admin/ImagesUpload';

import i18n from '../../../i18n';
import { request, getCurrency } from '../../../Utils/requestUtil';
import { uploadImage } from '../../../Utils/UploadData';
import { getLocale } from '../../../Utils/Hoocks';

import {
  AnnotationSection,
  StatusDescriptionText,
} from '../ModifyGroupPage/style';

import ModalStore from "../../../stores/ModalStore";
import ItemStore from "../../../stores/ItemStore";
import LanguageTabs from "../../../components/admin/LanguageTabs";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router";
import CurrentItemCard from "../../../components/admin/CurrentItemCard";
import {action} from "mobx";
import LoadingModifyItemFields from "../../../components/loadingCards/LoadingModifyItemFields";
import ParentGuidsDropdown from "../../../components/admin/ParentGuidsDropdown";
import Attributes from "../../../components/admin/Attributes/Attributes";
import CountDependenciesSwitch from "../../../components/admin/CountDependenciesSwitch/CountDependenciesSwitch";
import AttributesStore from "../../../stores/AttributesStore";
import {SubContentStiky} from "../ModifyAttributePage/style";
import GlobalStateStore from "../../../stores/GlobalStateStore";

const ModifyItem = observer((props) => {
  const { itemId, disableSave, groupId } = props;
  const rootGroup = props.location.state?.rootGroup || null;
  const history = useHistory();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const [modeIsModify, setModeIsModify] = useState(false);
  const [parentGuids, setParentGuids] = useState([]);

  useEffect(() => {
    if (groupId && ItemStore.groupGuid !== groupId) {
      action(() => {
        ItemStore.groupGuid = groupId;
      });
    }
    if (!groupId) {
      if (rootGroup) {
        action(() => {
          ItemStore.groupGuid = rootGroup._id;
        });
      }
    }
  }, [groupId])

  useEffect(action(() => {
    if (!ItemStore.groupGuid && props?.location?.state?.groupId) {
      ItemStore.groupGuid = props.location.state.groupId
    }
  }), [])

  const getParentGuids = () => {
    request('/api/v1/group', 'GET').then(
      (groups) => {
        const parentValues = [];
        groups.forEach((element) => {
          parentValues.push({ key: element._id, value: getLocale(i18n.language, element.title) });
        });
        setParentGuids(parentValues);
      },
      (error) => {
        ModalStore.openErrorWithMessage(i18n.t(`${error}`));
        action(() => {
          ItemStore.isLoading = false;
        });
      },
    );
  };

  const isSizeAttributeAdded = () => {
    const getColorIndex = ItemStore.attributes.findIndex((el) => (el.type === 'size'));
    return getColorIndex !== -1;
  };

  useEffect(action(() => {
    if ((!isSizeAttributeAdded())) {
      ItemStore.differentPriceFlag = false;
    }
  }), [ItemStore.attributes]);

  useEffect(action(() => {
    if (itemId) {
      ItemStore.setItem(itemId);
      setModeIsModify(true);
    }

    getParentGuids();
  }), [itemId]);

  useEffect(action(() => {
    if (ItemStore.attributeDependentPrice.length !== 0 && ItemStore.differentPriceFlag) {
      for (let i = 0; i < ItemStore.attributeDependentPrice.length; i++) {
        if (Number(ItemStore.attributeDependentPrice[i].price) !== 0) {
          ItemStore.price = (ItemStore.attributeDependentPrice[i].price);
          break;
        }
      }
    }
  }), [ItemStore.attributeDependentPrice]);

  const uploadImages = () => new Promise((resolve, reject) => {
    const uploadedImages = [];

    if (ItemStore.images.length === 0) {
      resolve(null);
    }

    ItemStore.images.forEach((imageToUpload) => {
      if (imageToUpload.type === 'url' || imageToUpload.type === 'file') {
        uploadedImages.push({
          url: imageToUpload.url, isMain: imageToUpload.isMain, type: imageToUpload.type, color: imageToUpload.color,
        });

        if (uploadedImages.length === ItemStore.images.length) {
          resolve(uploadedImages);
        }
      } else {
        uploadImage(imageToUpload.url, 'item').then(
          (result) => {
            uploadedImages.push({
              url: result, isMain: imageToUpload.isMain, type: 'file', color: imageToUpload.color,
            });

            if (uploadedImages.length === ItemStore.images.length) {
              resolve(uploadedImages);
            }
          },
          (error) => {
            reject(error);
          },
        );
      }
    });
  });

  const prepareRequestData = () => new Promise((resolve, reject) => {
    ItemStore.fillRequestData().then(
      (data) => {
        const requestData = data;
        uploadImages().then(
          (uploadedImages) => {
            requestData.images = uploadedImages;
            resolve(requestData);
          },
          (error) => {
            reject(error);
          },
        );
      },
      (error) => {
        if (error.message === 'Zero prices') {
          action(() => {
            ModalStore.isOpen = true;
            ModalStore.text = i18n.t('Fill in automatically');
            ModalStore.buttons = [
              {
                text: i18n.t('Yes'),
                mode: 'bold',
                action: (event) => { autoFillZeroPrices(); onModify(event); ModalStore.setDefaultModal(); },
              },
              {
                text: i18n.t('No'),
                mode: 'normal',
                action: () => { ModalStore.setDefaultModal(); },
              },
            ];
          });
        } else {
          reject(error);
        }
      },
    );
  });

  const createRequest = (requestData) => {
    request('/api/v1/item', 'POST', requestData).then(
      (createdItem) => history.push(`/main/${createdItem.groupGuid}`),
      (error) => {
        ModalStore.openErrorWithMessage(i18n.t(`${error}`));
        action(() => {
          ItemStore.isLoading = false;
        });
      },
    );
  };

  const onCreate = (event) => {
    event.preventDefault();
    action(() => {
      ItemStore.isLoading = true;
    });

    prepareRequestData().then(
      (requestData) => {
        createRequest(requestData);
      },
      (error) => {
        action(() => {
          ItemStore.isLoading = false;
        });
        ModalStore.openWithMessage(i18n.t(`Fill ${error.message} fields`));
      },
    );
  };

  const modifyRequest = (requestData) => {
    request(`/api/v1/item/${itemId}`, 'PUT', requestData).then(
      (updatedItem) => history.push(`/main/${updatedItem.groupGuid}`, { itemId }),
      (error) => {
        ModalStore.openErrorWithMessage(i18n.t(`${error}`));
        action(() => {
          ItemStore.isLoading = false;
        });
      },
    );
  };

  const onModify = (event) => {
    event.preventDefault();
    action(() => {
      ItemStore.isLoading = true;
    });

    prepareRequestData().then(
      (requestData) => {
        modifyRequest(requestData);
      },
      (error) => {
        action(() => {
          ItemStore.isLoading = false;
        });
        ModalStore.openWithMessage(i18n.t(`Fill ${error.message} fields`));
      },
    );
  };

  const autoFillZeroPrices = () => {
    const filledPrices = ItemStore.attributeDependentPrice;
    for (let i = 0; i < filledPrices.length; i++) {
      if (Number(filledPrices[i].price) === 0) { filledPrices[i].price = ItemStore.price; }
    }
    ItemStore.attributeDependentPrice = filledPrices;
  };

  const getTabContent = (language) => (
    <>
      {
        ItemStore.isLoading ? <LoadingModifyItemFields/> : (
          <UiCard shadow>
            <ParentGuidsDropdown
              description={i18n.t('Category')}
              value={parentGuids.find((element) => element.key === ItemStore.groupGuid)?.value || i18n.t('WithoutCategory')}
              type="item"
            />
            <UiInput
              description={i18n.t('ItemName')}
              required
              placeholder={`[${i18n.t('Text')}]`}
              value={ItemStore.title[language]}
              onChange={action((event) => {
                ItemStore.title[language] = event.target.value;
              })}
              bottomDivider
            />
            <UiInput
              description={i18n.t('Description')}
              placeholder={`[${i18n.t('Text')}]`}
              value={ItemStore.description[language]}
              onChange={action((event) => {
                ItemStore.description[language] = event.target.value;
              })}
              bottomDivider
            />
            <UiInput
              description={`${i18n.t('Price')}, ${i18n.t(getCurrency())}`}
              placeholder="0.00"
              value={ItemStore.price}
              onChange={ItemStore.onChangePrice}
              onBlur={ItemStore.onPriceBlur}
              bottomDivider
              required
              readonly={!ItemStore.differentPriceFlag}
            />
            <UiInput
              description={i18n.t('Unit')}
              required
              placeholder={`[${i18n.t('Text')}]`}
              value={ItemStore.unit[language]}
              onChange={action((event) => {
                ItemStore.unit[language] = event.target.value;
              })}
              bottomDivider
            />
            <UiInput
              description={i18n.t('TotalAmount')}
              placeholder="[0]"
              value={ItemStore.isInfiniteAmount ? i18n.t('Unlimited') : ItemStore.totalAmount}
              readonly={ItemStore.isInfiniteAmount}
              onChange={ItemStore.onChangeTotalAmount}
              bottomDivider
              readOnly={AttributesStore.isCountDependsOnAttributes}
              rightElement={
                <UiSwitch
                  checked={ItemStore.isInfiniteAmount}
                  onChange={action(() => {
                    ItemStore.isInfiniteAmount = !ItemStore.isInfiniteAmount;
                    ItemStore.availability = 'Available';
                  })}
                />
              }
            />
            <UiInput
              description={i18n.t('Availability')}
              readOnly
              value={i18n.t(ItemStore.availability)}
              rightElement={
                <UiScrollArrow
                  onClick={action(() => {
                    ItemStore.availability === 'Available'
                      ? ItemStore.availability = 'NotAvailable'
                      : ItemStore.availability = 'Available';
                  })}
                />
              }
              bottomDivider
            />
            <UiInput
              description={i18n.t('Status')}
              readOnly
              value={i18n.t(ItemStore.status)}
              rightElement={
                <UiSwitch
                  checked={ItemStore.status === 'Active'}
                  onClick={action(() => {
                    ItemStore.status === 'Active'
                      ? ItemStore.status = 'Inactive'
                      : ItemStore.status = 'Active';
                  })}
                />
              }
            />
            <AnnotationSection
              style={{ margin: 0 }}
              title={
                <StatusDescriptionText>
                  {i18n.t('StatusAnnotation')}
                </StatusDescriptionText>
              }
            />
          </UiCard>
        )
      }
    </>
  );

  return (
    <>
      <CurrentItemCard modeIsModify={modeIsModify} shareLinkOpened={GlobalStateStore.isShareLinkOpen}  />
      <LanguageTabs title={i18n.t('ItemDetails')} setSelectedLanguage={setSelectedLanguage}>
        {getTabContent}
      </LanguageTabs>
      <Attributes language={selectedLanguage} />
      {
        !ItemStore.isInfiniteAmount ? (
          <CountDependenciesSwitch />
        ) : null
      }
      <ImagesUpload />
      <SubContentStiky>
        <UiButton title={i18n.t('Save')} disabled={disableSave} onClick={modeIsModify ? onModify : onCreate} />
      </SubContentStiky>
    </>
  );
});

export default ModifyItem;
