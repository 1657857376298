import React, { useEffect, useState } from 'react';
import { UiButton } from '@compassplus/ui-mobicash';
import { useHistory } from 'react-router';
import i18n from '../../../../../i18n';
import {
  getCurrency,
  getMerchantId,
  request,
} from '../../../../../Utils/requestUtil';
import Delivery from '../../../../../components/admin/DeliverySettings';
import ModalStore from '../../../../../stores/ModalStore';
import ChangePricesBlock from './ChangePricesBlock';
import LinksSettings from "../LinkSettings/LinksSettings";
import GlobalStateStore from "../../../../../stores/GlobalStateStore";
import {observer} from "mobx-react-lite";

const MarketAdministration = observer(({ location }) => {
  const history = useHistory();

  const [priceAddition, setPriceAddition] = useState('');
  const [priceAdditionForm, setPriceAdditionForm] = useState(getCurrency);
  const [savedFlag, setSavedFlag] = useState(false);

  useEffect(() => {
    if (savedFlag) {
      setTimeout(() => {
        setSavedFlag(false);
      }, 1000);
    }
  }, [savedFlag]);

  const requestToChangePrices = () => {
    const requestData = {
      merchantId: getMerchantId(),
      priceAddition,
      additionForm: priceAdditionForm,
    };
    request('/api/v1/group/updatePrices', 'POST', requestData).then(
      () => {
        setPriceAddition('');
      },
      (error) => {
        ModalStore.openErrorWithMessage(i18n.t(error));
      },
    );
  };

  return (
    <>
      <LinksSettings location={location} />
      {!GlobalStateStore.cms && !GlobalStateStore.isLoading ? (
        <ChangePricesBlock
          priceAddition={priceAddition}
          setPriceAddition={setPriceAddition}
          priceAdditionForm={priceAdditionForm}
          setPriceAdditionForm={setPriceAdditionForm}
        />
      ) : null
      }
      <Delivery />
      <UiButton
        style={{ marginTop: '1rem' }}
        title={savedFlag ? i18n.t('Saved') : i18n.t('SaveChanges')}
        disabled={savedFlag}
        onClick={() => {
          if (priceAddition !== '') {
            ModalStore.isOpen = true;
            ModalStore.title = i18n.t('ChangingPrices');
            ModalStore.text = `${i18n.t('Changing all prices for')} ${priceAddition} ${i18n.t(priceAdditionForm)}`;
            ModalStore.buttons = [
              {
                text: i18n.t('Confirm'),
                mode: 'bold',
                action: () => {
                  requestToChangePrices();
                  ModalStore.setDefaultModal();
                },
              },
              {
                text: i18n.t('Cancel'),
                mode: 'normal',
                action: () => {
                  ModalStore.setDefaultModal();
                },
              },
            ];
          }
          history.push('/administration');
        }}
      />
    </>
  );
});

export default MarketAdministration;
