import React from 'react';
import i18n from '../../../i18n';
import { copiedToClipboardNotification } from '../../../Utils/notification';
import {
  Logo, ShareLinkButtons, SocialButton,
} from '../ShareLink/style';
import { Dialog, DialogInput, MainText } from '../../TemplateStyle';

const SharePaymentLink = ({
  link, active, setActive
}) => {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  const vkButton = () => (
    <SocialButton>
      <a
        target="_blank"
        href={isMobile ? `https://m.vk.com/share.php?url=${link}noparse=true` : `https://vk.com/share.php?url=${link}noparse=true`}
        rel="noreferrer"
      >
        <Logo src="/images/social/vk.svg" />
      </a>
    </SocialButton>
  );

  const tgButton = () => (
    <SocialButton>
      <a
        target="_blank"
        href={`https://t.me/share/url?url=${link}`}
        rel="noreferrer"
      >
        <Logo src="/images/social/telegram.png" />
      </a>
    </SocialButton>
  );

  const viberButton = () => (
    <SocialButton>
      <a
        target="_blank"
        href={`viber://forward?text=${link}`}
        rel="noreferrer"
      >
        <Logo src="/images/social/viber.png" />
      </a>
    </SocialButton>
  );

  const content = () => (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <DialogInput
          id="link"
          onClick={() => { document.getElementById('link').select(); navigator.clipboard.writeText(link).then(() => { copiedToClipboardNotification(); }); }}
          value={link}
          readOnly
        />
      </div>
      <MainText style={{ textAlign: 'center', fontSize: '0.75rem', marginTop: '0.5rem' }}>{i18n.t('ShareInSocial')}</MainText>
      <ShareLinkButtons>
        {vkButton()}
        {tgButton()}
        {(isMobile) && (
          <>
            {viberButton()}
          </>
        )}
      </ShareLinkButtons>
    </>
  );

  if (active) {
    return (
      <Dialog
        title={i18n.t('Share')}
        text={i18n.t('eInvoicingLink')}
        buttons={[
          {
            text: i18n.t('CopyLink'),
            mode: 'bold',
            action: () => {
              setActive(false);
              navigator.clipboard.writeText(link).then(() => {
                copiedToClipboardNotification();
              });
            },
          },
          {
            text: i18n.t('Cancel'),
            mode: '',
            action: () => {
              setActive(false);
            },
          },
        ]}
      >
        {content()}
      </Dialog>
    );
  }
  return null;
};

export default SharePaymentLink;
