import { makeAutoObservable } from 'mobx';
import {getAuth, getCashierAuth} from '../Utils/requestUtil';

class GlobalStateStore {
  constructor() {
    this.isAuth = getAuth();
    this.isCashierAuth = getCashierAuth();
    this.isLoading = false;
    this.location = {};
    this.isMarket = false;
    this.headerTitle = 'eMarketPlace';
    this.headerIsBack = false;
    this.headerIsActive = false;
    this.headerOnBack = undefined;
    this.headerHelp = false;
    this.headerContent = null;
    this.headerRenderRightElement = null;
    this.isSidebarOpen = false;
    this.sidebarClassName = '';
    this.selectedIds = [];

    this.isShareLinkOpen = false; // Нужен когда возвращаемся со страницы входа кассира на страницу товара или категории.
    this.isMarketShareLinkOpen = false; // Нужен когда возвращаемся со страницы входа кассира на главную страницу.

    makeAutoObservable(this);
  }

  setHeaderProperties({
    title, isBack, onBack, isActive, help, content, renderRightElement,
  }) {
    this.headerTitle = title;
    this.headerIsBack = isBack || false;
    this.headerOnBack = onBack;
    this.headerIsActive = isActive;
    this.headerHelp = help || false;
    this.headerContent = content || null;
    this.headerRenderRightElement = renderRightElement;

    if (typeof this.headerIsBack !== 'boolean') throw new Error('isBack parameter must be boolean');
    if (typeof this.headerHelp !== 'boolean') throw new Error('help parameter must be boolean');
  }
}

export default new GlobalStateStore();
