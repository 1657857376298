import cn from 'classnames';
import styled from 'styled-components';

import { useTheme } from '../../../../../context/ThemeProvider';

import styles from './BottomDivider.module.css';

const StyledBottomDivider = styled.div`
  background: ${ (props) => props.styles.colors.sectionBackground };
  box-shadow: ${ (props) => props.styles.shadows.mainShadow };
`;

const BottomDivider = ({
  className
}) => {

  const isTheme = useTheme();

  return (
    <StyledBottomDivider className={ cn(styles.divider, className) } styles={ isTheme.styles } />
  );
}

export default BottomDivider;