import React, { useEffect, useReducer, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  UiCard, UiArrow, UiSection, UiInput, UiIcon, UiScrollArrow,
} from '@compassplus/ui-mobicash';
import i18n from '../../../../i18n';
import { getCurrency } from '../../../../Utils/requestUtil';
import DeliveryStore from '../../../../stores/DeliveryStore';
import { CheckIsMobile } from '../../../../Utils/Hoocks';

const CostFields = observer(() => {
  const isMobile = CheckIsMobile();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [errMessageIndex, setErrMessageIndex] = useState(-1);

  useEffect(() => {
    if (errMessageIndex !== -1) {
      setTimeout(() => {
        setErrMessageIndex(-1);
      }, 5000);
    }
  }, [errMessageIndex]);

  const onFromOrderPriceInput = (value, index) => {
    const newvalue = value.replace(/[^0-9.]/g, '');
    DeliveryStore.form.cost.setFromOrderPrice(index, newvalue);
    forceUpdate();
  };

  const onFromOrderPriceBlur = (value, index) => {
    const costDependenciesIndex = index;
    if (DeliveryStore.form.cost.checkOrderCostUnique(costDependenciesIndex, Number(DeliveryStore.form.cost.getFromOrderPriceByIndex(costDependenciesIndex)).toFixed(2))) {
      DeliveryStore.form.cost.setFromOrderPrice(costDependenciesIndex, Number(DeliveryStore.form.cost.getFromOrderPriceByIndex(costDependenciesIndex)).toFixed(2));
      if (Number(DeliveryStore.form.cost.getToOrderPriceByIndex(costDependenciesIndex)) !== 0) {
        if (Number(DeliveryStore.form.cost.getToOrderPriceByIndex(costDependenciesIndex)) <= Number(DeliveryStore.form.cost.getFromOrderPriceByIndex(costDependenciesIndex))) {
          DeliveryStore.form.cost.setToOrderPrice(costDependenciesIndex, '');
        }
      }
      DeliveryStore.form.cost.getToOrderPriceInDependencies(costDependenciesIndex);
    } else {
      setErrMessageIndex(costDependenciesIndex);
      DeliveryStore.form.cost.setFromOrderPrice(costDependenciesIndex, Number(DeliveryStore.form.cost.getMaximumOrderPriceInDependencies() + 1).toFixed(2));
      DeliveryStore.form.cost.getToOrderPriceInDependencies(costDependenciesIndex);
    }

    forceUpdate();
  };

  const onDependenceDeliveryPriceInput = (value, index) => {
    const costDependenceIndex = index;
    value = value.replace(/[^0-9.]/g, '');
    DeliveryStore.form.cost.setDeliveryPrice(costDependenceIndex, value);
    forceUpdate();
  };

  const onDependenceDeliveryPriceBlur = (value, index) => {
    const costDependenceIndex = index;
    DeliveryStore.form.cost.setDeliveryPrice(costDependenceIndex, Number(DeliveryStore.form.cost.getDeliveryPriceByIndex(costDependenceIndex)).toFixed(2));
    forceUpdate();
  };

  const onAddCostDependence = () => {
    const newDependence = {
      type: 'moreThen',
      fromOrderPrice: (Number(DeliveryStore.form.cost.getMaximumOrderPriceInDependencies() + 1)).toFixed(2),
      toOrderPrice: '',
      deliveryPrice: '0.00',
      deliveryPriceType: 'value',
    };

    DeliveryStore.form.cost.addCostDependencies(newDependence);
    DeliveryStore.form.cost.getToOrderPriceInDependencies();
    forceUpdate();
  };

  const renderCostSettings = () => {
    switch (DeliveryStore.form.cost.type) {
      case 'dependsOnAmount':
        return (
          <div style={isMobile ? { padding: '0 1rem 0', marginTop: '1rem', width: '100%' } : {
            padding: '0 0 0 6px',
            width: '100%',
          }}
          >
            {
              DeliveryStore.form.cost.returnMinimumOrderPriceMessage() !== '' && (
                <UiCard>
                  <UiInput
                    description={i18n.t('MinimumOrderPrice')}
                    value={DeliveryStore.form.cost.returnMinimumOrderPriceMessage()}
                    readOnly
                  />
                </UiCard>
              )
            }
            {
                DeliveryStore.form.cost.costDependencies.map((depend, index) => (
                  <React.Fragment key={`${depend}`}>
                    <UiCard style={{ margin: '0.25rem 0' }}>
                      <UiInput
                        id={index}
                        description={i18n.t('OrderCostFrom')}
                        placeholder="0.00"
                        value={DeliveryStore.form.cost.getFromOrderPriceByIndex(index)}
                        onChange={(event) => { onFromOrderPriceInput(event.target.value, index); }}
                        onBlur={(event) => { onFromOrderPriceBlur(event.target.value, index); }}
                        rightElement={(
                          <UiIcon
                            icon="delete"
                            setColor="#037AFF"
                            onClick={() => {
                              DeliveryStore.form.cost.deleteCostDependence(index);
                              DeliveryStore.form.cost.getToOrderPriceInDependencies();
                              forceUpdate();
                            }}
                          />
                        )}
                        bottomDivider
                      />
                      {(DeliveryStore.form.cost.getDependenceTypeByIndex(index) === 'interval') && (
                        <UiInput
                          description={i18n.t('To')}
                          placeholder={DeliveryStore.form.cost.getToOrderPriceByIndex(index)}
                          readOnly
                          bottomDivider
                        />
                      )}

                      <UiInput
                        description={
                          `${i18n.t('CostOfDelivery')}, ${DeliveryStore.form.cost.getDeliveryPriceTypeByIndex(index) === 'value' ? i18n.t(getCurrency()) : '%'}`
                        }
                        value={(DeliveryStore.form.cost.getDeliveryPriceByIndex(index) == 0) ? ('') : (DeliveryStore.form.cost.getDeliveryPriceByIndex(index))}
                        placeholder={i18n.t('Free')}
                        onChange={(event) => { onDependenceDeliveryPriceInput(event.target.value, index); }}
                        onBlur={(event) => { onDependenceDeliveryPriceBlur(event.target.value, index); }}
                        autoComplete="off"
                        rightElement={(
                          <UiScrollArrow
                            onClick={() => {
                              (DeliveryStore.form.cost.getDeliveryPriceTypeByIndex(index) === 'value') ? DeliveryStore.form.cost.setDeliveryPriceType(index, 'percent')
                                : DeliveryStore.form.cost.setDeliveryPriceType(index, 'value');
                              forceUpdate();
                            }}
                          />
                        )}
                      />
                    </UiCard>
                  </React.Fragment>
                ))
              }

            <UiCard style={{ margin: '0.25rem 0 0' }}>
              <UiSection
                title={i18n.t('AddDependence')}
                onClick={() => { onAddCostDependence(); }}
                rightElement={<UiArrow />}
              />
            </UiCard>
          </div>
        );
      default:
        return (
          <>
          </>
        );
    }
  };

  return (
    <>
      {renderCostSettings()}
    </>
  );
});

export default CostFields;
