import React from 'react';
import {BodyDiv, ContentDiv, MContentDiv} from "../../../components/TemplateStyle";
import {useParams} from "react-router";
import OrderData from "./OrderData";
import {useOptions} from "@compassplus/ui-mobicash";

const OrderStatusPage = () => {
  const params = useParams();
  const orderId = params.orderId;
  const isOptions = useOptions();
  const getBodyDivStyle = () => {
    if (isOptions.screen === 'mobile') {
      return { background: '#FBFBFB', padding: '0' };
    }
    return { background: '#FBFBFB', padding: '0' };
  };
  return (
    <BodyDiv style={getBodyDivStyle()}>
      <ContentDiv>
        <MContentDiv>
          <OrderData orderId={orderId} />
        </MContentDiv>
      </ContentDiv>
    </BodyDiv>
  );
};

export default OrderStatusPage;