import cn from 'classnames';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../../../context/ThemeProvider';

import Logo from './Components/Logo';
import Pan from './Components/Pan';
import ExpDate from './Components/ExpDate';
import Cardholder from './Components/Cardholder';
import Ips from './Components/Ips';
import { StyledCardBackground } from './Components/Styled';

import styles from './LeftBankCardOld.module.css';

const StyledCard = styled.div`
  ${ (props) => css`
    background: ${ props.styles.colors.keyboardBackgroundColor };
    box-shadow: ${ (props) => props.styles.shadows.bankCard };
  `}
  
  ${ StyledCardBackground }
`;

const LeftBankCardOld = ({
  className,
  disabled,
  bankCard,
  pan,
  expDate,
  showCardholder,
  cardholder,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <StyledCard
      { ...props }
      className={ cn(styles.container, className) }
      srcBackground={ bankCard.srcBackground }
      styles={ isTheme.styles }
    >
      { bankCard.logo &&
        <Logo { ...bankCard.logo } />
      }
      <div className={ styles.content }>
        <Pan { ...pan } disabled={ disabled || pan.disabled } />
        <div className={ styles.grid }>
          <div>
            <ExpDate { ...expDate } disabled={ disabled || expDate.disabled } />
            { showCardholder &&
              <Cardholder { ...cardholder } disabled={ disabled || cardholder.disabled } />
            }
          </div>
          { bankCard.ips &&
            <Ips { ...bankCard.ips } />
          }
        </div>
      </div>
    </StyledCard>
  );
};

export default LeftBankCardOld;