import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Иконка счетчиков
 * @public
 * @version 1.5.0
 * @param {String} [width="3.25rem"]
 * @param {String} [height="3.25rem"]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * import { UiCountersIcon, UiPlates, UiPlate } from '@compassplus/ui-mobicash';
 *
 * const App = () => {
 *
 *   return (
 *     <>
 *       <UiCountersIcon />
 *       <UiPlates>
 *         <UiPlate view="2x1" image={ <UiCountersIcon width="100%" height="100%" /> } />
 *       </UiPlates>
 *     </>
 *   );
 * }; */
const UiCountersIcon = ({
  width,
  height,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      innerRef={ innerRef }
      width={ width }
      height={ height }
      viewBox="0 0 52 52"
    >
      <path fillRule="evenodd" clipRule="evenodd"
        d="M47.7665 22.1795H40.2237C37.5066 17.9405 32.1579 15.0551 25.9995 15.0551C19.8421 15.0551 14.4934 17.9405 11.7754 22.1795H4.23348C3.55216 22.1795 3 22.6407 3 23.2101V33.8566C3 34.4261 3.55216 34.8873 4.23348 34.8873H11.7754C14.3042 38.8315 19.1126 41.5914 24.7297 41.958C24.7724 41.988 27.2157 41.988 27.2585 41.9589C32.8801 41.595 37.694 38.8342 40.2237 34.8873H47.7665C48.4478 34.8873 49 34.4261 49 33.8566V23.2101C49 22.6407 48.4478 22.1795 47.7665 22.1795Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M47.7017 30.4054V18.3844C47.7017 17.7422 47.1814 17.2209 46.5383 17.2209H5.4713C4.82909 17.2209 4.30786 17.7422 4.30786 18.3844V30.4054C4.30786 31.0485 4.82909 31.5688 5.4713 31.5688H46.5383C47.1814 31.5688 47.7017 31.0485 47.7017 30.4054Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M5.47142 17.4486C4.95565 17.4486 4.53539 17.8679 4.53539 18.3846V30.4056C4.53539 30.9214 4.95565 31.3407 5.47142 31.3407H46.5384C47.0542 31.3407 47.4744 30.9214 47.4744 30.4056V18.3846C47.4744 17.8679 47.0542 17.4486 46.5384 17.4486H5.47142ZM46.5384 31.7956H5.47142C4.70459 31.7956 4.08057 31.1715 4.08057 30.4056V18.3846C4.08057 17.6178 4.70459 16.9938 5.47142 16.9938H46.5384C47.3052 16.9938 47.9292 17.6178 47.9292 18.3846V30.4056C47.9292 31.1715 47.3052 31.7956 46.5384 31.7956Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M46.0833 26.7131V20.009C46.0833 19.3668 45.5721 18.8464 44.9435 18.8464H41.9817" fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M46.0833 27.1679C45.8322 27.1679 45.6285 26.9641 45.6285 26.7131V20.009C45.6285 19.6187 45.321 19.3012 44.9435 19.3012H41.9817C41.7297 19.3012 41.5269 19.0975 41.5269 18.8464C41.5269 18.5945 41.7297 18.3916 41.9817 18.3916H44.9435C45.8222 18.3916 46.5381 19.1166 46.5381 20.009V26.7131C46.5381 26.9641 46.3343 27.1679 46.0833 27.1679Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M44.3817 23.9325V21.0862C44.3817 20.8124 44.1607 20.5914 43.8878 20.5914H42.6042" fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M44.3816 24.1599C44.256 24.1599 44.1542 24.058 44.1542 23.9325V21.0862C44.1542 20.9389 44.0341 20.8188 43.8876 20.8188H42.6041C42.4786 20.8188 42.3767 20.7169 42.3767 20.5914C42.3767 20.4658 42.4786 20.364 42.6041 20.364H43.8876C44.2852 20.364 44.609 20.6878 44.609 21.0862V23.9325C44.609 24.058 44.5071 24.1599 44.3816 24.1599Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M41.2217 24.3949C41.2217 32.7991 34.4093 39.6124 26.0042 39.6124C17.6009 39.6124 10.7876 32.7991 10.7876 24.3949C10.7876 15.9906 17.6009 9.17737 26.0042 9.17737C34.4093 9.17737 41.2217 15.9906 41.2217 24.3949Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M19.0838 24.0729H16.5904C16.313 24.0729 16.0874 23.8473 16.0874 23.5689V18.5122C16.0874 18.2338 16.313 18.0082 16.5904 18.0082H19.0838C19.3621 18.0082 19.5877 18.2338 19.5877 18.5122V23.5689C19.5877 23.8473 19.3621 24.0729 19.0838 24.0729Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M16.5905 18.2356C16.4386 18.2356 16.314 18.3594 16.314 18.5122V23.5689C16.314 23.7217 16.4386 23.8454 16.5905 23.8454H19.0838C19.2357 23.8454 19.3595 23.7217 19.3595 23.5689V18.5122C19.3595 18.3594 19.2357 18.2356 19.0838 18.2356H16.5905ZM19.0838 24.3003H16.5905C16.1875 24.3003 15.8591 23.9728 15.8591 23.5689V18.5122C15.8591 18.1083 16.1875 17.7808 16.5905 17.7808H19.0838C19.4868 17.7808 19.8143 18.1083 19.8143 18.5122V23.5689C19.8143 23.9728 19.4868 24.3003 19.0838 24.3003Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M27.047 39.612H24.9521C24.8247 39.612 24.7219 39.5083 24.7219 39.3819V29.4704C24.7219 29.343 24.8247 29.2402 24.9521 29.2402H27.047C27.1743 29.2402 27.2771 29.343 27.2771 29.4704V39.3819C27.2771 39.5083 27.1743 39.612 27.047 39.612Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M24.9527 29.4673L24.9499 39.3816L27.0476 39.3843C27.0494 39.3843 27.0503 39.3825 27.0503 39.3816V29.47L24.9527 29.4673ZM27.0476 39.8391H24.9527C24.7007 39.8391 24.4951 39.6335 24.4951 39.3816V29.47C24.4951 29.2181 24.7007 29.0125 24.9527 29.0125H27.0476C27.2996 29.0125 27.5051 29.2181 27.5051 29.47V39.3816C27.5051 39.6335 27.2996 39.8391 27.0476 39.8391Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M31.715 30.9992H30.4142C30.2878 30.9992 30.1841 30.8955 30.1841 30.769V29.4673C30.1841 29.3409 30.2878 29.2372 30.4142 29.2372H31.715C31.8424 29.2372 31.9452 29.3409 31.9452 29.4673V30.769C31.9452 30.8955 31.8424 30.9992 31.715 30.9992Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M30.4146 29.4651L30.4119 30.7686L31.7154 30.7722C31.7172 30.7722 31.7181 30.7704 31.7181 30.7686V29.4678L30.4146 29.4651ZM31.7154 31.2271H30.4146C30.1626 31.2271 29.957 31.0206 29.957 30.7686V29.4678C29.957 29.2158 30.1626 29.0103 30.4146 29.0103H31.7154C31.9674 29.0103 32.1729 29.2158 32.1729 29.4678V30.7686C32.1729 31.0206 31.9674 31.2271 31.7154 31.2271Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M34.6579 30.9992H33.3571C33.2307 30.9992 33.127 30.8955 33.127 30.769V29.4673C33.127 29.3409 33.2307 29.2372 33.3571 29.2372H34.6579C34.7852 29.2372 34.888 29.3409 34.888 29.4673V30.769C34.888 30.8955 34.7852 30.9992 34.6579 30.9992Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M33.3575 29.4651L33.3547 30.7686L34.6583 30.7722C34.6601 30.7722 34.661 30.7704 34.661 30.7686V29.4678L33.3575 29.4651ZM34.6583 31.2271H33.3575C33.1055 31.2271 32.8999 31.0206 32.8999 30.7686V29.4678C32.8999 29.2158 33.1055 29.0103 33.3575 29.0103H34.6583C34.9102 29.0103 35.1158 29.2158 35.1158 29.4678V30.7686C35.1158 31.0206 34.9102 31.2271 34.6583 31.2271Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M32.8969 34.3379H30.4145C30.2871 34.3379 30.1843 34.2351 30.1843 34.1078V32.807C30.1843 32.6796 30.2871 32.5759 30.4145 32.5759H32.8969C33.0243 32.5759 33.127 32.6796 33.127 32.807V34.1078C33.127 34.2351 33.0243 34.3379 32.8969 34.3379Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M30.4146 32.8038L30.4119 34.1082L32.897 34.111C32.8979 34.111 32.8997 34.1091 32.8997 34.1082V32.8065L30.4146 32.8038ZM32.897 34.5658H30.4146C30.1626 34.5658 29.957 34.3602 29.957 34.1082V32.8065C29.957 32.5545 30.1626 32.349 30.4146 32.349H32.897C33.149 32.349 33.3546 32.5545 33.3546 32.8065V34.1082C33.3546 34.3602 33.149 34.5658 32.897 34.5658Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M20.2951 31.0036H21.5959C21.7223 31.0036 21.826 30.9008 21.826 30.7735V29.4727C21.826 29.3453 21.7223 29.2416 21.5959 29.2416H20.2951C20.1677 29.2416 20.0649 29.3453 20.0649 29.4727V30.7735C20.0649 30.9008 20.1677 31.0036 20.2951 31.0036Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M20.295 29.4694L20.2922 30.7738L20.295 30.7766H21.5958L21.5985 29.4721L20.295 29.4694ZM21.5958 31.2314H20.295C20.043 31.2314 19.8374 31.0258 19.8374 30.7738V29.4721C19.8374 29.2202 20.043 29.0146 20.295 29.0146H21.5958C21.8477 29.0146 22.0533 29.2202 22.0533 29.4721V30.7738C22.0533 31.0258 21.8477 31.2314 21.5958 31.2314Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M17.352 31.0036H18.6528C18.7792 31.0036 18.8829 30.9008 18.8829 30.7735V29.4727C18.8829 29.3453 18.7792 29.2416 18.6528 29.2416H17.352C17.2246 29.2416 17.1218 29.3453 17.1218 29.4727V30.7735C17.1218 30.9008 17.2246 31.0036 17.352 31.0036Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M17.3518 29.4694L17.3491 30.7738L17.3518 30.7766H18.6526L18.6554 29.4721L17.3518 29.4694ZM18.6526 31.2314H17.3518C17.0999 31.2314 16.8943 31.0258 16.8943 30.7738V29.4721C16.8943 29.2202 17.0999 29.0146 17.3518 29.0146H18.6526C18.9046 29.0146 19.1102 29.2202 19.1102 29.4721V30.7738C19.1102 31.0258 18.9046 31.2314 18.6526 31.2314Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M19.0553 34.3379H21.5378C21.6651 34.3379 21.7679 34.2351 21.7679 34.1078V32.807C21.7679 32.6796 21.6651 32.5759 21.5378 32.5759H19.0553C18.928 32.5759 18.8252 32.6796 18.8252 32.807V34.1078C18.8252 34.2351 18.928 34.3379 19.0553 34.3379Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M19.0555 32.8038L19.0527 34.1082L19.0555 34.111H21.5379L21.5406 32.8065L19.0555 32.8038ZM21.5379 34.5658H19.0555C18.8035 34.5658 18.5979 34.3602 18.5979 34.1082V32.8065C18.5979 32.5545 18.8035 32.349 19.0555 32.349H21.5379C21.7899 32.349 21.9954 32.5545 21.9954 32.8065V34.1082C21.9954 34.3602 21.7899 34.5658 21.5379 34.5658Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M24.4959 24.0729H22.0025C21.7242 24.0729 21.4995 23.8473 21.4995 23.5689V18.5122C21.4995 18.2338 21.7242 18.0082 22.0025 18.0082H24.4959C24.7742 18.0082 24.9989 18.2338 24.9989 18.5122V23.5689C24.9989 23.8473 24.7742 24.0729 24.4959 24.0729Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M22.0024 18.2356C21.8505 18.2356 21.7268 18.3594 21.7268 18.5122V23.5689C21.7268 23.7217 21.8505 23.8454 22.0024 23.8454H24.4958C24.6477 23.8454 24.7714 23.7217 24.7714 23.5689V18.5122C24.7714 18.3594 24.6477 18.2356 24.4958 18.2356H22.0024ZM24.4958 24.3003H22.0024C21.5994 24.3003 21.272 23.9728 21.272 23.5689V18.5122C21.272 18.1083 21.5994 17.7808 22.0024 17.7808H24.4958C24.8987 17.7808 25.2262 18.1083 25.2262 18.5122V23.5689C25.2262 23.9728 24.8987 24.3003 24.4958 24.3003Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M30.0075 24.0729H27.5142C27.2358 24.0729 27.0103 23.8473 27.0103 23.5689V18.5122C27.0103 18.2338 27.2358 18.0082 27.5142 18.0082H30.0075C30.2859 18.0082 30.5106 18.2338 30.5106 18.5122V23.5689C30.5106 23.8473 30.2859 24.0729 30.0075 24.0729Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M27.5141 18.2356C27.3622 18.2356 27.2375 18.3594 27.2375 18.5122V23.5689C27.2375 23.7217 27.3622 23.8454 27.5141 23.8454H30.0074C30.1593 23.8454 30.283 23.7217 30.283 23.5689V18.5122C30.283 18.3594 30.1593 18.2356 30.0074 18.2356H27.5141ZM30.0074 24.3003H27.5141C27.1111 24.3003 26.7827 23.9728 26.7827 23.5689V18.5122C26.7827 18.1083 27.1111 17.7808 27.5141 17.7808H30.0074C30.4104 17.7808 30.7379 18.1083 30.7379 18.5122V23.5689C30.7379 23.9728 30.4104 24.3003 30.0074 24.3003Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M35.4192 24.0729H32.9259C32.6484 24.0729 32.4229 23.8473 32.4229 23.5689V18.5122C32.4229 18.2338 32.6484 18.0082 32.9259 18.0082H35.4192C35.6976 18.0082 35.9232 18.2338 35.9232 18.5122V23.5689C35.9232 23.8473 35.6976 24.0729 35.4192 24.0729Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M32.9262 18.2356C32.7743 18.2356 32.6496 18.3594 32.6496 18.5122V23.5689C32.6496 23.7217 32.7743 23.8454 32.9262 23.8454H35.4195C35.5714 23.8454 35.6952 23.7217 35.6952 23.5689V18.5122C35.6952 18.3594 35.5714 18.2356 35.4195 18.2356H32.9262ZM35.4195 24.3003H32.9262C32.5232 24.3003 32.1948 23.9728 32.1948 23.5689V18.5122C32.1948 18.1083 32.5232 17.7808 32.9262 17.7808H35.4195C35.8225 17.7808 36.15 18.1083 36.15 18.5122V23.5689C36.15 23.9728 35.8225 24.3003 35.4195 24.3003Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M30.9936 15.2332H29.2352C29.137 15.2332 29.0579 15.154 29.0579 15.0558V13.2965C29.0579 13.1983 29.137 13.1191 29.2352 13.1191H30.9936C31.0918 13.1191 31.171 13.1983 31.171 13.2965V15.0558C31.171 15.154 31.0918 15.2332 30.9936 15.2332Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M26.8842 15.2332H25.1259C25.0276 15.2332 24.9485 15.154 24.9485 15.0558V13.2965C24.9485 13.1983 25.0276 13.1191 25.1259 13.1191H26.8842C26.9825 13.1191 27.0616 13.1983 27.0616 13.2965V15.0558C27.0616 15.154 26.9825 15.2332 26.8842 15.2332Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M22.7748 15.2332H21.0165C20.9183 15.2332 20.8391 15.154 20.8391 15.0558V13.2965C20.8391 13.1983 20.9183 13.1191 21.0165 13.1191H22.7748C22.8731 13.1191 22.9522 13.1983 22.9522 13.2965V15.0558C22.9522 15.154 22.8731 15.2332 22.7748 15.2332Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M17.8573 19.5158C17.9055 19.5158 17.9501 19.5385 17.9892 19.584C18.0229 19.6231 18.0411 19.6686 18.0438 19.7205V22.4767C18.0438 22.5404 18.0229 22.5931 17.9801 22.6359C17.9347 22.6814 17.8819 22.7041 17.8209 22.7041C17.7573 22.7041 17.7045 22.6814 17.6618 22.6359C17.6163 22.5931 17.5935 22.5404 17.5935 22.4767V19.9615C17.5271 19.9615 17.4725 19.9406 17.4298 19.8979C17.3843 19.8524 17.3616 19.7996 17.3616 19.7387C17.3616 19.6723 17.3843 19.6186 17.4298 19.5795C17.4725 19.5367 17.5253 19.5158 17.589 19.5158H17.8573Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M23.9398 22.0688C24.0007 22.0688 24.0526 22.0897 24.0944 22.1325C24.1372 22.1834 24.1581 22.2371 24.1581 22.2917C24.1581 22.3526 24.1372 22.4035 24.0944 22.4463C24.0526 22.4881 24.0007 22.51 23.9398 22.51H22.4935C22.4325 22.51 22.3816 22.4881 22.3388 22.4463C22.2961 22.4035 22.2751 22.3526 22.2751 22.2917C22.2751 22.2462 22.2952 22.1943 22.3343 22.137L23.6624 20.4496C23.7561 20.2377 23.7197 20.0521 23.5532 19.8947C23.384 19.7337 23.1939 19.7128 22.9847 19.8311C22.8328 19.922 22.7573 20.0539 22.7573 20.2268C22.7573 20.2813 22.7363 20.3341 22.6936 20.3859C22.6417 20.4287 22.5881 20.4496 22.5298 20.4496C22.4753 20.4496 22.4225 20.4287 22.3707 20.3859C22.3252 20.3405 22.3024 20.2877 22.3024 20.2268C22.3024 19.9839 22.3907 19.7747 22.5662 19.5991V19.5946C22.7091 19.4517 22.8755 19.3653 23.0665 19.3353C23.2849 19.3016 23.4659 19.3226 23.6078 19.399C23.8443 19.5054 24.0062 19.6719 24.0944 19.8993C24.149 20.0112 24.1736 20.1431 24.1672 20.295C24.1645 20.4433 24.1217 20.5697 24.0399 20.6725L22.9437 22.0688H23.9398Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M28.2228 21.7259C28.2438 21.8505 28.2983 21.9524 28.3866 22.0306C28.4712 22.1061 28.5776 22.1444 28.7049 22.1444C28.8296 22.1444 28.9351 22.1007 29.0233 22.0125C29.1079 21.9279 29.1507 21.8214 29.1507 21.6941C29.1507 21.5667 29.1079 21.4558 29.0233 21.3621C28.9351 21.2647 28.8296 21.2165 28.7049 21.2165C28.6413 21.2165 28.5876 21.1956 28.5458 21.1528C28.5066 21.1137 28.4866 21.0601 28.4866 20.9936C28.4866 20.9236 28.5066 20.869 28.5458 20.8299C28.5876 20.7872 28.6413 20.7662 28.7049 20.7662C28.8323 20.7662 28.9387 20.7189 29.0233 20.6252C29.1079 20.5315 29.1507 20.4188 29.1507 20.2887C29.1507 20.1613 29.1079 20.0549 29.0233 19.9703C28.9351 19.8821 28.8296 19.8384 28.7049 19.8384C28.5958 19.8384 28.4921 19.8748 28.3957 19.9476C28.3047 20.014 28.2465 20.0976 28.2228 20.1977C28.2101 20.2523 28.1819 20.3023 28.1364 20.3478C28.1 20.3842 28.0473 20.396 27.9772 20.3842C27.8435 20.3569 27.7771 20.2723 27.7771 20.1295C27.828 19.9057 27.9408 19.7274 28.1137 19.5973C28.2829 19.4636 28.4803 19.3972 28.7049 19.3972C28.9442 19.3972 29.1534 19.4818 29.3326 19.6519C29.5118 19.8248 29.6009 20.0367 29.6009 20.2887C29.6009 20.5825 29.4945 20.8172 29.2826 20.9936C29.4945 21.1756 29.6009 21.4094 29.6009 21.6941C29.6009 21.9342 29.5118 22.1425 29.3326 22.3217C29.1534 22.5009 28.9442 22.5901 28.7049 22.5901C28.4712 22.5901 28.2647 22.5146 28.0864 22.3627C27.9135 22.2108 27.8098 22.0216 27.7771 21.7941C27.7771 21.655 27.8435 21.5685 27.9772 21.5349C28.0409 21.5349 28.0964 21.5549 28.1455 21.594C28.1964 21.6368 28.2228 21.6804 28.2228 21.7259Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M34.472 21.4349V20.2569L33.6897 21.4349H34.472ZM34.472 21.8897H33.2167C33.1075 21.8897 33.0375 21.8424 33.0075 21.7487C32.9747 21.6605 32.9847 21.5832 33.0393 21.5168L34.5266 19.4564L34.5357 19.4473C34.6085 19.3746 34.6921 19.3573 34.7858 19.3973C34.8804 19.4273 34.9268 19.4974 34.9268 19.6065V21.4349H35.086C35.1433 21.4349 35.1979 21.4558 35.2498 21.4986C35.2925 21.5495 35.3134 21.605 35.3134 21.6623C35.3134 21.7169 35.2925 21.7697 35.2498 21.8215C35.2043 21.867 35.1497 21.8897 35.086 21.8897H34.9268V22.3537C34.9268 22.4082 34.9059 22.461 34.8632 22.5128C34.8177 22.5583 34.7631 22.5811 34.6994 22.5811C34.6358 22.5811 34.583 22.5583 34.5402 22.5128C34.4948 22.4674 34.472 22.4146 34.472 22.3537V21.8897Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M26.0047 9.63264C17.8651 9.63264 11.2429 16.2549 11.2429 24.3953C11.2429 32.5349 17.8651 39.1571 26.0047 39.1571C34.1442 39.1571 40.7674 32.5349 40.7674 24.3953C40.7674 16.2549 34.1442 9.63264 26.0047 9.63264ZM26.0047 40.0667C17.363 40.0667 10.3333 33.037 10.3333 24.3953C10.3333 15.7537 17.363 8.72299 26.0047 8.72299C34.6463 8.72299 41.677 15.7537 41.677 24.3953C41.677 33.037 34.6463 40.0667 26.0047 40.0667Z"
        fill={ isTheme.styles.colors.textTitle } />
    </Element>
  );
};

UiCountersIcon.propTypes = {
  /** Определяет ширину иконки */
  width: PropTypes.string,
  /** Определяет высоту иконки */
  height: PropTypes.string
};

UiCountersIcon.defaultProps = {
  width: '3.25rem',
  height: '3.25rem'
};

export default UiCountersIcon;