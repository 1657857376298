import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Plate reports icon
 * @public
 * @version 1.3.0
 * @param {String} width
 * @param {String} height
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiReportsIcon />
 */
const UiReportsIcon = ({
  width,
  height,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      innerRef={ innerRef }
      width={ width }
      height={ height }
      viewBox="0 0 52 52"
    >
      <path fillRule="evenodd" clipRule="evenodd"
        d="M5 47.1389C5 48.5109 6.112 49.6229 7.484 49.6229H30.308C31.68 49.6229 32.792 48.5109 32.792 47.1389V41.5969H43.628C45 41.5969 46.112 40.4839 46.112 39.1119V27.3239C46.112 26.7849 45.675 26.3469 45.135 26.3469H6.102C5.494 26.3469 5 26.8399 5 27.4489V47.1389Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M37.792 6.55591H42.788C43.953 6.55591 44.897 7.49991 44.897 8.66491V37.6629C44.897 38.8289 43.953 39.7729 42.788 39.7729H21.109C19.944 39.7729 18.999 38.8289 18.999 37.6629V8.66491C18.999 7.49991 19.944 6.55591 21.109 6.55591H26.32"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M42.7875 40.0232H21.1085C19.8085 40.0232 18.7495 38.9642 18.7495 37.6642V8.66618C18.7495 7.36418 19.8085 6.30518 21.1085 6.30518H26.3195C26.4575 6.30518 26.5695 6.41818 26.5695 6.55518C26.5695 6.69318 26.4575 6.80518 26.3195 6.80518H21.1085C20.0835 6.80518 19.2495 7.64018 19.2495 8.66618V37.6642C19.2495 38.6892 20.0835 39.5232 21.1085 39.5232H42.7875C43.8125 39.5232 44.6465 38.6892 44.6465 37.6642V8.66618C44.6465 7.64018 43.8125 6.80518 42.7875 6.80518H37.7925C37.6545 6.80518 37.5425 6.69318 37.5425 6.55518C37.5425 6.41818 37.6545 6.30518 37.7925 6.30518H42.7875C44.0885 6.30518 45.1465 7.36418 45.1465 8.66618V37.6642C45.1465 38.9642 44.0885 40.0232 42.7875 40.0232Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M42.7875 40.2732H21.1085C19.6695 40.2732 18.4995 39.1032 18.4995 37.6642V8.66618C18.4995 7.22618 19.6695 6.05518 21.1085 6.05518H26.3195C26.5955 6.05518 26.8195 6.27918 26.8195 6.55518C26.8195 6.83118 26.5955 7.05518 26.3195 7.05518H21.1085C20.2215 7.05518 19.4995 7.77718 19.4995 8.66618V37.6642C19.4995 38.5512 20.2215 39.2732 21.1085 39.2732H42.7875C43.6745 39.2732 44.3965 38.5512 44.3965 37.6642V8.66618C44.3965 7.77718 43.6745 7.05518 42.7875 7.05518H37.7925C37.5165 7.05518 37.2925 6.83118 37.2925 6.55518C37.2925 6.27918 37.5165 6.05518 37.7925 6.05518H42.7875C44.2265 6.05518 45.3965 7.22618 45.3965 8.66618V37.6642C45.3965 39.1032 44.2265 40.2732 42.7875 40.2732Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M18.9995 37.6638C18.9995 38.8268 19.9455 39.7728 21.1085 39.7728H42.7875H21.1085C19.9455 39.7728 18.9995 38.8268 18.9995 37.6638Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M40.4243 36.7312H23.4733C22.6773 36.7312 22.0293 36.0842 22.0293 35.2892V11.0292C22.0293 10.2342 22.6773 9.58618 23.4733 9.58618H24.2033C24.3413 9.58618 24.4533 9.69918 24.4533 9.83618C24.4533 9.97418 24.3413 10.0862 24.2033 10.0862H23.4733C22.9523 10.0862 22.5293 10.5092 22.5293 11.0292V35.2892C22.5293 35.8082 22.9523 36.2312 23.4733 36.2312H40.4243C40.9443 36.2312 41.3663 35.8082 41.3663 35.2892V11.0292C41.3663 10.5092 40.9443 10.0862 40.4243 10.0862H39.7113C39.5733 10.0862 39.4613 9.97418 39.4613 9.83618C39.4613 9.69918 39.5733 9.58618 39.7113 9.58618H40.4243C41.2193 9.58618 41.8663 10.2342 41.8663 11.0292V35.2892C41.8663 36.0842 41.2193 36.7312 40.4243 36.7312Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M34.7066 15.1443H31.6206C31.4826 15.1443 31.3706 15.0323 31.3706 14.8943C31.3706 14.7563 31.4826 14.6443 31.6206 14.6443H34.7066C34.8446 14.6443 34.9566 14.7563 34.9566 14.8943C34.9566 15.0323 34.8446 15.1443 34.7066 15.1443Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M38.5337 17.6336H31.5327C31.3947 17.6336 31.2827 17.5216 31.2827 17.3836C31.2827 17.2456 31.3947 17.1336 31.5327 17.1336H38.5337C38.6717 17.1336 38.7837 17.2456 38.7837 17.3836C38.7837 17.5216 38.6717 17.6336 38.5337 17.6336Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M34.3785 21.3221H31.2915C31.1535 21.3221 31.0415 21.2101 31.0415 21.0721C31.0415 20.9341 31.1535 20.8221 31.2915 20.8221H34.3785C34.5165 20.8221 34.6285 20.9341 34.6285 21.0721C34.6285 21.2101 34.5165 21.3221 34.3785 21.3221Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M38.2056 23.8123H31.2036C31.0656 23.8123 30.9536 23.7003 30.9536 23.5623C30.9536 23.4243 31.0656 23.3123 31.2036 23.3123H38.2056C38.3436 23.3123 38.4556 23.4243 38.4556 23.5623C38.4556 23.7003 38.3436 23.8123 38.2056 23.8123Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M34.3785 27.618H31.2915C31.1535 27.618 31.0415 27.506 31.0415 27.368C31.0415 27.23 31.1535 27.118 31.2915 27.118H34.3785C34.5165 27.118 34.6285 27.23 34.6285 27.368C34.6285 27.506 34.5165 27.618 34.3785 27.618Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M38.2056 30.1072H31.2036C31.0656 30.1072 30.9536 29.9952 30.9536 29.8572C30.9536 29.7192 31.0656 29.6072 31.2036 29.6072H38.2056C38.3436 29.6072 38.4556 29.7192 38.4556 29.8572C38.4556 29.9952 38.3436 30.1072 38.2056 30.1072Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M27.6348 16.0613C27.6348 16.5243 27.2598 16.9003 26.7958 16.9003C26.3328 16.9003 25.9568 16.5243 25.9568 16.0613C25.9568 15.5983 26.3328 15.2223 26.7958 15.2223C27.2598 15.2223 27.6348 15.5983 27.6348 16.0613Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M27.3189 22.2395C27.3189 22.7025 26.9439 23.0785 26.4799 23.0785C26.0169 23.0785 25.6409 22.7025 25.6409 22.2395C25.6409 21.7765 26.0169 21.4005 26.4799 21.4005C26.9439 21.4005 27.3189 21.7765 27.3189 22.2395Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M27.3189 28.5349C27.3189 28.9979 26.9439 29.3739 26.4799 29.3739C26.0169 29.3739 25.6409 28.9979 25.6409 28.5349C25.6409 28.0719 26.0169 27.6959 26.4799 27.6959C26.9439 27.6959 27.3189 28.0719 27.3189 28.5349Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M26.8083 17.7566C25.8733 17.7566 25.1133 16.9956 25.1133 16.0616C25.1133 15.1266 25.8733 14.3656 26.8083 14.3656C26.9463 14.3656 27.0583 14.4786 27.0583 14.6156C27.0583 14.7536 26.9463 14.8656 26.8083 14.8656C26.1493 14.8656 25.6133 15.4026 25.6133 16.0616C25.6133 16.7206 26.1493 17.2566 26.8083 17.2566C27.4673 17.2566 28.0033 16.7206 28.0033 16.0616C28.0033 15.9236 28.1163 15.8116 28.2533 15.8116C28.3913 15.8116 28.5033 15.9236 28.5033 16.0616C28.5033 16.9956 27.7433 17.7566 26.8083 17.7566Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M26.4799 23.9353C25.5449 23.9353 24.7849 23.1743 24.7849 22.2403C24.7849 21.3053 25.5449 20.5443 26.4799 20.5443C26.6179 20.5443 26.7299 20.6573 26.7299 20.7943C26.7299 20.9323 26.6179 21.0443 26.4799 21.0443C25.8209 21.0443 25.2849 21.5813 25.2849 22.2403C25.2849 22.8993 25.8209 23.4353 26.4799 23.4353C27.1389 23.4353 27.6749 22.8993 27.6749 22.2403C27.6749 22.1023 27.7879 21.9903 27.9249 21.9903C28.0629 21.9903 28.1749 22.1023 28.1749 22.2403C28.1749 23.1743 27.4149 23.9353 26.4799 23.9353Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M26.4799 30.2303C25.5449 30.2303 24.7849 29.4693 24.7849 28.5353C24.7849 27.6003 25.5449 26.8393 26.4799 26.8393C26.6179 26.8393 26.7299 26.9523 26.7299 27.0893C26.7299 27.2273 26.6179 27.3393 26.4799 27.3393C25.8209 27.3393 25.2849 27.8763 25.2849 28.5353C25.2849 29.1943 25.8209 29.7303 26.4799 29.7303C27.1389 29.7303 27.6749 29.1943 27.6749 28.5353C27.6749 28.3973 27.7879 28.2853 27.9249 28.2853C28.0629 28.2853 28.1749 28.3973 28.1749 28.5353C28.1749 29.4693 27.4149 30.2303 26.4799 30.2303Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M31.9513 5.406C31.6753 5.406 31.4513 5.182 31.4513 4.906C31.4513 4.63 31.6753 4.406 31.9513 4.406C32.2273 4.406 32.4513 4.63 32.4513 4.906C32.4513 5.182 32.2273 5.406 31.9513 5.406ZM34.4103 5.015C34.1803 5.015 33.9703 4.859 33.9273 4.632C33.7473 3.702 32.9303 3 31.9483 3C30.9673 3 30.1493 3.702 29.9703 4.632C29.9263 4.859 29.7173 5.015 29.4863 5.015H26.8223C26.5453 5.015 26.3203 5.24 26.3203 5.517V9.334C26.3203 9.612 26.5453 9.836 26.8223 9.836H37.0743C37.3513 9.836 37.5763 9.612 37.5763 9.334V5.517C37.5763 5.24 37.3513 5.015 37.0743 5.015H34.4103Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M25.1402 14.18H30.1362C31.3012 14.18 32.2452 15.124 32.2452 16.289V45.288C32.2452 46.453 31.3012 47.397 30.1362 47.397H8.45717C7.29217 47.397 6.34717 46.453 6.34717 45.288V16.289C6.34717 15.124 7.29217 14.18 8.45717 14.18H13.6682"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M30.1359 47.6472H8.4569C7.1569 47.6472 6.0979 46.5882 6.0979 45.2882V16.2902C6.0979 14.9882 7.1569 13.9292 8.4569 13.9292H13.6679C13.8059 13.9292 13.9179 14.0422 13.9179 14.1792C13.9179 14.3172 13.8059 14.4292 13.6679 14.4292H8.4569C7.4319 14.4292 6.5979 15.2642 6.5979 16.2902V45.2882C6.5979 46.3132 7.4319 47.1472 8.4569 47.1472H30.1359C31.1609 47.1472 31.9949 46.3132 31.9949 45.2882V16.2902C31.9949 15.2642 31.1609 14.4292 30.1359 14.4292H25.1409C25.0029 14.4292 24.8909 14.3172 24.8909 14.1792C24.8909 14.0422 25.0029 13.9292 25.1409 13.9292H30.1359C31.4369 13.9292 32.4949 14.9882 32.4949 16.2902V45.2882C32.4949 46.5882 31.4369 47.6472 30.1359 47.6472Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M30.1359 47.8972H8.4569C7.0179 47.8972 5.8479 46.7272 5.8479 45.2882V16.2902C5.8479 14.8502 7.0179 13.6792 8.4569 13.6792H13.6679C13.9439 13.6792 14.1679 13.9032 14.1679 14.1792C14.1679 14.4552 13.9439 14.6792 13.6679 14.6792H8.4569C7.5699 14.6792 6.8479 15.4012 6.8479 16.2902V45.2882C6.8479 46.1752 7.5699 46.8972 8.4569 46.8972H30.1359C31.0229 46.8972 31.7449 46.1752 31.7449 45.2882V16.2902C31.7449 15.4012 31.0229 14.6792 30.1359 14.6792H25.1409C24.8649 14.6792 24.6409 14.4552 24.6409 14.1792C24.6409 13.9032 24.8649 13.6792 25.1409 13.6792H30.1359C31.5749 13.6792 32.7449 14.8502 32.7449 16.2902V45.2882C32.7449 46.7272 31.5749 47.8972 30.1359 47.8972Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M6.34766 45.2879C6.34766 46.4509 7.29366 47.3969 8.45666 47.3969H30.1357H8.45666C7.29366 47.3969 6.34766 46.4509 6.34766 45.2879Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M27.7724 44.3553H10.8214C10.0254 44.3553 9.37744 43.7083 9.37744 42.9133V18.6533C9.37744 17.8583 10.0254 17.2103 10.8214 17.2103H11.5514C11.6894 17.2103 11.8014 17.3233 11.8014 17.4603C11.8014 17.5983 11.6894 17.7103 11.5514 17.7103H10.8214C10.3004 17.7103 9.87744 18.1333 9.87744 18.6533V42.9133C9.87744 43.4323 10.3004 43.8553 10.8214 43.8553H27.7724C28.2924 43.8553 28.7144 43.4323 28.7144 42.9133V18.6533C28.7144 18.1333 28.2924 17.7103 27.7724 17.7103H27.0594C26.9214 17.7103 26.8094 17.5983 26.8094 17.4603C26.8094 17.3233 26.9214 17.2103 27.0594 17.2103H27.7724C28.5674 17.2103 29.2144 17.8583 29.2144 18.6533V42.9133C29.2144 43.7083 28.5674 44.3553 27.7724 44.3553Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M22.0548 22.7683H18.9688C18.8308 22.7683 18.7188 22.6563 18.7188 22.5183C18.7188 22.3803 18.8308 22.2683 18.9688 22.2683H22.0548C22.1928 22.2683 22.3048 22.3803 22.3048 22.5183C22.3048 22.6563 22.1928 22.7683 22.0548 22.7683Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M25.8819 25.2576H18.8809C18.7429 25.2576 18.6309 25.1456 18.6309 25.0076C18.6309 24.8696 18.7429 24.7576 18.8809 24.7576H25.8819C26.0199 24.7576 26.1319 24.8696 26.1319 25.0076C26.1319 25.1456 26.0199 25.2576 25.8819 25.2576Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M21.7266 28.9461H18.6396C18.5016 28.9461 18.3896 28.8341 18.3896 28.6961C18.3896 28.5581 18.5016 28.4461 18.6396 28.4461H21.7266C21.8646 28.4461 21.9766 28.5581 21.9766 28.6961C21.9766 28.8341 21.8646 28.9461 21.7266 28.9461Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M25.5538 31.4363H18.5518C18.4138 31.4363 18.3018 31.3243 18.3018 31.1863C18.3018 31.0483 18.4138 30.9363 18.5518 30.9363H25.5538C25.6918 30.9363 25.8038 31.0483 25.8038 31.1863C25.8038 31.3243 25.6918 31.4363 25.5538 31.4363Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M21.7266 35.242H18.6396C18.5016 35.242 18.3896 35.13 18.3896 34.992C18.3896 34.854 18.5016 34.742 18.6396 34.742H21.7266C21.8646 34.742 21.9766 34.854 21.9766 34.992C21.9766 35.13 21.8646 35.242 21.7266 35.242Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M25.5538 37.7312H18.5518C18.4138 37.7312 18.3018 37.6192 18.3018 37.4812C18.3018 37.3432 18.4138 37.2312 18.5518 37.2312H25.5538C25.6918 37.2312 25.8038 37.3432 25.8038 37.4812C25.8038 37.6192 25.6918 37.7312 25.5538 37.7312Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M14.9829 23.6858C14.9829 24.1488 14.6079 24.5248 14.1439 24.5248C13.6809 24.5248 13.3049 24.1488 13.3049 23.6858C13.3049 23.2228 13.6809 22.8468 14.1439 22.8468C14.6079 22.8468 14.9829 23.2228 14.9829 23.6858Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M14.667 29.8636C14.667 30.3266 14.292 30.7026 13.828 30.7026C13.365 30.7026 12.989 30.3266 12.989 29.8636C12.989 29.4006 13.365 29.0246 13.828 29.0246C14.292 29.0246 14.667 29.4006 14.667 29.8636Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M14.667 36.159C14.667 36.622 14.292 36.998 13.828 36.998C13.365 36.998 12.989 36.622 12.989 36.159C12.989 35.696 13.365 35.32 13.828 35.32C14.292 35.32 14.667 35.696 14.667 36.159Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M14.1562 25.3806C13.2212 25.3806 12.4612 24.6196 12.4612 23.6856C12.4612 22.7506 13.2212 21.9896 14.1562 21.9896C14.2942 21.9896 14.4062 22.1026 14.4062 22.2396C14.4062 22.3776 14.2942 22.4896 14.1562 22.4896C13.4972 22.4896 12.9612 23.0266 12.9612 23.6856C12.9612 24.3446 13.4972 24.8806 14.1562 24.8806C14.8152 24.8806 15.3512 24.3446 15.3512 23.6856C15.3512 23.5476 15.4642 23.4356 15.6012 23.4356C15.7392 23.4356 15.8512 23.5476 15.8512 23.6856C15.8512 24.6196 15.0912 25.3806 14.1562 25.3806Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M13.8283 31.5594C12.8933 31.5594 12.1333 30.7984 12.1333 29.8644C12.1333 28.9294 12.8933 28.1684 13.8283 28.1684C13.9663 28.1684 14.0783 28.2814 14.0783 28.4184C14.0783 28.5564 13.9663 28.6684 13.8283 28.6684C13.1693 28.6684 12.6333 29.2054 12.6333 29.8644C12.6333 30.5234 13.1693 31.0594 13.8283 31.0594C14.4873 31.0594 15.0233 30.5234 15.0233 29.8644C15.0233 29.7264 15.1363 29.6144 15.2733 29.6144C15.4113 29.6144 15.5233 29.7264 15.5233 29.8644C15.5233 30.7984 14.7633 31.5594 13.8283 31.5594Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M13.8283 37.8543C12.8933 37.8543 12.1333 37.0933 12.1333 36.1593C12.1333 35.2243 12.8933 34.4633 13.8283 34.4633C13.9663 34.4633 14.0783 34.5763 14.0783 34.7133C14.0783 34.8513 13.9663 34.9633 13.8283 34.9633C13.1693 34.9633 12.6333 35.5003 12.6333 36.1593C12.6333 36.8183 13.1693 37.3543 13.8283 37.3543C14.4873 37.3543 15.0233 36.8183 15.0233 36.1593C15.0233 36.0213 15.1363 35.9093 15.2733 35.9093C15.4113 35.9093 15.5233 36.0213 15.5233 36.1593C15.5233 37.0933 14.7633 37.8543 13.8283 37.8543Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M19.2989 13.0301C19.0229 13.0301 18.7989 12.8061 18.7989 12.5301C18.7989 12.2541 19.0229 12.0301 19.2989 12.0301C19.5749 12.0301 19.7989 12.2541 19.7989 12.5301C19.7989 12.8061 19.5749 13.0301 19.2989 13.0301ZM21.7569 12.6391C21.5269 12.6391 21.3189 12.4841 21.2759 12.2581C21.0969 11.3271 20.2789 10.6241 19.2969 10.6241C18.3139 10.6241 17.4959 11.3271 17.3179 12.2581C17.2749 12.4841 17.0659 12.6391 16.8359 12.6391H14.1679C13.8919 12.6391 13.6689 12.8631 13.6689 13.1381V16.9611C13.6689 17.2371 13.8919 17.4611 14.1679 17.4611H24.4249C24.7009 17.4611 24.9239 17.2371 24.9239 16.9611V13.1381C24.9239 12.8631 24.7009 12.6391 24.4249 12.6391H21.7569Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
    </Element>
  );
};

UiReportsIcon.propTypes = {
  /** Определяет ширину иконки */
  width: PropTypes.string,
  /** Определяет высоту иконки */
  height: PropTypes.string
};

UiReportsIcon.defaultProps = {
  width: '3.25rem',
  height: '3.25rem'
};

export default UiReportsIcon;