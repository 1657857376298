import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Plate loyalty serv icon
 * @public
 * @version 1.3.0
 * @param {String} width
 * @param {String} height
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiLoyaltyServIcon />
 */
const UiLoyaltyServIcon = ({
  width,
  height,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      innerRef={ innerRef }
      width={ width }
      height={ height }
      viewBox="0 0 52 52"
    >
      <mask id="mask0_82_13768" style={ { maskType: 'alpha' } } maskUnits="userSpaceOnUse" x="5" y="16" width="42"
        height="30">
        <path d="M5 16.4882H46.908V45.55H5V16.4882Z" fill={ isTheme.styles.colors.sectionBackground } />
      </mask>
      <g mask="url(#mask0_82_13768)">
        <path fillRule="evenodd" clipRule="evenodd"
          d="M46.2665 33.6842C46.6335 33.6312 46.9045 33.3392 46.9085 32.9952V30.1282C46.9085 29.3542 46.2325 28.7262 45.4005 28.7262H38.3615V23.6272H38.9685C39.6385 23.6272 40.1815 23.1222 40.1815 22.4992V19.1142C40.1815 18.4912 39.6385 17.9862 38.9685 17.9862H33.5095C34.7335 17.9862 35.8105 17.4192 36.4705 16.5572C27.1975 16.5382 17.9245 16.4932 8.65151 16.4882C9.30551 17.3922 10.4135 17.9862 11.6725 17.9862H6.21351C5.54351 17.9862 4.99951 18.4912 4.99951 19.1142V22.4992C4.99951 23.1222 5.54351 23.6272 6.21351 23.6272H6.81951V42.8092C6.81951 43.4322 7.36351 43.9372 8.03351 43.9372H16.7615V44.1482C16.7615 44.9222 17.4355 45.5502 18.2685 45.5502H45.4005C46.2325 45.5502 46.9085 44.9222 46.9085 44.1482V41.2822C46.9045 40.9372 46.6335 40.6452 46.2665 40.5922C44.6965 40.3402 43.4665 39.1962 43.1945 37.7352C42.8405 35.8282 44.2155 34.0142 46.2665 33.6842Z"
          fill={ isTheme.styles.colors.textDisable } />
      </g>
      <mask id="mask1_82_13768" style={ { maskType: 'alpha' } } maskUnits="userSpaceOnUse" x="9" y="6" width="13"
        height="17">
        <path d="M9.54639 6H21.5271V22.4845H9.54639V6Z" fill={ isTheme.styles.colors.sectionBackground } />
      </mask>
      <g mask="url(#mask1_82_13768)">
        <path fillRule="evenodd" clipRule="evenodd"
          d="M21.5271 10.6109V17.9579C21.4841 17.9959 21.4401 18.0349 21.3951 18.0749C21.0881 18.3429 20.7151 18.6589 20.2881 18.9949C18.5281 20.3799 15.8631 22.1249 13.2531 22.4659C10.6221 22.8109 9.34114 18.2949 9.57314 13.9249C9.73514 10.8929 10.6251 7.92991 12.2981 6.71291C15.2071 4.59691 18.8161 7.67591 20.6521 9.62191C21.0231 10.0159 21.3211 10.3619 21.5271 10.6109Z"
          fill={ isTheme.styles.colors.applicationPrimaryColor } />
      </g>
      <mask id="mask2_82_13768" style={ { maskType: 'alpha' } } maskUnits="userSpaceOnUse" x="24" y="6" width="13"
        height="17">
        <path d="M24.8259 6H36.8059V22.4849H24.8259V6Z" fill={ isTheme.styles.colors.sectionBackground } />
      </mask>
      <g mask="url(#mask2_82_13768)">
        <path fillRule="evenodd" clipRule="evenodd"
          d="M33.0999 22.4664C30.4659 22.1214 27.7769 20.3484 26.0179 18.9574C25.5959 18.6234 25.2259 18.3114 24.9259 18.0454C24.8919 18.0154 24.8579 17.9864 24.8259 17.9584V10.6114C25.0259 10.3694 25.3129 10.0354 25.6689 9.65638C27.4959 7.71138 31.1289 4.58438 34.0549 6.71338C35.7279 7.93038 36.6179 10.8924 36.7789 13.9244C37.0119 18.2954 35.7299 22.8104 33.0999 22.4664Z"
          fill={ isTheme.styles.colors.applicationPrimaryColor } />
      </g>
      <path fillRule="evenodd" clipRule="evenodd"
        d="M26.9563 13.6241V15.1541C26.9563 16.4861 26.1103 17.6191 24.9253 18.0461C24.6003 18.1631 24.2483 18.2281 23.8833 18.2281H22.3523C22.0193 18.2281 21.6953 18.1741 21.3943 18.0751C20.1663 17.6721 19.2793 16.5171 19.2793 15.1541V13.6241C19.2793 12.2131 20.2303 11.0241 21.5273 10.6641C21.7893 10.5901 22.0663 10.5511 22.3523 10.5511H23.8833C24.2113 10.5511 24.5283 10.6031 24.8253 10.6991C26.0623 11.0961 26.9563 12.2551 26.9563 13.6241Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M37.8568 20.3707V39.5687C37.8568 40.1917 37.3519 40.6977 36.7278 40.6977H9.62585C9.00185 40.6977 8.49585 40.1917 8.49585 39.5687V20.3707H37.8568Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M8.99585 20.8707V39.5687C8.99585 39.9157 9.27785 40.1977 9.62485 40.1977H36.7269C37.0749 40.1977 37.3568 39.9157 37.3568 39.5687V20.8707H8.99585ZM36.7269 41.1977H9.62485C8.72685 41.1977 7.99585 40.4667 7.99585 39.5687V19.8707H38.3568V39.5687C38.3568 40.4667 37.6259 41.1977 36.7269 41.1977Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M7.931 14.7242H38.421C39.045 14.7242 39.551 15.2302 39.551 15.8532V19.2412C39.551 19.8652 39.045 20.3702 38.421 20.3702H7.931C7.307 20.3702 6.802 19.8652 6.802 19.2412V15.8532C6.802 15.2302 7.307 14.7242 7.931 14.7242Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M7.931 15.2242C7.584 15.2242 7.302 15.5062 7.302 15.8532V19.2412C7.302 19.5882 7.584 19.8702 7.931 19.8702H38.421C38.768 19.8702 39.051 19.5882 39.051 19.2412V15.8532C39.051 15.5062 38.768 15.2242 38.421 15.2242H7.931ZM38.421 20.8702H7.931C7.033 20.8702 6.302 20.1402 6.302 19.2412V15.8532C6.302 14.9552 7.033 14.2242 7.931 14.2242H38.421C39.32 14.2242 40.051 14.9552 40.051 15.8532V19.2412C40.051 20.1402 39.32 20.8702 38.421 20.8702Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd" d="M20.918 27.147H25.435V20.862H20.918V27.147Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd" d="M20.918 19.87H25.435V15.225H20.918V19.87Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M19.1528 25.4742H44.4088C45.1838 25.4742 45.8128 26.1022 45.8128 26.8772V29.7452C45.8088 30.0912 45.5568 30.3832 45.2148 30.4352C43.3058 30.7662 42.0258 32.5812 42.3558 34.4902C42.6088 35.9522 43.7538 37.0972 45.2148 37.3492C45.5568 37.4022 45.8088 37.6942 45.8128 38.0392V40.9082C45.8128 41.6832 45.1838 42.3112 44.4088 42.3112H19.1528C18.3778 42.3112 17.7498 41.6832 17.7498 40.9082V26.8772C17.7498 26.1022 18.3778 25.4742 19.1528 25.4742Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M19.1528 25.7242C18.5168 25.7242 17.9998 26.2412 17.9998 26.8772V40.9082C17.9998 41.5442 18.5168 42.0612 19.1528 42.0612H44.4088C45.0448 42.0612 45.5628 41.5442 45.5628 40.9082V38.0392C45.5598 37.8182 45.3988 37.6312 45.1768 37.5972C43.6108 37.3262 42.3798 36.0952 42.1098 34.5332C41.7568 32.4912 43.1308 30.5422 45.1728 30.1892C45.3988 30.1542 45.5598 29.9672 45.5628 29.7432V26.8772C45.5628 26.2412 45.0448 25.7242 44.4088 25.7242H19.1528ZM44.4088 42.5612H19.1528C18.2418 42.5612 17.4998 41.8202 17.4998 40.9082V26.8772C17.4998 25.9662 18.2418 25.2242 19.1528 25.2242H44.4088C45.3208 25.2242 46.0628 25.9662 46.0628 26.8772V29.7452C46.0578 30.2182 45.7178 30.6112 45.2538 30.6822C43.4868 30.9882 42.2968 32.6772 42.6028 34.4482C42.8368 35.8022 43.9038 36.8702 45.2578 37.1032C45.7178 37.1742 46.0578 37.5672 46.0628 38.0372V40.9082C46.0628 41.8202 45.3208 42.5612 44.4088 42.5612Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M24.4475 26.7242C24.3095 26.7242 24.1975 26.6122 24.1975 26.4742V25.4742C24.1975 25.3362 24.3095 25.2242 24.4475 25.2242C24.5855 25.2242 24.6975 25.3362 24.6975 25.4742V26.4742C24.6975 26.6122 24.5855 26.7242 24.4475 26.7242Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M24.4475 30.9635C24.3095 30.9635 24.1975 30.8515 24.1975 30.7135V28.5935C24.1975 28.4555 24.3095 28.3435 24.4475 28.3435C24.5855 28.3435 24.6975 28.4555 24.6975 28.5935V30.7135C24.6975 30.8515 24.5855 30.9635 24.4475 30.9635ZM24.4475 35.2025C24.3095 35.2025 24.1975 35.0905 24.1975 34.9525V32.8335C24.1975 32.6945 24.3095 32.5835 24.4475 32.5835C24.5855 32.5835 24.6975 32.6945 24.6975 32.8335V34.9525C24.6975 35.0905 24.5855 35.2025 24.4475 35.2025ZM24.4475 39.4425C24.3095 39.4425 24.1975 39.3305 24.1975 39.1925V37.0725C24.1975 36.9345 24.3095 36.8225 24.4475 36.8225C24.5855 36.8225 24.6975 36.9345 24.6975 37.0725V39.1925C24.6975 39.3305 24.5855 39.4425 24.4475 39.4425Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M24.4475 42.5616C24.3095 42.5616 24.1975 42.4496 24.1975 42.3116V41.3116C24.1975 41.1736 24.3095 41.0616 24.4475 41.0616C24.5855 41.0616 24.6975 41.1736 24.6975 41.3116V42.3116C24.6975 42.4496 24.5855 42.5616 24.4475 42.5616Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M37.1394 27.5704C37.2814 27.3254 37.4814 27.1764 37.7394 27.1254C37.9974 27.0614 38.2364 27.0994 38.4554 27.2414C38.6874 27.3834 38.8354 27.5834 38.9004 27.8414C38.9654 28.0994 38.9264 28.3444 38.7844 28.5764L31.7214 40.1874C31.3084 40.6774 30.8694 40.7804 30.4054 40.4974C30.1604 40.3554 30.0114 40.1554 29.9604 39.8974C29.8954 39.6394 29.9344 39.4004 30.0764 39.1814L37.1394 27.5704Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M37.9736 27.3458C37.9176 27.3458 37.8596 27.3528 37.8006 27.3678C37.6006 27.4078 37.4626 27.5118 37.3556 27.6958L37.3526 27.6998L30.2896 39.3108C30.1826 39.4778 30.1556 39.6468 30.2026 39.8358C30.2436 40.0368 30.3466 40.1738 30.5306 40.2808C30.7756 40.4308 31.1046 40.5338 31.5296 40.0268L38.5716 28.4468C38.6776 28.2718 38.7056 28.0948 38.6586 27.9018C38.6096 27.7078 38.5006 27.5618 38.3256 27.4548C38.2116 27.3818 38.0966 27.3458 37.9736 27.3458ZM30.8956 40.8998C30.6896 40.8998 30.4816 40.8368 30.2746 40.7108C29.9706 40.5338 29.7806 40.2758 29.7156 39.9458C29.6356 39.6318 29.6856 39.3248 29.8666 39.0458L36.9246 27.4428C37.1046 27.1348 37.3626 26.9458 37.6906 26.8808C38.0066 26.8018 38.3126 26.8518 38.5916 27.0318C38.8746 27.2038 39.0616 27.4568 39.1436 27.7808C39.2246 28.1048 39.1756 28.4168 38.9976 28.7068L31.9346 40.3178C31.6096 40.7058 31.2566 40.8998 30.8956 40.8998Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M32.2694 30.4342C32.0374 30.6662 31.7534 30.7832 31.4174 30.7832C31.0824 30.7832 30.7984 30.6662 30.5664 30.4342C30.3344 30.1762 30.2184 29.8922 30.2184 29.5822V29.5632C30.2184 29.2412 30.3344 28.9632 30.5664 28.7312C30.7984 28.4992 31.0824 28.3832 31.4174 28.3832C31.7534 28.3832 32.0374 28.4992 32.2694 28.7312C32.5014 28.9632 32.6184 29.2472 32.6184 29.5822C32.6184 29.8922 32.5014 30.1762 32.2694 30.4342ZM33.1984 27.8022C32.7204 27.3262 32.1274 27.0862 31.4174 27.0862C30.7214 27.0862 30.1344 27.3262 29.6574 27.8022C29.1664 28.3312 28.9214 28.9252 28.9214 29.5822C28.9214 30.2672 29.1664 30.8532 29.6574 31.3442C30.1464 31.8342 30.7344 32.0792 31.4174 32.0792C32.0764 32.0792 32.6694 31.8342 33.1984 31.3442C33.6754 30.8672 33.9144 30.2792 33.9144 29.5822C33.9144 28.8732 33.6754 28.2802 33.1984 27.8022Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M31.4179 28.6329C31.1489 28.6329 30.9289 28.7229 30.7429 28.9079C30.5579 29.0929 30.4679 29.3079 30.4679 29.5639C30.4679 29.8309 30.5609 30.0549 30.7519 30.2669C31.1039 30.6169 31.7189 30.6279 32.0879 30.2619C32.2759 30.0509 32.3679 29.8289 32.3679 29.5829C32.3679 29.3139 32.2779 29.0929 32.0929 28.9079C31.9079 28.7229 31.6869 28.6329 31.4179 28.6329ZM31.4179 31.0329C31.0159 31.0329 30.6689 30.8909 30.3899 30.6109C30.1069 30.2979 29.9679 29.9549 29.9679 29.5829C29.9679 29.1739 30.1099 28.8349 30.3899 28.5549C30.9489 27.9949 31.8879 27.9949 32.4459 28.5549C32.7259 28.8339 32.8679 29.1799 32.8679 29.5829C32.8679 29.9549 32.7289 30.2979 32.4549 30.6019L32.4459 30.6109C32.1669 30.8909 31.8209 31.0329 31.4179 31.0329ZM31.4179 27.3369C30.7849 27.3369 30.2669 27.5469 29.8339 27.9789C29.3909 28.4579 29.1719 28.9849 29.1719 29.5829C29.1719 30.2039 29.3889 30.7219 29.8339 31.1669C30.2789 31.6129 30.7969 31.8289 31.4179 31.8289C32.0169 31.8289 32.5429 31.6109 33.0289 31.1609C33.4539 30.7349 33.6639 30.2159 33.6639 29.5829C33.6639 28.9369 33.4539 28.4119 33.0219 27.9789C32.5879 27.5469 32.0639 27.3369 31.4179 27.3369ZM31.4179 32.3289C30.6679 32.3289 30.0159 32.0569 29.4809 31.5209C28.9439 30.9849 28.6719 30.3329 28.6719 29.5829C28.6719 28.8619 28.9419 28.2059 29.4739 27.6329C30.0039 27.1019 30.6559 26.8369 31.4179 26.8369C32.1919 26.8369 32.8499 27.1019 33.3749 27.6249L33.3759 27.6259C33.8989 28.1499 34.1639 28.8079 34.1639 29.5829C34.1639 30.3449 33.8989 30.9969 33.3759 31.5209C32.7949 32.0589 32.1379 32.3289 31.4179 32.3289Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M38.2557 39.046C38.0357 39.265 37.7587 39.375 37.4237 39.375C37.1007 39.375 36.8107 39.265 36.5527 39.046C36.4437 38.917 36.3607 38.772 36.3057 38.625C36.2067 38.369 36.2397 38.052 36.2507 37.986C36.2767 37.829 36.3157 37.695 36.3647 37.583C36.4607 37.349 36.6797 37.155 36.8637 37.09C37.0307 37.025 37.2207 37.002 37.4237 36.995C37.7727 36.995 38.0497 37.111 38.2557 37.343C38.4877 37.575 38.6037 37.859 38.6037 38.194C38.6037 38.53 38.4877 38.814 38.2557 39.046ZM39.1657 36.433C38.6747 35.943 38.0947 35.698 37.4237 35.698C36.7267 35.698 36.1337 35.943 35.6437 36.433C35.1657 36.911 34.9277 37.498 34.9277 38.194C34.9277 38.891 35.1657 39.478 35.6437 39.956C36.1337 40.446 36.7267 40.691 37.4237 40.691C38.0947 40.691 38.6747 40.446 39.1657 39.956C39.6687 39.465 39.9197 38.879 39.9197 38.194C39.9197 37.498 39.6687 36.911 39.1657 36.433Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M37.4237 37.2447C37.2257 37.2517 37.0737 37.2767 36.9537 37.3227C36.8287 37.3677 36.6647 37.5107 36.5967 37.6777C36.5517 37.7787 36.5197 37.8947 36.4977 38.0277C36.4877 38.0837 36.4657 38.3457 36.5387 38.5347C36.5877 38.6637 36.6577 38.7837 36.7437 38.8837C37.1167 39.1927 37.7377 39.2107 38.0787 38.8697C38.2637 38.6837 38.3537 38.4637 38.3537 38.1947C38.3537 37.9257 38.2637 37.7047 38.0787 37.5197C37.9107 37.3307 37.6997 37.2447 37.4237 37.2447ZM37.4237 39.6247C37.0417 39.6247 36.6947 39.4947 36.3907 39.2367C36.2387 39.0627 36.1407 38.8957 36.0717 38.7127C35.9357 38.3607 36.0037 37.9487 36.0047 37.9437C36.0337 37.7717 36.0777 37.6157 36.1357 37.4827C36.2497 37.2047 36.5097 36.9497 36.7807 36.8537C36.9467 36.7887 37.1517 36.7537 37.4157 36.7447C37.8457 36.7447 38.1877 36.8907 38.4427 37.1767C38.7127 37.4457 38.8537 37.7917 38.8537 38.1947C38.8537 38.5977 38.7127 38.9427 38.4327 39.2227C38.1647 39.4897 37.8257 39.6247 37.4237 39.6247ZM37.4237 35.9477C36.7907 35.9477 36.2657 36.1647 35.8197 36.6097C35.3877 37.0427 35.1777 37.5607 35.1777 38.1947C35.1777 38.8277 35.3877 39.3467 35.8197 39.7787C36.2657 40.2237 36.7907 40.4407 37.4237 40.4407C38.0307 40.4407 38.5427 40.2237 38.9887 39.7787C39.4477 39.3317 39.6697 38.8137 39.6697 38.1947C39.6697 37.5627 39.4487 37.0467 38.9937 36.6147L38.9887 36.6097C38.5427 36.1647 38.0297 35.9477 37.4237 35.9477ZM37.4237 40.9407C36.6617 40.9407 36.0037 40.6687 35.4667 40.1327C34.9437 39.6087 34.6777 38.9567 34.6777 38.1947C34.6777 37.4317 34.9437 36.7797 35.4667 36.2567C36.0037 35.7197 36.6617 35.4477 37.4237 35.4477C38.1577 35.4477 38.8027 35.7187 39.3397 36.2537C39.8907 36.7777 40.1697 37.4307 40.1697 38.1947C40.1697 38.9457 39.8907 39.5987 39.3397 40.1347C38.8047 40.6687 38.1597 40.9407 37.4237 40.9407Z"
        fill={ isTheme.styles.colors.textTitle } />
    </Element>
  );
};

UiLoyaltyServIcon.propTypes = {
  /** Определяет ширину иконки */
  width: PropTypes.string,
  /** Определяет высоту иконки */
  height: PropTypes.string
};

UiLoyaltyServIcon.defaultProps = {
  width: '3.25rem',
  height: '3.25rem'
};

export default UiLoyaltyServIcon;