import React, { useReducer } from 'react';
import { useOptions } from '@compassplus/ui-mobicash';
import { StringDiv, TextInput } from '../style';
import { Text } from '../../../TemplateStyle';
import AttributesStore from '../../../../stores/AttributesStore';
import {action} from "mobx";

const TextField = ({ isActive }) => {
  const isOptions = useOptions();
  const attributes = AttributesStore.getAttributesByType('textField');
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const getTextField = (stringAttr, index) => (
    (stringAttr !== {}) && (
      <>
        <Text style={{ margin: '0.5rem 0' }} typography="subsection-title" view="readonly">
          {`${stringAttr.title[isOptions.language]}:`}
        </Text>
        <Text
          style={{ margin: '0.5rem 0' }}
          typography="note"
          view="readonly"
        >
          {stringAttr.description[isOptions.language]}
        </Text>
        <TextInput disabled={!isActive} onChange={action((event) => { stringAttr.changeValue(index, event.target.value); forceUpdate(); })}/>
      </>
    )
  );

  return (
    <>
      {attributes.map(getTextField)}
    </>
  );
};

export default TextField;
