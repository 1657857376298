import React, { useState } from 'react';
import {
  UiArrow, UiCardSection, UiDots, UiIcon, UiSection, useOptions,
} from '@compassplus/ui-mobicash';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react-lite';
import { ItemCard } from '../../../pages/admin/ModifyItemPage/style';
import i18n from '../../../i18n';
import ActionSheet from '../ActionSheet';
import { copyItem } from '../../../pages/admin/MainPage/RenderCards';
import { DeleteSection } from '../../TemplateStyle';
import { LoadingCardImage, LoadingTitle } from "../../loadingCards/style";
import ModalStore from '../../../stores/ModalStore';
import ShareLink from '../../Modals/ShareLink';
import ItemStore from '../../../stores/ItemStore';

const CurrentItemCard = observer(({ modeIsModify, shareLinkOpened }) => {
  const history = useHistory();
  const isOptions = useOptions();
  const currentLanguage = isOptions.language;

  const [isItemMenuOpen, setIsItemMenuOpen] = useState(false);
  const [isShareLinkOpen, setIsShareLinkOpen] = useState(shareLinkOpened || false);

  let mainImage = {};
  if (modeIsModify && ItemStore.images) {
    mainImage = ItemStore.images.find((element) => element.isMain === true);
  }

  const getDropdownSheet = () => (
    <ActionSheet
      open={isItemMenuOpen}
      setOpen={() => { setIsItemMenuOpen(false); }}
    >
      <UiSection
        title={i18n.t('LinkToItem')}
        onClick={() => { setIsShareLinkOpen(true); }}
        bottomDivider
        view="link"
        rightElement={<UiArrow />}
      />
      <UiSection
        title={i18n.t('Copy')}
        onClick={() => { copyItem(ItemStore, history); }}
        bottomDivider
        view="link"
        rightElement={<UiArrow />}
      />
      <DeleteSection
        title={i18n.t('Delete')}
        onClick={() => { setIsItemMenuOpen(false); ModalStore.openDeleteConfirm({ type: 'item', id: ItemStore.article }, history); }}
        rightElement={<UiArrow />}
      />
    </ActionSheet>
  );

  if (ItemStore.isLoading) {
    return (
      <ItemCard shadow>
        <UiCardSection
          title={<LoadingTitle />}
          leftElement={<LoadingCardImage />}
          rightElement={<UiDots />}
          status="Active"
          statusText={i18n.t('Active')}
        />
      </ItemCard>
    );
  }

  return (
    <>
      <ItemCard shadow>
        <UiCardSection
          title={modeIsModify ? (ItemStore.title[currentLanguage]) : `[${i18n.t('ItemDetails')}]`}
          description={
            modeIsModify ? (
              ItemStore.description[currentLanguage].length > 80
                ? `${ItemStore.description[currentLanguage].substr(0, 80)}...`
                : ItemStore.description[currentLanguage]
            )
              : i18n.t('Description')
          }
          leftElement={(modeIsModify && ItemStore.images.length !== 0) ? (
            <img style={{ width: '4.75rem', height: '3rem', objectFit: 'cover' }} src={mainImage?.url} />
          ) : (
            <UiIcon setColor="#D6D6D6" icon="legacy-gallery" />
          )}
          rightElement={modeIsModify ? (<UiDots onClick={() => { setIsItemMenuOpen(!isItemMenuOpen); }} />) : (<UiDots disabled />)}
          status={modeIsModify ? (ItemStore.status ? 'Active' : 'Blocked') : 'Blocked'}
          statusText={modeIsModify ? (ItemStore.status ? i18n.t('Active') : i18n.t('Inactive')) : i18n.t('Status')}
          active={isItemMenuOpen}
        />
        {getDropdownSheet()}
      </ItemCard>
      {
        modeIsModify && (
          <ShareLink itemId={ItemStore.article} type="item" active={isShareLinkOpen} setActive={setIsShareLinkOpen} />
        )
      }
    </>
  );
});

export default CurrentItemCard;
