import styled from 'styled-components';

import { UiCardSection } from '@compassplus/ui-mobicash';

export const AddPriceTypeSection = styled(UiCardSection)`
  & {
    min-height: 3.75rem;
  }
  
  & > div:first-child > span {
    color: #037AFF;
  }
`;

