import {useState} from 'react';

export const useDarkMode = () => {
  const [isDarkTheme, setIsDarkTheme] = useState(localStorage.getItem('darkTheme') === 'true' ? true : false);

  const handleChange = () => {
    localStorage.setItem('darkTheme', !isDarkTheme)
    setIsDarkTheme(!isDarkTheme);
    window.location.reload();
  }
  return {isDarkTheme, setIsDarkTheme, handleChange}
}
