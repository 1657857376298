import React from 'react';
import { observer } from 'mobx-react-lite';
import { UiCard } from '@compassplus/ui-mobicash';
import {AnimationDiv, Text} from '../../../../components/TemplateStyle';
import OrderStore from '../../../../stores/OrderStore';
import PickupPointFields from '../Fields/PickupPointFields';
import i18n from '../../../../i18n';
import PersonalData from '../Fields/PersonalData';
import Phone from '../Fields/Phone';
import Birthday from '../Fields/Birthday';
import AddressData from '../Fields/AddressData';
import DateFields from '../Fields/Date';
import EntryField from '../Fields/EntryField';

const DeliveryFields = observer(() => {
  const renderPickupPointFields = () => {
    if (OrderStore.selectedPoint !== -1 && OrderStore.selectedDelivery.type === 'Pickup') {
      return (
        <PickupPointFields />
      );
    }
    return null;
  };

  const checkIsRenderField = (type) => OrderStore.selectedDelivery.attributes.findIndex((el) => el.type === type) !== -1;

  return (
    <div style={{ margin: '0.5rem 0 0' }}>
      {renderPickupPointFields()}
      <>
        { (OrderStore.deliverySettingsArr && OrderStore.deliverySettingsArr.length === 0)
          || (OrderStore.selectedDeliveryType !== -1 && OrderStore.selectedDelivery.type !== 'Pickup')
          || (OrderStore.selectedDeliveryType !== -1 && OrderStore.selectedPoint !== -1)
          ? (
            <>
              {
                (checkIsRenderField('PersonalData') || checkIsRenderField('Birthday')) ? (
                  <AnimationDiv>
                    <Text style={{ textTransform: 'uppercase', margin: '0.25rem 0' }} view="readonly" typography="section-description-semibold">
                      {i18n.t('PersonalData')}
                    </Text>
                    <UiCard shadow style={{ margin: '0.25rem 0' }}>
                      {checkIsRenderField('PersonalData') ? <PersonalData /> : null}
                      {checkIsRenderField('Birthday') ? <Birthday /> : null}
                    </UiCard>
                  </AnimationDiv>
                ) : null
              }
              {checkIsRenderField('Phone') ? <Phone /> : null}
              {checkIsRenderField('ClientAddress') ? <AddressData /> : null}
              {checkIsRenderField('DeliveryDate') ? <DateFields /> : null}
              {checkIsRenderField('EntryField') ? <EntryField /> : null}
            </>
          ) : null}
      </>
    </div>
  );
});

export default DeliveryFields;
