import { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import styled from 'styled-components';

import { useTheme } from '../../../context/ThemeProvider';

import Substrate from './Components/Substrate';
import Cell from './Components/Cell';
import Backspace from './Components/Backspace';

import { createMemPad, getKeyboardSize } from '../../../utils/memPadUtils';
import { isFunction } from '../../../utils/typeUtils';

import styles from './UiMemPad.module.css';
import PropTypes from 'prop-types';

const StyledSVG = styled.svg`
  filter: ${ (props) => props.styles.shadows.memPad };
`;

/**
 * Компонент мозаичной клавиатуры
 * @public
 * @version 1.7.0
 * @param {String} className
 * @param {'default'|'create'} mode
 * @param {Object[][]} userCells:cells
 * @param {Boolean} userCells[][].active
 * @param {Number} userCells[][].number
 * @param {String} userCells[][].position
 * @param {Object} userCells[][].coordinates
 * @param {Number} userCells[][].coordinates.x
 * @param {Number} userCells[][].coordinates.y
 * @param {String} userCells[][].C
 * @param {'v'|'c'} userCells[][].T
 * @param {String} userCells[][].V
 * @param {Function} userSetCells:setCells
 * @param {String} userInitialValue:initialValue
 * @param {String} userValue:value
 * @param {Function} onValueChange
 * @param {Number} maxLength
 * @param {Number} rowCount
 * @param {Number} columnCount
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiMemPad />
 */
const UiMemPad = ({
  className,
  mode,
  cells: userCells,
  setCells: userSetCells,
  initialValue: userInitialValue,
  value: userValue,
  onValueChange,
  maxLength,
  rowCount,
  columnCount,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  const [cells, setCells] = useState(() => {
    if (userCells !== undefined && userCells !== null) {
      return userCells;
    } else {
      return createMemPad(mode === 'create' ? userInitialValue : '', { rowCount, columnCount });
    }
  });
  const [stackCells, setStackCells] = useState([]);

  useEffect(() => {
    if (isFunction(userSetCells)) {
      userSetCells(cells);
    }
  }, [userSetCells, cells]);

  useEffect(() => {
    if (mode === 'create') {
      const positions = [];

      cells.forEach((row) => {
        row.forEach((column) => {
          if (column.active) {
            positions[column.number] = column.position;
          }
        });
      });

      const hash = positions.join('');

      if (isFunction(onValueChange)) {
        onValueChange(`${ userInitialValue }/${ hash }`);
      }
    }
  }, [mode, onValueChange, cells, userInitialValue]);

  const keyboardSize = useMemo(() => getKeyboardSize(rowCount, columnCount), [rowCount, columnCount]);

  return (
    <div
      { ...props }
      className={ cn(styles.container, className) }
      ref={ innerRef }
    >
      <StyledSVG
        width={ keyboardSize.width }
        height={ keyboardSize.height }
        styles={ isTheme.styles }
      >
        <Substrate cells={ cells } />
        { cells.map((row, rIndex) => {
          return row.map((cell, cIndex) => {
            const { active, position, coordinates, C, T, V } = cell;

            if (T === 'c') {
              return null;
            }

            return (
              <Cell
                key={ position }
                mode={ mode }
                active={ mode === 'create' ? active : false }
                disabled={ mode === 'create' ? !active : false }
                position={ position }
                coordinates={ coordinates }
                C={ C }
                V={ V }
                value={ userValue }
                onValueChange={ onValueChange }
                maxLength={ maxLength }
                cells={ cells }
                setCells={ setCells }
                stackCells={ stackCells }
                setStackCells={ setStackCells }
                data-row={ rIndex }
                data-column={ cIndex }
              />
            );
          });
        }) }
        <Backspace
          mode={ mode }
          disabled={ mode === 'create' ? stackCells.length === 0 : !userValue }
          value={ userValue }
          onValueChange={ onValueChange }
          setCells={ setCells }
          stackCells={ stackCells }
          setStackCells={ setStackCells }
          keyboardSize={ keyboardSize }
        />
      </StyledSVG>
    </div>
  );
};

UiMemPad.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Режим работы MemPad */
  mode: PropTypes.oneOf(['default', 'create']),
  /** Сетка MemPad */
  cells: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.exact({
    active: PropTypes.bool,
    number: PropTypes.number,
    position: PropTypes.string,
    coordinates: PropTypes.exact({
      x: PropTypes.number,
      y: PropTypes.number,
    }),
    C: PropTypes.string,
    T: PropTypes.oneOf(['v', 'c']),
    V: PropTypes.string
  }))),
  /** Обработчик изменения сетки MemPad */
  setCells: PropTypes.func,
  /** Начальное значение, которое используется при создании (вида XXXX) */
  initialValue: PropTypes.string,
  /** Значение (вида XXXX/AABBCCDD) */
  value: PropTypes.string,
  /** Обработчик изменения значения пароля */
  onValueChange: PropTypes.func,
  /** Максимальное количество символов, которое может ввести пользователь */
  maxLength: PropTypes.number,
  /** Количество строк сетки MemPad */
  rowCount: PropTypes.number,
  /** Количество колонок сетки MemPad */
  columnCount: PropTypes.number,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

UiMemPad.defaultProps = {
  mode: 'default',
  initialValue: '',
  value: '',
  rowCount: 9,
  columnCount: 7
};

export default UiMemPad;