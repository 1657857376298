import React from 'react';
import {UiCard, UiInput, useOptions} from "@compassplus/ui-mobicash";
import {observer} from "mobx-react-lite";
import OrderStore from "../../../../stores/OrderStore";
import {AnimationCard} from "../../../../components/TemplateStyle";

const PickupPointFields = observer(() => {
  const isOptions = useOptions();
  const point = OrderStore.selectedDelivery?.addressData.points[OrderStore.selectedPoint];
  return (
    <AnimationCard shadow>
      {
        point?.attributes?.map((attribute, index) => (
          <UiInput
            key={`${attribute.title.en}-${index}`}
            description={attribute.title[isOptions.language]}
            placeholder={attribute.value[isOptions.language]}
            readOnly
            bottomDivider={index !== point.attributes.length - 1}
          />
        ))
      }
    </AnimationCard>
  )
})

export default PickupPointFields;