import lightTheme from '../light';

const colors = {
  ...lightTheme.styles.colors,
  applicationPrimaryColor: '#DE8E91', // Основной цвет бренда. В кобрендовом приложении меняется на цвет соответствующего бренда.
  sectionBackground: '#363434', // Цвет фона полей ввода, плиток, тулбара и т.д.
  textTitle: '#FFFFFF', // Цвет заголовков секций и описания полей
  textReadOnly: '#ADB0B2', // Цвет нередактируемых значений в полях ввода, основной цвет текста, информационных диалогов и панелей.
  textDisable: '#5C6166', // Цвет для задизейбленных состояний, а так же плейсхолдеров.
  textLink: '#529EFF', // Цвет ссылок, активных кнопок и элементов, которые меняют значения полей, введенных или измененных пользователем данных.
  applicationPosPrimaryColor: '#0A4D9B', // Основной цвет приложения для торговцев (MobiCash POS)
  sidemenuBackground: '#200B15', // Цвет фона бокового меню
  applicationBackground: '#292929', // Фон приложения
  overlay_zero_point_two: '#00000099', // Оверлейный слой для всплывающих диалогов и окон. Применяется в дизайне, может отличаться от нативных вариантов оформления.
  applicationPrimaryColor_zero_point_five: '#DE8E9180', // Цвет фона полей ввода, плиток, тулбара и т.д.
  warningColor: '#FC3F3F', // Оформление для предупреждающих или ошибочных сообщений в диалогах и формах. Основной цвет не оплаченных счетов.
  attentionColor: '#F8E71C', //
  keysBackgroundColor: '#2E3136', //
  keyboardBackgroundColor: '#202020', //
  settingsOrange: '#F3A264', // Цвет группы иконок в настройках приложения + Primary color для диспутных счетов.
  settingsBlue: '#7EBCE4', // Цвет группы иконок в настройках приложения и Account actions
  settingsGreen: '#9FCE7B', // Цвет группы иконок в настройках приложения и Account actions
  settingsPurple: '#A87BD1', // Цвет группы иконок в настройках приложения
  pendingBillPrimary: '#548BC9', // Основной цвет для счетов ожидающих оплату. Оформление суммы
  pendingBillSecondary: '#85ACD8', // Второстепенный цвет для счетов ожидающих оплату. Оформление бэйджиков со статусом счета, карты
  paidBillPrimary: '#66AA68', // Основной цвет для оплаченных счетов. Оформление суммы
  paidBillSecondary: '#92C293', // Второстепенный цвет для оплаченных счетов
  notPaidBillSecondary: '#F58987', // Второстепенный цвет для не оплаченных счетов
  disputeBillSecondary: '#F1C67F', // Второстепенный цвет для диспутных счетов

  favoriteActiveBorderBackground: '#FFFFFF',
  favoriteNotActiveBorderBackground: '#5C6166',

  favoriteActiveBackground: '#FFFFFF',
  favoriteNotActiveBackground: '#201D1D',

  favoriteActiveBorderStar: '#DE8E91',
  favoriteNotActiveBorderStar: '#5C6166',

  favoriteActiveStar: '#DE8E91',
  favoriteNotActiveStar: '#5C6166',

  memPad: {
    background: '#2E3136',
    colors: [
      '#C3DDF933',
      '#E8F1D433',
      '#FFFDD033',
      '#ECC7EE33',
      '#DAFAFF33',
      '#FFD1DC33',
      '#FFDEB333'
    ]
  }
};

export default colors;