import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../../../context/ThemeProvider';

import styles from './Notes.module.css';

const StyledNotes = styled.span`
  color: ${ (props) => props.styles.colors.textReadOnly };
  ${ (props) => props.styles.typography.Note };

  ${ (props) => props.warning && css`
    color: ${ (props) => props.styles.colors.warningColor };
  ` }
`;

const Notes = ({
  notes,
  warning
}) => {

  const isTheme = useTheme();

  return (
    <StyledNotes
      className={ styles.notes }
      warning={ warning }
      styles={ isTheme.styles }
    >
      { notes }
    </StyledNotes>
  );
};

Notes.propTypes = {
  notes: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  warning: PropTypes.bool
};

export default Notes;