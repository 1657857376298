import React from 'react';
import {
  UiArrow, UiCard, UiCardSection,
} from '@compassplus/ui-mobicash';
import {
  request,
} from '../../../Utils/requestUtil';
import { CardsContainer, ItemCardSection } from './styles';
import i18n from '../../../i18n';
import { LoadingCardImage, LoadingTitle } from '../../../components/loadingCards/style';
import SelectedItemCard from '../SelectItemsPage/SelectedItemCard';
import CategoryStore from '../../../stores/CategoryStore';
import AdminMarketStore from '../../../stores/AdminMarketStore';
import {AnimationCardSection} from "../../../components/TemplateStyle";

// Delete
export const deleteGroup = (id, history) => request(`/api/v1/group/${id}`, 'DELETE').then(
  (result) => {
    history.push(`/main/${result}`);
    window.location.reload();
  },
  (error) => console.log(error),
);

export const deleteItem = (id, history) => request(`/api/v1/item/${id}`, 'DELETE').then(
  (result) => history.push('/main'),
  (error) => console.log(error),
);

// ChangeStatus
export const changeGroupStatus = (group, history) => {
  request(`/api/v1/group/${group._id}`, 'PUT', { status: !group.status, parentGuid: group.parentGuid }).then(
    (responseGroup) => {
      history.push(`/main/${responseGroup.parentGuid}`);
      window.location.reload();
    },
    (error) => console.log(error),
  );
};

// Copy
export const copyGroup = (group, history) => request('/api/v1/group/', 'POST', group).then(
  (responseGroup) => {
    history.push(`/main/${responseGroup.parentGuid}`);
    window.location.reload();
  },
  (error) => console.log(error),
);

export const copyItem = (item, history) => {
  const newItem = item;
  newItem.status = item.status === 'Active';
  newItem.availability = item.availability === 'Available';
  request('/api/v1/item/', 'POST', newItem).then(
    (responseItem) => {
      history.push(`/main/${responseItem.groupGuid}`);
      window.location.reload();
    },
    (error) => console.log(error),
  );
};

const renderLoadingCard = (border) => (
  <UiCard>
    <UiCardSection
      title={<LoadingTitle />}
      leftElement={<LoadingCardImage />}
      rightElement={<UiArrow />}
      description={i18n.t('Description')}
      bottomDivider={border}
      status="Active"
      statusText={i18n.t('Active')}
    />
  </UiCard>
);

const onLevelDown = (history, id) => {
  AdminMarketStore.setCurrentGroup(id);
  history.push(`/main/${id}`, { groupId: id });
};

export const renderGroups = ({
  isLoading, currentGroups, language, history,
}) => {
  if (isLoading) {
    return (
      <CardsContainer>
        {renderLoadingCard()}
        {renderLoadingCard()}
        {renderLoadingCard()}
        {renderLoadingCard()}
      </CardsContainer>
    );
  }

  return (
    <UiCard shadow>
      {
        currentGroups && currentGroups.map((group, index) => (
          <AnimationCardSection
            key={group.type}
            title={group.title}
            description={group.description.length > 80
              ? `${group.description.substr(0, 80)}...`
              : group.description}
            onClick={() => onLevelDown(history, group.type)}
            leftElement={<img alt="..." style={{ width: '4.75rem', height: '3rem', objectFit: 'cover' }} src={group.image.url} />}
            rightElement={<UiArrow />}
            bottomDivider={index !== currentGroups.length - 1}
            status={group.status ? 'Active' : 'Blocked'}
            statusText={group.status ? i18n.t('Active') : i18n.t('Inactive')}
          />
        ))
      }
    </UiCard>
  );
};

export const renderItems = ({
  isLoading, currentItems, language, history, groupId, prevChangeId,
}) => {
  if (isLoading) {
    return (
      <CardsContainer>
        {renderLoadingCard()}
        {renderLoadingCard()}
        {renderLoadingCard()}
        {renderLoadingCard()}
      </CardsContainer>
    );
  }

  return (
    <UiCard shadow>
      {
        currentItems && currentItems.map((item, index) => (
          <>
            {index < AdminMarketStore.displayedItemsCount ? (
              <ItemCardSection
                key={item.article}
                title={item.title}
                description={item.description?.length > 80 ? `${item.description.substr(0, 80)}...` : item.description}
                onClick={!AdminMarketStore.isCMS ? () => {
                  CategoryStore.clear();
                  history.push(`/modifyItem/${item.article}`, { itemId: item.article, groupId });
                } : null}
                leftElement={(
                  <img
                    alt="..."
                    style={{
                      width: '3rem', height: '3rem', borderRadius: '80px', objectFit: 'cover',
                    }}
                    src={item.defaultImg && item.defaultImg.url}
                  />
                )}
                rightElement={<UiArrow />}
                bottomDivider={index !== currentItems.length - 1}
                active={item.article === prevChangeId}
                status={item.status ? 'Active' : 'Blocked'}
                statusText={item.status ? i18n.t('Active') : i18n.t('Inactive')}
              />
            ) : null}
          </>
        ))
      }
    </UiCard>
  );
};

export const renderSelectionItems = ({
  isLoading, items, language, selectedIds, setSelectedIds,
}) => {
  if (isLoading) {
    return (
      <CardsContainer>
        {renderLoadingCard()}
        {renderLoadingCard()}
        {renderLoadingCard()}
        {renderLoadingCard()}
      </CardsContainer>
    );
  }

  return (
    <UiCard shadow>
      {
        items && items.map((item, index) => (
          <React.Fragment key={`${item.title.en}-${index}`}>
            <SelectedItemCard
              item={item}
              index={index}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
              itemsLength={items.length}
            />
          </React.Fragment>
        ))
      }
    </UiCard>
  );
};
