import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../../../context/ThemeProvider';

import styles from './Flag.module.css';

const StyledFlag = styled.div`
  border: 0.063rem solid ${ (props) => props.styles.colors.textDisable };

  ${ (props) => props.country && css`
    background: url(${ (props) => props.country });
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  ` }
`;

const StyledFlagSpan = styled.span`
  color: ${ (props) => props.styles.colors.textDisable };
  ${ (props) => props.styles.fonts.regular }
  font-size: 0.563rem;
  line-height: 1.25rem;
`;

const Flag = ({
  flag
}) => {

  const isTheme = useTheme();

  const country = isTheme.styles.media.images.flags[flag];

  return (
    <StyledFlag
      className={ styles.flag }
      country={ country }
      styles={ isTheme.styles }
    >
      { !country &&
        <StyledFlagSpan styles={ isTheme.styles }>
          Flag
        </StyledFlagSpan>
      }
    </StyledFlag>
  );
};

Flag.propTypes = {
  /** alpha2 наименование страны */
  flag: PropTypes.string
};

export default Flag;