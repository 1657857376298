import styled from 'styled-components';
import { UiSection } from '@compassplus/ui-mobicash';

export const ColorSection = styled(UiSection)`
  & > div:first-child {
    max-width: 5rem;
  }
`;
export const Title = styled.h3`
  color: ${(props) => props.theme.textTitle};
`;

export const Text = styled.p`
  color: ${(props) => props.theme.textTitle} !important;
  font-weight: 500;
`;

export const Button = styled.input`
  width: 17px;
  height: 17px;
  margin-top: 4px;
  cursor: pointer;
`;

export const DisabledColorBorder = styled.div`
  border: 2px #c9c9c9 dashed;
  margin-left: 25px;
  border-radius: 30px;
  margin-bottom: 5px;
  width: 40px;
  height: 40px;
`;

export const ActiveColor = styled.div`
  cursor: pointer;
  margin-left: 25px;
  border-radius: 30px;
  margin-bottom: 5px;
  width: 40px;
  height: 40px;
  background: ${(props) => props.backgroundColor};
  
  :hover {
    transform: scale(1.1);
  }
`;

export const SelectedColor = styled.div`
  cursor: pointer;
  border: 1px blue solid;
  margin-left: 25px;
  margin-bottom: 5px;
  border-radius: 30px;
  transform: scale(1.1);
  width: 40px;
  height: 40px;
  background: ${(props) => props.backgroundColor};
`;

export const ActiveSize = styled.div`
  cursor: pointer;
  margin-left: 25px;
  vertical-align: middle;
  text-align: center;
  padding-top: 15px;
  margin-bottom: 5px;
  border: 1px #c9c9c9 solid;
  border-radius: 10px;
  width: 55px;
  height: 55px;
  user-select: none;
  :hover {
    border: 2px blue solid;
    transform: scale(1.02);
  }
`;

export const DisabledSize = styled(ActiveSize)`
  border: 1px #c9c9c9 dashed;
  opacity: 0.5;
  
  :hover{
    border: 1px #c9c9c9 dashed;
    transform: scale(1);
  }
`;

export const TextInput = styled.textarea`
    width: 100%;
    min-height: 35px;
    height: 35px;
    margin: auto;
    font-size: 1rem;
    border: 1px solid black;
    border-radius: 5px;
`;

export const StringDiv = styled.div`
  display: flex;
  padding-bottom: 10px;
`;
