import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Element component
 * @public
 * @version 0.0.97
 * @param {Function} onClick
 * @param {Boolean} [disabled=false]
 * @param {Boolean} [propagation=true]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiMinusForPlates />
 */
const UiMinusForPlates = ({
  onClick,
  disabled,
  propagation,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      disabled={ disabled }
      propagation={ propagation }
      onClick={ onClick }
      innerRef={ innerRef }
      width="0.75rem"
      height="0.75rem"
      viewBox="0 0 12 12"
    >
      <circle cx="6" cy="6" r="6" fill={ isTheme.styles.colors.sectionBackground } />
      <rect x="2" y="5" width="8" height="2" rx="1" fill={ isTheme.styles.colors.textDisable } />
    </Element>
  );
};

UiMinusForPlates.propTypes = {
  /** Обработчик события при клике */
  onClick: PropTypes.func,
  /** Компонент неактивен */
  disabled: PropTypes.bool,
  /** Разрешить/запретить всплытие обработчика onClick */
  propagation: PropTypes.bool,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

UiMinusForPlates.defaultProps = {
  disabled: false,
  propagation: true
};

export default UiMinusForPlates;