class DateData {
  // Для приода: delay - количество дней с момента оформления заказа. length - протяженность (необязательна).
  constructor(newDateData) {
    this.type = (newDateData.type !== undefined) ? newDateData.type : 'setByClient';
    this.subtype = (newDateData.subtype !== undefined) ? newDateData.subtype : 'clientDisableNDays';
    this.period = (newDateData.period !== undefined) ? newDateData.period : { delay: 1, length: 2 };
  }

  setType(newValue) {
    this.type = newValue;
    this.onSetType();
  }

  onSetType() {
    if (this.type === 'setByClient') {
      this.subtype = 'clientDisableNDays';
    }
    if (this.type === 'autoSet') {
      this.subtype = 'autoDate'; // или autoPeriod
    }
  }

  setSubtype(newValue) {
    this.subtype = newValue;
  }

  setPeriodDelay(newValue) {
    this.period.delay = newValue;
  }

  setPeriodLength(newValue) {
    this.period.length = newValue;
  }
}

export default DateData;
