const colors = {
  applicationPrimaryColor: '#BE1E24', // Основной цвет бренда. В кобрендовом приложении меняется на цвет соответствующего бренда
  sectionBackground: '#FFFFFF', // Цвет фона полей ввода, плиток, тулбара и т.д.
  textTitle: '#000000', // Цвет заголовков секций и описания полей
  textReadOnly: '#7F7F7F', // Цвет нередактируемых значений в полях ввода, основной цвет текста, информационных диалогов и панелей
  textDisable: '#D6D6D6', // Цвет для задизейбленных состояний, а так же плейсхолдеров
  textLink: '#037AFF', // Цвет ссылок, активных кнопок и элементов, которые меняют значения полей, введенных или измененных пользователем данных
  applicationPosPrimaryColor: '#0A4D9B', // Основной цвет приложения для торговцев (MobiCash POS)
  sidemenuBackground: '#200B15', // Цвет фона бокового меню
  applicationBackground: '#EBEBEB', // Фон приложения
  overlay_zero_point_two: '#00000033', // Оверлейный слой для всплывающих диалогов и окон. Применяется в дизайне, может отличаться от нативных вариантов оформления
  applicationPrimaryColor_zero_point_five: '#BE1E2480', // Цвет фона полей ввода, плиток, тулбара и т.д.
  warningColor: '#EC1310', // Оформление для предупреждающих или ошибочных сообщений в диалогах и формах. Основной цвет не оплаченных счетов
  attentionColor: '#F8E71C', //
  keysBackgroundColor: '#A3AEBF', //
  keyboardBackgroundColor: '#D3D7DE', //
  settingsOrange: '#EE7A21', // Цвет группы иконок в настройках приложения + Primary color для диспутных счетов
  settingsBlue: '#47A0D8', // Цвет группы иконок в настройках приложения и Account actions
  settingsGreen: '#76B943', // Цвет группы иконок в настройках приложения и Account actions
  settingsPurple: '#8342BD', // Цвет группы иконок в настройках приложения
  pendingBillPrimary: '#1560BD', // Основной цвет для счетов ожидающих оплату. Оформление суммы
  pendingBillSecondary: '#8DA8CC', // Второстепенный цвет для счетов ожидающих оплату. Оформление бэйджиков со статусом счета, карты
  paidBillPrimary: '#228B22', // Основной цвет для оплаченных счетов. Оформление суммы
  paidBillSecondary: '#7AC488', // Второстепенный цвет для оплаченных счетов
  notPaidBillSecondary: '#F08080', // Второстепенный цвет для не оплаченных счетов
  disputeBillSecondary: '#FFD38C', // Второстепенный цвет для диспутных счетов

  favoriteActiveBorderBackground: '#BE1E24',
  favoriteNotActiveBorderBackground: '#FFFFFF',

  favoriteActiveBackground: '#BE1E24',
  favoriteNotActiveBackground: '#FFFFFF',

  favoriteActiveBorderStar: '#FFFFFF',
  favoriteNotActiveBorderStar: '#D6D6D6',

  favoriteActiveStar: '#FFFFFF',
  favoriteNotActiveStar: '#D6D6D6',

  memPad: {
    background: '#FFFFFF',
    colors: [
      '#C3DDF9',
      '#E8F1D4',
      '#FFFDD0',
      '#ECC7EE',
      '#DAFAFF',
      '#FFD1DC',
      '#FFDEB3'
    ]
  }
};

export default colors;