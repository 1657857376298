import { useTheme } from '../../../../../context/ThemeProvider';

const Substrate = ({ cells }) => {

  const isTheme = useTheme();

  return (
    <>
      { cells.map((row) => {
        return row.map((cell, index) => {
          if (cell.T === 'c') {
            return null;
          }

          return (
            <path
              key={ index }
              d="M0.91695 26.1383C-0.305649 24.2217 -0.305651 21.7783 0.916951 19.8617L11.8485 2.73249C12.9423 1.01807 14.8389 0 16.853 0H39.1469C41.1611 0 43.0574 1.01755 44.1512 2.73198L55.0827 19.8612C56.3053 21.7778 56.3053 24.2212 55.0827 26.1378L44.1512 43.267C43.0574 44.9814 41.1608 45.9995 39.1466 45.9995H16.8527C14.8386 45.9995 12.9423 44.9819 11.8485 43.2675L0.91695 26.1383Z"
              fill={ isTheme.styles.colors.memPad.background }
              style={ { transform: `translate(${ cell.coordinates.x }px, ${ cell.coordinates.y }px)` } }
            />
          );
        });
      }) }
    </>
  );
};

export default Substrate;