import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Plate issued bill icon
 * @public
 * @version 1.3.0
 * @param {String} width
 * @param {String} height
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiIssuedBillIcon />
 */
const UiIssuedBillIcon = ({
  width,
  height,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      innerRef={ innerRef }
      width={ width }
      height={ height }
      viewBox="0 0 52 52"
    >
      <path
        d="M28.8945 14.7009H17.005C16.2834 14.7009 15.6985 15.2876 15.6985 16.0113V18.5815C15.6985 19.3052 16.2834 19.8919 17.005 19.8919H28.8945C29.616 19.8919 30.201 19.3052 30.201 18.5815V16.0113C30.201 15.2876 29.616 14.7009 28.8945 14.7009Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path
        d="M42.0401 29.1145C42.0609 29.265 42.0609 29.4176 42.0401 29.5681V35.2731H37.829V29.5782C37.829 29.3871 37.8561 29.1971 37.9094 29.0137C38.0324 28.5952 38.2774 28.2231 38.6129 27.9453L38.7135 27.8646C38.7714 27.8209 38.8318 27.7805 38.8944 27.7437L39.0552 27.653L39.216 27.5824L39.4471 27.5119H39.5878C39.7183 27.5015 39.8493 27.5015 39.9798 27.5119H33.5878V40.2725C33.5878 40.841 33.8137 41.3861 34.2154 41.7871C34.6172 42.1881 35.1617 42.4121 35.7285 42.4094H14.3617C13.3469 42.4104 12.4723 41.6935 12.2712 40.6959V42.3489C12.2933 43.8274 13.5005 45.0111 14.9748 44.9999H36.9245C38.3949 45.0055 39.596 43.8234 39.618 42.3489V37.8635H43.9999V31.6545C43.994 30.461 43.1906 29.4197 42.0401 29.1145Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path
        d="M10.1307 14.1363C8.95068 14.1253 8 13.1628 8 11.9793V9.13686C8.0055 7.959 8.95621 7.00552 10.1307 7H35.7387C36.9131 7.00552 37.8638 7.959 37.8693 9.13686V11.9793C37.8693 13.1611 36.917 14.1207 35.7387 14.1262L10.1307 14.1363Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path
        d="M33.5878 40.3129V10.5682H12.2712V40.3129C12.2712 41.493 13.2252 42.4498 14.4019 42.4498H35.7386C35.17 42.4551 34.6229 42.2324 34.219 41.831C33.815 41.4297 33.5878 40.8831 33.5878 40.3129ZM30.1707 18.5814C30.1652 19.3067 29.5774 19.8917 28.8542 19.8917H16.9949C16.2733 19.8917 15.6883 19.3051 15.6883 18.5814V16.0111C15.6883 15.2858 16.2717 14.6962 16.9949 14.6907H28.8743C29.6014 14.6907 30.1908 15.2819 30.1908 16.0111L30.1707 18.5814Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path
        d="M40 27.4716C40.5669 27.469 41.1114 27.6929 41.5131 28.094C41.9149 28.495 42.1407 29.04 42.1407 29.6085V35.3135H37.8694V29.6085C37.8694 28.4284 38.8233 27.4716 40 27.4716Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path
        d="M30.9245 22.6939H14.9546C14.8159 22.6939 14.7034 22.8067 14.7034 22.9459C14.7034 23.0851 14.8159 23.1979 14.9546 23.1979H30.9245C31.0632 23.1979 31.1757 23.0851 31.1757 22.9459C31.1757 22.8067 31.0632 22.6939 30.9245 22.6939Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M14.9546 25.7682H25.4873C25.6261 25.7682 25.7385 25.6554 25.7385 25.5162C25.7385 25.377 25.6261 25.2642 25.4873 25.2642H14.9546C14.8159 25.2642 14.7034 25.377 14.7034 25.5162C14.7085 25.6532 14.8181 25.763 14.9546 25.7682Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M14.9546 27.7236H25.4873C25.6261 27.7236 25.7385 27.6108 25.7385 27.4716C25.7385 27.3324 25.6261 27.2196 25.4873 27.2196H14.9546C14.8159 27.2196 14.7034 27.3324 14.7034 27.4716C14.7034 27.6108 14.8159 27.7236 14.9546 27.7236Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M14.9546 29.679H25.4873C25.6261 29.679 25.7385 29.5661 25.7385 29.427C25.7385 29.2878 25.6261 29.175 25.4873 29.175H14.9546C14.8159 29.175 14.7034 29.2878 14.7034 29.427C14.7034 29.5661 14.8159 29.679 14.9546 29.679Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M30.9246 29.175H29.0452C28.9064 29.175 28.7939 29.2878 28.7939 29.427C28.7939 29.5661 28.9064 29.679 29.0452 29.679H30.9246C31.0634 29.679 31.1759 29.5661 31.1759 29.427C31.1759 29.2878 31.0634 29.175 30.9246 29.175Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M30.9246 27.2196H29.0452C28.9064 27.2196 28.7939 27.3324 28.7939 27.4716C28.7939 27.6108 28.9064 27.7236 29.0452 27.7236H30.9246C31.0634 27.7236 31.1759 27.6108 31.1759 27.4716C31.1759 27.3324 31.0634 27.2196 30.9246 27.2196Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M30.9246 25.2642H29.0452C28.9064 25.2642 28.7939 25.377 28.7939 25.5162C28.7939 25.6554 28.9064 25.7682 29.0452 25.7682H30.9246C31.0634 25.7682 31.1759 25.6554 31.1759 25.5162C31.1759 25.377 31.0634 25.2642 30.9246 25.2642Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M14.9546 34.2551H25.4772C25.616 34.2551 25.7285 34.1423 25.7285 34.0031C25.7285 33.8639 25.616 33.7511 25.4772 33.7511H14.9546C14.8159 33.7511 14.7034 33.8639 14.7034 34.0031C14.7034 34.1423 14.8159 34.2551 14.9546 34.2551Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M14.9546 36.2205H27.3968C27.5334 36.2154 27.6429 36.1055 27.6481 35.9685C27.6481 35.8294 27.5356 35.7166 27.3968 35.7166H14.9546C14.8159 35.7166 14.7034 35.8294 14.7034 35.9685C14.7034 36.1077 14.8159 36.2205 14.9546 36.2205Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M29.9897 37.6719H14.9143C14.7756 37.6719 14.6631 37.7848 14.6631 37.9239C14.6631 38.0631 14.7756 38.1759 14.9143 38.1759H29.9897C30.1285 38.1759 30.241 38.0631 30.241 37.9239C30.241 37.7848 30.1285 37.6719 29.9897 37.6719Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M40 26.9676H34.1105V11.0722H35.1155C35.3931 11.0722 35.6181 10.8465 35.6181 10.5682C35.6181 10.2898 35.3931 10.0642 35.1155 10.0642H10.7537C10.4762 10.0642 10.2512 10.2898 10.2512 10.5682C10.2566 10.8443 10.4784 11.0668 10.7537 11.0722H11.7588V40.3028C11.7588 41.7613 12.9377 42.9436 14.3919 42.9436H35.7387C37.1906 42.9381 38.3663 41.759 38.3718 40.3028V35.8174H42.6331V29.6084C42.6276 28.1522 41.4519 26.9731 40 26.9676ZM14.402 41.9458C13.5028 41.9458 12.7738 41.2147 12.7738 40.3129V11.0822H33.1055V40.3129C33.0853 40.4769 33.0853 40.6428 33.1055 40.8068V40.958C33.1329 41.064 33.1665 41.1683 33.206 41.2704C33.2267 41.327 33.2537 41.3811 33.2864 41.4317C33.3253 41.5264 33.3724 41.6174 33.4271 41.7039L33.5276 41.8551L33.5879 41.9458H14.402ZM37.8693 28.1066C37.8393 28.1592 37.8058 28.2098 37.7688 28.2578C37.7688 28.3485 37.6683 28.4291 37.6281 28.5198C37.5879 28.6106 37.6281 28.6307 37.5578 28.6912C37.5183 28.7933 37.4847 28.8976 37.4573 29.0037C37.4573 29.0037 37.4573 29.1044 37.4573 29.1548C37.4419 29.3191 37.4419 29.4845 37.4573 29.6487V40.3129C37.4573 41.2147 36.7283 41.9458 35.8291 41.9458C34.9299 41.9458 34.201 41.2147 34.201 40.3129V27.9755H38.0201C37.9797 28.0295 37.9283 28.0742 37.8693 28.1066ZM41.6281 34.8095H38.3718V29.6084C38.4387 28.7535 39.15 28.094 40.005 28.094C40.86 28.094 41.5712 28.7535 41.6382 29.6084L41.6281 34.8095Z"
        fill={ isTheme.styles.colors.textTitle } />
    </Element>
  );
};

UiIssuedBillIcon.propTypes = {
  /** Определяет ширину иконки */
  width: PropTypes.string,
  /** Определяет высоту иконки */
  height: PropTypes.string
};

UiIssuedBillIcon.defaultProps = {
  width: '3.25rem',
  height: '3.25rem'
};

export default UiIssuedBillIcon;