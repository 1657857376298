import React, {useState} from 'react';
import {Text} from "../../../../components/TemplateStyle";
import OrdersRecordsStore from "../../../../stores/OrdersRecordsStore";
import { useHistory } from "react-router";
import {UiButton, UiCard, UiInput, useOptions} from "@compassplus/ui-mobicash";
import {SubContentStiky} from "../../ModifyAttributePage/style";
import i18n from '../../../../i18n';

const OrderEntryField = () => {
  const { modifyingOrder } = OrdersRecordsStore;
  const history = useHistory();
  const isOptions = useOptions();

  const [entryField, setEntryField] = useState(modifyingOrder?.deliveryData?.entryField);

  const renderEntryFieldInput = () => (
    <UiInput
      description={isOptions.language === 'ru' ? entryField?.Title : entryField?.Name}
      value={entryField?.Value}
      onChange={(event) => {
        setEntryField({
          ...entryField,
          'Value': event.target.value
        });
      }}
    />
  )

  return (
   <>
     <Text
      style={{ textTransform: 'uppercase', fontWeight: 600 }}
      view="readonly"
      typography="note"
     >
       {i18n.t('EntryField')}
     </Text>
     <UiCard
       style={{ margin: '0.5rem 0 0.25rem' }}
       shadow
     >
       {renderEntryFieldInput()}
     </UiCard>
     <SubContentStiky>
       <UiButton
         title={i18n.t('Save')}
         onClick={() => {
           OrdersRecordsStore.modifyDeliveryData({ entryField });
           history.replace(`/modifyOrder/${modifyingOrder._id}`);
         }}
       />
     </SubContentStiky>
   </>
  )
}

export default OrderEntryField;