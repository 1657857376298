import React from 'react';

import { Result } from 'antd';

import i18n from '../../../i18n';
import { removeUserData } from '../../../Utils/requestUtil';

import { ThemeButton, PageDiv } from '../../../components/TemplateStyle';

function NoMatch(props) {
  i18n.changeLanguage(localStorage.getItem('language'));

  return (
    <PageDiv>
      <Result
        status="404"
        title={404}
        subTitle={i18n.t('404')}
        extra={[
          <ThemeButton onClick={() => props.history.goBack()}>{i18n.t('Back')}</ThemeButton>,
          <ThemeButton onClick={() => removeUserData()}>{i18n.t('Log off')}</ThemeButton>,
        ]}
      />
    </PageDiv>
  );
}

export default NoMatch;
