import UiText from '../../../UiText';

import styles from './Title.module.css';

const Title = ({ title }) => {

  return (
    <UiText className={ styles.title } view="primary" typography="dashboard-name" position="right">
      { title }
    </UiText>
  );
};

export default Title;