import React, { useState, useReducer } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  UiCard,
  UiInput,
  UiDropdown,
  UiSection,
  UiArrow,
  useOptions
} from '@compassplus/ui-mobicash';
import { observer } from 'mobx-react-lite';
import i18n from '../../../i18n';

import ActionSheet from '../../../components/admin/ActionSheet';
import AttributeCard from './components/AttributeCard';
import LanguageTabs from '../../../components/admin/LanguageTabs';

import DeliveryStore from '../../../stores/DeliveryStore';
import { Text } from "../../../components/TemplateStyle";
import AddAttributeButton from "./components/AddAttributeButton";

const pickupPointPlaceholder = { ru: 'Центр выдачи', en: 'Pickup point' }

const DeliveryModify = observer((props) => {
  const { location, isPage, setLanguage } = props;
  const history = useHistory();
  const isOptions = useOptions();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const params = useParams();

  const [typeSelectOpen, setTypeSelectOpen] = useState(false);

  const checkFormIsValid = () => {
    return Object.keys(DeliveryStore.form).length !== 0
  }

  const renderAttributesSections = () => (
    <>
      <Text
        style={{ textTransform: 'uppercase', margin: '0 0 0.25rem 0' }}
        view="readonly"
        typography="section-description-semibold">
        {i18n.t('Attributes')}
      </Text>
      {
        DeliveryStore.form.attributes.length > 0 && (
          DeliveryStore.form.attributes.map((attr, index) => (
            <React.Fragment key={`${attr.type}-${index}`}>
              <AttributeCard
                attribute={attr}
                onCardClick={() => {
                  history.push('/administration/attribute',
                    {
                      type: attr.type,
                      modify: true,
                      id: index,
                      deliveryId: params.id,
                      deliveryForm: DeliveryStore.form
                    });
                }}
              />
            </React.Fragment>
          ))
        )
      }
    </>
  )

  const renderIssuingCentersSections = () => (
    <>
      <Text style={{ textTransform: 'uppercase' }} view="readonly" typography="section-description-semibold">
        {i18n.t('IssuingCenters')}
      </Text>
      {
          DeliveryStore.form.addressData.type === 'points' && DeliveryStore.form.addressData.points?.map((point, index) => (
              <React.Fragment key={`${point.title.en}-${index}`}>
                <UiCard style={{ margin: '0.5rem 0 0' }}>
                  <UiSection
                      title={point.title[isOptions.language] !== ''
                          ? point.title[isOptions.language]
                          : `${pickupPointPlaceholder[isOptions.language]} ${index + 1}`}
                      rightElement={<UiArrow />}
                      onClick={() => {
                        history.push('/administration/attribute', {
                          type: 'PickupPoint',
                          modify: true,
                          id: index,
                          deliveryId: params.id,
                          deliveryForm: DeliveryStore.form
                        });
                      }}
                  />
                </UiCard>
              </React.Fragment>
          ))
      }
    </>
  )

  const getAttributes = () => {
    switch (DeliveryStore.form.getType()) {
      case 'Pickup':
        return (
          <div style={{ margin: '1rem 0 0' }}>
            {renderIssuingCentersSections()}
          </div>
        );

      default:
        return (
          <div style={{ margin: '1rem 0 0' }}>
            {renderAttributesSections()}
          </div>
        );
    }
  };

  const getTabContent = (language) => (
    <div style={isOptions.screen === 'mobile' ? { padding: '0 0.5rem 0' } : { padding: '0 0 0 6px' }}>
      <UiCard>
        <UiInput
          description={i18n.t('Name')}
          value={DeliveryStore.form.title[language]}
          placeholder={`[${i18n.t('Text')}]`}
          onChange={(event) => { DeliveryStore.form.setTitle(language, event.target.value); forceUpdate(); }}
          bottomDivider
        />
        <UiInput
          description={i18n.t('Type')}
          value={i18n.t(DeliveryStore.form.getType())}
          rightElement={<UiDropdown />}
          onClick={() => { setTypeSelectOpen(!typeSelectOpen); }}
          bottomDivider
        />

        <ActionSheet
          open={typeSelectOpen}
          setOpen={() => { setTypeSelectOpen(false); }}
        >
          {DeliveryStore.form.getAllTypes().map((type, index) => (
            <React.Fragment key={`${type}-${index}`}>
              <UiSection
                  title={i18n.t(type)}
                  view="link"
                  onClick={() => {
                    if (isOptions.screen === 'mobile') {
                      DeliveryStore.form.setType(index); setTypeSelectOpen(false);
                    } else {
                      if (isPage === undefined || isPage) {
                        DeliveryStore.form.setType(index); setTypeSelectOpen(false);
                      } else {
                        DeliveryStore.form.setType(index); setTypeSelectOpen(false);
                        history.push(`/administration/delivery/${location.state.deliveryId}`, {
                          deliveryId: location.state.deliveryId,
                          deliveryForm: location.state.deliveryForm
                        });
                      }
                    }
                  }}
                  bottomDivider={index !== DeliveryStore.form.getAllTypes().length - 1}
              />
            </React.Fragment>
          ))}
        </ActionSheet>

        <UiInput
          description={i18n.t('Description')}
          value={DeliveryStore.form.description[language]}
          placeholder={`[${i18n.t('Text')}]`}
          onChange={(event) => { DeliveryStore.form.setDescription(language, event.target.value); forceUpdate(); }}
        />
      </UiCard>
      {getAttributes()}
    </div>
  );

  const renderPickupAttributes = () => {
    if (DeliveryStore.form.getType() !== 'Pickup') {
      return null;
    }

    return (
      <div style={isOptions.screen === 'mobile'
        ? { padding: '0 1rem 0', marginTop: '1rem', width: '100%' }
        : { padding: '0 0 0 6px', marginTop: '1rem', width: '100%' }}
      >
        {renderAttributesSections()}
      </div>
    )
  }

  return (
    <>
      {checkFormIsValid() ? (
        <>
          <LanguageTabs
            title={i18n.t('IssuingMethod')}
            setSelectedLanguage={setLanguage}
          >
            {getTabContent}
          </LanguageTabs>
          <AddAttributeButton type={DeliveryStore.form.getType()} />
          {renderPickupAttributes()}
        </>
      ) : null}
    </>
  )
});

export default DeliveryModify;
