import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Иконка сайта
 * @public
 * @version 1.5.0
 * @param {String} [width="3.25rem"]
 * @param {String} [height="3.25rem"]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * import { UiSiteIcon, UiPlates, UiPlate } from '@compassplus/ui-mobicash';
 *
 * const App = () => {
 *
 *   return (
 *     <>
 *       <UiSiteIcon />
 *       <UiPlates>
 *         <UiPlate view="2x1" image={ <UiSiteIcon width="100%" height="100%" /> } />
 *       </UiPlates>
 *     </>
 *   );
 * }; */
const UiSiteIcon = ({
  width,
  height,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      innerRef={ innerRef }
      width={ width }
      height={ height }
      viewBox="0 0 52 52"
    >
      <path fillRule="evenodd" clipRule="evenodd"
        d="M47.4882 41.6586L44.4632 40.0606V27.8236C44.4632 26.4726 42.3892 25.3776 39.8312 25.3776H8.63224C6.07324 25.3776 4.00024 26.4726 4.00024 27.8236V40.6676C4.00024 42.0186 6.07324 43.1146 8.63224 43.1146H39.8312C41.4322 43.1146 42.8432 42.6846 43.6752 42.0326L45.2282 42.8526C45.5402 43.0176 45.9492 43.0996 46.3582 43.0996C46.7672 43.0996 47.1762 43.0176 47.4882 42.8526C48.1132 42.5226 48.1132 41.9886 47.4882 41.6586Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M38.969 40.2934H9.35403C6.95403 40.2934 5.00903 38.3474 5.00903 35.9474V12.8464C5.00903 10.4464 6.95403 8.5004 9.35403 8.5004H38.969C41.369 8.5004 43.315 10.4464 43.315 12.8464V35.9474C43.315 38.3474 41.369 40.2934 38.969 40.2934Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M9.3543 9.0004C7.2333 9.0004 5.5083 10.7264 5.5083 12.8464V35.9474C5.5083 38.0674 7.2333 39.7934 9.3543 39.7934H38.9693C41.0893 39.7934 42.8153 38.0674 42.8153 35.9474V12.8464C42.8153 10.7264 41.0893 9.0004 38.9693 9.0004H9.3543ZM38.9693 40.7934H9.3543C6.6823 40.7934 4.5083 38.6194 4.5083 35.9474V12.8464C4.5083 10.1744 6.6823 8.0004 9.3543 8.0004H38.9693C41.6413 8.0004 43.8153 10.1744 43.8153 12.8464V35.9474C43.8153 38.6194 41.6413 40.7934 38.9693 40.7934Z"
        fill={ isTheme.styles.colors.textTitle } />
      <mask id="mask0_82_15500" style={ { maskType: 'alpha' } } maskUnits="userSpaceOnUse" x="4" y="8" width="44" height="36">
        <path d="M4 43.114H47.956V8H4V43.114Z" fill={ isTheme.styles.colors.sectionBackground } />
      </mask>
      <g mask="url(#mask0_82_15500)">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.00806 14.854H43.3151V14.354H5.00806V14.854Z" fill={ isTheme.styles.colors.textTitle } />
        <path fillRule="evenodd" clipRule="evenodd"
          d="M39.454 11.9116H23.689C23.551 11.9116 23.439 11.7996 23.439 11.6616C23.439 11.5236 23.551 11.4116 23.689 11.4116H39.454C39.592 11.4116 39.704 11.5236 39.704 11.6616C39.704 11.7996 39.592 11.9116 39.454 11.9116Z"
          fill={ isTheme.styles.colors.textTitle } />
        <path fillRule="evenodd" clipRule="evenodd"
          d="M29.2645 37.7504H9.44849C9.31049 37.7504 9.19849 37.6384 9.19849 37.5004C9.19849 37.3624 9.31049 37.2504 9.44849 37.2504H29.2645C29.4025 37.2504 29.5145 37.3624 29.5145 37.5004C29.5145 37.6384 29.4025 37.7504 29.2645 37.7504Z"
          fill={ isTheme.styles.colors.textTitle } />
        <path fillRule="evenodd" clipRule="evenodd"
          d="M23.0429 35.831H12.1609C12.0229 35.831 11.9109 35.719 11.9109 35.581C11.9109 35.443 12.0229 35.331 12.1609 35.331H23.0429C23.1809 35.331 23.2929 35.443 23.2929 35.581C23.2929 35.719 23.1809 35.831 23.0429 35.831Z"
          fill={ isTheme.styles.colors.textTitle } />
        <path fillRule="evenodd" clipRule="evenodd"
          d="M16.1435 33.7744H9.44849C9.31049 33.7744 9.19849 33.6624 9.19849 33.5244C9.19849 33.3864 9.31049 33.2744 9.44849 33.2744H16.1435C16.2815 33.2744 16.3935 33.3864 16.3935 33.5244C16.3935 33.6624 16.2815 33.7744 16.1435 33.7744Z"
          fill={ isTheme.styles.colors.textTitle } />
        <path fillRule="evenodd" clipRule="evenodd"
          d="M10.329 35.831H9.44897C9.30997 35.831 9.19897 35.719 9.19897 35.581C9.19897 35.443 9.30997 35.331 9.44897 35.331H10.329C10.467 35.331 10.579 35.443 10.579 35.581C10.579 35.719 10.467 35.831 10.329 35.831Z"
          fill={ isTheme.styles.colors.textTitle } />
        <path fillRule="evenodd" clipRule="evenodd"
          d="M9.95309 11.6723C9.95309 11.9503 9.72709 12.1763 9.44909 12.1763C9.17009 12.1763 8.94409 11.9503 8.94409 11.6723C8.94409 11.3933 9.17009 11.1683 9.44909 11.1683C9.72709 11.1683 9.95309 11.3933 9.95309 11.6723Z"
          fill={ isTheme.styles.colors.textTitle } />
        <path fillRule="evenodd" clipRule="evenodd"
          d="M12.063 11.6723C12.063 11.9503 11.837 12.1763 11.559 12.1763C11.28 12.1763 11.054 11.9503 11.054 11.6723C11.054 11.3933 11.28 11.1683 11.559 11.1683C11.837 11.1683 12.063 11.3933 12.063 11.6723Z"
          fill={ isTheme.styles.colors.textTitle } />
        <path fillRule="evenodd" clipRule="evenodd"
          d="M14.1728 11.6723C14.1728 11.9503 13.9468 12.1763 13.6688 12.1763C13.3898 12.1763 13.1638 11.9503 13.1638 11.6723C13.1638 11.3933 13.3898 11.1683 13.6688 11.1683C13.9468 11.1683 14.1728 11.3933 14.1728 11.6723Z"
          fill={ isTheme.styles.colors.textTitle } />
        <path fillRule="evenodd" clipRule="evenodd"
          d="M38.6658 26.9404H9.73185C9.29685 26.9404 8.94385 26.5874 8.94385 26.1524V18.9234C8.94385 18.4884 9.29685 18.1354 9.73185 18.1354H38.6658C39.1008 18.1354 39.4538 18.4884 39.4538 18.9234V26.1524C39.4538 26.5874 39.1008 26.9404 38.6658 26.9404Z"
          fill={ isTheme.styles.colors.textDisable } />
        <path fillRule="evenodd" clipRule="evenodd"
          d="M9.73234 18.3857C9.43534 18.3857 9.19434 18.6267 9.19434 18.9237V26.1527C9.19434 26.4487 9.43534 26.6907 9.73234 26.6907H38.6663C38.9623 26.6907 39.2043 26.4487 39.2043 26.1527V18.9237C39.2043 18.6267 38.9623 18.3857 38.6663 18.3857H9.73234ZM38.6663 27.1907H9.73234C9.16034 27.1907 8.69434 26.7247 8.69434 26.1527V18.9237C8.69434 18.3507 9.16034 17.8857 9.73234 17.8857H38.6663C39.2383 17.8857 39.7043 18.3507 39.7043 18.9237V26.1527C39.7043 26.7247 39.2383 27.1907 38.6663 27.1907Z"
          fill={ isTheme.styles.colors.textTitle } />
        <path fillRule="evenodd" clipRule="evenodd"
          d="M17.997 24.9213C17.902 25.1613 17.728 25.2813 17.473 25.2813C17.219 25.2813 17.048 25.1613 16.961 24.9213L15.827 21.9003L14.703 24.9213C14.608 25.1613 14.434 25.2813 14.179 25.2813C13.925 25.2813 13.754 25.1613 13.667 24.9213L12.031 20.5583C11.973 20.4133 11.976 20.2753 12.041 20.1443C12.107 20.0053 12.208 19.9113 12.347 19.8603C12.492 19.8023 12.63 19.8063 12.761 19.8713C12.893 19.9373 12.983 20.0383 13.034 20.1773L14.179 23.2193L15.313 20.1983C15.379 20.0313 15.488 19.9193 15.641 19.8603C15.699 19.8383 15.753 19.8273 15.805 19.8273H15.924C15.924 19.8353 15.928 19.8383 15.935 19.8383H15.957C15.964 19.8383 15.972 19.8423 15.979 19.8493H16.001C16.008 19.8573 16.016 19.8603 16.022 19.8603C16.175 19.9113 16.281 20.0243 16.339 20.1983L17.473 23.2193L18.619 20.1773C18.677 20.0313 18.771 19.9293 18.902 19.8713C19.099 19.8133 19.237 19.8103 19.317 19.8603C19.462 19.9193 19.56 20.0133 19.611 20.1443C19.677 20.2903 19.684 20.4273 19.633 20.5583L17.997 24.9213Z"
          fill={ isTheme.styles.colors.textTitle } />
        <path fillRule="evenodd" clipRule="evenodd"
          d="M26.3299 24.9213C26.2359 25.1613 26.0609 25.2813 25.8069 25.2813C25.5519 25.2813 25.3809 25.1613 25.2939 24.9213L24.1599 21.9003L23.0359 24.9213C22.9409 25.1613 22.7669 25.2813 22.5119 25.2813C22.2579 25.2813 22.0869 25.1613 21.9999 24.9213L20.3639 20.5583C20.3059 20.4133 20.3089 20.2753 20.3749 20.1443C20.4399 20.0053 20.5419 19.9113 20.6799 19.8603C20.8259 19.8023 20.9629 19.8063 21.0949 19.8713C21.2259 19.9373 21.3159 20.0383 21.3679 20.1773L22.5119 23.2193L23.6469 20.1983C23.7119 20.0313 23.8209 19.9193 23.9739 19.8603C24.0319 19.8383 24.0869 19.8273 24.1379 19.8273H24.2579C24.2579 19.8353 24.2609 19.8383 24.2679 19.8383H24.2909C24.2979 19.8383 24.3049 19.8423 24.3119 19.8493H24.3339C24.3409 19.8573 24.3489 19.8603 24.3559 19.8603C24.5079 19.9113 24.6139 20.0243 24.6719 20.1983L25.8069 23.2193L26.9519 20.1773C27.0099 20.0313 27.1039 19.9293 27.2359 19.8713C27.4319 19.8133 27.5699 19.8103 27.6499 19.8603C27.7949 19.9193 27.8939 20.0133 27.9449 20.1443C28.0099 20.2903 28.0169 20.4273 27.9659 20.5583L26.3299 24.9213Z"
          fill={ isTheme.styles.colors.textTitle } />
        <path fillRule="evenodd" clipRule="evenodd"
          d="M37.3657 33.1518L44.0387 39.8248C44.3347 40.1208 44.7217 40.2688 45.1097 40.2688C45.4967 40.2688 45.8837 40.1208 46.1787 39.8248C46.7707 39.2338 46.7707 38.2758 46.1787 37.6848L39.5787 31.0848"
          fill={ isTheme.styles.colors.applicationPrimaryColor } />
        <path fillRule="evenodd" clipRule="evenodd"
          d="M40.9594 26.9404C40.9594 31.1734 37.5274 34.6054 33.2944 34.6054C29.0614 34.6054 25.6294 31.1734 25.6294 26.9404C25.6294 22.7074 29.0614 19.2754 33.2944 19.2754C37.5274 19.2754 40.9594 22.7074 40.9594 26.9404Z"
          fill={ isTheme.styles.colors.sectionBackground } />
        <path fillRule="evenodd" clipRule="evenodd"
          d="M33.2949 19.7758C29.3439 19.7758 26.1299 22.9898 26.1299 26.9408C26.1299 30.8908 29.3439 34.1058 33.2949 34.1058C37.2459 34.1058 40.4599 30.8908 40.4599 26.9408C40.4599 22.9898 37.2459 19.7758 33.2949 19.7758ZM33.2949 35.1058C28.7929 35.1058 25.1299 31.4428 25.1299 26.9408C25.1299 22.4388 28.7929 18.7758 33.2949 18.7758C37.7969 18.7758 41.4599 22.4388 41.4599 26.9408C41.4599 31.4428 37.7969 35.1058 33.2949 35.1058Z"
          fill={ isTheme.styles.colors.textTitle } />
        <path fillRule="evenodd" clipRule="evenodd"
          d="M37.0125 19.9916C36.9305 20.0616 36.8615 20.1456 36.8155 20.2596L35.4055 24.0056L34.0095 20.2856C33.9375 20.0716 33.8075 19.9326 33.6195 19.8696C33.6115 19.8696 33.6025 19.8656 33.5935 19.8566H33.5665C33.5575 19.8476 33.5485 19.8426 33.5395 19.8426H33.5125C33.5035 19.8426 33.4995 19.8386 33.4995 19.8296H33.3515C33.2885 19.8296 33.2215 19.8426 33.1495 19.8696C32.9615 19.9416 32.8275 20.0806 32.7475 20.2856L31.3505 24.0056L29.9415 20.2596C29.9085 20.1716 29.8615 20.0956 29.8035 20.0326C29.3905 20.1256 29.0145 20.2756 28.6655 20.5186C28.6695 20.5886 28.6775 20.6576 28.7065 20.7296L30.7205 26.0996C30.8275 26.3946 31.0375 26.5426 31.3505 26.5426C31.6645 26.5426 31.8785 26.3946 31.9955 26.0996L33.3785 22.3806L34.7755 26.0996C34.8825 26.3946 35.0925 26.5426 35.4055 26.5426C35.7195 26.5426 35.9335 26.3946 36.0505 26.0996L38.0645 20.7296C38.0735 20.7056 38.0695 20.6806 38.0755 20.6576C37.7215 20.4336 37.3555 20.2316 37.0125 19.9916Z"
          fill={ isTheme.styles.colors.textTitle } />
      </g>
    </Element>
  );
};

UiSiteIcon.propTypes = {
  /** Определяет ширину иконки */
  width: PropTypes.string,
  /** Определяет высоту иконки */
  height: PropTypes.string
};

UiSiteIcon.defaultProps = {
  width: '3.25rem',
  height: '3.25rem'
};

export default UiSiteIcon;