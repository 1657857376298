import React, { useReducer } from 'react';
import {
  UiCard, UiInput, UiScrollArrow,
} from '@compassplus/ui-mobicash';
import { observer } from 'mobx-react-lite';
import i18n from '../../../../i18n';
import DeliveryStore from '../../../../stores/DeliveryStore';
import { CheckIsMobile } from '../../../../Utils/Hoocks';

const DateFields = observer((props) => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const isMobile = CheckIsMobile();

  const onDaysInput = (event) => {
    let inputValue = event.target.value.replace(/[^0-9]/g, '');
    if (inputValue !== '0') {
      const stringWithoutFirstZero = inputValue.match(/[1-9]+[0-9]*/g);
      if (stringWithoutFirstZero !== null) {
        inputValue = Number(stringWithoutFirstZero[0]);
      } else {
        inputValue = '';
      }
    }
    event.target.value = Number(inputValue);
    DeliveryStore.form.date.setPeriodDelay(inputValue);
    forceUpdate();
  };

  const onLengthInput = (event) => {
    let inputValue = event.target.value.replace(/[^0-9]/g, '');
    const stringWithoutFirstZero = inputValue.match(/[1-9]+[0-9]*/g);
    if (stringWithoutFirstZero !== null) {
      inputValue = Number(stringWithoutFirstZero[0]);
    } else {
      inputValue = '';
    }
    event.target.value = inputValue;
    DeliveryStore.form.date.setPeriodLength(inputValue);
    forceUpdate();
  };

  return (
    <div style={isMobile ? { padding: '0 1rem 0', marginTop: '1rem', width: '100%' } : {
      padding: '0 0 0 6px',
      width: '100%',
    }}
    >
      <UiCard>
        <UiInput
          description={i18n.t('DeliveryDate')}
          value={i18n.t(DeliveryStore.form.date.type)}
          rightElement={(
            <UiScrollArrow onClick={() => {
              (DeliveryStore.form.date.type === 'autoSet') ? DeliveryStore.form.date.setType('setByClient') : DeliveryStore.form.date.setType('autoSet');
              forceUpdate();
            }}
            />
          )}
          bottomDivider
        />
        {
          (DeliveryStore.form.date.type === 'autoSet') ? (
            <>
              <UiInput
                description={i18n.t('DeliveryTime')}
                value={i18n.t(DeliveryStore.form.date.subtype)}
                rightElement={(
                  <UiScrollArrow onClick={() => {
                    (DeliveryStore.form.date.subtype === 'autoPeriod') ? DeliveryStore.form.date.setSubtype('autoDate') : DeliveryStore.form.date.setSubtype('autoPeriod');
                    forceUpdate();
                  }}
                  />
                )}
                bottomDivider
              />
              {
                (DeliveryStore.form.date.subtype === 'autoPeriod') ? (
                  <>
                    <UiInput
                      description={i18n.t('DeliveryWillTake(FromCheckoutMoment)')}
                      placeholder={i18n.t('CountOfDays')}
                      value={DeliveryStore.form.date.period.delay}
                      onChange={onDaysInput}
                      bottomDivider
                    />
                    <UiInput
                      description={i18n.t('to')}
                      placeholder={i18n.t('CountOfDays')}
                      value={DeliveryStore.form.date.period.length}
                      onChange={onLengthInput}
                    />
                  </>
                ) : (
                  <UiInput
                    description={i18n.t('OrderWillBeDeliveredIn')}
                    placeholder={i18n.t('CountOfDays')}
                    value={DeliveryStore.form.date.period.delay}
                    onChange={onDaysInput}
                  />
                )
              }
            </>
          ) : (
            <>
              <UiInput
                description={i18n.t('DeliveryTime')}
                value={i18n.t(DeliveryStore.form.date.subtype)}
                readOnly
                bottomDivider
              />
              <UiInput
                description={i18n.t('CountOfDays')}
                placeholder={i18n.t('CountOfDays')}
                value={DeliveryStore.form.date.period.delay}
                onChange={onDaysInput}
              />
            </>
          )
        }
      </UiCard>
    </div>
  );
});

export default DateFields;
