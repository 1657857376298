import React, { useRef, useEffect, useState } from 'react';
import { UiIcon } from '@compassplus/ui-mobicash';
import { ModalWrapper, ModalSlide } from '../style';
import { ModalContent, Modal, ArrowCircle, CloseCircle } from './style';

const ModalSlider = ({
  isActive, images, index, open, setOpen,
}) => {
  const slideRef = useRef();
  const [xPosition, setXPosition] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const imageWidth = 356;

  useEffect(() => {
    setSelectedIndex(index);
    setXPosition(index * -imageWidth);
  }, [index]);

  const onRightClick = () => {
    if (selectedIndex + 1 === images.length) {
      setSelectedIndex(0);
      setXPosition(0);
    } else {
      setSelectedIndex(selectedIndex + 1);
      setXPosition((selectedIndex + 1) * -imageWidth);
    }
  };

  const onLeftClick = () => {
    if (selectedIndex === 0) {
      setSelectedIndex(images.length - 1);
      setXPosition((images.length - 1) * -imageWidth);
    } else {
      setSelectedIndex(selectedIndex - 1);
      setXPosition((selectedIndex - 1) * -imageWidth);
    }
  };

  return (
    <Modal style={open ? { transform: 'scale(1)' } : {}}>
      <ModalContent onClick={(e) => { e.stopPropagation(); }}>
        <CloseCircle onClick={() => { setOpen(false); }}>
          <UiIcon icon="cancel" viewBox="0 0 28 28" setWidth="1.25rem" setHeight="1.25rem" />
        </CloseCircle>
        {
          images.length > 1 ? (
            <ArrowCircle style={{ left: '-1.3rem' }} onClick={() => { onLeftClick(); }}>
              <UiIcon icon="back" viewBox="0 0 28 28" setWidth="1.458125rem" setHeight="1.458125rem" />
            </ArrowCircle>
          ) : null
        }
        <ModalWrapper style={!isActive ? { opacity: '0.6' } : {}}>
          <ModalSlide xPosition={xPosition} ref={slideRef}>
            {images.map((img, i) => (
              <img src={img.url} alt="..." key={img.url} />
            ))}
          </ModalSlide>
        </ModalWrapper>
        {
          images.length > 1 ? (
            <ArrowCircle onClick={() => { onRightClick(); }}>
              <UiIcon icon="back" viewBox="0 0 28 28" setWidth="1.458125rem" setHeight="1.458125rem" style={{ transform: 'matrix(-1, 0, 0, 1, 0, 0)' }} />
            </ArrowCircle>
          ) : null
        }
      </ModalContent>
    </Modal>
  );
};

export default ModalSlider;
