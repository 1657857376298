import cn from 'classnames';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../../../context/ThemeProvider';

import Input from '../../../../Functional/Input';
import { StyledBlockedInput, StyledCardBackground, StyledInputBackground } from '../LeftBankCardOld/Components/Styled';

import styles from './RightBankCardOld.module.css';

const StyledCard = styled.div`
  background: ${ (props) => props.styles.colors.overlay_zero_point_two };

  ${ StyledCardBackground }
`;

const StyledCvv = styled(Input)`
  text-align: center;

  ${ (props) => {
    if (props.disabled) {
      return css`
        ${ StyledBlockedInput };
      `;
    } else {
      return css`
        ${ StyledInputBackground };
      `;
    }
  } }
`;

const RightBankCardOld = ({
  className,
  disabled,
  bankCard,
  showCvv,
  cvv,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <StyledCard
      { ...props }
      className={ cn(styles.container, className) }
      srcBackground={ bankCard.srcBackground || isTheme.styles.media.images.cards.rightBankCardBackground }
      styles={ isTheme.styles }
    >
      { showCvv &&
        <StyledCvv
          inputMode="numeric"
          { ...cvv }
          className={ cn(styles.cvv, cvv.className) }
          type="password"
          maxLength="4"
          disabled={ disabled || cvv.disabled }
          styles={ isTheme.styles }
        />
      }
    </StyledCard>
  );
};

export default RightBankCardOld;