import { createContext, useContext, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { changeStorage } from "../services/changeStorage";

import { screens } from "../constants";

const OptionsContext = createContext(undefined);

/**
 * Options provider
 * @public
 * @version 0.0.90
 * @param {Boolean} [cookies=false]
 * @param {'mobile'|'desktop'} screen
 * @param {String} [language=en]
 * @param {String} [scaling=16]
 * @param {*} children
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <OptionsProvider>{ children }</OptionsProvider>
 */
export const OptionsProvider = ({
  cookies,
  screen,
  language,
  scaling,
  children,
  ...props
}) => {

  const [isCookies, setIsCookies] = useState(() => {
    return !!cookies;
  });

  const [isScreen, setIsScreen] = useState(null);

  const handlerScreen = useCallback(() => {
    if (window.innerWidth > 664 && isScreen !== screens.DESKTOP) {
      setIsScreen(screens.DESKTOP);
    } else if (window.innerWidth <= 664 && isScreen !== screens.MOBILE) {
      setIsScreen(screens.MOBILE);
    }
  }, [isScreen]);

  useEffect(() => {
    if (!screen) {
      window.addEventListener('resize', handlerScreen);

      handlerScreen();

      return () => {
        window.removeEventListener('resize', handlerScreen);
      };
    }
  }, [screen, handlerScreen]);

  const [isLanguage, setIsLanguage] = useState(() => {
    if (!!changeStorage.getItem('language')) {
      return changeStorage.getItem('language');
    } else {
      return language || 'en';
    }
  });

  useEffect(() => {
    if (isCookies && changeStorage.getItem('language') !== isLanguage) {
      changeStorage.setItem('language', isLanguage);
    }
  }, [isCookies, isLanguage]);

  const [isScaling, setIsScaling] = useState(() => {
    if (!!changeStorage.getItem('scaling')) {
      return changeStorage.getItem('scaling');
    } else {
      return scaling || '16';
    }
  });

  useEffect(() => {
    if (isCookies && changeStorage.getItem('scaling') !== isScaling) {
      changeStorage.setItem('scaling', isScaling);
    }

    document.documentElement.style.setProperty('font-size', `${ parseInt(isScaling) }px`)
  }, [isCookies, isScaling]);

  const [infoActive, setInfoActive] = useState(false);
  const [infoMenu, setInfoMenu] = useState('all');

  const setInfo = ({ active = infoActive, menu = infoMenu }) => {
    setInfoActive(active);
    setInfoMenu(menu);
  }

  return (
    <OptionsContext.Provider
      { ...props }
      value={ {
        cookies: isCookies,
        setCookies: setIsCookies,

        screen: isScreen,

        language: isLanguage,
        setLanguage: setIsLanguage,

        scaling: isScaling,
        setScaling: setIsScaling,

        info: {
          active: infoActive,
          menu: infoMenu
        },
        setInfo: setInfo
      } }
    >
      { children }
    </OptionsContext.Provider>
  );
};

OptionsProvider.propTypes = {
  cookies: PropTypes.bool,
  screen: PropTypes.oneOf([screens.MOBILE, screens.DESKTOP]),
  language: PropTypes.string,
  scaling: PropTypes.string,
};

export default OptionsProvider;

export const useOptions = () => {
  const context = useContext(OptionsContext);

  if (context === undefined) {
    throw new Error('useOptions must be used within a OptionsProvider');
  }

  return context;
};