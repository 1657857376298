import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Element component
 * @public
 * @version 0.0.97
 * @param {Boolean} [active=false]
 * @param {Function} onClick
 * @param {Boolean} [disabled=false]
 * @param {Boolean} [propagation=true]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiPointer />
 */
const UiPointer = ({
  active,
  onClick,
  disabled,
  propagation,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  const pointer = generatePointer();

  return (
    <Element
      { ...props }
      color={ isTheme.styles.colors.applicationPrimaryColor }
      disabled={ disabled }
      propagation={ propagation }
      onClick={ onClick }
      innerRef={ innerRef }
      width="0.625rem"
      height="0.625rem"
      viewBox="0 0 10 10"
    >
      { pointer }
    </Element>
  );

  function generatePointer() {
    if (active) {
      return <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 5C10 7.76142 7.76142 10 5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5ZM9 5C9 7.20914 7.20914 9 5 9C2.79086 9 1 7.20914 1 5C1 2.79086 2.79086 1 5 1C7.20914 1 9 2.79086 9 5Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 8C6.65685 8 8 6.65685 8 5C8 3.34315 6.65685 2 5 2C3.34315 2 2 3.34315 2 5C2 6.65685 3.34315 8 5 8Z"
        />
      </>;
    } else {
      return <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 7C6.10457 7 7 6.10457 7 5C7 3.89543 6.10457 3 5 3C3.89543 3 3 3.89543 3 5C3 6.10457 3.89543 7 5 7Z"
        fillOpacity="0.5"
      />
    }
  }
};

UiPointer.propTypes = {
  /** Состояние (активен/не активен) элемента */
  active: PropTypes.bool,
  /** Обработчик события при клике */
  onClick: PropTypes.func,
  /** Компонент неактивен */
  disabled: PropTypes.bool,
  /** Разрешить/запретить всплытие обработчика onClick */
  propagation: PropTypes.bool,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

UiPointer.defaultProps = {
  active: false,
  disabled: false,
  propagation: true
};

export default UiPointer;