import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import UiInput from '../../Ui/UiInput';
import UiScrollArrow from '../../Ui/UiScrollArrow';

/**
 * Input scroll component
 * @public
 * @version 0.0.90
 * @param {String} id
 * @param {Object[]} value
 * @param {String} value[].text
 * @param {String} value[].value
 * @param {Number} value[].index
 * @param {Object[]} forms
 * @param {String} forms[].text
 * @param {String} forms[].value
 * @param {Number} forms[].index
 * @param {Function} setForm
 * @param {Object} state
 * @param {String} state.text
 * @param {String} state.value
 * @param {Number} state.index
 * @param {Function} setState
 * @param {Object} defaultValue
 * @param {String} defaultValue.text
 * @param {String} defaultValue.value
 * @param {Number} defaultValue.index
 * @param {Boolean} [disabled=false]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiInputScroll />
 */
const UiInputScroll = ({
  id,
  value,
  forms,
  setForm,
  state,
  setState,
  defaultValue,
  disabled,
  ...props
}) => {

  const inputValueList = useMemo(() => {
    if (value.length !== 0) {
      return value;
    } else {
      return [];
    }
  }, [value]);

  const [inputValue, setInputValue] = useState(() => {
    if (!!defaultValue) {
      return { text: defaultValue.text, value: defaultValue.value, index: defaultValue.index };
    } else if (value.length !== 0) {
      return { text: value[0].text, value: value[0].value, index: value[0].index };
    } else
      return { text: '', value: '', index: 0 };
  });

  useEffect(() => {
    if (forms) {
      const form = forms.find((item) => item.id === id);
      if (!form) {
        if (!!defaultValue) {
          setForm(id, { text: defaultValue.text, value: defaultValue.value, index: defaultValue.index });
        } else if (inputValueList.length !== 0) {
          setForm(id, { text: inputValueList[0].text, value: inputValueList[0].value, index: inputValueList[0].index });
        } else {
          setForm(id, { text: '', value: '', index: 0 });
        }
      } else {
        setInputValue({ text: form.text, value: form.value, index: form.index });
      }
    } else if (state) {
      if (state.text !== undefined && state.value !== undefined && state.index !== undefined) {
        setInputValue({ text: state.text, value: state.value, index: state.index });
      } else {
        if (!!defaultValue) {
          setState({ text: defaultValue.text, value: defaultValue.value, index: defaultValue.index });
        } else if (inputValueList.length !== 0) {
          setState({ text: inputValueList[0].text, value: inputValueList[0].value, index: inputValueList[0].index });
        } else {
          setState({ text: '', value: '', index: 0 });
        }
      }
    }
  }, [forms, id, state, setForm, defaultValue, inputValueList, setState]);

  const handlerValue = () => {
    const index = (inputValue.index + 1) % inputValueList.length;
    const inputValueNext = inputValueList[index];
    if (!!inputValueNext) {
      if (setForm) {
        setForm(id, { text: inputValueNext.text, value: inputValueNext.value, index: inputValueNext.index });
      } else if (setState) {
        setState({ text: inputValueNext.text, value: inputValueNext.value, index: inputValueNext.index });
      } else {
        setInputValue({ text: inputValueNext.text, value: inputValueNext.value, index: inputValueNext.index });
      }
    }
  }

  return (
    <UiInput
      { ...props }
      readOnly={ true }
      disabled={ disabled }
      flag={ undefined }
      value={ inputValue.text }
      onClick={ inputValueList.length > 1 ? () => handlerValue() : undefined }
      rightElement={ <UiScrollArrow disabled={ disabled || !(inputValueList.length > 1) } /> }
    />
  );
};

UiInputScroll.propTypes = {
  /** Определяет уникальный идентификатор */
  id: PropTypes.string,
  /** Определяет массив объектов возможных значений скролла */
  value: PropTypes.arrayOf(PropTypes.exact({
    text: PropTypes.string,
    value: PropTypes.string,
    index: PropTypes.number
  })),
  /** Определяет массив объектов хука useForm (UI) */
  forms: PropTypes.array,
  /** Функция обработчик хука useForm (UI) */
  setForm: PropTypes.func,
  /** Определяет объект хука useState (React) */
  state: PropTypes.object,
  /** Функция обработчик хука useState (React) */
  setState: PropTypes.func,
  /** Определяет значение по умолчанию */
  defaultValue: PropTypes.exact({
    text: PropTypes.string,
    value: PropTypes.string,
    index: PropTypes.number
  }),
  /** Делает компонент неактивным */
  disabled: PropTypes.bool
};

UiInputScroll.defaultProps = {
  value: [{ text: 'Value 1', value: 'value1', index: 0 }, { text: 'Value 2', value: 'value2', index: 1 }],
  disabled: false
};

export default UiInputScroll;