import React, {useEffect} from 'react';
import {observer} from "mobx-react-lite";
import GlobalStateStore from "../../../stores/GlobalStateStore";
import i18n from '../../../i18n';
import OrdersRecordsStore from "../../../stores/OrdersRecordsStore";
import { useOptions } from "@compassplus/ui-mobicash";
import {MContentDiv, SubContentDiv} from "../../../components/TemplateStyle";
import ModifyOrder from "./ModifyOrder";
import Orders from "../OrdersPage/Orders";
import {useHistory, useParams} from "react-router";
import ModifyOrderData from "./ModifyOrderData/ModifyOrderData";

const ModifyOrderPage = observer(() => {
  const { modifyingOrder, isLoading } = OrdersRecordsStore;
  const isOptions = useOptions();
  const params = useParams();
  const { orderId } = params;
  const history = useHistory();

  const modifyingOrderData = params.attribute;

  useEffect(() => {
    if (!modifyingOrder) {
      OrdersRecordsStore.getOrderData(orderId, history);
    }
  }, [orderId]);

  useEffect(() => {
    GlobalStateStore.setHeaderProperties({
      title: isLoading ? i18n.t('Orders') : `${i18n.t('Order')} #${modifyingOrder?.eInvoicingOrder?.Id}`,
      isBack: true,
      onBack: () => {
        if (modifyingOrderData) {
          history.replace(`/modifyOrder/${modifyingOrder._id}`);
        } else {
          history.push('/orders');
        }
      }
    });
  }, [isLoading, modifyingOrder, modifyingOrderData]);

  if (isLoading) {
    return (
      <MContentDiv>
        <Orders />
      </MContentDiv>
    )
  }

  if (isOptions.screen === 'mobile') {
    return (
      <>
        {
          modifyingOrderData ? (
            <MContentDiv>
              <ModifyOrderData modifyingOrderData={modifyingOrderData} />
            </MContentDiv>
          ) : (
            <MContentDiv>
              <ModifyOrder />
            </MContentDiv>
          )
        }
      </>
    )
  }

  return (
    <>
      {
        modifyingOrderData ? (
          <>
            <MContentDiv>
              <ModifyOrder modifyingOrderData={modifyingOrderData} />
            </MContentDiv>
            <SubContentDiv>
              <ModifyOrderData modifyingOrderData={modifyingOrderData} />
            </SubContentDiv>
          </>
        ) : (
          <>
            <MContentDiv>
              <Orders activeOrderId={modifyingOrder?._id} />
            </MContentDiv>
            <SubContentDiv>
              <ModifyOrder />
            </SubContentDiv>
          </>
        )
      }
    </>
  )
});

export default ModifyOrderPage;