import React, { useState } from 'react';
import { UiInput, UiDropdown, useOptions } from '@compassplus/ui-mobicash';
import { observer } from 'mobx-react-lite';
import ActionSheet from '../ActionSheet';
import ItemStore from '../../../stores/ItemStore';
import ParentGuidsSelections from './ParentGuidsSelections';

const ParentGuidsDropdown = observer(({
  description, value, setValue, type,
}) => {
  const [selectionIsOpen, setSelectionIsOpen] = useState(false);

  return (
    <>
      <UiInput
        required
        description={description}
        readOnly
        value={value}
        active={selectionIsOpen}
        onClick={() => { setSelectionIsOpen(!selectionIsOpen); }}
        rightElement={<UiDropdown />}
        bottomDivider
      />
      <ActionSheet
        open={selectionIsOpen}
        setOpen={() => {
          setSelectionIsOpen(false);
        }}
      >
        <ParentGuidsSelections
          type={type}
          isOpened={selectionIsOpen}
          onClick={(value) => {
            if (type === 'group') {
              setValue({
                key: value._id || value.type,
                value: value.title,
              });
            }
            if (type === 'item') {
              ItemStore.groupGuid = value._id || value.type;
            }
            setSelectionIsOpen(false);
          }}
        />
      </ActionSheet>
    </>
  );
});

export default ParentGuidsDropdown;
