import React, { useEffect, useState } from 'react';
import { UiMinus, UiPlus, useOptions } from '@compassplus/ui-mobicash';
import { Description } from '../../OrderPage/style';
import { Minus, Plus } from '../../../../components/TemplateStyle';
import ShoppingCartStore from '../../../../stores/ShoppingCartStore';
import Merchant from '../../../../stores/Merchant';

const ItemCount = ({ item }) => {
  const isOptions = useOptions();

  const [update, setUpdate] = useState(false);
  useEffect(() => {
    async function fetchData() {
      await fetch('/api/v1/reserv/checkTime', { method: 'GET' });
    }
    if (!Merchant.isCMS) {
      fetchData();
      setTimeout(() => {
        setUpdate(!update);
      }, 5 * 60 * 1000);
    }
  }, [update]);

  const getPlusButton = (item) => {
    if (!item.isInfiniteAmount && item.totalAmount === 0) {
      return (
        <UiPlus style={{ margin: '0 0 0 0.5rem' }} />
      );
    }

    return (
      <Plus
        style={{ margin: '0 0 0 0.5rem' }}
        onClick={async () => {
          if (!Merchant.isCMS) {
            await fetch('/api/v1/reserv/checkTime', { method: 'GET' });
          }
          const addResponse = ShoppingCartStore.updateAmount(item, 1, Merchant.guid);
          addResponse.then((res) => {
            if (res.isError) {
              item.totalAmount = 0;
            } else {
              setUpdate(!update);
            }
          });
        }}
      />
    );
  };

  const getMinusButton = (item) => {
    if (item.amount <= 1) {
      return (
        <UiMinus style={{ margin: '0 0.5rem 0 0' }} />
      );
    }
    return (
      <Minus
        style={{ margin: '0 0.5rem 0 0' }}
        onClick={async () => {
          if (item.amount - 1 >= 1) {
            if (!Merchant.isCMS) {
              await fetch('/api/v1/reserv/checkTime', { method: 'GET' });
            }
            const removeResponse = ShoppingCartStore.updateAmount(item, -1, Merchant.guid);
            removeResponse.then((res) => {
              if (res.isError) {
                item.totalAmount = 0;
              } else {
                setUpdate(!update);
              }
            });
          }
        }}
      />
    );
  };

  return (
    <div style={{
      display: 'flex', flexDirection: 'row', lineHeight: '1.25',
    }}
    >
      {getMinusButton(item)}
      <Description style={{ userSelect: 'none' }}>
        {item.amount}
        &#160;
        {item.quantity[isOptions.language]}
      </Description>
      {getPlusButton(item)}
    </div>
  );
};

export default ItemCount;
