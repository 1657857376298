import React, { useState, useEffect } from 'react';
import {
  UiButton,
  useOptions,
} from '@compassplus/ui-mobicash';
import { useHistory, useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import { action } from 'mobx';
import i18n from '../../../i18n';

import { getCart } from '../../../Utils/requestUtil';
import { Stiky } from '../OrderPage/style';
import MerchantCard from '../../../components/market/MerchantCard';

import Merchant from '../../../stores/Merchant';
import GlobalStateStore from '../../../stores/GlobalStateStore';
import MarketStore from '../../../stores/MarketStore';
import MarketCurrentGroupCard from './components/MarketCurrentGroupCard';
import Groups from './components/Groups';
import Items from './components/Items';
import MarketSearch from '../../../components/market/MarketSearch';
import Breadcrumb from '../../../components/market/Breadcrumb';

const Market = observer(() => {
  const history = useHistory();
  const isOptions = useOptions();

  const {
    currentGroup, isLoading,
  } = MarketStore;

  const params = useParams();
  const merchantURL = params.merchant;
  const [groupId, setGroupId] = useState(params.id);

  const onScroll = action(() => {
    if (document.documentElement.scrollTop >= document.documentElement.offsetHeight - document.documentElement.clientHeight - 100) {
      MarketStore.displayedItemsCount += 40;
      //MarketStore.loadItems(Merchant.guid, MarketStore.rootGroupId);
    }
  });

  useEffect(() => {
    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, []);

  useEffect(action(() => {
    if (isOptions.screen === 'mobile') {
      GlobalStateStore.sidebarClassName = 'upper';
    } else {
      GlobalStateStore.sidebarClassName = '';
    }
  }), [isOptions.screen]);

  useEffect(() => {
    setGroupId(params.id);
  }, [params]);

  useEffect(() => {
    MarketStore.setMarketData(Merchant.guid, groupId, isOptions.language);
  }, [Merchant.guid, merchantURL, isOptions.language]);

  useEffect(action(() => {
    MarketStore.isLoading = true;
    MarketStore.setCurrentGroup(groupId, true);
  }), [groupId]);

  return (
    <>
      <div style={isOptions.screen === 'mobile'
        ? { padding: '0 0 64px', width: '100%' }
        : {
          padding: '0 0 64px', position: 'relative', width: '100%',
        }}
      >
        {isOptions.screen === 'mobile' ? <MerchantCard url={merchantURL} /> : null}
        <MarketSearch style={{ margin: '0.5rem 0 0' }} />
        {(groupId !== undefined && currentGroup && !isLoading) ? (
          <>
            <Breadcrumb
              style={{ margin: '1rem 0' }}
              links={MarketStore.breadcrumbs}
            />
            <MarketCurrentGroupCard />
          </>
        ) : null}
        <Groups setGroupId={setGroupId} />
        <Items />
      </div>
      {
          isOptions.screen === 'mobile' ? (
            <Stiky>
              <UiButton
                disabled={getCart(Merchant.guid) === null || JSON.parse(getCart(Merchant.guid)).length === 0}
                title={i18n.t('ViewShoppingCart')}
                onClick={() => { history.push(`/${merchantURL}/shoppingCart`); }}
              />
            </Stiky>
          ) : null
        }
    </>
  );
});

export default Market;
