import React, { useEffect, useState } from 'react';
import { UiButton, UiCard, UiDropdown, UiInput, UiSection, useOptions } from '@compassplus/ui-mobicash';
import { Text } from '../../../../components/TemplateStyle';
import i18n from '../../../../i18n';
import { SubContentStiky } from '../../ModifyAttributePage/style';
import AdminMarketStore from '../../../../stores/AdminMarketStore';
import OrdersRecordsStore from '../../../../stores/OrdersRecordsStore';
import { getMerchantId } from '../../../../Utils/requestUtil';
import ActionSheet from '../../../../components/admin/ActionSheet';
import { action } from 'mobx';
import { useHistory } from 'react-router';

const DeliveryType = () => {
  const { modifyingOrder } = OrdersRecordsStore;
  const [isTypeDropdownOpen, setIsTypeDropdownOpen] = useState(false);
  const isOptions = useOptions();
  const history = useHistory();
  const [deliverySettings, setDeliverySettings] = useState([]);
  const [selectedDelivery, setSelectedDelivery] = useState({})
  useEffect(() => {
    fetch(`/api/v1/public/deliveryObj/${getMerchantId()}`, { method: 'GET' })
      .then((response) => response.json())
      .then((response) => {
        setDeliverySettings(response);
        setSelectedDelivery(response.find((el) =>
          el.type === modifyingOrder?.deliveryData?.type?.Id &&
          (el.title.en === modifyingOrder?.deliveryData?.type?.Title || el.title.ru === modifyingOrder?.deliveryData?.type?.Title)
        ))
      });
  }, [AdminMarketStore.merchantGuid])

  const renderDeliveryTypeDropdown = () => {
    if (Object.keys(selectedDelivery).length !== 0) {
      return (
        <UiCard
          style={{ margin: '0.5rem 0 0.25rem', position: 'relative' }}
          shadow
        >
          <UiInput
            description={i18n.t('DeliveryMethod')}
            value={selectedDelivery.title[isOptions.language]}
            rightElement={<UiDropdown/>}
            active={isTypeDropdownOpen}
            onClick={() => {
              setIsTypeDropdownOpen(!isTypeDropdownOpen);
            }}
          />
          <ActionSheet
            open={isTypeDropdownOpen}
            setOpen={() => {
              setIsTypeDropdownOpen(!isTypeDropdownOpen);
            }}
          >
            {deliverySettings?.map((setting, index) => (
              <React.Fragment key={setting.title.en}>
                <UiSection
                  title={setting.title[isOptions.language]}
                  view="link"
                  onClick={action(() => {
                    setSelectedDelivery(setting);
                    setIsTypeDropdownOpen(false);
                  })}
                  bottomDivider={index !== deliverySettings.length - 1}
                />
              </React.Fragment>
            ))}
          </ActionSheet>
        </UiCard>
      )
    }
  }

  return (
    <>
      <Text
        style={{ textTransform: 'uppercase' }}
        typography="note"
        view="readonly"
      >
        {i18n.t('DeliveryMethod')}
      </Text>
      {renderDeliveryTypeDropdown()}
      <SubContentStiky>
        <UiButton
          title={i18n.t('Save')}
          onClick={() => {
            OrdersRecordsStore.modifyDeliveryData({ type: { Title: selectedDelivery.title[isOptions.language], Id: selectedDelivery.type } });
            history.replace(`/modifyOrder/${modifyingOrder._id}`);
          }}
        />
      </SubContentStiky>
    </>
  );
};

export default DeliveryType;
