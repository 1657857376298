import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StyledContainer = styled.div`
  ${ (props) => css`
    padding-top: ${ props.paddingTop };
    padding-right: ${ props.paddingRight };
    padding-bottom: ${ props.paddingBottom };
    padding-left: ${ props.paddingLeft };
  ` };

  ${ (props) => props.overflow === 'true' && css`
    max-height: ${ props.height };
    overflow-y: auto;
  ` }

  ${ (props) => props.relativity && css`
    position: relative;
  ` }
`;

/**
 * Сontainer component
 * @public
 * @version 0.0.90
 * @param {String} [paddingTop=0.5rem]
 * @param {String} [paddingBottom=0.5rem]
 * @param {String} [paddingLeft=0.5rem]
 * @param {String} [paddingRight=0.5rem]
 * @param {Boolean} [overflow=false]
 * @param {String} [height=100%]
 * @param {Boolean} relativity
 * @param {Function|Object} [innerRef]
 * @param {*} children
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiContainer>{ children }</UiContainer>
 */
const UiContainer = ({
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
  overflow,
  height,
  relativity,
  innerRef,
  children,
  ...props
}) => {

  return (
    <StyledContainer
      { ...props }
      overflow={ overflow.toString() }
      paddingTop={ paddingTop }
      paddingRight={ paddingRight }
      paddingBottom={ paddingBottom }
      paddingLeft={ paddingLeft }
      height={ height }
      relativity={ relativity }
      ref={ innerRef }
    >
      { children }
    </StyledContainer>
  );
};

UiContainer.propTypes = {
  /** Определяет отступ сверху */
  paddingTop: PropTypes.string,
  /** Определяет отступ снизу */
  paddingBottom: PropTypes.string,
  /** Определяет отступ слева */
  paddingLeft: PropTypes.string,
  /** Определяет отступ справа */
  paddingRight: PropTypes.string,
  /** Определяет прокрутку контейнера */
  overflow: PropTypes.bool,
  /** Определяет максимальную высоту контейнера (работает в комбинации с overflow={true}) */
  height: PropTypes.string,
  /** Определяет позиционирование относительно абсолютных компонентов */
  relativity: PropTypes.bool,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  /** Определяет пользовательский контент */
  children: PropTypes.any
};

UiContainer.defaultProps = {
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
  paddingLeft: '0.5rem',
  paddingRight: '0.5rem',
  overflow: false,
  height: '100%',
  relativity: false
};

export default UiContainer;