import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../context/ThemeProvider';

import ContainerItem from '../../Functional/ContainerItem';
import RightDivider from '../../Functional/RightDivider';

import styles from './UiSection.module.css';

const StyledSpan = styled.span`
  ${ (props) => {
    if (props.description) {
      return css`
        ${ props.styles.typography.SectionTitle };
      `;
    }

    return css`
      ${ props.styles.typography.SectionValue };
    `;
  } }

  ${ (props) => {
    switch (props.view) {
      case 'link':
        return css`
          color: ${ (props) => props.styles.colors.textLink };
        `;
      case 'default':
      default:
        return css`
          color: ${ (props) => props.styles.colors.textTitle };
        `;
    }
  } }

  ${ (props) => props.active && css`
    color: ${ (props) => props.styles.colors.textLink };
  ` }

  ${ (props) => props.disabled && css`
    color: ${ (props) => props.styles.colors.textReadOnly };
  ` }
`;

const StyledDescription = styled.span`
  ${ (props) => props.styles.typography.SectionSecondString };
  color: ${ (props) => props.styles.colors.textReadOnly };
`;

/**
 * Section component
 * @public
 * @version 0.0.90
 * @param {String} className
 * @param {Boolean} [active=false]
 * @param {Boolean} [disabled=false]
 * @param {'default'|'link'} [view=default]
 * @param {String} [title=Value]
 * @param {String} description
 * @param {JSX.Element} badge
 * @param {JSX.Element} leftElement
 * @param {JSX.Element} rightElement
 * @param {Boolean} [rightDivider=false]
 * @param {Boolean} [bottomDivider=false]
 * @param {Function} onClick
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiSection />
 */
const UiSection = ({
  className,
  active,
  disabled,
  view,
  title,
  description,
  badge,
  leftElement,
  rightElement,
  rightDivider,
  bottomDivider,
  onClick,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <ContainerItem
      { ...props }
      className={ className }
      active={ active }
      disabled={ disabled }
      bottomDivider={ bottomDivider }
      onClick={ onClick }
      innerRef={ innerRef }
    >
      { leftElement &&
        <div className={ styles.leftElement }>
          { leftElement }
        </div>
      }
      <div className={ styles.frame }>
        <div className={ styles.main }>
          { badge &&
            <div className={ styles.badge }>
              { badge }
            </div>
          }
          <StyledSpan
            className={ styles.title }
            description={ description }
            active={ active }
            view={ view }
            disabled={ disabled }
            styles={ isTheme.styles }
          >
            { title }
          </StyledSpan>
        </div>
        { description &&
          <StyledDescription
            className={ styles.description }
            styles={ isTheme.styles }
          >
            { description }
          </StyledDescription>
        }
      </div>
      { rightElement &&
        <div className={ styles.rightElement }>
          { rightDivider && <RightDivider /> }
          { rightElement }
        </div>
      }
    </ContainerItem>
  );
};

UiSection.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Выделяет контейнер как активный элемент */
  active: PropTypes.bool,
  /** Делает элемент неактивным */
  disabled: PropTypes.bool,
  /** Определяет цвет текста элемента */
  view: PropTypes.oneOf(['default', 'link']),
  /** Определеяет текст на элементе */
  title: PropTypes.string,
  /** Определяет текст описания на элементе */
  description: PropTypes.string,
  /** Бейдж в левой части компонента */
  badge: PropTypes.element,
  /** Добавляет левый элемент компонента */
  leftElement: PropTypes.element,
  /** Добавляет правый элемент компонента */
  rightElement: PropTypes.element,
  /** Добавляет правую разделительную черту между текстом и правым элементом */
  rightDivider: PropTypes.bool,
  /** Добавляет нижнюю разделительную черту */
  bottomDivider: PropTypes.bool,
  /** Добавляет обработчик на слушатель по нажатию */
  onClick: PropTypes.func,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

UiSection.defaultProps = {
  active: false,
  disabled: false,
  view: 'default',
  title: 'Value',
  rightDivider: false,
  bottomDivider: false
};

export default UiSection;