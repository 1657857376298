import styled from 'styled-components';

export const ImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${props => props.margin};
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.125rem;
`;

export const MiniImageIcon = styled(Image)`
  cursor: pointer;
  border-radius: 0.25rem;
`;

export const Wrapper = styled.div`
  margin: auto;
  position: relative;
  width: 288px;
  overflow: hidden;
`;

export const ModalWrapper = styled(Wrapper)`
  width: 22.25rem;
`;

export const Slide = styled.div`
  display: flex;
  width: 18rem;
  height: 18rem;
  transition: transform 0.5s ease-in-out;
  transform: ${props => `translateX(${props.xPosition}px)`};
  
 img {
    object-fit: cover;
    width: 18rem;
    height: 18rem;
  }
`;

export const ModalSlide = styled(Slide)`
  width: 22.25rem;
  height: 22.25rem;
  
  img {
    width: 22.25rem;
    height: 22.25rem;
    user-select: none;
  }
  
  @media (max-width: 425px) {
    & {
      width: 18rem;
      height: 18rem;
    }
    & > img {
      width: 18rem;
      height: 18rem;
    }
  }
  
  @media (max-width: 320px) {
    & {
      width: 16rem;
      height: 16rem;
    }
    & > img {
      width: 16rem;
      height: 16rem;
    }
  }
`;

export const Button = styled.img`
  position: absolute;
  top: 50%;
  z-index: 10;
  cursor: pointer;
  font-size: 15px;
  max-width: 40px;
  transform: translateY(-50%);
  left: ${props => props.side === 'prev' && 5}px;
  right: ${props => props.side === 'next' && 5}px;

  @media(max-width: 400px){
    max-width: 30px;
  }
`;
