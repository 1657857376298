import styled from 'styled-components';

import FSubstrate from '../FSubstrate';
import UiText from '../../../Ui/UiText';

const StyledText = styled(UiText)`
  font-size: 26px;
  line-height: 32px;
`;

const FCell = ({
  text,
  ...props
}) => {

  return (
    <FSubstrate { ...props }>
      <StyledText view="title" typography="text" position="center">
        { text }
      </StyledText>
    </FSubstrate>
  );
};

export default FCell;