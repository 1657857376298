import React, { useEffect } from 'react';
import { useOptions } from '@compassplus/ui-mobicash';
import { useHistory } from 'react-router';
import { MContentDiv, SubContentDiv } from '../../../components/TemplateStyle';
import CategoryActivePeriod from './CategoryActivePeriod';
import ModifyGroup from '../ModifyGroupPage/ModifyGroup';
import GlobalStateStore from '../../../stores/GlobalStateStore';
import i18n from '../../../i18n';
import CategoryStore from '../../../stores/CategoryStore';

const CategoryActivePage = () => {
  const isOptions = useOptions();
  const history = useHistory();

  useEffect(() => {
    GlobalStateStore.setHeaderProperties({
      title: i18n.t('ActivePeriod'),
      isBack: true,
    });

    if (!CategoryStore.parentGroup) {
      history.goBack();
    }
  }, []);

  if (isOptions.screen === 'mobile') {
    return (
      <MContentDiv>
        <CategoryActivePeriod />
      </MContentDiv>
    );
  }
  return (
    <>
      <MContentDiv>
        <ModifyGroup groupId={CategoryStore.type} disableSave />
      </MContentDiv>
      <SubContentDiv>
        <CategoryActivePeriod />
      </SubContentDiv>
    </>
  );
};

export default CategoryActivePage;
