import React from 'react';
import { UiCard } from '@compassplus/ui-mobicash';
import LoadingInput from './LoadingInput';
import i18n from '../../i18n';
import { getCurrency } from '../../Utils/requestUtil';

const LoadingModifyItemFields = () => (
  <UiCard shadow>
    <LoadingInput
      title={i18n.t('Category')}
      bottomDivider
    />
    <LoadingInput
      title={i18n.t('ItemName')}
      bottomDivider
    />
    <LoadingInput
      title={i18n.t('Description')}
      bottomDivider
    />
    <LoadingInput
      title={`${i18n.t('Price')}, ${i18n.t(getCurrency())}`}
      bottomDivider
    />
    <LoadingInput
      title={i18n.t('Unit')}
      bottomDivider
    />
    <LoadingInput
      title={i18n.t('TotalAmount')}
      bottomDivider
    />
    <LoadingInput
      title={i18n.t('Availability')}
      bottomDivider
    />
    <LoadingInput
      title={i18n.t('Status')}
    />
  </UiCard>
);

export default LoadingModifyItemFields;
