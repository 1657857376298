import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Иконка истории транзакций
 * @public
 * @version 1.5.0
 * @param {String} [width="3.25rem"]
 * @param {String} [height="3.25rem"]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * import { UiTransactionHistoryIcon, UiPlates, UiPlate } from '@compassplus/ui-mobicash';
 *
 * const App = () => {
 *
 *   return (
 *     <>
 *       <UiTransactionHistoryIcon />
 *       <UiPlates>
 *         <UiPlate view="2x1" image={ <UiTransactionHistoryIcon width="100%" height="100%" /> } />
 *       </UiPlates>
 *     </>
 *   );
 * }; */
const UiTransactionHistoryIcon = ({
  width,
  height,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      innerRef={ innerRef }
      width={ width }
      height={ height }
      viewBox="0 0 52 52"
    >
      <path
        d="M12.5795 40.1443C10.6827 40.1443 9.14502 38.6067 9.14502 36.7099V11.5241C9.14502 9.62728 10.6827 8.08963 12.5795 8.08963H37.7653C39.6621 8.08963 41.1997 9.62728 41.1997 11.5241V40.2245L12.5795 40.1443Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path
        d="M46.9812 35.9771C46.4662 40.741 42.9883 44.6549 38.318 45.7263C33.6476 46.7977 28.8115 44.7911 26.2716 40.728H12.5338C10.3399 40.728 8.56134 38.9495 8.56134 36.7555V20.5565C8.248 20.9603 8.07155 21.4536 8.05762 21.9646V41.0028C8.05762 42.6628 9.91221 44.0022 12.1904 44.0022H26.4089C28.9895 46.6857 32.591 48.143 36.3115 48.009C42.7683 48.009 48.0001 44.1968 48.0001 39.5031C48.0001 38.2551 47.6471 37.0326 46.9812 35.9771Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path
        d="M12.5795 40.1443C10.6827 40.1443 9.14505 38.6067 9.14505 36.7099V11.5241C9.14505 9.62727 10.6827 8.08962 12.5795 8.08962H37.7653C39.6621 8.08962 41.1998 9.62727 41.1998 11.5241V24.8039C41.5964 25.0238 41.9787 25.2685 42.3446 25.5365V11.6042C42.3446 9.07515 40.2944 7.02495 37.7653 7.02495H12.5795C10.0504 7.02495 8.00024 9.07515 8.00024 11.6042V36.79C8.00024 39.3191 10.0504 41.3693 12.5795 41.3693H26.6492C26.3812 41.0034 26.1365 40.6211 25.9165 40.2245L12.5795 40.1443Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M20.5472 31.3752C20.3873 31.3814 20.2609 31.5128 20.261 31.6728C20.261 31.8309 20.3891 31.9591 20.5472 31.9591H24.9089C24.9089 31.7644 25.0005 31.5698 25.0692 31.3752H20.5472Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M33.8613 16.9505H20.5357C20.3776 16.9505 20.2495 17.0787 20.2495 17.2367C20.2495 17.3948 20.3776 17.5229 20.5357 17.5229H33.8613C34.0194 17.5229 34.1475 17.3948 34.1475 17.2367C34.1475 17.0787 34.0194 16.9505 33.8613 16.9505Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M20.5472 24.0825C20.3891 24.0825 20.261 24.2107 20.261 24.3687C20.261 24.5268 20.3891 24.6549 20.5472 24.6549H30.8505C31.3078 24.4358 31.7781 24.2446 32.2586 24.0825H20.5472Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M20.5584 28.5013C20.4004 28.5013 20.2722 28.6295 20.2722 28.7875C20.2722 28.9456 20.4004 29.0737 20.5584 29.0737H26.1336C26.2481 28.8791 26.3626 28.6845 26.4885 28.5013H20.5584Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M27.8053 21.2664H20.5472C20.3891 21.2664 20.261 21.3946 20.261 21.5527C20.2609 21.7127 20.3873 21.8441 20.5472 21.8503H27.8053C27.9652 21.8441 28.0916 21.7127 28.0915 21.5527C28.0915 21.3946 27.9634 21.2664 27.8053 21.2664Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M20.5128 14.6268H27.7709C27.9289 14.6268 28.0571 14.4986 28.0571 14.3406C28.0571 14.1825 27.9289 14.0544 27.7709 14.0544H20.5128C20.3547 14.0544 20.2266 14.1825 20.2266 14.3406C20.2266 14.4986 20.3547 14.6268 20.5128 14.6268Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M15.373 17.6602C14.6358 17.7165 13.9291 17.3551 13.5433 16.7244C13.1574 16.0937 13.1574 15.3 13.5433 14.6693C13.9291 14.0386 14.6358 13.6772 15.373 13.7335C15.5311 13.7335 15.6592 13.8616 15.6592 14.0197C15.6592 14.1778 15.5311 14.3059 15.373 14.3059C14.608 14.3059 13.9878 14.9261 13.9878 15.6911C13.9878 16.4562 14.608 17.0764 15.373 17.0764C16.138 17.0764 16.7582 16.4562 16.7582 15.6911C16.7582 15.5331 16.8864 15.4049 17.0444 15.4049C17.2045 15.4048 17.3359 15.5312 17.3421 15.6911C17.3421 16.7786 16.4605 17.6602 15.373 17.6602Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M14.9948 24.8153C14.2576 24.8717 13.551 24.5103 13.1651 23.8796C12.7793 23.2489 12.7793 22.4551 13.1651 21.8244C13.551 21.1937 14.2576 20.8323 14.9948 20.8886C15.1529 20.8886 15.281 21.0168 15.281 21.1749C15.2812 21.3349 15.1547 21.4663 14.9948 21.4725C14.2298 21.4725 13.6096 22.0927 13.6096 22.8577C13.6096 23.6228 14.2298 24.2429 14.9948 24.2429C15.7573 24.2367 16.3738 23.6202 16.3801 22.8577C16.3801 22.6997 16.5082 22.5715 16.6663 22.5715C16.8243 22.5715 16.9525 22.6997 16.9525 22.8577C16.9462 23.9363 16.0734 24.8091 14.9948 24.8153Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M14.9948 32.1079C14.2576 32.1642 13.551 31.8028 13.1651 31.1721C12.7793 30.5414 12.7793 29.7477 13.1651 29.117C13.551 28.4863 14.2576 28.1249 14.9948 28.1812C15.1341 28.2089 15.2344 28.3311 15.2344 28.4731C15.2344 28.6151 15.1341 28.7373 14.9948 28.7651C14.2298 28.7651 13.6096 29.3852 13.6096 30.1503C13.6096 30.9153 14.2298 31.5355 14.9948 31.5355C15.7573 31.5293 16.3738 30.9127 16.3801 30.1503C16.3801 29.9922 16.5082 29.8641 16.6663 29.8641C16.8243 29.8641 16.9525 29.9922 16.9525 30.1503C16.9462 31.2288 16.0734 32.1017 14.9948 32.1079Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M35.8077 46.0171C42.0228 46.0171 47.0612 40.9788 47.0612 34.7637C47.0612 28.5485 42.0228 23.5102 35.8077 23.5102C29.5926 23.5102 24.5542 28.5485 24.5542 34.7637C24.5542 40.9788 29.5926 46.0171 35.8077 46.0171Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path
        d="M35.8513 44.3221C41.0682 44.3221 45.2974 40.087 45.2974 34.8629C45.2974 29.6387 41.0682 25.4034 35.8513 25.4034C34.8946 25.3977 33.943 25.5415 33.0307 25.83C32.862 25.8787 32.7207 25.9948 32.6402 26.1511C32.5596 26.3074 32.5469 26.49 32.605 26.656C32.7191 26.9984 33.085 27.1875 33.4299 27.0823C34.2151 26.8462 35.0314 26.7294 35.8513 26.7359C40.3299 26.7506 43.9501 30.3957 43.9403 34.8806C43.9305 39.3655 40.2945 42.9946 35.8158 42.9897C31.3371 42.9849 27.709 39.3478 27.709 34.8629C27.7169 34.2898 27.7793 33.7188 27.8953 33.1575C27.9687 32.797 27.7364 32.4451 27.3764 32.3715C27.0163 32.2979 26.6649 32.5305 26.5914 32.8911C26.4536 33.5393 26.3822 34.2 26.3786 34.8629C26.3932 40.0913 30.63 44.3221 35.8513 44.3221Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M15.3614 16.8357C15.9936 16.8357 16.5062 16.3231 16.5062 15.6909C16.5062 15.0586 15.9936 14.5461 15.3614 14.5461C14.7291 14.5461 14.2166 15.0586 14.2166 15.6909C14.2166 16.3231 14.7291 16.8357 15.3614 16.8357Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path
        d="M14.9947 24.0026C15.6269 24.0026 16.1395 23.49 16.1395 22.8577C16.1395 22.2255 15.6269 21.7129 14.9947 21.7129C14.3624 21.7129 13.8499 22.2255 13.8499 22.8577C13.8499 23.49 14.3624 24.0026 14.9947 24.0026Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path
        d="M14.9947 31.2947C15.6269 31.2947 16.1395 30.7821 16.1395 30.1498C16.1395 29.5176 15.6269 29.005 14.9947 29.005C14.3624 29.005 13.8499 29.5176 13.8499 30.1498C13.8499 30.7821 14.3624 31.2947 14.9947 31.2947Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
    </Element>
  );
};

UiTransactionHistoryIcon.propTypes = {
  /** Определяет ширину иконки */
  width: PropTypes.string,
  /** Определяет высоту иконки */
  height: PropTypes.string
};

UiTransactionHistoryIcon.defaultProps = {
  width: '3.25rem',
  height: '3.25rem'
};

export default UiTransactionHistoryIcon;