import PropTypes from 'prop-types';
import cn from 'classnames';
import styled, { css } from 'styled-components';

import { formatAmount } from '@compassplus/js-utils';

import { useTheme } from '../../../../../../context/ThemeProvider';

import styles from './Amount.module.css';

const StyledAmount = styled.span`
  color: ${ (props) => props.color };

  ${ (props) => {
    switch (props.type) {
      case 'amount':
        return css`
          height: 1rem;
          ${ (props) => props.styles.typography.Amount }
        `;
      case 'section':
        return css`
          height: 1rem;
          ${ (props) => props.styles.typography.SectionDescription }
        `;
      case 'remark':
        return css`
          height: 0.625rem;
          ${ (props) => props.styles.typography.Remark }
        `;
      default:
        return css`
          height: 1rem;
          ${ (props) => props.styles.typography.Amount }
        `;
    }
  } }
`;

const Amount = ({
  className,
  type,
  status,
  amount,
  currency
}) => {

  const isTheme = useTheme();

  const handlerColor = (status) => {
    switch (status) {

      // TXPG
      case 'Preparing':
        return isTheme.styles.colors.pendingBillPrimary;
      case 'WaitPushTran':
        return isTheme.styles.colors.pendingBillPrimary;
      case 'Authorized':
        return isTheme.styles.colors.paidBillPrimary;
      case 'PartPaid':
        return isTheme.styles.colors.paidBillPrimary;
      case 'Funded':
        return isTheme.styles.colors.paidBillPrimary;
      case 'FullyPaid':
        return isTheme.styles.colors.paidBillPrimary;
      case 'Expired':
        return isTheme.styles.colors.textDisable;
      case 'Cancelled':
        return isTheme.styles.colors.textReadOnly;
      case 'Rejected':
        return isTheme.styles.colors.notPaidBillSecondary;
      case 'Refused':
        return isTheme.styles.colors.notPaidBillSecondary;
      case 'Closed':
        return isTheme.styles.colors.textDisable;

      // MobiCash
      case 'Active':
        return isTheme.styles.colors.paidBillPrimary;
      case 'Refunded':
        return isTheme.styles.colors.textReadOnly;
      case 'Payed':
        return isTheme.styles.colors.paidBillPrimary;
      case 'PaymentFailed':
        return isTheme.styles.colors.notPaidBillSecondary;
      case 'Deleted':
        return isTheme.styles.colors.textDisable;

      // LoginStatus
      case 'NeedNewPwd':
        return isTheme.styles.colors.notPaidBillSecondary;
      case 'Blocked':
        return isTheme.styles.colors.textReadOnly;

      default:
        return isTheme.styles.colors.textReadOnly;
    }
  }

  return (
    <StyledAmount
      className={ cn(styles.amount, className) }
      type={ type }
      color={ handlerColor(status) }
      styles={ isTheme.styles }
    >
      { formatAmount(amount, currency) }
    </StyledAmount>
  );
};

Amount.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  status: PropTypes.oneOf([
    // TXPG
    'Preparing', 'WaitPushTran', 'Authorized', 'PartPaid', 'Funded', 'FullyPaid', 'Expired', 'Cancelled', 'Rejected', 'Refused', 'Closed',
    // MobiCash
    'Active', 'Refunded', 'Payed', 'PaymentFailed', 'Deleted',
    // LoginStatus
    'NeedNewPwd', 'Blocked'
  ]),
  type: PropTypes.oneOf(['amount', 'section', 'remark']).isRequired,
  currency: PropTypes.shape({
    decimalDelimiter: PropTypes.string,
    thousandsDelimiter: PropTypes.string,
    precision: PropTypes.number,
    signLocation: PropTypes.oneOf(['left', 'right']),
    sign: PropTypes.string
  }),
  amount: PropTypes.number,
};

Amount.defaultProps = {
  type: 'amount',
  status: 'Active',
  currency: {
    sign: '₽',
    signLocation: 'right',
    decimalDelimiter: ',',
    thousandsDelimiter: ' ',
    precision: 2,
  },
  amount: 9999999.99
};

export default Amount;