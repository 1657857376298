import { useMemo, useState } from 'react';

import {
  clearMobile,
  findCountry,
  formatMobile,
  validateMobile,
  isValidMobile,
  createMobile
} from '../utils/mobileUtils';

/**
 * Кастомный хук для работы с номером телефона
 * @param {String|Function} [initialValue=]
 * @return {[{phoneCountryCode: *, valueFormat: string, valueValid: boolean, alpha2, value: string},{setMobile: function(String): void, validateMobile: function(String): string, formatMobile: function(String): string, findCountry: function(String): (*|null), clearMobile: function(String): string, isValidMobile: function(String): boolean}]}
 */
const useMobile = (initialValue = '') => {

  const [state, setState] = useState(() => {
    if (typeof initialValue === 'function') {
      initialValue = initialValue();
    }

    return createMobile(initialValue);
  });

  const action = useMemo(() => {
    /**
     * Устанавливает номер телефона
     * @public
     * @version 1.4.0
     * @param {String} [mobile=]
     * @constructor
     * @example
     * import { useEffect } from 'react';
     *
     * import { useMobile } from '@compassplus/ui-mobicash';
     *
     * const App = () => {
     *   const [mobile, { setMobile }] = useMobile();
     *
     *   useEffect(() => {
     *     console.log(mobile);
     *   }, [mobile]);
     *
     *   useEffect(() => {
     *     setMobile('+79995831360');
     *   }, [setMobile]);
     * }; */
    function setMobile(mobile = '') {
      setState((state) => {
        const nextState = createMobile(mobile);

        if (nextState.value !== state.value) {
          return nextState;
        }

        return state;
      });
    }

    return { setMobile, createMobile, formatMobile, clearMobile, validateMobile, isValidMobile, findCountry };
  }, []);

  return [state, action];
};

export default useMobile;