import {makeAutoObservable, runInAction} from 'mobx';
import { getCart, setCart } from '../Utils/requestUtil';
import ItemStore from "./ItemStore";
import Merchant from "./Merchant";
import itemStore from "./ItemStore";

const checkItemAlreadyInCart = (cart, item) => {
  if (cart && cart.length !== 0) {
    const itemInCartArray = cart.filter((element) => element.itemId === item.id);
    const index = cart.findIndex((element) => element.itemId === item.id);
    if (itemInCartArray.length !== 0) {
      if (item.attributes.length === 0) {
        return index;
      }
      for (let itemInCartIndex = 0; itemInCartIndex < itemInCartArray.length; itemInCartIndex++) {
        let isSameAttributes = true;

        if  (itemInCartArray[itemInCartIndex].attributes.length !== item.attributes.length) {
          isSameAttributes = false;
          break;
        }

        for (let attributeIndex = 0; attributeIndex < itemInCartArray[itemInCartIndex].attributes.length; attributeIndex++) {
          const cartItemAttribute = itemInCartArray[itemInCartIndex].attributes[attributeIndex];
          const itemAttribute = item.attributes[attributeIndex]
          if (cartItemAttribute.title.en !== itemAttribute.title.en) {
            isSameAttributes = false;
            break;
          }

          let flag;
          switch (cartItemAttribute.type) {
            case 'color':
              flag = cartItemAttribute.complexValue.every((cValue) => itemAttribute.complexValue.some((color) => color.code === cValue.code));
              if (!flag) isSameAttributes = false;
              break;
            case 'radio':
              flag = cartItemAttribute.complexValue.every((cValue) => itemAttribute.complexValue.some((radio) => radio.title.en === cValue.title.en));
              if (!flag) isSameAttributes = false;
              break;
            case 'checkbox':
              const selectedCheckboxes = itemAttribute.complexValue.filter((el) => el.value);
              flag = selectedCheckboxes.every((checkbox) => cartItemAttribute.complexValue.some((cValue) => checkbox.title.en === cValue.title.en))
              if (!flag) isSameAttributes = false;
              break;
            case 'textField':
              if (cartItemAttribute.value !== itemAttribute.value) {
                isSameAttributes = false;
              }
              break;
            case 'list':
              flag = cartItemAttribute.value.every((cValue) => itemAttribute.value.some((listValue) => listValue === cValue))
              if (!flag) isSameAttributes = false;
              break;
          }
        }

        if (isSameAttributes) {
          return itemInCartIndex;
        }
      }
      return -1;
    }
  }
  return -1;
};

class ShoppingCartStore {
  constructor() {
    this.isLoading = false;
    this.cart = [];
    this.totalCost = '0.00';
    this.totalCount = 0;
    makeAutoObservable(this);
  }

  setCart(cart) {
    this.cart = cart;
  }

  setTotalCost(totalCost) {
    this.totalCost = totalCost;
  }

  setTotalCount(totalCount) {
    this.totalCount = totalCount;
  }

  async setCartByGuid(guid) {
    if (guid !== '' && getCart(guid) !== null) {
      this.isLoading = true;
      return fetch('/api/v1/public/getCartItems', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          merchantGuid: Merchant.guid,
          cart: JSON.parse(getCart(guid)),
        }),
      }).then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        if (response.status === 404) {
          localStorage.removeItem(`${Merchant.guid}-cart`);
        }
      })
        .then((response) => {
          if (response !== undefined) {
            runInAction(() => {
              this.setCart(response);
              let total = 0;
              let count = 0;
              for (const item of response) {
                count += item.amount;
                total += item.price * item.amount;
              }
              this.setTotalCost(total.toFixed(2));
              this.setTotalCount(count);
              this.isLoading = false;
            })

            return { isError: false };
          }
          return { isError: true };
        });
    }
    return { isError: true };
  }

  addItemToCart() {
    const { guid } = Merchant;
    const packedAttributes = ItemStore.getPackedOrderAttributes();

    const packedItem = {
      id: ItemStore.article,
      attributes: packedAttributes,
    }

    const cart = JSON.parse(getCart(guid));
    const itemIndex = checkItemAlreadyInCart(JSON.parse(getCart(guid)), packedItem);

    if (Merchant.isCMS) {

      if (Merchant.cmsTitle === 'Webasyst') {
        if (packedItem.attributes.length > 0) {
          packedItem.sku_id = packedItem.attributes[0].complexValue[0].sku_id;
        } else {
          packedItem.sku_id = ItemStore.sku_id;
        }

        packedItem.price = Number(ItemStore.price);
        if (itemIndex !== -1) {
          cart[itemIndex].amount += 1;
          setCart(guid, JSON.stringify(cart));
          this.setCartByGuid(guid);
          return {isError: false};
        } else {
          const cartItem = {
            id: packedItem.sku_id,
            itemId: packedItem.id,
            amount: 1,
            attributes: packedItem.attributes,
            price: packedItem.price,
            description: '',
          };
          if (getCart(guid) !== null) {
            setCart(guid, JSON.stringify([...JSON.parse(getCart(guid)), cartItem]));
            this.setCartByGuid(guid);
            return {isError: false};
          }
          setCart(guid, JSON.stringify([cartItem ]));
          this.setCartByGuid(guid);
          return {isError: false};
        }
      }
    } else {
      if (itemIndex !== -1) {
        return fetch('/api/v1/reserv/updateAmount', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: cart[itemIndex].id,
            itemId: packedItem.id,
            amount: cart[itemIndex].amount,
            attributes: packedItem.attributes,
            addAmount: 1,
          }),
        }).then((res) => {
          if (res.status === 200) {
            return res.json();
          }
        })
          .then((res) => {
            if (res !== undefined && res.nModified === undefined && res.modifiedCount === undefined) {
              cart[itemIndex] = res;
              setCart(guid, JSON.stringify(cart));
              this.setCartByGuid(guid);
              return {isError: false};
            }

            if (res !== undefined && (res.nModified !== undefined || res.modifiedCount !== undefined)) {
              cart[itemIndex].amount += 1;
              setCart(guid, JSON.stringify(cart));
              this.setCartByGuid(guid);
              return {isError: false};
            }

            return {isError: true};
          });
      }
      return fetch('/api/v1/reserv/addItem', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: packedItem.id,
          amount: 1,
          attributes: packedItem.attributes,
        }),
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
        .then((response) => {
          if (response !== undefined) {
            const cartItem = {
              id: response._id,
              itemId: response.itemId,
              amount: response.amount,
              attributes: response.attributes,
              description: response.description,
            };
            if (getCart(guid) !== null) {
              setCart(guid, JSON.stringify([...JSON.parse(getCart(guid)), cartItem]));
              this.setCartByGuid(guid);
              return {isError: false};
            }
            setCart(guid, JSON.stringify([cartItem]));
            this.setCartByGuid(guid);
            return {isError: false};
          }
          return {isError: true};
        });
    }
  }

  async updateAmount(item, amount, guid) {
    if (Merchant.isCMS) {
      if (item.isInfiniteAmount || item.amount + amount <= item.totalAmount) {
        const cart = JSON.parse(getCart(guid));
        const index = cart.findIndex((el) => (el.id === item.reservId || el._id === item.reservId));
        cart[index].amount += amount;
        setCart(guid, JSON.stringify(cart));
        this.setCartByGuid(guid);
        return { isError: 'false' };
      }
      return { isError: true, message: 'ItemIsNotAvailable' };
    }

    return fetch('/api/v1/reserv/updateAmount', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: item.reservId,
        itemId: item.id,
        amount: item.amount,
        description: item.description,
        attributes: item.attributes,
        addAmount: amount,
      }),
    }).then((res) => {
      if (res.status === 200) {
        return res.json();
      }
    })
      .then((res) => {
        if (res !== undefined && (res.nModified === undefined && res.modifiedCount === undefined)) {
          const cart = JSON.parse(getCart(guid));
          const index = cart.findIndex((el) => (el.id === item.reservId || el._id === item.reservId));
          cart[index] = res;
          setCart(guid, JSON.stringify(cart));
          this.setCartByGuid(guid);
          return { isError: 'false' };
        }

        if (res !== undefined && (res.nModified !== undefined || res.modifiedCount !== undefined)) {
          const cart = JSON.parse(getCart(guid));
          const index = cart.findIndex((el) => (el.id === item.reservId || el._id === item.reservId));
          cart[index].amount += amount;
          setCart(guid, JSON.stringify(cart));
          this.setCartByGuid(guid);
          return { isError: 'false' };
        }
        return { isError: true, message: 'ItemIsNotAvailable' };
      });
  }

  async deleteItem(deleteIndex, guid) {
    if (Merchant.isCMS) {
      const tmpCart = JSON.parse(getCart(guid));
      tmpCart.splice(deleteIndex, 1);
      setCart(guid, JSON.stringify(tmpCart));
      this.setCartByGuid(guid);
      return { isError: false };
    } else {
      if (this.cart.length !== 0) {
        fetch(`/api/v1/reserv/${this.cart[deleteIndex].reservId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: this.cart[deleteIndex].reservId,
          }),
        });

        const tmpCart = JSON.parse(getCart(guid));
        tmpCart.splice(deleteIndex, 1);
        setCart(guid, JSON.stringify(tmpCart));
        this.setCartByGuid(guid);
        return { isError: false };
      }
    }
    return { isError: true };
  }

  async checkReservs(guid) {
    try {
      let counter = 0;
      for (let i = 0; i < this.cart.length; i++) {
        if (this.cart[i].amount !== 0 && this.cart[i].availability) {
          if (Merchant.isCMS) {
            counter++;
          } else {
            const itemResponse = await fetch('/api/v1/reserv/updateAmount', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                id: this.cart[i].reservId,
                itemId: this.cart[i].id,
                amount: this.cart[i].amount,
                description: this.cart[i].description,
                attributes: this.cart[i].attributes,
                addAmount: 0,
              }),
            }).then((res) => {
              if (res.status === 200) {
                return res.json();
              }
            })
              .then((res) => {
                if (res !== undefined && (res.nModified === undefined && res.modifiedCount === undefined)) {
                  const cart = JSON.parse(getCart(guid));
                  const index = cart.findIndex((el) => (el.id === this.cart[i].reservId || el._id === this.cart[i].reservId));
                  cart[index] = res;
                  setCart(guid, JSON.stringify(cart));
                  this.setCartByGuid(guid);
                }

                if (res !== undefined && (res.nModified !== undefined || res.modifiedCount !== undefined)) {
                  const cart = JSON.parse(getCart(guid));
                  setCart(guid, JSON.stringify(cart));
                  this.setCartByGuid(guid);
                }
                return { status: 200 };
              });

            if (itemResponse.status === 200) {
              counter++;
            }
          }

        }
      }
      if (counter !== 0) {
        return { isError: false, checkPassed: counter };
      }
      return { isError: true, checkPassed: 0 };
    } catch (e) {
      return { isError: true, message: e.message };
    }
  }

  checkItemInCartById(id) {
    const index = this.cart.findIndex((el) => (el.id === id));
    return index !== -1;
  }

  getOrderDescription = (packedAttributes, language) => {
    try {
      return packedAttributes.map((attribute) => {
        switch (attribute.type) {
          case 'radio':
          case 'color':
          case 'checkbox':
            return `${attribute.title[language]}: ${attribute.complexValue.map((cValue) => cValue.title[language]).join(', ')}`;
          case 'list':
            return `${attribute.title[language]}: ${attribute.value.join(', ')}`;
          case 'textField':
            if (attribute.value !== '') {
              return `${attribute.title[language]}: ${attribute.value}`;
            }
          default:
            return '';
        }
      }).filter((el) => el !== '').join('\n');
    } catch (e) {
      localStorage.clear();
    }
  }
}

export default new ShoppingCartStore();
