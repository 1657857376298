import React, { useState, useEffect } from 'react';
import { UiSidebar, useOptions } from '@compassplus/ui-mobicash';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react-lite';
import { action } from 'mobx';
import i18n from '../../../i18n';
import {
  request, getMerchantId, getTitle, getPhoto, getLogoColor, getLogoSymbol, getAuth,
} from '../../../Utils/requestUtil';
import GlobalStateStore from '../../../stores/GlobalStateStore';
import { MerchantLogo } from '../../../pages/market/MarketPage/style';
import ModalStore from "../../../stores/ModalStore";
import ItemStore from "../../../stores/ItemStore";
import CategoryStore from "../../../stores/CategoryStore";
import AdminMarketStore from "../../../stores/AdminMarketStore";
import {CustSidebar} from "./SideMenuStyle";

const Sidebar = observer(() => {
  const history = useHistory();
  const isOptions = useOptions();
  const [groups, setGroups] = useState([]);
  const [rootGroup, setRootGroup] = useState({});
  const [open, setOpen] = useState(false);

  const { isCMS } = AdminMarketStore;

  i18n.changeLanguage(isOptions.language);

  useEffect(() => {
    if (getAuth()) {
      action(() => {
        AdminMarketStore.isLoading = true;
      });
      request(`/api/v1/settings/getActiveCMS/${getMerchantId()}`, 'GET')
        .then(action((res) => {
          if (res) {
            if (AdminMarketStore.allGroups.length !== 0 || AdminMarketStore.allItems.length !== 0) {
              AdminMarketStore.isLoading = false;
            }
            AdminMarketStore.setCMS(res.cms);
          }
        }));
    }
  }, [])

  useEffect(() => {
    setGroups(AdminMarketStore.allGroups);
  }, [AdminMarketStore.allGroups])

  useEffect(() => {
    setOpen(GlobalStateStore.isSidebarOpen && !GlobalStateStore.isLoading);
  }, [GlobalStateStore.isSidebarOpen, GlobalStateStore.isLoading]);

  useEffect(() => {
    // без этого почему-то не работает на мобилке
    GlobalStateStore.isSidebarOpen = open;
  }, [open]);

  useEffect(() => {
    if (sessionStorage.length > 0) {
      // request('/api/v1/group/merchant', 'GET').then(
      //   (responseGroups) => setGroups(responseGroups),
      //   (error) => console.log(error),
      // );
      request('/api/v1/group/root', 'GET').then(
        (responseGroup) => setRootGroup(responseGroup),
        (error) => console.log(error),
      );
    }
  }, [sessionStorage, AdminMarketStore.isCMS, AdminMarketStore.merchantGuid]);

  const getSidebarFooter = () => (
    <div>
      Powered by MobiCash.
      <br />
      <span style={{ color: '#037AFF' }}>Term of Service. | Privacy Policy.</span>
      <br />
      Copyright © 2023 Compass Plus Ltd.. All rights reserved.
    </div>
  );

  const getGroups = (groups) => {
    if (groups) {
      return groups.map((group) => {
        if (group) {
          if (!group.subGroups || group.subGroups?.length === 0) {
            return {
              title: group.title[isOptions.language] || group.title,
              link: '/',
              action: () => {
                history.push(`/main/${group._id || group.type}`);
              },
            };
          }
          return {
            title: group.title[isOptions.language] || group.title,
            link: '/',
            action: () => {
              history.push(`/main/${group._id || group.type}`);
            },
            subactive: isOptions.screen === 'desktop',
            sublist: getGroups(group.subGroups),
          };
        }
      });
    }
    return [];
  };

  if (GlobalStateStore.isAuth) {
    const menuList = [
      {
        title: i18n.t('AddCategory'),
        link: '/uploadGroup',
        icon: 'legacy-plus',
        action: () => {
          CategoryStore.clear();
          history.push('/uploadGroup', { rootGroup: rootGroup[0], groupId: rootGroup[0]._id });
          action(() => {
            CategoryStore.parentGroup = { key: rootGroup[0]._id, value: rootGroup[0].title };
          });
        },
      },
      {
        title: i18n.t('AddItem'),
        link: '/uploadItem',
        icon: 'legacy-marketplace',
        action: () => {
          ItemStore.clearItem();
          history.push('/uploadItem', { rootGroup: rootGroup[0], groupId: rootGroup[0]._id });
          action(() => {
            ItemStore.groupGuid = rootGroup[0]._id;
          });
        },
      },
      {
        title: i18n.t('Catalog'),
        link: '/',
        icon: 'legacy-reports',
        action: () => {
          history.push('/main');
        },
        subactive: isOptions.screen === 'desktop',
        sublist: getGroups(groups),
      },
      {
        title: i18n.t('Administration'),
        link: '/administration',
        icon: 'legacy-preferences',
        action: () => {
          history.push('/administration');
        },
      },
      {
        title: i18n.t('Orders'),
        link: '/orders',
        icon: 'cart',
        action: () => {
          history.push('/orders');
        },
      },
      {
        title: i18n.t('Settings'),
        link: '/setting',
        icon: 'settings',
        action: () => {
          history.push('/settings');
        },
      },
      {
        title: i18n.t('Logout'),
        icon: 'log-out',
        action: () => {
          if (getAuth()) {
            ModalStore.openLogoutModal();
          }
        },
      }
    ]

    if (isCMS) {
      menuList.splice(0,2);
    }

    return (
      <CustSidebar
        user={{
          title: getTitle(isOptions.language),
          description: getMerchantId(),
          icon: (
            <>
              {
                getPhoto() !== '' ? (
                  <img style={{ width: '48px', height: '48px' }} src={getPhoto()} />
                ) : (
                  <MerchantLogo
                    style={{ background: getLogoColor() }}
                  >
                    {getLogoSymbol()}
                  </MerchantLogo>
                )
              }
            </>
          ),
        }}
        menuList={menuList}
        open={open}
        setOpen={setOpen}
        footer={getSidebarFooter()}
      />
    );
  }
  return null;
});

export default Sidebar;
