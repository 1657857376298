import React from 'react';
import { action } from 'mobx';
import { UiCard, useOptions } from '@compassplus/ui-mobicash';
import { useHistory } from 'react-router';
import { DeleteSection } from '../../../../components/TemplateStyle';
import i18n from '../../../../i18n';
import { request } from '../../../../Utils/requestUtil';
import { observer } from 'mobx-react-lite';

import ModalStore from '../../../../stores/ModalStore';
import DeliveryStore from '../../../../stores/DeliveryStore';
import DeliveryForms from '../../../../stores/DeliveryForms';

const DeleteDeliveryButton = observer(() => {
  const history = useHistory();
  const isOptions = useOptions();

  const onDelete = () => {
    if (DeliveryStore.form._id !== undefined) {
      request(`/api/v1/delivery/${DeliveryStore.form._id}`, 'DELETE').then(() => {
        DeliveryForms.getFormFromDB();
      });
    }
    history.push('/administration');
  };

  return (
    <UiCard shadow>
      <DeleteSection
        title={i18n.t('DeleteIssuingMethod')}
        onClick={action(() => {
          ModalStore.isOpen = true;
          ModalStore.text = `${i18n.t('DeleteQuestion')} ${DeliveryStore.form.title[isOptions.language]}?`;
          ModalStore.buttons = [
            {
              text: i18n.t('Submit'),
              mode: 'normal',
              action: () => {
                onDelete();
                ModalStore.setDefaultModal();
              },
            },
            {
              text: i18n.t('Cancel'),
              mode: 'normal',
              action: () => { ModalStore.setDefaultModal(); },
            },
          ];
        })}
      />
    </UiCard>
  );
});

export default DeleteDeliveryButton;
