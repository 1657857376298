import { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../context/ThemeProvider';

import UiCard from '../UiCard';

import { useForkRef } from '../../../hooks';

import { isFunction } from '../../../utils/typeUtils';

import styles from './UiDropdownSheet.module.css';

const StyledCard = styled(UiCard)`
  background-color: ${ (props) => props.styles.colors.keyboardBackgroundColor };

  ${ (props) => props.isOpened && css`
    display: block;
  ` }
`;

/**
 * Выпадающий список (для десктопа)
 * @public
 * @version 1.9.0
 * @param {String} [className]
 * @param {Boolean} [isOpened=false]
 * @param {Function} [onClose]
 * @param {Function|Object} [innerRef]
 * @param {*} [children]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiDropdownSheet>
 *   { children }
 * </UiDropdownSheet>
 */
const UiDropdownSheet = ({
  className,
  isOpened,
  onClose,
  innerRef,
  children,
  ...props
}) => {

  const isTheme = useTheme();

  const containerRef = useRef(null);
  const refs = useForkRef([containerRef, innerRef]);

  const clickHandler = useCallback((event) => {
    const { target } = event;
    const container = containerRef.current;

    if (container && !container.contains(target) && isFunction(onClose)) {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    if (isOpened) {
      const timeout = setTimeout(() => {
        document.addEventListener('click', clickHandler, false);
      }, 0);

      return () => {
        clearTimeout(timeout);
        document.removeEventListener('click', clickHandler, false);
      };
    }
  }, [isOpened, clickHandler]);

  return (
    <StyledCard
      {...props}
      className={cn(styles.container, className)}
      isOpened={isOpened}
      innerRef={refs}
      styles={isTheme.styles}
    >
      {children}
    </StyledCard>
  );
};

UiDropdownSheet.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Определяет видимость компонента */
  isOpened: PropTypes.bool,
  /** Функция обработчик закрытия */
  onClose: PropTypes.func,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  /** Контент выпадающего списка */
  children: PropTypes.any
};

UiDropdownSheet.defaultProps = {
  isOpened: false
};

export default UiDropdownSheet;