import { useCallback, useEffect, useRef } from 'react';
import cn from 'classnames';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../../../../../context/ThemeProvider';

import Input from '../../../../../../Functional/Input';
import { StyledBlockedInput, StyledCardNumbers, StyledInputBackground } from '../Styled';

import { useForkRef } from '../../../../../../../hooks';

import { calculateFontSize, rem } from '../../../../../../../utils/htmlUtils';
import { isFunction } from '../../../../../../../utils/typeUtils';

import styles from './Pan.module.css';

const StyledPan = styled(Input)`
  ${ (props) => {
    if (props.disabled) {
      return css`
        ${ StyledCardNumbers };
        ${ StyledBlockedInput };
        padding: 0.125rem 0;
        text-align: center;
      `;
    } else {
      return css`
        ${ StyledInputBackground };
        padding: 0.125rem 0.25rem;
      `;
    }
  } }
`;

const Pan = ({
  className,
  disabled,
  onInput,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  const maxFontSize = useRef(null);
  const panRef = useRef(null);
  const refs = useForkRef([innerRef, panRef]);

  const clearFontSize = (element) => {
    element.style.fontSize = null;
    maxFontSize.current = calculateFontSize(element);
  }

  const decreaseFontSize = useCallback((element) => {
    if (element) {
      const { clientWidth, scrollWidth } = element;
      const fontSize = calculateFontSize(element);

      if (clientWidth < scrollWidth && fontSize > 8) {
        element.style.fontSize = rem(fontSize - 1);
        decreaseFontSize(element);
      }
    }
  }, []);

  const increaseFontSizeScale = (element) => {
    if (element) {
      const { clientWidth, scrollWidth } = element;
      const fontSize = calculateFontSize(element);

      if (clientWidth === scrollWidth && (maxFontSize.current !== null && fontSize < maxFontSize.current)) {
        element.style.fontSize = rem(fontSize + 1);
        increaseFontSizeScale(element);
      }
    }
  };

  useEffect(() => {
    if (document.readyState === 'interactive') {
      document.onreadystatechange = () => {
        if (document.readyState === "complete") {
          decreaseFontSize(panRef.current);
        }
      };
    } else {
      requestAnimationFrame(() => {
        if (panRef.current) {
          clearFontSize(panRef.current);
          decreaseFontSize(panRef.current);
        }
      });
    }
  }, [decreaseFontSize, disabled]);

  const inputHandler = (event) => {
    increaseFontSizeScale(panRef.current);
    decreaseFontSize(panRef.current);

    if (isFunction(onInput)) {
      onInput(event);
    }
  };

  return (
    <StyledPan
      inputMode="numeric"
      { ...props }
      className={ cn(styles.pan, className) }
      type="text"
      disabled={ disabled }
      onInput={ inputHandler }
      innerRef={ refs }
      styles={ isTheme.styles }
    />
  );
};

export default Pan;