import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Element component
 * @public
 * @version 0.0.90
 * @param {Function} onClick
 * @param {Boolean} [disabled=false]
 * @param {Boolean} [propagation=true]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiQuestions />
 */
const UiQuestions = ({
  onClick,
  disabled,
  propagation,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      color={ isTheme.styles.colors.textLink }
      disabled={ disabled }
      propagation={ propagation }
      onClick={ onClick }
      innerRef={ innerRef }
      width="1rem"
      height="1rem"
      viewBox="0 0 16 16"
    >
      <path
        d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16ZM5.39258 5.75391C5.45703 4.48828 6.30078 3.48047 7.94727 3.48047C9.42383 3.48047 10.4316 4.39453 10.4316 5.60156C10.4316 6.52148 9.95703 7.17188 9.20703 7.62305C8.46289 8.0625 8.25195 8.39062 8.25195 9.00586V9.38672H7.22656V8.84766C7.2207 8.07422 7.5957 7.54688 8.39844 7.06641C9.07812 6.65039 9.3418 6.28125 9.3418 5.6543C9.3418 4.92773 8.7793 4.39453 7.90625 4.39453C7.02148 4.39453 6.45898 4.91602 6.39453 5.75391H5.39258ZM7.74219 12.0586C7.36719 12.0586 7.07422 11.7656 7.07422 11.3906C7.07422 11.0098 7.36719 10.7227 7.74219 10.7227C8.12305 10.7227 8.41016 11.0098 8.41016 11.3906C8.41016 11.7656 8.12305 12.0586 7.74219 12.0586Z" />
    </Element>
  );
};

UiQuestions.propTypes = {
  /** Обработчик события при клике */
  onClick: PropTypes.func,
  /** Компонент неактивен */
  disabled: PropTypes.bool,
  /** Разрешить/запретить всплытие обработчика onClick */
  propagation: PropTypes.bool,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

UiQuestions.defaultProps = {
  disabled: false,
  propagation: true
};

export default UiQuestions;