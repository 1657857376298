import {makeAutoObservable, runInAction} from 'mobx';
import Merchant from "./Merchant";
import i18n from "../i18n";

class MarketStore {
  isCategory = false;
  isLoading = false;
  merchantGroups = [];
  goodsWithoutCategory = [];
  currentGroup = null;
  currentGroups = [];
  currentItems = [];
  prevFetchMerchantGuid = '';
  prevLanguage = ''
  displayedItemsCount = 40;
  allItems = [];
  allGroups = [];

  // Страница товара
  selectedImageIndex = 0;

  breadcrumbs = [];

  constructor() {
    makeAutoObservable(this);
  }

  clear() {
    this.isCategory = false;
    this.isLoading = false;
    this.merchantGroups = [];
    this.goodsWithoutCategory = [];
    this.currentGroup = null;
    this.currentGroups = [];
    this.currentItems = [];
    this.prevFetchMerchantGuid = '';
    this.prevLanguage = ''
    this.displayedItemsCount = 40;
    this.allItems = [];
    this.allGroups = [];
    this.rootGroupId = '';
    this.isFetchingItems = false;
    this.selectedImageIndex = 0;

    this.breadcrumbs = [];
  }

  setMarketData(merchantGuid, groupId, language, item) {
    if (merchantGuid !== '') {
      if ((this.prevFetchMerchantGuid !== merchantGuid && (this.merchantGroups.length === 0 && this.goodsWithoutCategory.length === 0)) || (this.prevLanguage !== language)) {
        this.isLoading = true;
        fetch(`/api/v1/public/market/${merchantGuid}`, {
          method: 'GET',
          headers: {
            'language': language
          }
        })
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            }
          })
          .then((response) => {
            if (response) {
              runInAction(() => {
                if (merchantGuid === Merchant.guid) {
                  this.merchantGroups = response.goods;
                  this.rootGroupId = response.rootGroupId;
                  this.goodsWithoutCategory = response.goodsWithoutCategory;
                  this.isCategory = false;
                  this.allItems = response.goodsWithoutCategory;
                  this.allGroups = response.goods;
                  this.prevFetchMerchantGuid = merchantGuid;
                  this.prevLanguage = language;
                  this.setCurrentGroup(groupId);
                  this.setBreadcrumbsLinks(groupId, item);
                }
                this.isLoading = false;
              })
            }
          })
      } else {
        this.setCurrentGroup(groupId);
        this.setBreadcrumbsLinks(groupId, item);
      }
    }
  }

  checkGroups(groups, groupId, isAddBreadcrumbs) {
    for (const group of groups) {
      if (group.type === groupId) {
        if (isAddBreadcrumbs) {
          this.breadcrumbs.push({
            title: group.title,
            href: `/${Merchant.marketLink}/${group.type}`
          });
        }
        return group
      }
    }
    for (const group of groups) {
      if (group.subGroups) {
        if (isAddBreadcrumbs) {
          this.breadcrumbs.push({
            title: group.title,
            href: `/${Merchant.marketLink}/${group.type}`
          })
        }
        let subgroupId = this.checkGroups(group.subGroups, groupId, isAddBreadcrumbs)
        if (subgroupId) {
          return subgroupId;
        }
        if (isAddBreadcrumbs) {
          this.breadcrumbs.pop();
        }
      }
    }
    return null;
  }

  setCurrentGroup(groupId, isAddBreadcrumbs) {
    this.isLoading = true;
    this.currentGroups = [];
    this.currentGroup = [];
    this.currentItems = [];
    if (isAddBreadcrumbs) {
      this.breadcrumbs = [{
        title: Merchant.localName,
        href: `/${Merchant.marketLink}`
      }];
    }

    if (groupId) {
      this.currentGroup = this.checkGroups(this.merchantGroups, groupId, isAddBreadcrumbs);
      if (this.currentGroup) {
        this.currentGroups = this.currentGroup.subGroups || [];
        this.allGroups = this.currentGroups.subGroups || [];
        this.currentItems = this.currentGroup.items || [];
        this.allItems = this.currentGroup.items || [];
        this.isLoading = false;
      }
      this.isLoading = false;
    } else {
      this.currentGroup = null;
      this.currentGroups = this.merchantGroups;
      this.allGroups = this.merchantGroups;
      this.currentItems = this.goodsWithoutCategory;
      this.allItems = this.goodsWithoutCategory;
      this.isLoading = false;
    }
  }

  setBreadcrumbsLinks(groupId, item) {
    this.breadcrumbs = [{
      title: Merchant.localName,
      href: `/${Merchant.marketLink}`
    }]

    if (groupId) {
      this.currentGroup = this.checkGroups(this.merchantGroups, groupId, true);
    }

    if (item) {
      this.breadcrumbs.push({
        title: item.title[i18n.language],
        href: `/${Merchant.marketLink}/order/${item.article}`
      })
    }
  }
}

export default new MarketStore();