const fonts = {
  input: {
    'font-family': 'Roboto, sans-serif',
    'font-style': 'normal',
    'font-weight': 'normal',
    'font-size': '0.75rem',
    'line-height': '1.125rem',
  },
  regular: {
    'font-family': 'Roboto, sans-serif',
    'font-style': 'normal',
    'font-weight': 'normal',
    'font-size': '1.125rem',
    'line-height': '1.313rem',

  },
  semibold: {
    'font-family': 'Roboto, sans-serif',
    'font-style': 'normal',
    'font-weight': '600',
    'font-size': '1.125rem',
    'line-height': '1.313rem',
  },
  bold: {
    'font-family': 'Roboto, sans-serif',
    'font-style': 'normal',
    'font-weight': 'bold',
    'font-size': '1.125rem',
    'line-height': '1.313rem',
  },
  cardNumbers: {
    'font-family': 'CardNumbers, sans-serif',
    'font-style': 'normal',
    'font-weight': 'normal',
    'font-size': '1.5rem',
    'line-height': '1.375rem',
  }
};

export default fonts;