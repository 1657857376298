import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../../context/ThemeProvider';

import { isFunction } from '../../../../utils/typeUtils';

import styles from './UiTabItem.module.css';

const StyledLi = styled.li`
  ${ (props) => css`
    ${ props.styles.typography.TabsNotActive }
    color: ${ props.styles.colors.applicationPrimaryColor };
    border-bottom: 0.063rem solid ${ props.styles.colors.applicationPrimaryColor };
    opacity: 0.4;
  ` }
  
  ${ (props) => props.active && css`
    ${ (props) => props.styles.typography.TabsActive }
    border-bottom: 0.125rem solid ${ (props) => props.styles.colors.applicationPrimaryColor };
    opacity: 1;
  ` }

  ${ (props) => props.disabled && css`
    color: ${ (props) => props.styles.colors.textReadOnly };
    border-bottom: 0.063rem solid ${ (props) => props.styles.colors.textReadOnly };
    cursor: default;
    pointer-events: none;
  ` }
`;

const UiTabItem = ({
  title,
  active,
  disabled,
  activeTab,
  setActiveTab,
  onClick,
  controllable
}) => {

  const isTheme = useTheme();

  const [userActive, setUserActive] = useState(active);
  const itemRef = useRef(null);

  useEffect(() => {
    if (controllable) {
      if (active) {
        setActiveTab(title);
      }
    } else {
      if (userActive) {
        setActiveTab(title);
        setUserActive(false);
      }
    }
  }, [controllable, active, userActive, setActiveTab, title]);

  useEffect(() => {
    const { current } = itemRef;

    if (current && isFunction(onClick)) {
      current.addEventListener('click', onClick);

      return () => {
        current.removeEventListener('click', onClick);
      };
    }
  }, [onClick])

  return (
    <StyledLi
      className={ styles.item }
      active={ title === activeTab }
      disabled={ disabled }
      onClick={ controllable ? undefined : () => setActiveTab(title) }
      styles={ isTheme.styles }
      ref={ itemRef }
    >
      { title }
    </StyledLi>
  );
};

UiTabItem.propTypes = {
  title: PropTypes.string,
  disabled: PropTypes.bool,
  activeTab: PropTypes.string,
  onClick: PropTypes.func,
  controllable: PropTypes.bool
};

export default UiTabItem;