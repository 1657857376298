import FBackspace from '../../../../Functional/FKeyboard/FBackspace';

import { isFunction } from '../../../../../utils/typeUtils';

import styles from './Backspace.module.css';

const Backspace = ({
  mode,
  disabled,
  value: userValue,
  onValueChange,
  setCells,
  stackCells,
  setStackCells,
  keyboardSize,
  ...props
}) => {

  const clickHandler = () => {
    if (mode === 'create') {
      const prevStack = stackCells.pop();

      setCells(prevStack);
      setStackCells(stackCells);
    } else {
      let [value = '', hash = ''] = userValue.split('/');

      if (isFunction(onValueChange)) {
        value = `${ value.slice(0, -1) }/${ hash.slice(0, -2) }`;
        onValueChange(value === '/' ? '' : value);
      }
    }
  };

  return (
    <foreignObject
      { ...props }
      x={ keyboardSize.width - 48 }
      y={ keyboardSize.height - 48 }
      width={ 48 }
      height={ 48 }
    >
      <FBackspace
        className={ styles.backspace }
        disabled={ disabled }
        onClick={ clickHandler }
      />
    </foreignObject>
  );
};

export default Backspace;