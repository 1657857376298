import PropTypes from 'prop-types';
import cn from 'classnames';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../context/ThemeProvider';

import styles from './UiAction.module.css';

const StyledAction = styled.button`
  ${ (props) => css`
    ${ props.styles.typography.Keyboard }
    box-shadow: ${ props.styles.shadows.action };
  ` }

  ${ (props) => {
    if (props.active) {
      return css`
        color: ${ props.styles.colors.sectionBackground };
        background: ${ props.styles.colors.textLink };
        cursor: pointer;
      `;
    } else {
      return css`
        color: ${ props.styles.colors.overlay_zero_point_two };
        background: ${ props.styles.colors.keysBackgroundColor };
      `;
    }
  } }
`;

/**
 * Button action component
 * @public
 * @version 0.0.90
 * @param {String} [className]
 * @param {Boolean} [active=false]
 * @param {String} [text=[Text]]
 * @param {Function} [onClick]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiAction />
 */
const UiAction = ({
  className,
  active,
  text,
  onClick,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <StyledAction
      { ...props }
      className={ cn(styles.action, className) }
      active={ active }
      onClick={ active ? onClick : undefined }
      ref={ innerRef }
      styles={ isTheme.styles }
    >
      { text }
    </StyledAction>
  );
};

UiAction.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Состояние (активен/не активен) кнопки */
  active: PropTypes.bool,
  /** Текст отображаемый на кнопке */
  text: PropTypes.string,
  /** Обработчик клика */
  onClick: PropTypes.func,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

UiAction.defaultProps = {
  active: false,
  text: '[Action]'
};

export default UiAction;