import React, { useEffect, useState } from 'react';
import {
  UiArrow, UiDots, UiSection, useOptions,
} from '@compassplus/ui-mobicash';
import { useHistory, useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import { DeleteSection, MContentDiv, SubContentDiv } from '../../../components/TemplateStyle';
import Main from '../MainPage/Main';
import i18n from '../../../i18n';
import SelectItems from './components/SelectItems';
import GlobalStateStore from '../../../stores/GlobalStateStore';
import ActionSheet from '../../../components/admin/ActionSheet';
import { HeaderActionContainer } from './style';
import { request } from '../../../Utils/requestUtil';
import ModalStore from '../../../stores/ModalStore';
import ParentGuidsSelections from '../../../components/admin/ParentGuidsDropdown/ParentGuidsSelections';

const SelectItemsPage = observer(() => {
  const history = useHistory();
  const params = useParams();
  const [groupId, setGroupId] = useState(params.id);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMoveMenuOpen, setIsMoveMenuOpen] = useState(false);
  const isOptions = useOptions();

  useEffect(() => {
    GlobalStateStore.selectedIds = [];
  }, []);

  useEffect(() => {
    setGroupId(params.id);
  }, [params]);

  const onDelete = () => {
    request('/api/v1/item/deleteMany', 'DELETE', { itemsIds: GlobalStateStore.selectedIds })
      .then((res) => {
        if (res && res !== 0) {
          history.goBack();
        } else {
          ModalStore.openUnexpectedErrorModal();
        }
      });
  };

  const move = (category) => {
    request('/api/v1/item/move', 'POST', {
      itemsIds: GlobalStateStore.selectedIds,
      newCategory: category.type,
    }).then((res) => {
      if (res) {
        history.goBack();
      } else {
        ModalStore.openUnexpectedErrorModal();
      }
    });
  };

  useEffect(() => {
    GlobalStateStore.setHeaderProperties({
      title: i18n.t('Select'),
      isBack: true,
      onBack: () => history.goBack(),
      isActive: isDropdownOpen,
      renderRightElement: () => (
        <>
          <UiDots
            color
            style={GlobalStateStore.selectedIds.length !== 0 ? { cursor: 'pointer', color: '#BE1E24' } : {}}
            onClick={() => { setIsDropdownOpen(!isDropdownOpen); setIsMoveMenuOpen(false); }}
            disabled={GlobalStateStore.selectedIds.length === 0}
          />
          <HeaderActionContainer>
            <ActionSheet
              open={isDropdownOpen}
              setOpen={setIsDropdownOpen}
              style={{ marginTop: '20x' }}
            >
              {isMoveMenuOpen ? (
                <ParentGuidsSelections isOpened={isMoveMenuOpen} onClick={move} />
              ) : (
                <>
                  <UiSection
                    title={i18n.t('Move')}
                    view="link"
                    style={{ userSelect: 'none' }}
                    rightElement={<UiArrow />}
                    onClick={() => setIsMoveMenuOpen(true)}
                    bottomDivider
                  />
                  <DeleteSection
                    title={i18n.t('Delete')}
                    style={{ userSelect: 'none' }}
                    rightElement={<UiArrow />}
                    onClick={() => {
                      if (GlobalStateStore.selectedIds.length !== 0) {
                        ModalStore.openManyItemsDeleteConfirm(onDelete, GlobalStateStore.selectedIds.length);
                      }
                    }}
                  />
                </>
              )}
            </ActionSheet>
          </HeaderActionContainer>
        </>
      ),
    });
  }, [isDropdownOpen, isMoveMenuOpen]);

  return (
    <>
      {
        isOptions.screen === 'mobile' ? (
          <MContentDiv>
            <SelectItems groupId={groupId} />
          </MContentDiv>
        ) : (
          <>
            <MContentDiv>
              <Main groupId={groupId} />
            </MContentDiv>
            <SubContentDiv>
              <SelectItems groupId={groupId} />
            </SubContentDiv>
          </>
        )
      }
    </>
  );
});

export default SelectItemsPage;
