import React, { useEffect, useState } from 'react';
import {
  UiCard, UiSection, UiCheckbox, useOptions,
} from '@compassplus/ui-mobicash';
import { observer } from 'mobx-react-lite';
import { request } from '../../../../Utils/requestUtil';
import ModalStore from '../../../../stores/ModalStore';
import { renderSelectionItems } from '../../MainPage/RenderCards';
import GlobalStateStore from '../../../../stores/GlobalStateStore';
import i18n from '../../../../i18n';

const SelectItems = observer(({ groupId }) => {
  const isOptions = useOptions();
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAllItemsSelected, setAllItemsSelected] = useState(false);

  useEffect(() => {
    if (groupId) {
      request(`/api/v1/item/${groupId}`, 'GET').then(
        (responseItems) => {
          setItems(responseItems);
          setIsLoading(false);
        },
        (error) => {
          console.log(error);
          ModalStore.openUnexpectedErrorModal();
        },
      );
    } else {
      request('/api/v1/group/root', 'GET').then(
        (rootGroup) => {
          request(`/api/v1/item/${rootGroup[0]._id}`, 'GET').then(
            (responseItems) => {
              setItems(responseItems);
              setIsLoading(false);
            },
            (error) => {
              console.log(error);
              ModalStore.openUnexpectedErrorModal();
            },
          );
        },
        (error) => {
          console.log(error);
          ModalStore.openUnexpectedErrorModal();
        },
      );
    }
  }, [groupId]);

  useEffect(() => {
    setAllItemsSelected(GlobalStateStore.selectedIds.length === items.length && GlobalStateStore.selectedIds.length !== 0);
  }, [GlobalStateStore.selectedIds]);

  const onChangeAllSelected = (flag) => {
    if (flag) {
      const itemsIds = [];
      items.forEach((item) => {
        const itemIsNotSelected = GlobalStateStore.selectedIds.indexOf(item._id) === -1;
        if (itemIsNotSelected) {
          itemsIds.push(item._id);
        }
      });
      GlobalStateStore.selectedIds = [...GlobalStateStore.selectedIds, ...itemsIds];
    } else {
      GlobalStateStore.selectedIds = [];
    }
  };

  const getRightSelectedString = (number) => {
    if (isOptions.language === 'ru') {
      if (number !== 11 && number % 10 === 1) {
        return `Выбран ${number} товар`;
      }
      if (number !== 12 && number !== 13 && number !== 14 && (number % 10 === 2 || number % 10 === 3 || number % 10 === 4)) {
        return `Выбрано ${number} товара`;
      }
      return `Выбрано ${number} товаров`;
    }
    if (isOptions.language === 'en') {
      if (number !== 11 && number % 10 === 1) {
        return `Selected ${number} item`;
      }
      return `Selected ${number} items`;
    }
  };

  return (
    <>
      <UiCard style={{ margin: '0 0 0.5rem' }}>
        <UiSection
          style={{ userSelect: 'none' }}
          leftElement={(
            <UiCheckbox
              checked={isAllItemsSelected}
              onClick={() => {
                setAllItemsSelected(!isAllItemsSelected);
                onChangeAllSelected(!isAllItemsSelected);
              }}
            />
          )}
          onClick={(event) => {
            event.preventDefault();
            setAllItemsSelected(!isAllItemsSelected);
            onChangeAllSelected(!isAllItemsSelected);
          }}
          title={GlobalStateStore.selectedIds.length !== 0 ? getRightSelectedString(GlobalStateStore.selectedIds.length) : i18n.t('SelectAllItems')}
        />
      </UiCard>

      {renderSelectionItems({
        isLoading,
        items,
        language: isOptions.language,
      })}
    </>
  );
});

export default SelectItems;
