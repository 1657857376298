import styled from 'styled-components';

import {
  UiSubsection, UiTab, UiTabBlock, UiCard,
} from '@compassplus/ui-mobicash';

export const PSPLogoContainer = styled.div`
  width: 96px;
  height: 96px;
  border: 1.54px solid ${(props) => props.theme.textDisable};
  box-sizing: border-box;
  border-radius: 80px;
  background: ${(props) => props.theme.sectionBackground};
  
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const Subsection = styled(UiSubsection)`
  & > div {
    margin: 0 0 1rem 0;
  }
`;

export const Tab = styled(UiTab)`
  & > div:last-child {
     position: relative;
  }
   
  & > div > ol {
    margin-bottom: 0;
  }
  
  @media(max-width: 663px) {
    & > div:last-child {
      position: relative;
      padding: 1rem 0.5rem 0;
    }
  }
`;

export const QRContainer = styled(UiCard)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  height: 292px;
`;
