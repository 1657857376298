import React, { useState } from 'react';
import {
  UiButton, UiCard, UiCardSection, UiInput,
} from '@compassplus/ui-mobicash';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react-lite';
import {
  getCashierAuth,
  getCashierLogin,
  getMerchantId,
  removeCashierAuth,
  request, setCashierData,
  setCashierLogin,
} from '../../../Utils/requestUtil';
import i18n from '../../../i18n';
import ModalStore from '../../../stores/ModalStore';
import { useFormInput } from '../../../Utils/Hoocks';
import GlobalStateStore from '../../../stores/GlobalStateStore';
import {Text} from "../../../components/TemplateStyle";

const CashierAuth = observer(({ location }) => {
  const history = useHistory();
  const login = useFormInput('');
  const password = useFormInput('');

  const [isCashierAuth, setIsCashierAuth] = useState(getCashierAuth());

  const cashierAuth = () => {
    const requestData = {
      login: login.value,
      password: password.value,
      language: i18n.language.toUpperCase(),
      merchantGuid: getMerchantId(),
    };

    request('/api/v1/cashier/auth', 'POST', requestData).then(
      (responseInfo) => {
        setIsCashierAuth(true);
        GlobalStateStore.isCashierAuth = true;
        setCashierLogin(responseInfo.login);
        setCashierData(isCashierAuth, responseInfo.login, responseInfo.password, responseInfo.challenge);
        if (location.state !== undefined) {
          switch (location.state.type) {
            case 'group':
              GlobalStateStore.isShareLinkOpen = true;
              history.goBack();
              break;
            case 'market':
              GlobalStateStore.isMarketShareLinkOpen = true;
              history.goBack();
              break;
            case 'item':
              GlobalStateStore.isShareLinkOpen = true;
              history.goBack();
              break;
            default:
              history.push('/administration');
              break;
          }
        } else {
          history.push('/administration');
        }
      },
      (error) => {
        ModalStore.openErrorWithMessage(i18n.t(error));
      },
    );
  };

  return (
    <>
      <Text
        view="readonly"
        style={{ textTransform: 'uppercase' }}
        typography="section-description-semibold"
      >
        {i18n.t('CashierData')}
      </Text>
      <Text style={{ margin: '0.5rem 0' }} view="readonly" typography="note">{i18n.t('CashierAnnotation')}</Text>
      <UiCard>
        {
          getCashierAuth() ? (
            <UiCardSection title={getCashierLogin()} description="" />
          ) : (
            <>
              <UiInput
                description={i18n.t('Login')}
                value={login.value}
                onChange={login.onChange}
                placeholder={i18n.t('Login')}
                bottomDivider
              />
              <UiInput
                type="password"
                description={i18n.t('Password')}
                value={password.value}
                onChange={password.onChange}
                placeholder={i18n.t('Password')}
              />
            </>
          )
        }
      </UiCard>
      {
        getCashierAuth() ? (
          <UiButton
            style={{ marginTop: '1rem' }}
            title={i18n.t('Change')}
            view="default"
            onClick={() => {
              removeCashierAuth();
              GlobalStateStore.isCashierAuth = false;
              setIsCashierAuth(false);
            }}
          />
        ) : (
          <UiButton
            style={{ marginTop: '1rem' }}
            title={i18n.t('Send')}
            view="default"
            onClick={() => {
              cashierAuth();
            }}
          />
        )
      }
    </>
  );
});

export default CashierAuth;
