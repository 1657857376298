import Field from './Field';
import PickupPoint from './PickupPoint';

const arrayMove = (arr, index, indexTo) => {
  const elem = arr[index];
  arr.splice(index, 1);
  arr.splice(indexTo, 0, elem);
}

class AddressData {
  constructor(newAddressData) {
    this.type = (newAddressData.type !== undefined) ? newAddressData.type : 'client';
    this.fields = (newAddressData.fields !== undefined) ? this.setFields(newAddressData.fields) : this.setFields([
      { title: { ru: 'Страна', en: 'Country' }, required: true },
      { title: { ru: 'Индекс', en: 'Post code' }, required: true },
      { title: { ru: 'Область/регион', en: 'Region' }, required: true },
      { title: { ru: 'Город', en: 'City' }, required: true },
      { title: { ru: 'Район', en: 'District' }, required: true },
      { title: { ru: 'Улица', en: 'Street' }, required: true },
      { title: { ru: 'Дом', en: 'House' }, required: true },
      { title: { ru: 'Квартира', en: 'Apartment' }, required: true },
    ]);
    this.points = (newAddressData.points !== undefined) ? this.setPoints(newAddressData.points) : [];
  }

  setFields(fields) {
    const fieldsArr = [];
    fields.forEach((field) => {
      fieldsArr.push(new Field(field));
    });
    return fieldsArr;
  }

  raisePoint(index) {
    arrayMove(this.fields, index, index - 1);
  }

  putDownPoint(index) {
    arrayMove(this.fields, index, index + 1)
  }

  setPoints(points) {
    const pointsArr = [];
    points.forEach((point) => {
      pointsArr.push(new PickupPoint(point));
    });
    return pointsArr;
  }

  setType(newType) {
    this.type = newType;
  }

  addField() {
    this.fields = [...this.fields, new Field({ title: { ru: `Название${this.fields.length}`, en: `Title${this.fields.length}` } })];
  }

  deleteField(index) {
    this.fields.splice(index, 1);
  }

  addPoint() {
    this.points = [...this.points, new PickupPoint({ title: { ru: '', en: '' } })];
  }

  deletePoint(index) {
    this.points.splice(index, 1);
  }
}

export default AddressData;
