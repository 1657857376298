import React, { useEffect, useState } from 'react';
import {
  UiSection, UiSwitch, UiCard, UiQuestions, useOptions,
} from '@compassplus/ui-mobicash';
import { observer } from 'mobx-react-lite';
import { Text } from '../../TemplateStyle';
import WebasystConnection from './Webasyst/WebasystConnection';
import ModalStore from '../../../stores/ModalStore';
import i18n from '../../../i18n';
import AdminMarketStore from '../../../stores/AdminMarketStore';

const AVAILABLE_CMS = ['Webasyst'];

const CMSConnections = observer(() => {
  const { isCMS, cmsTitle } = AdminMarketStore;
  const [selectedConnection, setSelectedConnection] = useState(cmsTitle);
  const isOptions = useOptions();

  useEffect(() => {
    if (window.location.search !== '') {
      setSelectedConnection(window.location.search.split('=')[1]);
    }
  }, []);

  useEffect(() => {
    if (isCMS) {
      setSelectedConnection(cmsTitle);
    }
  }, [cmsTitle]);

  const switchClick = (connection) => {
    if (selectedConnection === connection) {
      if (cmsTitle === connection) {
        ModalStore.isOpen = true;
        ModalStore.title = i18n.t('MerchantService');
        ModalStore.text = i18n.t('WebasystDisconnectMessage');
        ModalStore.buttons = [
          {
            text: i18n.t('Confirm'),
            mode: 'normal',
            action: () => {
              AdminMarketStore.removeCMS();
              setSelectedConnection('');
              ModalStore.setDefaultModal();
            },
          },
          {
            text: i18n.t('Cancel'),
            mode: 'normal',
            action: () => { ModalStore.setDefaultModal(); },
          },
        ];
      } else {
        setSelectedConnection('');
      }
    } else {
      setSelectedConnection(connection);
    }
  };

  const renderContent = (cms) => {
    switch (cms) {
      case 'Webasyst':
        return <WebasystConnection />;
      default:
        return null;
    }
  };

  const renderAvailableCMS = () => (
    <>
      {
          AVAILABLE_CMS.map((cms, index) => (
            <React.Fragment key={cms}>
              <UiCard shadow style={{ margin: '0.25rem 0' }}>
                <UiSection
                  title={cms}
                  rightElement={(
                    <UiSwitch
                      checked={selectedConnection === cms}
                      onClick={() => switchClick(cms)}
                    />
                  )}
                />
              </UiCard>
              {(cms === selectedConnection && cms !== cmsTitle) ? renderContent(cms) : null}
            </React.Fragment>
          ))
        }
    </>
  );

  return (
    <>
      <Text view="readonly" typography="subsection-title">
        {i18n.t('ConnectionToCMS')}
        <UiQuestions
          style={{ margin: '0 0.25rem' }}
          onClick={() => isOptions.setInfo({ active: true, menu: 'all' })}
        />
      </Text>
      {renderAvailableCMS()}
    </>
  );
});

export default CMSConnections;
