import PropTypes from 'prop-types';
import cn from 'classnames';

import UiContainer from '../UiContainer';

import './UiSectionContainer.css';

/**
 * Контейнер секций
 * @public
 * @version 1.9.0
 * @param {String} className
 * @param {*} primaryContent
 * @param {*} secondaryContent
 * @param {Boolean} secondaryContentOpened
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiSectionContainer
 *  primaryContent={<PrimaryContent />}
 *  secondaryContent={<SecondaryContent />}
 *  secondaryContentOpened={secondaryContentOpened}
 * />
 */
const UiSectionContainer = ({
  className,
  primaryContent,
  secondaryContent,
  secondaryContentOpened,
  ...props
}) => {

  return (
    <div
      {...props}
      className={cn('ui-section-container--container', className)}
    >
      <div
        className={cn(
          'ui-section-container--primary-container',
          secondaryContentOpened && 'active'
        )}
      >
        <UiContainer
          overflow={true}
          paddingTop="0.625rem"
          paddingRight={secondaryContent ? '0.25rem' : '0.5rem'}
          relativity={true}
          height="100%"
        >
          {primaryContent}
        </UiContainer>
      </div>
      <div
        className={cn(
          'ui-section-container--secondary-container',
          secondaryContentOpened && 'active'
        )}
      >
        <UiContainer
          overflow={true}
          paddingTop="0.625rem"
          paddingLeft="0.25rem"
          relativity={true}
          height="100%"
        >
          {secondaryContent}
        </UiContainer>
      </div>
    </div>
  );
};

UiSectionContainer.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Определяет пользовательский контент левой части контейнера */
  primaryContent: PropTypes.any,
  /** Определяет пользовательский контент правой части контейнера */
  secondaryContent: PropTypes.any,
  /** Состояние раскрытия правой части контейнера (true - видны оба блока, false - видна только левая часть блока) */
  secondaryContentOpened: PropTypes.bool
};

UiSectionContainer.defaultProps = {
  secondaryContentOpened: false
};

export default UiSectionContainer;