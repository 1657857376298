import styled from 'styled-components';


export const ShareLinkDisabled = styled.div`
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0);
`;

export const ShareLinkActive = styled(ShareLinkDisabled)`
    transform: scale(1);
`;

export const ShareLinkContent = styled.div`
    border-radius: 12px;
    background-color: ${(props) => props.theme.contentDivBackground};
    color: ${(props) => props.theme.textTitle};
`;

export const Title = styled.h3`
    color: ${(props) => props.theme.textTitle}
`;

export const ShareLinkButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
`;

export const SocialButton = styled.div`
    margin: 5px;
`;

export const Logo = styled.img`
    width: 35px;
    border-radius: 40px;
`;
