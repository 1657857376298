import styled from 'styled-components';

export const DropzoneDiv = styled.div`
`;

export const ColorsContainer = styled.div`
  margin: auto;
  width: 200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Color = styled.div`
  width: 32px;
  height: 32px;
  margin: 0.25rem 0.25rem;
  border-radius: 50px;
  cursor: pointer;
  border: 0.5px solid ${(props) => props.theme.applicationBackground};
  
  :hover {
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2)) drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.4));
  }
`;
