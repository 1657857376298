import { useRef } from 'react';
import cn from 'classnames';
import { Transition } from 'react-transition-group';

import styles from './Calendar.module.css';

const Calendar = ({
  className,
  active,
  children
}) => {

  const containerRef = useRef();

  return (
    <Transition
      in={ active }
      timeout={ active ? 300 : 200 }
      nodeRef={ containerRef }
    >
      { (state) => {
        return (
          <div
            className={ cn(styles.calendar, className, styles[state]) }
            ref={ containerRef }
          >
            { children }
          </div>
        );
      } }
    </Transition>
  );
};

export default Calendar;