import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Element component
 * @public
 * @version 0.0.90
 * @param {Function} onClick
 * @param {Boolean} [disabled=false]
 * @param {Boolean} [propagation=true]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiClear />
 */
const UiClear = ({
  onClick,
  disabled,
  propagation,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      color={ isTheme.styles.colors.textReadOnly }
      disabled={ disabled }
      propagation={ propagation }
      onClick={ onClick }
      innerRef={ innerRef }
      width="0.75rem"
      height="0.75rem"
      viewBox="0 0 12 12"
    >
      <path
        d="M6 5.39391L4.18199 3.5759C4.01448 3.40838 3.73811 3.41316 3.57563 3.57563C3.40827 3.743 3.41247 4.01856 3.5759 4.18199L5.39391 6L3.5759 7.81801C3.40838 7.98552 3.41316 8.26189 3.57563 8.42437C3.743 8.59173 4.01856 8.58753 4.18199 8.4241L6 6.60609L7.81801 8.4241C7.98552 8.59162 8.26189 8.58684 8.42437 8.42437C8.59173 8.257 8.58753 7.98144 8.4241 7.81801L6.60609 6L8.4241 4.18199C8.59162 4.01448 8.58684 3.73811 8.42437 3.57563C8.257 3.40827 7.98144 3.41247 7.81801 3.5759L6 5.39391ZM6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12Z" />
    </Element>
  );
};

UiClear.propTypes = {
  /** Обработчик события при клике */
  onClick: PropTypes.func,
  /** Компонент неактивен */
  disabled: PropTypes.bool,
  /** Разрешить/запретить всплытие обработчика onClick */
  propagation: PropTypes.bool,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
};

UiClear.defaultProps = {
  disabled: false,
  propagation: true
};

export default UiClear;