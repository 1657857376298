import UiBody from '../src/components/Layout/UiBody';
import UiContainer from '../src/components/Layout/UiContainer';
import UiDropDownContainer from '../src/components/Layout/UiDropDownContainer';
import UiFrame from '../src/components/Layout/UiFrame';
import UiSectionContainer from '../src/components/Layout/UiSectionContainer';

import UiAction from '../src/components/Ui/UiAction';
import UiActionSheet from '../src/components/Ui/UiActionSheet';
import UiArrow from '../src/components/Ui/UiArrow';
import UiBadge from '../src/components/Ui/UiBadge';
import UiBankCardOld from '../src/components/Ui/UiBankCardOld';
import UiButton from '../src/components/Ui/UiButton';
import UiCalendar from '../src/components/Ui/UiCalendar';
import UiCancelForPlates from '../src/components/Ui/UiCancelForPlates';
import UiCard from '../src/components/Ui/UiCard';
import UiCardSection from '../src/components/Ui/UiCardSection';
import UiCheckbox from '../src/components/Ui/UiCheckbox';
import UiClear from '../src/components/Ui/UiClear';
import UiCross from '../src/components/Ui/UiCross';
import UiDialog from '../src/components/Ui/UiDialog';
import UiDigiPad from '../src/components/Ui/UiDigiPad';
import UiDots from '../src/components/Ui/UiDots';
import UiDropdown from '../src/components/Ui/UiDropdown';
import UiDropdownSheet from '../src/components/Ui/UiDropdownSheet';
import UiExpand from '../src/components/Ui/UiExpand';
import UiEye from '../src/components/Ui/UiEye';
import UiFavorite from '../src/components/Ui/UiFavorite';
import UiHeader from '../src/components/Ui/UiHeader';
import UiIcon from '../src/components/Ui/UiIcon';
import UiImage from '../src/components/Ui/UiImage';
import UiInformation from '../src/components/Ui/UiInformation';
import UiInput from '../src/components/Ui/UiInput';
import UiKeyboard from '../src/components/Ui/UiKeyboard';
import UiLoader from '../src/components/Ui/UiLoader';
import UiMemPad from '../src/components/Ui/UiMemPad';
import UiMinus from '../src/components/Ui/UiMinus';
import UiMinusForPlates from '../src/components/Ui/UiMinusForPlates';
import UiNumPad from '../src/components/Ui/UiNumPad';
import UiPlates from '../src/components/Ui/UiPlates';
import UiPlate from '../src/components/Ui/UiPlate';
import UiPlus from '../src/components/Ui/UiPlus';
import UiPlusForPlates from '../src/components/Ui/UiPlusForPlates';
import UiPointer from '../src/components/Ui/UiPointer';
import UiProgressBar from '../src/components/Ui/UiProgressBar';
import UiQuestions from '../src/components/Ui/UiQuestions';
import UiRadioButton from '../src/components/Ui/UiRadioButton';
import UiRating from '../src/components/Ui/UiRating';
import UiReload from '../src/components/Ui/UiReload/UiReload';
import UiScrollArrow from '../src/components/Ui/UiScrollArrow/UiScrollArrow';
import UiSection from '../src/components/Ui/UiSection';
import UiShortcut from '../src/components/Ui/UiShortcut';
import UiSidebar from '../src/components/Ui/UiSidebar';
import UiSimpleButton from '../src/components/Ui/UiSimpleButton';
import UiSimpleInput from '../src/components/Ui/UiSimpleInput';
import UiSimpleLink from '../src/components/Ui/UiSimpleLink';
import UiSlider from '../src/components/Ui/UiSlider';
import UiSliderImages from '../src/components/Ui/UiSliderImages';
import UiStepper from '../src/components/Ui/UiStepper';
import UiStiky from '../src/components/Ui/UiStiky';
import UiSubsection from '../src/components/Ui/UiSubsection';
import UiSubstrate from '../src/components/Ui/UiSubstrate';
import UiSwitch from '../src/components/Ui/UiSwitch';
import UiTab from '../src/components/Ui/UiTab';
import UiTabBlock from '../src/components/Ui/UiTab/UiTabBlock';
import UiText from '../src/components/Ui/UiText';

import UiInputAmount from '../src/components/Shell/UiInputAmount';
import UiInputDate from '../src/components/Shell/UiInputDate';
import UiInputEmail from '../src/components/Shell/UiInputEmail';
import UiInputMobile from '../src/components/Shell/UiInputMobile';
import UiInputNumber from '../src/components/Shell/UiInputNumber';
import UiInputScroll from '../src/components/Shell/UiInputScroll';
import UiInputSheet from '../src/components/Shell/UiInputSheet';
import UiInputSwitch from '../src/components/Shell/UiInputSwitch';
import UiInputText from '../src/components/Shell/UiInputText';

import UiFinancialInstitutionBadge from '../src/components/Badges/UiFinancialInstitutionBadge';
import UiMobiCashBadge from '../src/components/Badges/UiMobiCashBadge';
import UiSuperAppBadge from '../src/components/Badges/UiSuperAppBadge';

import UiNewsIcon from '../src/components/Icons/UiNewsIcon';
import UiBillsIcon from '../src/components/Icons/UiBillsIcon';
import UiMerchantsIcon from '../src/components/Icons/UiMerchantsIcon';
import UiReportsIcon from '../src/components/Icons/UiReportsIcon';
import UiLoyaltyIcon from '../src/components/Icons/UiLoyaltyIcon';
import UiShareIcon from '../src/components/Icons/UiShareIcon';
import UiEventLogIcon from '../src/components/Icons/UiEventLogIcon';
import UiCouponsIcon from '../src/components/Icons/UiCouponsIcon';
import UiAwardsIcon from '../src/components/Icons/UiAwardsIcon';
import UiWalletIcon from '../src/components/Icons/UiWalletIcon';
import UiLoyaltyServIcon from '../src/components/Icons/UiLoyaltyServIcon';
import UiBillFinderIcon from '../src/components/Icons/UiBillFinderIcon';
import UiUsersIcon from '../src/components/Icons/UiUsersIcon';
import UiIssuedBillIcon from '../src/components/Icons/UiIssuedBillIcon';
import UiStaticQrIcon from '../src/components/Icons/UiStaticQrIcon';
import UiUserGroupIcon from '../src/components/Icons/UiUserGroupIcon';
import UiBigBillFinderIcon from '../src/components/Icons/UiBigBillFinderIcon';
import UiBigIssuedBillIcon from '../src/components/Icons/UiBigIssuedBillIcon';
import UiBigBillsIcon from '../src/components/Icons/UiBigBillsIcon';
import UiPayIcon from '../src/components/Icons/UiPayIcon';
import UiCallIcon from '../src/components/Icons/UiCallIcon';
import UiInstaIcon from '../src/components/Icons/UiInstaIcon';
import UiVkIcon from '../src/components/Icons/UiVkIcon';
import UiInfoIcon from '../src/components/Icons/UiInfoIcon';
import UiCountersIcon from '../src/components/Icons/UiCountersIcon';
import UiPickupIcon from '../src/components/Icons/UiPickupIcon';
import UiRequestPaymentIcon from '../src/components/Icons/UiRequestPaymentIcon';
import UiSiteIcon from '../src/components/Icons/UiSiteIcon';
import UiDeliveryIcon from '../src/components/Icons/UiDeliveryIcon';
import UiAdvertisingIcon from '../src/components/Icons/UiAdvertisingIcon';
import UiTransfersIcon from '../src/components/Icons/UiTransfersIcon';
import UiCharityIcon from '../src/components/Icons/UiCharityIcon';
import UiFacebookIcon from '../src/components/Icons/UiFacebookIcon';
import UiPosIcon from '../src/components/Icons/UiPosIcon';
import UiBioPayIcon from '../src/components/Icons/UiBioPayIcon';
import UiProductExploreIcon from '../src/components/Icons/UiProductExploreIcon';
import UiHistoryIcon from '../src/components/Icons/UiHistoryIcon';
import UiTransactionHistoryIcon from '../src/components/Icons/UiTransactionHistoryIcon';
import UiServiceRequestsIcon from '../src/components/Icons/UiServiceRequestsIcon';
import UiCurrencyExchangeRatesIcon from '../src/components/Icons/UiCurrencyExchangeRatesIcon';
import UiHubIcon from '../src/components/Icons/UiHubIcon';
import UiPaymentsIcon from '../src/components/Icons/UiPaymentsIcon';
import UiPaymentCabinetIcon from '../src/components/Icons/UiPaymentCabinetIcon';
import UiEasyPaymentsIcon from '../src/components/Icons/UiEasyPaymentsIcon';
import UiDataIcon from '../src/components/Icons/UiDataIcon';
import UiDocumentsIcon from '../src/components/Icons/UiDocumentsIcon';
import UiBoardingIcon from '../src/components/Icons/UiBoardingIcon';
import UiRegistrationIcon from '../src/components/Icons/UiRegistrationIcon';
import UiCurrencyExchangeIcon from '../src/components/Icons/UiCurrencyExchangeIcon';
import UiCurrencyRatesIcon from '../src/components/Icons/UiCurrencyRatesIcon';
import UiTerminalIcon from '../src/components/Icons/UiTerminalIcon';

import Element from '../src/components/Functional/Element';
import Modal from '../src/components/Functional/Modal';

import useDate from '../src/hooks/use-date';
import useEmail from '../src/hooks/use-email';
import { useForkRef } from '../src/hooks';
import useForm from '../src/hooks/use-form';
import useMobile from '../src/hooks/use-mobile';

import { formatValue as amountFormatValue, cleanValue as amountCleanValue } from '../src/utils/amountUtils';
import { createMemPad, isValidMemPad } from '../src/utils/memPadUtils';
import { formatValue as numberFormatValue, cleanValue as numberCleanValue, fixedDecimalValue } from '../src/utils/numberUtils';

import OptionsProvider, { useOptions } from '../src/context/OptionsProvider';
import ThemeProvider, { useTheme } from '../src/context/ThemeProvider';
import LightTheme from '../src/themes/light';
import DarkTheme from '../src/themes/dark';

import './fonts.css';

const amountUtils = {
  formatValue: amountFormatValue,
  cleanValue: amountCleanValue
};

const memPadUtils = {
  createMemPad,
  isValidMemPad
};

const numberUtils = {
  formatValue: numberFormatValue,
  cleanValue: numberCleanValue,
  fixedDecimalValue
};

export {
  UiBody,
  UiContainer,
  UiDropDownContainer,
  UiFrame,
  UiSectionContainer,

  UiAction,
  UiActionSheet,
  UiArrow,
  UiBadge,
  UiBankCardOld,
  UiButton,
  UiCalendar,
  UiCancelForPlates,
  UiCard,
  UiCardSection,
  UiCheckbox,
  UiClear,
  UiCross,
  UiDialog,
  UiDigiPad,
  UiDots,
  UiDropdown,
  UiDropdownSheet,
  UiExpand,
  UiEye,
  UiFavorite,
  UiHeader,
  UiIcon,
  UiImage,
  UiInformation,
  UiInput,
  UiKeyboard,
  UiLoader,
  UiMemPad,
  UiMinus,
  UiMinusForPlates,
  UiNumPad,
  UiPlate,
  UiPlates,
  UiPlus,
  UiPlusForPlates,
  UiPointer,
  UiProgressBar,
  UiQuestions,
  UiRadioButton,
  UiRating,
  UiReload,
  UiScrollArrow,
  UiSection,
  UiShortcut,
  UiSidebar,
  UiSimpleButton,
  UiSimpleInput,
  UiSimpleLink,
  UiSlider,
  UiSliderImages,
  UiStepper,
  UiStiky,
  UiSubsection,
  UiSubstrate,
  UiSwitch,
  UiTab,
  UiTabBlock,
  UiText,

  UiInputAmount,
  UiInputDate,
  UiInputEmail,
  UiInputMobile,
  UiInputNumber,
  UiInputScroll,
  UiInputSheet,
  UiInputSwitch,
  UiInputText,

  UiFinancialInstitutionBadge,
  UiMobiCashBadge,
  UiSuperAppBadge,

  UiNewsIcon,
  UiBillsIcon,
  UiMerchantsIcon,
  UiReportsIcon,
  UiLoyaltyIcon,
  UiShareIcon,
  UiEventLogIcon,
  UiCouponsIcon,
  UiAwardsIcon,
  UiWalletIcon,
  UiLoyaltyServIcon,
  UiBillFinderIcon,
  UiUsersIcon,
  UiIssuedBillIcon,
  UiStaticQrIcon,
  UiUserGroupIcon,
  UiBigBillFinderIcon,
  UiBigIssuedBillIcon,
  UiBigBillsIcon,
  UiPayIcon,
  UiCallIcon,
  UiInstaIcon,
  UiVkIcon,
  UiInfoIcon,
  UiCountersIcon,
  UiPickupIcon,
  UiRequestPaymentIcon,
  UiSiteIcon,
  UiDeliveryIcon,
  UiAdvertisingIcon,
  UiTransfersIcon,
  UiCharityIcon,
  UiFacebookIcon,
  UiPosIcon,
  UiBioPayIcon,
  UiProductExploreIcon,
  UiHistoryIcon,
  UiTransactionHistoryIcon,
  UiServiceRequestsIcon,
  UiCurrencyExchangeRatesIcon,
  UiHubIcon,
  UiPaymentsIcon,
  UiPaymentCabinetIcon,
  UiEasyPaymentsIcon,
  UiDataIcon,
  UiDocumentsIcon,
  UiBoardingIcon,
  UiRegistrationIcon,
  UiCurrencyExchangeIcon,
  UiCurrencyRatesIcon,
  UiTerminalIcon,

  Element,
  Modal,

  useDate,
  useEmail,
  useForkRef,
  useForm,
  useMobile,

  amountUtils,
  memPadUtils,
  numberUtils,

  OptionsProvider,
  useOptions,
  ThemeProvider,
  useTheme,

  LightTheme,
  DarkTheme
};