import styled from 'styled-components';
import { UiSection, UiCard, UiBadge, UiText } from '@compassplus/ui-mobicash';
import {AnimationCard} from "../../../components/TemplateStyle";

export const ItemsContainer = styled.div`
  //display: flex;
  //flex-wrap: wrap;
  //justify-content: start;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  
  @media(max-width: 668px) {
    grid-template-columns: repeat(3, 1fr);
  }
  
  @media(max-width: 486px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

export const CategoryMarketCard = styled(AnimationCard)`
  margin: 0.25rem 0;
  max-width: 320px;
  
  @media(max-width: 668px) {
    max-width: 100%;
  }
`;

export const MarketCard = styled(AnimationCard)`
  width: 148px;
  height: 180px;
  position: relative;
  cursor: pointer;
  margin: 0.25rem auto;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.13);
  
  @media(max-width: 483px) {
    margin: 0.25rem auto;
  }
  
  @media(max-width: 328px) {
    margin: 0.25rem 0.125rem;
  }
`;

export const MerchantSection = styled(UiSection)`
  & {
    height: 64px;
  }
  & > div:first-child {
    max-width: 5rem;
  }
  & > div:last-child {
    margin: auto 0;
  }
`;

export const MerchantLogo = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7F7F7F;
  line-height: 2;
`;

export const CartItemsCount = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background: #F8E71C;
  font-size: 8px;
  border-radius: 40px;
  height: 13px;
  width: 13px;
  text-align: center;
  font-weight: 700;
`;

export const CardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  mask-image: linear-gradient(to top,
  rgba(255,255,255,0.0001) 15.41%,
  rgba(255,255,255,0.152941) 18.18%,
  rgba(255,255,255,0.305882) 20.82%,
  rgba(255,255,255,0.458824) 23.62%,
  rgba(255,255,255,0.603922) 25.86%,
  rgba(255,255,255,0.729412) 27.85%,
  rgba(255,255,255,0.839216) 29.87%,
  rgba(255,255,255,0.92549) 31.21%,
  rgba(255,255,255,0.980392) 33.15%,
  #FFFFFF 100%);
  background-blend-mode: screen;
`;

export const CardText = styled.div`
  height: 64px;
  width: 148px;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 0.25rem 0.25rem 0.25rem;
`;

export const CardDescription = styled.div`
  font-family: Roboto;
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 1rem;
  max-height: 1rem;
  color: ${(props) => props.theme.textReadOnly};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 1;
`;

export const CardTitle = styled.div`
  font-family: Roboto;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  max-height: 1.25rem;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 1;
`;

export const CardPrice = styled(UiText)`
  font-style: normal;
  font-weight: 600;
  
  // Чтобы "нет в наличии" было на одной высоте с ценой
  line-height: 2rem;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  margin: 0.625rem 0 0.5rem;
  
  transition: ease 0.5s;
  
  @media(max-width: 648px) {
    flew-direction: column;
  }
`;

export const CategoryImageCard = styled(UiCard)`
  height: 11.875rem;
  border: 1px solid #F2F2F2;
  margin: 0.625rem 0 2rem;
  
  @media (max-width: 648px) {
    height: 5.625rem;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const InCartBage = styled(UiBadge)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 99;
`;
