import React from 'react';
import {
  UiQuestions, UiCard, UiCardSection, UiArrow, useOptions,
} from '@compassplus/ui-mobicash';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router';
import i18n from '../../../i18n';
import AttributesStore from '../../../stores/AttributesStore';
import AddAttributeButton from './AddAttributesButton';
import { Text } from '../../TemplateStyle';
import ItemStore from '../../../stores/ItemStore';

const Attributes = observer(({ language }) => {
  const isOptions = useOptions();
  const history = useHistory();
  const { attributes } = AttributesStore;

  return (
    <>
      <Text
        typography="note"
        view="readonly"
        style={{ textTransform: 'uppercase', fontWeight: 600, margin: '1rem 0 0.25rem' }}
      >
        {i18n.t('Attributes')}
        <UiQuestions
          style={{ margin: '0 0.25rem' }}
          onClick={() => isOptions.setInfo({ active: true, menu: 'all' })}
        />
      </Text>
      {(attributes && attributes.length !== 0) ? (
        <UiCard style={{ margin: '0.25rem 0' }} shadow>
          {
            attributes.map((attribute, index) => (
              <UiCardSection
                key={attribute.title.en}
                title={attribute.title[language]}
                description={attribute.complexValue && attribute.complexValue.map((val, index) => {
                  if (index !== attribute.complexValue.length - 1) {
                    return `${val.title[language]}, `;
                  }
                  return `${val.title[language]}`;
                })}
                active={index === AttributesStore.id}
                rightElement={<UiArrow />}
                onClick={() => {
                  AttributesStore.setModifyAttribute(attribute, index);
                  history.push('/addAttribute', { attribute, index, rootGroup: ItemStore.rootGroup });
                }}
                bottomDivider={index !== attributes.length - 1}
              />
            ))
          }
        </UiCard>
      ) : null}
      <AddAttributeButton />
    </>
  );
});

export default Attributes;
