import React, { useState } from 'react';
import { UiCard, useOptions } from '@compassplus/ui-mobicash';
import { observer } from 'mobx-react-lite';
import i18n from '../../../../i18n';

import DeliveryTypeSelection from './DeliveryTypeSelection';
import DeliveryPointSelection from './DeliveryPointSelection';

import OrderStore from '../../../../stores/OrderStore';
import { Text } from '../../../../components/TemplateStyle';

const CheckoutSelections = observer(() => {
  const isOptions = useOptions();
  const [typeSelectionOpen, setTypeSelectionOpen] = useState(false);
  const [pointSelectionOpen, setPointSelectionOpen] = useState(false);

  const getDeliveryDescription = () => {
    if (OrderStore.deliverySettingsArr && OrderStore.deliverySettingsArr.length > 0 && OrderStore.selectedDeliveryType !== -1) {
      if (OrderStore.selectedDelivery.description && OrderStore.selectedDelivery.description[isOptions.language] !== '') {
        return (
          <Text style={{ margin: '0.25rem 0 0' }} typography="note" view="readonly">
            {OrderStore.selectedDelivery.description[isOptions.language]}
          </Text>
        );
      }
    }
    return null;
  };

  return (
    <>
      {
        OrderStore.deliverySettingsArr && OrderStore.deliverySettingsArr.length > 0 ? (
          <>
            <Text style={{ textTransform: 'uppercase' }} view="readonly" typography="section-description-semibold">{i18n.t('OrderDetails')}</Text>
            <UiCard shadow style={{ margin: '0.5rem 0 0' }}>
              <DeliveryTypeSelection
                typeSelectionOpen={typeSelectionOpen}
                setTypeSelectionOpen={setTypeSelectionOpen}
                setPointSelectionOpen={setPointSelectionOpen}
              />
              <DeliveryPointSelection
                pointSelectionOpen={pointSelectionOpen}
                setPointSelectionOpen={setPointSelectionOpen}
                setTypeSelectionOpen={setTypeSelectionOpen}
              />
            </UiCard>
            {getDeliveryDescription()}
          </>
        ) : null
      }
    </>
  );
});

export default CheckoutSelections;
