import { request } from './requestUtil';

const allDataUnload = () => {
  request('/api/v1/group/json', 'GET').then(
    (linkToFile) => {
      const a = document.createElement('a');
      a.href = linkToFile.toString();
      a.setAttribute('download', 'output.json');
      a.click();
    },
  );
};

const uploadImage = (imageData, type) => {
  const formData = new FormData();
  formData.append('file', imageData);
  formData.append('name', 'image');
  return new Promise((resolve, reject) => {
    request(`/api/uploads/image/${type}/`, 'POST', formData).then(
      (result) => resolve(result),
      (error) => reject(error),
    );
  });
};

export { allDataUnload, uploadImage };
