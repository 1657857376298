import './Slide.css';

const Slide = ({
  slide
}) => {

  return (
    <div className="ui-slider--slide">
      {slide}
    </div>
  );
};

export default Slide;