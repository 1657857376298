import PropTypes from 'prop-types';

import { useTheme } from '../../../../../../context/ThemeProvider';

import UiBadge from '../../../../UiBadge';

const Status = ({
  className,
  status,
  statusText
}) => {

  const isTheme = useTheme();

  const handlerColor = (status) => {
    switch (status) {

      // TXPG
      case 'Preparing':
        return isTheme.styles.colors.pendingBillPrimary;
      case 'WaitPushTran':
        return isTheme.styles.colors.pendingBillPrimary;
      case 'Authorized':
        return isTheme.styles.colors.paidBillPrimary;
      case 'PartPaid':
        return isTheme.styles.colors.paidBillPrimary;
      case 'Funded':
        return isTheme.styles.colors.paidBillPrimary;
      case 'FullyPaid':
        return isTheme.styles.colors.paidBillPrimary;
      case 'Expired':
        return isTheme.styles.colors.textDisable;
      case 'Cancelled':
        return isTheme.styles.colors.textReadOnly;
      case 'Rejected':
        return isTheme.styles.colors.notPaidBillSecondary;
      case 'Refused':
        return isTheme.styles.colors.notPaidBillSecondary;
      case 'Closed':
        return isTheme.styles.colors.textDisable;

      // MobiCash
      case 'Active':
        return isTheme.styles.colors.paidBillPrimary;
      case 'Refunded':
        return isTheme.styles.colors.textReadOnly;
      case 'Payed':
        return isTheme.styles.colors.paidBillPrimary;
      case 'PaymentFailed':
        return isTheme.styles.colors.notPaidBillSecondary;
      case 'Deleted':
        return isTheme.styles.colors.textDisable;
      case 'Disputed':
        return isTheme.styles.colors.disputeBillSecondary;

      // LoginStatus
      case 'NeedNewPwd':
        return isTheme.styles.colors.notPaidBillSecondary;
      case 'Blocked':
        return isTheme.styles.colors.textReadOnly;

      default:
        return isTheme.styles.colors.textReadOnly;
    }
  }

  return (
    <UiBadge
      className={ className }
      text={ statusText ? statusText : status }
      color={ handlerColor(status) }
    />
  );
};

Status.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  status: PropTypes.oneOf([
    // TXPG
    'Preparing', 'WaitPushTran', 'Authorized', 'PartPaid', 'Funded', 'FullyPaid', 'Expired', 'Cancelled', 'Rejected', 'Refused', 'Closed',
    // MobiCash
    'Active', 'Refunded', 'Payed', 'PaymentFailed', 'Deleted', 'Disputed',
    // LoginStatus
    'NeedNewPwd', 'Blocked'
  ]),
  statusText: PropTypes.string
};

export default Status;