import React, { useEffect, useState } from 'react';
import {
  UiButton, UiCard, UiIcon, UiInput, UiTabBlock, useMobile
} from '@compassplus/ui-mobicash';
import QRCode from 'qrcode.react';
import { useHistory } from 'react-router';
import i18n from '../../../i18n';
import {MainText, Text} from '../../../components/TemplateStyle';
import { QRContainer, Tab } from './styles';
import { useFormInput } from '../../../Utils/Hoocks';
import { generateSessionUUID } from '../../../Utils/session';
import GlobalStateStore from '../../../stores/GlobalStateStore';
import {
  getRequestData,
  removeUserData,
  request, setCurrency, setLogoColor, setLogoSymbol,
  setMerchantId, setPayeeList, setPhoto,
  setRequestData,
  setTitle,
  setUserData,
} from '../../../Utils/requestUtil';
import ModalStore from '../../../stores/ModalStore';
import DeliveryForms from '../../../stores/DeliveryForms';
import AdminMarketStore from "../../../stores/AdminMarketStore";

const LoginTabs = ({ redirect }) => {
  const history = useHistory();
  const login = useFormInput('');
  const password = useFormInput('');
  const phonePassword = useFormInput('');
  const smsCode = useFormInput('');
  const [showCode, setShowCode] = useState(false);
  const [mobile, { setMobile }] = useMobile('');
  const [activeUpdate, setActiveUpdate] = useState(false);

  const [checkRegistrationRrn, setCheckRegistrationRrn] = useState();

  const [sessionId, setSessionId] = useState(null);

  let checkSessionTimer;
  let generateSessionTimer;

  const requestData = {
    login: login.value,
    password: password.value,
    language: i18n.language.toUpperCase(),
  };

  const openSession = () => {
    clearTimeout(generateSessionTimer);
    const uuid = generateSessionUUID();

    setSessionId(uuid);
    checkSession(uuid);
    generateSessionTimer = setTimeout(openSession, 1000 * 60 * 5);
  };

  useEffect(() => {
    GlobalStateStore.setHeaderProperties({
      title: 'eMarketPlace',
      isBack: false,
    });
    GlobalStateStore.isAuth = false;
    removeUserData();
    openSession();
  }, []);

  const hasRootGroup = () => new Promise((resolve, reject) => {
    request('/api/v1/group', 'GET').then(
      (groups) => resolve(groups.length > 0 ? groups : false),
      (error) => reject(error),
    );
  });

  const checkRootGroup = (settings) => {
    hasRootGroup().then(
      (rootGroups) => {
        if (!rootGroups) {
          const requestRootData = {
            title: { en: settings.Name, ru: settings.Name },
            merchantGuid: settings.Rid,
            image: null,
          };

          request('/api/v1/group/', 'POST', requestRootData).then(
            (rootGroup) => {
              GlobalStateStore.isLoading = false;
              if (redirect) {
                history.push(redirect.pathname)
              } else {
                history.push('/main');
              }
            },
            (error) => {
              ModalStore.openErrorWithMessage(i18n.t(error));
            },
          );
        } else if (rootGroups.length > 0) {
          GlobalStateStore.isLoading = false;
          if (redirect) {
            history.push(redirect.pathname)
          } else {
            history.push('/main');
          }
        }
      },
      (rootError) => {
        ModalStore.openErrorWithMessage(i18n.t(rootError));
      },
    );
  };

  const getSettingsRequest = () => new Promise((resolve, reject) => {
    const reqBody = getRequestData();
    reqBody.language = '';
    request('/api/v1/settings/getSettings', 'POST', reqBody).then(
      (settings) => resolve(settings),
      (getSettingsError) => reject(getSettingsError),
    );
  });

  const getPayeeListRequest = () => new Promise((resolve, reject) => {
    const reqBody = getRequestData();
    reqBody.language = '';
    request('/api/v1/settings/getPayeeList', 'POST', reqBody).then(
      (payeeList) => resolve(payeeList),
      (getSettingsError) => reject(getSettingsError),
    );
  });

  const getSettings = () => new Promise((resolve) => {
    getSettingsRequest().then(
      (settings) => {
        setMerchantId(settings.Rid);
        setTitle(JSON.stringify({ en: settings.Name, ru: settings.NameLocal }));
        setPhoto(settings.Photo !== undefined ? `data:image/png;base64,${settings.Photo}` : '');
        setLogoSymbol(settings.LogoSymbol);
        setLogoColor(settings.LogoColor);
        GlobalStateStore.cms = settings?.cms;
        AdminMarketStore.email = settings?.email;
        GlobalStateStore.accessToken = settings?.accessToken;
        resolve(settings);
      },
      (settingsError) => {
        ModalStore.openErrorWithMessage(i18n.t(settingsError));
        GlobalStateStore.isLoading = false;
      },
    );
  });

  const getPayeeList = () => new Promise((resolve) => {
    getPayeeListRequest().then(
      (response) => {
        const settings = response.settings;
        setPayeeList(JSON.stringify(response.payeeList));
        setMerchantId(settings.Rid);
        setTitle(JSON.stringify({ en: settings.Name, ru: settings.NameLocal }));
        setPhoto(settings.Photo !== undefined ? `data:image/png;base64,${settings.Photo}` : '');
        setLogoSymbol(settings.LogoSymbol);
        setLogoColor(settings.LogoColor);
        setCurrency(settings.Currency);
        GlobalStateStore.cms = settings?.cms;
        AdminMarketStore.email = settings?.email;
        GlobalStateStore.accessToken = settings?.accessToken;
        resolve(settings);
      },
      (settingsError) => {
        ModalStore.openErrorWithMessage(i18n.t(settingsError));
        GlobalStateStore.isLoading = false;
      },
    );
  });

  const checkSession = (uuid) => {
    clearTimeout(checkSessionTimer);
    request('/api/v1/authorization/checkSession', 'POST', { sessionId: uuid }).then(
      (user) => {
        if (user) {
          clearTimeout(generateSessionTimer);
          setRequestData(user.login, user.password, user.challenge);

          setUserData(
            true,
            user.login,
            user.password,
            user.challenge,
            user.authToken,
            user.currency,
            user.refreshToken,
            user.expTime,
          );

          getSettings().then(
            (settings) => {
              checkRootGroup(settings);
            },
          );
        } else {
          checkSessionTimer = setTimeout(() => {
            checkSession(uuid);
          }, 1000 * 50 * 5);
        }
      },
      (error) => {
        if (error) {
          checkSessionTimer = setTimeout(() => {
            checkSession(uuid);
          }, 1000 * 60 * 5);
        }
      },
    );
  };

  const onAuth = (event) => {
    event.preventDefault();

    if (!login.value || !password.value) {
      ModalStore.openErrorWithMessage(i18n.t('Fill required fields'));
    }

    GlobalStateStore.isLoading = true;
    request('/api/v1/authorization', 'POST', requestData).then(
      (user) => {
        GlobalStateStore.isAuth = true;

        setUserData(
          true,
          user.login,
          user.password,
          user.challenge,
          user.authToken,
          user.currency,
          user.refreshToken,
          user.expTime,
        );

        getSettings().then(
          (settings) => {
            checkRootGroup(settings);
            DeliveryForms.getFormFromDB();
          },
        );
      },
      (authorizationError) => {
        ModalStore.openErrorWithMessage(i18n.t(authorizationError));
        GlobalStateStore.isLoading = false;
      },
    );
  };

  const authByPhoneRequest = () => {
    if (!mobile || !mobile.valueValid || !phonePassword.value) {
      ModalStore.openErrorWithMessage(i18n.t('FillRequiredFields'));
      return;
    }

    const reqBody = {
      login: mobile.value,
      password: phonePassword.value,

      DeviceInfo: "DeviceInfo"
    }

    request('/api/v1/authorization/phone', 'POST', reqBody).then(
      (response) => {
        if (response) {
          if (response.status === 'otp') {
            setShowCode(true);
            getUpdateTimer();
            setCheckRegistrationRrn(response.CheckRegistration.data.Rrn);
          } else {
            GlobalStateStore.isAuth = true;
            GlobalStateStore.isLoading = true;
            setUserData(
              true,
              response.result.login,
              response.result.password,
              response.result.challenge,
              response.result.authToken,
              response.result.currency,
              response.result.refreshToken,
              response.result.expTime,
            );

            getPayeeList().then(
              (settings) => {
                checkRootGroup(settings);
                DeliveryForms.getFormFromDB();
              }
            );
          }
        }
      },
      (authorizationError) => {
        setShowCode(false);
        smsCode.setValue('');
        ModalStore.openErrorWithMessage(i18n.t(authorizationError));
        GlobalStateStore.isLoading = false;
      },
    )
  }

  const authByCodeRequest = () => {
    GlobalStateStore.isLoading = true;
    if (!mobile || !mobile.valueValid || !phonePassword.value || !smsCode.value || !checkRegistrationRrn) {
      ModalStore.openErrorWithMessage(i18n.t('FillRequiredFields'));
      return;
    }

    const reqBody = {
      login: mobile.value,
      password: phonePassword.value,
      SmsCode: smsCode.value,
      InitTranRrn: checkRegistrationRrn,
      DeviceInfo: "DeviceInfo"
    }

    request('/api/v1/authorization/code', 'POST', reqBody).then(
      (response) => {
        if (response) {
          GlobalStateStore.isAuth = true;
          setUserData(
            true,
            response.result.login,
            response.result.password,
            response.result.challenge,
            response.result.authToken,
            response.result.currency,
            response.result.refreshToken,
            response.result.expTime,
          );

          getPayeeList().then(
            (settings) => {
              checkRootGroup(settings);
              DeliveryForms.getFormFromDB();
            }
          );
        }
      },
      (authorizationError) => {
        setShowCode(false);
        smsCode.setValue('');
        ModalStore.openErrorWithMessage(i18n.t(authorizationError));
        GlobalStateStore.isLoading = false;
      },
    )
  }

  const phoneHandleClick = () => {
    if (showCode) {
      authByCodeRequest();
    } else {
      authByPhoneRequest();
    }
  }

  const getUpdateTimer = () => {
    setActiveUpdate(false);
    setTimeout(() => {
      setActiveUpdate(true);
    }, 1000 * 60 * 2);
  }

  return (
    <Tab>
      <UiTabBlock active title={i18n.t('ByPhone')}>
        <UiCard style={{ margin: '0 0 1rem 0' }} shadow>
          <UiInput
            type="tel"
            onKeyUp={(event) => { if (event.keyCode === 13) { phoneHandleClick(event); } }}
            description={i18n.t('PhoneNumber')}
            value={mobile.value && mobile.valueFormat}
            onChange={(event) => { setMobile(event.target.value); }}
            placeholder="+7 999 999 99 99"
            readOnly={showCode}
            required
            bottomDivider
          />
          <UiInput
            autoComplete="new-password"
            onKeyUp={(event) => { if (event.keyCode === 13) { phoneHandleClick(event); } }}
            description={i18n.t('Password')}
            type="password"
            value={phonePassword.value}
            onChange={phonePassword.onChange}
            placeholder={i18n.t('Password')}
            readOnly={showCode}
            bottomDivider={showCode}
            required
          />
          {
            showCode ? (
              <UiInput
                onKeyUp={(event) => { if (event.keyCode === 13) { phoneHandleClick(event); } }}
                description={i18n.t('VerificationCode')}
                value={smsCode.value}
                onChange={smsCode.onChange}
                rightElement={
                  <UiIcon
                    icon="legacy-reset"
                    disabled={!activeUpdate}
                    view={activeUpdate ? 'link' : 'default'}
                    onClick={() => {
                      if (activeUpdate) {
                        authByPhoneRequest();
                        getUpdateTimer();
                      }
                    }}
                  />
                }
                placeholder={i18n.t('VerificationCode')}
                required
              />
            ) : null
          }
        </UiCard>
        {
          showCode ? (
            <Text style={{ margin: '0 0 0.5rem', textAlign: 'justify' }} view="readonly" typography="note">{i18n.t('CodeAnnotation')}</Text>
          ) : null
        }
        <UiButton
          title={i18n.t('Continue')}
          onClick={phoneHandleClick}
        />
      </UiTabBlock>
      <UiTabBlock title={i18n.t('ByLogin')}>
        <UiCard style={{ margin: '0 0 1rem 0' }} shadow>
          <UiInput
            onKeyUp={(event) => { if (event.keyCode === 13) { onAuth(event); } }}
            description={i18n.t('Login')}
            value={login.value}
            onChange={login.onChange}
            placeholder={i18n.t('Login')}
            required
            bottomDivider
          />
          <UiInput
            onKeyUp={(event) => { if (event.keyCode === 13) { onAuth(event) } }}
            description={i18n.t('Password')}
            type="password"
            value={password.value}
            onChange={password.onChange}
            placeholder={i18n.t('Password')}
            required
          />
        </UiCard>
        <UiButton title={i18n.t('Continue')} onClick={onAuth} />
      </UiTabBlock>
      <UiTabBlock title={i18n.t('ByQRCode')}>
        <MainText style={{ margin: '0 0 1rem 0' }}>{i18n.t('QRAnnotation')}</MainText>
        <QRContainer shadow>
          <QRCode
            imageSettings={{ src: '/images/mobicash_standardapp.svg', height: 30, width: 30 }}
            value={`&mobiAuth&sessionId=${sessionId}`}
            includeMargin={Boolean(true)}
            size={190}
          />
        </QRContainer>
      </UiTabBlock>
    </Tab>
  );
};

export default LoginTabs;
