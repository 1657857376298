import React, { useState, useEffect } from 'react';
import { UiCheckbox, useOptions } from '@compassplus/ui-mobicash';
import { observer } from 'mobx-react-lite';
import { action } from 'mobx';
import { ItemCardSection } from '../MainPage/styles';
import i18n from '../../../i18n';
import GlobalStateStore from '../../../stores/GlobalStateStore';

const SelectedItemCard = observer(({
  item, index, itemsLength,
}) => {
  const isOptions = useOptions();
  const [isChecked, setIsChecked] = useState(GlobalStateStore.selectedIds.indexOf(item._id) !== -1);

  useEffect(() => {
    setIsChecked(GlobalStateStore.selectedIds.indexOf(item._id) !== -1);
  }, [GlobalStateStore.selectedIds]);

  const removeId = (id) => {
    const idIndex = GlobalStateStore.selectedIds.indexOf(id);
    if (idIndex === 0) {
      GlobalStateStore.selectedIds = [...GlobalStateStore.selectedIds.slice(idIndex + 1, GlobalStateStore.selectedIds.length)];
    } else if (idIndex === GlobalStateStore.selectedIds.length) {
      GlobalStateStore.selectedIds = [...GlobalStateStore.selectedIds.slice(0, idIndex - 1)];
    } else {
      GlobalStateStore.selectedIds = [...GlobalStateStore.selectedIds.slice(0, idIndex), ...GlobalStateStore.selectedIds.slice(idIndex + 1, GlobalStateStore.selectedIds.length)];
    }
  };

  return (
    <ItemCardSection
      title={item.title[isOptions.language]}
      description={item.description[isOptions.language].length > 80 ? `${item.description[isOptions.language].substr(0, 80)}...` : item.description[isOptions.language]}
      leftElement={(
        <img
          style={{
            width: '3rem', height: '3rem', borderRadius: '80px', objectFit: 'cover',
          }}
          src={item.images && item.images.length > 0 ? (item.images.find((image) => Boolean(image.isMain) === true).url) : ''}
        />
      )}
      rightElement={(
        <UiCheckbox
          checked={isChecked}
          onClick={() => {
            if (isChecked) {
              removeId(item._id);
            } else {
              action(() => {
                GlobalStateStore.selectedIds = [...GlobalStateStore.selectedIds, item._id];
              });
            }
          }}
        />
      )}
      onClick={(event) => {
        if (isChecked) {
          removeId(item._id);
        } else {
          GlobalStateStore.selectedIds = [...GlobalStateStore.selectedIds, item._id];
        }
        event.preventDefault();
      }}
      bottomDivider={index !== itemsLength - 1}
      status={item.status ? 'Active' : 'Blocked'}
      statusText={item.status ? i18n.t('Active') : i18n.t('Inactive')}
    />
  );
});

export default SelectedItemCard;
