import React from 'react';
import i18n from "../../../../i18n";
import {UiCard, UiDropdown, UiInput, UiSection, useOptions} from "@compassplus/ui-mobicash";
import OrderStore from "../../../../stores/OrderStore";
import {ActionSheet} from "../../../admin/MainPage/styles";
import {action} from "mobx";
import {CheckoutDropdown} from "../style";
import ShoppingCart from "../../../../stores/ShoppingCartStore";
import ModalStore from "../../../../stores/ModalStore";
import { getCurrency } from "../../../../Utils/requestUtil";
import DeliveryStore from "../../../../stores/DeliveryStore";

const DeliveryTypeSelection = ({ typeSelectionOpen, setTypeSelectionOpen, setPointSelectionOpen }) => {
  const isOptions = useOptions();

  const onTypeSelectClick = (index) => {
    let flag = true;
    if (OrderStore.deliverySettingsArr[index].cost !== undefined) {
      if (OrderStore.deliverySettingsArr[index].cost.minOrderPrice > Number(ShoppingCart.totalCost)) {
        flag = false;
        ModalStore.openWithMessage(
          `${i18n.t('MinOrderPriceMsg')} ${Number(OrderStore.deliverySettingsArr[index].cost.minOrderPrice).toFixed(2)} ${i18n.t(getCurrency())}`
        )
      }
    }

    if (flag) {
      OrderStore.selectedDeliveryType = index;
      DeliveryStore.setForm(OrderStore.deliverySettingsArr[index]);
    }

    setTypeSelectionOpen(false);
  }

  const renderDropdownSections = () => (
    <>
      {OrderStore.deliverySettingsArr?.map((setting, index) => (
        <UiSection
          key={`${setting.title.en}${index}`}
          title={setting.title[isOptions.language]}
          view="link"
          onClick={action(() => { onTypeSelectClick(index); })}
          bottomDivider={index !== OrderStore.deliverySettingsArr.length - 1}
        />
      ))}
    </>
  )

  return (
    <>
      <UiInput
        description={i18n.t('DeliveryMethod')}
        placeholder={i18n.t('NotDefined')}
        rightElement={<UiDropdown />}
        onClick={() => { setTypeSelectionOpen(!typeSelectionOpen); setPointSelectionOpen(false); }}
        value={OrderStore.selectedDeliveryType !== -1 ? OrderStore.selectedDelivery?.title[isOptions.language] : ''}
        bottomDivider={OrderStore.selectedDelivery?.type === 'Pickup'}
        readOnly
      />
      {
        isOptions.screen === 'mobile' ? (
          <ActionSheet
            isOpened={typeSelectionOpen}
            cancelClick={() => { setTypeSelectionOpen(false); }}
            cancelTitle={i18n.t('Cancel')}
          >
            <UiCard>
              {renderDropdownSections()}
            </UiCard>
          </ActionSheet>
        ) : (
          <CheckoutDropdown isOpened={typeSelectionOpen}>
            {renderDropdownSections()}
          </CheckoutDropdown>
        )
      }
    </>
  )
}

export default DeliveryTypeSelection;