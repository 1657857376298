import { Children, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import SlidesList from './SlideList';
import Points from './Points';

import { useForkRef } from '../../../hooks';

import { isFunction } from '../../../utils/typeUtils';

import './UiSlider.css';

/**
 * Слайдер
 * @public
 * @version 1.9.0
 * @param {String} className
 * @param {Boolean} autoPlay
 * @param {Number} autoPlayTime
 * @param {String} width
 * @param {String} height
 * @param {Function} onChange
 * @param innerRef
 * @param {*} children
 * @param props
 * @return {JSX.Element|null}
 * @constructor
 * @example
 * <UiSlider>
 *   <div>...</div>
 *   <div>...</div>
 * </UiSlider>
 */
const UiSlider = ({
  className,
  autoPlay,
  autoPlayTime,
  width,
  height,
  onChange,
  innerRef,
  children,
  ...props
}) => {

  const items = useMemo(() => {
    return Children.toArray(children);
  }, [children]);

  const [slide, setSlide] = useState(0);

  const sliderRef = useRef(null);
  const refs = useForkRef([innerRef, sliderRef]);

  const goToSlide = useCallback((number) => {
    const nextSlide = number % items.length;

    setSlide(nextSlide);

    const { props: { id = '' } } = items[nextSlide];

    if (!!id && isFunction(onChange)) {
      onChange({ id });
    }
  }, [items, onChange]);

  useEffect(() => {
    if (!autoPlay) return;

    const interval = setInterval(() => {
      goToSlide(slide + 1);
    }, autoPlayTime);

    return () => {
      clearInterval(interval);
    };
  }, [autoPlay, autoPlayTime, goToSlide, slide]);

  const slideChangeHandler = useCallback((slideId) => {
    const slideIndex = items.findIndex((item) => {
      const { props: { id: itemId = '' } } = item;

      return !!itemId && itemId === slideId;
    });

    if (slideIndex === -1) {
      return false;
    } else {
      goToSlide(slideIndex);

      return true;
    }
  }, [items, goToSlide]);

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slidechange = slideChangeHandler;
    }
  }, [slideChangeHandler]);

  if (items.length === 0) {
    return null;
  }

  return (
    <div
      {...props}
      className={cn('ui-slider--container', className)}
      style={{ width: width, height: height }}
      ref={refs}
    >
      <SlidesList items={items} slide={slide} />
      <Points items={items} slide={slide} goToSlide={goToSlide} />
    </div>
  );
};

UiSlider.displayName = 'UiSlider';

UiSlider.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Определяет состояние автопрокрутки слайдера (вкл/выкл) */
  autoPlay: PropTypes.bool,
  /** Определяет время автопрокрутки слайдера */
  autoPlayTime: PropTypes.number,
  /** Определяет ширину слайдера */
  width: PropTypes.string,
  /** Определяет высоту слайдера */
  height: PropTypes.string,
  /** Функция изменения значения Slider */
  onChange: PropTypes.func,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  /** Дочерние элементы */
  children: PropTypes.any
};

UiSlider.defaultProps = {
  autoPlay: false,
  autoPlayTime: 5000,
  width: '100%',
  height: '100%'
};

export default UiSlider;