import React, { useReducer } from 'react';
import { UiCard, UiSection, UiCheckbox, useOptions } from "@compassplus/ui-mobicash";
import {Text} from "../../../TemplateStyle";
import AttributesStore from "../../../../stores/AttributesStore";
import {action} from "mobx";

const Checkbox = ({ isActive }) => {
  const isOptions = useOptions();
  const attributes = AttributesStore.getAttributesByType('checkbox');
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const getCheckboxAttribute = (checkboxAttribute) => (
    (checkboxAttribute !== undefined) ? (
      <>
        <Text
          style={{ margin: '0.5rem 0' }}
          typography="subsection-title"
          view="readonly"
        >
          {checkboxAttribute.title[isOptions.language]}
        </Text>
        <Text
          style={{ margin: '0.5rem 0' }}
          typography="note"
          view="readonly"
        >
          {checkboxAttribute.description[isOptions.language]}
        </Text>
        <UiCard shadow>
          {getCheckOptions(checkboxAttribute)}
        </UiCard>
      </>
    ) : null
  );

  const getCheckOptions = (checkboxAttribute) => (
    (checkboxAttribute.complexValue !== undefined) ? (
      checkboxAttribute.complexValue?.map((checkbox,index) => (
        <UiSection
          key={checkbox.title.en}
          title={checkbox.title[isOptions.language]}
          bottomDivider={index !== checkboxAttribute.complexValue.length - 1}
          onClick={action(() => { checkboxAttribute.changeValue(index, !checkbox.value); forceUpdate(); })}
          disabled={!isActive}
          leftElement={(
            <UiCheckbox
              checked={checkbox.value}
              disabled={!isActive}
            />
          )}
        />
      ))
    ) : null
  );

  return (
    <>
      {attributes.map(getCheckboxAttribute)}
    </>
  );
};

export default Checkbox;