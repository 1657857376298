import fonts from '../common/fonts';
import frames from '../common/frames';
import typography from '../common/typography';
import colors from './colors';
import media from './media';
import shadows from './shadows';

const lightTheme = {
  name: 'light',
  styles: {
    fonts,
    frames,
    typography,
    colors,
    media,
    shadows
  }
};

export default lightTheme;