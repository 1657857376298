import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Element component
 * @public
 * @version 0.0.90
 * @param {Function} onClick
 * @param {Boolean} [disabled=false]
 * @param {Boolean} [propagation=true]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiMinus />
 */
const UiMinus = ({
  onClick,
  disabled,
  propagation,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      color={ isTheme.styles.colors.textReadOnly }
      disabled={ disabled }
      propagation={ propagation }
      onClick={ onClick }
      innerRef={ innerRef }
      width="1rem"
      height="1rem"
      viewBox="0 0 16 16"
    >
      <path
        d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16ZM13 8C13 7.44772 12.5523 7 12 7H4C3.44772 7 3 7.44772 3 8C3 8.55229 3.44772 9 4 9H12C12.5523 9 13 8.55229 13 8Z" />
    </Element>
  );
};

UiMinus.propTypes = {
  /** Обработчик события при клике */
  onClick: PropTypes.func,
  /** Компонент неактивен */
  disabled: PropTypes.bool,
  /** Разрешить/запретить всплытие обработчика onClick */
  propagation: PropTypes.bool,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

UiMinus.defaultProps = {
  disabled: false,
  propagation: true
};

export default UiMinus;