import PropTypes from 'prop-types';
import styled from 'styled-components';
import cn from 'classnames';

import { useTheme } from '../../../context/ThemeProvider';

import styles from './UiSimpleButton.module.css';

const StyledButton = styled.button`
  ${ (props) => {
    switch (props.mode) {
      case 'normal':
        return props.styles.typography.DialogButton;
      case 'bold':
        return props.styles.typography.DialogButtonBold;
      default:
        return props.styles.typography.DialogButton;
    }
  } }
  color: ${ (props) => props.styles.colors.textLink };

  &:not(:disabled):hover {
    opacity: 0.4;
  }

  &:disabled {
    color: ${ (props) => props.styles.colors.textDisable };
  }
`;

/**
 * Simple button component
 * @public
 * @version 0.0.90
 * @param {String} className
 * @param {'normal'|'bold'} [mode=normal]
 * @param {String} [title=Button]
 * @param {Boolean} [disabled=false]
 * @param {Function} onClick
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiSimpleButton />
 */
const UiSimpleButton = ({
  className,
  mode,
  title,
  disabled,
  onClick,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <StyledButton
      { ...props }
      className={ cn(styles.button, className) }
      mode={ mode }
      disabled={ disabled }
      onClick={ onClick }
      ref={ innerRef }
      styles={ isTheme.styles }
    >
      { title }
    </StyledButton>
  );
};

UiSimpleButton.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Определяет текст кнопки */
  title: PropTypes.string,
  /** Определяет режим отображения кнопки */
  mode: PropTypes.oneOf(['normal', 'bold']),
  /** Делает компонент неактивным */
  disabled: PropTypes.bool,
  /** Устанавливает обработчик события при нажатии */
  onClick: PropTypes.func,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

UiSimpleButton.defaultProps = {
  title: 'Button',
  mode: 'normal',
  disabled: false
};

export default UiSimpleButton;