import Field from './Field';

const arrayMove = (arr, index, indexTo) => {
  const elem = arr[index];
  arr.splice(index, 1);
  arr.splice(indexTo, 0, elem);
};

const pickupPointFields = [
  { title: { ru: 'Город/населенный пункт', en: 'City' } },
  { title: { ru: 'Улица', en: 'Street' } },
  { title: { ru: 'Дом', en: 'House' } },
  { title: { ru: 'Телефон', en: 'Phone' } },
  { title: { ru: 'График работы', en: 'Working hours' } },
];

class PickupPoint {
  constructor(point = {}) {
    this.title = point.title !== undefined ? point.title : { ru: '', en: '' };
    this.attributes = point.attributes !== undefined ? this.setFields(point.attributes) : this.setFields(pickupPointFields);
  }

  setFields(fields) {
    const fieldsArr = [];
    fields.forEach((field) => {
      fieldsArr.push(new Field(field));
    });
    return fieldsArr;
  }

  setTitle(language, value) {
    this.title[language] = value;
  }

  setAttributeValue(index, language, value) {
    this.attributes[index].setTitle(language, value);
  }

  setAttributeTitle(index, language, value) {
    this.attributes[index].setValue(language, value);
  }

  raisePoint(index) {
    arrayMove(this.attributes, index, index - 1);
  }

  putDownPoint(index) {
    arrayMove(this.attributes, index, index + 1);
  }

  addAttribute() {
    for (let i = 0; i <= pickupPointFields.length; i++) {
      if (this.attributes.findIndex((el) => el.title.en === pickupPointFields[i].title.en) === -1) {
        this.attributes.push(new Field(pickupPointFields[i]));
        break;
      }
    }
  }
}

export default PickupPoint;
