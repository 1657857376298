import React, {useState} from 'react';
import {
  UiCard, UiIcon
} from '@compassplus/ui-mobicash';
import i18n from '../../../../../i18n';
import { Text } from '../../../../../components/TemplateStyle';
import { Input } from '../../../ModifyGroupPage/style';
import Merchant from "../../../../../stores/Merchant";
import {observer} from "mobx-react-lite";
import SuccessLinkSettingsModal from "../../../../../components/Modals/SuccessLinkSettingsModal";

const SuccessOrderLink = observer(() => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  return (
    <>
      <Text
        view="readonly"
        typography="note"
      >
        {i18n.t('SuccessOrderLink')}
      </Text>
      <UiCard style={{ margin: '0.25rem 0 1rem' }} shadow>
        <Input
          description={i18n.t('SuccessOrderLink')}
          readOnly
          placeholder={Merchant.successLink}
          rightElement={(
            <UiIcon
              icon="legacy-edit"
              setColor="#037AFF"
              onClick={() => setIsSettingsOpen(true)}
            />
          )}
        />
      </UiCard>
      <SuccessLinkSettingsModal active={isSettingsOpen} setActive={setIsSettingsOpen} />
    </>
  );
});

export default SuccessOrderLink;
