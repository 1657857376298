import React from 'react';
import OrderStore from "../../../../stores/OrderStore";
import { UiCard, UiDropdown, UiInput, UiSection, useOptions } from "@compassplus/ui-mobicash";
import i18n from "../../../../i18n";
import { ActionSheet } from "../../../admin/MainPage/styles";
import { action } from "mobx";
import { CheckoutDropdown } from "../style";
import {AnimationInput} from "../../../../components/TemplateStyle";

const DeliveryPointSelection = ({ pointSelectionOpen, setPointSelectionOpen, setTypeSelectionOpen }) => {
  const isOptions = useOptions();

  const renderDropdownSection = () => (
    <>
      {OrderStore.selectedDelivery?.addressData.points?.map((point, index) => (
        <UiSection
          key={`${point.title.en}-${index}`}
          title={point.title[isOptions.language]}
          view="link"
          onClick={action(() => { OrderStore.selectedPoint = index; setPointSelectionOpen(false); })}
          bottomDivider={index !== OrderStore.selectedDelivery.addressData.points.length - 1}
        />
      ))}
    </>
  )

  return (
    <>
      {
        (OrderStore.selectedDeliveryType !== -1 && OrderStore.selectedDelivery?.type === 'Pickup') ? (
          <>
            <AnimationInput
              description={i18n.t('SelectIssuingCenter')}
              placeholder={i18n.t('NotDefined')}
              value={OrderStore.selectedPoint !== -1 ? OrderStore.selectedDelivery.addressData.points[OrderStore.selectedPoint].title[isOptions.language] : ''}
              required
              rightElement={<UiDropdown/>}
              onClick={() => { setPointSelectionOpen(!pointSelectionOpen); setTypeSelectionOpen(false); }}
              readOnly
            />
            {
              isOptions.screen === 'mobile' ? (
                <ActionSheet
                  isOpened={pointSelectionOpen}
                  cancelClick={() => { setPointSelectionOpen(false); }}
                  cancelTitle={i18n.t('Cancel')}
                >
                  <UiCard style={{ margin: '0.5rem 0 0' }}>
                    {renderDropdownSection()}
                  </UiCard>
                </ActionSheet>
              ) : (
                <CheckoutDropdown isOpened={pointSelectionOpen}>
                  {renderDropdownSection()}
                </CheckoutDropdown>
              )
            }
          </>
        ) : null
      }
    </>
  )
}

export default DeliveryPointSelection;