import { Fragment, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

/**
 * Портал
 * @public
 * @version 0.0.90
 * @param {*} children
 * @return {React.ReactPortal}
 * @constructor
 * @example
 * <Modal>
 *   { children }
 * </Modal>
 */
const Modal = ({
  children
}) => {

  const [node, setNode] = useState(null);

  useEffect(() => {
    const div = document.createElement('div');

    setNode(div);
    document.body.appendChild(div);
  }, []);

  useEffect(() => {
    return () => {
      if (node !== null) {
        document.body.removeChild(node);
      }
    };
  }, [node]);

  return (
    <Fragment>
      { node !== null &&
        createPortal(children, node)
      }
    </Fragment>
  );
}

export default Modal;