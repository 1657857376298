import React, { useEffect, useReducer, useState } from 'react';
import { UiIcon } from '@compassplus/ui-mobicash';
import DropZone from 'react-dropzone';

import i18n from '../../../i18n';

import {
  DropzoneDiv,
} from '../../../Utils/UtilsStyle';

import {
  ImageCover, Dialog, DialogInput,
} from '../../TemplateStyle';
import {
  AddCard, AddText, DeleteButton, ImageCard, MiniImageCard, ImageSubsection
} from './styles';
import { ColumnButtonsDialog, DialogButton } from '../ImagesUpload/style';
import MinusIcon from "../../icons/MinusIcon";

function ImageUpload(props) {
  i18n.changeLanguage(localStorage.getItem('language'));

  const [openModal, setOpenModal] = useState({ url: false, file: false });

  const {
    image, imageType, changeImage, changeImageType,
  } = props;

  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [imageUpload, setImageUpload] = useState(image);
  const [imageTypeUpload, setImageTypeUpload] = useState(imageType);
  const [isAddImageOpen, setIsAddImageOpen] = useState(false);

  useEffect(() => {
    if (image !== null && image.url !== undefined) {
      setImageUpload(image.url);
    } else {
      setImageUpload(image);
    }
  }, [image]);

  const changeImageUpload = (img, type) => {
    setImageUpload(img);
    setImageTypeUpload(type);
  };

  const onDelete = () => {
    let isOpen = openModal;
    isOpen = { url: false, file: false };
    changeImage(null);
    changeImageType(null);
    changeImageUpload(null, null);
    setOpenModal(isOpen);
  };

  const onOkButton = () => {
    let isOpen = openModal;
    isOpen = { url: false, file: false };
    changeImage(imageUpload);
    changeImageType(imageTypeUpload);
    setOpenModal(isOpen);
  };

  const onDrop = (files) => {
    const file = files[0];
    changeImageUpload(file, file.type);
    changeImage(file);
    changeImageType(file.type);
    forceUpdate();
    setIsAddImageOpen(false);
  };

  return (
    <ImageSubsection title={i18n.t('Image')}>
      <div style={{ display: 'flex' }}>
        <AddCard onClick={() => { setIsAddImageOpen(true); }}>
          <UiIcon icon="legacy-plus" setWidth="3.2rem" setHeight="3.2rem" setColor="#D6D6D6" />
          <AddText>{i18n.t('Add')}</AddText>
        </AddCard>
        {
          (image) && (
            <MiniImageCard>
              <DeleteButton onClick={() => { onDelete(); }}>
                <MinusIcon />
              </DeleteButton>
              <ImageCover
                src={imageType === 'url' ? imageUpload : URL.createObjectURL(imageUpload)}
              />
            </MiniImageCard>
          )
        }
      </div>
      {image && (
        <ImageCard>
          <ImageCover
            src={imageType === 'url' ? imageUpload : URL.createObjectURL(imageUpload)}
          />
        </ImageCard>
      )}
      {
        isAddImageOpen && (
          <ColumnButtonsDialog
            title={i18n.t('AddImage')}
            text={i18n.t('AddImageLink')}
            buttons={[
              {
                text: i18n.t('AddLink'),
                mode: '',
                action: () => { setOpenModal({ url: true, file: false }); setIsAddImageOpen(false); },
              },
              {
                text: i18n.t('Cancel'),
                mode: 'bold',
                action: () => { setIsAddImageOpen(false); },
              },
            ]}
          >
            <DropZone multiple={false} maxSize={8000000000} onDrop={onDrop} accept="image/*">
              {({ getRootProps, getInputProps }) => (
                <DropzoneDiv {...getRootProps()}>
                  <input {...getInputProps()} />
                  <DialogButton>
                    {i18n.t('AddFile')}
                  </DialogButton>
                </DropzoneDiv>
              )}
            </DropZone>
          </ColumnButtonsDialog>
        )
      }
      {
        (openModal.url && (
          <Dialog
            title={i18n.t('AddImage')}
            text={i18n.t('AddImageLink')}
            buttons={[
              {
                text: i18n.t('Add'),
                mode: 'bold',
                action: () => { onOkButton(); },
              },
              {
                text: i18n.t('Cancel'),
                mode: '',
                action: () => { setOpenModal({ url: false, file: false }); },
              },
            ]}
          >
            <DialogInput onChange={(event) => changeImageUpload(event.currentTarget.value, imageTypeUpload)} value={imageUpload} />
          </Dialog>
        ))
      }
    </ImageSubsection>
  );
}

export default ImageUpload;
