export const icons = [
  {
    'icon': 'language',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'auto-logout',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'scale',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'theme',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'sound',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'regions',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'help-me-pay',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'mobile-phone',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'finger-print',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'biometric',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'touch-guard',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'privacy-policy',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'FAQ',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'about',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'TC',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'send-email',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'contact-us',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'cars',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'phones',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'email',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'send-bills',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'addresses',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'registration-data',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'biometric-2',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'launch-at-start',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'back',
    'viewBox': '0 0 28 28'
  },
  {
    'icon': 'menu',
    'viewBox': '0 0 28 28'
  },
  {
    'icon': 'apply',
    'viewBox': '0 0 28 28'
  },
  {
    'icon': 'cancel',
    'viewBox': '0 0 28 28'
  },
  {
    'icon': 'settings',
    'viewBox': '0 0 28 28'
  },
  {
    'icon': 'account-actions',
    'viewBox': '0 0 28 28'
  },
  {
    'icon': 'back-desktop',
    'viewBox': '0 0 28 28'
  },
  {
    'icon': 'homepage',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'unsubscribe',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'delete',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'change-number',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'mempad',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'digipad',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'security-questions',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'log-out',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-sign-in',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'callback',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'remove-PM',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'deactivate',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'account',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legal',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'payment-methods',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'bills',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'security',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'support',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'users',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'pay-the-bill',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'faceID',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'merchant',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'merchant-services',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'wallet',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'loyalty',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'security-code',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'pay-with-touchID',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': '3D-secure',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'avatar',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'QR-code',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'find',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'backspace',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'cart',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'address-book',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'empty-logo',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'empty-photo',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'tag',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'link',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'biopay',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'mobicash',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'geo',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'cashier',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'mail',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'merchat-homepage',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'phone',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'push',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'qrcode',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'by-courier',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'by-email',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'by-mail',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'customer-location',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'internet-service',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'merchant-location',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'provider-infrustructure',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-cashier',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-money',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-personal-documents',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-regions',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-gallery',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-tour',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-order',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-other',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-cancel',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-register-merchant',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-how-get-prizes',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-get-fiscal-data',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-devices',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-dispute-chat',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-documents',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-notifications',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-news',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-marketplace',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-bill-finder2',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-certificates',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-cars',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-start-dispute',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-schedule-cancel',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-share',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-to-the-top',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-reload',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-country',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-preferences',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-prizes',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-send-query',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-send-message',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-schedule',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-pending',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-phones',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-account-events',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-apply',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-bill-finder',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-reset',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-reports',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-recurrent-payment',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-repeat',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-guest-checkout',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-favorite-add',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-move-to-bills',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-plus',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-callendar',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-event-log',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-edit',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-advertisment',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-add-card',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-safe-deposit',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-ATM',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-bank',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-print',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-totals',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-cloud',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'legacy-terminal',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'template',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'folder',
    'viewBox': '0 0 24 24'
  },
  {
    'icon': 'none',
    'viewBox': '0 0 32 32'
  },
  {
    'icon': 'photo',
    'viewBox': '0 0 24 24'
  },
];

