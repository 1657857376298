import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styled, { css } from 'styled-components';
import { isNotEmptyObject } from '@compassplus/js-utils';

import { useTheme } from '../../../context/ThemeProvider';

import Panel from './Components/Panel';
import Keyboard from './Components/Keyboard';
import Action from './Components/Action';

import { useForkRef } from '../../../hooks';

import { isFunction } from '../../../utils/typeUtils';

import styles from './UiKeyboard.module.css';

const StyledKeyboard = styled.div`
  ${ (props) => css`
    background: ${ props.styles.colors.keyboardBackgroundColor };
    box-shadow: ${ props.styles.shadows.keyboard };
  ` }
`;

/**
 * Keyboard component
 * @public
 * @version 0.0.90
 * @param {String} [className]
 * @param {string} [comment]
 * @param {'number'|'pin'|'memPad'} [type='number']
 * @param {Function} [onCancel]
 * @param {String} [value='']
 * @param {Number} [maxLength]
 * @param {Object} [button]
 * @param {Object} [keyboardProps]
 * @param {*} children
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiKeyboard />
 */
const UiKeyboard = ({
  className,
  comment,
  type,
  onCancel,
  value,
  maxLength,
  button,
  keyboardProps,
  children,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  const containerRef = useRef(null);
  const refs = useForkRef([innerRef, containerRef]);

  useEffect(() => {
    if (isNotEmptyObject(button)) {
      const { active = false, action } = button;
      const keyUpHandler = (event) => {
        if (event.code === 'Enter' || event.code === 'NumpadEnter') {
          if (isFunction(action)) {
            action(event);
          }
        }
      };

      if (active) {
        document.addEventListener('keyup', keyUpHandler);

        return () => {
          document.removeEventListener('keyup', keyUpHandler);
        };
      }
    }
  }, [button]);

  return (
    <div
      { ...props }
      className={ cn(styles.container, className) }
      ref={ refs }
    >
      { children }
      <div className={ styles.content }>
        <StyledKeyboard className={ styles.keyboard } styles={ isTheme.styles }>
          <Panel
            comment={ comment }
            value={ value }
            maxLength={ maxLength }
            onCancel={ onCancel }
          />
          <Keyboard type={ type } value={ value } maxLength={ maxLength } keyboardProps={ keyboardProps } />
        </StyledKeyboard>
        { isNotEmptyObject(button) &&
          <Action { ...button } type={ type } />
        }
      </div>
    </div>
  );
};

UiKeyboard.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Комментарий на панели клавиатуры */
  comment: PropTypes.string,
  /** Вид клавиатуры */
  type: PropTypes.oneOf(['number', 'pin', 'memPad']),
  /** Обработчик закрытия клавиатуры */
  onCancel: PropTypes.func,
  /** Значение */
  value: PropTypes.string,
  /** Максимальное количество символов, которое может ввести пользователь */
  maxLength: PropTypes.number,
  /** Набор пропов кнопки взаимодействия (компонент UiAction) */
  button: PropTypes.object,
  /** Набор пропов клавиатуры (компоненты UiNumPad, UiDigiPad, UiMemPad) */
  keyboardProps: PropTypes.object,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

UiKeyboard.defaultProps = {
  comment: 'Short comment',
  type: 'number',
  value: ''
};

export default UiKeyboard;