import { createRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import './UiDropDownContainerItem.css';

/**
 * Компонент элемента раскрывающегося контейнера
 * @public
 * @version 1.9.0
 * @param {*} children
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiDropDownContainer.Item>
 *   {контент}
 * </UiDropDownContainer.Item>
 */
const UiDropDownContainerItem = ({
  children,
  ...props
}) => {
  const [height, setHeight] = useState(undefined);
  const nodeRef = createRef(null);

  const onEnter = useCallback(() => {
    setHeight(0);
  }, []);

  const onEntering = useCallback(() => {
    nodeRef.current && setHeight(nodeRef.current.scrollHeight);
  }, [nodeRef]);

  const onEntered = useCallback(() => {
    setHeight(undefined);
  }, []);

  const onExit = useCallback(() => {
    nodeRef.current && setHeight(nodeRef.current.scrollHeight);
  }, [nodeRef]);

  const onExiting = useCallback(() => {
    setHeight(0);
  }, []);

  return (
    <CSSTransition
      {...props}
      nodeRef={nodeRef}
      timeout={100}
      classNames="ui-drop-down-container--item"
      onEnter={onEnter}
      onEntering={onEntering}
      onEntered={onEntered}
      onExit={onExit}
      onExiting={onExiting}
    >
      <div
        className="ui-drop-down-container--item"
        ref={nodeRef}
        style={{ height }}
      >
        {children}
      </div>
    </CSSTransition>
  );
};

UiDropDownContainerItem.propTypes = {
  /** Дочерние элементы */
  children: PropTypes.any
};

export default UiDropDownContainerItem;