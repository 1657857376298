import React, { useReducer } from 'react';
import {
  UiCard, UiSection, UiSwitch, UiCheckbox,
} from '@compassplus/ui-mobicash';
import { observer } from 'mobx-react-lite';
import i18n from '../../../../i18n';

const DaysOfWeek = observer(({ attribute }) => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  return (
    <>
      <UiCard shadow style={{ margin: '0.25rem 0' }}>
        <UiSection
          title={i18n.t('DaysOfTheWeek')}
          rightElement={(
            <UiSwitch
              checked={attribute.status}
              onClick={() => { attribute.status = !attribute.status; forceUpdate(); }}
            />
          )}
          description={attribute.status ? i18n.t('SelectDesiredDaysOfWeek') : null}
          bottomDivider={attribute.status}
        />
        {
          attribute.status ? attribute.days.map((day, index) => (
            <UiSection
              key={day.title}
              onClick={() => {
                day.checked = !day.checked;
                forceUpdate();
              }}
              leftElement={(
                <UiCheckbox
                  checked={day.checked}
                  onClick={() => {
                    day.checked = !day.checked;
                    forceUpdate();
                  }}
                />
              )}
              title={i18n.t(day.title)}
              bottomDivider={index !== attribute.days.length - 1}
            />
          )) : null
        }
      </UiCard>
    </>
  );
});

export default DaysOfWeek;
