import Slide from './Slide';

import './SlideList.css';

const SlideList = ({
  items,
  slide
}) => {

  return (
    <div
      className="ui-slider--slide-list"
      style={{ transform: `translateX(-${ slide * 100 }%)` }}
    >
      {items.map((item, index) => {
        return (
          <Slide key={ index } slide={ item } />
        );
      })}
    </div>
  );
};

export default SlideList;