import React from 'react';
import { useOptions } from '@compassplus/ui-mobicash';
import { BodyDiv, ContentDiv } from '../TemplateStyle';
import MarketSideMenu from '../market/MarketSideMenu';
import MarketHeader from '../market/MarketHeader';

const MarketPageContainer = (props) => {
  const isOptions = useOptions();
  const getBodyDivStyle = () => {
    if (isOptions.screen === 'mobile') {
      return { background: '#FBFBFB' };
    }
    return { background: '#FBFBFB', padding: '4rem 0 0' };
  };

  return (
    <>
      <MarketHeader />
      <MarketSideMenu />
      <BodyDiv style={getBodyDivStyle()}>
        <ContentDiv>
          {props.children}
        </ContentDiv>
      </BodyDiv>
    </>
  );
};

export default MarketPageContainer;
