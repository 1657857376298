import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Element component
 * @public
 * @version 0.0.90
 * @param {Function} onClick
 * @param {Boolean} [disabled=false]
 * @param {Boolean} [propagation=true]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiCross />
 */
const UiCross = ({
  onClick,
  disabled,
  propagation,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      color={ isTheme.styles.colors.warningColor }
      disabled={ disabled }
      propagation={ propagation }
      onClick={ onClick }
      innerRef={ innerRef }
      width="1rem"
      height="1rem"
      viewBox="0 0 16 16"
    >
      <path d="M9.48711 8L14.692 13.2049C15.1027 13.6156 15.1027 14.2814 14.692 14.692C14.2814 15.1027 13.6155 15.1027 13.2049 14.692L8 9.48711L2.7951 14.692C2.38445 15.1027 1.71865 15.1027 1.30799 14.692C0.897336 14.2814 0.897337 13.6156 1.30799 13.2049L6.51289 8L1.30799 2.7951C0.897336 2.38445 0.897336 1.71865 1.30799 1.30799C1.71865 0.897336 2.38445 0.897336 2.7951 1.30799L8 6.51289L13.2049 1.30799C13.6156 0.897336 14.2814 0.897336 14.692 1.30799C15.1027 1.71865 15.1027 2.38445 14.692 2.7951L9.48711 8Z" />
    </Element>
  );
};

UiCross.propTypes = {
  /** Определяет обработчик события при нажатии */
  onClick: PropTypes.func,
  /** Делает компонент неактивным */
  disabled: PropTypes.bool,
  /** Разрешить/запретить всплытие обработчика onClick */
  propagation: PropTypes.bool,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

UiCross.defaultProps = {
  disabled: false,
  propagation: true
};

export default UiCross;