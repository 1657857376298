import React from 'react';
import { UiIcon, useOptions } from '@compassplus/ui-mobicash';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react-lite';
import i18n from '../../../i18n';
import GlobalStateStore from '../../../stores/GlobalStateStore';
import ModalStore from '../../../stores/ModalStore';
import { getAuth } from '../../../Utils/requestUtil';
import { ActiveHeader } from './style';

const Header = observer(() => {
  const isOptions = useOptions();
  const history = useHistory();

  const renderLeftIcon = () => {
    if (isOptions.screen === 'mobile') {
      return (
        <UiIcon icon="menu" view="brand" onClick={() => { GlobalStateStore.isSidebarOpen = true; }} />
      );
    }

    // desktop
    if (GlobalStateStore.headerIsBack) {
      return (
        <UiIcon
          icon="back"
          view="brand"
          onClick={() => {
            if (GlobalStateStore.headerOnBack) {
              GlobalStateStore.headerOnBack();
            } else {
              history.goBack();
            }
          }}
        />
      );
    }

    return (
      <UiIcon
        icon="settings"
        view="brand"
        onClick={() => { history.push('/settings'); }}
      />
    );
  };

  return (
    <ActiveHeader
      text={i18n.t(GlobalStateStore.headerTitle)}
      help={GlobalStateStore.headerHelp}
      left={renderLeftIcon()}
      className={GlobalStateStore.headerIsActive ? 'header-active' : 'header'}
      right={GlobalStateStore.headerRenderRightElement ? GlobalStateStore.headerRenderRightElement() : (
        <UiIcon
          icon="account-actions"
          view="brand"
          onClick={() => {
            if (getAuth()) {
              ModalStore.openLogoutModal();
            }
          }}
        />
      )}
    >
      { GlobalStateStore.headerContent && GlobalStateStore.headerContent() }
    </ActiveHeader>
  );
});

export default Header;
