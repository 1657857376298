import React from 'react';
import styled from 'styled-components';
import { UiCardSection } from '@compassplus/ui-mobicash';
import { LoadingTitle } from './style';

const StyledCardSection = styled(UiCardSection)`
  & > div:first-child > span {
    font-size: 0.75rem;
  }
`;

const LoadingInput = ({ title, bottomDivider }) => (
  <StyledCardSection
    title={title}
    description={<LoadingTitle />}
    bottomDivider={bottomDivider || false}
  />
);

export default LoadingInput;
