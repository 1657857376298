import React from 'react';
import OrderStore from "../../../../stores/OrderStore";
import ShoppingCart from "../../../../stores/ShoppingCartStore";
import i18n from "../../../../i18n";
import { getCurrency } from "../../../../Utils/requestUtil";
import { UiButton, UiCard, UiInput, useOptions } from "@compassplus/ui-mobicash";
import { observer } from "mobx-react-lite";
import {AnimationDiv, Text} from "../../../../components/TemplateStyle";

const PaymentDetails = observer(({ setCreateBillOpenDialog }) => {
  const isOptions = useOptions();

  const getTotalCost = () => {
    return (Number(ShoppingCart.totalCost) + Number(OrderStore.deliveryCostObj.dependence.deliveryPrice)).toFixed(2)
  }

  if ((OrderStore.deliverySettingsArr && OrderStore.deliverySettingsArr.length === 0)
    || (OrderStore.selectedDeliveryType !== -1 && OrderStore.selectedDelivery.type !== 'Pickup')
    || (OrderStore.selectedDeliveryType !== -1 && OrderStore.selectedPoint !== -1)) {
    return (
      <AnimationDiv style={{ margin: '1rem 0 0' }}>
        <Text
          style={{textTransform: 'uppercase'}}
          view="readonly"
          typography="section-description-semibold"
        >
          {i18n.t('CostOfOrder')}
        </Text>
        <UiCard shadow style={{margin: '0.5rem 0'}}>
          {
            (OrderStore.selectedDeliveryType !== -1 && (OrderStore.selectedDelivery?.type === 'Post' || OrderStore.selectedDelivery?.type === 'Courier' ||
              OrderStore.selectedDelivery?.type === 'TransportCompany' || OrderStore.selectedDelivery?.type === 'Custom'))
              ? (
                <>
                  <UiInput
                    description={i18n.t('OrderAmount')}
                    value={`${ShoppingCart.totalCost} ${i18n.t(getCurrency())}`}
                    readOnly
                    bottomDivider
                  />
                  <UiInput
                    description={i18n.t('Delivery')}
                    value={OrderStore.deliveryCostObj?.message || i18n.t('Free')}
                    readOnly
                    bottomDivider={OrderStore.costMessage.index === -1}
                  />
                  {
                    OrderStore.costMessage.index !== -1 && (
                      <Text
                        style={{
                          margin: '0 0 0 0.5rem',
                          borderBottom: '0.063rem solid #00000033',
                          width: 'calc(100% - 5px)'
                        }}
                        view="readonly"
                        typography="note"
                        bottomDivider
                      >
                        {OrderStore.costMessage.message}
                      </Text>
                    )
                  }
                </>
              ) : null
          }
          <UiInput
            description={i18n.t('TOTAL')}
            value={`${getTotalCost()} ${i18n.t(getCurrency())}`}
            readOnly
          />
        </UiCard>
        <UiButton
          style={{ margin: '0 0 0.5rem' }}
          title={i18n.t('IssueInvoice')}
          onClick={() => {
            if (OrderStore.checkRequiredFields(isOptions.language)) {
              setCreateBillOpenDialog(true);
            }
          }}
        />
      </AnimationDiv>
    )
  } else {
    return null;
  }
});

export default PaymentDetails;