import { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import LeftBankCardOld from './Components/LeftBankCardOld';
import RightBankCardOld from './Components/RightBankCardOld';

import { useForkRef } from '../../../hooks';

import styles from './UiBankCardOld.module.css';

/**
 * Компонент банковской карты (старый)
 * @public
 * @version 0.0.109
 * @param {String} [className]
 * @param {Boolean} [disabled=false]
 * @param {Object} [leftBankCard={}]
 * @param {String} [leftBankCard.srcBackground]
 * @param {Object} [leftBankCard.logo={}]
 * @param {String} [leftBankCard.logo.src]
 * @param {String} [leftBankCard.logo.alt]
 * @param {Object} [leftBankCard.ips={}]
 * @param {String} [leftBankCard.ips.src]
 * @param {String} [leftBankCard.ips.alt]
 * @param {Object} [rightBankCard={}]
 * @param {String} [rightBankCard.srcBackground]
 * @param {Object} [pan={}]
 * @param {Object} [expDate={}]
 * @param {Boolean} [showCvv=true]
 * @param {Object} [cvv={}]
 * @param {Boolean} [showCardholder=false]
 * @param {Object} [cardholder={}]
 * @param innerRef
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * import { useState } from 'react';
 *
 * import { UiBankCardOld } from '@compassplus/ui-mobicash';
 *
 * const App = () => {
 *
 *   const [pan, setPan] = useState('9999 9999 9999 9999');
 *
 *   return (
 *     <UiBankCardOld pan={ { disabled: true, value: pan } } />
 *   );
 * };
 *
 * export default UiBankCardOld; */
const UiBankCardOld = ({
  className,
  disabled,
  leftBankCard,
  rightBankCard,
  pan,
  expDate,
  showCvv,
  cvv,
  showCardholder,
  cardholder,
  innerRef,
  ...props
}) => {

  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const refs = useForkRef([innerRef, containerRef]);

  const handlerResize = useCallback(() => {
    if (containerRef.current && contentRef.current) {
      if (contentRef.current.clientWidth > containerRef.current.clientWidth) {
        contentRef.current.style.transform = `scale(${ containerRef.current.clientWidth / contentRef.current.clientWidth })`;
      } else if (contentRef.current.style.transform !== 'scale(1)') {
        contentRef.current.style.transform = 'scale(1)';
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handlerResize);

    handlerResize();

    return () => {
      window.removeEventListener('resize', handlerResize);
    };
  }, [handlerResize]);

  return (
    <div { ...props } className={ cn(styles.container, className) } ref={ refs }>
      <div className={ styles.content } ref={ contentRef }>
        <LeftBankCardOld
          className={ styles.leftCard }
          disabled={ disabled }
          bankCard={ leftBankCard }
          pan={ pan }
          expDate={ expDate }
          showCardholder={ showCardholder }
          cardholder={ cardholder }
        />
        <RightBankCardOld
          className={ styles.rightCard }
          disabled={ disabled }
          bankCard={ rightBankCard }
          showCvv={ showCvv }
          cvv={ cvv }
        />
      </div>
    </div>
  );
};

UiBankCardOld.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Блокирует доступ и изменение поля формы */
  disabled: PropTypes.bool,
  /** Пропы визуала левой карты */
  leftBankCard: PropTypes.shape({
    srcBackground: PropTypes.string,
    logo: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string
    }),
    ips: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string
    })
  }),
  /** Пропы визуала правой карты */
  rightBankCard: PropTypes.shape({
    srcBackground: PropTypes.string
  }),
  /** Набор пропов поля "PAN" (см. пропы UiInput) */
  pan: PropTypes.object,
  /** Набор пропов поля "expiration date" (см. пропы UiInput) */
  expDate: PropTypes.object,
  /** Отобразить поле "CVV" */
  showCvv: PropTypes.bool,
  /** Набор пропов поля "CVV" (см. пропы UiInput) */
  cvv: PropTypes.object,
  /** Отобразить поле "cardholder" */
  showCardholder: PropTypes.bool,
  /** Набор пропов поля "cardholder" (см. пропы UiInput) */
  cardholder: PropTypes.object,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

UiBankCardOld.defaultProps = {
  disabled: false,
  leftBankCard: {},
  rightBankCard: {},
  pan: {
    disabled: false,
    placeholder: 'Card number'
  },
  expDate: {
    disabled: false,
    placeholder: 'MM/YY'
  },
  showCvv: true,
  cvv: {
    disabled: false,
    placeholder: '999'
  },
  showCardholder: false,
  cardholder: {
    disabled: false,
    placeholder: 'Cardholder'
  }
};

export default UiBankCardOld;