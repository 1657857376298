import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Plate bill finder icon
 * @public
 * @version 1.3.0
 * @param {String} width
 * @param {String} height
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiBillFinderIcon />
 */
const UiBillFinderIcon = ({
  width,
  height,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      innerRef={ innerRef }
      width={ width }
      height={ height }
      viewBox="0 0 52 52"
    >
      <path fillRule="evenodd" clipRule="evenodd"
        d="M18.8379 18.6731C18.8379 16.3131 18.8879 14.0291 18.8159 11.7511C18.7829 10.7391 17.9549 10.1111 16.9289 10.1041C15.3459 10.0921 13.7629 10.0921 12.1789 10.1041C11.0479 10.1111 10.2929 10.8771 10.2859 12.0221C10.2749 13.5821 10.2759 15.1441 10.2859 16.7051C10.2929 17.8221 10.9859 18.6361 12.0899 18.6591C14.3079 18.7061 16.5279 18.6731 18.8379 18.6731ZM20.5619 15.5211C20.5629 16.9291 20.5469 18.3381 20.5709 19.7461C20.5799 20.2351 20.4169 20.3941 19.9249 20.3901C17.1519 20.3721 14.3789 20.3951 11.6069 20.3781C9.76692 20.3671 8.58192 19.1411 8.57792 17.2871C8.57392 15.3061 8.56992 13.3251 8.57892 11.3451C8.58792 9.60907 9.79492 8.40307 11.5299 8.39607C13.5329 8.38807 15.5349 8.38907 17.5379 8.39607C19.3369 8.40207 20.5419 9.61007 20.5589 11.4271C20.5709 12.7921 20.5619 14.1561 20.5619 15.5211Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M34.3125 18.679C36.6405 18.679 38.8545 18.709 41.0675 18.666C42.1565 18.645 42.8635 17.825 42.8695 16.717C42.8775 15.181 42.8765 13.646 42.8695 12.111C42.8625 10.884 42.1245 10.135 40.9135 10.128C39.3775 10.119 37.8415 10.12 36.3065 10.127C35.1135 10.133 34.3415 10.896 34.3165 12.101C34.2985 12.934 34.3125 13.768 34.3125 14.602V18.679ZM32.6165 15.551C32.6165 14.211 32.6105 12.871 32.6185 11.533C32.6285 9.59499 33.8095 8.42599 35.7475 8.42399C37.6585 8.42199 39.5685 8.42199 41.4795 8.42399C43.3915 8.42599 44.5685 9.58999 44.5725 11.484C44.5765 13.438 44.5765 15.392 44.5725 17.347C44.5685 19.125 43.4095 20.356 41.6325 20.375C38.8225 20.408 36.0105 20.376 33.2005 20.39C32.7355 20.392 32.5995 20.219 32.6075 19.767C32.6315 18.362 32.6165 16.956 32.6165 15.551Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M37.3223 16.7825H39.8613C40.4723 16.7825 40.9683 16.2875 40.9683 15.6765V13.1365C40.9683 12.5255 40.4723 12.0305 39.8613 12.0305H37.3223C36.7113 12.0305 36.2163 12.5255 36.2163 13.1365V15.6765C36.2163 16.2875 36.7113 16.7825 37.3223 16.7825Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M13.2481 16.8743H15.8861C16.5191 16.8743 17.0341 16.3593 17.0341 15.7253V13.0883C17.0341 12.4533 16.5191 11.9383 15.8861 11.9383H13.2481C12.6131 11.9383 12.0991 12.4533 12.0991 13.0883V15.7253C12.0991 16.3593 12.6131 16.8743 13.2481 16.8743Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M12.1318 33.2014C11.0278 33.2244 10.3348 34.0384 10.3278 35.1554C10.3188 36.7174 10.3178 38.2784 10.3278 39.8404C10.3348 40.9834 11.0898 41.7494 12.2218 41.7574C13.8048 41.7684 15.3878 41.7684 16.9718 41.7574C17.9968 41.7494 18.8248 41.1214 18.8578 40.1094C18.9308 37.8314 18.8808 35.5484 18.8808 33.1874C16.5698 33.1874 14.3498 33.1544 12.1318 33.2014ZM20.6018 40.4334C20.5848 42.2514 19.3798 43.4584 17.5798 43.4654C15.5778 43.4724 13.5748 43.4724 11.5718 43.4654C9.83675 43.4584 8.62975 42.2524 8.62175 40.5154C8.61175 38.5354 8.61575 36.5554 8.61975 34.5744C8.62375 32.7204 9.80875 31.4934 11.6488 31.4824C14.4208 31.4664 17.1938 31.4894 19.9678 31.4714C20.4588 31.4674 20.6218 31.6254 20.6128 32.1144C20.5888 33.5224 20.6048 34.9314 20.6048 36.3404C20.6038 37.7044 20.6128 39.0694 20.6018 40.4334Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M13.2901 34.9866H15.9281C16.5621 34.9866 17.0761 35.5016 17.0761 36.1356V38.7726C17.0761 39.4076 16.5621 39.9226 15.9281 39.9226H13.2901C12.6551 39.9226 12.1421 39.4076 12.1421 38.7726V36.1356C12.1421 35.5016 12.6551 34.9866 13.2901 34.9866Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M21.7578 25.3899H21.5558H20.2978H19.8948H18.3228H16.3488V23.3899H18.3228H18.5908V21.3899H18.3228H16.7288H16.3488H14.3748V23.3899H12.5048V21.3899H10.5298H8.55683V23.3899H10.4298V25.3899H12.4038V27.3909H10.4298H8.45483V29.3899H10.4298H12.4038H14.3778V27.3909H16.3488H18.3228H20.2978H21.5558V27.3899H21.7578V25.3899Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd" d="M38.585 27.39H40.586V25.39H38.585V27.39Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd" d="M38.585 29.39H40.586V27.39H38.585V29.39Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd" d="M42.5859 25.39H44.5859V23.39H42.5859V25.39Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd" d="M36.5911 25.39H38.5911V23.39H36.5911V25.39Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd" d="M34.5911 25.39H36.5911V23.39H34.5911V25.39Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd" d="M34.5781 23.39H36.5791V21.39H34.5781V23.39Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd" d="M34.5911 27.39H36.5911V25.39H34.5911V27.39Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd" d="M40.5859 23.39H42.5859V21.39H40.5859V23.39Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd" d="M34.5791 31.39H36.5791V29.39H34.5791V31.39Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd" d="M36.5791 29.391H38.5791V27.391H36.5791V29.391Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd" d="M40.5859 29.39H42.5859V27.39H40.5859V29.39Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd" d="M38.585 31.39H40.586V29.39H38.585V31.39Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd" d="M40.5859 31.39H42.5859V29.39H40.5859V31.39Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd" d="M23.092 22.23H24.819V20.504H23.092V22.23Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M31.6416 12.3909V10.3899V8.38989H29.6416H27.6416H25.6416V10.3899H27.6416H29.6406V12.3909H27.6416H25.6416V14.3909H27.6416H29.6416V16.3909V18.3899H31.6416V16.3909V14.3909V12.3909Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M27.6416 10.3909H25.6416H23.6416V12.3899H25.6416H27.6416V10.3909Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M21.6416 14.3909V16.3909H23.6416V18.3899H25.6416V16.3909V14.3909H23.6416H21.6416Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M22.47 21.427C22.024 21.427 21.655 21.796 21.655 22.242V30.505C21.655 30.951 22.024 31.32 22.47 31.32H30.734C31.179 31.32 31.549 30.951 31.549 30.505V22.242C31.549 21.796 31.179 21.427 30.734 21.427H22.47Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd" d="M23.2849 28.385L26.6019 28.382L24.9439 25.531L23.2849 28.385Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd" d="M26.574 28.385H29.89L28.232 25.531L26.574 28.385Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd" d="M24.9431 24.363L26.6021 27.216L28.2601 24.363H24.9431Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <mask id="mask0_82_13758" style={ { maskType: 'alpha' } } maskUnits="userSpaceOnUse" x="24" y="24" width="5"
        height="4">
        <path fillRule="evenodd" clipRule="evenodd" d="M24.9431 24.363L26.6021 27.216L28.2601 24.363H24.9431Z"
          fill={ isTheme.styles.colors.sectionBackground } />
      </mask>
      <g mask="url(#mask0_82_13758)">
      </g>
      <path fillRule="evenodd" clipRule="evenodd"
        d="M3.98795 18C3.44161 18 3 17.5584 3 17.0121V3.98795C3 3.4426 3.44161 3 3.98795 3H17.0121C17.5574 3 18 3.4426 18 3.98795C18 4.53428 17.5574 4.97589 17.0121 4.97589H4.97589V17.0121C4.97589 17.5584 4.53428 18 3.98795 18Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M48.0121 18C47.4657 18 47.0241 17.5584 47.0241 17.0121V4.97589H34.9879C34.4426 4.97589 34 4.53428 34 3.98795C34 3.4426 34.4426 3 34.9879 3H48.0121C48.5584 3 49 3.4426 49 3.98795V17.0121C49 17.5584 48.5584 18 48.0121 18Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M17.0116 49H3.98746C3.44211 49 2.99951 48.5584 2.99951 48.0121V34.9879C2.99951 34.4426 3.44211 34 3.98746 34C4.53379 34 4.97541 34.4426 4.97541 34.9879V47.0242H17.0116C17.5579 47.0242 17.9995 47.4658 17.9995 48.0121C17.9995 48.5584 17.5579 49 17.0116 49Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M25.5911 46.953H46.5911V36.01H25.5911V46.953ZM47.5911 48.953H24.5911C24.0381 48.953 23.5911 48.506 23.5911 47.953V35.01C23.5911 34.457 24.0381 34.01 24.5911 34.01H47.5911C48.1441 34.01 48.5911 34.457 48.5911 35.01V47.953C48.5911 48.506 48.1441 48.953 47.5911 48.953Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M30.5811 38.4133C30.6021 38.2593 30.5781 38.0973 30.4941 37.9513C30.2941 37.6043 29.8511 37.4853 29.5051 37.6853L27.7581 38.6933C27.4121 38.8933 27.2931 39.3373 27.4931 39.6843C27.6271 39.9163 27.8711 40.0463 28.1211 40.0463C28.2441 40.0463 28.3681 40.0153 28.4821 39.9483L29.1431 39.5673V44.1873C29.1431 44.5883 29.4671 44.9123 29.8661 44.9123C30.2671 44.9123 30.5911 44.5883 30.5911 44.1873V38.5133C30.5911 38.4783 30.5861 38.4463 30.5811 38.4133Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M35.876 38.4124C35.897 38.2584 35.873 38.0964 35.789 37.9504C35.589 37.6034 35.146 37.4844 34.799 37.6844L33.053 38.6934C32.707 38.8934 32.588 39.3364 32.788 39.6834C32.922 39.9154 33.166 40.0454 33.416 40.0454C33.539 40.0454 33.663 40.0144 33.777 39.9484L34.437 39.5674V44.1864C34.437 44.5874 34.761 44.9114 35.161 44.9114C35.562 44.9114 35.886 44.5874 35.886 44.1864V38.5124C35.886 38.4774 35.88 38.4454 35.876 38.4124Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M42.6533 44.9709H39.5283C39.2523 44.9709 39.0283 44.7469 39.0283 44.4709C39.0283 44.1949 39.2523 43.9709 39.5283 43.9709H42.6533C42.9293 43.9709 43.1533 44.1949 43.1533 44.4709C43.1533 44.7469 42.9293 44.9709 42.6533 44.9709Z"
        fill={ isTheme.styles.colors.textTitle } />
    </Element>
  );
};

UiBillFinderIcon.propTypes = {
  /** Определяет ширину иконки */
  width: PropTypes.string,
  /** Определяет высоту иконки */
  height: PropTypes.string
};

UiBillFinderIcon.defaultProps = {
  width: '3.25rem',
  height: '3.25rem'
};

export default UiBillFinderIcon;