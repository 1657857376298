import { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styled, { css } from 'styled-components';

import SubsectionHeader from './SubsectionHeader';
import SubsectionContainer from './SubsectionContainer';

import styles from './UiSubsection.module.css';

const StyledContainer = styled.div`
  ${ (props) => props.relativity && css`
    position: relative;
  ` }
`;

/**
 * Subsection component
 * @public
 * @version 0.0.90
 * @param {String} className
 * @param {String} [title=Subsection]
 * @param {JSX.Element} badge
 * @param {Boolean} [help=false]
 * @param {Function} onHelp
 * @param {Boolean} [bottomDivider=false]
 * @param {Boolean} [state=true]
 * @param {Boolean} [expand=true]
 * @param {Function} onExpand
 * @param {Boolean} relativity
 * @param {*} children
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiSubsection>{ children }</UiSubsection>
 */
const UiSubsection = ({
  className,
  title,
  badge,
  help,
  onHelp,
  bottomDivider,
  state,
  expand,
  onExpand,
  relativity,
  children,
  innerRef,
  ...props
}) => {

  const [active, setActive] = useState(state);

  return (
    <StyledContainer { ...props } className={ cn(styles.subsection, className) } relativity={ relativity } ref={ innerRef }>
      <SubsectionHeader
        title={ title }
        badge={ badge }
        help={ help }
        onHelp={ onHelp }
        bottomDivider={ bottomDivider }
        active={ active }
        setActive={ setActive }
        expand={ expand }
        onExpand={ onExpand }
      />
      <SubsectionContainer
        active={ active }
      >
        { children }
      </SubsectionContainer>
    </StyledContainer>
  );
};

UiSubsection.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Текстовая надпись подраздела */
  title: PropTypes.string,
  /** Бейдж в левой части подраздела */
  badge: PropTypes.element,
  /** Флаг отображения кнопки помощи */
  help: PropTypes.bool,
  /** Функция присваиваемая кнопки помощи */
  onHelp: PropTypes.func,
  /** Нижняя полоса "разделитель" */
  bottomDivider: PropTypes.bool,
  /** Состояние открытия/закрытия подраздела */
  state: PropTypes.bool,
  /** Состояние отображения/скрытия кнопки раскрытия подраздела */
  expand: PropTypes.bool,
  /** Пользовательская функция обработчик нажатия по заголовку подраздела */
  onExpand: PropTypes.func,
  /** Определяет позиционирование относительно абсолютных компонентов */
  relativity: PropTypes.bool,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  /** Контент подраздела */
  children: PropTypes.any
};

UiSubsection.defaultProps = {
  title: 'Subsection',
  help: false,
  onHelp: () => {},
  bottomDivider: false,
  state: true,
  expand: true,
  onExpand: () => {},
  relativity: false
};

export default UiSubsection;