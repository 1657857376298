import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getLogin } from '../requestUtil';
import AdminPageContainer from "../../components/PageContainers/AdminPageContainer";

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        getLogin()
          ? props.location.pathname === '/'
            ? <Redirect to={{ pathname: '/main' }} />
            : (
              <AdminPageContainer>
                <Component {...props} />
              </AdminPageContainer>
            )
          : <Redirect to={{ pathname: '/authorization', state: { redirect: props.location } }} />
      )}
    />
  );
}

export default PrivateRoute;
