import { css } from 'styled-components';

export const StyledCardBackground = css`
  ${ (props) => props.srcBackground && css`
    background: url(${ props.srcBackground });
    background-size: cover;
  ` }
`;

export const StyledInputBackground = css`
  background: ${ (props) => props.styles.colors.sectionBackground };
  border-radius: 0.25rem;
`;

export const StyledBlockedInput = css`
  color: ${ (props) => props.styles.colors.textTitle };
`;

export const StyledCardNumbers = css`
  ${ (props) => props.styles.fonts.cardNumbers };
  height: 1.5rem;
`;