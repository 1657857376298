import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../context/ThemeProvider';

import BackgroundModal from '../../Functional/BackgroundModal';
import UiCard from '../UiCard';
import UiButton from '../UiButton';
import UiText from '../UiText';

import styles from './UiActionSheet.module.css';

const StyledBackground = styled(BackgroundModal)`
  ${ (props) => {
    if (props.isOpened) {
      return css`
        display: block;
      `;
    } else {
      return css`
        display: none;
      `;
    }
  } }
`;

const StyledTitle = styled(UiText)`
  ${ (props) => {
    if (!!props.subtitle) {
      return css`
        margin-bottom: 1rem;
      `;
    }
  } }
`;

const StyledDescription = styled(UiText)`
  border-bottom: 0.063rem solid ${ (props) => props.styles.colors.textDisable };
`;

/**
 * Выпадающий список (для мобильных устройств)
 * @public
 * @version 0.0.90
 * @param {String} [className]
 * @param {Boolean} [isOpened=false]
 * @param {'default'|'black'} [view=default]
 * @param {String} [title]
 * @param {String} [subtitle]
 * @param {String} [description]
 * @param {String} [cancelTitle]
 * @param {Function} [cancelClick]
 * @param {*} children
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiActionSheet>
 *   { children }
 * </UiActionSheet>
 */
const UiActionSheet = ({
  className,
  isOpened,
  view,
  title,
  subtitle,
  description,
  cancelTitle,
  cancelClick,
  children,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <StyledBackground
      { ...props }
      className={ className }
      isOpened={ isOpened }
      view={ view }
      styles={ isTheme.styles }
    >
      <div className={styles.container}>
        {(title || subtitle) &&
          <div className={styles.header}>
            {title &&
              <StyledTitle
                subtitle={subtitle}
                color="#FFFFFF"
                view="section"
                typography="top-navbar-title"
                position="center"
              >
                {title}
              </StyledTitle>
            }
            {subtitle &&
              <UiText
                color="#FFFFFF"
                view="section"
                typography="top-navbar-title"
                position="center"
              >
                {subtitle}
              </UiText>
            }
          </div>
        }
        <UiCard className={ styles.content } shadow={ true }>
          { description &&
            <StyledDescription className={ styles.description } view="readonly" typography="note" styles={ isTheme.styles }>
              { description }
            </StyledDescription> }
          { children }
        </UiCard>

        <UiButton title={ cancelTitle } view="white" onClick={ cancelClick } />
      </div>
    </StyledBackground>
  );
};

UiActionSheet.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Определяет видимость компонента */
  isOpened: PropTypes.bool.isRequired,
  /** Вид отображения */
  view: PropTypes.oneOf(['default', 'black']),
  /** Устанавливает заголовок */
  title: PropTypes.string,
  /** Устанавливает подзаголовок */
  subtitle: PropTypes.string,
  /** Устанавливает описание */
  description: PropTypes.string,
  /** Устанавливает текст кнопки отмены */
  cancelTitle: PropTypes.string,
  /** Слушатель на нажатие по кнопке отмены */
  cancelClick: PropTypes.func,
  /** Определяет тело выпадающего списка */
  children: PropTypes.any
};

UiActionSheet.defaultProps = {
  isOpened: false,
  view: 'default'
};

export default UiActionSheet;