import React, { useEffect } from 'react';

import {
  UiCard,
  UiInput,
  UiSwitch,
  UiButton,
  useOptions,
} from '@compassplus/ui-mobicash';
import { useHistory } from 'react-router';
import ImageUpload from '../../../components/admin/ImageUpload';

import i18n from '../../../i18n';
import { request } from '../../../Utils/requestUtil';
import { uploadImage } from '../../../Utils/UploadData';

import { AnnotationSection, StatusDescriptionText } from './style';
import ModalStore from '../../../stores/ModalStore';
import CurrentGroupCard from '../../../components/admin/CurrentGroupCard/CurrentGroupCard';
import LanguageTabs from '../../../components/admin/LanguageTabs';
import ParentGuidsDropdown from "../../../components/admin/ParentGuidsDropdown";
import { SubContentStiky } from "../ModifyAttributePage/style";
import CategoryActiveAttribute from "../../../components/admin/CategoryActivePeriod/CategoryActiveAttribute";
import CategoryStore from "../../../stores/CategoryStore";
import { action } from "mobx";
import { observer } from "mobx-react-lite";

const ModifyGroup = observer((props) => {
  const { groupId, disableSave } = props;
  const history = useHistory();
  const isOptions = useOptions();

  const { modeIsModify, merchantGuid, title, description, status, parentGroup, isLoading, image, imageType } = CategoryStore;

  useEffect(() => {
    if (groupId) {
      if (!modeIsModify) {
        CategoryStore.setGroup(groupId);
      }
    }
  }, [groupId]);

  useEffect(action(() => {
    if (!parentGroup && props?.location?.state?.parentGroup) {
      CategoryStore.parentGroup = props.location.state.parentGroup
    }
  }), [])

  const setParentGroup = action((value) => {
    CategoryStore.parentGroup = value
  })

  const setImage = action((value) => {
    CategoryStore.image = value;
  });

  const setImageType = action((value) => {
    CategoryStore.imageType = value;
  })

  const checkRequiredFields = () => new Promise((resolve, reject) => {
    if (!image) {
      return reject(new Error('image'));
    }

    if ((i18n.language === 'ru' && title.ru.value === '') || (i18n.language === 'en' && title.en.value === '')) {
      return reject(new Error('title'));
    }

    return resolve();
  });

  const fillRequestData = () => new Promise((resolve, reject) => {
    checkRequiredFields().then(
      () => {
        const requestData = {};
        requestData.merchantGuid = merchantGuid;
        requestData.parentGuid = parentGroup.key;
        requestData.title = title;
        requestData.description = description
        requestData.status = status === 'Active';
        requestData.activePeriods = CategoryStore.activePeriods;

        if (i18n.language === 'ru') {
          if (title.en === '') {
            requestData.title.en = title.ru;
          }
          if (description.en.value === '') {
            requestData.description.en = description.ru;
          }
        } else {
          if (title.ru === '') {
            requestData.title.ru = title.en;
          }
          if (description.ru === '') {
            requestData.description.ru = description.en;
          }
        }

        resolve(requestData);
      },
      (emptyFields) => {
        reject(emptyFields);
      },
    );
  });

  const prepareRequestData = () => new Promise((resolve, reject) => {
    fillRequestData().then(
      (data) => {
        const requestData = data;
        if (imageType === 'url') {
          if (image.url !== undefined) {
            requestData.image = { url: image.url, type: image.type };
          } else {
            requestData.image = { url: image, type: imageType };
          }
          resolve(requestData);
        } else {
          uploadImage(image, 'group').then(
            (result) => {
              requestData.image = { url: result, type: 'file' };
              resolve(requestData);
            },
            (error) => {
              reject(error);
            },
          );
        }
      },
      (emptyFields) => {
        reject(emptyFields);
      },
    );
  });

  const createRequest = (requestData) => {
    request('/api/v1/group/', 'POST', requestData).then(
      (createdGroup) => {
        history.push('/main/');
      },
      (error) => {
        ModalStore.openErrorWithMessage(i18n.t(error));
      },
    );
  };

  const onCreate = (event) => {
    event.preventDefault();

    prepareRequestData().then(
      (requestData) => {
        createRequest(requestData);
      },
      (error) => {
        if (!error.message) {
          localStorage.clear();
        }
        ModalStore.openWithMessage(i18n.t(`Fill ${error.message} fields`));
      },
    );
  };

  const modifyRequest = (requestData) => {
    request(`/api/v1/group/${props.match.params.id}`, 'PUT', requestData).then(
      (updatedGroup) => history.push(`/main/${updatedGroup.parentGuid}`),
      (error) => {
        ModalStore.openErrorWithMessage(i18n.t(error));
      },
    );
  };

  const onModify = (event) => {
    event.preventDefault();

    prepareRequestData().then(
      (requestData) => {
        modifyRequest(requestData);
      },
      (error) => {
        ModalStore.openWithMessage(i18n.t(`Fill ${error.message} fields`));
      },
    );
  };

  const checkRequiredFieldsEmpty = () => (title.ru.value === '' && title.en.value === '');

  const getTabContent = (language) => (
    <UiCard shadow>
      <UiInput
        description={i18n.t('CategoryName')}
        required
        placeholder={`[${i18n.t('Text')}]`}
        value={title[language]}
        onChange={action((event) => {
          CategoryStore.title[language] = event.target.value;
        })}
        bottomDivider
      />
      <UiInput
        description={i18n.t('Description')}
        placeholder={`[${i18n.t('Text')}]`}
        value={description[language]}
        onChange={action((event) => {
          CategoryStore.description[language] = event.target.value;
        })}
        bottomDivider
      />
      <ParentGuidsDropdown
        description={i18n.t('ParentCategory')}
        value={parentGroup ? parentGroup.value[isOptions.language] || parentGroup.value : i18n.t('WithoutCategory')}
        setValue={setParentGroup}
        type="group"
      />
      <UiInput
        description={i18n.t('Status')}
        readOnly
        value={i18n.t(status)}
        rightElement={
          <UiSwitch
            checked={status === 'Active'}
            onClick={action(() => { status === 'Active' ? CategoryStore.status = 'Inactive' : CategoryStore.status = 'Active'; })}
          />
        }
      />
      <AnnotationSection
        style={{ margin: '0' }}
        title={<StatusDescriptionText>
          {i18n.t('StatusAnnotation')}
        </StatusDescriptionText>}
        bottomDivider
      />
      <CategoryActiveAttribute />
    </UiCard>
  );

  return (
    <>
      <CurrentGroupCard isLoading={isLoading} currentGroup={CategoryStore.currentGroup} />
      <LanguageTabs
        title={i18n.t('CategoryDetails')}
      >
        {getTabContent}
      </LanguageTabs>
      <ImageUpload
        changeImage={setImage}
        changeImageType={setImageType}
        image={image}
        imageType={imageType}
      />
      <SubContentStiky>
        <UiButton
          disabled={checkRequiredFieldsEmpty() || disableSave}
          title={i18n.t('Save')}
          onClick={modeIsModify ? onModify : onCreate}
        />
      </SubContentStiky>
    </>
  );
});

export default ModifyGroup;
