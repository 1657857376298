import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import i18n from '../../../i18n';
import { copiedToClipboardNotification } from '../../../Utils/notification';
import {
  Logo, ShareLinkButtons, SocialButton,
} from './style';
import {
  getCashierAuth,
  getCashierLogin,
  getTitle,
  request,
} from '../../../Utils/requestUtil';
import { Dialog, DialogInput, MainText } from '../../TemplateStyle';
import {action} from "mobx";
import GlobalStateStore from "../../../stores/GlobalStateStore";

const ShareLink = ({
  itemId, active, setActive, state, type
}) => {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  const history = useHistory();
  const [link, setLink] = useState('');
  const createBuyURL = (id) => {
    request(`/api/v1/cashier/getMarketUrl/${getCashierLogin()}`, 'GET').then(
      (response) => {
        if (response !== '' || response !== undefined) {
          switch (type) {
            case 'group':
              setLink(`${window.location.origin}/${response}/${id}`);
              break;
            case 'market':
              setLink(`${window.location.origin}/${response}`);
              break;
            default:
              setLink(`${window.location.origin}/${response}/order/${id}`);
              break;
          }
        } else {
          const defaultURL = getTitle().replace(' ', '');
          switch (type) {
            case 'group':
              setLink(`${window.location.origin}/${defaultURL}/${id}`);
              break;
            case 'market':
              setLink(`${window.location.origin}/${defaultURL}`);
              break;
            default:
              setLink(`${window.location.origin}/${defaultURL}/order/${id}`);
              break;
          }

          const requestData = {
            login: getCashierLogin(),
            link: defaultURL,
          };

          request('/api/v1/cashier/setMarketURL', 'POST', requestData)
            .then(
              (error) => {
                console.log(error)
              });
        }
      }
    )
  };

  useEffect(() => {
    if (active && getCashierAuth()) {
      createBuyURL(itemId);
    }
  }, [itemId, active]);

  const vkButton = () => (
    <SocialButton>
      <a
        target="_blank"
        href={isMobile ? `https://m.vk.com/share.php?url=${link}noparse=true` : `https://vk.com/share.php?url=${link}noparse=true`}
        rel="noreferrer"
      >
        <Logo src="/images/social/vk.svg" />
      </a>
    </SocialButton>
  );

  const tgButton = () => (
    <SocialButton>
      <a
        target="_blank"
        href={`https://t.me/share/url?url=${link}`}
        rel="noreferrer"
      >
        <Logo src="/images/social/telegram.png" />
      </a>
    </SocialButton>
  );

  const viberButton = () => (
    <SocialButton>
      <a
        target="_blank"
        href={`viber://forward?text=${link}`}
        rel="noreferrer"
      >
        <Logo src="/images/social/viber.png" />
      </a>
    </SocialButton>
  );

  const content = () => (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <DialogInput
          id="link"
          onClick={() => { document.getElementById('link').select(); navigator.clipboard.writeText(link).then(() => { copiedToClipboardNotification(); }); }}
          value={link}
          readOnly
        />
      </div>
      <MainText style={{ textAlign: 'center', fontSize: '0.75rem', marginTop: '0.5rem' }}>{i18n.t('ShareInSocial')}</MainText>
      <ShareLinkButtons>
        {vkButton()}
        {tgButton()}
        {(isMobile) && (
          <>
            {viberButton()}
          </>
        )}
      </ShareLinkButtons>
    </>
  );

  if (active) {
    if (getCashierAuth()) {
      return (
        <Dialog
          title={i18n.t('Share')}
          text={type === 'group' ? i18n.t('LinkToCategory') : i18n.t('LinkToItem')}
          buttons={[
            {
              text: i18n.t('CopyLink'),
              mode: 'bold',
              action: () => {
                GlobalStateStore.isShareLinkOpen = false;
                GlobalStateStore.isMarketShareLinkOpen = false;
                navigator.clipboard.writeText(link).then(() => {
                  copiedToClipboardNotification();
                });
              },
            },
            {
              text: i18n.t('Cancel'),
              mode: '',
              action: () => {
                GlobalStateStore.isShareLinkOpen = false;
                GlobalStateStore.isMarketShareLinkOpen = false;
                setActive(false);
              },
            },
          ]}
        >
          {content()}
        </Dialog>
      );
    }
    return (
      <Dialog
        title={i18n.t('FillInCashierAnnotation')}
        mode="warning"
        text={i18n.t('FillInCashierAnnotation')}
        buttons={[
          {
            text: i18n.t('Settings'),
            mode: 'bold',
            action: () => { history.push('/administration/cashierAuth', { cashier: true, id: itemId, state, type, getLink: true }); },
          },
          {
            text: i18n.t('Cancel'),
            mode: '',
            action: () => { setActive(false); },
          },
        ]}
      />
    );
  }
  return null;
};

export default ShareLink;
