import { useTheme } from '../../../../../../../context/ThemeProvider';

import Element from '../../../../../../Functional/Element';

const Expand = () => {

  const isTheme = useTheme();

  return (
    <Element
      color={ isTheme.styles.colors.textReadOnly }
      width="1rem"
      height="1rem"
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00008 12.0001L12.5001 7.50008C12.7762 7.22393 12.7762 6.77622 12.5001 6.50008C12.2239 6.22393 11.7762 6.22393 11.5001 6.50008L8.00008 10.0001L4.50008 6.50008C4.22393 6.22393 3.77622 6.22393 3.50008 6.50008C3.22393 6.77622 3.22393 7.22393 3.50008 7.50008L7.50008 11.5001L8.00008 12.0001Z"
      />
    </Element>
  );
};

export default Expand;