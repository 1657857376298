import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import UiInput from '../../Ui/UiInput';
import UiSwitch from '../../Ui/UiSwitch';

const defaultValues = [{ text: 'False', value: false }, { text: 'True', value: true }];

/**
 * Input switch component
 * @public
 * @version 0.0.90
 * @param {String} [id]
 * @param {Object[]} [value=[{ text: 'False', value: false }, { text: 'True', value: true }]]
 * @param {String} value.text
 * @param {Any} value.value
 * @param {Object[]} [forms]
 * @param {String} forms[].text
 * @param {Any} forms[].value
 * @param {Boolean} forms[].checked
 * @param {Function} [setForm]
 * @param {Object} [state]
 * @param {String} state.text
 * @param {Any} state.value
 * @param {Boolean} state.checked
 * @param {Function} [setState]
 * @param {Object} [defaultValue={text:'False',value:false,checked:false}]
 * @param {String} [defaultValue.text='False']
 * @param {String} [defaultValue.value=false]
 * @param {Boolean} [defaultValue.checked=false]
 * @param {Boolean} [disabled=false]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiInputSwitch />
 */
const UiInputSwitch = ({
  id,
  value,
  forms,
  setForm,
  state,
  setState,
  defaultValue,
  disabled,
  ...props
}) => {

  const [inputValue, setInputValue] = useState({
    text: defaultValue.text || defaultValues[0].text,
    value: defaultValue.value !== undefined ? defaultValue.value : defaultValues[0].value,
    checked: defaultValue.checked || false
  });

  useEffect(() => {
    if (Array.isArray(forms) && typeof setForm === 'function' && !!id) {
      const form = forms.find((item) => item.id === id);

      if (typeof form === 'object' && form.text !== undefined && form.value !== undefined && form.checked !== undefined) {
        setInputValue({ text: form.text, value: form.value, checked: form.checked });
      } else {
        setForm(id, {
          text: defaultValue.text || defaultValues[0].text,
          value: defaultValue.value !== undefined ? defaultValue.value : defaultValues[0].value,
          checked: defaultValue.checked || false
        });
      }
    } else if (typeof state === 'object' && typeof setState === 'function') {
      if (state.text !== undefined && state.value !== undefined && state.checked !== undefined) {
        setInputValue({ text: state.text, value: state.value, checked: state.checked });
      } else {
        setState({
          text: defaultValue.text || defaultValues[0].text,
          value: defaultValue.value !== undefined ? defaultValue.value : defaultValues[0].value,
          checked: defaultValue.checked || false
        });
      }
    } else {
      setInputValue({
        text: defaultValue.text || defaultValues[0].text,
        value: defaultValue.value !== undefined ? defaultValue.value : defaultValues[0].value,
        checked: defaultValue.checked || false
      });
    }
  }, [id, forms, setForm, state, setState, defaultValue]);

  const handlerChecked = (event) => {
    if (typeof setForm === 'function' && !!id) {
      setForm(id, {
        text: value[Number(event.target.checked)].text,
        value: value[Number(event.target.checked)].value,
        checked: event.target.checked
      });
    } else if (typeof setState === 'function') {
      setState({
        text: value[Number(event.target.checked)].text,
        value: value[Number(event.target.checked)].value,
        checked: event.target.checked
      });
    } else {
      setInputValue({
        text: value[Number(event.target.checked)].text,
        value: value[Number(event.target.checked)].value,
        checked: event.target.checked
      });
    }
  };

  return (
    <UiInput
      { ...props }
      readOnly={ true }
      disabled={ disabled }
      flag={ undefined }
      value={ inputValue.text }
      rightElement={ <UiSwitch checked={ inputValue.checked } disabled={ disabled } onChange={ handlerChecked } /> }
    />
  );
};

UiInputSwitch.propTypes = {
  /** Уникальный идентификатор элемента (необходим для useForm) */
  id: PropTypes.string,
  /** Массив двух значений для значений FALSE/TRUE */
  value: PropTypes.arrayOf(PropTypes.exact({
    text: PropTypes.string,
    value: PropTypes.any
  })),
  /** Массив объектов хука useForm (UI) */
  forms: PropTypes.array,
  /** Функция обработчик хука useForm (UI) */
  setForm: PropTypes.func,
  /** Объект хука useState (React) */
  state: PropTypes.object,
  /** Функция обработчик хука useState (React) */
  setState: PropTypes.func,
  /** Значение по умолчанию */
  defaultValue: PropTypes.exact({
    text: PropTypes.string,
    value: PropTypes.any,
    checked: PropTypes.bool
  }),
  /** Блокирует компонент для изменения */
  disabled: PropTypes.bool
};

UiInputSwitch.defaultProps = {
  value: defaultValues,
  defaultValue: { text: defaultValues[0].text, value: defaultValues[0].value, checked: false },
  disabled: false
};


export default UiInputSwitch;