export const lightTheme = {
  applicationPrimaryColor: '#BE1E24',
  applicationBackground: '#EBEBEB',
  menuBackground: '#CCCCCC',
  sectionBackground: '#FFFFFF',
  textTitle: '#000000',
  textReadOnly: '#7F7F7F',
  textDisable: '#D6D6D6',
  textLink: '#037AFF',
  pendingBillSecondary: '#8DA8CC',
  applicationPosPrimaryColor: '#0A4D9B',
  warningColor: '#EC1310',
  settingsGreen: '#76B943',
  shadowBox: '0px 2px 4px rgba(0, 0, 0, 0.12)',
  darkDivBackground: '#333333',
  lightDivBackground: '#FFFFFF',
  grayDivBackground: '#B3B3B3',
  cardBackground: '#FFFFFF',
  contentDivBackground: '#FFFFFF',
  pageTitleBackground: '#5C6875',
  collapseBackground: '#fafafa',
};

export const darkTheme = {
  applicationPrimaryColor: '#DE8E91',
  applicationBackground: '#272C32',
  menuBackground: '#22282E',
  sectionBackground: '#393C40',
  textTitle: '#FFFFFF',
  textReadOnly: '#ADB0B2',
  textDisable: '#5C6166',
  textLink: '#529EFF',
  applicationPosPrimaryColor: '#0A4D9B',
  warningColor: '#FC3F3F',
  settingsGreen: '#9FCE7B',
  shadowBox: '0px 2px 4px #000000',
  darkDivBackground: '#0A121A',
  lightDivBackground: '#333333',
  grayDivBackground: '#202020',
  cardBackground: '#393C40',
  contentDivBackground: '#393C40',
  pageTitleBackground: '#0A121A',
  collapseBackground: '#343434',
};
