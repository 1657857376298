import { useState, useMemo } from 'react';

/**
 * Кастомный хук для работы с формами
 * @param {Array|Function} [initialValue=[]]
 * @return {[unknown,{clearForm: function(*): void, clear: function(): void, setForm: function(*, Object): void}]}
 */
const useForm = (initialValue = []) => {

  const [state, setState] = useState(() => {
    if (Array.isArray(initialValue)) {
      return initialValue;
    } else if (typeof initialValue === 'function') {
      return initialValue();
    } else {
      return [];
    }
  });

  const action = useMemo(() => {
    /**
     * Устанавливает значения в элемент формы (добавляет новые значения, не переписывая весь элемент)
     * @public
     * @version 1.4.0
     * @param id
     * @param {Object} value
     * @constructor
     * @example
     * import { useEffect } from 'react';
     *
     * import { useForm } from '@compassplus/ui-mobicash';
     *
     * const App = () => {
     *   const [forms, { setForm }] = useForm([]);
     *
     *   useEffect(() => {
     *     console.log(forms);
     *   }, [forms]);
     *
     *   useEffect(() => {
     *     setForm(0, { value: '' });
     *   }, [setForm]);
     * }; */
    function setForm(id, value) {
      setState((state) => {
        const formItem = state.find((item) => item.id === id);

        if (formItem) {
          return state.map((item) => item.id === id ? { ...item, ...value } : item);
        } else {
          return [...state, { id: id, ...value }];
        }
      })
    }

    /**
     * Удаляет элемент формы
     * @public
     * @version 1.4.0
     * @param id
     * @constructor
     * @example
     * import { useEffect } from 'react';
     *
     * import { useForm } from '@compassplus/ui-mobicash';
     *
     * const App = () => {
     *   const [forms, { clearForm }] = useForm([{ id: 0, value: '' }]);
     *
     *   useEffect(() => {
     *     console.log(forms);
     *   }, [forms]);
     *
     *   useEffect(() => {
     *     clearForm(0);
     *   }, [clearForm]);
     * }; */
    function clearForm(id) {
      setState((state) => {
        return state.filter((item) => item.id !== id);
      });
    }

    /**
     * Очищает форму
     * @public
     * @version 1.4.0
     * @constructor
     * @example
     * import { useEffect } from 'react';
     *
     * import { useDate } from '@compassplus/ui-mobicash';
     *
     * const App = () => {
     *   const [date, { clear }] = useDate([{ id: 0, value: '' }, { id: 1, value: '' }]);
     *
     *   useEffect(() => {
     *     console.log(date);
     *   }, [date]);
     *
     *   useEffect(() => {
     *     clear();
     *   }, [clear]);
     * }; */
    function clear() {
      setState([]);
    }

    return { setForm, clearForm, clear };
  }, []);

  return [state, action];
};

export default useForm;