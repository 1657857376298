import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Иконка рекламы
 * @public
 * @version 1.5.0
 * @param {String} [width="3.25rem"]
 * @param {String} [height="3.25rem"]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * import { UiAdvertisingIcon, UiPlates, UiPlate } from '@compassplus/ui-mobicash';
 *
 * const App = () => {
 *
 *   return (
 *     <>
 *       <UiAdvertisingIcon />
 *       <UiPlates>
 *         <UiPlate view="2x1" image={ <UiAdvertisingIcon width="100%" height="100%" /> } />
 *       </UiPlates>
 *     </>
 *   );
 * }; */
const UiAdvertisingIcon = ({
  width,
  height,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      innerRef={ innerRef }
      width={ width }
      height={ height }
      viewBox="0 0 52 52"
    >
      <path fillRule="evenodd" clipRule="evenodd"
        d="M43.228 17.0854H8.77102C7.79279 17.0854 7 17.7034 7 18.4654V33.6254C7 34.3864 7.79279 35.0044 8.77102 35.0044H21.4773L23.8282 37.3244C23.8939 37.3894 23.9292 37.4614 23.9292 37.5344V42.7634H20.9337C20.45 42.7634 20.0536 43.0714 20.0536 43.4484V43.8374C20.0536 44.2144 20.45 44.5224 20.9337 44.5224H31.0565C31.5402 44.5224 31.9366 44.2144 31.9366 43.8374V43.4484C31.9366 43.0714 31.5402 42.7634 31.0565 42.7634H28.1218V37.5344C28.1218 37.4614 28.1552 37.3894 28.2219 37.3244L30.5728 35.0044H43.228C44.2072 35.0044 45 34.3864 45 33.6254V18.4654C45 17.7034 44.2072 17.0854 43.228 17.0854Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M26.2348 33.0093H10.5124C9.62545 33.0093 8.90723 32.2763 8.90723 31.3733V13.3983C8.90723 12.4943 9.62545 11.7613 10.5124 11.7613H41.7354C42.6214 11.7613 43.3406 12.4943 43.3406 13.3983V31.3733C43.3406 32.2763 42.6214 33.0093 41.7354 33.0093H30.2763"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M26.2299 11.8945C26.0945 11.8945 25.9846 11.7825 25.9846 11.6445V9.55249C25.9846 9.41449 26.0945 9.30249 26.2299 9.30249C26.3653 9.30249 26.4752 9.41449 26.4752 9.55249V11.6445C26.4752 11.7825 26.3653 11.8945 26.2299 11.8945Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M34.3447 11.9849C34.2093 11.9849 34.0994 11.8729 34.0994 11.7349V6.30591C34.0994 6.16791 34.2093 6.05591 34.3447 6.05591C34.4801 6.05591 34.59 6.16791 34.59 6.30591V11.7349C34.59 11.8729 34.4801 11.9849 34.3447 11.9849Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M39.242 6.51019H34.341C34.2056 6.51019 34.0957 6.39819 34.0957 6.26019C34.0957 6.12219 34.2056 6.01019 34.341 6.01019H39.242C39.3774 6.01019 39.4873 6.12219 39.4873 6.26019C39.4873 6.39819 39.3774 6.51019 39.242 6.51019Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M18.0068 11.9751C17.8714 11.9751 17.7615 11.8631 17.7615 11.7251V6.29608C17.7615 6.15808 17.8714 6.04608 18.0068 6.04608C18.1422 6.04608 18.2521 6.15808 18.2521 6.29608V11.7251C18.2521 11.8631 18.1422 11.9751 18.0068 11.9751Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M18.0098 6.5H13.1098C12.9744 6.5 12.8645 6.388 12.8645 6.25C12.8645 6.112 12.9744 6 13.1098 6H18.0098C18.1452 6 18.2551 6.112 18.2551 6.25C18.2551 6.388 18.1452 6.5 18.0098 6.5Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M26.1097 14.1621C25.9537 14.0431 25.6378 14.0351 25.4298 14.1671L20.6897 17.5841L15.4159 18.8401C15.1284 18.9081 14.9371 19.1831 14.9714 19.4801L14.9793 19.5511C14.9783 19.5511 14.9891 19.6511 15.0067 19.8061C14.4896 20.0381 14.108 20.5951 13.9598 21.2911C13.9471 21.3521 13.9382 21.4181 13.9284 21.4841C13.9225 21.5251 13.9127 21.5661 13.9088 21.6091C13.8843 21.8361 13.8823 22.0851 13.9088 22.3591C14.0628 23.5281 14.7909 24.4191 15.62 24.5471C15.6435 24.7021 15.6582 24.8001 15.6592 24.8001L15.67 24.8711C15.7142 25.1671 15.9693 25.3811 16.2636 25.3701C16.2636 25.3701 21.9849 25.2521 21.9849 25.2511L27.1321 27.1731C27.3666 27.2461 27.6688 27.1531 27.7895 26.9971C27.8886 26.8721 27.9377 26.7111 27.9161 26.5511L26.3472 14.5581C26.3266 14.3981 26.2383 14.2561 26.1097 14.1621Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M14.8989 19.9043C14.3819 20.1363 14.0002 20.6943 13.853 21.3893C13.8393 21.4503 13.8304 21.5173 13.8206 21.5823C13.8147 21.6243 13.8059 21.6643 13.801 21.7073C13.7765 21.9343 13.7745 22.1843 13.802 22.4573C13.955 23.6263 14.6841 24.5183 15.5122 24.6463C15.5357 24.8003 14.8813 19.7493 14.8989 19.9043Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd" d="M20.7563 17.7871L21.5639 24.1121L20.7563 17.7871Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M21.5635 24.3618C21.4418 24.3618 21.3368 24.2698 21.3202 24.1438L20.5127 17.8198C20.496 17.6828 20.5902 17.5568 20.7246 17.5388C20.8561 17.5178 20.9817 17.6178 20.9993 17.7548L21.8078 24.0798C21.8245 24.2158 21.7293 24.3418 21.5959 24.3598C21.5851 24.3618 21.5743 24.3618 21.5635 24.3618Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd" d="M19.8384 17.8198L20.4693 22.7948L19.8384 17.8198Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M20.4687 23.0449C20.347 23.0449 20.242 22.9529 20.2253 22.8269L19.5944 17.8519C19.5778 17.7149 19.672 17.5889 19.8064 17.5719C19.9398 17.5549 20.0634 17.6499 20.0811 17.7879L20.713 22.7629C20.7297 22.8999 20.6345 23.0249 20.501 23.0429C20.4893 23.0449 20.4795 23.0449 20.4687 23.0449Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.032 19.6372L15.6766 24.7162L15.032 19.6372Z" fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M15.6762 24.9663C15.5545 24.9663 15.4496 24.8743 15.4329 24.7483L14.7892 19.6693C14.7716 19.5323 14.8667 19.4063 15.0012 19.3893C15.1375 19.3673 15.2582 19.4673 15.2759 19.6053L15.9205 24.6843C15.9372 24.8213 15.842 24.9463 15.7086 24.9643C15.6968 24.9663 15.687 24.9663 15.6762 24.9663Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M30.6658 21.4707C30.5304 21.4707 30.4215 21.3597 30.4205 21.2217C30.4185 20.7857 30.3812 20.3437 30.3106 19.9057C30.2733 19.6797 30.2282 19.4577 30.1742 19.2397C30.1418 19.1057 30.2213 18.9707 30.3528 18.9367C30.4823 18.9037 30.6177 18.9847 30.6501 19.1187C30.707 19.3497 30.7551 19.5857 30.7943 19.8247C30.8699 20.2877 30.9091 20.7577 30.9111 21.2197C30.9121 21.3577 30.8031 21.4697 30.6668 21.4707H30.6658Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M33.6773 21.7461C33.6695 21.7461 33.6616 21.7461 33.6538 21.7451C33.5194 21.7321 33.4203 21.6101 33.432 21.4731C33.4968 20.7681 33.4723 20.0521 33.3565 19.3461C33.2692 18.8081 33.1298 18.2781 32.9424 17.7701C32.8943 17.6411 32.9591 17.4971 33.0847 17.4481C33.2132 17.3971 33.3535 17.4651 33.4016 17.5941C33.6008 18.1331 33.7489 18.6951 33.8412 19.2651C33.9628 20.0131 33.9893 20.7711 33.9216 21.5191C33.9089 21.6491 33.8019 21.7461 33.6773 21.7461Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M36.3425 23.3535C36.313 23.3535 36.2826 23.3485 36.2542 23.3365C36.1276 23.2865 36.0648 23.1425 36.1138 23.0135C36.6358 21.6395 36.7869 20.1395 36.5485 18.6775C36.3474 17.4445 35.8931 16.3045 35.1955 15.2885C35.117 15.1755 35.1444 15.0195 35.2553 14.9395C35.3662 14.8615 35.5193 14.8885 35.5977 15.0015C36.3366 16.0795 36.8193 17.2885 37.0322 18.5955C37.2844 20.1455 37.1254 21.7365 36.5711 23.1935C36.5338 23.2925 36.4406 23.3535 36.3425 23.3535Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M22.057 25.0332L22.1011 25.0492L27.2484 26.9712C27.3799 27.0132 27.5761 26.9442 27.6281 26.8762C27.6899 26.7982 27.7174 26.7052 27.7056 26.6172L26.1357 14.6242C26.125 14.5352 26.0739 14.4532 25.9984 14.3972L25.9945 14.3952C25.9268 14.3432 25.7178 14.3302 25.5922 14.4122L20.7775 17.8602L15.5037 19.1162C15.3379 19.1552 15.228 19.3132 15.2476 19.4842L15.2741 19.7302H15.2731L15.3016 19.9932L15.1377 20.0672C14.7031 20.2622 14.3646 20.7522 14.2311 21.3772C14.2213 21.4242 14.2144 21.4722 14.2076 21.5212L14.1938 21.6082C14.1615 21.8882 14.1615 22.1222 14.186 22.3662C14.3194 23.3882 14.9523 24.2192 15.6892 24.3332L15.8668 24.3602L15.9452 24.8652C15.9698 25.0362 16.1209 25.1562 16.2857 25.1522C16.2906 25.1522 21.9343 25.0362 22.0099 25.0342L22.057 25.0332ZM27.3387 27.4812C27.2533 27.4812 27.1699 27.4702 27.0914 27.4452L21.9334 25.5192L21.9344 25.5152C21.4899 25.5452 20.2045 25.5722 16.3004 25.6522C15.8874 25.6762 15.5224 25.3632 15.4596 24.9412L15.4488 24.8722L15.4547 24.8712C15.4488 24.8462 15.4429 24.8192 15.437 24.7902C14.5559 24.5542 13.8534 23.6122 13.6984 22.4252C13.6699 22.1382 13.6699 21.8682 13.6964 21.6142C13.7003 21.5832 13.7052 21.5512 13.7111 21.5192L13.9642 21.4852L13.7219 21.4492C13.7307 21.3872 13.7396 21.3272 13.7523 21.2692C13.9044 20.5542 14.2733 19.9912 14.7767 19.6942C14.7737 19.6642 14.7718 19.6362 14.7708 19.6112H14.7678L14.76 19.5402C14.7119 19.1172 14.9837 18.7252 15.3928 18.6292L20.6186 17.3842L25.3194 13.9962C25.6147 13.8072 26.0425 13.8082 26.2878 13.9932C26.4713 14.1292 26.594 14.3352 26.6224 14.5582L28.1913 26.5512C28.2207 26.7752 28.155 27.0072 28.0118 27.1872C27.8724 27.3692 27.6006 27.4812 27.3387 27.4812Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M21.4434 30.2881L21.047 30.5041C20.8242 30.6261 20.5436 30.5391 20.4249 30.3121L17.4127 24.5771C17.294 24.3491 17.3783 24.0631 17.6011 23.9421L17.9975 23.7261C18.2202 23.6041 18.5008 23.6901 18.6205 23.9181L21.6317 29.6541C21.7514 29.8811 21.6671 30.1661 21.4434 30.2881Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M18.2354 23.8657C18.201 23.8657 18.1667 23.8737 18.1343 23.8917L17.7389 24.1077C17.6339 24.1647 17.5947 24.2977 17.6506 24.4047L20.6628 30.1387C20.7178 30.2457 20.8483 30.2847 20.9532 30.2287L21.3496 30.0127C21.3997 29.9857 21.436 29.9397 21.4527 29.8837C21.4693 29.8267 21.4644 29.7687 21.437 29.7177L18.4257 23.9817C18.3865 23.9077 18.3129 23.8657 18.2354 23.8657ZM20.8532 30.7547C20.601 30.7547 20.3567 30.6167 20.2301 30.3747L17.2179 24.6397C17.0344 24.2897 17.1639 23.8537 17.5064 23.6667L17.9028 23.4507C18.2462 23.2627 18.674 23.3947 18.8584 23.7457L21.8697 29.4807C21.958 29.6497 21.9766 29.8437 21.9226 30.0277C21.8677 30.2127 21.747 30.3637 21.5802 30.4537L21.1848 30.6697C21.0788 30.7277 20.965 30.7547 20.8532 30.7547Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M22.0171 33.0093L24.1561 35.7673C24.2159 35.8443 24.2463 35.9293 24.2463 36.0163V42.5883H28.0464V36.0163C28.0464 35.9293 28.0768 35.8443 28.1377 35.7673L30.2757 33.0093"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M28.0461 42.8384H24.247C24.1116 42.8384 24.0017 42.7264 24.0017 42.5884V36.0164C24.0017 35.9864 23.9889 35.9554 23.9634 35.9224L21.8245 33.1644C21.7411 33.0564 21.7587 32.8994 21.8657 32.8134C21.9716 32.7274 22.1257 32.7454 22.2101 32.8544L24.348 35.6124C24.4422 35.7344 24.4923 35.8734 24.4923 36.0164V42.3384H27.8008V36.0164C27.8008 35.8734 27.8508 35.7334 27.946 35.6114L30.084 32.8544C30.1684 32.7454 30.3224 32.7284 30.4284 32.8134C30.5344 32.8994 30.553 33.0564 30.4686 33.1644L28.3306 35.9224C28.3041 35.9554 28.2914 35.9864 28.2914 36.0164V42.5884C28.2914 42.7264 28.1815 42.8384 28.0461 42.8384Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M27.2572 42.209H30.7061C31.1447 42.209 31.5038 42.575 31.5038 43.021V43.483C31.5038 43.93 31.1447 44.295 30.7061 44.295H21.534C21.0954 44.295 20.7363 43.93 20.7363 43.483V43.021C20.7363 42.575 21.0954 42.209 21.534 42.209H26.3644"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M30.706 44.5454H21.533C20.958 44.5454 20.491 44.0684 20.491 43.4834V43.0214C20.491 42.4354 20.958 41.9594 21.533 41.9594H26.3643C26.4997 41.9594 26.6096 42.0704 26.6096 42.2094C26.6096 42.3474 26.4997 42.4594 26.3643 42.4594H21.533C21.2288 42.4594 20.9816 42.7114 20.9816 43.0214V43.4834C20.9816 43.7934 21.2288 44.0454 21.533 44.0454H30.706C31.0102 44.0454 31.2574 43.7934 31.2574 43.4834V43.0214C31.2574 42.7114 31.0102 42.4594 30.706 42.4594H27.2572C27.1218 42.4594 27.0119 42.3474 27.0119 42.2094C27.0119 42.0704 27.1218 41.9594 27.2572 41.9594H30.706C31.281 41.9594 31.748 42.4354 31.748 43.0214V43.4834C31.748 44.0684 31.281 44.5454 30.706 44.5454Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M24.0658 9.50493H28.3643C28.7323 9.50493 28.9805 9.09793 28.8157 8.76193L27.7403 6.57093C27.6559 6.39793 27.4813 6.28693 27.2899 6.28693H25.1412C24.9498 6.28693 24.7762 6.39793 24.6898 6.57093L23.6154 8.76193C23.4506 9.09793 23.6988 9.50493 24.0658 9.50493Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M36.0234 9.48531H40.322C40.6899 9.48531 40.9372 9.07831 40.7733 8.74231L39.6979 6.55131C39.6126 6.37831 39.4379 6.26831 39.2476 6.26831H37.0978C36.9075 6.26831 36.7328 6.37831 36.6475 6.55131L35.5731 8.74231C35.4082 9.07831 35.6555 9.48531 36.0234 9.48531Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M16.7783 8.73239L15.7039 6.54139C15.6176 6.36739 15.4439 6.25739 15.2526 6.25739H13.1038C12.9125 6.25739 12.7378 6.36739 12.6525 6.54139L11.5781 8.73239C11.4132 9.06739 11.6615 9.47539 12.0294 9.47539H16.328C16.6949 9.47539 16.9432 9.06739 16.7783 8.73239Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M41.7351 33.5093H30.2759C30.0051 33.5093 29.7853 33.2853 29.7853 33.0093C29.7853 32.7333 30.0051 32.5093 30.2759 32.5093H41.7351C42.3493 32.5093 42.8497 31.9993 42.8497 31.3733V13.3983C42.8497 12.7713 42.3493 12.2613 41.7351 12.2613H10.5121C9.89784 12.2613 9.39744 12.7713 9.39744 13.3983V31.3733C9.39744 31.9993 9.89784 32.5093 10.5121 32.5093H26.2344C26.5062 32.5093 26.725 32.7333 26.725 33.0093C26.725 33.2853 26.5062 33.5093 26.2344 33.5093H10.5121C9.35623 33.5093 8.41626 32.5513 8.41626 31.3733V13.3983C8.41626 12.2203 9.35623 11.2613 10.5121 11.2613H41.7351C42.8909 11.2613 43.8309 12.2203 43.8309 13.3983V31.3733C43.8309 32.5513 42.8909 33.5093 41.7351 33.5093Z"
        fill={ isTheme.styles.colors.textTitle } />
    </Element>
  );
};

UiAdvertisingIcon.propTypes = {
  /** Определяет ширину иконки */
  width: PropTypes.string,
  /** Определяет высоту иконки */
  height: PropTypes.string
};

UiAdvertisingIcon.defaultProps = {
  width: '3.25rem',
  height: '3.25rem'
};

export default UiAdvertisingIcon;