import React from 'react';
import { Route } from 'react-router-dom';
import AdminPageContainer from '../../components/PageContainers/AdminPageContainer';

function AdminPageRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <AdminPageContainer>
          <Component {...props} />
        </AdminPageContainer>
      )}
    />
  );
}

export default AdminPageRoute;
