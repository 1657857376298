import { makeAutoObservable, runInAction } from 'mobx';
import {getMerchantId, request} from '../Utils/requestUtil';
import ModalStore from './ModalStore';
import i18n from "../i18n";
import modifyOrder from "../pages/admin/ModifyOrderPage/ModifyOrder";

class OrdersRecords {
  constructor() {
    this.isLoading = false;
    this.statuses = ['Created', 'Payed', 'InDelivery', 'ReadyForIssue', 'Completed', 'Canceled', 'Deleted'];
    this.allOrders = [];
    this.displayedOrders = [];
    this.selectedStatus = 'All';
    this.modifyingOrder = null;
    makeAutoObservable(this);
  }

  getOrders() {
    request('/api/v1/orders/', 'GET').then((orders) => {
      if (!orders) {
        ModalStore.openUnexpectedErrorModal();
      } else {
        runInAction(() => {
          this.allOrders = orders;
          this.displayedOrders = orders;
        });
      }
    });
  }

  setModifyingOrder(order) {
    this.modifyingOrder = order;
    if (order.isNew) {
      this.modifyingOrder.isNew = false;
    }
  }

  getOrderData(orderId, history) {
    this.isLoading = true;
    request(`/api/v1/orders/byId/${orderId}`, 'GET')
      .then((order) => {
        if (!order) {
          ModalStore.openUnexpectedErrorModal();
        } else {
          if (order.merchantGuid !== getMerchantId()) {
            history.push('/orders');
            runInAction(() => {
              this.isLoading = false;
              ModalStore.isOpen = true;
              ModalStore.icon = 'merchant';
              ModalStore.title = i18n.t('MerchantService');
              ModalStore.mode = 'normal';
              ModalStore.text = i18n.t('Order not found');
              ModalStore.buttons = [
                {
                  text: i18n.t('Ok'),
                  mode: 'normal',
                  action: () => {
                    ModalStore.setDefaultModal();
                  },
                },
              ];
            });
          } else {
            runInAction(() => {
              this.isLoading = false;
              this.modifyingOrder = order;
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        history.push('/orders');
        runInAction(() => {
          this.isLoading = false;
          ModalStore.isOpen = true;
          ModalStore.icon = 'merchant';
          ModalStore.title = i18n.t('MerchantService');
          ModalStore.mode = 'normal';
          ModalStore.text = i18n.t('OrderNotFound');
          ModalStore.buttons = [
            {
              text: i18n.t('Ok'),
              mode: 'normal',
              action: () => {
                ModalStore.setDefaultModal();
              },
            },
          ];
        });
      });
  }

  setStatus(newStatus) {
    this.selectedStatus = newStatus;
    if (newStatus === 'All') {
      this.displayedOrders = this.allOrders;
    } else {
      this.displayedOrders = this.allOrders.filter((el) => el.status === newStatus);
    }
  }

  modifyStatus = (newStatus) => {
    this.modifyingOrder.status = newStatus;
    request('/api/v1/orders/setStatus', 'POST', {
      orderId: this.modifyingOrder._id,
      status: newStatus
    })
      .then((res) => {
        return true;
      })
      .catch((err) => {
        return false;
      })
  }

  modifyDeliveryData = ({ type, deliveryDate, addressFields, entryField }) => {
    if (type) { this.modifyingOrder.deliveryData.type = type }
    if (deliveryDate) { this.modifyingOrder.deliveryData.deliveryDate = deliveryDate }
    if (addressFields) { this.modifyingOrder.deliveryData.addressFields = addressFields; }
    if (entryField) { this.modifyingOrder.deliveryData.entryField = entryField; }
    request('/api/v1/orders/setDeliveryData', 'POST', {
      orderId: this.modifyingOrder._id,
      addressFields,
      type,
      deliveryDate,
      entryField
    }).then((res) => {
        return true;
      })
      .catch((err) => {
        return false;
      })
  }

  modifyCustomerData = ({ personalData, phoneNumber, beautyPhone, birthday }) => {
    if (personalData) { this.modifyingOrder.customer.personalData = personalData; }
    if (phoneNumber) { this.modifyingOrder.customer.phoneNumber = phoneNumber; }
    if (beautyPhone) { this.modifyingOrder.customer.beautyPhone = beautyPhone; }
    if (birthday) { this.modifyingOrder.customer.birthday = birthday; }
    request('/api/v1/orders/setCustomerData', 'POST', {
      orderId: this.modifyingOrder._id,
      personalData,
      phoneNumber,
      beautyPhone,
      birthday
    }).then((res) => {
      return true;
    })
      .catch((err) => {
        return false;
      })
  }

  setViewed = (orderId) => {
    request('/api/v1/orders/viewed', 'POST', {
      orderId: orderId,
    }).then((res) => {
      return true;
    })
      .catch((err) => {
        return false;
      })
  }
}

export default new OrdersRecords();
