class Field {
  constructor(newField) {
    this.title = (newField.title !== undefined) ? newField.title : { ru: 'Название', en: 'Title' };
    this.required = (newField.required !== undefined) ? newField.required : false;
    this.value = (newField.value !== undefined) ? newField.value : { ru: '', en: '' };
  }

  setTitle(language = 'ru', newValue) {
    this.title[language] = newValue;
  }

  setRequired(newValue) {
    this.required = newValue;
  }

  setType(newValue) {
    this.type = newValue;
  }

  setLength(newValue) {
    this.length = newValue;
  }

  setAdditional(key, newValue) {
    this.additional[key] = newValue;
  }

  setValue(language, newValue) {
    this.value[language] = newValue;
  }
}

export default Field;
