import styled from 'styled-components';
import { UiHeader } from '@compassplus/ui-mobicash';

export const StyledHeader = styled(UiHeader)`
  & {
    background: #FFFFFF;
  }
  
  & > div {
    max-width: 40.5rem;
    margin: auto;
    padding: 0;
  }
  & {
     box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  }
`;

export const CartItemsCount = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  color: #FFFFFF;
  background: #BE1E24;
  font-size: 9px;
  border-radius: 40px;
  height: 15px;
  width: 15px;
  text-align: center;
  font-weight: 700;
  line-height: 1.5;
`
