import { useTheme } from '../../../../../../../context/ThemeProvider';

const PathWithShadow = ({ fill }) => {

  const isTheme = useTheme();

  return (
    <>
      <g filter="url(#filter0_dd_6_19)">
        <path
          d="M4.91695 26.1383C3.69435 24.2217 3.69435 21.7783 4.91695 19.8617L15.8485 2.73249C16.9423 1.01807 18.8389 0 20.853 0H43.1469C45.1611 0 47.0574 1.01755 48.1512 2.73198L59.0827 19.8612C60.3053 21.7778 60.3053 24.2212 59.0827 26.1378L48.1512 43.267C47.0574 44.9814 45.1608 45.9995 43.1466 45.9995H20.8527C18.8386 45.9995 16.9423 44.9819 15.8485 43.2675L4.91695 26.1383Z"
          fill={ isTheme.styles.colors.memPad.background }
        />
      </g>
      <path
        d="M18.3777 41.6546L7.44618 24.5249C6.85127 23.5923 6.85127 22.4077 7.44618 21.4751L18.3777 4.34587C18.9119 3.50839 19.8472 3 20.853 3H43.147C44.1528 3 45.0881 3.50839 45.6223 4.34587L56.5538 21.4751C57.1487 22.4077 57.1487 23.5923 56.5538 24.5249L45.6223 41.6546C45.0881 42.4916 44.1528 43 43.147 43H20.853C19.8472 43 18.9119 42.4916 18.3777 41.6546Z"
        fill={ fill }
      />
      <defs>
        <filter id="filter0_dd_6_19" x="0" y="0" width="63.9997" height="53.9995" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="2"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6_19"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="1"/>
          <feGaussianBlur stdDeviation="0.5"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
          <feBlend mode="normal" in2="effect1_dropShadow_6_19" result="effect2_dropShadow_6_19"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_6_19" result="shape"/>
        </filter>
      </defs>
    </>
  );
};

export default PathWithShadow;