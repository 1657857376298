import React, { useReducer } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router';
import {
  UiSection, UiIcon, UiCard, UiInput, UiScrollArrow,
} from '@compassplus/ui-mobicash';
import { action } from 'mobx';
import AttributesStore from '../../../../stores/AttributesStore';
import i18n from '../../../../i18n';
import { getCurrency } from '../../../../Utils/requestUtil';

const RadioCheckboxValues = observer(({ language }) => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const history = useHistory();
  const { complexValue } = AttributesStore.modifyAttribute;

  if (!complexValue) {
    history.goBack();
  }

  const checkAttributeValueTitleUnique = (value, index) => {
    const title = value.title[language];
    let flag = true;
    AttributesStore.attributes.forEach((attribute, attributeIndex) => {
      if (attribute.complexValue) {
        attribute.complexValue.forEach((cValue, cValueIndex) => {
          if (AttributesStore.modeIsModify && attributeIndex === AttributesStore.id) {
            if (cValueIndex !== index) {
              if (title.toLowerCase() === cValue.title.ru.toLowerCase() || title.toLowerCase() === cValue.title.en.toLowerCase()) {
                flag = false;
              }
            }
          } else {
            if (title.toLowerCase() === cValue.title.ru.toLowerCase() || title.toLowerCase() === cValue.title.en.toLowerCase()) {
              flag = false;
            }
          }
        });
      }
    });

    value.isUniqueTitle = flag;
    forceUpdate();
  };

  const getPriceChange = (cValue) => {
    if (cValue.priceChangeForm === 'percent') {
      cValue.priceChange = cValue.percent;
      return Number(cValue.percent) !== 0 ? cValue.percent : '';
    }

    return Number(cValue.priceChange) !== 0 ? cValue.priceChange : '';
  }

  return (
    <>
      {
        complexValue.map((value, index) => (
          <UiCard
            key={`${value.title.en}-${index}`}
            style={{ margin: '0.25rem 0' }}
          >
            <UiInput
              description={i18n.t('Value')}
              placeholder={i18n.t('Value')}
              value={value.title[language]}
              onChange={action((event) => {
                complexValue[index].title[language] = event.target.value;
                forceUpdate();
              })}
              onBlur={() => checkAttributeValueTitleUnique(value, index)}
              warning={!value.isUniqueTitle}
              rightElement={(
                <UiIcon
                  icon="cancel"
                  setColor="#EC1310"
                  setWidth="1.125rem"
                  setHeight="1.125rem"
                  viewBox="0 0 28 28"
                  onClick={() => {
                    AttributesStore.modifyAttribute.deleteComplexValue(index);
                    forceUpdate();
                  }}
                />
              )}
              bottomDivider
              required
            />
            <UiInput
              description={`${i18n.t('Price')} ${complexValue[index].priceChangeForm === 'value' ? i18n.t(getCurrency()) : '%'}`}
              value={getPriceChange(value)}
              placeholder="0.00"
              onChange={action((event) => {
                event.currentTarget.value = event.currentTarget.value.replace(/[^0-9.]/g, '');
                complexValue[index].priceChange = event.currentTarget.value;
                if (complexValue[index].priceChangeForm === 'percent') {
                  complexValue[index].percent = event.currentTarget.value;
                }
                forceUpdate();
              })}
              onBlur={action((event) => {
                if (event.currentTarget.value !== '') {
                  event.currentTarget.value = Number(event.currentTarget.value).toFixed(2);
                  complexValue[index].priceChange = event.currentTarget.value;
                  if (complexValue[index].priceChangeForm === 'percent') {
                    complexValue[index].percent = event.currentTarget.value;
                  }
                  forceUpdate();
                } else {
                  complexValue[index].priceChange = '0.00';
                  forceUpdate();
                }
              })}
              rightElement={(
                <UiScrollArrow
                  onClick={action(() => {
                    if (complexValue[index].priceChangeForm === 'value') {
                      complexValue[index].priceChangeForm = 'percent';
                    } else {
                      complexValue[index].priceChangeForm = 'value';
                    }
                    forceUpdate();
                  })}
                />
              )}
            />
          </UiCard>
        ))
      }
      <UiCard style={{ margin: '0.25rem 0' }} shadow>
        <UiSection
          leftElement={(
            <UiIcon
              icon="legacy-plus"
              setColor="#037AFF"
            />
          )}
          onClick={() => { AttributesStore.modifyAttribute.addComplexValue(AttributesStore.modifyAttribute.type); forceUpdate(); }}
          title={i18n.t('AddValue')}
          view="link"
        />
      </UiCard>
    </>
  );
});

export default RadioCheckboxValues;
