import { useTheme } from '../../../context/ThemeProvider';

import UiBadge from '../../Ui/UiBadge';
import UiIcon from '../../Ui/UiIcon';

/**
 * Компонент значка Super-app
 * @public
 * @version 0.0.101
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiSuperAppBadge />
 */
const UiSuperAppBadge = (props) => {

  const isTheme = useTheme();

  const Icon = (props) => {

    return (
      <UiIcon { ...props } setWidth="0.75rem" setHeight="0.75rem" viewBox="0 0 12 12">
        <path
          d="M1.13378 5.24407C1.84287 5.24407 2.26755 4.92059 2.26755 4.41148V4.40964C2.26755 3.99242 2.00262 3.76635 1.4143 3.65608L1.11624 3.5991C0.792864 3.53845 0.648707 3.44104 0.648707 3.27378V3.27194C0.648707 3.08263 0.829878 2.95214 1.13183 2.9503C1.42209 2.9503 1.62469 3.07712 1.65391 3.28481L1.65586 3.30686H2.21106L2.20911 3.27194C2.18184 2.80694 1.78248 2.5 1.13183 2.5C0.508446 2.5 0.0603902 2.8198 0.0603902 3.30503V3.30686C0.0603902 3.7057 0.340912 3.95566 0.896112 4.06043L1.19222 4.11557C1.54092 4.18357 1.67924 4.27363 1.67924 4.45375V4.45559C1.67924 4.65776 1.47469 4.79194 1.14936 4.79194C0.82793 4.79194 0.596109 4.66328 0.559096 4.45743L0.5552 4.43721H0L0.00194807 4.46662C0.0350653 4.95551 0.465589 5.24407 1.13378 5.24407Z"
        />
        <path
          d="M3.69254 5.24407C4.41722 5.24407 4.86917 4.84891 4.86917 4.26077V2.54595H4.28086V4.21114C4.28086 4.55484 4.07046 4.77356 3.69254 4.77356C3.31461 4.77356 3.10227 4.55484 3.10227 4.21114V2.54595H2.51396V4.26077C2.51396 4.85075 2.97175 5.24407 3.69254 5.24407Z"
        />
        <path
          d="M5.22467 5.19812H5.81299V4.36185H6.35455C6.94676 4.36185 7.34222 4.00161 7.34222 3.45574V3.45206C7.34222 2.90619 6.94676 2.54595 6.35455 2.54595H5.22467V5.19812ZM6.21039 2.98522C6.54741 2.98522 6.74611 3.15248 6.74611 3.4539V3.45758C6.74611 3.759 6.54741 3.92809 6.21039 3.92809H5.81299V2.98522H6.21039Z"
        />
        <path
          d="M7.57693 5.19812H9.43929V4.74047H8.16525V4.07329H9.36721V3.63953H8.16525V3.0036H9.43929V2.54595H7.57693V5.19812Z"
        />
        <path
          d="M9.74998 5.19812H10.3383V4.23687H10.8097L11.3338 5.19812H12L11.4078 4.15416C11.7195 4.03837 11.9162 3.74797 11.9162 3.39876V3.39509C11.9162 2.86208 11.5422 2.54595 10.911 2.54595H9.74998V5.19812ZM10.3383 3.81598V2.97971H10.839C11.1292 2.97971 11.3143 3.14145 11.3143 3.39509V3.39876C11.3143 3.65975 11.139 3.81598 10.8467 3.81598H10.3383Z"
        />
        <path
          d="M2.29772 9.5H2.91526L3.12565 8.85671H4.11332L4.32371 9.5H4.9432L3.96527 6.84782H3.2737L2.29772 9.5ZM3.61462 7.36245H3.6263L3.97696 8.44134H3.26202L3.61462 7.36245Z"
        />
        <path
          d="M5.11752 9.5H5.70584V8.66373H6.24741C6.83962 8.66373 7.23508 8.30349 7.23508 7.75761V7.75394C7.23508 7.20806 6.83962 6.84782 6.24741 6.84782H5.11752V9.5ZM6.10325 7.2871C6.44026 7.2871 6.63897 7.45435 6.63897 7.75578V7.75945C6.63897 8.06088 6.44026 8.22997 6.10325 8.22997H5.70584V7.2871H6.10325Z"
        />
        <path
          d="M7.46979 9.5H8.05811V8.66373H8.59967C9.19188 8.66373 9.58734 8.30349 9.58734 7.75761V7.75394C9.58734 7.20806 9.19188 6.84782 8.59967 6.84782H7.46979V9.5ZM8.45551 7.2871C8.79253 7.2871 8.99123 7.45435 8.99123 7.75578V7.75945C8.99123 8.06088 8.79253 8.22997 8.45551 8.22997H8.05811V7.2871H8.45551Z"
        />
      </UiIcon>
    );
  };

  return (
    <UiBadge
      { ...props }
      icon={ <Icon /> }
      color={ isTheme.styles.colors.settingsGreen }
    >
    </UiBadge>
  );
};

export default UiSuperAppBadge;