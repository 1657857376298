import React, {useEffect, useReducer, useState} from 'react';
import {
  UiCard, UiDots, UiIcon, UiInput, UiSection, UiSwitch,
} from '@compassplus/ui-mobicash';
import { observer } from 'mobx-react-lite';

import i18n from '../../../../i18n';
import { DeleteSection } from '../../../TemplateStyle';
import DeliveryStore from '../../../../stores/DeliveryStore';

import ActionSheet from "../../ActionSheet";

const AddressFields = observer((props) => {
  const { language, id } = props;
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [selectionIsOpen, setSelectionIsOpen] = useState(new Array(DeliveryStore.form.addressData.fields.length));
  const [pointSelectionIsOpen, setPointSelectionIsOpen] = useState()

  useEffect(() => {
    setSelectionIsOpen(new Array(DeliveryStore.form.addressData.fields.length));
    selectionIsOpen.fill(false);
    if (id !== undefined && DeliveryStore.form.addressData.points.length !== 0) {
      let tmpArr = new Array(DeliveryStore.form.addressData.points[id].attributes.length);
      tmpArr.fill(false);
      setPointSelectionIsOpen(tmpArr);
    }
  },[DeliveryStore.form.addressData.fields.length, DeliveryStore.form.addressData.points])

  const onChangeSelectionIsOpen = (index) => {
    if (index !== 0) {
      selectionIsOpen.fill(false, 0, index - 1);
      selectionIsOpen.fill(false, index + 1);
    } else {
      selectionIsOpen.fill(false, 1);
    }
    if (index === 0) {
      setSelectionIsOpen([!selectionIsOpen[index], ...selectionIsOpen.slice(index + 1, selectionIsOpen.length)]);
    } else if (index === selectionIsOpen.length) {
      setSelectionIsOpen([...selectionIsOpen.slice(0, index - 1), !selectionIsOpen[index]]);
    } else {
      setSelectionIsOpen([...selectionIsOpen.slice(0, index), !selectionIsOpen[index], ...selectionIsOpen.slice(index + 1, selectionIsOpen.length)]);
    }
  };

  const onChangePointSelectionIsOpen = (index) => {
    if (index !== 0) {
      pointSelectionIsOpen.fill(false, 0, index - 1);
      pointSelectionIsOpen.fill(false, index + 1);
    } else {
      pointSelectionIsOpen.fill(false, 1);
    }

    if (index === 0) {
      setPointSelectionIsOpen([!pointSelectionIsOpen[index], ...pointSelectionIsOpen.slice(index + 1, pointSelectionIsOpen.length)]);
    } else if (index === selectionIsOpen.length) {
      setPointSelectionIsOpen([...pointSelectionIsOpen.slice(0, index - 1), !pointSelectionIsOpen[index]]);
    } else {
      setPointSelectionIsOpen([...pointSelectionIsOpen.slice(0, index), !pointSelectionIsOpen[index], ...pointSelectionIsOpen.slice(index + 1, pointSelectionIsOpen.length)]);
    }
  };

  const onFieldDelete = (index) => {
    DeliveryStore.form.addressData.deleteField(index);
    forceUpdate();
  };

  const getField = (field, index) => (
    <UiCard style={{ margin: '0.25rem 0', position: 'relative' }}>
      <UiInput
        description={i18n.t('Title')}
        placeholder={i18n.t('Title')}
        value={field.title[language]}
        onChange={(event) => { field.setTitle(language, event.target.value); forceUpdate(); }}
        rightElement={<UiDots onClick={() => {
          onChangeSelectionIsOpen(index);
        }} />}
        bottomDivider
      />

      <ActionSheet
        open={selectionIsOpen[index]}
        setOpen={() => { onChangeSelectionIsOpen(index); }}
      >
        {
          index !== 0 ? (
            <UiSection
              title={i18n.t('RaiseField')}
              onClick={() => {
                onChangeSelectionIsOpen(index);
                DeliveryStore.form.addressData.raisePoint(index);
                forceUpdate();
              }}
              view="link"
              bottomDivider
            />
          ) : null
        }
        {
          index !== DeliveryStore.form.addressData.fields.length - 1 ? (
            <UiSection
              title={i18n.t('PutDownField')}
              onClick={() => {
                onChangeSelectionIsOpen(index);
                DeliveryStore.form.addressData.putDownPoint(index);
                forceUpdate();
              }}
              view="link"
              bottomDivider
            />
          ) : null
        }
        <DeleteSection
          title={i18n.t('DeleteField')}
          onClick={() => { onChangeSelectionIsOpen(index); onFieldDelete(index) }}
        />
      </ActionSheet>

      <UiSection
        title={i18n.t('Required')}
        rightElement={(
          <UiSwitch
            checked={field.required}
            onClick={() => {
              field.setRequired(!field.required);
              forceUpdate();
            }}
          />
        )}
      />
    </UiCard>
  );

  const getPointField = (pointField, index) => (
    <div style={{ position: 'relative' }}>
      <UiInput
        description={pointField.title[i18n.language]}
        placeholder={pointField.title[language]}
        required
        value={pointField.value[language]}
        onChange={(event) => { pointField.setValue(language, event.target.value); forceUpdate(); }}
        rightElement={<UiDots onClick={() => onChangePointSelectionIsOpen(index)} />}
        bottomDivider={index !== DeliveryStore.form.addressData.points[id].attributes.length - 1}
        active={pointSelectionIsOpen && pointSelectionIsOpen[index]}
      />
      { pointSelectionIsOpen !== undefined ? (
        <>
            <ActionSheet
              open={pointSelectionIsOpen[index]}
              setOpen={() => { onChangePointSelectionIsOpen(index); }}
            >
              <>
                {
                  index !== 0 ? (
                    <UiSection
                      title={i18n.t('RaiseField')}
                      view="link"
                      onClick={() => {
                        onChangePointSelectionIsOpen(index);
                        DeliveryStore.form.addressData.points[id].raisePoint(index);
                        forceUpdate();
                      }}
                      bottomDivider
                    />
                  ) : null
                }
                {
                  index !== DeliveryStore.form.addressData.points[id].attributes.length - 1 ? (
                    <UiSection
                      title={i18n.t('PutDownField')}
                      view="link"
                      onClick={() => {
                        onChangePointSelectionIsOpen(index);
                        DeliveryStore.form.addressData.points[id].putDownPoint(index);
                        forceUpdate();
                      }}
                      bottomDivider
                    />
                  ) : null
                }
                <DeleteSection
                  title={i18n.t('DeleteField')}
                  onClick={() => { onChangePointSelectionIsOpen(index); DeliveryStore.form.addressData.points[id].attributes.splice(index, 1); forceUpdate(); }}
                />
              </>
            </ActionSheet>
        </>
      ) : null}
    </div>
  );

  return (
    <>
      {
        DeliveryStore.form.addressData.type === 'client' && DeliveryStore.form.addressData.fields.map((field, index) => (
          <React.Fragment key={`${field.title.en}-${index}`}>
            {getField(field, index)}
          </React.Fragment>
        ))
      }
      {
        DeliveryStore.form.addressData.type === 'points' && (
            <UiCard style={{ margin: '0.25rem 0' }}>
              <UiInput
                description={i18n.t('Title')}
                placeholder={i18n.t('Title')}
                value={DeliveryStore.form.addressData.points[id].title[language]}
                onChange={(event) => { DeliveryStore.form.addressData.points[id].setTitle(language, event.target.value); forceUpdate(); }}
                bottomDivider={true}
              />
            {
              DeliveryStore.form.addressData.points[id].attributes?.map((point, index) => (
                <React.Fragment key={`${point.title.en}-${index}`}>
                  {getPointField(point, index)}
                </React.Fragment>
              ))
            }
            </UiCard>
        )
      }
      {
        (DeliveryStore.form.addressData.type === 'points' && DeliveryStore.form.addressData.points[id].attributes.length === 5)
          ? null
          : (
            <UiCard style={{ margin: '0.25rem 0' }}>
              <UiSection
                leftElement={<UiIcon setColor="#037AFF" icon="legacy-plus" />}
                title={i18n.t('AddField')}
                view="link"
                onClick={() => {
                  if (DeliveryStore.form.addressData.type === 'points') {
                    DeliveryStore.form.addressData.points[id].addAttribute();
                  }
                  if (DeliveryStore.form.addressData.type === 'client') {
                    DeliveryStore.form.addressData.addField();
                  }
                  forceUpdate();
                }}
              />
            </UiCard>
          )
      }
    </>
  );
});

export default AddressFields;
