import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { MContentDiv } from '../../../components/TemplateStyle';
import Orders from './Orders';
import GlobalStateStore from '../../../stores/GlobalStateStore';
import i18n from '../../../i18n';

const OrdersPage = observer(() => {
  useEffect(() => {
    GlobalStateStore.setHeaderProperties({
      title: i18n.t('Orders'),
    });
  }, []);

  return (
    <MContentDiv>
      <Orders />
    </MContentDiv>
  );
});

export default OrdersPage;
