import PropTypes from 'prop-types';
import cn from 'classnames';
import styled from 'styled-components';

import { useTheme } from '../../../context/ThemeProvider';

import styles from './UiBody.module.css';

const StyledBody = styled.div`
  background: ${ (props) => props.styles.colors.applicationBackground };
`;

/**
 * Page body component
 * @public
 * @version 0.0.90
 * @param {String} className
 * @param {*} children
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiBody>{ children }</UiBody>
 */
const UiBody = ({
  className,
  children,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <StyledBody
      { ...props }
      className={ cn(styles.body, className) }
      styles={ isTheme.styles }
    >
      { children }
    </StyledBody>
  );
};

UiBody.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Определяет пользовательский контент */
  children: PropTypes.any
};

export default UiBody;