import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Иконка переводов
 * @public
 * @version 1.5.0
 * @param {String} [width="3.25rem"]
 * @param {String} [height="3.25rem"]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * import { UiTransfersIcon, UiPlates, UiPlate } from '@compassplus/ui-mobicash';
 *
 * const App = () => {
 *
 *   return (
 *     <>
 *       <UiTransfersIcon />
 *       <UiPlates>
 *         <UiPlate view="2x1" image={ <UiTransfersIcon width="100%" height="100%" /> } />
 *       </UiPlates>
 *     </>
 *   );
 * }; */
const UiTransfersIcon = ({
  width,
  height,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      innerRef={ innerRef }
      width={ width }
      height={ height }
      viewBox="0 0 52 52"
    >
      <path fillRule="evenodd" clipRule="evenodd"
        d="M4 27.0296V37.6246C4 38.5436 4.75 39.2886 5.675 39.2886H23.774C24.697 39.2886 25.453 38.5386 25.453 37.6216V27.0566C25.453 26.7746 25.222 26.5456 24.938 26.5456H4.487C4.218 26.5456 4 26.7626 4 27.0296Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M27.0728 31.4632V42.0592C27.0728 42.9782 27.8338 43.7232 28.7718 43.7232H47.1218C48.0568 43.7232 48.8228 42.9732 48.8228 42.0572V31.4902C48.8228 31.2082 48.5898 30.9792 48.3008 30.9792H27.5668C27.2948 30.9792 27.0728 31.1962 27.0728 31.4632Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M28.626 16.0828C27.106 15.6708 25.53 15.6758 24.019 16.0948V33.0488C25.533 33.4688 27.108 33.4738 28.626 33.0618V16.0828Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M23.624 16.4431V12.0771V10.8751V8.55012C23.624 8.00712 23.184 7.56812 22.642 7.56812H6.659C6.116 7.56812 5.677 8.00712 5.677 8.55012V35.6241C5.677 36.1671 6.116 36.6061 6.659 36.6061H22.642C23.184 36.6061 23.624 36.1671 23.624 35.6241V31.8011"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M22.6415 37.1062H6.65951C5.84151 37.1062 5.17651 36.4412 5.17651 35.6242V8.55023C5.17651 7.73223 5.84151 7.06723 6.65951 7.06723H22.6415C23.4585 7.06723 24.1235 7.73223 24.1235 8.55023V16.4432C24.1235 16.7192 23.9005 16.9432 23.6235 16.9432C23.3475 16.9432 23.1235 16.7192 23.1235 16.4432V8.55023C23.1235 8.28423 22.9075 8.06723 22.6415 8.06723H6.65951C6.39251 8.06723 6.17651 8.28423 6.17651 8.55023V35.6242C6.17651 35.8902 6.39251 36.1062 6.65951 36.1062H22.6415C22.9075 36.1062 23.1235 35.8902 23.1235 35.6242V31.8012C23.1235 31.5252 23.3475 31.3012 23.6235 31.3012C23.9005 31.3012 24.1235 31.5252 24.1235 31.8012V35.6242C24.1235 36.4412 23.4585 37.1062 22.6415 37.1062Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M28.9741 31.7439V40.9109C28.9741 41.4529 29.4141 41.8929 29.9571 41.8929H45.9391C46.4811 41.8929 46.9221 41.4529 46.9221 40.9109V36.6249V35.8299V35.6739V25.1699V17.3639V16.1619V13.8369C46.9221 13.2939 46.4811 12.8539 45.9391 12.8539H29.9571C29.4141 12.8539 28.9741 13.2939 28.9741 13.8369V16.6979"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M45.9391 42.3928H29.9571C29.1391 42.3928 28.4741 41.7278 28.4741 40.9108V31.7438C28.4741 31.4678 28.6981 31.2438 28.9741 31.2438C29.2511 31.2438 29.4741 31.4678 29.4741 31.7438V40.9108C29.4741 41.1768 29.6911 41.3928 29.9571 41.3928H45.9391C46.2051 41.3928 46.4221 41.1768 46.4221 40.9108V13.8368C46.4221 13.5708 46.2051 13.3538 45.9391 13.3538H29.9571C29.6911 13.3538 29.4741 13.5708 29.4741 13.8368V16.6978C29.4741 16.9738 29.2511 17.1978 28.9741 17.1978C28.6981 17.1978 28.4741 16.9738 28.4741 16.6978V13.8368C28.4741 13.0188 29.1391 12.3538 29.9571 12.3538H45.9391C46.7571 12.3538 47.4221 13.0188 47.4221 13.8368V40.9108C47.4221 41.7278 46.7571 42.3928 45.9391 42.3928Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M20.5907 31.5027H9.07972C8.46172 31.5027 7.95972 31.0207 7.95972 30.4287V10.9987C7.95972 10.4057 8.46172 9.92374 9.07972 9.92374H20.5907C21.2087 9.92374 21.7107 10.4057 21.7107 10.9987V12.4977C21.7107 12.6357 21.5987 12.7477 21.4607 12.7477C21.3227 12.7477 21.2107 12.6357 21.2107 12.4977V10.9987C21.2107 10.6817 20.9327 10.4237 20.5907 10.4237H9.07972C8.73772 10.4237 8.45972 10.6817 8.45972 10.9987V30.4287C8.45972 30.7447 8.73772 31.0027 9.07972 31.0027H20.5907C20.9327 31.0027 21.2107 30.7447 21.2107 30.4287C21.2107 30.2907 21.3227 30.1787 21.4607 30.1787C21.5987 30.1787 21.7107 30.2907 21.7107 30.4287C21.7107 31.0207 21.2087 31.5027 20.5907 31.5027Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M14.8356 33.1673C14.4016 33.1673 14.0476 33.5203 14.0476 33.9543C14.0476 34.3883 14.4016 34.7423 14.8356 34.7423C15.2696 34.7423 15.6226 34.3883 15.6226 33.9543C15.6226 33.5203 15.2696 33.1673 14.8356 33.1673ZM14.8356 35.2423C14.1256 35.2423 13.5476 34.6643 13.5476 33.9543C13.5476 33.2453 14.1256 32.6673 14.8356 32.6673C15.5446 32.6673 16.1226 33.2453 16.1226 33.9543C16.1226 34.6643 15.5446 35.2423 14.8356 35.2423Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M21.4609 17.9412C21.3229 17.9412 21.2109 17.8292 21.2109 17.6912V11.2082C21.2109 11.0702 21.3229 10.9582 21.4609 10.9582C21.5989 10.9582 21.7109 11.0702 21.7109 11.2082V17.6912C21.7109 17.8292 21.5989 17.9412 21.4609 17.9412Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M31.5073 18.3572C31.3693 18.3572 31.2573 18.2452 31.2573 18.1072V16.1892C31.2573 15.6492 31.7593 15.2112 32.3773 15.2112H43.8883C44.5063 15.2112 45.0083 15.6492 45.0083 16.1892V17.5142C45.0083 17.6522 44.8973 17.7642 44.7583 17.7642C44.6203 17.7642 44.5083 17.6522 44.5083 17.5142V16.1892C44.5083 15.9252 44.2303 15.7112 43.8883 15.7112H32.3773C32.0353 15.7112 31.7573 15.9252 31.7573 16.1892V18.1072C31.7573 18.2452 31.6453 18.3572 31.5073 18.3572Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M43.8888 36.7893H32.3768C31.7598 36.7893 31.2578 36.3073 31.2578 35.7153V30.1953C31.2578 30.0573 31.3688 29.9453 31.5078 29.9453C31.6458 29.9453 31.7578 30.0573 31.7578 30.1953V35.7153C31.7578 36.0313 32.0348 36.2893 32.3768 36.2893H43.8888C44.2308 36.2893 44.5088 36.0313 44.5088 35.7153V34.2613C44.5088 34.1223 44.6208 34.0113 44.7588 34.0113C44.8968 34.0113 45.0088 34.1223 45.0088 34.2613V35.7153C45.0088 36.3073 44.5068 36.7893 43.8888 36.7893Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M38.1327 38.4534C37.6987 38.4534 37.3457 38.8074 37.3457 39.2414C37.3457 39.6754 37.6987 40.0284 38.1327 40.0284C38.5667 40.0284 38.9207 39.6754 38.9207 39.2414C38.9207 38.8074 38.5667 38.4534 38.1327 38.4534ZM38.1327 40.5284C37.4237 40.5284 36.8457 39.9504 36.8457 39.2414C36.8457 38.5314 37.4237 37.9534 38.1327 37.9534C38.8427 37.9534 39.4207 38.5314 39.4207 39.2414C39.4207 39.9504 38.8427 40.5284 38.1327 40.5284Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M44.7588 36.096C44.6208 36.096 44.5088 35.984 44.5088 35.846V21.265C44.5088 21.127 44.6208 21.015 44.7588 21.015C44.8968 21.015 45.0088 21.127 45.0088 21.265V35.846C45.0088 35.984 44.8968 36.096 44.7588 36.096Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M34.1402 24.157C34.1402 28.474 30.6402 31.975 26.3222 31.975C22.0042 31.975 18.5042 28.474 18.5042 24.157C18.5042 19.839 22.0042 16.339 26.3222 16.339C30.6402 16.339 34.1402 19.839 34.1402 24.157Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M32.0529 23.4798C31.9319 23.4798 31.8249 23.3918 31.8059 23.2678C31.3949 20.5778 29.0289 18.5498 26.3029 18.5498C23.6409 18.5498 21.3419 20.4428 20.8369 23.0518C20.8099 23.1878 20.6769 23.2718 20.5439 23.2508C20.4079 23.2238 20.3199 23.0928 20.3459 22.9578C20.8969 20.1138 23.4019 18.0498 26.3029 18.0498C29.2739 18.0498 31.8519 20.2608 32.3009 23.1918C32.3219 23.3288 32.2279 23.4558 32.0909 23.4768C32.0779 23.4788 32.0659 23.4798 32.0529 23.4798Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M26.3029 30.2771C23.4019 30.2771 20.8969 28.2131 20.3459 25.3691C20.3199 25.2341 20.4079 25.1031 20.5439 25.0761C20.6779 25.0501 20.8109 25.1381 20.8369 25.2751C21.3419 27.8831 23.6409 29.7771 26.3029 29.7771C29.0289 29.7771 31.3949 27.7491 31.8059 25.0591C31.8269 24.9231 31.9569 24.8311 32.0909 24.8501C32.2279 24.8711 32.3219 24.9981 32.3009 25.1341C31.8519 28.0661 29.2739 30.2771 26.3029 30.2771Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M24.9585 40.8767L23.1265 39.7567C23.0105 39.6847 22.8555 39.7207 22.7835 39.8397C22.7115 39.9577 22.7485 40.1107 22.8665 40.1837L24.2725 41.0437C24.2565 41.0447 24.2435 41.0337 24.2275 41.0387C22.0555 41.6597 19.6695 40.8767 18.2915 39.0877C18.2075 38.9787 18.0505 38.9587 17.9415 39.0427C17.8325 39.1267 17.8115 39.2847 17.8955 39.3937C19.0545 40.8977 20.8655 41.7497 22.7195 41.7497C23.2335 41.7497 23.7505 41.6767 24.2585 41.5417L23.5535 42.6957C23.4815 42.8137 23.5185 42.9677 23.6365 43.0397C23.6765 43.0647 23.7215 43.0767 23.7665 43.0767C23.8505 43.0767 23.9325 43.0337 23.9795 42.9567L25.0415 41.2207C25.1135 41.1027 25.0765 40.9487 24.9585 40.8767Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M32.5402 10.6834C31.0592 8.76243 28.5152 7.91143 26.1752 8.53643L26.8822 7.38143C26.9542 7.26343 26.9172 7.10943 26.7992 7.03743C26.6832 6.96443 26.5282 7.00143 26.4562 7.12043L25.3942 8.85643C25.3222 8.97443 25.3592 9.12743 25.4772 9.20043L27.3082 10.3204C27.3492 10.3454 27.3942 10.3574 27.4382 10.3574C27.5222 10.3574 27.6042 10.3144 27.6522 10.2374C27.7242 10.1204 27.6862 9.96643 27.5692 9.89343L26.1632 9.03343C26.1792 9.03243 26.1922 9.04343 26.2082 9.03843C28.3792 8.41543 30.7652 9.19943 32.1442 10.9894C32.1932 11.0524 32.2672 11.0864 32.3422 11.0864C32.3952 11.0864 32.4492 11.0694 32.4952 11.0344C32.6042 10.9504 32.6242 10.7924 32.5402 10.6834Z"
        fill={ isTheme.styles.colors.textTitle } />
    </Element>
  );
};

UiTransfersIcon.propTypes = {
  /** Определяет ширину иконки */
  width: PropTypes.string,
  /** Определяет высоту иконки */
  height: PropTypes.string
};

UiTransfersIcon.defaultProps = {
  width: '3.25rem',
  height: '3.25rem'
};

export default UiTransfersIcon;