import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import UiInput from '../../Ui/UiInput';

import useMobile from '../../../hooks/use-mobile';

import { createMobile, findCountry, formatMobile, isValidMobile, validateMobile } from '../../../utils/mobileUtils';

/**
 * Input mobile component
 * @public
 * @version 0.0.90
 * @param {String} id
 * @param {Object[]} forms
 * @param {String} forms[].text
 * @param {String} forms[].value
 * @param {Boolean} forms[].valid
 * @param {String} forms[].alpha2
 * @param {Function} setForm
 * @param {Object} state
 * @param {String} state.text
 * @param {String} state.value
 * @param {Boolean} state.valid
 * @param {String} state.alpha2
 * @param {Function} setState
 * @param {String} defaultValue
 * @param {Boolean} [warning=false]
 * @param {Function} onFocus
 * @param {Function} onBlur
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiInputMobile />
 */
const UiInputMobile = ({
  id,
  forms,
  setForm,
  state,
  setState,
  defaultValue,
  warning,
  onFocus,
  onBlur,
  ...props
}) => {

  const [mobile, { setMobile }] = useMobile(defaultValue);
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    if (Array.isArray(forms) && typeof setForm === 'function' && !!id) {
      const form = forms.find((item) => item.id === id);

      if (typeof form === 'object' && form.text !== undefined && form.value !== undefined) {
        setMobile(form.text);
      } else {
        setForm(id, {
          text: formatMobile(defaultValue),
          value: validateMobile(defaultValue),
          valid: isValidMobile(defaultValue),
          alpha2: findCountry(defaultValue)?.alpha2 || 'default'
        });
        setMobile(defaultValue);
      }
    } else if (typeof state === 'object' && typeof setState === 'function') {
      if (state.text !== undefined && state.value !== undefined) {
        setMobile(state.text);
      } else {
        setState({
          text: formatMobile(defaultValue),
          value: validateMobile(defaultValue),
          valid: isValidMobile(defaultValue),
          alpha2: findCountry(defaultValue)?.alpha2 || 'default'
        });
        setMobile(defaultValue);
      }
    } else {
      setMobile(defaultValue);
    }
  }, [id, forms, setForm, state, setState, defaultValue, setMobile]);

  return (
    <UiInput
      { ...props }
      flag={ mobile.alpha2 }
      value={ !focus && mobile.value.length === 0 ? '' : mobile.valueFormat }
      warning={ !focus && (!mobile.valueValid || warning) }
      onChange={ (event) => {
        const nextMobile = createMobile(event.target.value);

        if (JSON.stringify(nextMobile) !== JSON.stringify(mobile)) {
          const { value, valueFormat: text, valueValid: valid, alpha2 = 'default' } = nextMobile;

          if (typeof setForm === 'function') {
            setForm(id, { text, value, valid, alpha2 });
          } else if (typeof setState === 'function') {
            setState({ text, value, valid, alpha2 });
          } else {
            setMobile(value);
          }
        }
      } }
      onFocus={ (event) => {
        setFocus(true);

        if (typeof onFocus === 'function') {
          onFocus(event);
        }
      } }
      onBlur={ (event) => {
        setFocus(false);

        if (typeof onBlur === 'function') {
          onBlur(event);
        }
      } }
    />
  );
};

UiInputMobile.propTypes = {
  /** Определяет уникальный идентификатор */
  id: PropTypes.string,
  /** Определяет массив объектов хука useForm (UI) */
  forms: PropTypes.array,
  /** Функция обработчик хука useForm (UI) */
  setForm: PropTypes.func,
  /** Определяет объект хука useState (React) */
  state: PropTypes.object,
  /** Функция обработчик хука useState (React) */
  setState: PropTypes.func,
  /** Определяет значение по умолчанию */
  defaultValue: PropTypes.string,
  /** Текст (value) и примечание (notes) становятся красными */
  warning: PropTypes.bool,
  /** Обработчик получения фокуса на элементе */
  onFocus: PropTypes.func,
  /** Обработчик потери фокуса на элементе */
  onBlur: PropTypes.func
};

UiInputMobile.defaultProps = {
  defaultValue: ''
};

export default UiInputMobile;