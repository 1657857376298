import PropTypes from 'prop-types';
import cn from 'classnames';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../../../context/ThemeProvider';

import TextArea from '../TextArea';

import styles from './Input.module.css';

const StyledInput = styled.input`
  color: ${ (props) => props.styles.colors.textLink };
  text-align: start;
  
  ${ (props) => {
    if (props.typography === 'dialogText') {
      return css`
        ${ props.styles.typography.DialogText };
        min-height: ${ props.styles.typography.DialogText['line-height'] };
      `;
    } else {
      return css`
        ${ props.styles.typography.SectionValue };
      `;
    }
  } }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${ (props) => props.styles.colors.textDisable };
  }

  :-moz-placeholder {
    color: ${ (props) => props.styles.colors.textDisable };
    opacity: 1;
  }

  ::-moz-placeholder {
    color: ${ (props) => props.styles.colors.textDisable };
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: ${ (props) => props.styles.colors.textDisable };
  }

  ${ (props) => props.readOnly && css`
    cursor: default;
  ` }

  ${ (props) => props.readOnly && props.onClick && css`
    cursor: pointer;
  ` }

  ${ (props) => props.warning && css`
    color: ${ props.styles.colors.warningColor };
  ` }
`;

const StyledText = styled.span`
  color: ${ (props) => props.styles.colors.textReadOnly };
  
  ${ (props) => props.oneLiner && css`
    white-space: nowrap;
  ` }

  ${ (props) => {
    if (props.typography === 'dialogText') {
      return css`
        ${ props.styles.typography.DialogText };
        min-height: ${ props.styles.typography.DialogText['line-height'] };
      `;
    } else {
      return css`
        ${ props.styles.typography.SectionValue };
      `;
    }
  } }
`;

const Input = ({
  className,
  disabled,
  readOnly,
  warning,
  type,
  typography,
  value,
  onClick,
  onChange,
  onBlur,
  onFocus,
  placeholder,
  oneLiner,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  const handlerType = (type) => {
    switch (type) {
      case 'textarea':
        return <TextArea
          { ...props }
          className={ className }
          disabled={ disabled }
          value={ value }
          readOnly={ readOnly }
          placeholder={ placeholder }
          onClick={ onClick }
          onChange={ onChange }
          onBlur={ onBlur }
          onFocus={ onFocus }
          innerRef={ innerRef }
        />;
      case 'readonly':
        return <StyledText
          { ...props }
          className={ cn(styles.input, styles.text, className) }
          typography={ typography }
          oneLiner={ oneLiner }
          ref={ innerRef }
          styles={ isTheme.styles }
        >
          { value }
        </StyledText>;
      default:
        return <StyledInput
          { ...props }
          className={ cn(styles.input, className) }
          disabled={ disabled }
          readOnly={ readOnly }
          warning={ warning }
          type={ type }
          typography={ typography }
          value={ value }
          placeholder={ placeholder }
          onClick={ onClick }
          onChange={ onChange }
          onBlur={ onBlur }
          onFocus={ onFocus }
          ref={ innerRef }
          styles={ isTheme.styles }
        />;
    }
  }

  return handlerType(type);
};

Input.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  warning: PropTypes.bool,
  type: PropTypes.string,
  typography: PropTypes.oneOf(['sectionValue', 'dialogText']),
  value: PropTypes.any,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  oneLiner: PropTypes.bool,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

Input.defaultProps = {
  typography: 'sectionValue',
  placeholder: 'Default',
  onChange: () => {},
  oneLiner: false
};

export default Input;