import { escapeRegExp } from './regExpUtils';
import { removeInvalidChars, replaceDecimalSeparator } from './stringUtils';

/**
 * Значение формата с десятичным разделителем
 * @public
 * @version 1.8.0
 * @param {String} [value='']
 * @param {'.'|','} [decimalSeparator='.']
 * @return {String}
 * @constructor
 * @example
 * const value = formatValue({ value: '1000.00', decimalSeparator: ',' });
 *
 * console.log(value);
 * // 1000,00 */
export function formatValue({ value: _value = '', decimalSeparator = '.' }) {
  if (_value === '' || _value === '-') {
    return _value;
  }

  const reg = new RegExp(`^\\d?-\\d`);
  const isNegative = reg.test(_value);

  const value =
    decimalSeparator !== '.'
      ? replaceDecimalSeparator(_value, decimalSeparator, isNegative)
      : _value;

  const defaultNumberFormatOptions = {
    useGrouping: false,
    minimumFractionDigits: 0,
    maximumFractionDigits: 17
  };

  const numberFormatter = new Intl.NumberFormat(undefined, defaultNumberFormatOptions);

  const parts = numberFormatter.formatToParts(Number(value));
  let formatted = replaceParts(parts, { decimalSeparator });

  const includeDecimalSeparator = _value.slice(-1) === decimalSeparator ? decimalSeparator : '';

  const [, decimals] = value.match(RegExp('\\d+\\.(\\d+)')) || [];

  if (decimals && decimalSeparator) {
    if (formatted.includes(decimalSeparator)) {
      formatted = formatted.replace(RegExp(`(\\d+)(${ escapeRegExp(decimalSeparator) })(\\d+)`, 'g'), `$1$2${ decimals }`);
    } else {
      formatted = `${ formatted }${ decimalSeparator }${ decimals }`;
    }
  }

  return [formatted, includeDecimalSeparator].join('');
}
/**
 * Удалить десятичный разделитель и лишние десятичные знаки из значения
 * @public
 * @version 1.8.0
 * @param {String} [value]
 * @param {Boolean} [allowDecimals=true]
 * @param {Boolean} [allowNegativeValue=true]
 * @param {Number} [decimalsLimit=2]
 * @param {'.'|','} [decimalSeparator]
 * @param {Function} [transformRawValue]
 * @return {String}
 * @constructor
 * @example
 * const value = cleanValue({
 *   value: '7 000.05',
 *   allowDecimals: true,
 *   allowNegativeValue: true,
 *   decimalsLimit: 2,
 *   decimalSeparator: ',',
 *   transformRawValue: (value) => value
 * });
 *
 * console.log(value);
 * // 7000.05 */
export function cleanValue({
  value = '',
  allowDecimals = true,
  allowNegativeValue = true,
  decimalsLimit = 2,
  decimalSeparator = '.',
  transformRawValue = (rawValue) => rawValue
}) {
  const transformedValue = transformRawValue(value);

  if (transformedValue === '-') {
    if (allowNegativeValue) {
      return transformedValue;
    } else {
      return '';
    }
  }

  const reg = new RegExp(`((^|\\D)-\\d)|(-)`);
  const isNegative = reg.test(transformedValue);

  const withoutInvalidChars = removeInvalidChars(transformedValue, [decimalSeparator]);

  return fixedNegativeValue({
    value: withoutInvalidChars,
    allowDecimals,
    allowNegativeValue,
    decimalsLimit,
    decimalSeparator,
    isNegative
  });
}

const replaceParts = (parts = [], { decimalSeparator = '.' }) => {
  return parts
    .reduce(
      (prev, { type, value }) => {
        if (type === 'decimal') {
          return [...prev, !!decimalSeparator ? decimalSeparator : value];
        }

        return [...prev, value];
      },
      ['']
    )
    .join('');
};

/**
 * Зафиксировать десятичное значение
 * @public
 * @version 1.8.0
 * @param {String} [value]
 * @param {String} [decimalSeparator]
 * @param {Number} [fixedDecimalLength=0]
 * @return {String}
 * @constructor
 * @example
 * const value = fixedDecimalValue('1000', ',', 2);
 *
 * console.log(value);
 * // 1000,00 */
export function fixedDecimalValue(value = '', decimalSeparator, fixedDecimalLength = 0) {
  if (fixedDecimalLength && !!value) {
    if (value.includes(decimalSeparator)) {
      const [int, decimals] = value.split(decimalSeparator);

      if (decimals.length > fixedDecimalLength) {
        return `${ int }${ decimalSeparator }${ decimals.slice(0, fixedDecimalLength) }`;
      } else {
        return `${ int }${ decimalSeparator }${ decimals }${ '0'.repeat(fixedDecimalLength - decimals.length) }`;
      }
    } else {
      return `${ value }${ decimalSeparator }${ '0'.repeat(fixedDecimalLength) }`;
    }
  }

  return value;
}

export function padTrimValue(value = '', decimalSeparator = '.') {
  if (value === '') {
    return value;
  }

  if (!value.match(/\d/g)) {
    return '';
  }

  const [int, decimals] = value.split(decimalSeparator);

  if (decimals && decimals.length > 0) {
    return `${ int }${ decimalSeparator }${ decimals }`;
  } else {
    return `${ int }`;
  }
}

export function fixedNegativeValue({
  value = '',
  allowDecimals = true,
  allowNegativeValue = true,
  decimalsLimit = 2,
  decimalSeparator = '.',
  isNegative = false
}) {
  const includeNegative = isNegative && allowNegativeValue ? '-' : '';

  if (decimalSeparator && value.includes(decimalSeparator)) {
    const [int, decimals] = value.split(decimalSeparator);
    const trimmedDecimals = decimalsLimit && decimals ? decimals.slice(0, decimalsLimit) : decimals;
    const includeDecimals = allowDecimals ? `${ decimalSeparator }${ trimmedDecimals }` : '';

    return `${ includeNegative }${ int }${ includeDecimals }`;
  }

  return `${ includeNegative }${ value }`;
}