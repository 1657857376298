import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Plate event log icon
 * @public
 * @version 1.3.0
 * @param {String} width
 * @param {String} height
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiEventLogIcon />
 */
const UiEventLogIcon = ({
  width,
  height,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      innerRef={ innerRef }
      width={ width }
      height={ height }
      viewBox="0 0 52 52"
    >
      <path fillRule="evenodd" clipRule="evenodd"
        d="M46.561 15.1728V15.0048C46.561 13.5368 45.373 12.3308 43.822 12.1068C43.585 12.0608 43.34 12.0328 43.087 12.0328H41.466H38.597H31.665C31.51 10.5308 30.112 9.35181 28.411 9.35181C26.711 9.35181 25.313 10.5308 25.157 12.0328H18.323H14.121H13.734C13.482 12.0328 13.237 12.0608 12.999 12.1068C11.449 12.3308 10.261 13.5378 10.261 15.0048V15.1728V23.0158V26.9548V30.5608C9.742 30.8168 9.053 31.2248 8.596 31.7088C7.74 32.6138 7.268 33.7648 7.268 34.9478V36.2708C7.268 37.6848 6.712 38.8788 5.521 40.0208C4.647 40.1828 4 40.8928 4 41.7078C4 42.6568 4.854 43.4288 5.904 43.4288H10.098C10.412 44.3698 11.373 45.0378 12.477 45.0378C13.58 45.0378 14.541 44.3698 14.855 43.4288H19.05C20.1 43.4288 20.954 42.6568 20.954 41.7078C20.954 41.3128 20.796 40.9458 20.54 40.6508H43.309C45.105 40.6508 46.561 39.3328 46.561 37.7068V37.5108V37.5098V36.1848V17.4298V15.1728Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M41.7407 8.76512C41.5227 8.71812 41.2977 8.69012 41.0667 8.69012H14.1357C13.9037 8.69012 13.6787 8.71812 13.4607 8.76512C12.0387 8.99212 10.9487 10.2121 10.9487 11.6961V27.4311C10.4857 27.6831 15.9637 37.3751 16.2797 37.6391C16.2817 37.6411 20.3807 37.6381 20.3837 37.6381H41.2337C42.9017 37.6381 44.2537 36.2861 44.2537 34.6181V34.4611V11.6961C44.2537 10.2121 43.1627 8.99212 41.7407 8.76512Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M36.3765 36.0199H14.5225C13.4535 36.0199 12.5835 35.1499 12.5835 34.0819V12.2529C12.5835 11.1839 13.4535 10.3139 14.5225 10.3139H18.2175C18.3555 10.3139 18.4675 10.4259 18.4675 10.5639C18.4675 10.7029 18.3555 10.8139 18.2175 10.8139H14.5225C13.7295 10.8139 13.0835 11.4599 13.0835 12.2529V34.0819C13.0835 34.8739 13.7295 35.5199 14.5225 35.5199H36.3765C36.5975 35.5199 36.7775 35.3399 36.7775 35.1189V31.0479C36.7775 30.9099 36.8995 30.7879 37.0385 30.7879H40.8345C40.9725 30.7879 41.0845 30.8989 41.0845 31.0379C41.0845 31.1759 40.9725 31.2879 40.8345 31.2879H37.2775V35.1189C37.2775 35.6159 36.8735 36.0199 36.3765 36.0199Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M38.8553 36.0199C38.7983 36.0199 38.7423 36.0009 38.6953 35.9619C38.5893 35.8739 38.5753 35.7159 38.6633 35.6099L41.8213 31.8149C41.9963 31.6049 42.0923 31.3359 42.0923 31.0589V11.9739C42.0923 11.3339 41.5913 10.8139 40.9743 10.8139H37.0273C36.8893 10.8139 36.7773 10.7029 36.7773 10.5639C36.7773 10.4259 36.8893 10.3139 37.0273 10.3139H40.9743C41.8663 10.3139 42.5923 11.0589 42.5923 11.9739V31.0589C42.5923 31.4529 42.4553 31.8349 42.2063 32.1349L39.0473 35.9299C38.9983 35.9889 38.9273 36.0199 38.8553 36.0199Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M25.1963 26.349C25.1383 26.349 25.0813 26.329 25.0343 26.289L20.2703 22.243C20.1643 22.154 20.1523 21.996 20.2413 21.891C20.3313 21.786 20.4883 21.773 20.5933 21.862L25.1933 25.768L35.9363 16.309C36.0403 16.218 36.1973 16.228 36.2893 16.332C36.3803 16.435 36.3703 16.593 36.2663 16.684L25.3613 26.287C25.3143 26.328 25.2553 26.349 25.1963 26.349Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M11.0317 24.2812C10.7557 24.2812 10.5317 24.0572 10.5317 23.7812V11.7142C10.5317 9.80219 12.0867 8.24719 13.9987 8.24719H18.2487C18.5247 8.24719 18.7487 8.47019 18.7487 8.74719C18.7487 9.02319 18.5247 9.24719 18.2487 9.24719H13.9987C12.6387 9.24719 11.5317 10.3542 11.5317 11.7142V23.7812C11.5317 24.0572 11.3077 24.2812 11.0317 24.2812Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M41.1875 38.0605H13.9995C12.0865 38.0605 10.5315 36.5055 10.5315 34.5935C10.5315 34.3165 10.7555 34.0935 11.0315 34.0935C11.3085 34.0935 11.5315 34.3165 11.5315 34.5935C11.5315 35.9535 12.6385 37.0605 13.9995 37.0605H41.1875C42.5475 37.0605 43.6545 35.9535 43.6545 34.5935V11.7145C43.6545 10.3535 42.5475 9.2475 41.1875 9.2475H37.0275C36.7505 9.2475 36.5275 9.0235 36.5275 8.7475C36.5275 8.4705 36.7505 8.2475 37.0275 8.2475H41.1875C43.0995 8.2475 44.6545 9.8025 44.6545 11.7145V34.5935C44.6545 36.5055 43.0995 38.0605 41.1875 38.0605Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M19.1507 37.3964C17.9627 36.1594 17.4077 34.8604 17.4077 33.3234V31.7614C17.4077 30.4944 16.8657 29.2744 15.9197 28.4154C15.4607 27.9974 14.9237 27.6814 14.3467 27.4794C14.3467 27.4794 12.7337 26.8344 11.3197 27.4854C10.8567 27.6864 9.95269 28.2014 9.42969 28.8134C8.67469 29.6964 8.25869 30.8174 8.25869 31.9684V33.3234C8.25869 34.8604 7.70369 36.1594 6.51569 37.3964C5.78969 37.5074 5.23169 38.1364 5.23169 38.8924C5.23169 39.7274 5.91069 40.4064 6.74469 40.4064H18.9217C19.7557 40.4064 20.4357 39.7274 20.4357 38.8924C20.4357 38.1364 19.8777 37.5074 19.1507 37.3964Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M10.8164 40.4062C11.0074 41.3452 11.8394 42.0532 12.8334 42.0532C13.8284 42.0532 14.6594 41.3452 14.8514 40.4062H10.8164Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M18.9217 40.1562H14.8507H10.8167H6.74469C6.04769 40.1562 5.48169 39.5892 5.48169 38.8922C5.48169 38.2792 5.93569 37.7482 6.53969 37.6482H19.1277C19.7307 37.7482 20.1857 38.2792 20.1857 38.8922C20.1857 39.5892 19.6187 40.1562 18.9217 40.1562ZM12.8337 41.8032C12.0827 41.8032 11.4177 41.3362 11.1497 40.6562H14.5167C14.2487 41.3362 13.5837 41.8032 12.8337 41.8032ZM8.50869 33.3232V31.9682C8.50869 30.8772 8.90269 29.8142 9.61969 28.9752C10.0977 28.4162 10.9577 27.9152 11.4237 27.7122C11.8587 27.5122 12.3167 27.4462 12.7357 27.4462C13.5697 27.4462 14.2477 27.7092 14.2647 27.7162C14.8197 27.9102 15.3197 28.2072 15.7517 28.6002C16.6457 29.4122 17.1577 30.5652 17.1577 31.7612V33.3232C17.1577 34.7592 17.6197 35.9862 18.5947 37.1482H7.07169C8.04669 35.9862 8.50869 34.7592 8.50869 33.3232ZM19.2757 37.1652C18.1717 35.9942 17.6577 34.7722 17.6577 33.3232V31.7612C17.6577 30.4242 17.0857 29.1372 16.0887 28.2302C15.6067 27.7922 15.0487 27.4602 14.4397 27.2482C14.3707 27.2192 12.7097 26.5712 11.2197 27.2562C10.7867 27.4442 9.81469 27.9782 9.23969 28.6502C8.44569 29.5802 8.00869 30.7582 8.00869 31.9682V33.3232C8.00869 34.7722 7.49469 35.9942 6.39169 37.1652C5.58169 37.3312 4.98169 38.0582 4.98169 38.8922C4.98169 39.8652 5.77269 40.6562 6.74469 40.6562H10.6307C10.9207 41.6202 11.8107 42.3032 12.8337 42.3032C13.8557 42.3032 14.7457 41.6202 15.0367 40.6562H18.9217C19.8947 40.6562 20.6857 39.8652 20.6857 38.8922C20.6857 38.0582 20.0857 37.3312 19.2757 37.1652Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M13.957 26.0712C13.957 26.6912 13.454 27.1952 12.833 27.1952C12.213 27.1952 11.71 26.6912 11.71 26.0712C11.71 25.4502 12.213 24.9472 12.833 24.9472C13.454 24.9472 13.957 25.4502 13.957 26.0712Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M27.6157 8.226C27.2797 8.226 27.0077 7.954 27.0077 7.618C27.0077 7.282 27.2797 7.009 27.6157 7.009C27.9517 7.009 28.2237 7.282 28.2237 7.618C28.2237 7.954 27.9517 8.226 27.6157 8.226ZM34.8587 8.781H30.8337C30.5957 8.781 30.3787 8.616 30.3447 8.38C30.1507 7.035 28.9927 6 27.5937 6C26.1937 6 25.0357 7.035 24.8417 8.38C24.8077 8.616 24.5907 8.781 24.3527 8.781H20.3267C20.0507 8.781 19.8267 9.005 19.8267 9.282V11.813C19.8267 12.089 20.0507 12.313 20.3267 12.313H34.8587C35.1357 12.313 35.3597 12.089 35.3597 11.813V9.282C35.3597 9.005 35.1357 8.781 34.8587 8.781Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
    </Element>
  );
};

UiEventLogIcon.propTypes = {
  /** Определяет ширину иконки */
  width: PropTypes.string,
  /** Определяет высоту иконки */
  height: PropTypes.string
};

UiEventLogIcon.defaultProps = {
  width: '3.25rem',
  height: '3.25rem'
};

export default UiEventLogIcon;