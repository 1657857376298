import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Иконка данных
 * @public
 * @version 1.5.0
 * @param {String} [width="3.25rem"]
 * @param {String} [height="3.25rem"]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * import { UiDataIcon, UiPlates, UiPlate } from '@compassplus/ui-mobicash';
 *
 * const App = () => {
 *
 *   return (
 *     <>
 *       <UiDataIcon />
 *       <UiPlates>
 *         <UiPlate view="2x1" image={ <UiDataIcon width="100%" height="100%" /> } />
 *       </UiPlates>
 *     </>
 *   );
 * }; */
const UiDataIcon = ({
  width,
  height,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      innerRef={ innerRef }
      width={ width }
      height={ height }
      viewBox="0 0 52 52"
    >
      <g clipPath="url(#clip0_3270_12123)">
        <path
          d="M30.6356 23.8193H41.4604C41.6199 23.8193 41.7777 23.7874 41.925 23.7254C42.0723 23.6635 42.2062 23.5727 42.319 23.4582C42.4317 23.3437 42.5211 23.2078 42.5821 23.0582C42.6432 22.9086 42.6746 22.7482 42.6746 22.5863C42.6691 19.3477 41.3984 16.2436 39.1412 13.9555C36.884 11.6674 33.825 10.3823 30.6356 10.3823C30.4746 10.3803 30.3149 10.4118 30.1663 10.4746C30.0177 10.5375 29.8833 10.6305 29.7713 10.748C29.6597 10.8621 29.5713 10.9974 29.5113 11.1462C29.4512 11.295 29.4207 11.4544 29.4214 11.6152V22.5863C29.4214 22.7482 29.4528 22.9086 29.5138 23.0582C29.5749 23.2078 29.6643 23.3437 29.7771 23.4582C29.8898 23.5727 30.0236 23.6635 30.171 23.7254C30.3183 23.7874 30.4761 23.8193 30.6356 23.8193Z"
          fill={ isTheme.styles.colors.textDisable } />
        <path
          d="M15.7359 39.4087C15.463 39.4087 15.2013 39.2987 15.0083 39.1027C14.8154 38.9068 14.7069 38.641 14.7069 38.3639V29.587C14.0174 27.7669 13.6685 25.8321 13.678 23.882C13.678 23.579 13.678 23.276 13.7294 22.9834C12.5588 26.1397 12.6549 29.6364 13.997 32.7216V40.8193C14.0177 41.0817 14.1352 41.3265 14.326 41.5047C14.5167 41.6829 14.7667 41.7814 15.0259 41.7806H17.9791C18.2383 41.7814 18.4883 41.6829 18.679 41.5047C18.8698 41.3265 18.9873 41.0817 19.008 40.8193V39.3251C18.8656 39.4034 18.7069 39.4463 18.545 39.4505L15.7359 39.4087Z"
          fill={ isTheme.styles.colors.textDisable } />
        <path
          d="M44.5472 26.9643C44.1445 29.0116 43.3472 30.9579 42.2012 32.6912C41.0552 34.4246 39.5831 35.9107 37.8695 37.0643C36.1558 38.2179 34.2345 39.0161 32.2161 39.4131C30.1976 39.81 28.1218 39.7979 26.108 39.3774C25.9763 39.4423 25.8324 39.4779 25.6861 39.4819H22.8049C22.532 39.4819 22.2703 39.3718 22.0774 39.1758C21.8844 38.9799 21.776 38.7141 21.776 38.437V37.7578C21.0267 37.3345 20.311 36.8524 19.6357 36.3159V38.4056C19.6348 38.6696 19.5356 38.9235 19.3579 39.1161C19.9677 39.5457 20.6073 39.9299 21.2718 40.2655V40.882C21.2925 41.1444 21.41 41.3891 21.6008 41.5674C21.7915 41.7456 22.0415 41.8441 22.3008 41.8433H25.3156C25.4676 41.8403 25.6177 41.8084 25.7581 41.7492C26.8238 41.9482 27.9055 42.0461 28.9891 42.0418C37.8383 42.0418 45.0411 35.5114 45.0411 27.4659C45.0425 27.3996 45.0306 27.3338 45.0063 27.2723C44.982 27.2108 44.9457 27.1549 44.8996 27.1081C44.8534 27.0612 44.7984 27.0243 44.7378 26.9996C44.6773 26.9749 44.6124 26.9629 44.5472 26.9643Z"
          fill={ isTheme.styles.colors.textDisable } />
        <path
          d="M8.93448 39.4819C8.66157 39.4819 8.3998 39.3718 8.20683 39.1758C8.01386 38.9799 7.9055 38.7141 7.9055 38.437V35.1143C7.6729 35.1416 7.45635 35.2486 7.29172 35.4176C7.12709 35.5867 7.02421 35.8078 7 36.0442V40.8402C7.02074 41.1026 7.1382 41.3473 7.32897 41.5256C7.51973 41.7038 7.76975 41.8023 8.02897 41.8015H10.9924C11.2516 41.8023 11.5016 41.7038 11.6924 41.5256C11.8831 41.3473 12.0007 41.1026 12.0214 40.8402V39.4192C11.9362 39.4548 11.8458 39.4759 11.7539 39.4819H8.93448Z"
          fill={ isTheme.styles.colors.textDisable } />
        <path
          d="M29.1641 11.6152V22.5863C29.1641 22.9826 29.3191 23.3627 29.595 23.6429C29.871 23.9231 30.2453 24.0805 30.6355 24.0805H41.4603C41.8506 24.0805 42.2249 23.9231 42.5008 23.6429C42.7767 23.3627 42.9318 22.9826 42.9318 22.5863C42.9127 19.2736 41.6034 16.1032 39.2898 13.7676C36.9763 11.432 33.8464 10.121 30.5841 10.1211C30.3925 10.1216 30.203 10.1607 30.0264 10.236C29.8498 10.3114 29.6896 10.4214 29.5551 10.5599C29.4235 10.7001 29.321 10.8659 29.2538 11.0472C29.1866 11.2285 29.1561 11.4217 29.1641 11.6152ZM29.6786 11.6152C29.6768 11.4872 29.7005 11.3601 29.7482 11.2416C29.796 11.1231 29.8668 11.0157 29.9564 10.9256C30.1375 10.7447 30.3814 10.6433 30.6355 10.6435C33.7567 10.6435 36.7503 11.9011 38.9592 14.1402C41.1682 16.3793 42.4118 19.417 42.4173 22.5863C42.4173 22.8441 42.3164 23.0912 42.137 23.2735C41.9575 23.4557 41.7141 23.5581 41.4603 23.5581H30.6355C30.3817 23.5581 30.1383 23.4557 29.9589 23.2735C29.7794 23.0912 29.6786 22.8441 29.6786 22.5863V11.6152Z"
          fill={ isTheme.styles.colors.textTitle } />
        <path
          d="M44.3415 23.3179C44.205 23.3179 44.0742 23.373 43.9777 23.4709C43.8813 23.5689 43.827 23.7018 43.827 23.8404C43.827 26.7687 42.9719 29.6312 41.3698 32.066C39.7676 34.5008 37.4904 36.3985 34.8262 37.5191C32.1619 38.6397 29.2303 38.9329 26.4019 38.3617C23.5736 37.7904 20.9756 36.3803 18.9364 34.3096C16.8973 32.239 15.5087 29.6009 14.9461 26.7288C14.3835 23.8568 14.6722 20.8798 15.7758 18.1744C16.8794 15.469 18.7482 13.1567 21.1459 11.5298C23.5437 9.90293 26.3627 9.03457 29.2465 9.03457C29.3829 9.03457 29.5137 8.97955 29.6102 8.88158C29.7067 8.7836 29.761 8.65069 29.761 8.51213C29.761 8.37358 29.7067 8.2407 29.6102 8.14273C29.5137 8.04475 29.3829 7.9897 29.2465 7.9897C26.1601 7.99177 23.1435 8.9229 20.5781 10.6654C18.0127 12.4079 16.0137 14.8835 14.8335 17.7794C13.6533 20.6753 13.345 23.8615 13.9476 26.9352C14.5503 30.009 16.0368 32.8323 18.2192 35.0485C20.4016 37.2646 23.182 38.774 26.209 39.3859C29.236 39.9979 32.3736 39.6848 35.2255 38.4864C38.0773 37.288 40.5153 35.258 42.2313 32.653C43.9473 30.048 44.8643 26.9849 44.8663 23.8508C44.8677 23.7804 44.855 23.7105 44.8292 23.6452C44.8033 23.5799 44.7647 23.5206 44.7157 23.4708C44.6667 23.4211 44.6083 23.3819 44.544 23.3556C44.4797 23.3293 44.4108 23.3165 44.3415 23.3179Z"
          fill={ isTheme.styles.colors.textTitle } />
        <path
          d="M18.6068 26.0135H15.736C15.1677 26.0135 14.707 26.4813 14.707 27.0584V38.3639C14.707 38.9409 15.1677 39.4087 15.736 39.4087H18.6068C19.1751 39.4087 19.6358 38.9409 19.6358 38.3639V27.0584C19.6358 26.4813 19.1751 26.0135 18.6068 26.0135Z"
          fill={ isTheme.styles.colors.applicationPrimaryColor } />
        <path
          d="M11.8053 32.1678H8.93449C8.3662 32.1678 7.90552 32.6356 7.90552 33.2127V38.4265C7.90552 39.0036 8.3662 39.4714 8.93449 39.4714H11.8053C12.3736 39.4714 12.8343 39.0036 12.8343 38.4265V33.2127C12.8343 32.6356 12.3736 32.1678 11.8053 32.1678Z"
          fill={ isTheme.styles.colors.applicationPrimaryColor } />
        <path
          d="M25.6759 19.9846H22.8051C22.2368 19.9846 21.7761 20.4524 21.7761 21.0295V38.4266C21.7761 39.0036 22.2368 39.4714 22.8051 39.4714H25.6759C26.2442 39.4714 26.7049 39.0036 26.7049 38.4266V21.0295C26.7049 20.4524 26.2442 19.9846 25.6759 19.9846Z"
          fill={ isTheme.styles.colors.applicationPrimaryColor } />
      </g>
      <defs>
        <clipPath id="clip0_3270_12123">
          <rect width="38" height="34" fill={ isTheme.styles.colors.sectionBackground } transform="translate(7 8)" />
        </clipPath>
      </defs>
    </Element>
  );
};

UiDataIcon.propTypes = {
  /** Определяет ширину иконки */
  width: PropTypes.string,
  /** Определяет высоту иконки */
  height: PropTypes.string
};

UiDataIcon.defaultProps = {
  width: '3.25rem',
  height: '3.25rem'
};

export default UiDataIcon;