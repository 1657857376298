import React, { useState, useEffect } from 'react';

import { useOptions } from '@compassplus/ui-mobicash';

import { useParams } from 'react-router';
import i18n from '../../../i18n';

import {MContentDiv, SubContentDiv, Text} from '../../../components/TemplateStyle';

import Main from '../MainPage/Main';
import GlobalStateStore from '../../../stores/GlobalStateStore';
import ModifyItem from './ModifyItem';

const ModifyItemPage = (props) => {
  const { location } = props;
  const isOptions = useOptions();
  const params = useParams();
  const [modeIsModify, setModeIsModify] = useState(false);
  const [itemId, setItemId] = useState(params.id);

  useEffect(() => {
    setItemId(params.id);
    if (params.id) {
      setModeIsModify(true);
    } else {
      setModeIsModify(false);
    }
  }, [params]);

  useEffect(() => {
    GlobalStateStore.setHeaderProperties({
      title: modeIsModify ? 'EditingItem' : 'AddingItem',
      isBack: true,
      help: true,
      content: () => (
        <div id="block-one">
          <Text typography="note" view="readonly" position="center">
            {i18n.t('InputFieldAnnotation')}
          </Text>
          <Text typography="note" view="readonly" position="center">
            {i18n.t('RadioAnnotation')}
          </Text>
          <Text typography="note" view="readonly" position="center">
            {i18n.t('ColorAnnotation')}
          </Text>
          <Text typography="note" view="readonly" position="center">
            {i18n.t('CheckboxAnnotation')}
          </Text>
          <Text typography="note" view="readonly" position="center">
            {i18n.t('ListAnnotation')}
          </Text>
        </div>
      ),
    });
  }, [modeIsModify]);

  return (
      <>
        {isOptions.screen === 'mobile' ? (
            <MContentDiv>
              <ModifyItem {...props} itemId={itemId} groupId={location.state?.groupId}/>
            </MContentDiv>
        ) : (
            <>
              <MContentDiv>
                <Main groupId={location.state?.groupId} itemId={itemId}/>
              </MContentDiv>
              <SubContentDiv className="editContent">
                <ModifyItem {...props} itemId={itemId} groupId={location.state?.groupId}/>
              </SubContentDiv>
            </>
        )}
      </>
  );
};

export default ModifyItemPage;
