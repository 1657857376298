import { Children, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import UiTabItem from './UiTabItem';
import UiTabBlock from './UiTabBlock';

import styles from './UiTab.module.css';

/**
 * Tab component
 * @public
 * @version 0.0.90
 * @param {String} className
 * @param {Boolean} [controllable=false]
 * @param {*} children
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiTab>{ children }</UiTab>
 */
const UiTab = ({
  className,
  controllable,
  children
}) => {

  const validChildren = () => {
    if (children === undefined || !(children instanceof Object) || children.length === 0) {
      children = [
        <UiTabBlock title={ 'Title 1' }>
          Content 1
        </UiTabBlock>,
        <UiTabBlock title={ 'Title 2' }>
          Content 2
        </UiTabBlock>
      ]
    }

    if (!Array.isArray(children)) {
      children = [children];
    }
  }

  validChildren();

  children = Children.toArray(children).filter((item) => {
    return UiTabBlock.name === item?.type?.name;
  });

  validChildren();

  const [activeTab, setActiveTab] = useState(children[0].props.title);

  return (
    <div className={ cn(styles.tabs, className) }>
      <div>
        <ol className={ styles.ol }>
          { children.map((item) => {
            const { title, active, disabled, onClick } = item.props;

            return <UiTabItem
              key={ title }
              title={ title }
              active={ active }
              disabled={ disabled }
              activeTab={ activeTab }
              setActiveTab={ setActiveTab }
              onClick={ onClick }
              controllable={ controllable }
            />;
          }) }
        </ol>
      </div>

      { children.map((item, index) => {
        const { title, className, children } = item.props;

        if (title === activeTab) {
          return <div key={ index } className={ cn(styles.tabsContent, className) }>
            { children }
          </div>
        }

        return undefined;
      }) }
    </div>
  );
};

UiTab.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Определяет контролируемость компонента (вкл: слушает "active" компонента UiTabBlock/выкл: компонент самостоятельно управляется активной вкладкой) */
  controllable: PropTypes.bool,
  /** Дочерние элементы */
  children: PropTypes.node
};

UiTab.defaultProps = {
  controllable: false
};

export default UiTab;