import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react-lite';
import Merchant from '../../../stores/Merchant';
import { CustomSidebar, LanguageButtonActive, LanguageButtonDisabled } from './style';
import { MerchantLogo } from '../../../pages/market/MarketPage/style';
import './style.css';
import i18n from '../../../i18n';
import ModalStore from '../../../stores/ModalStore';
import { useOptions } from '@compassplus/ui-mobicash';
import GlobalStateStore from "../../../stores/GlobalStateStore";

const MarketSideMenu = observer(({ setGroupId }) => {
  const isOptions = useOptions();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [groups, setGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  i18n.changeLanguage(isOptions.language);

  useEffect(() => {
    if (Merchant.guid !== '') {
      setIsLoading(true);
      fetch('/api/v1/public/market/groups/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          language: isOptions.language,
          merchantGuid: Merchant.guid,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          ModalStore.openUnexpectedErrorModal();
        })
        .then((response) => {
          if (response) {
            setIsLoading(false);
            setGroups(response);
          }
        });
    }
  }, [Merchant.guid, isOptions.language]);

  const renderLanguageButton = (language) => {
    if (isOptions.language === language) {
      return (
        <LanguageButtonActive>{language.toUpperCase()}</LanguageButtonActive>
      );
    }
    return (
      <LanguageButtonDisabled onClick={() => {
        i18n.changeLanguage(language);
        localStorage.setItem('language', language);
        isOptions.setLanguage(language);
      }}
      >
        {language.toUpperCase()}
      </LanguageButtonDisabled>
    );
  };

  const getSidebarFooter = () => (
    <>
      <div style={{ margin: '1rem 0', display: 'flex' }}>
        {renderLanguageButton('ru')}
        {renderLanguageButton('en')}
      </div>
    </>
  );

  const getGroups = (groups) => {
    if (groups) {
      return groups.map((group) => {
        if (!group.subGroups || group.subGroups?.length === 0) {
          return {
            title: group.title,
            link: '/',
            action: () => {
              history.push(`/${Merchant.marketLink}/${group.type}`);
              GlobalStateStore.isSidebarOpen = false;
            },
          };
        }
        return {
          title: group.title,
          link: '/',
          action: () => {
            history.push(`/${Merchant.marketLink}/${group.type}`);
            GlobalStateStore.isSidebarOpen = false;
          },
          subactive: isOptions.screen === 'desktop',
          sublist: getGroups(group.subGroups),
        };
      });
    }
    return [];
  };

  return (
    <div className="market">
      <CustomSidebar
        className={GlobalStateStore.sidebarClassName}
        user={{
          title: isOptions.language === 'en' ? Merchant.name : Merchant.localName,
          description: '',
          icon: (
            <>
              {
                Merchant.photo !== '' ? (
                  <img style={{ width: '48px', height: '48px' }} src={Merchant.photo} />
                ) : (
                  <MerchantLogo
                    style={{ background: Merchant.logoColor }}
                  >
                    {Merchant.logoSymbol}
                  </MerchantLogo>
                )
              }
            </>
          ),
        }}
        menuList={[
          {
            title: i18n.t('Catalog'),
            link: '/',
            icon: 'legacy-reports',
            action: () => { history.push(`/${Merchant.marketLink}`); },
            subactive: !isLoading,
            sublist: getGroups(groups),
          },
        ]}
        open={isSidebarOpen}
        setOpen={setIsSidebarOpen}
        footer={getSidebarFooter()}
      />
    </div>
  );
});

export default MarketSideMenu;
