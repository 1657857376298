import React, {useEffect, useState} from 'react';
import {
  UiIcon,
  UiSection,
  UiArrow,
  useOptions
} from '@compassplus/ui-mobicash';
import i18n from '../../../i18n';
import {isEmpty, getMerchantId, request} from '../../../Utils/requestUtil';
import { useHistory } from 'react-router';

import {
  SectionCard,
  EmptyText,
  Subsection,
  AddButtonsContainer
} from './styles';

import SearchInput from "./components/SearchInput";

import { observer } from "mobx-react-lite";
import { action } from "mobx";

import CurrentGroupCard from "../../../components/admin/CurrentGroupCard/CurrentGroupCard";
import { renderGroups, renderItems } from "./RenderCards";
import AdminMerchantCard from "./components/MerchantCard";
import CategoryStore from "../../../stores/CategoryStore";
import ItemStore from "../../../stores/ItemStore";
import AdminMarketStore from "../../../stores/AdminMarketStore";
import Merchant from "../../../stores/Merchant";

const Main = observer((props) => {
  let { groupId, isShareLinkOpen, isMarketShareLinkOpen } = props;
  const [rootGroup, setRootGroup] = useState();
  const isOptions = useOptions();

  const { isLoading, currentGroup, isCategory, allGroups, allItems, currentGroups, currentItems, isCMS } = AdminMarketStore;

  const currentLanguage = isOptions.language;
  const history = useHistory();
  useEffect(() => {
    request({
      url: '/api/v1/group/root',
      method: 'GET'
    }).then(
      (responseGroup) => setRootGroup(responseGroup),
      (error) => console.log(error),
    );
  }, [AdminMarketStore.merchantGuid]);


  useEffect(() => {
    AdminMarketStore.getMarketData(getMerchantId(), groupId, currentLanguage);
  }, [getMerchantId(), currentLanguage]);

  useEffect(() => {
    if (getMerchantId()) {
      AdminMarketStore.setCurrentGroup(groupId);
    }
  }, [groupId, Merchant.guid]);

  useEffect(() => {
    // Добавляем для контейнера товаров ограничение, чтобы столбцы были одинаковой высоты. Добавляется прокрутка товаров.
    if (document.getElementsByClassName('items').length !== 0 && document.getElementsByClassName('editContent').length !== 0) {
      const editContentHeight = document.getElementsByClassName('editContent')[0].clientHeight;
      document.getElementsByClassName('items')[0].children[1].setAttribute('style', `max-height: ${editContentHeight-178}px; overflow-y: scroll`)
    }
  })

  const onScroll = action(() => {
    if (document.documentElement.scrollTop >= document.documentElement.offsetHeight - document.documentElement.clientHeight - 100) {
      AdminMarketStore.displayedItemsCount += 40;
    }
  });

  useEffect(() => {
    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, []);

  const isEmptyContent = () => (isEmpty(currentGroups) && isEmpty(currentItems) && !isLoading);

  const getContent = () => {
    if (isEmptyContent()) {
      return (
        <EmptyText>
          {i18n.t('EmptyMessage')}
        </EmptyText>
      );
    }
    return (
      <>
        {
          (currentGroups?.length > 0 || isLoading) ? (
            <Subsection
              style={{ margin: '0.375rem 0 0' }}
              title={i18n.t('Categories')}
            >
              {renderGroups({ isLoading, currentGroups, language: currentLanguage, history })}
            </Subsection>
          ) : null
        }
        {
          (currentItems?.length > 0 || isLoading) ? (
            <Subsection className="items" title={isCategory ? i18n.t('Items') : i18n.t('ItemsWithoutCategory')}>
              {
                renderItems({
                  isLoading,
                  currentItems,
                  language: currentLanguage,
                  history,
                  groupId,
                  prevChangeId: props?.itemId || props?.location?.state?.itemId
                })
              }
            </Subsection>
          ) : null
        }
      </>
    );
  };

  const renderAddButtons = () => (
    <>
      <SectionCard shadow style={{ margin: '0 0.5rem 0.625rem 0' }}>
        <UiSection
          title={i18n.t('AddCategory')}
          onClick={action(() => {
            if (!isLoading) {
              CategoryStore.clear();
              if (currentGroup) {
                CategoryStore.parentGroup = { key: currentGroup?._id || currentGroup?.type, value: currentGroup?.title[currentLanguage] || currentGroup?.title };
                history.push('/uploadGroup', { parentGroup: { key: currentGroup?._id || currentGroup?.type, value: currentGroup?.title[currentLanguage] || currentGroup?.title }});
              } else {
                history.push('/uploadGroup', { rootGroup: rootGroup[0] });
                CategoryStore.parentGroup = { key: rootGroup[0]._id, value: rootGroup[0].title };
              }
            }
          })}
          leftElement={<UiIcon icon="legacy-reports" />}
          rightElement={<UiArrow />}
        />
      </SectionCard>
      <SectionCard shadow>
        <UiSection
          title={i18n.t('AddItem')}
          onClick={action(() => {
            if (!isLoading) {
              CategoryStore.clear();
              ItemStore.clearItem();
              if (currentGroup) {
                ItemStore.groupGuid = currentGroup?._id || currentGroup?.type;
                ItemStore.parentGroup = { key: currentGroup?._id || currentGroup?.type, value: currentGroup?.title[currentLanguage] || currentGroup?.title }
                history.push('/uploadItem', { groupId: currentGroup?._id || currentGroup?.type });
              } else {
                ItemStore.groupGuid = rootGroup[0]._id;
                history.push('/uploadItem', { rootGroup: rootGroup[0] });
              }
            }
          })}
          leftElement={<UiIcon icon="legacy-marketplace" />}
          rightElement={<UiArrow />}
        />
      </SectionCard>
    </>
  )

  return (
    <>
      {((isCategory && groupId && currentGroup) || isLoading) ? (
        <CurrentGroupCard
          currentGroup={currentGroup}
          isLoading={isLoading}
          shareLinkOpened={isShareLinkOpen}
          isPreview
          style={{ margin: '0 0 0.25rem' }}
        />
      ) : (
        <AdminMerchantCard shareLinkOpened={isMarketShareLinkOpen} />
      )}
      <SearchInput
        allGroups={allGroups}
        allItems={allItems}
      />
      { !isCMS ? (
          <AddButtonsContainer>
            {renderAddButtons()}
          </AddButtonsContainer>
        ) : null
      }
      {getContent()}
    </>
  )
})

export default Main;
