import GB from './GB.svg';
import JO from './JO.svg';
import RU from './RU.svg';

const flags = {
  GB,
  JO,
  RU
};

export default flags;