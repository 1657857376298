import cn from 'classnames';

import UiCard from '../../../../../UiCard';
import UiImage from '../../../../../UiImage';

import styles from './Ips.module.css';

const Ips = ({
  className,
  src,
  alt,
  ...props
}) => {

  return (
    <UiCard className={ styles.ips }>
      <UiImage
        { ...props }
        className={ cn(styles.image, className) }
        src={ src }
        alt={ alt }
      />
    </UiCard>
  );
};

export default Ips;