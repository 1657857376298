import React, { useEffect, useReducer, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { UiCard, UiIcon,UiInput, UiButton, useOptions } from '@compassplus/ui-mobicash';
import { getCurrency } from '../../../Utils/requestUtil';
import i18n from '../../../i18n';
import { Stiky } from '../OrderPage/style';
import { CartSection } from './style';
import ShoppingCartStore from "../../../stores/ShoppingCartStore";
import Merchant from "../../../stores/Merchant";
import { observer } from "mobx-react-lite";
import GlobalStateStore from "../../../stores/GlobalStateStore";
import ItemCount from "./components/ItemCount";
import {Text} from "../../../components/TemplateStyle";
import ModalStore from "../../../stores/ModalStore";
import ShoppingCartItem from "../../../components/market/ShoppingCartItem";

const ShoppingCart = observer(() => {
  const isOptions = useOptions();
  const currentLanguage = isOptions.language;
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const history = useHistory();
  const params = useParams();
  const merchantURL = params.merchant;
  const [update, setUpdate] = useState(false);

  useEffect(() => { GlobalStateStore.isMarket = true; }, [])

  const onDelete = (index) => {
    ShoppingCartStore.deleteItem(index, Merchant.guid)
    setUpdate(!update);
    forceUpdate();
  };

  const getItems = () => (
    <>
      {
        ShoppingCartStore.cart?.map((product, index) => (
          <React.Fragment key={index}>
            <ShoppingCartItem
              product={product}
              index={index}
              onImageClick={() => {
                history.push(`/${merchantURL}/order/${product.id}`);
              }}
              onDelete={() => onDelete(index)}
            />
          </React.Fragment>
        ))
      }
    </>
  );

  const onCheckout = async () => {
    await fetch('/api/v1/reserv/checkTime', { method: 'GET' });
    const checkReservs = await ShoppingCartStore.checkReservs(Merchant.guid)

    if (!checkReservs.isError) {
      history.push(`/${merchantURL}/checkout`);
    } else {
      ModalStore.openUnexpectedErrorModal();
    }
  }

  return (
    <>
      <Text view="readonly" typography="subsection-title">{i18n.t('Cart')}</Text>
      <Text view="primary" typography="note">
        {`${i18n.t('Cart')} (${ShoppingCartStore.totalCount} / ${ShoppingCartStore.totalCost} ${i18n.t(getCurrency())})`}
      </Text>
      {(!Merchant.isCMS) ? (
        <Text style={{ margin: '0.5rem 0' }} view="readonly" typography="note">{i18n.t('CartNotification')}</Text>
      ) : null}
      {(ShoppingCartStore.cart && ShoppingCartStore.cart.length !== 0) ? (
        <div style={{ margin: '0 0 64px', width: '100%' }}>
          {getItems()}
        </div>
      ) : (
        <Text position='center'>
          {i18n.t('YourCartIsEmpty')}
        </Text>
      )}
      <Stiky>
        <UiButton
          title={i18n.t('Checkout')}
          onClick={async () => { await onCheckout(); }}
          disabled={!(ShoppingCartStore.cart && ShoppingCartStore.cart.length !== 0)}
        />
      </Stiky>
    </>
  );
});

export default ShoppingCart;
