import { cloneElement } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../context/ThemeProvider';

import styles from './UiBadge.module.css';

const StyledBadge = styled.div`
  ${ (props) => css`
    ${ props.styles.typography.Badge }
    font-size: 0.625rem;
    color: ${ props.color };
    border: 0.063rem solid ${ props.color };
  ` }

  ${ (props) => props.filled === 'true' && css`
    color: ${ props.styles.colors.sectionBackground };
    background: ${ props.color };
  ` }
`;

/**
 * Компонент бейджика
 * @public
 * @version 1.8.1
 * @param {String} [className]
 * @param {String} [color]
 * @param {Boolean} [filled=false]
 * @param {*} [icon]
 * @param {String} [text]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiBadge />
 */
const UiBadge = ({
  className,
  color,
  filled,
  icon,
  text,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  if (color === undefined || color === null) {
    color = isTheme.styles.colors.applicationPrimaryColor;
  }

  return (
    <StyledBadge
      { ...props }
      className={ cn(styles.badge, className) }
      color={ color }
      filled={ filled.toString() }
      ref={ innerRef }
      styles={ isTheme.styles }
    >
      { icon &&
        cloneElement(icon, {
          view: 'default',
          type: 'default',
          setWidth: '0.75rem',
          setHeight: '0.75rem',
          setColor: filled ? isTheme.styles.colors.sectionBackground : color,
          disabled: false
        })
      }
      { text }
    </StyledBadge>
  );
};

UiBadge.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Цвет бейджика */
  color: PropTypes.string,
  /** Заливка бейджика */
  filled: PropTypes.bool,
  /** Текстовая надпись бейджика */
  text: PropTypes.string,
  /** Иконка бейджика */
  icon: PropTypes.any,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

UiBadge.defaultProps = {
  filled: false
};

export default UiBadge;