import { useTheme } from '../../../context/ThemeProvider';

import UiBadge from '../../Ui/UiBadge';
import UiIcon from '../../Ui/UiIcon';

/**
 * Компонент значка финансового института
 * @public
 * @version 0.0.101
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiFinancialInstitutionBadge />
 */
const UiFinancialInstitutionBadge = (props) => {

  const isTheme = useTheme();

  const Icon = (props) => {

    return (
      <UiIcon { ...props } setWidth="0.75rem" setHeight="0.75rem" viewBox="0 0 12 12">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.75 4V3.25L6 1L11.25 3.25V4H9.75V4.75V8.5H10.5V8.875H1.5V8.5H2.25V4.75V4H0.75ZM0.75 9.60978V11H11.25V9.60978H0.75ZM6.75 4H8.25V4.75V8.5H6.75V4.75V4ZM3.75 4H5.25V4.75V8.5H3.75L3.75 4.75V4Z"
        />
      </UiIcon>
    );
  };

  return (
    <UiBadge
      { ...props }
      icon={ <Icon /> }
      color={ isTheme.styles.colors.applicationPosPrimaryColor }
    >
    </UiBadge>
  );
};

export default UiFinancialInstitutionBadge;