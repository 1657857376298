import { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledPlates = styled.div`
  display: grid;
  grid-template-columns: repeat(${ (props) => props.columns }, 1fr);
  grid-gap: 0.5rem;
  grid-auto-flow: row dense;
`;

/**
 * Plates component
 * @public
 * @version 0.0.90
 * @param {String} className
 * @param {Number} columns
 * @param {*} children
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiPlates>{ children }</UiPlates>
 */
const UiPlates = ({
  className,
  columns,
  children,
  ...props
}) => {

  const PlatesRef = useRef(null);
  const [internalColumns, setInternalColumns] = useState(4);

  const handlerResize = useCallback(() => {
    let fontSize = parseInt(getComputedStyle(document.documentElement).fontSize);
    let tempColumn = (PlatesRef.current.clientWidth / fontSize) / (4.375 + 0.25);

    tempColumn % 1 < 0.5 && tempColumn--;

    if (Math.floor(tempColumn) !== internalColumns) {
      setInternalColumns(Math.floor(tempColumn));
    }
  }, [internalColumns]);

  useEffect(() => {
    window.addEventListener('resize', handlerResize);

    const mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach(() => {
        handlerResize();
      });
    });

    mutationObserver.observe(PlatesRef.current, {
      attributes: false,
      characterData: false,
      childList: true,
      subtree: true,
      attributeOldValue: false,
      characterDataOldValue: false,
    });

    handlerResize();

    return () => {
      window.removeEventListener('resize', handlerResize);
      mutationObserver.disconnect();
    };
  }, [PlatesRef, handlerResize]);

  return (
    <StyledPlates
      { ...props }
      className={ className }
      columns={ columns || internalColumns }
      ref={ PlatesRef }
    >
      { children }
    </StyledPlates>
  );
};

UiPlates.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Количество колонок в инфопанели  */
  columns: PropTypes.number,
  /** Дочерние элементы */
  children: PropTypes.node
};

export default UiPlates;