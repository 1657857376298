import Axios from 'axios';

export const setAuth = (auth) => localStorage.setItem('auth', auth);
export const getAuth = () => localStorage.getItem('auth') || false;
export const removeAuth = () => localStorage.removeItem('auth');

export const setLogin = (login) => localStorage.setItem('login', login);
export const getLogin = () => localStorage.getItem('login') || '';
export const removeLogin = () => localStorage.removeItem('login');

export const setPassword = (password) => localStorage.setItem('password', password);
export const getPassword = () => localStorage.getItem('password');
export const removePassword = () => localStorage.removeItem('password');

export const setCashierAuth = (cashierAuth) => localStorage.setItem('cashierAuth', cashierAuth);
export const getCashierAuth = () => localStorage.getItem('cashierAuth') || false;
export const removeCashierAuth = () => localStorage.removeItem('cashierAuth');

export const setCashierLogin = (cashierLogin) => localStorage.setItem('cashierLogin', cashierLogin);
export const getCashierLogin = () => localStorage.getItem('cashierLogin') || '';
export const removeCashierLogin = () => localStorage.removeItem('cashierLogin');

export const setCashierPassword = (cashierPassword) => localStorage.setItem('cashierPassword', cashierPassword);
export const getCashierPassword = () => localStorage.getItem('cashierPassword');
export const removeCashierPassword = () => localStorage.removeItem('cashierPassword');

export const setCashierChallenge = (cashierChallenge) => localStorage.setItem('cashierChallenge', cashierChallenge);
export const getCashierChallenge = () => localStorage.getItem('cashierChallenge');
export const removeCashierChallenge = () => localStorage.removeItem('cashierChallenge');

export const setChallenge = (challenge) => localStorage.setItem('challenge', challenge);
export const getChallenge = () => localStorage.getItem('challenge');
export const removeChallenge = () => localStorage.removeItem('challenge');

export const setRefreshToken = (refreshToken) => localStorage.setItem('refreshToken', refreshToken);
export const getRefreshToken = () => localStorage.getItem('refreshToken') || '';
export const removeRefreshToken = () => localStorage.removeItem('refreshToken');

export const setMerchantId = (merchantId) => localStorage.setItem('merchantId', merchantId);
export const getMerchantId = () => localStorage.getItem('merchantId') || '';
export const removeMerchantId = () => localStorage.removeItem('merchantId');

export const setLogoColor = (logoColor) => localStorage.setItem('logoColor', logoColor);
export const getLogoColor = () => localStorage.getItem('logoColor');
export const removeLogoColor = () => localStorage.removeItem('logoColor');

export const setLogoSymbol = (logoSymbol) => localStorage.setItem('logoSymbol', logoSymbol);
export const getLogoSymbol = () => localStorage.getItem('logoSymbol');
export const removeLogoSymbol = () => localStorage.removeItem('logoSymbol');

export const setPhoto = (logoPhoto) => localStorage.setItem('logoPhoto', logoPhoto);
export const getPhoto = () => localStorage.getItem('logoPhoto');
export const removePhoto = () => localStorage.removeItem('logoPhoto');

export const setExpTime = (expTime) => localStorage.setItem('expTime', expTime);
export const getExpTime = () => localStorage.getItem('expTime') || 0;
export const removeExpTime = () => localStorage.removeItem('expTime');

export const setTitle = (title) => localStorage.setItem('title', title);
export const getTitle = (language) => {
  if (localStorage.getItem('title') !== 'undefined' && localStorage.getItem('title') !== null && localStorage.getItem('title') !== '') {
    try {
      if (typeof JSON.parse(localStorage.getItem('title')) === 'object') {
        return JSON.parse(localStorage.getItem('title'))[language || 'en'];
      }
      return localStorage.getItem('title');
    } catch (e) {
      return localStorage.getItem('title');
    }
  }
  return 'Catalog';
};

export const removeTitle = () => localStorage.removeItem('title');

export const setToken = (token) => sessionStorage.setItem('authToken', token);
export const removeToken = () => sessionStorage.removeItem('authToken');

export const setCurrency = (currency) => localStorage.setItem('currency', currency);
export const getCurrency = () => localStorage.getItem('currency') || 810;
export const removeCurrency = () => localStorage.removeItem('currency');

export const setCart = (guid, cart) => { localStorage.setItem(`${guid}-cart`, cart); };
export const getCart = (guid) => { if (localStorage.getItem(`${guid}-cart`) === '') { return null; } return localStorage.getItem(`${guid}-cart`); };
export const removeCart = (guid) => localStorage.removeItem(`${guid}-cart`);

export const getLanguage = () => { if (!localStorage.getItem('language') || localStorage.getItem('language') === '\"en\"') { return 'en'; } return localStorage.getItem('language'); };

export const setPayeeList = (payeeList) => localStorage.setItem('payeeList', payeeList);
export const getPayeeList = () => JSON.parse(localStorage.getItem('payeeList')) || [];
export const removePayeeList = () => localStorage.removeItem('payeeList');

export const setMerchantData = (guid, currency) => {
  localStorage.setItem('guid', guid);
  localStorage.setItem('currency', currency);
};

export const removeMerchantData = () => {
  localStorage.removeItem('guid');
  localStorage.removeItem('currency');
};

export const setUserData = (auth, login, password, challenge, authToken, currency, refreshToken, expTime, merchantId, title) => {
  setAuth(auth);
  setLogin(login);
  setPassword(password);
  setChallenge(challenge);
  setToken(authToken);
  setCurrency(currency);
  setRefreshToken(refreshToken);
  setExpTime(expTime);
  setMerchantId(merchantId);
  setTitle(title);
};

export const removeUserData = () => {
  removeAuth();
  removeLogin();
  removePassword();
  removeCashierAuth();
  removeCashierLogin();
  removeCashierPassword();
  removeCashierChallenge();
  removeChallenge();
  removeToken();
  removeCurrency();
  removeRefreshToken();
  removeExpTime();
  removeMerchantId();
  removeTitle();
  removePayeeList();
};

export const setCashierData = (auth, login, password, challenge) => {
  setCashierAuth(auth);
  setCashierLogin(login);
  setCashierPassword(password);
  setCashierChallenge(challenge);
};

export const removeCashierData = () => {
  removeCashierAuth();
  removeCashierLogin();
  removeCashierPassword();
  removeCashierChallenge();
};

const removeUserDataAndMoveToLogin = () => {
  removeUserData();
  window.location.href = '/authorization';
};

const errorHandler = (statusCode) => {
  if (statusCode === 403) {
    removeUserDataAndMoveToLogin();
  }
};

const axiosRequest = (url, method, data, headers = null) => new Promise((resolve, reject) => {
  const requestParams = { url, method, data };

  if (headers) {
    requestParams.headers = headers;
  }

  Axios(requestParams)
    .then((response) => {
      if (response.status === 200 || response.status === 204 || response.statusText === 'OK' || response.statusText === 'Created') {
        resolve(response.data);
      } else {
        reject(response.status);
      }
    })
    .catch((error) => {
      if (error.response) {
        if (!url.includes('authorization')) {
          errorHandler(error.response.status);
        }
        reject(error.response.data ? error.response.data.code || error.response.data : error.response.status);
      }
    });
});

const authorizationRequest = (url, method, data) => new Promise((resolve, reject) => {
  axiosRequest(url, method, data).then(
    (result) => resolve(result),
    (error) => reject(error),
  );
});

const requestWithToken = (url, method, data) => new Promise((resolve, reject) => {
  const headers = {
    'user-link': getLogin(),
    merchantGuid: getMerchantId(),
  };

  getToken().then(
    (token) => {
      headers['api-token'] = token;

      axiosRequest(url, method, data, headers).then(
        (result) => resolve(result),
        (error) => reject(error),
      );
    },
    (error) => {
      reject(error);
    },
  );
});

export const request = (url, method, data = null) => new Promise((resolve, reject) => {
  if (url.includes('authorization')) {
    authorizationRequest(url, method, data).then(
      (result) => resolve(result),
      (error) => {
        if (url === '/api/v1/authorization/refresh') {
          errorHandler(error);
        }
        reject(error);
      },
    );
  } else {
    requestWithToken(url, method, data).then(
      (result) => resolve(result),
      (error) => {
        errorHandler(error);
        reject(error);
      },
    );
  }
});

const updateAuthTokenWithRefreshToken = () => new Promise((resolve) => {
  const refreshToken = getRefreshToken();
  if (refreshToken !== '') {
    request('/api/v1/authorization/refresh', 'POST', { refreshToken }).then(
      (data) => {
        const password = getPassword();
        const challenge = getChallenge();
        const merchantId = getMerchantId();
        const title = getTitle();
        const currency = getCurrency();

        if (password && challenge) {
          setUserData(true, data.login, password, challenge, data.authToken, currency, data.refreshToken, data.expTime, merchantId, title);
          return resolve(data.authToken);
        }

        return removeUserDataAndMoveToLogin();
      },
    );
  } else {
    return removeUserDataAndMoveToLogin();
  }
});

export const getToken = () => new Promise((resolve, reject) => {
  const time = (new Date()).getTime();
  const expTime = Number(getExpTime());

  if (time < expTime) {
    const token = sessionStorage.getItem('authToken');
    if (!token) {
      return reject(403);
    }
    return resolve(token);
  }

  updateAuthTokenWithRefreshToken().then((updatedToken) => resolve(updatedToken));
});

export const setRequestData = (login, password, challenge, language) => {
  setLogin(login);
  setPassword(password);
  setChallenge(challenge);
};

export const getRequestData = () => {
  if (!localStorage.getItem('language') || (localStorage.getItem('language') === '\"en\"')) {
    localStorage.setItem('language', 'en');
  }
  return {
    login: getLogin(),
    password: getPassword(),
    challenge: getChallenge(),
    language: localStorage.getItem('language').toUpperCase(),
  };
};

export const isEmpty = (obj) => {
  if (!obj || (Object.keys(obj).length === 0 && obj.constructor === Object) || (obj && obj.length === 0)) {
    return true;
  }
  return false;
};
