import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  UiSubsection,
  UiCard,
  UiInput,
  UiInputDate,
  UiSection,
  UiArrow,
  useOptions,
  useDate,
} from '@compassplus/ui-mobicash';
import { useHistory } from 'react-router';
import OrderCard from '../OrdersPage/OrderCard';
import OrdersRecordsStore from '../../../stores/OrdersRecordsStore';
import i18n from '../../../i18n';
import ShoppingCartItem from '../../../components/market/ShoppingCartItem';
import { StyledUiInput } from './style';
import ModalStore from '../../../stores/ModalStore';
import SharePaymentLink from '../../../components/Modals/SharePaymentLink/SharePaymentLink';

const ModifyOrder = observer(({ modifyingOrderData }) => {
  const { modifyingOrder } = OrdersRecordsStore;
  const isOptions = useOptions();
  const history = useHistory();
  const [shareLinkOpen, setShareLinkOpen] = useState(false);
  const [, { validateDateFormat }] = useDate();
  const renderOrderDetails = () => (
    <UiSubsection style={{ margin: '0.75rem 0 0.25rem' }} title={i18n.t('OrderDetails')}>
      <UiCard style={{ margin: '0.5rem 0 0.25rem' }} shadow>
        <UiInputDate
          description={i18n.t('CreationTime')}
          defaultValue={modifyingOrder.creationTime}
          bottomDivider
          disabled
          readOnly
          type={modifyingOrder.status === 'Deleted' ? 'readonly' : 'text'}
        />
        <UiInputDate
          description={i18n.t('LastUpdate')}
          defaultValue={modifyingOrder.lastUpdate}
          bottomDivider
          disabled
          readOnly
          type={modifyingOrder.status === 'Deleted' ? 'readonly' : 'text'}
        />
        <UiInput
          description={i18n.t('CashierLogin')}
          value={modifyingOrder.cashierLogin}
          bottomDivider
          readOnly
          type={modifyingOrder.status === 'Deleted' ? 'readonly' : 'text'}
        />
        <UiInput
          description={i18n.t('OrderId')}
          value={modifyingOrder._id}
          bottomDivider
          readOnly
          type={modifyingOrder.status === 'Deleted' ? 'readonly' : 'text'}
        />
        <UiInput
          description={i18n.t('eInvoicingBillId')}
          value={modifyingOrder.eInvoicingOrder.Id}
          readOnly
          type={modifyingOrder.status === 'Deleted' ? 'readonly' : 'text'}
        />
      </UiCard>
    </UiSubsection>
  );

  const renderOrderStatus = () => (
    <UiSubsection
      style={{ margin: '0.75rem 0 0.25rem' }}
      title={i18n.t('OrderStatus')}
    >
      <UiCard
        style={{ margin: '0.25rem 0' }}
        shadow
      >
        {
          modifyingOrder.status === 'Deleted' ? (
            <UiCard shadow>
              <UiSection
                title={i18n.t('ReopenOrder')}
                description=""
                rightElement={<UiArrow />}
                onClick={() => {
                  if (modifyingOrderData !== 'status') {
                    history.push(`/modifyOrder/${modifyingOrder._id}/status`);
                  }
                }}
                active={modifyingOrderData === 'status'}
              />
            </UiCard>
          ) : (
            <UiInput
              description={i18n.t('OrderStatus')}
              value={i18n.t(modifyingOrder.status)}
              rightElement={<UiArrow />}
              onClick={() => {
                if (modifyingOrderData !== 'status') {
                  history.push(`/modifyOrder/${modifyingOrder._id}/status`);
                }
              }}
              active={modifyingOrderData === 'status'}
              readOnly
            />
          )
        }
      </UiCard>
      {(modifyingOrder.status === 'Created') ? (
        <UiCard shadow>
          <UiSection
            title={i18n.t('eInvoicingLink')}
            description=""
            rightElement={<UiArrow />}
            onClick={() => { setShareLinkOpen(true); }}
          />
        </UiCard>
      ) : null}
    </UiSubsection>
  );

  const renderItemsList = () => (
    <UiSubsection
      style={{ margin: '0.75rem 0 0.25rem' }}
      title={i18n.t('ItemList')}
    >
      {modifyingOrder.items.map((item, index) => (
        <React.Fragment key={item.id}>
          <ShoppingCartItem
            style={{ margin: '0.5rem 0 0' }}
            product={item}
            index={index}
            shadow
            disabled
          />
        </React.Fragment>
      ))}
    </UiSubsection>
  );

  const renderDeliveryData = () => {
    const deliveryType = modifyingOrder.deliveryData.type;
    const { deliveryDate } = modifyingOrder.deliveryData;
    const deliveryAddress = modifyingOrder.deliveryData.addressFields;
    const { entryField } = modifyingOrder.deliveryData;

    return (
      <UiSubsection
        style={{ margin: '0.75rem 0 0.25rem' }}
        title={i18n.t('Delivery')}
      >
        <UiCard
          style={{ margin: '0.5rem 0 0' }}
          shadow
        >
          {deliveryType ? (
            <>
              {
                modifyingOrder.status === 'Deleted' ? (
                  <UiInput
                    type="readonly"
                    color="#037AFF"
                    description={i18n.t('DeliveryMethod')}
                    onClick={() => {
                      if (modifyingOrderData !== 'deliveryType') {
                        history.push(`/modifyOrder/${modifyingOrder._id}/deliveryType`);
                      }
                    }}
                    value={deliveryType.Title}
                    rightElement={<UiArrow />}
                    bottomDivider={deliveryAddress !== undefined || deliveryDate !== undefined || entryField !== undefined}
                    disabled={modifyingOrder.status === 'Deleted'}
                    readOnly
                  />
                ) : (
                  <StyledUiInput
                    type="readonly"
                    color="#037AFF"
                    description={i18n.t('DeliveryMethod')}
                    onClick={() => {
                      if (modifyingOrderData !== 'deliveryType') {
                        history.push(`/modifyOrder/${modifyingOrder._id}/deliveryType`);
                      }
                    }}
                    value={deliveryType.Title}
                    rightElement={<UiArrow />}
                    bottomDivider={deliveryAddress !== undefined || deliveryDate !== undefined || entryField !== undefined}
                    disabled={modifyingOrder.status === 'Deleted'}
                    readOnly
                  />
                )
              }
            </>
          ) : null}
          {deliveryAddress ? (
            <>
              {
                modifyingOrder.status === 'Deleted' ? (
                  <UiInput
                    type="readonly"
                    color="#037AFF"
                    description={i18n.t('Address')}
                    value={deliveryAddress.map((el) => el.Value).join(', ')}
                    rightElement={<UiArrow />}
                    bottomDivider={deliveryDate !== undefined || entryField !== undefined}
                    onClick={() => {
                      if (modifyingOrderData !== 'address') {
                        history.push(`/modifyOrder/${modifyingOrder._id}/address`);
                      }
                    }}
                    disabled={modifyingOrder.status === 'Deleted'}
                    readOnly
                  />
                ) : (
                  <StyledUiInput
                    type="readonly"
                    color="#037AFF"
                    description={i18n.t('Address')}
                    value={deliveryAddress.map((el) => el.Value).join(', ')}
                    rightElement={<UiArrow />}
                    bottomDivider={deliveryDate !== undefined || entryField !== undefined}
                    onClick={() => {
                      if (modifyingOrderData !== 'address') {
                        history.push(`/modifyOrder/${modifyingOrder._id}/address`);
                      }
                    }}
                    disabled={modifyingOrder.status === 'Deleted'}
                    readOnly
                  />
                )
              }
            </>
          ) : null}
          {deliveryDate ? (
            <UiInput
              description={i18n.t('DeliveryDate')}
              defaultValue={validateDateFormat(deliveryDate, isOptions.language).dateString}
              value={validateDateFormat(deliveryDate, isOptions.language).dateString}
              rightElement={<UiArrow />}
              bottomDivider={entryField !== undefined}
              onClick={() => {
                if (modifyingOrderData !== 'deliveryDate') {
                  history.push(`/modifyOrder/${modifyingOrder._id}/deliveryDate`);
                }
              }}
              disabled={modifyingOrder.status === 'Deleted'}
              readOnly
              type={modifyingOrder.status === 'Deleted' ? 'readonly' : 'text'}
            />
          ) : null}
          {entryField ? (
            <>
              {
                modifyingOrder.status === 'Deleted' ? (
                  <UiInput
                    type="readonly"
                    color="#037AFF"
                    description={isOptions.language === 'ru' ? entryField.Title : entryField.Name}
                    value={entryField.Value}
                    rightElement={<UiArrow />}
                    onClick={() => {
                      if (modifyingOrderData !== 'entryField') {
                        history.push(`/modifyOrder/${modifyingOrder._id}/entryField`);
                      }
                    }}
                    readOnly
                    disabled={modifyingOrder.status === 'Deleted'}
                  />
                ) : (
                  <StyledUiInput
                    type="readonly"
                    color="#037AFF"
                    description={isOptions.language === 'ru' ? entryField.Title : entryField.Name}
                    value={entryField.Value}
                    rightElement={<UiArrow />}
                    onClick={() => {
                      if (modifyingOrderData !== 'entryField') {
                        history.push(`/modifyOrder/${modifyingOrder._id}/entryField`);
                      }
                    }}
                    readOnly
                    disabled={modifyingOrder.status === 'Deleted'}
                  />
                )
              }
            </>
          ) : null}
        </UiCard>
      </UiSubsection>
    );
  };

  const renderPersonalData = () => {
    const { personalData, phoneNumber, birthday } = modifyingOrder.customer;

    return (
      <UiSubsection
        style={{ margin: '0.75rem 0 0.25rem' }}
        title={i18n.t('PersonalData')}
      >
        <UiCard
          style={{ margin: '0.5rem 0 0' }}
          shadow
        >
          {
            personalData ? (
              <UiInput
                description={i18n.t('PersonalData')}
                value={personalData.map((el) => el.Value).join(' ')}
                rightElement={<UiArrow />}
                bottomDivider={phoneNumber !== undefined || birthday !== undefined}
                onClick={() => {
                  if (modifyingOrderData !== 'personalData') {
                    history.push(`/modifyOrder/${modifyingOrder._id}/personalData`);
                  }
                }}
                disabled={modifyingOrder.status === 'Deleted'}
                readOnly
                type={modifyingOrder.status === 'Deleted' ? 'readonly' : 'text'}
              />
            ) : null
          }
          {
            phoneNumber ? (
              <UiInput
                description={i18n.t('PhoneNumber')}
                value={phoneNumber}
                rightElement={<UiArrow />}
                bottomDivider={birthday !== undefined}
                onClick={() => {
                  if (modifyingOrderData !== 'phoneNumber') {
                    history.push(`/modifyOrder/${modifyingOrder._id}/phoneNumber`);
                  }
                }}
                disabled={modifyingOrder.status === 'Deleted'}
                readOnly
                type={modifyingOrder.status === 'Deleted' ? 'readonly' : 'text'}
              />
            ) : null
          }
          {
            birthday ? (
              <UiInput
                description={i18n.t('Birthday')}
                defaultValue={validateDateFormat(birthday, isOptions.language).dateString}
                rightElement={<UiArrow />}
                onClick={() => {
                  if (modifyingOrderData !== 'birthday') {
                    history.push(`/modifyOrder/${modifyingOrder._id}/birthday`);
                  }
                }}
                disabled={modifyingOrder.status === 'Deleted'}
                readOnly
                type={modifyingOrder.status === 'Deleted' ? 'readonly' : 'text'}
              />
            ) : null
          }
        </UiCard>
      </UiSubsection>
    );
  };

  const renderOrderAmount = () => (
    <UiSubsection
      style={{ margin: '0.75rem 0 0.5rem' }}
      title={i18n.t('OrderAmount')}
    >
      <UiCard
        style={{ margin: '0.5rem 0' }}
        shadow
      >
        <UiInput
          description={i18n.t('OrderAmount')}
          value={(modifyingOrder.cartTotal / 100).toFixed(2)}
          readOnly
          bottomDivider
          type={modifyingOrder.status === 'Deleted' ? 'readonly' : 'text'}
        />
        <UiInput
          description={i18n.t('CostOfDelivery')}
          value={modifyingOrder.deliveryCost !== 0 ? (modifyingOrder.deliveryCost / 100).toFixed(2) : i18n.t('Free')}
          readOnly
          bottomDivider
          type={modifyingOrder.status === 'Deleted' ? 'readonly' : 'text'}
        />
        <UiInput
          description={i18n.t('TOTAL')}
          value={(modifyingOrder.amount).toFixed(2)}
          readOnly
          type={modifyingOrder.status === 'Deleted' ? 'readonly' : 'text'}
        />
      </UiCard>
    </UiSubsection>
  );

  if (modifyingOrder) {
    return (
      <>
        <OrderCard
          style={{ margin: ' 0 0 0.25rem', position: 'relative' }}
          order={modifyingOrder}
          isDeleteSection={modifyingOrder.status !== 'Deleted'}
          onDelete={() => {
            ModalStore.openActionConfirm(
              `${i18n.t('DeleteQuestion')} ${i18n.t('Order')} #${modifyingOrder.eInvoicingOrder.Id}?`,
              () => {
                history.replace('/orders');
                OrdersRecordsStore.modifyStatus('Deleted');
                ModalStore.setDefaultModal();
              },
            );
          }}
        />
        {renderOrderDetails()}
        {renderOrderStatus()}
        {renderItemsList()}
        {renderDeliveryData()}
        {renderPersonalData()}
        {renderOrderAmount()}
        <SharePaymentLink active={shareLinkOpen} setActive={setShareLinkOpen} link={modifyingOrder.eInvoicingLink} />
      </>
    );
  }
  return null;
});

export default ModifyOrder;
