import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Element component
 * @public
 * @version 0.0.90
 * @param {Function} onClick
 * @param {Boolean} [disabled=false]
 * @param {Boolean} [propagation=true]
 * @param {String} [width=1rem]
 * @param {String} [height=1rem]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiExpand />
 */
const UiExpand = ({
  onClick,
  disabled,
  propagation,
  width,
  height,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      color={ isTheme.styles.colors.textReadOnly }
      disabled={ disabled }
      propagation={ propagation }
      onClick={ onClick }
      innerRef={ innerRef }
      width={ width }
      height={ height }
      viewBox="0 0 16 16"
    >
      <path
        d="M15.7528 8.04703L8.44721 11.889C8.16569 12.037 7.83432 12.037 7.55279 11.889L0.247215 8.04703C0.0957054 7.96735 1.32046e-06 7.80448 1.33603e-06 7.62632C1.3666e-06 7.27665 0.349863 7.04923 0.647215 7.2056L7.77639 10.9548C7.91716 11.0288 8.08284 11.0288 8.22361 10.9548L15.3528 7.2056C15.6501 7.04923 16 7.27665 16 7.62632C16 7.80448 15.9043 7.96735 15.7528 8.04703Z" />
      <path
        d="M15.7528 4.89169L8.44721 8.73362C8.16569 8.88167 7.83432 8.88167 7.55279 8.73362L0.247215 4.89169C0.0957057 4.81201 1.59631e-06 4.64914 1.61188e-06 4.47097C1.64245e-06 4.12131 0.349863 3.89389 0.647216 4.05026L7.7764 7.79943C7.91716 7.87346 8.08284 7.87346 8.22361 7.79943L15.3528 4.05026C15.6501 3.89389 16 4.12131 16 4.47098C16 4.64914 15.9043 4.81201 15.7528 4.89169Z" />
    </Element>
  );
};

UiExpand.propTypes = {
  /** Обработчик события при клике */
  onClick: PropTypes.func,
  /** Компонент неактивен */
  disabled: PropTypes.bool,
  /** Разрешить/запретить всплытие обработчика onClick */
  propagation: PropTypes.bool,
  /** Определяет ширину компонента */
  width: PropTypes.string,
  /** Определяет высоту компонента */
  height: PropTypes.string,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

UiExpand.defaultProps = {
  disabled: false,
  propagation: true,
  width: '1rem',
  height: '1rem'
};

export default UiExpand;