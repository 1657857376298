import React from 'react';

import { notification, Typography } from 'antd';

import i18n from '../i18n';

notification.config({ duration: 3 });

const copiedToClipboardNotification = () => {
  notification.open({
    message: i18n.t('Copied to clipboard'),
    closeIcon: <></>,
  });
};

const alreadyExist = (type) => {
  notification.open({
    message: <Typography.Text style={{ color: '#FC3F3F' }}>{i18n.t(`${type} already exist`)}</Typography.Text>,
    closeIcon: <></>,
  });
};


export {
  copiedToClipboardNotification, alreadyExist,
};
