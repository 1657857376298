import { UiArrow, UiSection, useOptions } from '@compassplus/ui-mobicash';
import React, { useEffect, useState } from 'react';
import { request } from '../../../Utils/requestUtil';
import i18n from '../../../i18n';
import CategoryStore from "../../../stores/CategoryStore";

const ParentGuidsSelections = ({ isOpened, onClick, type }) => {
  const isOptions = useOptions();
  const [currentGroup, setCurrentGroup] = useState({});
  const [groups, setGroups] = useState([]);
  const [rootGroup, setRootGroup] = useState({});

  useEffect(() => {
    setCurrentGroup({
      group: null,
      subGroups: groups,
    });

    return () => {
      setCurrentGroup({});
    }
  }, [isOpened]);

  useEffect(() => {
    request('/api/v1/group/merchant', 'GET').then(
      (responseGroups) => {
        setGroups(responseGroups);
        setCurrentGroup({
          group: null,
          subGroups: responseGroups,
        });
      },
      (error) => console.log(error),
    );
    request('/api/v1/group/root', 'GET').then(
      (responseGroup) => {
        setRootGroup(responseGroup);
      },
      (error) => console.log(error),
    );
  }, []);

  return (
    <>
      {
        currentGroup.group ? (
          <UiSection
            title={currentGroup.group.title[isOptions.language]}
            view="link"
            disabled={currentGroup.group._id === CategoryStore.type && CategoryStore.modeIsModify}
            style={{ userSelect: 'none' }}
            rightElement={<UiArrow />}
            onClick={() => {
              onClick(currentGroup.group);
            }}
            bottomDivider
          />
        ) : (
          <UiSection
            title={type && type === 'group' ? rootGroup[0]?.title[isOptions.language] : i18n.t('ItemsWithoutCategory')}
            view="link"
            style={{ userSelect: 'none' }}
            rightElement={<UiArrow />}
            onClick={() => {
              onClick(rootGroup[0]);
            }}
            bottomDivider
          />
        )
      }
      {
        currentGroup.subGroups ? (
          currentGroup.subGroups.map((group, index) => (
            <UiSection
              key={index}
              title={group.title[isOptions.language]}
              disabled={group._id === CategoryStore.type && CategoryStore.modeIsModify}
              rightElement={<UiArrow />}
              onClick={() => {
                if (group.subGroups.length !== 0) {
                  setCurrentGroup({
                    group,
                    subGroups: group.subGroups,
                  });
                } else {
                  onClick(group);
                }
              }}
              style={{ userSelect: 'none' }}
              view="link"
              bottomDivider={index !== currentGroup.subGroups.length - 1}
            />
          ))
        ) : null
      }
    </>
  );
};

export default ParentGuidsSelections;
