import { isValidElement, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../context/ThemeProvider';

import UiCard from '../UiCard';
import UiImage from '../UiImage';
import Title from './Components/Title';
import Description from './Components/Description';
import UiPlusForPlates from '../UiPlusForPlates';
import UiMinusForPlates from '../UiMinusForPlates';

import styles from './UiPlate.module.css';

const StyledCard = styled(UiCard)`
  ${ (props) => !props.activeElement && css`
    &:active {
      background: ${ (props) => props.styles.colors.keyboardBackgroundColor };
    }
  ` }

  ${ (props) => props.active && css`
    background: ${ props.styles.colors.keyboardBackgroundColor };
  ` }

  ${ (props) => props.disabled && css`
    cursor: default;
    pointer-events: none;
    -moz-user-select: none;
    user-select: none;

    & > img {
      filter: grayscale(100%);
      opacity: 0.2;
    }

    & > div > span {
      color: ${ (props) => props.styles.colors.textDisable };
    }
  ` }
`;

/**
 * Plate component
 * @public
 * @version 0.0.90
 * @param {String} className
 * @param {Boolean} active
 * @param {'1x1'|'2x1'|'2x2'} [view=1x1]
 * @param {String} [title=Plate name]
 * @param {String} [description=dashboard plate description]
 * @param {String|JSX.Element} image
 * @param {String|JSX.Element} bigImage
 * @param {Boolean} disabled
 * @param {Boolean} [minimized=false]
 * @param {Function} onClick
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiPlate />
 */
const UiPlate = ({
  className,
  active,
  view,
  title,
  description,
  image,
  bigImage,
  disabled,
  minimized,
  onClick,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  const [activeElement, setActiveElement] = useState(false);
  const [state, setState] = useState(() => {
    if (view === '2x2' && minimized) {
      return '2x1';
    } else if (view === '2x1' && minimized) {
      return '1x1';
    } else {
      return view;
    }
  });
  const type = view === '2x2' ? 1 : 0;

  useEffect(() => {
    if (view === '2x2' && minimized) {
      setState('2x1')
    } else if (view === '2x1' && minimized) {
      setState('1x1')
    } else {
      setState(view)
    }
  }, [view, minimized]);

  const plate = generatePlate();

  return (
    <StyledCard
      { ...props }
      className={ cn(styles.container, plate.classes, className) }
      active={ active }
      activeElement={ activeElement }
      shadow={ true }
      onClick={ onClick }
      disabled={ disabled }
      innerRef={ innerRef }
      styles={ isTheme.styles }
    >
      { isValidElement(plate.image) ?
        <div className={ styles.image }>
          { plate.image }
        </div>
        :
        <UiImage className={ styles.image } src={ plate.image } />
      }
      <div className={ styles.content }>
        { plate.content }
      </div>
      { plate.element }
    </StyledCard>
  );

  function generatePlate() {
    const plate = {};

    plate.image = image;
    plate.content = [];

    if (state === '2x2') {
      plate.classes = styles.plateLarge;
      plate.element = <UiMinusForPlates
        className={ styles.element }
        propagation={ false }
        onClick={ () => setState('2x1') }
        onMouseDown={ () => setActiveElement(true) }
        onMouseUp={ () => setActiveElement(false) }
      />;

      plate.content.push(<Description key="description" description={ description } />);

      if (bigImage) {
        plate.image = bigImage;
      }
    } else if (state === '2x1') {
      plate.classes = styles.plateMedium;
      plate.content.push(<Description key="description" description={ description } />);

      if (type === 0) {
        plate.element = <UiMinusForPlates
          className={ styles.element }
          propagation={ false }
          onClick={ () => setState('1x1') }
          onMouseDown={ () => setActiveElement(true) }
          onMouseUp={ () => setActiveElement(false) }
        />;
      } else {
        plate.element = <UiPlusForPlates
          className={ styles.element }
          propagation={ false }
          onClick={ () => setState('2x2') }
          onMouseDown={ () => setActiveElement(true) }
          onMouseUp={ () => setActiveElement(false) }
        />;
      }
    } else {
      plate.classes = styles.plateSmall;
      plate.element = <UiPlusForPlates
        className={ styles.element }
        propagation={ false }
        onClick={ () => setState('2x1') }
        onMouseDown={ () => setActiveElement(true) }
        onMouseUp={ () => setActiveElement(false) }
      />;
    }

    plate.content.push(<Title key="title" title={ title } />);

    return plate;
  }
};

UiPlate.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Определяет контейнер как активный элемент */
  active: PropTypes.bool,
  /** Вид инфоплиты */
  view: PropTypes.oneOf(['1x1', '2x1', '2x2']).isRequired,
  /** Текстовая надпись на инфоплите */
  title: PropTypes.string,
  /** Текстовое описание на инфоплите */
  description: PropTypes.string,
  /** Изображение на инфоплите */
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** Крупное изображение на инфоплите вида 2x2 */
  bigImage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** Делает элемент неактивным */
  disabled: PropTypes.bool,
  /** Определяет сворачивание элемента по умолчанию */
  minimized: PropTypes.bool,
  /** Функция обработчик клика по инфоплите */
  onClick: PropTypes.func,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

UiPlate.defaultProps = {
  active: false,
  view: '1x1',
  title: 'Plate name',
  description: 'dashboard plate description',
  disabled: false,
  minimized: false
};

export default UiPlate;