import styled from 'styled-components';
import { UiDropdownSheet } from '@compassplus/ui-mobicash';

export const CheckoutDropdown = styled(UiDropdownSheet)`
  min-width: 100%;
  max-width: 100%;
  text-align: end;
  background-color: #FFFFFF !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  
  & span:hover {
   color: #037AFF;
  }
`;
