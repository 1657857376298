import i18n from '../../i18n';
import { getCurrency } from '../../Utils/requestUtil';

class DeliveryCostData {
  /*
    type = 'free' - бесплатная доставка. minOrderPrice = 0,
    type = 'freeWithCertainAmount' - бесплатная доставка от определенной суммы заказа. Для указания суммы используем minOrderPrice,
    type = 'minimumAmount' - для осуществления доставки необходима определенная сумма заказа. Для указания суммы используем minOrderPrice,
    type = 'dependsOnAmount' - указываем массив зависимостей стоимости доставки от суммы заказа. Используем массив costDependencies.
    type = 'fixedPrice' - фиксированная стоимость доставки
   */
  constructor(newDeliveryCostData) {
    this.type = (newDeliveryCostData.type !== undefined) ? newDeliveryCostData.type : 'dependsOnAmount';
    this.minOrderPrice = (newDeliveryCostData.minOrderPrice !== undefined) ? newDeliveryCostData.minOrderPrice : 0;
    this.costDependencies = (newDeliveryCostData.costDependencies !== undefined) ? newDeliveryCostData.costDependencies : [{
      type: 'moreThen',
      fromOrderPrice: '0.00',
      toOrderPrice: '',
      deliveryPrice: '0.00',
      deliveryPriceType: 'value',
    }];
  }

  setType(newValue) {
    this.type = newValue;
  }

  setMinOrderPrice(newValue) {
    this.minOrderPrice = newValue;
  }

  addCostDependencies(newValue) {
    this.costDependencies = [...this.costDependencies, newValue];
  }

  deleteCostDependence(index) {
    if (this.costDependencies.length > 1) {
      this.costDependencies.splice(index, 1);
    }
  }

  setDependenceType(index, newValue) {
    this.costDependencies[index].type = newValue;
  }

  setFromOrderPrice(index, newValue) {
    this.costDependencies[index].fromOrderPrice = newValue;
  }

  setToOrderPrice(index, newValue) {
    this.costDependencies[index].toOrderPrice = newValue;
  }

  setDeliveryPrice(index, newValue) {
    this.costDependencies[index].deliveryPrice = newValue;
  }

  setDeliveryPriceType(index, newValue) {
    this.costDependencies[index].deliveryPriceType = newValue;
  }

  getDependenceTypeByIndex(index) {
    return this.costDependencies[index].type;
  }

  getDeliveryPriceByIndex(index) {
    return this.costDependencies[index].deliveryPrice;
  }

  getFromOrderPriceByIndex(index) {
    return this.costDependencies[index].fromOrderPrice;
  }

  getToOrderPriceByIndex(index) {
    return this.costDependencies[index].toOrderPrice;
  }

  getDeliveryPriceTypeByIndex(index) {
    return this.costDependencies[index].deliveryPriceType;
  }

  getPreviewString(index) {
    if (this.getToOrderPriceByIndex(index) !== '') {
      return `${i18n.t('OrderFrom')} ${this.getFromOrderPriceByIndex(index)} ${i18n.t('to')} ${this.getToOrderPriceByIndex(index)} ${i18n.t(getCurrency())}`;
    }
    return `${i18n.t('OrderValueFrom')} ${this.costDependencies[index].fromOrderPrice} ${i18n.t(getCurrency())}`;
  }

  getMinimumOrderPriceInDependencies() {
    const prices = this.costDependencies.map((dependence) => {
      return Number(dependence.fromOrderPrice);
    });
    return Math.min(...prices);
  }

  getMaximumOrderPriceInDependencies() {
    const prices = this.costDependencies.map((dependence) => {
      return Number(dependence.fromOrderPrice);
    });
    return Math.max(...prices);
  }

  getToOrderPriceInDependencies() {
    if (this.costDependencies.length > 1) {
      for (let index = 0; index < this.costDependencies.length; index++) {
        let prices = this.costDependencies.map((dependence) => {
          if (Number(dependence.fromOrderPrice) > Number(this.getFromOrderPriceByIndex(index))) {
            return Number(dependence.fromOrderPrice);
          }
        });
        prices = prices.filter((element) => element !== undefined);
        if (prices.length !== 0) {
          this.setToOrderPrice(index, Math.min(...prices).toFixed(2));
          this.setDependenceType(index, 'interval');
        } else {
          this.setToOrderPrice(index, '');
          this.setDependenceType(index, 'moreThen');
        }
      }
    } else {
      this.setToOrderPrice(0, '');
      this.setDependenceType(0, 'moreThen');
      return null;
    }
  }

  returnMinimumOrderPriceMessage() {
    this.setMinOrderPrice(this.getMinimumOrderPriceInDependencies());
    if (this.getMinimumOrderPriceInDependencies() > 0) {
      return `${this.getMinimumOrderPriceInDependencies()}`;
    }
    return '';
  }

  checkOrderCostUnique(valueIndex, newValue) {
    const index = this.costDependencies.findIndex((element, i) => (element.fromOrderPrice === newValue && i != valueIndex));
    if (index === -1) {
      return true;
    }
    return false;
  }
}

export default DeliveryCostData;
