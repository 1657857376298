import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Иконка звонка
 * @public
 * @version 1.5.0
 * @param {String} [width="3.25rem"]
 * @param {String} [height="3.25rem"]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * import { UiCallIcon, UiPlates, UiPlate } from '@compassplus/ui-mobicash';
 *
 * const App = () => {
 *
 *   return (
 *     <>
 *       <UiCallIcon />
 *       <UiPlates>
 *         <UiPlate view="2x1" image={ <UiCallIcon width="100%" height="100%" /> } />
 *       </UiPlates>
 *     </>
 *   );
 * }; */
const UiCallIcon = ({
  width,
  height,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      innerRef={ innerRef }
      width={ width }
      height={ height }
      viewBox="0 0 52 52"
    >
      <path fillRule="evenodd" clipRule="evenodd"
        d="M14.8625 34.5498C18.0625 37.6498 21.5625 39.9498 24.7625 41.0498C27.9625 42.1498 31.3625 42.3498 33.7625 39.9498L35.3625 38.4498C36.6625 37.2498 36.3625 35.2498 34.9625 34.2498L30.6625 30.7498H30.5625C29.4625 30.0498 27.9625 30.2498 27.0625 31.1498L24.7625 33.3498C24.5625 33.5498 24.3625 33.5498 24.1625 33.4498C21.5625 32.4498 19.3625 30.8498 17.7625 28.7498C16.5625 27.1498 16.0625 25.9498 16.0625 25.7498V25.6498C15.9625 25.4498 16.0625 25.2498 16.1625 25.0498L18.4625 22.8498C19.3625 21.9498 19.5625 20.5498 18.8625 19.4498L15.7625 15.3498C14.7625 13.8498 12.6625 13.6498 11.4625 14.9498L10.9625 15.3498L9.26251 16.9498C7.26251 18.8498 6.96251 21.7498 8.36251 25.2498C9.46251 28.3498 11.7625 31.6498 14.8625 34.5498Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M14.562 32.1499C17.562 35.1499 20.762 37.3499 23.762 38.4499C26.762 39.5499 29.862 39.6499 32.162 37.4499L33.662 35.9499C34.862 34.7499 34.662 32.8499 33.262 31.9499L29.262 28.5499H29.162C28.162 27.8499 26.762 28.0499 25.862 28.9499L23.662 31.1499C23.462 31.3499 23.262 31.3499 23.062 31.2499C20.662 30.2499 18.562 28.7499 17.062 26.7499C15.862 25.2499 15.462 24.0499 15.462 23.8499V23.7499C15.362 23.5499 15.462 23.3499 15.562 23.1499L17.762 20.9499C18.662 20.0499 18.762 18.7499 18.162 17.6499L15.262 13.6499C14.362 12.2499 12.362 12.0499 11.162 13.2499L10.762 13.6499L9.16205 15.2499C7.36205 17.0499 6.96205 19.8499 8.26205 23.2499C9.46205 26.0499 11.662 29.2499 14.562 32.1499Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M13.0592 12.9619C12.4822 12.9619 11.9302 13.1889 11.5162 13.6039L9.51623 15.6039C7.81723 17.3019 7.53823 19.9549 8.72923 23.0709C9.95523 25.9309 12.1552 29.0359 14.9152 31.7959C17.8882 34.7689 21.0062 36.9069 23.9342 37.9809C27.2682 39.2029 29.9202 38.9029 31.8162 37.0889L33.3092 35.5969C33.7762 35.1289 34.0062 34.5029 33.9382 33.8769C33.8722 33.2639 33.5282 32.7149 32.9922 32.3709L28.8752 28.9599C28.0992 28.4159 26.9562 28.5639 26.2162 29.3039L24.0162 31.5039C23.6842 31.8349 23.2552 31.9039 22.8382 31.6969C20.2892 30.6359 18.1432 29.0239 16.6622 27.0499C15.4642 25.5519 14.9622 23.8449 14.9622 23.8449C14.8432 23.4729 14.9682 23.1199 15.1132 22.9289C15.2582 22.7379 17.4092 20.5969 17.4092 20.5969C18.1232 19.8809 18.2442 18.8439 17.7232 17.8889L14.8572 13.9429C14.4992 13.3879 13.9362 13.0439 13.2952 12.9749C13.2162 12.9659 13.1382 12.9619 13.0592 12.9619ZM27.6032 39.7069C26.3682 39.7069 25.0242 39.4449 23.5902 38.9199C20.5272 37.7959 17.2832 35.5779 14.2082 32.5039C11.3602 29.6549 9.08523 26.4389 7.80323 23.4469C6.45423 19.9209 6.81423 16.8909 8.80923 14.8969L10.8092 12.8969C11.4942 12.2109 12.4392 11.8729 13.4022 11.9809C14.3452 12.0819 15.1762 12.5919 15.6832 13.3789L18.5672 17.3569C19.3312 18.7499 19.1412 20.2779 18.1162 21.3039L15.9742 23.4449C15.8472 23.6289 15.9622 23.8499 15.9622 23.8499C15.9822 23.9699 16.3282 25.0319 17.4532 26.4379C18.8362 28.2819 20.8392 29.7819 23.2542 30.7889L25.5092 28.5969C26.5612 27.5429 28.1352 27.3239 29.3112 28.0499L33.5862 31.5689C34.3242 32.0389 34.8342 32.8549 34.9332 33.7699C35.0332 34.6979 34.6982 35.6209 34.0162 36.3039L32.5162 37.8039C31.1922 39.0699 29.5302 39.7069 27.6032 39.7069Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M25.8625 37.1001C25.8335 37.1001 25.8045 37.0951 25.7765 37.0851C22.7465 35.9741 19.5345 33.7751 16.4855 30.7261C13.5655 27.8071 11.3435 24.5791 10.2285 21.6381C10.1795 21.5091 10.2445 21.3651 10.3735 21.3161C10.5035 21.2671 10.6475 21.3321 10.6965 21.4611C11.7865 24.3371 13.9685 27.5021 16.8395 30.3731C19.8365 33.3711 22.9875 35.5291 25.9485 36.6151C26.0785 36.6631 26.1445 36.8071 26.0975 36.9361C26.0605 37.0381 25.9645 37.1001 25.8625 37.1001Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M32.1623 24.5C32.2063 24.5 32.2503 24.512 32.2883 24.534C33.3563 25.157 34.6963 25.5 36.0623 25.5C41.8643 25.253 43.6123 20.222 43.6123 18.05C43.6123 13.887 40.2253 10.5 36.0623 10.5C33.1233 10.5 30.4653 12.208 29.2903 14.852C28.7723 15.888 28.5123 16.961 28.5123 18.05C28.5123 19.476 28.8283 20.71 29.4783 21.824C29.6143 22.093 29.6333 22.484 29.4703 22.692L28.1313 25.201L31.6133 24.505L32.1623 24.5ZM36.0623 26C34.6343 26 33.2283 25.646 32.0953 25H31.6623L27.7113 25.795C27.6163 25.815 27.5193 25.776 27.4613 25.699C27.4043 25.621 27.3963 25.518 27.4413 25.432L29.0413 22.432C29.0533 22.41 29.0683 22.39 29.0863 22.373C29.0963 22.344 29.0973 22.179 29.0383 22.062C28.3503 20.883 28.0123 19.566 28.0123 18.05C28.0123 16.882 28.2903 15.735 28.8383 14.638C30.0893 11.825 32.9263 10 36.0623 10C40.5013 10 44.1123 13.611 44.1123 18.05C44.1123 20.097 42.6293 25.909 36.0623 26Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M32.7624 22.1499C33.3694 22.1499 33.8624 21.6579 33.8624 21.0499C33.8624 20.4419 33.3694 19.9499 32.7624 19.9499C32.1554 19.9499 31.6624 20.4419 31.6624 21.0499C31.6624 21.6579 32.1554 22.1499 32.7624 22.1499Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M36.2624 22.1499C36.8694 22.1499 37.3624 21.6579 37.3624 21.0499C37.3624 20.4419 36.8694 19.9499 36.2624 19.9499C35.6554 19.9499 35.1624 20.4419 35.1624 21.0499C35.1624 21.6579 35.6554 22.1499 36.2624 22.1499Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M39.7624 22.1499C40.3694 22.1499 40.8624 21.6579 40.8624 21.0499C40.8624 20.4419 40.3694 19.9499 39.7624 19.9499C39.1554 19.9499 38.6624 20.4419 38.6624 21.0499C38.6624 21.6579 39.1554 22.1499 39.7624 22.1499Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
    </Element>
  );
};

UiCallIcon.propTypes = {
  /** Определяет ширину иконки */
  width: PropTypes.string,
  /** Определяет высоту иконки */
  height: PropTypes.string
};

UiCallIcon.defaultProps = {
  width: '3.25rem',
  height: '3.25rem'
};

export default UiCallIcon;