import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  html {
    font-size: 16px;
    background: #EBEBEB;
  }
  h1 {
    margin-bottom: 0px;
  }
  #root {
    height: 100%;
    min-width: 320px;
  }
 
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
  .anticon{
    color: ${({ theme }) => theme.textTitle};
  }
  
  .image-button {
  /* remove standard background appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom radiobutton appearance */
    display: inline-block;
    width: 14px !important;
    height: 14px !important;
    padding: 4px !important;
    /* background-color only for content */
    background-clip: content-box;
    border: none !important;
    background-color: #BE1E24;
    opacity: 0.4;
    border-radius: 50%;
  }

  .image-button:checked {
    opacity: 1;
    background-color: #BE1E24;
    padding: 2px !important;
    border: 1px solid #BE1E24 !important;
  }

  input[type="radio"] {
    /* remove standard background appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom radiobutton appearance */
    display: inline-block;
    width: 16px;
    height: 16px;
    padding: 2px;
    /* background-color only for content */
    background-clip: content-box;
    border: 1px solid #BE1E24;
    border-radius: 50%;
  }
  
  .disabled-radio {
    border: 1px solid #D6D6D6 !important;
  }

  /* appearance for checked radiobutton */
  input[type="radio"]:checked {
    background-color: #BE1E24;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  
  
  .ant-select-dropdown,
  .ant-dropdown-menu{
    background: ${({ theme }) => theme.sectionBackground};
  }
  .ant-select-item.ant-select-item-option.ant-select-item-option-active.ant-select-item-option-selected,
  .ant-select-item.ant-select-item-option.ant-select-item-option-active,
  .ant-select-item.ant-select-item-option.ant-select-item-option-selected,
  .ant-dropdown-menu-item.ant-dropdown-menu-item-active{
    background: ${({ theme }) => theme.grayDivBackground};
  }
  .ant-notification-hook-holder, .ant-notification-notice{
    background: ${({ theme }) => theme.cardBackground}CC;
    box-shadow: ${({ theme }) => theme.shadowBox};
    text-align: center;
    padding: inherit;
    width: auto;
    padding: 12px 0px 5px 20px;
  }
  .ant-notification-notice-message{
    color: ${({ theme }) => theme.textTitle};
  }
  .ant-notification.ant-notification-topRight{
    right: calc((95% - 16.625rem)/2) !important;
  }
  .ant-modal-content{
    background: ${({ theme }) => theme.applicationBackground};
  }
  .ant-modal-confirm-title{
    color: ${({ theme }) => theme.textTitle} !important;
  }
  .ant-modal-confirm-btns, .ant-modal-footer{
    .ant-btn{
      background: ${(props) => props.theme.textLink}0D;
      border-radius: 2px;
      border: 0px;
      color: ${(props) => props.theme.textLink};
      padding: 0px 8px 0px 8px;
    }
    .ant-btn:hover, .ant-btn:active, .ant-btn:focus{
      border: 0px;
      background: ${(props) => props.theme.textLink}1A;
      color: ${(props) => props.theme.textLink};
    }
    .ant-btn-primary{
      background: ${(props) => props.theme.textLink} !important;
      border: 1px solid ${(props) => props.theme.textLink} !important;
      box-shadow: ${(props) => props.theme.shadowBox};
      border-radius: 2px !important;
      color: #FFFFFF !important;
    }
  }
  .ant-modal-confirm-btns{
    .ant-btn{
      min-width: 75px;
    }
  }
  .ant-modal-footer{
    .ant-btn-primary:hover, .ant-btn-primary:active, .ant-btn-primary:focus{
      border: 1px solid ${(props) => props.theme.applicationPosPrimaryColor} !important;
      background: ${(props) => props.theme.textLink} !important;
      color: #FFFFFF !important;
    }
  }
  .ant-modal-mask, .ant-image-preview-mask{
    background: rgba(0, 0, 0, 0.6);
  }
  
  .ant-collapse-content {
    background-color: ${(props) => props.theme.sectionBackground};
  }
  
  .ant-popover-inner-content{
    padding: 0;
  }
`;

// Глобальный дизайн всех всплывающих элементов
