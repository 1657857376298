import PropTypes from 'prop-types';
import cn from 'classnames';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../context/ThemeProvider';

import BottomDivider from '../BottomDivider';

import styles from './ContainerItem.module.css';

export const StyledContainerItem = styled.div`
  ${ (props) => {
    if (props.access || props.onClick) return css`
      cursor: pointer;

      &:active {
        background: ${ props.styles.colors.keyboardBackgroundColor };
      }

      &:active input::placeholder,
      &:active input::-webkit-input-placeholder {
        color: ${ props.styles.colors.textTitle };
        opacity: 0.2;
      }

      &:active input:-moz-placeholder {
        color: ${ props.styles.colors.textTitle };
        opacity: 0.2;
      }

      &:active input::-moz-placeholder {
        color: ${ props.styles.colors.textTitle };
        opacity: 0.2;
      }

      &:active input:-ms-input-placeholder {
        color: ${ props.styles.colors.textTitle };
        opacity: 0.2;
      }
    `
  }
  }

  ${ (props) => props.active && css`
    background: ${ props.styles.colors.keyboardBackgroundColor };

    input::placeholder,
    input::-webkit-input-placeholder {
      color: ${ props.styles.colors.textTitle };
      opacity: 0.2;
    }

    input:-moz-placeholder {
      color: ${ props.styles.colors.textTitle };
      opacity: 0.2;
    }

    input::-moz-placeholder {
      color: ${ props.styles.colors.textTitle };
      opacity: 0.2;
    }

    input:-ms-input-placeholder {
      color: ${ props.styles.colors.textTitle };
      opacity: 0.2;
    }
  ` }

  ${ (props) => props.disabled && css`
    pointer-events: none;
  ` }
`;

const ContainerItem = ({
  className,
  active,
  access,
  disabled,
  bottomDivider,
  onClick,
  innerRef,
  children,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <StyledContainerItem
      { ...props }
      className={ cn(styles.container, className) }
      active={ active }
      disabled={ disabled }
      onClick={ onClick }
      ref={ innerRef }
      styles={ isTheme.styles }
    >
      { children }
      { bottomDivider && <BottomDivider /> }
    </StyledContainerItem>
  );
};

ContainerItem.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Выделяет контейнер как активный элемент */
  active: PropTypes.bool,
  /** Разрешение на обработчик события :active */
  access: PropTypes.bool,
  /** Делает элемент неактивным */
  disabled: PropTypes.bool,
  /** Добавляет нижнюю разделительную линию */
  bottomDivider: PropTypes.bool,
  /** Добавляет обработчик на слушатель по нажатию */
  onClick: PropTypes.func,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  /** Контент чекбокса */
  children: PropTypes.any
};

ContainerItem.defaultProps = {
  active: false,
  access: true,
  disabled: false,
  bottomDivider: false
};

export default ContainerItem;