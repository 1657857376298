import PropTypes from 'prop-types';
import cn from 'classnames';
import styled, { css } from 'styled-components';

import { useOptions } from '../../../context/OptionsProvider';
import { useTheme } from '../../../context/ThemeProvider';

import UiImage from '../UiImage';

import styles from './UiSubstrate.module.css';

const StyledContainer = styled.div`
  ${ (props) => css`
    ${ props.styles.typography.DialogText };
    color: ${ props.styles.colors.textReadOnly };
    background: ${ props.styles.colors.sectionBackground };
    border: 0.063rem solid ${ props.styles.colors.textDisable };
  ` }

  ${ (props) => {
    if (props.view === 'round') {
      return css`
        border-radius: 50%;
      `;
    } else if (props.view === 'square') {
      return css`
        border-radius: 0.25rem;
      `;
    } else {
      return css`
        border-radius: 0.25rem;
      `;
    }
  } };

  ${ (props) => {
    if (props.type === 'default' && !(props.view === 'round' && (props.width !== props.height))) {
      return css`
        width: ${ props.width };
        height: ${ props.height };
        min-width: ${ props.width };
        min-height: ${ props.height };
      `;
    } else if (props.type === 'logo') {
      return css`
        width: 3rem;
        height: 3rem;
        min-width: 3rem;
        min-height: 3rem;
      `;
    } else if (props.type === 'image' && props.view !== 'round') {
      return css`
        width: 4.75rem;
        height: 3rem;
        min-width: 4.75rem;
        min-height: 3rem;
      `;
    } else if (props.type === 'paymentMethod' && props.view !== 'round' && props.screen === 'mobile') {
      return css`
        width: 2.188rem;
        height: 1.25rem;
        min-width: 2.188rem;
        min-height: 1.25rem;
      `;
    } else if (props.type === 'paymentMethod' && props.view !== 'round' && props.screen === 'desktop') {
      return css`
        width: 2.688rem;
        height: 1.5rem;
        min-width: 2.688rem;
        min-height: 1.5rem;
      `;
    } else {
      return css`
        width: 3rem;
        height: 3rem;
        min-width: 3rem;
        min-height: 3rem;
      `;
    }
  } };
`;

/**
 * Substrate component
 * @public
 * @version 0.0.90
 * @param {String} className
 * @param {String} [src=]
 * @param {String} [alt=Alt]
 * @param {'round', 'square'} [view=round]
 * @param {'default', 'logo', 'image', 'paymentMethod'} [type=default]
 * @param {String} [setWidth=3rem]
 * @param {String} [setHeight=3rem]
 * @param {*} children
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <Substrate />
 */
const UiSubstrate = ({
  className,
  src,
  alt,
  view,
  type,
  setWidth,
  setHeight,
  children,
  ...props
}) => {

  const isOptions = useOptions();
  const isTheme = useTheme();

  return (
    <StyledContainer
      { ...props }
      className={ cn(styles.substrate, className) }
      view={ view }
      type={ type }
      screen={ isOptions.screen }
      width={ setWidth }
      height={ setHeight }
      styles={ isTheme.styles }
    >
      { !!src ?
        <UiImage className={ styles.image } src={ src } alt={ alt } />
        :
        children
      }
    </StyledContainer>
  );
};

UiSubstrate.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Определяет URL-адрес до ресурса */
  src: PropTypes.string,
  /** Определяет альтернативное текстовое описание изображения */
  alt: PropTypes.string,
  /** Определить вид подложки */
  view: PropTypes.oneOf(['round', 'square']),
  /** Определить тип подложки */
  type: PropTypes.oneOf(['default', 'logo', 'image', 'paymentMethod']),
  /** Установить ширину компонента */
  setWidth: PropTypes.string,
  /** Установить высоту компонента */
  setHeight: PropTypes.string,
};

UiSubstrate.defaultProps = {
  src: '',
  alt: 'Alt',
  view: 'round',
  type: 'default',
  setWidth: '3rem',
  setHeight: '3rem'
};

export default UiSubstrate;