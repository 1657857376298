import { useRef } from 'react';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../../../../../context/ThemeProvider';

import Input from '../../../../../../Functional/Input';
import UiText from '../../../../../UiText';
import { StyledBlockedInput, StyledCardNumbers, StyledInputBackground } from '../Styled';

import { useForkRef } from '../../../../../../../hooks';

import styles from './ExpDate.module.css';

const StyledExpDate = styled(Input)`
  ${ (props) => {
    if (props.disabled) {
      return css`
        ${ StyledCardNumbers };
        ${ StyledBlockedInput };
        text-align: center;
      `;
    } else {
      return css`
        ${ StyledInputBackground };
      `;
    }
  } }
`;

const StyledText = styled(UiText)`
  font-size: 0.25rem;
  line-height: 0.25rem;
`;

const ExpDate = ({
  disabled,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  const expDateRef = useRef(null);
  const refs = useForkRef([innerRef, expDateRef]);

  return (
    <div className={ styles.container }>
      <StyledText className={ styles.monthYear }>
        { 'MONTH/YEAR' }
      </StyledText>
      <StyledText className={ styles.validThru } position="right">
        { 'VALID\nTHRU' }
      </StyledText>
      <StyledExpDate
        inputMode="numeric"
        { ...props }
        type="text"
        className={ styles.expDate }
        disabled={ disabled }
        innerRef={ refs }
        styles={ isTheme.styles }
      />
    </div>
  );
};

export default ExpDate;