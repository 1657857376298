import styled from 'styled-components';
import { UiCardSection } from '@compassplus/ui-mobicash';

export const MerchantWithCart = styled(UiCardSection)`
 & > div:first-child {
    border: none;
    max-width: 48px !important;
    max-height: 48px !important;
    min-width: 48px !important;
    min-height: 48px !important;
    overflow: none;
 }
`;
