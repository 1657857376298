import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useTheme } from '../../../../../../context/ThemeProvider';

import styles from './PaymentOrder.module.css';

const StyledPaymentOrder = styled.span`
  color: ${ (props) => props.styles.colors.applicationPrimaryColor };
  ${ (props) => props.styles.typography.SectionDescription }
`;

const PaymentOrder = ({
  text
}) => {

  const isTheme = useTheme();

  return (
    <StyledPaymentOrder className={ styles.paymentOrder } styles={ isTheme.styles }>{ text }</StyledPaymentOrder>
  );
};

PaymentOrder.propTypes = {
  text: PropTypes.string
};

export default PaymentOrder;