import UiText from '../../../UiText';
import UiSubstrate from '../../../UiSubstrate';
import UiFavorite from '../../../UiFavorite';
import DateComponent from '../CommonComponents/DateComponent';

import styles from '../../UiCardSection.module.css';

const CardSection = ({
  type,
  title,
  description,
  leftElement,
  favorite,
  text,
  date,
  elements,
  rightElement
}) => {

  const substrate = checkSubstrate();

  return (
    <>
      { leftElement &&
        <>
          <UiSubstrate
            className={ styles.leftElement }
            { ...substrate }
          >
            { leftElement }
          </UiSubstrate>
          { favorite &&
            <UiFavorite
              className={ styles.favorite }
              active={ favorite.active }
              onClick={ favorite.onClick }
            />
          }
        </>
      }
      <div className={ styles.content }>
        <UiText className={ styles.text } view="title" typography="section-title">
          { title }
        </UiText>
        { type !== 'one-string' && description &&
          <UiText className={ styles.text } view="readonly" typography="section-description">
            { description }
          </UiText>
        }
        { elements &&
          <div className={ styles.elements }>
            { elements }
          </div>
        }
      </div>
      { rightElement &&
        <div className={ styles.rightElement }>
          { rightElement }
        </div>
      }
      { (text || date) &&
        <DateComponent text={ text } date={ date } />
      }
    </>
  );

  function checkSubstrate() {
    if (type === 'card') {
      return { view: 'square', type: 'image' };
    } else if (type === 'marketplace-cart-item') {
      return { view: 'square', type: 'logo' };
    } else {
      return { view: 'round', type: 'logo' };
    }
  }
};

export default CardSection;