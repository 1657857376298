import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Element component
 * @public
 * @version 0.0.90
 * @param {Function} onClick
 * @param {Boolean} [disabled=false]
 * @param {Boolean} [propagation=true]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiArrow />
 */
const UiArrow = ({
  onClick,
  disabled,
  propagation,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      color={ isTheme.styles.colors.textReadOnly }
      disabled={ disabled }
      propagation={ propagation }
      onClick={ onClick }
      innerRef={ innerRef }
      width="1rem"
      height="1rem"
      viewBox="0 0 16 16"
    >
      <path
        d="M9.14847 1.07432C8.8083 0.65217 9.09029 0 9.61298 0C9.79158 0 9.96126 0.0830167 10.0775 0.227262L15.7413 7.25624C16.0862 7.68423 16.0862 8.31577 15.7413 8.74376L10.0775 15.7727C9.96126 15.917 9.79158 16 9.61298 16C9.09029 16 8.8083 15.3478 9.14847 14.9257L14.4294 8.37188C14.6019 8.15789 14.6019 7.84211 14.4294 7.62812L9.14847 1.07432Z" />
    </Element>
  );
};

UiArrow.propTypes = {
  /** Обработчик события при клике */
  onClick: PropTypes.func,
  /** Компонент неактивен */
  disabled: PropTypes.bool,
  /** Разрешить/запретить всплытие обработчика onClick */
  propagation: PropTypes.bool,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

UiArrow.defaultProps = {
  disabled: false,
  propagation: true
};

export default UiArrow;