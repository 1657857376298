import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useTheme } from '../../../../../context/ThemeProvider';

import UiText from '../../../UiText';
import UiQuestions from '../../../UiQuestions';

import styles from './Description.module.css';

const StyledRequired = styled.span`
  color: ${ (props) => props.styles.colors.warningColor };
`;

const Description = ({
  description,
  required,
  help,
  onHelp
}) => {

  const isTheme = useTheme();

  return (
    <UiText className={ styles.description } view="title" typography="section-description-semibold">
      { description }
      { required &&
        <StyledRequired styles={ isTheme.styles }>
          &nbsp;*
        </StyledRequired>
      }
      { help &&
        <UiQuestions className={ styles.question } propagation={ false } onClick={ onHelp } />
      }
    </UiText>
  );
};

Description.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  required: PropTypes.bool
};

export default Description;