import { useEffect, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../../../../../context/ThemeProvider';

import UiIcon from '../../../../../UiIcon';
import UiText from '../../../../../UiText';

import styles from './Header.module.css';

const StyledValue = styled.li`
  ${ (props) => props.active && css`
    color: ${ props.styles.colors.applicationBackground };
    background: ${ props.styles.colors.applicationBackground };
    box-shadow: 0.25rem 0, -0.25rem 0;
  ` }
`;

const Header = ({
  valueInformation,
  minValue,
  maxValue,
  setValue
}) => {

  const isTheme = useTheme();

  const years = useMemo(() => {
    const years = [];

    for (let year = minValue.getFullYear(); year <= maxValue.getFullYear(); year++) {
      years.push(year);
    }

    return years;
  }, [minValue, maxValue]);
  const selectorRef = useRef(null);

  const wheelHandler = (event) => {
    const selector = selectorRef.current;
    const delta = event.deltaY || event.deltaX;

    selector.scrollLeft += delta;
    event.preventDefault();
  };

  useEffect(() => {
    const { current } = selectorRef;

    if (current) {
      current.addEventListener('wheel', wheelHandler);

      return () => {
        current.removeEventListener('wheel', wheelHandler);
      };
    }
  }, []);

  const setNextYearHandler = () => {
    const selector = selectorRef.current;

    if (selector.children[0]) {
      selector.scrollLeft += selector.clientWidth / 3;
    }
  };

  const setPrevYearHandler = () => {
    const selector = selectorRef.current;

    if (selector.children[0]) {
      selector.scrollLeft -= selector.clientWidth / 3;
    }
  };

  const SelectorItem = ({ year }) => {

    const active = useMemo(() => valueInformation.year === year, [year]);
    const itemRef = useRef(null);

    useEffect(() => {
      const item = itemRef.current;

      if (active) {
        item.scrollIntoView({ inline: 'center' });
      }
    }, [active]);

    const setYearHandler = (year) => {
      const { month, day } = valueInformation;

      setValue(new Date(year, month, day));
    };

    return (
      <StyledValue
        className={ styles.value }
        active={ active }
        onClick={ () => setYearHandler(year) }
        ref={ itemRef }
        styles={ isTheme.styles }
      >
        <UiText className={ styles.year } view="title" typography="section-value" position="center">
          { year }
        </UiText>
      </StyledValue>
    );
  };

  return (
    <div className={ styles.header }>
      <UiIcon
        icon="back"
        setWidth="1.375rem"
        setHeight="1.375rem"
        onClick={ setPrevYearHandler }
      />
      <ul className={ styles.selector } ref={ selectorRef }>
        { years.map((year, index) => {
          return (
            <SelectorItem key={ index } year={ year } />
          );
        }) }
      </ul>
      <UiIcon
        icon="back"
        setWidth="1.375rem"
        setHeight="1.375rem"
        onClick={ setNextYearHandler }
        style={ { transform: 'scale(-1, 1)' } }
      />
    </div>
  );
};

export default Header;