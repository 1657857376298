import React, { useEffect } from 'react';

import { observer } from 'mobx-react-lite';

import { MContentDiv } from '../../../components/TemplateStyle';
import GlobalStateStore from '../../../stores/GlobalStateStore';
import Administration from './Administration';

const AdministrationPage = observer((props) => {
  useEffect(() => {
    GlobalStateStore.setHeaderProperties({
      title: 'Administration',
      isBack: false,
    });
  }, []);

  return (
    <MContentDiv>
      <Administration {...props} />
    </MContentDiv>
  );
});

export default AdministrationPage;
