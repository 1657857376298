import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Иконка запроса на обслуживание
 * @public
 * @version 1.5.0
 * @param {String} [width="3.25rem"]
 * @param {String} [height="3.25rem"]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * import { UiServiceRequestsIcon, UiPlates, UiPlate } from '@compassplus/ui-mobicash';
 *
 * const App = () => {
 *
 *   return (
 *     <>
 *       <UiServiceRequestsIcon />
 *       <UiPlates>
 *         <UiPlate view="2x1" image={ <UiServiceRequestsIcon width="100%" height="100%" /> } />
 *       </UiPlates>
 *     </>
 *   );
 * }; */
const UiServiceRequestsIcon = ({
  width,
  height,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      innerRef={ innerRef }
      width={ width }
      height={ height }
      viewBox="0 0 52 52"
    >
      <path
        d="M2 27.5833C2 25.9265 3.34315 24.5833 5 24.5833H34C35.6568 24.5833 37 25.9265 37 27.5833V31.5833H48C49.6569 31.5833 51 32.9265 51 34.5833V42.0379C51 43.6947 49.6569 45.0379 48 45.0379H44V49.4583L34.7576 45.0379H16C14.3431 45.0379 13 43.6947 13 42.0379V40.5833L9 42.0379V38.0379H5C3.34315 38.0379 2 36.6947 2 35.0379V27.5833Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path
        d="M46 22.5C47.6569 22.5 49 23.8431 49 25.5V39.5C49 41.1569 47.6569 42.5 46 42.5H43V47.5L37.1667 42.5H17C15.3431 42.5 14 41.1569 14 39.5V25.5C14 23.8431 15.3431 22.5 17 22.5H46Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M13.75 25.5C13.75 23.7051 15.2051 22.25 17 22.25H46C47.7949 22.25 49.25 23.7051 49.25 25.5V39.5C49.25 41.2949 47.7949 42.75 46 42.75H43.25V47.5C43.25 47.5976 43.1932 47.6863 43.1045 47.7271C43.0158 47.7679 42.9114 47.7534 42.8373 47.6898L37.0742 42.75H17C15.2051 42.75 13.75 41.2949 13.75 39.5V25.5ZM17 22.75C15.4812 22.75 14.25 23.9812 14.25 25.5V39.5C14.25 41.0188 15.4812 42.25 17 42.25H37.1667C37.2263 42.25 37.2841 42.2713 37.3294 42.3102L42.75 46.9564V42.5C42.75 42.3619 42.8619 42.25 43 42.25H46C47.5188 42.25 48.75 41.0188 48.75 39.5V25.5C48.75 23.9812 47.5188 22.75 46 22.75H17Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M7 11.5C5.34315 11.5 4 12.8431 4 14.5V31.5C4 33.1569 5.34315 34.5 7 34.5H10V39.5L15.8333 34.5H39C40.6569 34.5 42 33.1569 42 31.5V14.5C42 12.8431 40.6569 11.5 39 11.5H7Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M7 12C5.61929 12 4.5 13.1193 4.5 14.5V31.5C4.5 32.8807 5.61929 34 7 34H10C10.2761 34 10.5 34.2239 10.5 34.5V38.4129L15.5079 34.1204C15.5986 34.0427 15.714 34 15.8333 34H39C40.3807 34 41.5 32.8807 41.5 31.5V14.5C41.5 13.1193 40.3807 12 39 12H7ZM3.5 14.5C3.5 12.567 5.067 11 7 11H39C40.933 11 42.5 12.567 42.5 14.5V31.5C42.5 33.433 40.933 35 39 35H16.0183L10.3254 39.8796C10.1771 40.0067 9.96847 40.0359 9.79107 39.9543C9.61367 39.8727 9.5 39.6953 9.5 39.5V35H7C5.067 35 3.5 33.433 3.5 31.5V14.5Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M9.16667 19.6967L20.3071 19.6967C20.5832 19.6967 20.8071 19.9205 20.8071 20.1967C20.8071 20.4728 20.5832 20.6967 20.3071 20.6967H9.16667C8.89053 20.6967 8.66667 20.4728 8.66667 20.1967C8.66667 19.9205 8.89053 19.6967 9.16667 19.6967Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M9.16689 16.4167H23.3335C23.6096 16.4167 23.8335 16.6405 23.8335 16.9167C23.8335 17.1928 23.6096 17.4167 23.3335 17.4167H9.16689C8.89075 17.4167 8.66689 17.1928 8.66689 16.9167C8.66689 16.6405 8.89075 16.4167 9.16689 16.4167Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path
        d="M33.6001 20.6667H23.8334C23.5573 20.6667 23.3334 20.4428 23.3334 20.1667C23.3334 19.8905 23.5573 19.6667 23.8334 19.6667L33.6001 19.6667C33.8762 19.6667 34.1001 19.8905 34.1001 20.1667C34.1001 20.4428 33.8762 20.6667 33.6001 20.6667Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M9.16701 22.5568H15.2085C15.4846 22.5568 15.7085 22.7807 15.7085 23.0568C15.7085 23.3329 15.4846 23.5568 15.2085 23.5568H9.16701C8.89086 23.5568 8.66701 23.3329 8.66701 23.0568C8.66701 22.7807 8.89086 22.5568 9.16701 22.5568Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M22.3132 23.0667C22.316 23.1949 22.2663 23.3186 22.1757 23.4092C22.0851 23.4998 21.9614 23.5495 21.8332 23.5467H17.8332C17.5571 23.5467 17.3332 23.3229 17.3332 23.0467C17.3332 22.7706 17.5571 22.5467 17.8332 22.5467H21.8332C21.9688 22.5431 22.0993 22.5981 22.1913 22.6978C22.2832 22.7974 22.3277 22.9319 22.3132 23.0667Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M26.9133 22.5568C27.1895 22.5568 27.4133 22.7807 27.4133 23.0568C27.4133 23.3329 27.1895 23.5568 26.9133 23.5568H24.3333C24.0572 23.5568 23.8333 23.3329 23.8333 23.0568C23.8333 22.7807 24.0572 22.5568 24.3333 22.5568H26.9133Z"
        fill={ isTheme.styles.colors.textTitle } />
    </Element>
  );
};

UiServiceRequestsIcon.propTypes = {
  /** Определяет ширину иконки */
  width: PropTypes.string,
  /** Определяет высоту иконки */
  height: PropTypes.string
};

UiServiceRequestsIcon.defaultProps = {
  width: '3.25rem',
  height: '3.25rem'
};

export default UiServiceRequestsIcon;