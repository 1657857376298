import React from 'react';
import { UiCard, useOptions } from '@compassplus/ui-mobicash';
import { useHistory } from 'react-router';
import { DeleteSection } from '../../../../components/TemplateStyle';
import i18n from '../../../../i18n';
import DeliveryStore from '../../../../stores/DeliveryStore';

const DeleteAttributeButton = ({ attributeType, location }) => {
  const history = useHistory();
  const isOptions = useOptions();
  return (
    <div style={isOptions.screen === 'mobile'
      ? { padding: '0 1rem 0', marginTop: '1rem', width: '100%' }
      : { padding: '0 0 0 6px', marginTop: '1rem', width: '100%' }}
    >
      <UiCard>
        <DeleteSection
          title={i18n.t('DeleteAttribute')}
          onClick={() => {
            DeliveryStore.form.deleteAttribute(location.state.id);
            if (attributeType === 'PickupPoint') {
              DeliveryStore.form.addressData.deletePoint(location.state.id);
            }
            if (location.state.deliveryId) {
              history.push(`/administration/delivery/${location.state.deliveryId}`,
                {
                  deliveryId: location.state.deliveryId,
                  deliveryForm: location.state.deliveryForm,
                });
            } else {
              history.push('/administration/delivery', {
                deliveryForm: DeliveryStore.form,
              });
            }
          }}
        />
      </UiCard>
    </div>
  );
};

export default DeleteAttributeButton;
