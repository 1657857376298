import styled from 'styled-components';
import {
  UiDialog, UiCard, UiMinus, UiPlus, UiSection, UiText, UiCardSection, UiInput
} from '@compassplus/ui-mobicash';
import {
  Typography,
  Button,
  Input,
  Select,
} from 'antd';

const { Title } = Typography;
const { TextArea } = Input;

export const BodyDiv = styled.div`
  background: ${(props) => props.theme.applicationBackground};
  min-height: 100vh;
  height: fit-content;
  padding-top: 2.75rem;
  display: grid;
  align-content: space-between;
`;

export const ContentDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0px;
  margin: auto;
  max-width: 63.5rem;
  min-width: 63.5rem;
  
  @media(max-width: 1280px) {
    max-width: 80%;
    min-width: 80%;
    min-height: fit-content;
  }
  
  @media(max-width: 810px) {
    max-width: 100%;
    min-width: 100%;
  }
  
  @media(max-width: 663px) {
    max-width: 100%;
    min-width: 100%;
    min-height: fit-content;
  }
`;

export const MContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0 0;
  width: calc((100% - 0.5rem) / 2);
  max-width: calc((100% - 0.5rem) / 2);
  min-width: 20rem;
  
  @media (max-width: 810px) {
    max-width: 20rem;
  }
  
  @media(max-width: 663px) {
    padding: 1rem 0.5rem 0;
    max-width: 100%;
    min-width: 100%;
    min-height: fit-content;
  }

`;

export const SubContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 0 0.5rem;
  padding: 1rem 0 0;
  width: calc((100% - 0.5rem) / 2);
  max-width: calc((100% - 0.5rem) / 2);
  min-width: 320px;
  
  @media (max-width: 810px) {
    max-width: 20rem;
  }
  
  @media(max-width: 663px) {
    margin: 0;
    width: 100%;
    max-width: 100%;
    min-height: fit-content;
  }
  
`;

export const Text = styled(UiText)`
  width: 100%;
`;

export const AnimationCard = styled(UiCard)`
  transition: 0.5s;
  animation: show 0.5s 1; /* Указываем название анимации, её время и количество повторов*/
  animation-fill-mode: forwards; /* Чтобы элемент оставался в конечном состоянии анимации */

  @keyframes show{
    0%{
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
`;

export const AnimationCardSection = styled(UiCardSection)`
  transition: 0.5s;
  animation: show 0.5s 1; /* Указываем название анимации, её время и количество повторов*/
  animation-fill-mode: forwards; /* Чтобы элемент оставался в конечном состоянии анимации */

  @keyframes show{
    0%{
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
`

export const AnimationInput= styled(UiInput)`
  transition: 0.5s;
  animation: show 0.5s 1; /* Указываем название анимации, её время и количество повторов*/
  animation-fill-mode: forwards; /* Чтобы элемент оставался в конечном состоянии анимации */

  @keyframes show{
    0%{
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
`

export const AnimationDiv = styled.div`
  transition: 0.5s;
  animation: show 0.5s 1; /* Указываем название анимации, её время и количество повторов*/
  animation-fill-mode: forwards; /* Чтобы элемент оставался в конечном состоянии анимации */

  @keyframes show{
    0%{
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
`;

export const MainText = styled.div`
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${(props) => props.theme.textReadOnly};
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 400;
  
  margin: 1rem 0 0;
`;


export const MainTitle = styled(MainText)`
  color: ${(props) => props.theme.applicationPrimaryColor};
  
  font-size: 1.625rem;
  line-height: 2rem;
  display: flex;
  align-items: center;
  text-align: center;
`;

export const ImageCover = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Dialog = styled(UiDialog)`
  & > div {
    width: 20.75rem;
  }
`;

export const DialogInput = styled.input`
  font-size: 0.75rem;
  margin: 0 1rem;
  background: #fff;
  border: 1px solid #A3AEBF;
  width: 18.8125rem;
  height: 1.625rem;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  color: ${(props) => props.theme.textLink};
  
  :focus {
    border: 1px solid #A3AEBF;
    outline: none;
  }
`;

export const Minus = styled(UiMinus)`
  & {
    color: ${(props) => props.theme.textLink} !important;
  }
`;

export const Plus = styled(UiPlus)`
  & {
    color: ${(props) => props.theme.textLink} !important;
  }
`;

export const DeleteSection = styled(UiSection)`
  & span {
    color: ${(props) => props.theme.warningColor} !important;
  }  
`;
export const ThemeTitle = styled(Title)`
  color: ${(props) => props.theme.textTitle} !important;
`;

export const ThemeInput = styled(Input)`
  background: ${(props) => props.theme.sectionBackground};
  color: ${(props) => props.theme.textTitle};
  border-radius: 2px;
  border-color: ${(props) => props.theme.textDisable};
  min-width: 100px;
  .ant-input-group .ant-input{
    background: ${(props) => props.theme.sectionBackground};
    border: 1px solid ${(props) => props.theme.textDisable};
    color: ${(props) => props.theme.textTitle};
  }
  .ant-input-group-addon .ant-select{
    margin: -5px -12px;
    border-radius: 0px 2px 2px 0px;
  }
  .ant-input-group-addon{
    background-color: ${(props) => props.theme.applicationBackground};
    border: 1px solid ${(props) => props.theme.textDisable};
  }
  
  &.ant-input[disabled]{
    background: ${(props) => props.theme.sectionBackground};
    color: ${(props) => props.theme.textDisable};
  }
  &.ant-input[disabled]:hover{
    border-color: ${(props) => props.theme.textDisable};
  }
`;


export const ThemeButton = styled(Button)`
  background: ${(props) => props.theme.textLink};
  border: 1px solid ${(props) => props.theme.textLink};
  box-shadow: ${(props) => props.theme.shadowBox};
  border-radius: 2px;
  color: #FFFFFF;
  &:hover, &:active, &:focus{
    box-shadow: none;
    border: 1px solid ${(props) => props.theme.applicationPosPrimaryColor};
    background: ${(props) => props.theme.textLink};
    color: #FFFFFF;
  }
  &.ant-btn[disabled]{
    background: ${(props) => props.theme.sectionBackground} !important;
    border: 1px solid ${(props) => props.theme.textDisable} !important;
    color: ${(props) => props.theme.textReadOnly} !important;
    .anticon{
      color: ${(props) => props.theme.textReadOnly};
    }
  }
  .anticon{
    color: #FFFFFF;
  }
  margin: 5px;
`;

export const PageDiv = styled.div`
  background-color: ${(props) => props.theme.applicationBackground};
  background-image: url(${(props) => props.auth && 'https://assets.allcafe.ru/user-uploads/42363/2013/10/07/1381090732_XL.jpeg'});
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  height: 100%;
  top: 0;
  position: fixed;

  @media (max-width: 980px) {
    background-size: auto 100%;
    background-position-x: 25%;
  }
`;

export const QRAnnotation = styled.div`
  width: 100%;
  
  text-align: center;
  font-size: 14px;
  color: white;
  
  margin: auto;
  
  padding: 16px;
`;
