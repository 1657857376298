import React, {useEffect, useState} from 'react';
import ModalStore from "../../../stores/ModalStore";
import {CardTitle, ItemsContainer} from "../MarketPage/style";
import {useHistory} from "react-router";
import {MarketMerchantCard, MarketMerchantLogo} from "./style";
import {Text} from "../../../components/TemplateStyle";
import Merchant from "../../../stores/Merchant";
import MarketStore from "../../../stores/MarketStore";

const Merchants = () => {
  const history = useHistory();
  const [merchants, setMerchants] = useState([]);

  useEffect(() => {
    fetch('/api/v1/public/market/allMerchants', {
      method: 'GET'
    }).then((res) => {
      if (res.ok) {
        return res.json()
      } else {
        ModalStore.openUnexpectedErrorModal();
      }
    }).then((res) => {
      if (res) {
        setMerchants(res);
      }
    })
  }, [])

  return (
    <ItemsContainer>
      {
        merchants.map((merchant) => (
          <MarketMerchantCard
            onClick={() => {
              Merchant.clear();
              MarketStore.clear();
              history.push(`/${merchant.marketUrl}`);
            }}
            shadow
          >
            <div style={{ margin: 'auto', width: '134px' }}>
              {merchant.logo ? <MarketMerchantLogo src={merchant.logo} /> : <Text position="center" view="readonly">[LOGO]</Text>}
            </div>
            <CardTitle typography="section-title" style={{ margin: '0.25rem' }}>{merchant.tcpName}</CardTitle>
          </MarketMerchantCard>
        ))
      }
    </ItemsContainer>
  )
}

export default Merchants;