import { useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styled, { css } from 'styled-components';

import { useOptions } from '../../../context/OptionsProvider';
import { useTheme } from '../../../context/ThemeProvider';

import UiText from '../UiText';
import UiQuestions from '../UiQuestions';
import UiInfoPanel from '../UiInfoPanel';

import styles from './UiHeader.module.css';

const StyledHeader = styled.header`
  background: ${ (props) => props.styles.colors.sectionBackground };
  
  ${ (props) => !props.help && css`
    box-shadow: ${ props.styles.shadows.stiky };
  ` }

  ${ (props) => props.help && css`
    filter: ${ props.styles.shadows.infoPanel };
  ` }
`;

const StyledContainer = styled.div`
  ${ (props) => css`
    background: ${ props.styles.colors.sectionBackground };
  ` }
`;

/**
 * Header component
 * @public
 * @version 0.0.90
 * @param {String} className
 * @param {JSX.Element} left
 * @param {String} [text=Header]
 * @param {JSX.Element} right
 * @param {Boolean} [help=false]
 * @param {String} guest
 * @param {*} [children='Content']
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiHeader>{ children }</UiHeader>
 */
const UiHeader = ({
  className,
  left,
  text,
  right,
  help,
  guest,
  children,
  ...props
}) => {

  const isOptions = useOptions();
  const isTheme = useTheme();

  useEffect(() => {
    if (!help) {
      isOptions.setInfo({ active: false, menu: 'all' });
    }
  }, [help, isOptions]);

  return (
    <StyledHeader
      { ...props }
      className={ cn(styles.header, className) }
      help={ help }
      styles={ isTheme.styles }
    >
      <StyledContainer className={ styles.container } styles={ isTheme.styles }>
        <div className={ styles.elements }>
          { left }
        </div>
        <div className={ styles.content }>
          <UiText className={ styles.title } view="primary" typography="top-navbar-title" position="center">
            { text }
            { help &&
              <UiQuestions
                className={ styles.question }
                onClick={ () => isOptions.info.active ? isOptions.setInfo({ active: false }) : isOptions.setInfo({ active: true, menu: 'all' }) }
              />
            }
          </UiText>
          { guest &&
            <UiText view="readonly" typography="dashboard-description" position="center">
              { guest }
            </UiText>
          }
        </div>
        <div className={ styles.elements }>
          { right }
        </div>
      </StyledContainer>
      { help &&
        <UiInfoPanel>
          { children }
        </UiInfoPanel>
      }
    </StyledHeader>
  );
};

UiHeader.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Левый элемент заголовка */
  left: PropTypes.element,
  /** Текстовая надпись на заголовке */
  text: PropTypes.string,
  /** Правый элемент заголовка */
  right: PropTypes.element,
  /** Добавление информационной панели */
  help: PropTypes.bool,
  /** Текстовая надпись гостевого режима */
  guest: PropTypes.string,
  /** Контент выпадающей инфопанели */
  children: PropTypes.any
};

UiHeader.defaultProps = {
  text: 'Header',
  help: false,
  children: 'Content'
};

export default UiHeader;