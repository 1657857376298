import React, { useEffect, useReducer, useState } from 'react';
import { LinkToColorSection } from "./style";
import { UiIcon } from "@compassplus/ui-mobicash";
import { Dialog } from "../../TemplateStyle";
import i18n from "../../../i18n";
import { Color, ColorsContainer } from "../../../Utils/UtilsStyle";
import AttributesStore from "../../../stores/AttributesStore";
import ItemStore from "../../../stores/ItemStore";
import { observer } from "mobx-react-lite";

const LinkToColor = observer(({ selectedImage }) => {
  const [selectedColorCode, setSelectedColorCode] = useState('');
  const [isLinkColorOpen, setIsLinkColorOpen] = useState(false);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const { images } = ItemStore;
  const colors = AttributesStore.getAttributesByType('color')[0]?.complexValue;

  useEffect(() => {
    if (images.length !== 0 && images[0]?.color !== undefined) {
      setSelectedColorCode(images[0].color);
    }
  }, [images]);

  useEffect(() => {
    if (images.length !== 0 && images[selectedImage]?.color !== undefined) {
      setSelectedColorCode(images[selectedImage].color);
    } else {
      setSelectedColorCode('');
    }
  }, [selectedImage])

  if (colors && colors?.length !== 0) {
    return (
      <>
        <LinkToColorSection style={selectedColorCode !== '' ? { border: `1px solid ${selectedColorCode}` } : {}}>
          <UiIcon
            icon="legacy-preferences"
            setColor="#037AFF"
            viewBox="0 0 31 32"
            onClick={() => {
              setIsLinkColorOpen(true);
              if (images[selectedImage].color !== undefined) {
                setSelectedColorCode(images[selectedImage].color);
              }
            }}
          />
        </LinkToColorSection>
        {
          (isLinkColorOpen) && (
            <Dialog
              title={i18n.t('BindImageToColor')}
              text={i18n.t('ChooseColor')}
              buttons={[
                {
                  text: i18n.t('Bind'),
                  mode: 'bold',
                  action: () => {
                    images[selectedImage].color = selectedColorCode;
                    forceUpdate();
                    setIsLinkColorOpen(false);
                  },
                },
                {
                  text: i18n.t('Cancel'),
                  mode: '',
                  action: () => {
                    setIsLinkColorOpen(false);
                  },
                },
              ]}
            >
              <ColorsContainer>
                {colors.map((color) => (
                  <>
                    {
                      (color.code === selectedColorCode) ? (
                        <Color
                          key={color.code}
                          onClick={() => {
                            setSelectedColorCode(color.code);
                          }}
                          style={{ backgroundColor: color.code, filter: 'drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.5))' }}
                        />
                      ) : (
                        <Color
                          key={color.code}
                          onClick={() => {
                            setSelectedColorCode(color.code);
                          }}
                          style={{ backgroundColor: color.code }}
                        />
                      )
                    }
                  </>
                ))}
              </ColorsContainer>
            </Dialog>
          )
        }
      </>
    );
  }
  return null;
});

export default LinkToColor;