import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Plate share icon
 * @public
 * @version 1.3.0
 * @param {String} width
 * @param {String} height
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiShareIcon />
 */
const UiShareIcon = ({
  width,
  height,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      innerRef={ innerRef }
      width={ width }
      height={ height }
      viewBox="0 0 52 52"
    >
      <path fillRule="evenodd" clipRule="evenodd"
        d="M36.591 38.8602C36.591 40.7642 35.055 42.3082 33.16 42.3082C31.266 42.3082 29.73 40.7642 29.73 38.8602C29.73 36.9552 31.266 35.4112 33.16 35.4112C35.055 35.4112 36.591 36.9552 36.591 38.8602Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M44.691 31.4593C44.691 31.4593 44.17 31.2933 43.458 31.2723C42.914 31.2633 43.23 31.2633 43.113 31.2633H36.923V30.4973C36.923 30.3613 36.643 30.2523 36.296 30.2523H30.862C30.836 29.2313 28.705 28.4053 26.091 28.4053C23.478 28.4053 21.349 29.2313 21.32 30.2523H15.887C15.541 30.2523 15.26 30.3613 15.26 30.4973L15.25 31.2633H8.829C8.829 31.2633 8.139 31.2623 7.597 31.3603C6.797 31.4753 6 31.9033 6 32.3343V40.5523C6 41.1433 7.229 41.6243 8.738 41.6243H43.113C44.623 41.6243 45.851 41.1433 45.851 40.5523V32.3343C45.851 31.9733 45.392 31.6533 44.691 31.4593Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M41.4611 15.5521H35.6301V12.4481H29.7211C29.7311 12.3381 29.7541 12.2311 29.7541 12.1181C29.7541 9.98212 28.0231 8.25012 25.8861 8.25012C23.7501 8.25012 22.0181 9.98212 22.0181 12.1181C22.0181 12.2311 22.0421 12.3381 22.0521 12.4481H16.2871V15.5521H10.3901C9.10308 15.5521 8.05908 16.5951 8.05908 17.8831V36.8901C8.05908 38.1771 9.10308 39.2201 10.3901 39.2201H41.4611C42.7481 39.2201 43.7921 38.1771 43.7921 36.8901V17.8831C43.7921 16.5951 42.7481 15.5521 41.4611 15.5521Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M41.4274 39.7206H10.3564C8.79539 39.7206 7.52539 38.4506 7.52539 36.8896V17.8836C7.52539 16.3226 8.79539 15.0526 10.3564 15.0526H16.2874V16.0526H10.3564C9.34639 16.0526 8.52539 16.8736 8.52539 17.8836V36.8896C8.52539 37.8996 9.34639 38.7206 10.3564 38.7206H41.4274C42.4364 38.7206 43.2584 37.8996 43.2584 36.8896V17.8836C43.2584 16.8736 42.4364 16.0526 41.4274 16.0526H35.6304V15.0526H41.4274C42.9884 15.0526 44.2584 16.3226 44.2584 17.8836V36.8896C44.2584 38.4506 42.9884 39.7206 41.4274 39.7206Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M35.5967 19.347H22.8747C22.7367 19.347 22.6247 19.235 22.6247 19.097C22.6247 18.959 22.7367 18.847 22.8747 18.847H35.3467V12.699H29.6867C29.6167 12.699 29.5497 12.669 29.5027 12.618C29.4547 12.566 29.4317 12.496 29.4377 12.427C29.4417 12.381 29.4487 12.336 29.4537 12.292C29.4617 12.235 29.4707 12.178 29.4707 12.118C29.4707 10.123 27.8477 8.5 25.8527 8.5C23.8577 8.5 22.2347 10.123 22.2347 12.118C22.2347 12.178 22.2427 12.235 22.2507 12.292C22.2567 12.336 22.2637 12.381 22.2667 12.427C22.2737 12.496 22.2497 12.566 22.2027 12.618C22.1557 12.669 22.0887 12.699 22.0177 12.699H16.5037V18.847H20.8667C21.0047 18.847 21.1167 18.959 21.1167 19.097C21.1167 19.235 21.0047 19.347 20.8667 19.347H16.2537C16.1157 19.347 16.0037 19.235 16.0037 19.097V12.449C16.0037 12.31 16.1157 12.199 16.2537 12.199H21.7377C21.7357 12.172 21.7347 12.145 21.7347 12.118C21.7347 9.847 23.5817 8 25.8527 8C28.1237 8 29.9707 9.847 29.9707 12.118C29.9707 12.145 29.9697 12.172 29.9677 12.199H35.5967C35.7347 12.199 35.8467 12.31 35.8467 12.449V19.097C35.8467 19.235 35.7347 19.347 35.5967 19.347Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M17.0381 25.8514C16.3431 25.8514 15.7771 26.4204 15.7771 27.1204C15.7771 27.8204 16.3431 28.3894 17.0381 28.3894C17.7331 28.3894 18.2991 27.8204 18.2991 27.1204C18.2991 26.4204 17.7331 25.8514 17.0381 25.8514ZM17.0381 28.8894C16.0671 28.8894 15.2771 28.0954 15.2771 27.1204C15.2771 26.1444 16.0671 25.3514 17.0381 25.3514C18.0091 25.3514 18.7991 26.1444 18.7991 27.1204C18.7991 28.0954 18.0091 28.8894 17.0381 28.8894Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M27.3194 26.4418H25.8914C25.7534 26.4418 25.6414 26.3298 25.6414 26.1918C25.6414 26.0538 25.7534 25.9418 25.8914 25.9418H27.3194C27.4574 25.9418 27.5694 26.0538 27.5694 26.1918C27.5694 26.3298 27.4574 26.4418 27.3194 26.4418Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M34.4741 26.4418H29.4751C29.3371 26.4418 29.2251 26.3298 29.2251 26.1918C29.2251 26.0538 29.3371 25.9418 29.4751 25.9418H34.4741C34.6121 25.9418 34.7241 26.0538 34.7241 26.1918C34.7241 26.3298 34.6121 26.4418 34.4741 26.4418Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M36.5908 28.6839H25.8918C25.7538 28.6839 25.6418 28.5719 25.6418 28.4339C25.6418 28.2959 25.7538 28.1839 25.8918 28.1839H36.5908C36.7288 28.1839 36.8408 28.2959 36.8408 28.4339C36.8408 28.5719 36.7288 28.6839 36.5908 28.6839Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M19.7887 32.556C19.7887 32.556 19.4427 29.495 18.4277 29.319C17.9407 29.622 17.4867 29.772 17.0387 29.774C16.5897 29.772 16.1367 29.622 15.6487 29.319C14.6337 29.495 14.2887 32.556 14.2887 32.556C14.2067 32.776 14.3447 33.021 14.5497 33.021H17.0387H19.5267C19.7317 33.021 19.8697 32.776 19.7887 32.556Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M17.0383 24.2958C13.9993 24.2958 11.5283 26.7808 11.5283 29.8368C11.5283 32.8918 13.9993 35.3768 17.0383 35.3768C20.0773 35.3768 22.5483 32.8918 22.5483 29.8368C22.5483 26.7808 20.0773 24.2958 17.0383 24.2958ZM17.0383 35.8768C13.7243 35.8768 11.0283 33.1668 11.0283 29.8368C11.0283 26.5058 13.7243 23.7958 17.0383 23.7958C20.3523 23.7958 23.0483 26.5058 23.0483 29.8368C23.0483 33.1668 20.3523 35.8768 17.0383 35.8768Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M39.4693 16.6795C39.4693 19.3175 37.3423 21.4555 34.7183 21.4555C32.0933 21.4555 29.9663 19.3175 29.9663 16.6795C29.9663 14.0415 32.0933 11.9025 34.7183 11.9025C37.3423 11.9025 39.4693 14.0415 39.4693 16.6795Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M36.591 37.1673C36.591 39.0713 35.055 40.6153 33.16 40.6153C31.266 40.6153 29.73 39.0713 29.73 37.1673C29.73 35.2623 31.266 33.7183 33.16 33.7183C35.055 33.7183 36.591 35.2623 36.591 37.1673Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M33.1602 33.9686C31.4072 33.9686 29.9802 35.4036 29.9802 37.1676C29.9802 38.9306 31.4072 40.3656 33.1602 40.3656C34.9142 40.3656 36.3412 38.9306 36.3412 37.1676C36.3412 35.4036 34.9142 33.9686 33.1602 33.9686ZM33.1602 40.8656C31.1312 40.8656 29.4802 39.2066 29.4802 37.1676C29.4802 35.1276 31.1312 33.4686 33.1602 33.4686C35.1892 33.4686 36.8412 35.1276 36.8412 37.1676C36.8412 39.2066 35.1892 40.8656 33.1602 40.8656Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M21.6367 26.1634L21.3657 25.7434L31.0147 19.4894L31.2867 19.9094L21.6367 26.1634Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M29.8546 36.1332L22.2246 32.3372L22.4466 31.8892L30.0776 35.6862L29.8546 36.1332Z"
        fill={ isTheme.styles.colors.textTitle } />
    </Element>
  );
};

UiShareIcon.propTypes = {
  /** Определяет ширину иконки */
  width: PropTypes.string,
  /** Определяет высоту иконки */
  height: PropTypes.string
};

UiShareIcon.defaultProps = {
  width: '3.25rem',
  height: '3.25rem'
};

export default UiShareIcon;