import React, { useState } from 'react';
import { UiArrow, UiCardSection, UiDots, UiSection, UiIcon, useOptions } from '@compassplus/ui-mobicash';
import { DeleteSection } from '../../TemplateStyle';
import { LoadingCardImage, LoadingTitle } from '../../../components/loadingCards/style'
import i18n from '../../../i18n';
import ActionSheet from "../ActionSheet";
import {changeGroupStatus, copyGroup} from "../../../pages/admin/MainPage/RenderCards";
import ModalStore from "../../../stores/ModalStore";
import {useHistory} from "react-router";
import ShareLink from "../../Modals/ShareLink";
import { CategoryCard } from "../../../pages/admin/MainPage/styles";
import { observer } from "mobx-react-lite";
import CategoryStore from "../../../stores/CategoryStore";
import AdminMarketStore from "../../../stores/AdminMarketStore";

const CurrentGroupCard = observer(({ shareLinkOpened, style, currentGroup, isLoading, isPreview }) => {
  const history = useHistory();
  const isOptions = useOptions();
  const currentLanguage = isOptions.language;

  const [isCategoryMenuOpen, setIsCategoryMenuOpen] = useState(false);
  const [isShareLinkOpen, setIsShareLinkOpen] = useState(shareLinkOpened);

  const getDropdownSheet = () => (
    <ActionSheet
      open={isCategoryMenuOpen}
      setOpen={() => { setIsCategoryMenuOpen(false); }}
    >
      <UiSection
        title={i18n.t('LinkToCategory')}
        onClick={() => { setIsShareLinkOpen(true); setIsCategoryMenuOpen(false); }}
        bottomDivider
        view="link"
        rightElement={<UiArrow />}
      />
      {
        !AdminMarketStore.isCMS ? (
          <>
            {isPreview ? (
              <UiSection
                title={i18n.t('Edit')}
                onClick={() => {
                  CategoryStore.clear();
                  history.push(`/modifyGroup/${currentGroup.type}`, { groupId: currentGroup.type });
                  setIsCategoryMenuOpen(false);
                }}
                bottomDivider
                view="link"
                rightElement={<UiArrow />}
              />
            ) : null}

            <UiSection
              title={i18n.t('Copy')}
              onClick={() => { copyGroup(currentGroup, history); setIsCategoryMenuOpen(false); }}
              bottomDivider
              view="link"
              rightElement={<UiArrow />}
            />
            {isPreview ? (
              <UiSection
                title={i18n.t('Active/Inactive')}
                onClick={() => { changeGroupStatus(currentGroup, history); setIsCategoryMenuOpen(false); }}
                bottomDivider
                view="link"
                rightElement={<UiArrow />}
              />
            ) : null}
            <DeleteSection
              title={i18n.t('Delete')}
              onClick={() => { setIsCategoryMenuOpen(false); ModalStore.openDeleteConfirm({ type: 'group', id: currentGroup.type }, history); }}
              rightElement={<UiArrow />}
            />
          </>
        ) : null
      }
    </ActionSheet>
  );

  if (isLoading) {
    return (
      <CategoryCard shadow>
        <UiCardSection
          title={<LoadingTitle />}
          leftElement={<LoadingCardImage />}
          rightElement={<UiDots />}
          status="Active"
          statusText={i18n.t('Active')}
        />
      </CategoryCard>
    );
  }

  const getGroupTitle = () => {
    if (currentGroup) {
      if (isPreview) {
        return currentGroup.title[currentLanguage] || currentGroup.title;
      }
      return currentGroup.title[currentLanguage];
    }
    return `[${i18n.t('CategoryName')}]`;
  }

  const getGroupDescription = () => {
    if (currentGroup) {
      if (isPreview) {
        return currentGroup.description;
      }
      return currentGroup.description[currentLanguage];
    }
    return `[${i18n.t('Description')}]`;
  }

  return (
    <>
      <CategoryCard style={style} shadow>
        <UiCardSection
          title={getGroupTitle()}
          description={getGroupDescription()}
          leftElement={
            currentGroup ? (
              <img alt="..." style={{ width: '4.75rem', height: '3rem', objectFit: 'cover' }} src={currentGroup.image.url} />
            ) : (
              <UiIcon setColor="#D6D6D6" icon="legacy-gallery" />
            )
          }
          rightElement={
            currentGroup ? (
              <UiDots onClick={() => { setIsCategoryMenuOpen(!isCategoryMenuOpen); }} />
            ) : (
              <UiDots disabled />
            )
          }
          active={isCategoryMenuOpen}
          status={currentGroup ? (currentGroup.status ? 'Active' : 'Blocked') : 'Blocked'}
          statusText={currentGroup ? (currentGroup.status ? i18n.t('Active') : i18n.t('Inactive')) : i18n.t('Status')}
        />
        {getDropdownSheet()}
      </CategoryCard>
      {
        currentGroup ? (
          <ShareLink itemId={currentGroup?.type} active={isShareLinkOpen} setActive={setIsShareLinkOpen} state={currentGroup.state} type="group" />
        ) : null
      }
    </>
  );
});

export default CurrentGroupCard;