const Path = ({ fill }) => {

  return (
    <path
      d="M14.3777 41.6546L3.44618 24.5249C2.85127 23.5923 2.85127 22.4077 3.44618 21.4751L14.3777 4.34587C14.9119 3.50839 15.8472 3 16.853 3H39.147C40.1528 3 41.0881 3.50839 41.6223 4.34587L52.5538 21.4751C53.1487 22.4077 53.1487 23.5923 52.5538 24.5249L41.6223 41.6546C41.0881 42.4916 40.1528 43 39.147 43H16.853C15.8472 43 14.9119 42.4916 14.3777 41.6546Z"
      fill={ fill }
    />
  );
};

export default Path;