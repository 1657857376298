import React, { useState } from 'react';
import { UiInputDate, UiIcon } from '@compassplus/ui-mobicash';
import i18n from '../../../../i18n';

const DatePeriod = ({ period, bottomDivider, onDelete }) => {
  const getTextDate = (date) => {
    const parsed = date.split('-');
    return `${parsed[2]}.${parsed[1]}.${parsed[0]}`;
  }

  const [from, setFrom] = useState({ text: getTextDate(period.from), value: period.from });
  const [to, setTo] = useState({ text: getTextDate(period.to), value: period.to })

  return (
    <>
      <UiInputDate
        bottomDivider
        state={from}
        description={i18n.t('From')}
        setState={(value) => {
          setFrom({ text: value.text.split(',')[0], value: value.value });
          period.from = value.value.split(' ')[0];
        }}
        minDate={new Date((new Date()).getTime() - 24 * 60 * 60 * 1000).toISOString().split('T')[0]}
        rightElement={<UiIcon icon="cancel" setColor="#EC1310" onClick={onDelete} />}
      />
      <UiInputDate
        state={to}
        description={i18n.t('To')}
        setState={(value) => {
          setTo({ text: value.text.split(',')[0], value: value.value });
          period.to = value.value.split(' ')[0];
        }}
        minDate={from.value}
        bottomDivider={bottomDivider}
      />
    </>
  )
}

export default DatePeriod;