import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';

import { observer } from 'mobx-react-lite';

import {
  MContentDiv, SubContentDiv,
} from '../../../components/TemplateStyle';
import { getCart } from '../../../Utils/requestUtil';

import { useOptions } from '@compassplus/ui-mobicash';
import ShoppingCart from '../../../stores/ShoppingCartStore';
import Merchant from '../../../stores/Merchant';
import Checkout from './Checkout';
import CheckoutShoppingCard from './CheckoutShoppingCart';
import GlobalStateStore from "../../../stores/GlobalStateStore";
import {action} from "mobx";

const CheckoutPage = observer(() => {
  const history = useHistory();
  const isOptions = useOptions();
  const params = useParams();

  useEffect(action(() => {
    if (ShoppingCart.cart.length === 0 && Merchant.guid && (!getCart(Merchant.guid) || JSON.parse(getCart(Merchant.guid))?.length === 0)) {
      if (Merchant.marketLink) {
        history.push(`/${Merchant.marketLink}`);
      } else {
        history.push(`/${params.merchant}`);
      }
    }
    GlobalStateStore.sidebarClassName = '';
  }), [Merchant.marketLink, ShoppingCart.cart]);

  const renderContent = () => {
    if (isOptions.screen === 'mobile') {
      return (
        <MContentDiv>
          <div style={{ padding: '0 0.5rem', width: '100%' }}>
            {
              Merchant.guid !== '' && getCart(Merchant.guid) !== undefined && (
                <CheckoutShoppingCard />
              )
            }
            <Checkout />
          </div>
        </MContentDiv>
      );
    }

    return (
      <>
        <MContentDiv style={{ position: 'relative' }}>
          {
            Merchant.guid !== '' && getCart(Merchant.guid) !== undefined && (
              <CheckoutShoppingCard />
            )
          }
        </MContentDiv>
        <SubContentDiv>
          <div style={{ padding: '0 0 0 0.5rem', position: 'relative', width: '100%' }}>
            <Checkout />
          </div>
        </SubContentDiv>
      </>
    );
  };

  return (
    <>
      {renderContent()}
    </>
  );
});

export default CheckoutPage;
