import React from 'react';
import { useOptions } from '@compassplus/ui-mobicash';
import { observer } from 'mobx-react-lite';
import i18n from '../../../../i18n';
import Images from '../../../../components/market/OrderImages';
import ItemStore from '../../../../stores/ItemStore';
import { LoadingTitle } from '../../../../components/loadingCards/style';
import LoadingImagesCarousel from '../../../../components/loadingCards/LoadingImagesCarousel';
import { TextWithMargin } from '../style';

const ProductCard = observer(() => {
  const {
    title, currency, price, description, images, isLoading,
  } = ItemStore;

  const isOptions = useOptions();
  const isActive = ItemStore.checkIsActive();

  if (isLoading) {
    return (
      <>
        <LoadingTitle margin="0.25rem 0" />
        <LoadingTitle margin="0.25rem 0" />
        <LoadingImagesCarousel />
      </>
    );
  }
  return (
    <>
      <TextWithMargin
        view={isActive ? 'readonly' : 'disable'}
        typography="subsection-title"
      >
        {title[isOptions.language]}
      </TextWithMargin>
      <TextWithMargin
        view={isActive ? 'readonly' : 'disable'}
        typographt="top-navbar-title"
      >
        {isActive ? `${Number(price).toFixed(2)} ${i18n.t(currency)}` : i18n.t('OUTOFSTOCK')}
      </TextWithMargin>
      <Images images={images} isActive={isActive} />
      <TextWithMargin
        typography="note"
        view={isActive ? 'readonly' : 'disable'}
        style={description[isOptions.language] && (isOptions.screen !== 'mobile' || ItemStore.attributes.length === 0) ? { padding: '1rem 0 4rem' } : {}}
      >
        <div dangerouslySetInnerHTML={{ __html: description[isOptions.language] }} />
      </TextWithMargin>
    </>
  );
});

export default ProductCard;
