import React, {useState} from 'react'
import { UiCard, UiSection, UiRadioButton, useOptions } from "@compassplus/ui-mobicash";
import {Text} from "../../../TemplateStyle";
import AttributesStore from "../../../../stores/AttributesStore";
import {action} from "mobx";

const Radio = ({ onSelectClick, isActive }) => {
  const isOptions = useOptions();
  const attributes = AttributesStore.getAttributesByType('radio');
  const [prevSelected] = useState(new Array(attributes.length));

  const getRadioAttribute = (radioAttribute, index) => (
    (radioAttribute !== undefined) && (
      <div key={radioAttribute.title.en}>
        <Text
          style={{ margin: '0.5rem 0' }}
          typography="subsection-title"
          view="readonly"
        >
          {radioAttribute.title[isOptions.language]}
        </Text>
        <Text
          style={{ margin: '0.5rem 0' }}
          typography="note"
          view="readonly"
        >
          {radioAttribute.description[isOptions.language]}
        </Text>
        <UiCard shadow>
          {getRadioOptions(radioAttribute, index)}
        </UiCard>
      </div>
    )
  );

  const onClick = action((radio, index, radioAttribute, attrIndex) => {
    const selectedIndex = AttributesStore.selectedAttributesNamesArr.indexOf(prevSelected[attrIndex]);
    if (selectedIndex === -1) { prevSelected[attrIndex] = null; }
    if (radio.active) {
      radioAttribute.changeValue(index, !radio.value);
      if (prevSelected[attrIndex]) {
        AttributesStore.selectedAttributesNamesArr.splice(selectedIndex, 1);
        prevSelected[attrIndex] = null;
      }
      if (radio.value) {
        prevSelected[attrIndex] = radio.title.en;
        AttributesStore.selectedAttributesNamesArr.push(radio.title.en);
        onSelectClick(radioAttribute);
      } else {
        AttributesStore.setDefaultActive();
        prevSelected[attrIndex] = null;
        onSelectClick();
      }
    } else {
      if (radio.disabled) {
        if (prevSelected[attrIndex]) {
          radioAttribute.changeValue(index, false);
          AttributesStore.selectedAttributesNamesArr.splice(selectedIndex, 1);
          onSelectClick();
          prevSelected[attrIndex] = null;
        }
      } else {
        AttributesStore.setAllRadioFalse();
        radioAttribute.changeValue(index, !radio.value);

        for (let i = 0; i < prevSelected.length; i++) {
          if (i === attrIndex) {
            prevSelected[attrIndex] = radio.title.en;
          } else {
            prevSelected[i] = null;
          }
        }

        AttributesStore.selectedAttributesNamesArr = [];
        AttributesStore.selectedAttributesNamesArr.push(radio.title.en);
        AttributesStore.setDefaultActive();
        onSelectClick(radioAttribute);
      }
    }
  })

  const getRadioOptions = (radioAttribute, attrIndex) => (
    (radioAttribute.complexValue !== undefined) && (
      radioAttribute.complexValue?.map((radio, index) => (
        <UiSection
          key={radio.title.en}
          style={radio.disabled ? { opacity: 0.8 } : {}}
          className="answer-form"
          title={radio.title[isOptions.language]}
          disabled={!isActive}
          bottomDivider={index !== radioAttribute.complexValue.length - 1}
          onClick={() => onClick(radio, index, radioAttribute, attrIndex)}
          leftElement={(
            <UiRadioButton
              disabled={!radio.active || radio.disabled || !isActive}
              checked={radio.value}
            />
          )}
        />
      ))
    )
  );

  return (
    <>
      {attributes.map(getRadioAttribute)}
    </>
  )
};

export default Radio;
