import React from 'react'
import { UiCard, useOptions } from "@compassplus/ui-mobicash";
import i18n from "../../../i18n";
import ShoppingCart from "../../../stores/ShoppingCartStore";
import { getCurrency } from "../../../Utils/requestUtil";
import { CheckoutCartSection } from "../ShoppingCartPage/style";
import { observer } from "mobx-react-lite";
import { Text } from "../../../components/TemplateStyle";
import LoadingShoppingCart from "../../../components/loadingCards/LoadingShoppingCart";

const CheckoutShoppingCard = observer(() => {
  const isOptions = useOptions();
  if (ShoppingCart.isLoading) {
    return <LoadingShoppingCart />
  }
  return (
    <>
      <Text view="readonly" typography="subsection-title">{i18n.t('Cart')}</Text>
      <Text view="primary" typography="note">
        {`${i18n.t('Cart')} (${ShoppingCart.totalCount} / ${ShoppingCart.totalCost} ${i18n.t(getCurrency())})`}
      </Text>
      <UiCard style={{ margin: '0.5rem 0 0', boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.13)' }}>
        {
          ShoppingCart.cart?.map((item, index) => (
            <React.Fragment key={`${item.title.en}-${index}`}>
              <CheckoutCartSection
                type="marketplace-card"
                title={item.title[isOptions.language]}
                description={ShoppingCart.getOrderDescription(item.attributes, isOptions.language).split('\n').join(' ')}
                order={`${item.amount} x ${Number(item.price).toFixed(2)} ${i18n.t(getCurrency())}`}
                bottomDivider={index !== ShoppingCart.cart.length - 1}
              />
            </React.Fragment>
          ))
        }
      </UiCard>
    </>
  )
});

export default CheckoutShoppingCard;