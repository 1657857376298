import { fillLanguage } from '../Utils/fillLanguage';
import ItemStore from "../stores/ItemStore";

const ATTRIBUTE_TYPE_CHECKBOX = 'checkbox';
const ATTRIBUTE_TYPE_RADIO = 'radio';
const ATTRIBUTE_TYPE_TEXTFIELD = 'textField';
const ATTRIBUTE_TYPE_COLOR = 'color';
const ATTRIBUTE_TYPE_LIST = 'list';

class Attribute {
  constructor({ title, description, type, complexValue, pointName, maxPointsNumber, isSeparateCount, priceChange, priceChangeForm, percent }) {
    this.setTypeAndValueByType(type || ATTRIBUTE_TYPE_RADIO);
    if (title) this.title = title;
    if (description) this.description = description;
    if (type) this.type = type;
    if (complexValue) this.complexValue = complexValue.map((cValue) => {
      switch (type) {
        case ATTRIBUTE_TYPE_CHECKBOX:
        case ATTRIBUTE_TYPE_COLOR:
        case ATTRIBUTE_TYPE_RADIO:
          cValue.value = false;
          cValue.isUniqueTitle = true;
        default:
          break;
      }
      return cValue;
    });
    if (pointName) this.pointName = pointName;
    if (maxPointsNumber) this.maxPointsNumber = maxPointsNumber;
    if (isSeparateCount) this.isSeparateCount = isSeparateCount || false;
    if (priceChange) this.priceChange = priceChange;
    if (percent) this.percent = percent;
    if (priceChangeForm) this.priceChangeForm = priceChangeForm || 'value';

    switch (this.type) {
      case ATTRIBUTE_TYPE_TEXTFIELD:
        this.value = '';
        break;
      case ATTRIBUTE_TYPE_LIST:
        this.value = [];
        break;
      default:
        break;
    }
  }

  changeValue(index, newValue) {
    switch (this.type) {
      case ATTRIBUTE_TYPE_RADIO:
      case ATTRIBUTE_TYPE_COLOR:
        this.changeRadioValue(index, newValue);
        break;
      case ATTRIBUTE_TYPE_CHECKBOX:
        if (newValue) {
          ItemStore.price = (Number(ItemStore.price) + Number(this.complexValue[index].priceChange)).toFixed(2);
        } else {
          if (this.complexValue[index].value) {
            ItemStore.price = (Number(ItemStore.price) - Number(this.complexValue[index].priceChange)).toFixed(2);
          }
        }
        this.complexValue[index].value = newValue;
        break;
      case ATTRIBUTE_TYPE_TEXTFIELD:
        this.value = newValue;
      default:
        break;
    }
  }

  changeRadioValue(index, newValue) {
    if (this.type === ATTRIBUTE_TYPE_RADIO || this.type === ATTRIBUTE_TYPE_COLOR) {
      for (let i = 0; i < this.complexValue.length; i++) {
        if (this.complexValue[i].value) {
          ItemStore.price = (Number(ItemStore.price) - Number(this.complexValue[i].priceChange)).toFixed(2);
        }
        if (i !== index) {
          this.complexValue[i].value = false;
        } else {
          this.complexValue[i].value = newValue;
          if (newValue) {
            if (this.complexValue[index].priceChangeForm === 'value') {
              ItemStore.price = (Number(ItemStore.price) + Number(this.complexValue[i].priceChange)).toFixed(2);
            }
            if (this.complexValue[index].priceChangeForm === 'percent') {
              ItemStore.price = (Number(ItemStore.price) + Number(this.complexValue[i].priceChange)).toFixed(2);
            }
          }
        }
      }
    }
  };

  setIsSeparateCount(value) {
    this.isSeparateCount = value;
  }

  setTitle(title, lang) {
    this.title[lang] = title;
  }

  setDescription(description, lang) {
    this.description[lang] = description;
  }

  setComplexValue(complexValue) {
    this.complexValue = complexValue;
  }

  setComplexValueForIndex(index, title, lang) {
    if (lang !== undefined) {
      this.complexValue[index].title[lang] = title;
    } else {
      this.complexValue[index].title = title;
    }
  }

  setTypeAndValueByType(type) {
    switch (type) {
      case ATTRIBUTE_TYPE_CHECKBOX: case ATTRIBUTE_TYPE_RADIO:
        this.title = { en: '', ru: '' };
        this.description = { en: '', ru: '' };
        this.complexValue = [
          {
            title: { en: '', ru: '' },
            priceChange: 0,
            priceChangeForm: 'value',
            isUniqueTitle: true,
          },
        ];
        break;
      case ATTRIBUTE_TYPE_LIST:
        this.title = { en: '', ru: '' };
        this.description = { en: '', ru: '' };
        this.complexValue = null;
        this.pointName = { en: '', ru: '' };
        this.priceChange = 0;
        this.priceChangeForm = 'value';
        this.maxPointsNumber = 10;
        break;
      case ATTRIBUTE_TYPE_COLOR:
        this.title = { en: 'Color', ru: 'Цвет' };
        this.description = { en: '', ru: '' };
        this.complexValue = [
          {
            code: '#FFFFFF',
            title: { ru: 'Белый', en: 'White' },
            priceChange: 0,
            priceChangeForm: 'value',
            isUniqueTitle: true,
          },
          {
            code: '#000000',
            title: { ru: 'Черный', en: 'Black' },
            priceChange: 0,
            priceChangeForm: 'value',
            isUniqueTitle: true,
          },
          {
            code: '#E21A0B',
            title: { ru: 'Красный', en: 'Red' },
            priceChange: 0,
            priceChangeForm: 'value',
            isUniqueTitle: true,
          },
          {
            code: '#FF9900',
            title: { ru: 'Оранжевый', en: 'Orange' },
            priceChange: 0,
            priceChangeForm: 'value',
            isUniqueTitle: true,
          },
          {
            code: '#FFFE1D',
            title: { ru: 'Желтый', en: 'Yellow' },
            priceChange: 0,
            priceChangeForm: 'value',
            isUniqueTitle: true,
          },
          {
            code: '#4FAD5A',
            title: { ru: 'Зеленый', en: 'Green' },
            priceChange: 0,
            priceChangeForm: 'value',
            isUniqueTitle: true,
          },
          {
            code: '#0C68CD',
            title: { ru: 'Синий', en: 'Blue' },
            priceChange: 0,
            priceChangeForm: 'value',
            isUniqueTitle: true,
          },
        ];
        break;
      case ATTRIBUTE_TYPE_TEXTFIELD:
        this.title = { en: '', ru: '' };
        this.description = { en: '', ru: '' };
        this.complexValue = null;
        break;
      default:
        this.title = { en: '', ru: '' };
        this.description = { en: '', ru: '' };
        this.complexValue = null;
    }

    this.type = type;
  }

  addComplexValue(type) {
    switch (type) {
      case 'radio':
      case 'checkbox':
        if (!this.complexValue) this.complexValue = [];
        this.complexValue.push({
          title: { en: '', ru: '' },
          priceChange: 0,
          priceChangeForm: 'value',
          isUniqueTitle: true,
        });
        break;
      default:
        break;
    }
  }

  setListPointName(name) {
    if (this.type === ATTRIBUTE_TYPE_LIST) {
      this.pointName = name;
    }
  }

  setListMaxPointsNumber(number) {
    if (this.type === ATTRIBUTE_TYPE_LIST) {
      this.maxPointsNumber = number;
    }
  }

  deleteComplexValue(index) {
    this.complexValue.splice(index, 1);
  }

  checkIsFilled() {
    if (this.title.ru === '' && this.title.en === '') {
      return false;
    }

    if (this.type === 'list') {
      if (!this.pointName
        || (this.pointName.ru === '' && this.pointName.en === '')
        || !this.maxPointsNumber || this.maxPointsNumber === '') {
        return false;
      }
    }

    if (this.complexValue && this.complexValue.length === 0) {
      return false;
    }

    if (this.complexValue && this.complexValue.length !== 0) {
      for (let i = 0; i < this.complexValue.length; i++) {
        if (this.complexValue[i].title.ru === '' && this.complexValue[i].title.en === '') {
          return false;
        }
      }
    }
    return true;
  }

  fillUndefinedFields() {
    this.title = fillLanguage(this.title);
    this.description = fillLanguage(this.description);
    if (this.complexValue && this.complexValue.length !== 0) {
      for (let i = 0; i < this.complexValue.length; i++) {
        this.complexValue[i].title = fillLanguage(this.complexValue[i].title);
      }
    }
  }
}

export default Attribute;
