import styled from 'styled-components';

import {AnimationCardSection, MainText} from '../../../components/TemplateStyle';

import { UiCard, UiSubsection, UiActionSheet } from '@compassplus/ui-mobicash';

export const SectionCard = styled(UiCard)`
  margin: 0 0 0.625rem 0;
  cursor: pointer;
  width: 15.5rem;
  height: 3rem;
  
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

export const StyledSearchInput = styled.input`
  border: 1px solid #A3AEBF;
  border-radius: 0.25rem;
  margin: 0.25rem 0;
  width: 100%;
  height: 1.625rem;
  padding: 0.25rem 0.25rem 0.25rem 1.5rem;
  font-family: 'Roboto';
  font-size: 0.75rem;
  
  background: url('/images/Search.svg') no-repeat;
  background-color: #fff;
  background-position: 0.25rem 50%;
  background-left: 5px;
  
  :focus {
    border: 1px solid #A3AEBF;
    outline: none;
  }
`;

export const AddButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.25rem 0 0;
  width: 100%; 
  @media (max-width: 1200px) {
    display: block;
  }
  
  span {
    font-size: 0.875rem;
  }
`;

export const EmptyText = styled(MainText)`
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  line-height: 1.5rem;
  height: 347px;
`;

export const Subsection = styled(UiSubsection)`
  position: relative;
  margin: 1rem 0 0;
  & > div {
    margin: 0 0 0.5rem 0;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
`;

export const CategoryCard = styled(UiCard)`
  margin: 0 0 1rem 0;
  position: relative;
`;

export const ActionSheet = styled(UiActionSheet)`
  & > div {
    max-width: 50%;
  }
  & > button {
    max-width: 50%;
  }
  
  @media(max-width: 663px) {
     & > div {
    max-width: 100%;
  }
  & > button {
    max-width: 100%;
  }
  }
`;

export const ItemCardSection = styled(AnimationCardSection)`
  & > div:first-child {
    border: none;
    max-width: 3rem;
    min-width: 3rem;
  }
`;