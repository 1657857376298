import React, {useReducer} from 'react';
import {observer} from "mobx-react-lite";
import {UiCard, UiArrow, UiSection, UiSwitch, UiInput} from "@compassplus/ui-mobicash";
import i18n from "../../../../i18n";
import TimeInput from "../../../../components/admin/TimeInput/TimeInput";

const Time = observer(({ attribute }) => {
  const [, forceUpdate] = useReducer((x) => x+1,0);
  return (
    <>
      <UiCard shadow style={{ margin: '0.25rem 0' }}>
        <UiSection
          title={i18n.t('Time')}
          rightElement={<UiSwitch
            checked={attribute.status}
            onClick={() => { attribute.status = !attribute.status; forceUpdate(); }}
          />}
          description={attribute.status ? i18n.t('SelectDesiredTime') : null}
        />
      </UiCard>
      {
        attribute.status ? (
          <>
            {
              attribute.periods.map((period, index) => (
                <UiCard
                  key={`${period.from}-${index}`}
                  style={{ margin: '0.25rem 0' }}
                  shadow
                >
                  <TimeInput
                    value={period.from}
                    description={i18n.t('From')}
                    setValue={(value) => {
                      period.from = value;
                      forceUpdate();
                    }}
                    onDelete={() => {
                      if (attribute.length > 1) {
                        attribute.periods.splice(index, 1);
                      }
                    }}
                    bottomDivider
                  />
                  <TimeInput
                    value={period.to}
                    setValue={(value) => {
                      period.to = value;
                      forceUpdate();
                    }}
                    description={i18n.t('To')}
                  />
                </UiCard>
              ))
            }
            <UiCard style={{ margin: '0.25rem 0' }} shadow>
              <UiSection
                title={i18n.t('AddPeriod')}
                rightElement={<UiArrow />}
                onClick={(event) => {
                  attribute.periods.push({
                    from: '00:00',
                    to: '12:00'
                  });
                  forceUpdate();
                  event.stopPropagation();
                }}
              />
            </UiCard>
          </>
        ) : null
      }
    </>
  );
});

export default Time;