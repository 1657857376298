import React, { useState } from 'react';
import i18n from '../../../i18n';
import { SearchContainer, StyledMarketSearchInput } from './style';
import SearchIcon from '../../icons/SearchIcon';
import MarketStore from '../../../stores/MarketStore';
import { action } from 'mobx';

const MarketSearch = ({ style }) => {
  const [searchString, setSearchString] = useState('');

  const onSearchClick = action(() => {
    if (searchString !== '') {
      let searchArr = searchString.split(' ');
      searchArr = searchArr.filter((el) => el.length > 1);

      MarketStore.currentItems = MarketStore.allItems.filter((el) => {
        if (el.title.ru && el.title.en) {
          return searchArr.every((search) =>
            el.title.ru.toLowerCase().includes(search.toLowerCase())
            || el.title.en.toLowerCase().includes(search.toLowerCase())
          )
        }

        return searchArr.every((search) => el.title.toLowerCase().includes(search.toLowerCase()));
      });

      MarketStore.currentGroups = MarketStore.allGroups.filter((el) => {
        if (el.title.ru && el.title.en) {
          return searchArr.every((search) =>
            el.title.ru.toLowerCase().includes(search.toLowerCase())
            || el.title.en.toLowerCase().includes(search.toLowerCase())
          )
        }

        return searchArr.every((search) => el.title.toLowerCase().includes(search.toLowerCase()));
      });
    }
  });

  return (
    <SearchContainer style={style}>
      <StyledMarketSearchInput
        placeholder={i18n.t('Search')}
        onChange={action((event) => {
          setSearchString(event.target.value);
          if (event.target.value === '') {
            MarketStore.currentGroups = MarketStore.allGroups;
            MarketStore.currentItems = MarketStore.allItems;
          }
        })}
        onKeyDown={(event) => {
          if (event.keyCode === 13) {
            onSearchClick();
          }
        }}
      />
      <SearchIcon
        style={{ margin: '0 0.5rem' }}
        color={searchString !== '' ? '#037AFF' : '#D3D3D3'}
        onClick={onSearchClick}
      />
    </SearchContainer>
  );
};

export default MarketSearch;
