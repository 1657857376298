import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Иконка социальной сети "Facebook"
 * @public
 * @version 1.5.0
 * @param {String} [width="3.25rem"]
 * @param {String} [height="3.25rem"]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * import { UiFacebookIcon, UiPlates, UiPlate } from '@compassplus/ui-mobicash';
 *
 * const App = () => {
 *
 *   return (
 *     <>
 *       <UiFacebookIcon />
 *       <UiPlates>
 *         <UiPlate view="2x1" image={ <UiFacebookIcon width="100%" height="100%" /> } />
 *       </UiPlates>
 *     </>
 *   );
 * }; */
const UiFacebookIcon = ({
  width,
  height,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      innerRef={ innerRef }
      width={ width }
      height={ height }
      viewBox="0 0 52 52"
    >
      <path fillRule="evenodd" clipRule="evenodd"
        d="M43.587 28.1835C43.587 28.1595 43.583 28.1365 43.583 28.1125C43.583 28.0845 43.587 28.0585 43.587 28.0305C43.587 19.7405 35.621 13.0205 25.794 13.0205C15.966 13.0205 8 19.7405 8 28.0305C8 28.0625 8.003 28.0935 8.004 28.1255C8.004 28.1455 8 28.1635 8 28.1835C8 28.2625 8.012 28.3385 8.014 28.4165C8.022 28.6875 8.037 28.9565 8.062 29.2245C8.542 35.1125 13.035 40.0585 19.262 42.1355V45.0965C19.262 45.3515 19.485 45.5735 19.786 45.6055C20.543 45.6865 21.314 45.7285 22.097 45.7285C22.88 45.7285 23.651 45.6865 24.407 45.6055C24.709 45.5735 24.932 45.3515 24.932 45.0965V43.1565C25.219 43.1675 25.503 43.1925 25.794 43.1925C35.144 43.1925 42.795 37.1045 43.516 29.3735C43.524 29.2975 43.523 29.2205 43.529 29.1445C43.553 28.8255 43.587 28.5085 43.587 28.1835Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M8.75342 24.5034C8.75342 33.7984 16.2884 41.3344 25.5844 41.3344C34.8794 41.3344 42.4144 33.7984 42.4144 24.5034C42.4144 15.2084 34.8794 7.67239 25.5844 7.67239C16.2884 7.67239 8.75342 15.2084 8.75342 24.5034Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M29.3169 41.246C29.0879 41.246 28.8819 41.089 28.8299 40.856C28.7689 40.587 28.9379 40.319 29.2069 40.258C36.6889 38.564 41.9149 32.014 41.9149 24.331C41.9149 15.326 34.5889 8 25.5839 8C16.5789 8 9.25391 15.326 9.25391 24.331C9.25391 29.542 11.7739 34.485 15.9959 37.552C16.2189 37.714 16.2689 38.027 16.1059 38.25C15.9439 38.474 15.6309 38.522 15.4079 38.361C10.9279 35.106 8.25391 29.862 8.25391 24.331C8.25391 14.775 16.0279 7 25.5839 7C35.1399 7 42.9149 14.775 42.9149 24.331C42.9149 32.485 37.3689 39.435 29.4279 41.234C29.3909 41.242 29.3539 41.246 29.3169 41.246Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M28.8423 31.4882L29.4263 27.6812C29.4803 27.3312 29.2103 27.0172 28.8563 27.0172H25.3453C25.0273 27.0172 24.7693 26.7592 24.7693 26.4402V23.7982C24.7693 22.4412 25.4333 21.1182 27.5653 21.1182H29.1533C29.4713 21.1182 29.7293 20.8602 29.7293 20.5412V17.3902C29.7293 17.1022 29.5183 16.8602 29.2333 16.8192C28.5313 16.7202 27.1893 16.5592 25.8883 16.5592C21.9683 16.5592 19.4063 18.9352 19.4063 23.2362V26.4402C19.4063 26.7592 19.1483 27.0172 18.8293 27.0172H15.6253C15.3073 27.0172 15.0493 27.2752 15.0493 27.5932V31.4012C15.0493 31.7192 15.3073 31.9772 15.6253 31.9772H18.8293C19.1483 31.9772 19.4063 32.2352 19.4063 32.5542V43.4682C19.4063 43.7552 19.6173 44.0032 19.9023 44.0392C20.6173 44.1302 21.3473 44.1772 22.0873 44.1772C22.8283 44.1772 23.5573 44.1302 24.2733 44.0392C24.5583 44.0032 24.7693 43.7552 24.7693 43.4682V32.5542C24.7693 32.2352 25.0273 31.9772 25.3453 31.9772H28.2723C28.5573 31.9772 28.7993 31.7692 28.8423 31.4882Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
    </Element>
  );
};

UiFacebookIcon.propTypes = {
  /** Определяет ширину иконки */
  width: PropTypes.string,
  /** Определяет высоту иконки */
  height: PropTypes.string
};

UiFacebookIcon.defaultProps = {
  width: '3.25rem',
  height: '3.25rem'
};

export default UiFacebookIcon;