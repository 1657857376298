import {makeAutoObservable, runInAction} from 'mobx';
import { getAuth, getMerchantId, request } from '../Utils/requestUtil';

class AdminMarketStore {
  constructor() {
    this.merchantGuid = '';
    this.isLoading = false;
    this.isCategory = false;
    this.rootGroup = {};
    this.allGroups = [];
    this.currentGroups = [];
    this.currentGroup = {};
    this.rootGroup = {};
    this.allItems = [];
    this.goodsWithoutCategory = [];
    this.currentItems = [];
    this.displayedItemsCount = 40;

    this.isCMS = false;
    this.cmsTitle = undefined;

    makeAutoObservable(this);
  }

  getMarketData = (merchantGuid, groupId, language) => {
    this.isLoading = true;
    this.merchantGuid = merchantGuid;
    fetch(`/api/v1/public/market/${merchantGuid}`, {
      method: 'GET',
      headers: {
        language,
        returnInactive: true
      },
    }).then((response) => response.json())
      .then((response) => {
        if (response) {
          runInAction(() => {
            this.isLoading = false;
            this.allGroups = response.goods;
            this.goodsWithoutCategory = response.goodsWithoutCategory;
            this.isCategory = false;
            this.allItems = response.goodsWithoutCategory;
            this.allGroups = response.goods;
            this.rootGroup = response.rootGroup;
            this.prevFetchMerchantGuid = merchantGuid;
            this.setCurrentGroup(groupId);
          })
        }
      });
  }

  checkGroups(groups, groupId) {
    for (const group of groups) {
      if (group.type === groupId) {
        return group;
      }
    }
    for (const group of groups) {
      if (group.subGroups) {
        const subgroupId = this.checkGroups(group.subGroups, groupId);
        if (subgroupId) {
          return subgroupId;
        }
      }
    }
    return null;
  }

  setCurrentGroup = (groupId) => {
    if ((this.allGroups && this.allGroups.length !== 0) || (this.allItems && this.allItems.length !== 0)) {
      this.isLoading = true;
      this.currentGroups = [];
      this.currentGroup = [];
      this.currentItems = [];
      if (groupId && groupId !== this.rootGroup?._id) {
        this.currentGroup = this.checkGroups(this.allGroups, groupId);
        if (this.currentGroup) {
          this.isCategory = true;
          this.currentGroups = this.currentGroup.subGroups || [];
          this.currentItems = this.currentGroup.items || [];
          this.isLoading = false;
        }
        this.isLoading = false;
      } else {
        this.currentGroup = this.rootGroup;
        this.currentGroups = this.allGroups;
        this.currentItems = this.goodsWithoutCategory;
        this.isLoading = false;
      }
    } else {
      this.currentGroup = this.rootGroup;
      this.allGroups = [];
      this.currentGroups = [];
      this.rootGroup = {};
      this.allItems = [];
      this.goodsWithoutCategory = [];
      this.currentItems = [];
    }
  }

  saveCMS = (cmsTitle, cmsURL, accessToken) => {
    this.isLoading = true;
    request('/api/v1/settings/setCMS', 'POST', {
      merchantGuid: getMerchantId(),
      cms: cmsTitle,
      accessToken,
      cmsURL,
    })
      .then((res) => {
        if (cmsTitle && cmsTitle !== '') {
          this.isCMS = true;
        } else {
          this.isCMS = false;
        }
        this.cmsTitle = cmsTitle;
        this.isLoading = false;
      });
  }

  removeCMS = () => {
    this.saveCMS('', '', '');
    this.isCMS = false;
  }

  setCMS = (cmsTitle) => {
    this.cmsTitle = cmsTitle;
    if (cmsTitle && cmsTitle !== '') {
      this.isCMS = true;
    } else {
      this.isCMS = false;
    }
  }
}

export default new AdminMarketStore();
