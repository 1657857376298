import lightTheme from '../light';

const shadows = {
  ...lightTheme.styles.shadows,
  mainShadow: '0 0.063rem 0.063rem #000000',
  stiky: '0 0 0.25rem rgba(0, 0, 0, 0.12), 0 0.063rem 0.188rem rgba(0, 0, 0, 0.1)',
  infoPanel: 'drop-shadow(0 0 0.25rem rgba(0, 0, 0, 0.2)) drop-shadow(0 0 0.125rem rgba(0, 0, 0, 0.4))',
  divider: 'radial-gradient(50% 50% at 50% 50%, #3C3C3C 0%, rgba(91, 91, 91, 0) 100%)',
  keyboard: '0 -0.125rem 0.188rem rgba(0, 0, 0, 0.7)',
  action: 'inset 0px -1px 0px #000000',
  memPad: 'drop-shadow(inset 0 -0.063rem 0 #000000)',
  fav: '0 0.063rem 0.063rem rgba(0, 0, 0, 0.7)'
};

export default shadows;