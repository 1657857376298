import { useRef } from 'react';
import cn from 'classnames';
import styled, { css } from 'styled-components';
import { Transition } from 'react-transition-group';

import { useTheme } from '../../../../../../context/ThemeProvider';

import styles from './Cell.module.css';

const StyledCell = styled.td`
  ${( props) => props.styles.typography.SectionValue }
  
  ${ (props) => {
    if (props.disabled) {
      return css`
        color: ${ props.styles.colors.textDisable };
      `;
    } else if (props.free) {
      return css`
        color: ${ props.styles.colors.warningColor };
      `;
    } else {
      return css`
        color: ${ props.styles.colors.textTitle };
      `;
    }
  } }
  
  ${ (props) => props.active && css`
    background: ${ props.styles.colors.applicationBackground };
  ` }

  ${ (props) => props.onClick && css`
    cursor: pointer;
  ` }
`;

const Cell = ({
  className,
  active,
  disabled,
  free,
  children,
  ...props
}) => {

  const isTheme = useTheme();

  const containerRef = useRef();

  return (
    <Transition
      in={ active }
      timeout={ active ? 100 : 0 }
      nodeRef={ containerRef }
    >
      { (state) => {
        return (
          <StyledCell
            { ...props }
            className={ cn(className, styles[state]) }
            active={ active }
            disabled={ disabled }
            free={ free }
            ref={ containerRef }
            styles={ isTheme.styles }
          >
            { children }
          </StyledCell>
        );
      } }
    </Transition>
  );
};

export default Cell;