import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Иконка запроса платежа
 * @public
 * @version 1.5.0
 * @param {String} [width="3.25rem"]
 * @param {String} [height="3.25rem"]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * import { UiRequestPaymentIcon, UiPlates, UiPlate } from '@compassplus/ui-mobicash';
 *
 * const App = () => {
 *
 *   return (
 *     <>
 *       <UiRequestPaymentIcon />
 *       <UiPlates>
 *         <UiPlate view="2x1" image={ <UiRequestPaymentIcon width="100%" height="100%" /> } />
 *       </UiPlates>
 *     </>
 *   );
 * }; */
const UiRequestPaymentIcon = ({
  width,
  height,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      innerRef={ innerRef }
      width={ width }
      height={ height }
      viewBox="0 0 52 52"
    >
      <path fillRule="evenodd" clipRule="evenodd"
        d="M41.5968 30.6242L42.2428 29.7412C42.2428 29.7402 42.2438 29.7402 42.2438 29.7402C42.4138 29.5052 42.4218 29.2002 42.2618 28.9592L41.6598 28.0572L42.2608 27.1662C42.4208 26.9262 42.4138 26.6192 42.2448 26.3872L41.5948 25.4882L42.2418 24.6032C42.4168 24.3622 42.4188 24.0502 42.2478 23.8082L41.5958 22.8842L42.2308 22.0452C42.4158 21.7992 42.4188 21.4792 42.2378 21.2292L41.4908 20.2072C41.3408 20.0022 41.0878 19.8802 40.8158 19.8802H22.4918C22.1908 19.8802 21.9478 20.0912 21.9478 20.3522V33.1922C21.9478 33.4532 22.1908 33.6642 22.4918 33.6642H40.8158C41.0878 33.6642 41.3408 33.5412 41.4918 33.3362L42.2388 32.3112C42.4178 32.0662 42.4178 31.7502 42.2388 31.5062L41.5968 30.6242Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M10 30.6687V41.7587C10 42.7207 10.777 43.5007 11.734 43.5007H30.47C31.426 43.5007 32.208 42.7157 32.208 41.7557V30.6967C32.208 30.4017 31.969 30.1617 31.674 30.1617H10.505C10.226 30.1617 10 30.3887 10 30.6687Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M31.0493 34.0154V39.8694C31.0493 40.4764 30.5593 40.9684 29.9553 40.9684H12.1553C11.5513 40.9684 11.0613 40.4764 11.0613 39.8694V9.5984C11.0613 8.9924 11.5513 8.5004 12.1553 8.5004H29.9553C30.5593 8.5004 31.0493 8.9924 31.0493 9.5984V13.5424"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M29.955 41.468H12.156C11.277 41.468 10.561 40.751 10.561 39.87V9.599C10.561 8.718 11.277 8 12.156 8H29.955C30.834 8 31.549 8.718 31.549 9.599V13.542C31.549 13.819 31.326 14.042 31.049 14.042C30.773 14.042 30.549 13.819 30.549 13.542V9.599C30.549 9.269 30.283 9 29.955 9H12.156C11.828 9 11.561 9.269 11.561 9.599V39.87C11.561 40.199 11.828 40.468 12.156 40.468H29.955C30.283 40.468 30.549 40.199 30.549 39.87V34.015C30.549 33.739 30.773 33.515 31.049 33.515C31.326 33.515 31.549 33.739 31.549 34.015V39.87C31.549 40.751 30.834 41.468 29.955 41.468Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M28.7216 33.7834V35.5544C28.7216 36.1094 28.2736 36.5594 27.7216 36.5594H14.4856C13.9336 36.5594 13.4856 36.1094 13.4856 35.5544V11.8804C13.4856 11.3254 13.9336 10.8754 14.4856 10.8754H27.7216C28.2736 10.8754 28.7216 11.3254 28.7216 11.8804V13.7064"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M27.7216 36.8093H14.4856C13.7966 36.8093 13.2356 36.2473 13.2356 35.5553V11.8803C13.2356 11.1883 13.7966 10.6253 14.4856 10.6253H27.7216C28.4106 10.6253 28.9716 11.1883 28.9716 11.8803V13.7063C28.9716 13.8443 28.8596 13.9563 28.7216 13.9563C28.5836 13.9563 28.4716 13.8443 28.4716 13.7063V11.8803C28.4716 11.4643 28.1356 11.1253 27.7216 11.1253H14.4856C14.0716 11.1253 13.7356 11.4643 13.7356 11.8803V35.5553C13.7356 35.9713 14.0716 36.3093 14.4856 36.3093H27.7216C28.1356 36.3093 28.4716 35.9713 28.4716 35.5553V33.7833C28.4716 33.6453 28.5836 33.5333 28.7216 33.5333C28.8596 33.5333 28.9716 33.6453 28.9716 33.7833V35.5553C28.9716 36.2473 28.4106 36.8093 27.7216 36.8093Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M21.9472 38.7209C21.9472 39.1999 21.5612 39.5869 21.0842 39.5869C20.6082 39.5869 20.2212 39.1999 20.2212 38.7209C20.2212 38.2419 20.6082 37.8549 21.0842 37.8549C21.5612 37.8549 21.9472 38.2419 21.9472 38.7209Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M40.6709 26.8635C40.7339 26.7635 40.7369 26.6285 40.6769 26.5255L40.0119 25.3695C39.9539 25.2695 39.9549 25.1405 40.0129 25.0405L40.6759 23.9005C40.7359 23.7975 40.7339 23.6625 40.6709 23.5615L39.9479 22.4045C39.8829 22.3005 39.8829 22.1605 39.9479 22.0565L40.6679 20.9145C40.7329 20.8115 40.7329 20.6735 40.6709 20.5695L39.9469 19.3825C39.8819 19.2765 39.8839 19.1355 39.9519 19.0325L40.6619 17.9435C40.7299 17.8385 40.7309 17.6965 40.6639 17.5905L39.9179 16.4055C39.8689 16.3275 39.7879 16.2805 39.7029 16.2805H21.3789V31.1555H39.7029C39.7879 31.1555 39.8689 31.1085 39.9179 31.0295L40.6659 29.8415C40.7319 29.7375 40.7319 29.5975 40.6659 29.4925L39.9489 28.3545C39.8829 28.2505 39.8829 28.1095 39.9489 28.0055L40.6709 26.8635Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M40.4547 29.7082L39.7037 30.9052H21.6287V16.5302L39.7067 16.5392L40.4537 17.7242C40.4677 17.7472 40.4677 17.7842 40.4527 17.8072L39.7427 18.8962C39.6217 19.0812 39.6177 19.3232 39.7337 19.5122L40.4567 20.6992C40.4707 20.7222 40.4707 20.7582 40.4567 20.7812L39.7367 21.9232C39.6197 22.1092 39.6197 22.3502 39.7357 22.5362L40.4587 23.6942C40.4737 23.7162 40.4737 23.7512 40.4597 23.7742L39.7967 24.9152C39.6937 25.0912 39.6937 25.3192 39.7957 25.4942L40.4607 26.6492C40.4737 26.6732 40.4737 26.7082 40.4597 26.7292L39.7387 27.8722C39.6197 28.0592 39.6197 28.3002 39.7377 28.4882L40.4547 29.6262C40.4697 29.6492 40.4697 29.6852 40.4547 29.7082ZM40.1607 28.2212C40.1467 28.1982 40.1457 28.1622 40.1607 28.1392L40.8817 26.9972C40.9957 26.8192 40.9997 26.5842 40.8937 26.4002L40.2287 25.2442C40.2157 25.2222 40.2157 25.1882 40.2287 25.1662L40.8917 24.0262C40.9987 23.8432 40.9957 23.6092 40.8837 23.4292L40.1597 22.2712C40.1457 22.2492 40.1457 22.2122 40.1597 22.1892L40.8797 21.0472C40.9957 20.8642 40.9967 20.6252 40.8837 20.4392L40.1607 19.2512C40.1457 19.2292 40.1467 19.1922 40.1617 19.1692L40.8707 18.0802C40.9927 17.8942 40.9947 17.6442 40.8757 17.4572L40.1297 16.2732C40.0347 16.1212 39.8747 16.0302 39.7037 16.0302H21.6287V13.9642C21.6287 13.8262 21.5167 13.7142 21.3787 13.7142C21.2407 13.7142 21.1287 13.8262 21.1287 13.9642V16.2802V31.1552V33.4712C21.1287 33.6092 21.2407 33.7212 21.3787 33.7212C21.5167 33.7212 21.6287 33.6092 21.6287 33.4712V31.4052H39.7037C39.8757 31.4052 40.0357 31.3132 40.1297 31.1622L40.8767 29.9752C40.9957 29.7892 40.9957 29.5472 40.8777 29.3592L40.1607 28.2212Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M24.9824 28.0363C24.8444 28.0363 24.7324 27.9243 24.7324 27.7863V19.2133C24.7324 19.0753 24.8444 18.9633 24.9824 18.9633C25.1204 18.9633 25.2324 19.0753 25.2324 19.2133V27.7863C25.2324 27.9243 25.1204 28.0363 24.9824 28.0363Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M27.291 28.0363C27.153 28.0363 27.041 27.9243 27.041 27.7863V21.0363C27.041 20.8973 27.153 20.7863 27.291 20.7863C27.429 20.7863 27.541 20.8973 27.541 21.0363V27.7863C27.541 27.9243 27.429 28.0363 27.291 28.0363Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M29.603 28.0363C29.465 28.0363 29.353 27.9243 29.353 27.7863V21.0363C29.353 20.8973 29.465 20.7863 29.603 20.7863C29.741 20.7863 29.853 20.8973 29.853 21.0363V27.7863C29.853 27.9243 29.741 28.0363 29.603 28.0363Z"
        fill={ isTheme.styles.colors.textTitle } />
    </Element>
  );
};

UiRequestPaymentIcon.propTypes = {
  /** Определяет ширину иконки */
  width: PropTypes.string,
  /** Определяет высоту иконки */
  height: PropTypes.string
};

UiRequestPaymentIcon.defaultProps = {
  width: '3.25rem',
  height: '3.25rem'
};

export default UiRequestPaymentIcon;