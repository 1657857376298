import styled, { css } from 'styled-components';

import FSubstrate from '../FSubstrate';
import UiIcon from '../../../Ui/UiIcon';

import { useTheme } from '../../../../context/ThemeProvider';

const StyledBackspace = styled(FSubstrate)`
  ${ (props) => css`
    background: ${ props.styles.colors.keysBackgroundColor };
  ` };
`;

const FBackspace = ({
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <StyledBackspace
      { ...props }
      styles={ isTheme.styles }
    >
      <UiIcon
        view="default"
        icon="backspace"
        setWidth="36px"
        setHeight="36px"
        setColor={ isTheme.styles.colors.textTitle }
      />
    </StyledBackspace>
  );
};

export default FBackspace;