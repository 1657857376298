import React from 'react';
import { UiButton } from '@compassplus/ui-mobicash';
import { observer } from 'mobx-react-lite';
import ItemStore from '../../../../stores/ItemStore';
import i18n from '../../../../i18n';
import { DisabledButton, Stiky } from '../style';
import ModalStore from '../../../../stores/ModalStore';
import AttributesStore from '../../../../stores/AttributesStore';
import ShoppingCartStore from '../../../../stores/ShoppingCartStore';

const AddToCartButton = observer(() => {
  const isActive = ItemStore.checkIsActive();
  const { requiredSelectedFlag } = AttributesStore;
  return (
    <Stiky>
      {
        (isActive && requiredSelectedFlag) ? (
          <UiButton
            title={i18n.t('AddToCart')}
            onClick={() => {
              const addResponse = ShoppingCartStore.addItemToCart();
              if (addResponse.isError) {
                ModalStore.openUnexpectedErrorModal();
              } else {
                ModalStore.openAddToCart();
              }
            }}
          />
        ) : (
          <DisabledButton
            title={i18n.t('AddToCart')}
            onClick={() => { ModalStore.openWithMessage(!requiredSelectedFlag ? i18n.t('AddAttributesMustBeSpecified') : i18n.t('ItemIsNotAvailable')); }}
          />
        )
      }
    </Stiky>
  );
});

export default AddToCartButton;
