import React from 'react';
import { UiArrow, UiCard, UiCardSection, UiText, useOptions } from "@compassplus/ui-mobicash";
import i18n from "../../../../../i18n";
import { getCashierAuth, getCashierLogin } from "../../../../../Utils/requestUtil";
import { useHistory } from "react-router";
import {Text} from "../../../../../components/TemplateStyle";

const CashierAdministration = ({ location }) => {
  const isOptions = useOptions();
  const history = useHistory();

  return (
    <div style={isOptions.screen === 'mobile' ? {padding: '0 0.5rem 0'} : {padding: '0 0 0 6px'}}>
      <Text view="readonly" style={{textTransform: 'uppercase'}}
              typography="section-description-semibold">{i18n.t('Cashier')}</Text>
      <Text style={{paddingTop: '0.5rem'}} view="readonly" typography="note">
        {i18n.t('CashierAnnotation')}
      </Text>
      <UiCard style={{ marginTop: '0.5rem' }}>
        <UiCardSection
          title={(getCashierAuth() ? (getCashierLogin()) : i18n.t('SpecifyCashierDetails'))}
          description=""
          onClick={() => {
            history.push('/administration/cashierAuth', {
              id: location?.state?.id,
              state: location?.state?.state,
              type: location?.state?.type
            });
          }}
          rightElement={<UiArrow/>}
        />
      </UiCard>
    </div>
  )
}

export default CashierAdministration;