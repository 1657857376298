import React, { useEffect, useState, useReducer } from 'react';
import { useHistory } from 'react-router';
import {
  UiCard, UiInput, UiScrollArrow, UiSection, UiSwitch, UiDropdown, useOptions,
} from '@compassplus/ui-mobicash';
import { observer } from 'mobx-react-lite';
import i18n from '../../../i18n';
import PersonalDataFields from '../../../components/admin/DeliveryFormAttributeModify/PersonalDataFields';
import AddressFields from '../../../components/admin/DeliveryFormAttributeModify/AddressFields';
import DateFields from '../../../components/admin/DeliveryFormAttributeModify/DateFields';
import CostFields from '../../../components/admin/DeliveryFormAttributeModify/CostFields';
import LanguageTabs from '../../../components/admin/LanguageTabs';
import DeleteAttributeButton from './components/DeleteAttributeButton';
import SaveAttributeButton from './components/SaveAttributeButton';
import ActionSheet from '../../../components/admin/ActionSheet';

import DeliveryStore from '../../../stores/DeliveryStore';

const DeliveryAttributeModify = observer((props) => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const { location } = props;
  const isOptions = useOptions();
  const history = useHistory();
  const [typeSelectionIsOpen, setTypeSelectionIsOpen] = useState(false);
  const [modeIsModify, setModeIsModify] = useState(location.state.modify || false);
  const [attributeType, setAttributeType] = useState(location.state.type);
  const [language, setLanguage] = useState(props.language);

  const checkFormIsValid = () => Object.keys(DeliveryStore.form).length !== 0;

  useEffect(() => {
    if (!checkFormIsValid()) {
      history.push('/administration');
    }
  }, []);

  useEffect(() => {
    setLanguage(props.language);
  }, [props.language]);

  useEffect(() => {
    setAttributeType(location.state.type);
  }, [location.state.type]);

  const getTabContent = (language) => (
    <div style={isOptions.screen === 'mobile' ? { padding: '0 0.5rem 0' } : { padding: '0 0 0 6px', width: '100%' }}>
      {
        attributeType === 'PersonalData' && (
          <PersonalDataFields language={language} />
        )
      }
      {
        (attributeType === 'ClientAddress' || attributeType === 'PickupPoint') && (
          <AddressFields language={language} id={location.state.id} />
        )
      }
      {
        (attributeType === 'EntryField') && (
          <UiCard>
            <UiInput
              description={i18n.t(attributeType)}
              placeholder={i18n.t('Name')}
              value={DeliveryStore.form.getEntryFieldTitle()[language]}
              onChange={(event) => { DeliveryStore.form.setEntryFieldTitle(language, event.target.value); forceUpdate(); }}
              bottomDivider
            />
            <UiInput
              description={i18n.t('Type')}
              value={i18n.t(DeliveryStore.form.getEntryFieldType())}
              onClick={() => {
                (DeliveryStore.form.getEntryFieldType() === 'String')
                  ? DeliveryStore.form.setEntryFieldType('Number')
                  : DeliveryStore.form.setEntryFieldType('String');
                forceUpdate();
              }}
              rightElement={<UiScrollArrow />}
            />
          </UiCard>
        )
      }
    </div>
  );

  const renderAttributeFields = () => {
    switch (attributeType) {
      case 'Birthday':
        return (
          <div style={isOptions.screen === 'mobile' ? { padding: '0 1rem 0', width: '100%' } : {
            padding: '0 0 0 6px',
            width: '100%',
          }}
          >
            <UiCard>
              <UiSection
                title={i18n.t('Required')}
                rightElement={(
                  <UiSwitch
                    checked={DeliveryStore.form.birthday}
                    onClick={() => {
                      DeliveryStore.form.setBirthdayRequired(!DeliveryStore.form.birthday);
                      forceUpdate();
                    }}
                  />
                )}
              />
            </UiCard>
          </div>
        );
      case 'Phone':
        return (
          <div style={isOptions.screen === 'mobile' ? { padding: '0 1rem 0', width: '100%' } : {
            padding: '0 0 0 6px',
            width: '100%',
          }}
          >
            <UiCard>
              <UiSection
                title={i18n.t('Required')}
                rightElement={(
                  <UiSwitch
                    checked={DeliveryStore.form.phone}
                    onClick={() => {
                      DeliveryStore.form.setPhoneRequired(!DeliveryStore.form.phone);
                      forceUpdate();
                    }}
                  />
                )}
              />
            </UiCard>
          </div>
        );
      case 'PersonalData':
      case 'EntryField':
      case 'ClientAddress':
      case 'PickupPoint':
        return (
          <>
            {
              isOptions.screen === 'mobile' ? (
                <LanguageTabs setCurrentLanguage={setLanguage}>
                  {getTabContent}
                </LanguageTabs>
              ) : (
                <>
                  {getTabContent(language)}
                </>
              )
            }
          </>
        );
      case 'CostOfDelivery':
        return (
          <CostFields />
        );
      case 'DeliveryDate':
        return (
          <DateFields />
        );
      default:
        return null;
    }
  };

  const renderAttributeTypeSelect = () => (
    <UiCard style={{ position: 'relative', margin: '0.25rem 0' }}>
      <UiInput
        description={i18n.t('AttributeType')}
        value={i18n.t(attributeType || 'undefined')}
        rightElement={<UiDropdown />}
        onClick={() => {
          if (!modeIsModify) { setTypeSelectionIsOpen(!typeSelectionIsOpen); }
        }}
      />
      <ActionSheet
        open={typeSelectionIsOpen}
        setOpen={setTypeSelectionIsOpen}
      >
        {
          DeliveryStore.form.getAllAttributesType().map((type, index) => (
            <>
              {
                DeliveryStore.form.attributes.findIndex((el) => el.type === type) === -1 && (
                  <UiSection
                    title={i18n.t(type)}
                    view="link"
                    onClick={() => {
                      setAttributeType(type);
                      setTypeSelectionIsOpen(false);
                    }}
                    bottomDivider={index !== DeliveryStore.form.getAllAttributesType().length - 1}
                  />
                )
              }
            </>
          ))
        }
      </ActionSheet>
    </UiCard>
  );

  return (
    <>
      {checkFormIsValid() ? (
        <>
          <div style={isOptions.screen === 'mobile' ? { padding: '0 0.5rem 0.5rem', width: '100%' } : { padding: '0 0 0.5rem 6px', width: '100%' }}>
            {DeliveryStore.form.type !== 'Pickup' ? renderAttributeTypeSelect() : null}
          </div>
          {renderAttributeFields()}
          { modeIsModify ? <DeleteAttributeButton attributeType={attributeType} location={location} /> : null }
          <SaveAttributeButton attributeType={attributeType} modeIsModify={modeIsModify} location={location} />
        </>
      ) : null}
    </>
  );
});

export default DeliveryAttributeModify;
