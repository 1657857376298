import { escapeRegExp } from './regExpUtils';
import { fixedNegativeValue } from './numberUtils';
import { removeInvalidChars, replaceDecimalSeparator } from './stringUtils';

/**
 * Удалить префикс, разделители и лишние десятичные знаки из значения
 * @public
 * @version 1.6.1
 * @param {String} [value]
 * @param {Boolean} [allowDecimals=true]
 * @param {Boolean} [allowNegativeValue=true]
 * @param {Number} [decimalsLimit=2]
 * @param {'.'|','|' '} [groupSeparator]
 * @param {'.'|','} [decimalSeparator]
 * @param {String} [prefix]
 * @param {Function} [transformRawValue]
 * @return {String}
 * @constructor
 * @example
 * const value = cleanValue({
 *   value: '$1 000,00',
 *   allowDecimals: true,
 *   allowNegativeValue: true,
 *   decimalsLimit: 2,
 *   groupSeparator: ' ',
 *   decimalSeparator: ',',
 *   prefix: '$',
 *   transformRawValue: (value) => value
 * });
 *
 * console.log(value);
 * // 1000,00 */
export const cleanValue = ({
  value = '',
  allowDecimals = true,
  allowNegativeValue = true,
  decimalsLimit = 2,
  groupSeparator = ' ',
  decimalSeparator = '.',
  prefix = '',
  transformRawValue = (rawValue) => rawValue
}) => {
  const transformedValue = transformRawValue(value);

  if (transformedValue === '-') {
    if (allowNegativeValue) {
      return transformedValue;
    } else {
      return '';
    }
  }

  const reg = new RegExp(`((^|\\D)-\\d)|(-${ escapeRegExp(prefix) })`);
  const isNegative = reg.test(transformedValue);

  const [prefixWithValue, preValue] = RegExp(`(\\d+)-?${ escapeRegExp(prefix) }`).exec(value) || [];
  const withoutPrefix = prefix
    ? prefixWithValue
      ? transformedValue.replace(prefixWithValue, '').concat(preValue)
      : transformedValue.replace(prefix, '')
    : transformedValue;
  const withoutSeparators = removeSeparators(withoutPrefix, groupSeparator);
  const withoutInvalidChars = removeInvalidChars(withoutSeparators, [groupSeparator, decimalSeparator]);

  return fixedNegativeValue({
    value: withoutInvalidChars,
    allowDecimals,
    allowNegativeValue,
    decimalsLimit,
    decimalSeparator,
    isNegative
  });
};

/**
 * Значение формата с десятичным разделителем, разделителем групп и префиксом
 * @public
 * @version 1.6.1
 * @param {String} [value]
 * @param {'.'|','|' '} [groupSeparator]
 * @param {'.'|','} [decimalSeparator]
 * @param {String} [prefix]
 * @param {String} [suffix]
 * @param {Object} [intlConfig]
 * @param {String} [intlConfig.locale]
 * @param {String} [intlConfig.currency]
 * @return {String}
 * @constructor
 * @example
 * const firstValue = formatValue({ value: '1000.00', groupSeparator: ' ', decimalSeparator: ',', prefix: '$' });
 * const secondValue = formatValue({ value: '1000.00', intlConfig: { locale: 'ru', currency: 'USD' } });
 *
 * console.log(firstValue);
 * // $1 000,00
 * console.log(secondValue);
 * // 1 000 $ */
export const formatValue = ({ value: _value = '', groupSeparator = ' ', decimalSeparator = '.', prefix = '', suffix = '', intlConfig }) => {
  if (_value === '' || _value === '-') {
    return _value;
  }

  const reg = new RegExp(`^\\d?-${ prefix ? `${ escapeRegExp(prefix) }?` : '' }\\d`);
  const isNegative = reg.test(_value);

  const value =
    decimalSeparator !== '.'
      ? replaceDecimalSeparator(_value, decimalSeparator, isNegative)
      : _value;

  const defaultNumberFormatOptions = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 20
  };

  const numberFormatter = intlConfig
    ? new Intl.NumberFormat(
      intlConfig.locale,
      intlConfig.currency
        ? {
          ...defaultNumberFormatOptions,
          style: 'currency',
          currency: intlConfig.currency,
        }
        : defaultNumberFormatOptions
    )
    : new Intl.NumberFormat(undefined, defaultNumberFormatOptions);

  const parts = numberFormatter.formatToParts(Number(value));
  let formatted = replaceParts(parts, { prefix, groupSeparator, decimalSeparator });
  const intlSuffix = getSuffix(formatted, { groupSeparator, decimalSeparator });

  const includeDecimalSeparator = _value.slice(-1) === decimalSeparator ? decimalSeparator : '';

  const [, decimals] = value.match(RegExp('\\d+\\.(\\d+)')) || [];

  if (decimals && decimalSeparator) {
    if (formatted.includes(decimalSeparator)) {
      formatted = formatted.replace(RegExp(`(\\d+)(${ escapeRegExp(decimalSeparator) })(\\d+)`, 'g'), `$1$2${ decimals }`);
    } else {
      if (intlSuffix && !suffix) {
        formatted = formatted.replace(intlSuffix, `${ decimalSeparator }${ decimals }${ intlSuffix }`);
      } else {
        formatted = `${ formatted }${ decimalSeparator }${ decimals }`;
      }
    }
  }

  if (suffix && includeDecimalSeparator) {
    return `${ formatted }${ includeDecimalSeparator }${ suffix }`;
  }

  if (intlSuffix && includeDecimalSeparator) {
    return formatted.replace(intlSuffix, `${ includeDecimalSeparator }${ intlSuffix }`);
  }

  if (intlSuffix && suffix) {
    return formatted.replace(intlSuffix, `${ includeDecimalSeparator }${ suffix }`);
  }

  return [formatted, includeDecimalSeparator, suffix].join('');
};

const replaceParts = (parts = [], { prefix = '', groupSeparator = ' ', decimalSeparator = '.' }) => {
  return parts
    .reduce(
      (prev, { type, value }, i) => {
        if (i === 0 && !!prefix) {
          if (type === 'minusSign') {
            return [value, prefix];
          }

          if (type === 'currency') {
            return [...prev, prefix];
          }

          return [prefix, value];
        }

        if (type === 'currency') {
          return !!prefix ? prev : [...prev, value];
        }

        if (type === 'group') {
          return [...prev, !!groupSeparator ? groupSeparator : value];
        }

        if (type === 'decimal') {
          return [...prev, !!decimalSeparator ? decimalSeparator : value];
        }

        return [...prev, value];
      },
      ['']
    )
    .join('');
};

export const getSuffix = (value = '', { groupSeparator = ' ', decimalSeparator = '.' }) => {
  const suffixReg =  new RegExp(`[\\d|${ escapeRegExp(decimalSeparator) }]([^${ escapeRegExp(groupSeparator) }${ escapeRegExp(decimalSeparator) }0-9]+)`);
  const suffixMatch = value.match(suffixReg);

  return suffixMatch ? suffixMatch[1] : undefined;
};

export const removeSeparators = (value = '', separator = ' ') => {
  const reg = new RegExp(escapeRegExp(separator), 'g');

  return value.replace(reg, '');
};

export const repositionCursor = ({ selectionStart, value = '', lastKeyPressed = '', stateValue = '', groupSeparator }) => {
  let cursorPosition = selectionStart;
  let modifiedValue = value;

  if (stateValue && cursorPosition) {
    const splitValue = value.split('');

    if (lastKeyPressed === 'Backspace' && stateValue[cursorPosition] === groupSeparator) {
      splitValue.splice(cursorPosition - 1, 1);
      cursorPosition -= 1;
    }

    if (lastKeyPressed === 'Delete' && stateValue[cursorPosition] === groupSeparator) {
      splitValue.splice(cursorPosition, 1);
      cursorPosition += 1;
    }

    modifiedValue = splitValue.join('');

    return { modifiedValue, cursorPosition };
  }

  return { modifiedValue, cursorPosition: selectionStart };
};