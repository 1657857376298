import React, { useReducer } from 'react';
import {
  UiArrow, UiCard, UiIcon, UiInput, UiSection, useOptions,
} from '@compassplus/ui-mobicash';
import i18n from '../../../../i18n';
import { Text } from '../../../TemplateStyle';
import AttributesStore from '../../../../stores/AttributesStore';
import ItemStore from "../../../../stores/ItemStore";

const List = ({ isActive }) => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const attributes = AttributesStore.getAttributesByType('list');
  const isOptions = useOptions();

  const getListAttribute = (listAttribute, index) => (
    (listAttribute) && (
      <>
        <Text style={{ margin: '0.5rem 0' }} typography="subsection-title" view="readonly">
          {`${listAttribute.title[isOptions.language]}:`}
        </Text>
        <Text style={{ margin: '0 0 0.5rem 0' }} typography="note" view="readonly">
          {`${listAttribute.description[isOptions.language]}`}
        </Text>
        <UiCard style={{ margin: '0 0 0.5rem 0' }} shadow>
          {
            listAttribute.value && listAttribute.value.map((point, pointIndex) => (
              <UiInput
                key={pointIndex}
                description={`${listAttribute.pointName[isOptions.language]} ${pointIndex + 1}`}
                value={point}
                onChange={(event) => {
                  listAttribute.value[pointIndex] = event.target.value;
                  AttributesStore.checkAllRequiredAttributesSelected();
                  forceUpdate();
                }}
                placeholder={`[${i18n.t('Text')}]`}
                rightElement={(
                  <UiIcon
                    icon="cancel"
                    view="brand"
                    onClick={() => {
                      if (listAttribute.value.length > 1) {
                        ItemStore.price = (Number(ItemStore.price) - Number(listAttribute.priceChange)).toFixed(2);
                      }
                      if (pointIndex === 0 && listAttribute.value.length === 1) {
                        listAttribute.value[pointIndex] = '';
                      } else {
                        listAttribute.value.splice(pointIndex, 1);
                      }
                      AttributesStore.checkAllRequiredAttributesSelected();
                      forceUpdate();
                    }}
                  />
                )}
                bottomDivider={pointIndex !== listAttribute.maxPointsNumber - 1}
                required
              />
            ))
          }
          {
            listAttribute.value.length < listAttribute.maxPointsNumber && (
              <UiSection
                disabled={!isActive}
                title={`${i18n.t('Add')} ${listAttribute.pointName[isOptions.language].toLowerCase()}`}
                onClick={() => {
                  listAttribute.value.push('');
                  if (listAttribute.value.length > 1) {
                    ItemStore.price = (Number(ItemStore.price) + Number(listAttribute.priceChange)).toFixed(2);
                  }
                  forceUpdate();
                }}
                rightElement={<UiArrow />}
              />
            )
          }
        </UiCard>
      </>
    )
  );

  return (
    <>
      {attributes.map(getListAttribute)}
    </>
  );
};

export default List;
