import React, { useState } from 'react';
import OrdersRecordsStore from "../../../../stores/OrdersRecordsStore";
import { UiButton, UiCard, UiRadioButton, UiSection } from "@compassplus/ui-mobicash";
import i18n from "../../../../i18n";
import { useHistory } from "react-router";
import { SubContentStiky } from "../../ModifyAttributePage/style";

const Statuses = () => {
  const { statuses, modifyingOrder } = OrdersRecordsStore;
  const [selectedStatus, setSelectedStatus] = useState(modifyingOrder?.status);
  const history = useHistory();

  return (
    <>
      <UiCard shadow>
        {
          statuses.map((status, index) => (
            <React.Fragment key={status}>
              {
                (status !== 'Deleted') ? (
                  <UiSection
                    title={i18n.t(status)}
                    leftElement={<UiRadioButton checked={selectedStatus === status} />}
                    bottomDivider={index !== statuses.length - 2}
                    onClick={() => {
                      setSelectedStatus(status);
                    }}
                  />
                ) : null
              }
            </React.Fragment>
          ))
        }
      </UiCard>
      <SubContentStiky>
        <UiButton
          title={i18n.t('Save')}
          onClick={() => {
            OrdersRecordsStore.modifyStatus(selectedStatus);
            history.replace(`/modifyOrder/${modifyingOrder._id}`);
          }}
        />
      </SubContentStiky>
    </>
  );
}

export default Statuses