import { createContext, useContext, useState, useEffect } from 'react';
import { createGlobalStyle } from 'styled-components';
import { mergeDeep } from '@compassplus/js-utils';

import { useOptions } from './OptionsProvider';

import { changeStorage } from '../services/changeStorage';

import lightTheme from '../themes/light';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    scrollbar-color: ${ (props) => `${ props.styles.colors.overlay_zero_point_two } ${ props.styles.colors.textDisable }` };
    scrollbar-width: thin;
    scroll-behavior: smooth;
    box-sizing: border-box;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  html {
    ${ (props) => props.styles.fonts.regular };
    font-size: 16px;
    line-height: normal;
  }

  #root {
    color: ${ (props) => props.styles.colors.textTitle };
    background: ${ (props) => props.styles.colors.sectionBackground };
  }
`;

const ThemeContext = createContext(undefined);

/**
 * Theme provider
 * @public
 * @version 0.0.90
 * @param {Object} theme
 * @param {String} theme.name
 * @param {Object} theme.styles
 * @param {*} children
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @export
 * <ThemeProvider>{ children }</ThemeProvider>
 */
export const ThemeProvider = ({
  theme,
  storybook,
  children,
  ...props
}) => {

  const isOptions = useOptions();

  const [isTheme, setIsTheme] = useState(() => {
    if (theme && storybook) {
      return { name: theme.name, styles: theme.styles };
    } else if (changeStorage.getItem('theme') && changeStorage.getObject('styles')) {
      const target = JSON.parse(JSON.stringify(lightTheme.styles));

      return {
        name: changeStorage.getItem('theme'),
        styles: mergeDeep(target, changeStorage.getObject('styles'))
      }
    } else if (theme) {
      const target = JSON.parse(JSON.stringify(lightTheme.styles));

      return {
        name: theme.name,
        styles: mergeDeep(target, theme.styles)
      }
    } else {
      return { name: lightTheme.name, styles: lightTheme.styles };
    }
  });

  useEffect(() => {
    if (isOptions.cookies && changeStorage.getItem('theme') !== isTheme.name) {
      changeStorage.setItem('theme', isTheme.name);
      changeStorage.setObject('styles', isTheme.styles);
    }
  }, [isOptions.cookies, isTheme]);

  useEffect(() => {
    if (theme && storybook) {
      setIsTheme({ name: theme.name, styles: theme.styles });
    }
  }, [theme, storybook]);

  const setTheme = ({ name, styles }) => {
    setIsTheme({ name, styles });
  };

  return (
    <ThemeContext.Provider
      value={ {
        theme: isTheme.name,
        styles: isTheme.styles,
        setTheme: setTheme,
      } }
      { ...props }
    >
      <GlobalStyle styles={ isTheme.styles } />
      { children }
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;

export const useTheme = () => {
  const context = useContext(ThemeContext);

  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }

  return context;
};