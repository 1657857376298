import PropTypes from 'prop-types';
import cn from 'classnames';
import styled from 'styled-components';

import { useTheme } from '../../../context/ThemeProvider';

import styles from './UiStiky.module.css';

const StyledStiky = styled.div`
  background: ${ (props) => props.styles.colors.applicationBackground };
  box-shadow: ${ (props) => props.styles.shadows.stiky };
`;

/**
 * Stiky component
 * @public
 * @version 0.0.90
 * @param {String} className
 * @param {*} children
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiStiky>{ children }</UiStiky>
 */
const UiStiky = ({
  className,
  children,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <StyledStiky { ...props } className={ cn(styles.stiky, className) } styles={ isTheme.styles }>
      { children }
    </StyledStiky>
  );
};

UiStiky.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Контент контейнера */
  children: PropTypes.any
};

export default UiStiky;