import PropTypes from 'prop-types';
import cn from 'classnames';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../context/ThemeProvider';

import styles from './BackgroundModal.module.css';

const StyledBackgroundModal = styled.div`
  ${ (props) => {
    switch (props.view) {
      case 'black':
        return css`
          background: rgba(0, 0, 0, 0.5);
          backdrop-filter: blur(0.5rem);
        `;
      default:
        return css`
          background: ${ props.styles.colors.overlay_zero_point_two };
        `;
    }
  } }
`;

const BackgroundModal = ({
  className,
  view,
  innerRef,
  children,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <StyledBackgroundModal
      { ...props }
      className={ cn(styles.background, className) }
      view={ view }
      ref={ innerRef }
      styles={ isTheme.styles }
    >
      { children }
    </StyledBackgroundModal>
  );
};

BackgroundModal.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Вид отображения */
  view: PropTypes.oneOf(['default', 'black']),
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  /** Контент */
  children: PropTypes.any
};

export default BackgroundModal;