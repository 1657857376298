export function fillLanguage(langObj) {
  const tmpObj = langObj;
  if (langObj.ru !== undefined && langObj.en !== undefined) {
    if (langObj.ru === '' && langObj.en !== '') {
      tmpObj.ru = langObj.en;
    }
    if (langObj.ru !== '' && langObj.en === '') {
      tmpObj.en = langObj.ru;
    }
  }
  return tmpObj;
}