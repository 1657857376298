import React from 'react';
import { UiCard, UiCardSection } from '@compassplus/ui-mobicash';
import { LoadingMarketSection, LoadingTitle } from './style';

const LoadingMarketAttribute = ({ style }) => {
  return (
    <div style={style}>
      <LoadingTitle margin={'0.25rem 0'} />
      <UiCard style={{ margin: '0.25rem 0' }} shadow>
        <UiCardSection
          title={<LoadingMarketSection />}
          description=''
          bottomDivider
        />
        <UiCardSection
          title={<LoadingMarketSection />}
          description=''
        />
      </UiCard>
    </div>
  );
}

export default LoadingMarketAttribute;