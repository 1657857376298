import PropTypes from 'prop-types';
import cn from 'classnames';
import styled, { css } from 'styled-components';

import { useOptions } from '../../../context/OptionsProvider';
import { useTheme } from '../../../context/ThemeProvider';

import Modal from '../../Functional/Modal';
import BackgroundModal from '../../Functional/BackgroundModal';
import Loader from '../../Functional/Loader';

import { screens } from '../../../constants';

import styles from './UiLoader.module.css';

const StyledContainer = styled.div`
  color: ${ (props) => props.styles.colors.textTitle };

  ${ (props) => {
    if (props.type === 'modal' && props.screen === screens.MOBILE) {
      return css`
        width: 4.375rem;
        height: 4.375rem;
      `;
    } else if (props.type === 'modal' && props.screen === screens.DESKTOP) {
      return css`
        width: 6.25rem;
        height: 6.25rem;
      `;
    } else if (props.type === 'default' && props.screen === screens.MOBILE) {
      return css`
        width: 3rem;
        height: 3rem;
      `;
    } else {
      return css`
        width: 4rem;
        height: 4rem;
      `;
    }
  } }
`;

/**
 * Loader component
 * @public
 * @version 0.0.90
 * @param {String} className
 * @param {'default'|'modal'} [type=modal]
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiLoader />
 */
const UiLoader = ({
  className,
  type
}) => {

  const isOptions = useOptions();
  const isTheme = useTheme();

  return (
    type === 'modal' ?
      <Modal>
        <BackgroundModal className={ cn(styles.loader, className) }>
          <StyledContainer type="modal" screen={ isOptions.screen } styles={ isTheme.styles }>
            <Loader />
          </StyledContainer>
        </BackgroundModal>
      </Modal>
      :
      <StyledContainer type="default" className={ className } screen={ isOptions.screen } styles={ isTheme.styles }>
        <Loader />
      </StyledContainer>
  );
};

UiLoader.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Определяет тип индикатора загрузки */
  type: PropTypes.oneOf(['default', 'modal'])
};

UiLoader.defaultProps = {
  type: 'modal'
};

export default UiLoader;