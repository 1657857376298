import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Иконка доставки
 * @public
 * @version 1.5.0
 * @param {String} [width="3.25rem"]
 * @param {String} [height="3.25rem"]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * import { UiDeliveryIcon, UiPlates, UiPlate } from '@compassplus/ui-mobicash';
 *
 * const App = () => {
 *
 *   return (
 *     <>
 *       <UiDeliveryIcon />
 *       <UiPlates>
 *         <UiPlate view="2x1" image={ <UiDeliveryIcon width="100%" height="100%" /> } />
 *       </UiPlates>
 *     </>
 *   );
 * }; */
const UiDeliveryIcon = ({
  width,
  height,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      innerRef={ innerRef }
      width={ width }
      height={ height }
      viewBox="0 0 52 52"
    >
      <path fillRule="evenodd" clipRule="evenodd"
        d="M45.9737 35.221L43.8277 34.897C43.5497 34.863 43.3077 34.769 43.1537 34.636L40.1017 31.994C39.9037 31.823 39.5617 31.719 39.1947 31.719H31.7277V26.246C31.7277 26.03 31.3817 25.854 30.9547 25.854H21.6507H13.5417H4.81768C4.39068 25.854 4.04468 26.03 4.04468 26.246V37.178V39.744C4.04468 40.067 4.56068 40.328 5.19868 40.328H7.65268C7.64668 40.371 7.62768 40.411 7.62768 40.455C7.62768 41.583 9.37268 42.5 11.5167 42.5C13.6567 42.5 15.3967 41.586 15.4047 40.462H33.7127C33.7207 41.586 35.4607 42.5 37.6007 42.5C39.7447 42.5 41.4897 41.583 41.4897 40.455H43.4917H45.6817H45.7407C46.3357 40.455 46.8187 40.187 46.8187 39.857V38.972V35.805C46.8187 35.525 46.4667 35.282 45.9737 35.221Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M31.8892 20.533H39.2752C39.6392 20.533 39.9772 20.728 40.1732 21.051L43.1932 26.019C43.3452 26.269 43.5852 26.446 43.8602 26.51L45.9832 27.12C46.4712 27.234 46.8182 27.691 46.8182 28.219V35.84C46.8182 36.462 46.3412 36.965 45.7522 36.965H31.8892V20.533Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M32.3892 36.4651H45.7522C46.0642 36.4651 46.3182 36.1851 46.3182 35.8401V28.2191C46.3182 27.9251 46.1292 27.6681 45.8692 27.6071L43.7222 26.9901C43.3382 26.9021 42.9902 26.6471 42.7652 26.2781L39.7462 21.3101C39.6402 21.1371 39.4642 21.0331 39.2752 21.0331H32.3892V36.4651ZM45.7522 37.4651H31.3892V20.0331H39.2752C39.8162 20.0331 40.3112 20.3161 40.6002 20.7901L43.6202 25.7591C43.7032 25.8961 43.8282 25.9891 43.9732 26.0231L46.1212 26.6401C46.8162 26.8011 47.3182 27.4531 47.3182 28.2191V35.8401C47.3182 36.7361 46.6162 37.4651 45.7522 37.4651Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M4.5 35.823C4.5 36.454 5.011 36.965 5.642 36.965H31.889V30.801H4.5V35.823Z" fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M5 31.301V35.823C5 36.177 5.288 36.465 5.642 36.465H31.389V31.301H5ZM32.389 37.465H5.642C4.737 37.465 4 36.728 4 35.823V30.301H32.389V37.465Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M31.8892 30.801H4.50024V10.239C4.50024 9.831 4.84224 9.5 5.26524 9.5H31.1232C31.5462 9.5 31.8892 9.831 31.8892 10.239V30.801Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M5 30.301H31.389V10.239C31.389 10.107 31.27 10 31.124 10H5.265C5.119 10 5 10.107 5 10.239V30.301ZM32.389 31.301H4V10.239C4 9.556 4.567 9 5.265 9H31.124C31.821 9 32.389 9.556 32.389 10.239V31.301Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M40.8033 26.9773L38.6243 23.3813C38.5263 23.2183 38.3503 23.1193 38.1603 23.1193H34.3333C34.0333 23.1193 33.7913 23.3623 33.7913 23.6623V27.2583C33.7913 27.5573 34.0333 27.8003 34.3333 27.8003H40.3393C40.7623 27.8003 41.0223 27.3383 40.8033 26.9773Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M34.333 23.3694C34.172 23.3694 34.041 23.5004 34.041 23.6614V27.2584C34.041 27.4194 34.172 27.5504 34.333 27.5504H40.339C40.494 27.5504 40.569 27.4464 40.594 27.4014C40.646 27.3084 40.645 27.1984 40.589 27.1064L38.411 23.5104C38.358 23.4234 38.262 23.3694 38.161 23.3694H34.333ZM40.339 28.0504H34.333C33.896 28.0504 33.541 27.6954 33.541 27.2584V23.6614C33.541 23.2244 33.896 22.8694 34.333 22.8694H38.161C38.436 22.8694 38.695 23.0154 38.838 23.2514L41.017 26.8484C41.167 27.0954 41.172 27.3944 41.03 27.6474C40.887 27.8994 40.629 28.0504 40.339 28.0504Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M46.8183 35.8401V34.1771H44.6853C44.0463 34.1771 43.5273 34.6951 43.5273 35.3351V36.9651H45.6933C46.3153 36.9651 46.8183 36.4611 46.8183 35.8401Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M43.7773 36.7151H45.6933C46.1753 36.7151 46.5683 36.3221 46.5683 35.8401V34.4271H44.6853C44.1843 34.4271 43.7773 34.8341 43.7773 35.3351V36.7151ZM45.6933 37.2151H43.2773V35.3351C43.2773 34.5581 43.9083 33.9271 44.6853 33.9271H47.0683V35.8401C47.0683 36.5981 46.4513 37.2151 45.6933 37.2151Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M11.8927 40.8123C9.77168 40.8123 8.04468 39.0863 8.04468 36.9653C8.04468 34.8433 9.77168 33.1173 11.8927 33.1173C14.0147 33.1173 15.7407 34.8433 15.7407 36.9653C15.7407 39.0863 14.0147 40.8123 11.8927 40.8123Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M11.8927 33.3674C9.90868 33.3674 8.29468 34.9814 8.29468 36.9654C8.29468 38.9484 9.90868 40.5624 11.8927 40.5624C13.8767 40.5624 15.4907 38.9484 15.4907 36.9654C15.4907 34.9814 13.8767 33.3674 11.8927 33.3674ZM11.8927 41.0624C9.63368 41.0624 7.79468 39.2244 7.79468 36.9654C7.79468 34.7054 9.63368 32.8674 11.8927 32.8674C14.1517 32.8674 15.9907 34.7054 15.9907 36.9654C15.9907 39.2244 14.1517 41.0624 11.8927 41.0624Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M37.6986 40.8123C35.5766 40.8123 33.8506 39.0863 33.8506 36.9653C33.8506 34.8433 35.5766 33.1173 37.6986 33.1173C39.8196 33.1173 41.5466 34.8433 41.5466 36.9653C41.5466 39.0863 39.8196 40.8123 37.6986 40.8123Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M37.6986 33.3674C35.7146 33.3674 34.1006 34.9814 34.1006 36.9654C34.1006 38.9484 35.7146 40.5624 37.6986 40.5624C39.6826 40.5624 41.2966 38.9484 41.2966 36.9654C41.2966 34.9814 39.6826 33.3674 37.6986 33.3674ZM37.6986 41.0624C35.4396 41.0624 33.6006 39.2244 33.6006 36.9654C33.6006 34.7054 35.4396 32.8674 37.6986 32.8674C39.9576 32.8674 41.7966 34.7054 41.7966 36.9654C41.7966 39.2244 39.9576 41.0624 37.6986 41.0624Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M14.9442 19.634L17.5052 17.703C17.7412 17.526 18.0732 17.526 18.3092 17.703L20.8702 19.634C21.2942 19.954 21.9182 19.667 21.9182 19.152V9.99701H13.8962V19.152C13.8962 19.667 14.5202 19.954 14.9442 19.634Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M16.9106 25.197H6.66064C6.38464 25.197 6.16064 24.973 6.16064 24.697C6.16064 24.421 6.38464 24.197 6.66064 24.197H16.9106C17.1866 24.197 17.4106 24.421 17.4106 24.697C17.4106 24.973 17.1866 25.197 16.9106 25.197Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M22.4419 27.7493H6.66089C6.38389 27.7493 6.16089 27.5253 6.16089 27.2493C6.16089 26.9733 6.38389 26.7493 6.66089 26.7493H22.4419C22.7179 26.7493 22.9419 26.9733 22.9419 27.2493C22.9419 27.5253 22.7179 27.7493 22.4419 27.7493Z"
        fill={ isTheme.styles.colors.textTitle } />
    </Element>
  );
};

UiDeliveryIcon.propTypes = {
  /** Определяет ширину иконки */
  width: PropTypes.string,
  /** Определяет высоту иконки */
  height: PropTypes.string
};

UiDeliveryIcon.defaultProps = {
  width: '3.25rem',
  height: '3.25rem'
};

export default UiDeliveryIcon;