import React from 'react';
import { useOptions } from '@compassplus/ui-mobicash';
import i18n from '../../../i18n';

import {
  MContentDiv,
  SubContentDiv,
  MainTitle,
} from '../../../components/TemplateStyle';

import {
  Subsection, PSPLogoContainer,
} from './styles';
import LoginTabs from './LoginTabs';

const Authorization = (props) => {
  const isOptions = useOptions();
  i18n.changeLanguage(localStorage.getItem('language') || 'en');

  const renderAuthInputs = () => {
    if (isOptions.screen === 'mobile') {
      return (
        <LoginTabs redirect={props?.location?.state?.redirect} />
      );
    }
    return (
      <Subsection title={i18n.t('Entrance')}>
        <LoginTabs redirect={props?.location?.state?.redirect} />
      </Subsection>
    );
  };

  return (
    <>
      <MContentDiv>
        <PSPLogoContainer>
          <img src="/images/CompassPlus.svg" />
        </PSPLogoContainer>
        <MainTitle>
          <span>eMarketPlace</span>
        </MainTitle>
      </MContentDiv>
      <SubContentDiv>
        {renderAuthInputs()}
      </SubContentDiv>
    </>
  );
};

export default Authorization;
