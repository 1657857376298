import PropTypes from 'prop-types';
import cn from 'classnames';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../context/ThemeProvider';

import styles from './UiButton.module.css';

const StyledButton = styled.button`

  ${ (props) => css`
    ${ props.styles.typography.CTAButton };
    color: #FFFFFF;
  ` }

  ${ (props) => {
    switch (props.view) {
      case 'default':
        return css`
          background: ${ props.styles.colors.textLink };
        `;
      case 'white':
        return css`
          ${ props.styles.typography.CTAButtonBold };
          color: ${ props.styles.colors.textLink };
          background: ${ props.styles.colors.sectionBackground };
        `;
      case 'warning':
        return css`
          background: ${ props.styles.colors.warningColor };
        `;
      default:
        return css`
          background: ${ props.styles.colors.textLink };
        `;
    }
  } };

  box-shadow: ${ (props) => props.styles.shadows.mainShadow };

  &:disabled {
    color: ${ (props) => props.styles.colors.applicationBackground };
    background: ${ (props) => props.styles.colors.textDisable };
    box-shadow: none;
  }
`;

/**
 * Button component
 * @public
 * @version 0.0.90
 * @param {String} className
 * @param {'button'|'submit'} [type=button]
 * @param {String} [title=CTA button]
 * @param {'default'|'white'|'warning'} [view=default]
 * @param {Boolean} [disabled=false]
 * @param {Function} onClick
 * @param {Function|Object} [innerRef]
 * @param props
 * @returns {JSX.Element}
 * @constructor
 * @example
 * <UiButton />
 */
const UiButton = ({
  className,
  type,
  title,
  view,
  disabled,
  onClick,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <StyledButton
      { ...props }
      type={ type }
      className={ cn(styles.button, className) }
      view={ view }
      disabled={ disabled }
      onClick={ onClick }
      ref={ innerRef }
      styles={ isTheme.styles }
    >
      { title }
    </StyledButton>
  );
};

UiButton.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Определяет тип кнопки */
  type: PropTypes.oneOf(['button', 'submit']),
  /** Устанавливает надпись на кнопке */
  title: PropTypes.string,
  /** Вид отображения кнопки */
  view: PropTypes.oneOf(['default', 'white', 'warning']),
  /** Делает компонент неактивным */
  disabled: PropTypes.bool,
  /** Устанавливает обработчик события при нажатии */
  onClick: PropTypes.func,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

UiButton.defaultProps = {
  type: 'button',
  title: 'CTA button',
  view: 'default',
  disabled: false
};

export default UiButton;