import { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import UiInput from '../../Ui/UiInput';

import { useForkRef } from '../../../hooks';

import { cleanValue, fixedDecimalValue, formatValue, padTrimValue } from '../../../utils/numberUtils';
import { isFunction } from '../../../utils/typeUtils';

/**
 * Компонент поля ввода чисел (целых и десятичных)
 * @public
 * @version 1.8.0
 * @param {Boolean} [allowDecimals=true]
 * @param {Boolean} [allowNegativeValue=true]
 * @param {Number} [decimalsLimit=2]
 * @param {Number} [fixedDecimalLength=0]
 * @param {'.'|','} [decimalSeparator='.']
 * @param {Number} [userMaxLength:maxLength=20]
 * @param {Number|String} [defaultValue]
 * @param {Number|String} [userValue:value]
 * @param {Function} [onValueChange]
 * @param {Function} [onChange]
 * @param {Function} [onBlur]
 * @param {Function} [transformRawValue]
 * @param {Boolean} [custom=true]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiInputNumber />
 */
const UiInputNumber = ({
  allowDecimals,
  allowNegativeValue,
  decimalsLimit,
  fixedDecimalLength,
  decimalSeparator,
  maxLength: userMaxLength,
  defaultValue,
  value: userValue,
  onValueChange,
  onChange,
  onBlur,
  transformRawValue = (value) => value,
  custom = true,
  innerRef,
  ...props
}) => {

  const inputRef = useRef(null);
  const refs = useForkRef([inputRef, innerRef]);

  const cleanValueOptions = useMemo(() => {
    return {
      allowDecimals,
      allowNegativeValue,
      decimalsLimit: decimalsLimit || fixedDecimalLength,
      decimalSeparator,
      transformRawValue
    };
  }, [allowDecimals, allowNegativeValue, decimalSeparator, decimalsLimit, fixedDecimalLength, transformRawValue]);

  const formattedStateValue =
    defaultValue !== undefined && defaultValue !== null
      ? formatValue({ value: String(defaultValue), decimalSeparator })
      : userValue !== undefined && userValue !== null
        ? formatValue({ value: String(userValue), decimalSeparator })
        : '';

  const [stateValue, setStateValue] = useState(formattedStateValue);

  const processChange = useCallback((value) => {
    const stringValue = cleanValue({ value, ...cleanValueOptions });

    if (userMaxLength && stringValue.replace(/-/g, '').length > userMaxLength) {
      return;
    }

    if (stringValue === '' || stringValue === '-' || stringValue === decimalSeparator) {
      if (isFunction(onValueChange)) {
        onValueChange({
          text: '',
          value: null
        });
      }

      setStateValue(stringValue);
      return;
    }

    const stringValueWithoutSeparator = decimalSeparator
      ? stringValue.replace(decimalSeparator, '.')
      : stringValue;
    const numberValue = parseFloat(stringValueWithoutSeparator);
    const formattedValue = formatValue({ value: stringValue, decimalSeparator });

    setStateValue(formattedValue);

    if (isFunction(onValueChange)) {
      onValueChange({
        text: stringValue,
        value: numberValue
      });
    }
  }, [cleanValueOptions, decimalSeparator, onValueChange, userMaxLength]);

  const changeHandler = useCallback((event) => {
    const { target: { value } } = event;

    processChange(value);

    if (isFunction(onChange)) {
      onChange(event);
    }
  }, [processChange, onChange]);

  const blurHandler = useCallback((event) => {
    const { target: { value } } = event;

    const valueOnly = cleanValue({ value, ...cleanValueOptions });

    if (valueOnly === '-' || !valueOnly) {
      setStateValue('');

      if (isFunction(onBlur)) {
        onBlur(event);
      }

      return;
    }

    if (valueOnly.includes(decimalSeparator)) {
      const fixedDecimals = fixedDecimalValue(valueOnly, decimalSeparator, fixedDecimalLength);
      const newValue = padTrimValue(fixedDecimals, decimalSeparator);
      const numberValue = parseFloat(newValue.replace(decimalSeparator, '.'));
      const formattedValue = formatValue({ value: newValue, decimalSeparator });

      if (isFunction(onValueChange)) {
        onValueChange({
          text: formattedValue,
          value: numberValue
        });
      }

      setStateValue(formattedValue);
    }

    if (isFunction(onBlur)) {
      onBlur(event);
    }
  }, [cleanValueOptions, decimalSeparator, fixedDecimalLength, onBlur, onValueChange]);

  const getRenderValue = () => {
    if (userValue !== undefined && userValue !== null && stateValue !== '-' && (!decimalSeparator || stateValue !== decimalSeparator)) {
      return formatValue({ value: String(userValue), decimalSeparator });
    }

    return stateValue;
  };

  const inputProps = {
    type: 'text',
    inputMode: 'decimal',
    value: getRenderValue(),
    onChange: changeHandler,
    onBlur: blurHandler,
    ...props
  };

  if (custom) {
    return (
      <UiInput
        { ...inputProps }
        flag={ undefined }
        innerRef={ refs }
      />
    );
  }

  return (
    <input { ...inputProps } ref={ refs } />
  );
};

UiInputNumber.propTypes = {
  /** Разрешить десятичные дроби */
  allowDecimals: PropTypes.bool,
  /** Разрешить отрицательное значение */
  allowNegativeValue: PropTypes.bool,
  /** Ограничить допустимую длину десятичных знаков */
  decimalsLimit: PropTypes.number,
  /** Значение всегда будет иметь указанную длину десятичных знаков */
  fixedDecimalLength: PropTypes.number,
  /** Разделитель между целой частью и дробной частью значения */
  decimalSeparator: PropTypes.oneOf(['.', ',']),
  /** Максимальное количество символов, которое может ввести пользователь */
  maxLength: PropTypes.number,
  /** Значение по умолчанию, если не передано свойство value */
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Неформатированное значение */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Обработчик изменения значения */
  onValueChange: PropTypes.func,
  /** Пользовательский обработчик необработанного значения (Вызывается перед onChange. Необходимо вернуть строку) */
  transformRawValue: PropTypes.func,
  /** Кастомный компонент */
  custom: PropTypes.bool,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

UiInputNumber.defaultProps = {
  allowDecimals: true,
  allowNegativeValue: true,
  decimalsLimit: 2,
  fixedDecimalLength: 0,
  decimalSeparator: '.',
  maxLength: 17,
  transformRawValue: (value) => value,
  custom: true
};

export default UiInputNumber;