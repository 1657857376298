import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Иконка обзора продукта
 * @public
 * @version 1.5.0
 * @param {String} [width="3.25rem"]
 * @param {String} [height="3.25rem"]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * import { UiProductExploreIcon, UiPlates, UiPlate } from '@compassplus/ui-mobicash';
 *
 * const App = () => {
 *
 *   return (
 *     <>
 *       <UiProductExploreIcon />
 *       <UiPlates>
 *         <UiPlate view="2x1" image={ <UiProductExploreIcon width="100%" height="100%" /> } />
 *       </UiPlates>
 *     </>
 *   );
 * }; */
const UiProductExploreIcon = ({
  width,
  height,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      innerRef={ innerRef }
      width={ width }
      height={ height }
      viewBox="0 0 52 52"
    >
      <path fillRule="evenodd" clipRule="evenodd"
        d="M48.488 39.6582L45.463 38.0602V25.8232C45.463 24.4722 43.389 23.3772 40.831 23.3772H9.632C7.073 23.3772 5 24.4722 5 25.8232V38.6672C5 40.0182 7.073 41.1142 9.632 41.1142H40.831C42.432 41.1142 43.843 40.6842 44.675 40.0322L46.228 40.8522C46.54 41.0172 46.949 41.0992 47.358 41.0992C47.767 41.0992 48.176 41.0172 48.488 40.8522C49.113 40.5222 49.113 39.9882 48.488 39.6582Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M36.9688 35.293H11.3538C8.95379 35.293 7.00879 33.347 7.00879 30.947V15.846C7.00879 13.446 8.95379 11.5 11.3538 11.5H36.9688C39.3688 11.5 41.3148 13.446 41.3148 15.846V30.947C41.3148 33.347 39.3688 35.293 36.9688 35.293Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M11.3543 12C9.2333 12 7.5083 13.726 7.5083 15.846V30.947C7.5083 33.067 9.2333 34.793 11.3543 34.793H36.9693C39.0893 34.793 40.8153 33.067 40.8153 30.947V15.846C40.8153 13.726 39.0893 12 36.9693 12H11.3543ZM36.9693 35.793H11.3543C8.6823 35.793 6.5083 33.619 6.5083 30.947V15.846C6.5083 13.174 8.6823 11 11.3543 11H36.9693C39.6413 11 41.8153 13.174 41.8153 15.846V30.947C41.8153 33.619 39.6413 35.793 36.9693 35.793Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M39.9688 38.293H14.3538C11.9538 38.293 10.0088 36.347 10.0088 33.947V18.846C10.0088 16.446 11.9538 14.5 14.3538 14.5H39.9688C42.3688 14.5 44.3148 16.446 44.3148 18.846V33.947C44.3148 36.347 42.3688 38.293 39.9688 38.293Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M14.3543 15C12.2333 15 10.5083 16.726 10.5083 18.846V33.947C10.5083 36.067 12.2333 37.793 14.3543 37.793H39.9693C42.0893 37.793 43.8153 36.067 43.8153 33.947V18.846C43.8153 16.726 42.0893 15 39.9693 15H14.3543ZM39.9693 38.793H14.3543C11.6823 38.793 9.5083 36.619 9.5083 33.947V18.846C9.5083 16.174 11.6823 14 14.3543 14H39.9693C42.6413 14 44.8153 16.174 44.8153 18.846V33.947C44.8153 36.619 42.6413 38.793 39.9693 38.793Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M27.0388 31.9322H13.4058C13.2678 31.9322 13.1558 31.8202 13.1558 31.6822C13.1558 31.5442 13.2678 31.4322 13.4058 31.4322H27.0388C27.1768 31.4322 27.2888 31.5442 27.2888 31.6822C27.2888 31.8202 27.1768 31.9322 27.0388 31.9322Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M20.3918 34.4811H13.2498C13.1118 34.4811 12.9998 34.3691 12.9998 34.2311C12.9998 34.0931 13.1118 33.9811 13.2498 33.9811H20.3918C20.5298 33.9811 20.6418 34.0931 20.6418 34.2311C20.6418 34.3691 20.5298 34.4811 20.3918 34.4811Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M38.2028 34.4811H22.9148C22.7768 34.4811 22.6648 34.3691 22.6648 34.2311C22.6648 34.0931 22.7768 33.9811 22.9148 33.9811H38.2028C38.3408 33.9811 38.4528 34.0931 38.4528 34.2311C38.4528 34.3691 38.3408 34.4811 38.2028 34.4811Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M17.9743 25.7525H15.9053C14.8563 25.7525 14.0063 24.9025 14.0063 23.8545V22.1485C14.0063 21.0995 14.8563 20.2495 15.9053 20.2495H17.9743C19.0223 20.2495 19.8723 21.0995 19.8723 22.1485V23.8545C19.8723 24.9025 19.0223 25.7525 17.9743 25.7525Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M15.9048 20.4996C14.9958 20.4996 14.2568 21.2386 14.2568 22.1476V23.8536C14.2568 24.7636 14.9958 25.5026 15.9048 25.5026H17.9738C18.8828 25.5026 19.6218 24.7636 19.6218 23.8536V22.1476C19.6218 21.2386 18.8828 20.4996 17.9738 20.4996H15.9048ZM17.9738 26.0026H15.9048C14.7198 26.0026 13.7568 25.0386 13.7568 23.8536V22.1476C13.7568 20.9636 14.7198 19.9996 15.9048 19.9996H17.9738C19.1588 19.9996 20.1218 20.9636 20.1218 22.1476V23.8536C20.1218 25.0386 19.1588 26.0026 17.9738 26.0026Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M15.8718 22.5089H14.0068C13.8688 22.5089 13.7568 22.3969 13.7568 22.2589C13.7568 22.1209 13.8688 22.0089 14.0068 22.0089H15.8718C16.0098 22.0089 16.1218 22.1209 16.1218 22.2589C16.1218 22.3969 16.0098 22.5089 15.8718 22.5089Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M15.8718 23.9482H14.0068C13.8688 23.9482 13.7568 23.8362 13.7568 23.6982C13.7568 23.5602 13.8688 23.4482 14.0068 23.4482H15.8718C16.0098 23.4482 16.1218 23.5602 16.1218 23.6982C16.1218 23.8362 16.0098 23.9482 15.8718 23.9482Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M19.8381 22.566H17.9731C17.8351 22.566 17.7231 22.454 17.7231 22.316C17.7231 22.178 17.8351 22.066 17.9731 22.066H19.8381C19.9761 22.066 20.0881 22.178 20.0881 22.316C20.0881 22.454 19.9761 22.566 19.8381 22.566Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M19.8381 24.0055H17.9731C17.8351 24.0055 17.7231 23.8935 17.7231 23.7555C17.7231 23.6175 17.8351 23.5055 17.9731 23.5055H19.8381C19.9761 23.5055 20.0881 23.6175 20.0881 23.7555C20.0881 23.8935 19.9761 24.0055 19.8381 24.0055Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M38.3655 31.1514L45.0385 37.8244C45.3345 38.1204 45.7215 38.2684 46.1095 38.2684C46.4965 38.2684 46.8835 38.1204 47.1785 37.8244C47.7705 37.2334 47.7705 36.2754 47.1785 35.6844L40.5785 29.0844"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M41.9592 24.94C41.9592 29.173 38.5271 32.605 34.2941 32.605C30.0611 32.605 26.6292 29.173 26.6292 24.94C26.6292 20.707 30.0611 17.275 34.2941 17.275C38.5271 17.275 41.9592 20.707 41.9592 24.94Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M34.2946 17.7754C30.3436 17.7754 27.1296 20.9894 27.1296 24.9404C27.1296 28.8904 30.3436 32.1054 34.2946 32.1054C38.2456 32.1054 41.4596 28.8904 41.4596 24.9404C41.4596 20.9894 38.2456 17.7754 34.2946 17.7754ZM34.2946 33.1054C29.7926 33.1054 26.1296 29.4424 26.1296 24.9404C26.1296 20.4384 29.7926 16.7754 34.2946 16.7754C38.7966 16.7754 42.4596 20.4384 42.4596 24.9404C42.4596 29.4424 38.7966 33.1054 34.2946 33.1054Z"
        fill={ isTheme.styles.colors.textTitle } />
    </Element>
  );
};

UiProductExploreIcon.propTypes = {
  /** Определяет ширину иконки */
  width: PropTypes.string,
  /** Определяет высоту иконки */
  height: PropTypes.string
};

UiProductExploreIcon.defaultProps = {
  width: '3.25rem',
  height: '3.25rem'
};

export default UiProductExploreIcon;