import React, { useState } from 'react';
import { UiCard, UiIcon, UiSection } from '@compassplus/ui-mobicash';
import i18n from '../../../i18n';
import ActionSheet from '../ActionSheet';
import AttributesStore from "../../../stores/AttributesStore";
import {useHistory} from "react-router";
import ItemStore from "../../../stores/ItemStore";

const ATTRIBUTE_TYPE_CHECKBOX = 'checkbox';
const ATTRIBUTE_TYPE_RADIO = 'radio';
const ATTRIBUTE_TYPE_TEXTFIELD = 'textField';
const ATTRIBUTE_TYPE_COLOR = 'color';
const ATTRIBUTE_TYPE_LIST = 'list';

const AddAttributesButton = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const history = useHistory();

  const renderAttributeTypeSection = (type, bottomDivider) => (
    <UiSection
      key={type}
      title={i18n.t(type)}
      view="link"
      onClick={() => {
        AttributesStore.addAttribute(type);
        history.push('/addAttribute', { rootGroup: ItemStore.rootGroup });
      }}
      bottomDivider={bottomDivider}
    />
  );

  return (
    <UiCard style={{ margin: '0.25rem 0', position: 'relative' }}>
      <UiSection
        title={i18n.t('AddAttribute')}
        view="link"
        leftElement={<UiIcon icon="legacy-plus" setColor="#037AFF" />}
        onClick={() => {
          setIsDropdownOpen(!isDropdownOpen);
        }}
        active={isDropdownOpen}
      />
      <ActionSheet
        open={isDropdownOpen}
        setOpen={setIsDropdownOpen}
      >
        {renderAttributeTypeSection(ATTRIBUTE_TYPE_TEXTFIELD, true)}
        {renderAttributeTypeSection(ATTRIBUTE_TYPE_RADIO, true)}
        {renderAttributeTypeSection(ATTRIBUTE_TYPE_CHECKBOX, true)}
        {AttributesStore.getAttributesByType(ATTRIBUTE_TYPE_COLOR).length === 0 ? renderAttributeTypeSection(ATTRIBUTE_TYPE_COLOR, true) : null}
        {renderAttributeTypeSection(ATTRIBUTE_TYPE_LIST, false)}
      </ActionSheet>
    </UiCard>
  );
};

export default AddAttributesButton;
