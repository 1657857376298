import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router';
import { observer } from 'mobx-react-lite';
import { useOptions } from '@compassplus/ui-mobicash';
import i18n from '../../../i18n';
import { MContentDiv, SubContentDiv } from '../../../components/TemplateStyle';
import DeliveryModify from '../DeliveryModifyPage/DeliveryModify';
import DeliveryAttributeModify from './DeliveryAttributeModify';

import GlobalStateStore from '../../../stores/GlobalStateStore';
import DeliveryStore from '../../../stores/DeliveryStore';

const DeliveryAttributePage = observer((props) => {
  const { location } = props;
  const history = useHistory();
  const isOptions = useOptions();
  const [language, setLanguage] = useState(i18n.language);

  const checkFormIsValid = () => Object.keys(DeliveryStore.form).length !== 0;

  useEffect(() => {
    if (!checkFormIsValid()) {
      history.push('/administration');
    }
  }, []);

  useEffect(() => {
    GlobalStateStore.setHeaderProperties({
      title: 'Attribute',
      isBack: true,
      onBack: () => {
        if (location.state.deliveryId !== undefined) {
          history.push(`/administration/delivery/${location.state.deliveryId}`, {
            deliveryId: location.state.deliveryId,
            deliveryForm: location.state.deliveryForm,
          });
        } else {
          history.push('/administration/delivery', {
            deliveryForm: DeliveryStore.form,
          });
        }
      },
    });
  }, []);

  return (
    <>
      {
        isOptions.screen === 'mobile' ? (
          <MContentDiv>
            <DeliveryAttributeModify {...props} language={language} />
          </MContentDiv>
        ) : (
          <>
            <MContentDiv>
              <DeliveryModify location={location} setLanguage={setLanguage} />
            </MContentDiv>
            <SubContentDiv style={{ padding: '1rem 0.5rem 0' }}>
              <DeliveryAttributeModify {...props} language={language} />
            </SubContentDiv>
          </>
        )
      }
    </>
  );
});

export default DeliveryAttributePage;
