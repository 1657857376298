import styled from 'styled-components';
import {UiActionSheet} from "@compassplus/ui-mobicash";

export const MobileActionSheet = styled(UiActionSheet)`
  & > div {
    max-width: 50%;
  }
  & > button {
    max-width: 50%;
  }
  
  @media(max-width: 663px) {
     & > div {
    max-width: 100%;
  }
  & > button {
    max-width: 100%;
  }
  }
`;