import PropTypes from 'prop-types';
import cn from 'classnames';
import styled, { css } from 'styled-components';

import ContainerItem from '../../Functional/ContainerItem';
import LeftElement from './Components/LeftElement';
import Description from './Components/Description';
import Input from './Components/Input';
import Notes from './Components/Notes';
import RightElement from './Components/RightElement';
import Flag from './Components/Flag/Flag';

import styles from './UiInput.module.css';

const StyledGroup = styled.div`
  ${ (props) => props.indentTop && css`
    margin-top: 0.25rem;
  ` } 
`;

/**
 * Input component
 * @public
 * @version 0.0.90
 * @param {String} className
 * @param {Boolean} [active=false]
 * @param {Boolean} [disabled=false]
 * @param {Function} onClick
 * @param {Function} onChange
 * @param {Function} onBlur
 * @param {Function} onFocus
 * @param {Boolean} [readOnly=false]
 * @param {Boolean} [warning=false]
 * @param {'text'|'textarea'|'password'|'readonly'} [type=text]
 * @param value
 * @param {JSX.Element} leftElement
 * @param [description=Description]
 * @param {Boolean} [required=false]
 * @param {String} [placeholder=Default]
 * @param {Boolean} [help=false]
 * @param {Function} onHelp
 * @param {String} flag
 * @param notes
 * @param {JSX.Element} rightElement
 * @param {Boolean} [bottomDivider=false]
 * @param {Boolean} [rightDivider=false]
 * @param {Boolean} [oneLiner=false]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiInput />
 */
const UiInput = ({
  className,
  active,
  disabled,
  onClick,
  onChange,
  onBlur,
  onFocus,
  readOnly,
  warning,
  type,
  value,
  leftElement,
  description,
  required,
  placeholder,
  help,
  onHelp,
  flag,
  notes,
  rightElement,
  bottomDivider,
  rightDivider,
  oneLiner,
  innerRef,
  ...props
}) => {

  return (
    <ContainerItem
      className={ className }
      active={ active }
      disabled={ disabled }
      access={ false }
      bottomDivider={ bottomDivider }
      onClick={ onClick }
    >
      { leftElement &&
        <LeftElement leftElement={ leftElement } />
      }
      <div className={ cn(styles.content, notes && styles.notes) }>
        <div className={ styles.frame }>
          <div className={ styles.input }>
            { description &&
              <Description description={ description } required={ required } help={ help } onHelp={ onHelp } />
            }
            { (value !== undefined || type !== 'readonly') &&
              <StyledGroup indentTop={ !!description } className={ styles.group }>
                { flag &&
                  <Flag flag={ flag } />
                }
                <Input
                  { ...props }
                  className={ className }
                  readOnly={ readOnly }
                  disabled={ disabled }
                  placeholder={ placeholder }
                  warning={ warning }
                  type={ type }
                  value={ value }
                  onClick={ onClick }
                  onChange={ onChange }
                  onBlur={ onBlur }
                  onFocus={ onFocus }
                  oneLiner={ oneLiner }
                  innerRef={ innerRef }
                />
              </StyledGroup>
            }
          </div>
          { rightElement &&
            <RightElement rightElement={ rightElement } rightDivider={ rightDivider } />
          }
        </div>
        { notes &&
          <Notes notes={ notes } warning={ warning } />
        }
      </div>
    </ContainerItem>
  );
};

UiInput.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Изменяет цвет фона для активного состояния (выпадающего окна, доп. секции контента и т.п.) */
  active: PropTypes.bool,
  /** Блокирует компонент для изменения */
  disabled: PropTypes.bool,
  /** Обработчик клика на контейнер UiInput */
  onClick: PropTypes.func,
  /** Обработчик на изменение значения value */
  onChange: PropTypes.func,
  /** Обработчик потери фокуса на элементе */
  onBlur: PropTypes.func,
  /** Обработчик получения фокуса на элементе */
  onFocus: PropTypes.func,
  /** Заблокировать поле для изменения */
  readOnly: PropTypes.bool,
  /** Добавляет левый элемент компонента */
  leftElement: PropTypes.element,
  /** Текст (описание) сверху поля ввода */
  description: PropTypes.any.isRequired,
  /** Пометить input как обязательный к заполнению */
  required: PropTypes.bool,
  /** Текст (value) и примечание (notes) становятся красными */
  warning: PropTypes.bool,
  /** Тип элемента */
  type: PropTypes.oneOf(['text', 'textarea', 'password', 'readonly']),
  /** Значение элемента */
  value: PropTypes.any,
  /** Описание на поле ввода */
  placeholder: PropTypes.string.isRequired,
  /** Добавление кнопки помощи */
  help: PropTypes.bool,
  /** Обработчик клика на кнопку помощи */
  onHelp: PropTypes.func,
  /** Устанавливает флаг страны */
  flag: PropTypes.string,
  /** Текст (примечание) снизу поля ввода */
  notes: PropTypes.any,
  /** Добавляет правый элемент компонента */
  rightElement: PropTypes.element,
  /** Добавляет нижнюю разделительную черту */
  bottomDivider: PropTypes.bool,
  /** Добавляет правую разделительную черту */
  rightDivider: PropTypes.bool,
  /** Запрещается перенос текста и добавляет "..." в конце в случае переполнения текста */
  oneLiner: PropTypes.bool,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

UiInput.defaultProps = {
  active: false,
  disabled: false,
  readOnly: false,
  warning: false,
  type: 'text',
  description: 'Description',
  placeholder: 'Default',
  required: false,
  help: false,
  bottomDivider: false,
  rightDivider: false,
  oneLiner: false
};

export default UiInput;