import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { useOptions } from '../../../context/OptionsProvider';

import UiInput from '../../Ui/UiInput';
import UiDropdown from '../../Ui/UiDropdown';
import UiDropdownSheet from '../../Ui/UiDropdownSheet';
import UiSection from '../../Ui/UiSection';
import UiActionSheet from '../../Ui/UiActionSheet';
import UiArrow from '../../Ui/UiArrow';

import { screens } from '../../../constants';

import { StyledDiv } from '../../Ui/UiCard/UiCard';
import { StyledContainerItem } from '../../Functional/ContainerItem/ContainerItem';

const StyledContainer = styled.div`
  ${ (props) => props.relativity && css`
    position: relative;
  ` }
  
  ${ StyledDiv } > &:first-child > ${ StyledContainerItem } {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  ${ StyledDiv } > &:last-child > ${ StyledContainerItem } {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
`;

/**
 * Input sheet component
 * @public
 * @version 0.0.90
 * @param {String} id
 * @param {Object[]} value
 * @param {String} value[].text
 * @param {String} value[].value
 * @param {Object[]} forms
 * @param {String} forms[].text
 * @param {String} forms[].value
 * @param {Function} setForm
 * @param {Object} state
 * @param {String} state.text
 * @param {String} state.value
 * @param {Function} setState
 * @param {Boolean} [required=false]
 * @param {String} [cancelTitle=Cancel]
 * @param {Object} defaultValue
 * @param {String} defaultValue.text
 * @param {String} defaultValue.value
 * @param {Function} onClick
 * @param {Boolean} relativity
 * @param {Boolean} disabled
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiInputSheet />
 */
const UiInputSheet = ({
  id,
  value,
  forms,
  setForm,
  state,
  setState,
  required,
  cancelTitle,
  defaultValue,
  onClick,
  relativity,
  disabled,
  ...props
}) => {

  const isOptions = useOptions();

  const inputValueList = useMemo(() => {
    if (value.length < 2) {
      return [{ text: 'Value 1', value: 'value1' }, { text: 'Value 2', value: 'value2' }];
    } else {
      return value;
    }
  }, [value]);

  const [sheet, setSheet] = useState(false);
  const [inputValue, setInputValue] = useState(() => {
    if (required && value.length > 1) {
      return { text: value[0].text, value: value[0].value };
    } else if (!!defaultValue) {
      return { text: defaultValue.text, value: defaultValue.text };
    } else {
      return { text: '', value: '' };
    }
  });

  useEffect(() => {
    if (forms) {
      const form = forms.find((item) => item.id === id);
      if (!form) {
        if (required && inputValueList.length > 1) {
          setForm(id, { text: value[0].text, value: value[0].value });
        } else if (!!defaultValue) {
          setForm(id, { text: defaultValue.text, value: defaultValue.value });
        } else {
          setForm(id, { text: '', value: '' });
        }
      } else {
        setInputValue({ text: form.text, value: form.value });
      }
    } else if (state) {
      if (state.text !== undefined && state.value !== undefined) {
        setInputValue({ text: state.text, value: state.value });
      } else {
        if (required && inputValueList.length > 1) {
          setState({ text: value[0].text, value: value[0].value });
        } else if (!!defaultValue) {
          setState({ text: defaultValue.text, value: defaultValue.value });
        } else {
          setState({ text: '', value: '' });
        }
      }
    }
  }, [forms, id, setForm, defaultValue, inputValueList, required, setState, state, value]);

  const handlerValue = (value) => {
    if (setForm) {
      setForm(id, { text: value.text, value: value.value });
    } else if (setState) {
      setState({ text: value.text, value: value.value });
    } else {
      setInputValue({ text: value.text, value: value.value });
    }
    setSheet(false);
  }

  return (
    <StyledContainer relativity={ relativity }>
      <UiInput
        { ...props }
        active={ sheet }
        flag={ undefined }
        readOnly={ true }
        disabled={ disabled }
        required={ required }
        value={ inputValue.text }
        onClick={ (event) => {
          event.stopPropagation();

          if (typeof onClick === 'function') {
            onClick();
          }

          setSheet(!sheet);
        } }
        rightElement={ <UiDropdown disabled={ disabled } /> }
      />
      { isOptions.screen === screens.DESKTOP ?
        <UiDropdownSheet
          isOpened={ sheet }
          onClose={ () => setSheet(false) }
        >
          { value.map((item, index) => {
            return <UiSection
              key={ item.value }
              view="link"
              title={ item.text }
              onClick={ () => handlerValue(item) }
              bottomDivider={ index !== value.length - 1 }
            />;
          }) }
        </UiDropdownSheet>
        :
        <UiActionSheet
          isOpened={ sheet }
          cancelClick={ () => setSheet(false) }
          cancelTitle={ cancelTitle }
        >
          { value.map((item, index) => {
            return <UiSection
              key={ item.value }
              view="link"
              title={ item.text }
              onClick={ () => handlerValue(item) }
              rightElement={ <UiArrow /> }
              bottomDivider={ index !== value.length - 1 }
            />;
          }) }
        </UiActionSheet>
      }
    </StyledContainer>
  );
};

UiInputSheet.propTypes = {
  /** Определяет уникальный идентификатор */
  id: PropTypes.string,
  /** Определяет массив объектов возможных значений скролла */
  value: PropTypes.arrayOf(PropTypes.exact({
    text: PropTypes.string,
    value: PropTypes.string
  })),
  /** Определяет массив объектов хука useForm (UI) */
  forms: PropTypes.array,
  /** Функция обработчик хука useForm (UI) */
  setForm: PropTypes.func,
  /** Определяет объект хука useState (React) */
  state: PropTypes.object,
  /** Функция обработчик хука useState (React) */
  setState: PropTypes.func,
  /** Устанавливает текст кнопки отмены */
  cancelTitle: PropTypes.string,
  /** Подставляет 1 элемент массива как дефолтное значение и помечает элемент как обязательный к заполнению */
  required: PropTypes.bool,
  /** Определяет значение по умолчанию */
  defaultValue: PropTypes.exact({
    text: PropTypes.string,
    value: PropTypes.string
  }),
  /** Определяет позиционирование относительно абсолютных компонентов */
  relativity: PropTypes.bool,
  /** Делает компонент неактивным */
  disabled: PropTypes.bool
};

UiInputSheet.defaultProps = {
  value: [{ text: 'Value 1', value: 'value1' }, { text: 'Value 2', value: 'value2' }],
  cancelTitle: 'Cancel',
  required: false,
  relativity: true,
  disabled: false
};

export default UiInputSheet;