import React, { useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { UiTabBlock, UiSimpleInput } from '@compassplus/ui-mobicash';
import { observer } from 'mobx-react-lite';
import {
  ColorInput,
  ColorPickerContainer, DefaultColorsContainer,
} from './styles';
import { useFormInput } from '../../../Utils/Hoocks';
import { alreadyExist } from '../../../Utils/notification';
import i18n from '../../../i18n';
import { Dialog, Text } from '../../TemplateStyle';
import { Tab } from '../../../pages/admin/Authorization/styles';
import { Color } from '../../../Utils/UtilsStyle';
import { defaultColors } from './defaultColors';
import AttributesStore from '../../../stores/AttributesStore';

const NewColor = observer(({ active, setActive }) => {
  const [selectedColor, setSelectedColor] = useState({});
  const [showAdditionalColorsFlag, setShowAdditionalColorsFlag] = useState(false);
  const colors = AttributesStore.modifyAttribute.complexValue;
  const code = useFormInput('');
  const title = { ru: useFormInput(''), en: useFormInput('') };
  const [isUniqueTitle, setIsUniqueTitle] = useState(true);

  const checkNewColorTitleUnique = () => {
    let flag = true;
    AttributesStore.attributes.forEach((attribute) => {
      if (attribute.complexValue) {
        for (const cValue of attribute.complexValue) {
          if (title.ru.value.toLowerCase() === cValue.title.ru.toLowerCase() || title.ru.value.toLowerCase() === cValue.title.en.toLowerCase()
              || title.en.value.toLowerCase() === cValue.title.ru.toLowerCase() || title.en.value.toLowerCase() === cValue.title.en.toLowerCase()) {
            flag = false;
          }
        }
      }
    });
    setIsUniqueTitle(flag);
  }

  const addColor = () => {
    if (((colors.findIndex((el) => el.code.toUpperCase() === code.value.toUpperCase())) === -1) && isUniqueTitle) {
      const newColorComplexValue = {
        code: code.value,
        title: {
          en: title.en.value,
          ru: title.ru.value,
        },
        priceChange: 0,
        priceChangeForm: 'value',
      };

      if (title.ru.value === '' && title.en.value !== '') {
        newColorComplexValue.title.ru = title.en.value;
      }
      if (title.en.value === '' && title.ru.value !== '') {
        newColorComplexValue.title.en = title.ru.value;
      }

      AttributesStore.modifyAttribute.complexValue.push(newColorComplexValue);
      setActive(false);
      title.ru.setValue('');
      title.en.setValue('');
    } else {
      alreadyExist('Color');
    }
  };

  const setColor = (color) => {
    setSelectedColor(color);
    code.setValue(color.code);
    title.ru.setValue(color.title.ru);
    title.en.setValue(color.title.en);
  };

  const renderContent = () => (
    <>
      <Text view="readonly" typography="note" position="center">{i18n.t('StandardColors')}</Text>
      <DefaultColorsContainer>
        {defaultColors.map((color) => (
          <>
            {
              (color.code === selectedColor.code) ? (
                <Color
                  key={color.code}
                  onClick={() => { setColor(color); }}
                  style={{ backgroundColor: color.code, filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2)) drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.4))' }}
                />
              ) : (
                <Color
                  key={color.code}
                  onClick={() => { setColor(color); }}
                  style={{ backgroundColor: color.code }}
                />
              )
            }
          </>
        ))}
      </DefaultColorsContainer>
      <Text
        position="center"
        typography="note"
        view="link"
        style={{ cursor: 'pointer' }}
        onClick={() => { setShowAdditionalColorsFlag(!showAdditionalColorsFlag); }}
      >
        {i18n.t('AdditionalColors')}
      </Text>
    </>
  );

  const renderAdditionalColors = () => (
    <>
      <ColorPickerContainer>
        <HexColorPicker color={code.value} onChange={code.setValue} />
        <ColorInput placeholder="#000000" mask="#******" value={code.value} onChange={code.onChange} />
      </ColorPickerContainer>
      <Text style={{ margin: '0.5rem 0' }} position="center" view="readonly">{i18n.t('Name')}</Text>
      <Tab>
        <UiTabBlock title={i18n.t('RU')}>
          <UiSimpleInput
            style={{ margin: '0 0.5rem' }}
            onChange={(event) => {
              title.ru.setValue(event.target.value);
            }}
            onBlur={checkNewColorTitleUnique}
            warning={!isUniqueTitle}
            value={title.ru.value}
            placeholder="[Текст]"
          />
        </UiTabBlock>
        <UiTabBlock title={i18n.t('EN')} active={i18n.language === 'en'}>
          <UiSimpleInput
            style={{ margin: '0 0.5rem' }}
            onChange={(event) => {
              title.en.setValue(event.target.value);
            }}
            onBlur={checkNewColorTitleUnique}
            warning={!isUniqueTitle}
            value={title.en.value}
            placeholder="[Text]"
          />
        </UiTabBlock>
      </Tab>
    </>
  );

  if (active) {
    return (
      <Dialog
        title={i18n.t('AddColor')}
        text={i18n.t('SelectDesiredColor')}
        buttons={[
          {
            text: i18n.t('Add'),
            mode: 'bold',
            action: () => {
              addColor();
            },
          },
          {
            text: i18n.t('Cancel'),
            mode: '',
            action: () => { setActive(false); },
          },
        ]}
      >
        {renderContent()}
        {showAdditionalColorsFlag && renderAdditionalColors()}
      </Dialog>
    );
  }
  return null;
});

export default NewColor;
