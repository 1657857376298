import PropTypes from 'prop-types';
import cn from 'classnames';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../context/ThemeProvider';

import ContainerItem from '../../Functional/ContainerItem';
import CardSection from './Components/CardSection';
import TableSection from './Components/TableSection';
import PaymentCardSection from './Components/PaymentCardSection';
import Status from './Components/CommonComponents/Status';
import UiRating from '../UiRating';
import Amount from './Components/CommonComponents/Amount';
import PaymentOrder from './Components/CommonComponents/PaymentOrder';
import Text from './Components/CommonComponents/Text';

import styles from './UiCardSection.module.css';

const StyledRating = styled(UiRating)`
  & > div:first-child {
    ${ (props) => props.active && css`
      background: ${ props.styles.colors.textTitle };
      opacity: 0.2;
    ` }
  }

  & > svg {
    ${ (props) => props.active && css`
      fill: ${ props.styles.colors.keyboardBackgroundColor };
    ` }
  }
`;

const StyledStatus = styled(Status)`
  ${ (props) => props.active && (props.status === 'Expired' || props.status === 'Closed' || props.status === 'Deleted') && css`
    color: ${ props.styles.colors.textTitle };
    border-color: ${ props.styles.colors.textTitle }32;
    opacity: 0.2;
  ` }
`;

const StyledAmount = styled(Amount)`
  & {
    ${ (props) => props.active && (props.status === 'Expired' || props.status === 'Closed' || props.status === 'Deleted') && css`
      color: ${ props.styles.colors.textTitle };
      opacity: 0.2;
    ` }
  }
`;

const StyledContainerItem = styled(ContainerItem)`
  &:active ${ StyledRating } > svg {
    fill: ${ (props) => props.styles.colors.keyboardBackgroundColor };
  }

  &:active ${ StyledRating } > div:first-child {
    background: ${ (props) => props.styles.colors.textTitle };
    opacity: 0.2;
  }

  &:active ${ StyledAmount } {
    ${ (props) => (props.status === 'Expired' || props.status === 'Closed' || props.status === 'Deleted') && css`
      color: ${ props.styles.colors.textTitle };
      opacity: 0.2;
    ` }
  }

  &:active ${ StyledStatus } {
    ${ (props) => (props.status === 'Expired' || props.status === 'Closed' || props.status === 'Deleted') && css`
      color: ${ props.styles.colors.textTitle };
      border-color: ${ props.styles.colors.textTitle }32;
      opacity: 0.2;
    ` }
  }
`;

/**
 * Card section component
 * @public
 * @version 0.0.90
 * @param {String} className
 * @param {'card'|'rating'|'amount'|'two-strings'|'one-string'|'marketplace-card'|'merchant'|'payment'|'marketplace-cart-item'|'mobile-customization-card'|'desktop-customization-card'} [type=card]
 * @param {String} [title=Title]
 * @param {String} [description=Description]
 * @param {String} text
 * @param {JSX.Element} leftElement
 * @param {JSX.Element} rightElement
 * @param {Date|String|Number} date
 * @param {Object} currency
 * @param {String} currency.decimalDelimiter
 * @param {String} currency.thousandsDelimiter
 * @param {Number} currency.precision
 * @param {'left', 'right'} currency.signLocation
 * @param {String} currency.sign
 * @param {'Preparing'|'WaitPushTran'|'Authorized'|'PartPaid'|'Funded'|'FullyPaid'|'Expired'|'Cancelled'|'Rejected'|'Refused'|'Closed'|'Active'|'Refunded'|'Payed'|'PaymentFailed'|'Deleted'|'Disputed'|'NeedNewPwd'|'Blocked'} status
 * @param {String} statusText
 * @param {Number} amount
 * @param {Number} control
 * @param {Number} cost
 * @param {Number} rating
 * @param {String} order
 * @param {Boolean} [active=false]
 * @param {Object} favorite
 * @param {Boolean} favorite.active
 * @param {Function} favorite.onClick
 * @param {Boolean} [bottomDivider=false]
 * @param {Function} onClick
 * @param {JSX.Element} elements
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiCardSection />
 */
const UiCardSection = ({
  className,
  type,
  title,
  description,
  text,
  leftElement,
  rightElement,
  date,
  currency,
  status,
  statusText,
  amount,
  control,
  cost,
  rating,
  order,
  active,
  favorite,
  bottomDivider,
  onClick,
  elements,
  ...props
}) => {

  const isTheme = useTheme();

  const content = generateContent();

  return (
    <StyledContainerItem
      { ...props }
      className={ cn(styles.container, className) }
      active={ active }
      status={ status }
      bottomDivider={ bottomDivider }
      onClick={ onClick }
      styles={ isTheme.styles }
    >
      { content }
    </StyledContainerItem>
  );

  function renderSwitch() {
    switch (type) {
      case 'card':
        return <>
          { status &&
            <StyledStatus className={ styles.status } active={ active } status={ status } statusText={ statusText }
              styles={ isTheme.styles } />
          }
          { control &&
            <Text text={ `'${ control }` } />
          }
        </>;
      case 'rating':
        return <>
          { rating &&
            <Text text={ `${ rating }` } />
          }
          { rating &&
            <StyledRating active={ active } cost={ cost } rating={ rating } styles={ isTheme.styles } />
          }
        </>;
      case 'amount':
        return <>
          { status &&
            <StyledStatus className={ styles.status } active={ active } status={ status } statusText={ statusText }
              styles={ isTheme.styles } />
          }
          { currency && amount !== undefined &&
            <StyledAmount active={ active } currency={ currency } status={ status } amount={ amount } type="amount"
              styles={ isTheme.styles } />
          }
        </>;
      case 'two-strings':
        return <></>;
      case 'one-string':
        return <></>;
      case 'marketplace-card':
        return order ?
          <PaymentOrder text={ order } />
          :
          <></>;
      case 'merchant':
        return <>
          { status &&
            <StyledStatus className={ styles.status } active={ active } status={ status } statusText={ statusText }
              styles={ isTheme.styles } /> }
          { currency && amount !== undefined &&
            <StyledAmount currency={ currency } amount={ amount } type="remark" styles={ isTheme.styles } /> }
        </>;
      case 'payment':
        return <>
          { status &&
            <StyledStatus className={ styles.statusPayment } active={ active } status={ status }
              statusText={ statusText } styles={ isTheme.styles } />
          }
          { currency && amount !== undefined &&
            <StyledAmount className={ styles.amountPayment } currency={ currency } status={ status } amount={ amount }
              type="amount" styles={ isTheme.styles } />
          }
        </>;
      case 'marketplace-cart-item':
        return <>
          { status &&
            <StyledStatus className={ styles.status } active={ active } status={ status } statusText={ statusText }
              styles={ isTheme.styles } /> }
          { currency && amount !== undefined &&
            <StyledAmount currency={ currency } amount={ amount } type="section" styles={ isTheme.styles } /> }
        </>;
      case 'mobile-customization-card':
      case 'desktop-customization-card':
        return elements;
      default:
        return undefined;
    }
  }

  function generateContent() {
    const elements = renderSwitch();

    if (type === 'payment') {
      return <PaymentCardSection
        title={ title }
        description={ description }
        text={ text }
        date={ date }
        rightElement={ rightElement }
        elements={ elements }
      />;
    }

    if (type === 'desktop-customization-card') {
      return <TableSection
        title={ title }
        description={ description }
        leftElement={ leftElement }
        rightElement={ rightElement }
        elements={ elements }
      />;
    }

    return <CardSection
      type={ type }
      title={ title }
      description={ description }
      text={ text }
      date={ date }
      favorite={ favorite }
      leftElement={ leftElement }
      rightElement={ rightElement }
      elements={ elements }
    />;
  }
};

UiCardSection.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Тип карты раздела */
  type: PropTypes.oneOf([
    'card',
    'rating',
    'amount',
    'two-strings',
    'one-string',
    'marketplace-card',
    'merchant',
    'payment',
    'marketplace-cart-item',
    'mobile-customization-card',
    'desktop-customization-card'
  ]),
  /** Основная текстовая надпись на карте раздела */
  title: PropTypes.string,
  /** Дополнительная текстовая надпись на карте раздела */
  description: PropTypes.string,
  /** Дополнительная текстовая надпись слева от даты */
  text: PropTypes.string,
  /** Дата отображаемая в карте раздела */
  date: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.number
  ]),
  /** Левый компонент карты раздела */
  leftElement: PropTypes.element,
  /** Правый компонент карты раздела */
  rightElement: PropTypes.element,
  /** Справочник выбранной валюты */
  currency: PropTypes.shape({
    decimalDelimiter: PropTypes.string,
    thousandsDelimiter: PropTypes.string,
    precision: PropTypes.number,
    signLocation: PropTypes.oneOf(['left', 'right']),
    sign: PropTypes.string
  }),
  /** Статус счета */
  status: PropTypes.oneOf([
    // TXPG
    'Preparing', 'WaitPushTran', 'Authorized', 'PartPaid', 'Funded', 'FullyPaid', 'Expired', 'Cancelled', 'Rejected', 'Refused', 'Closed',
    // MobiCash
    'Active', 'Refunded', 'Payed', 'PaymentFailed', 'Deleted', 'Disputed',
    // LoginStatus
    'NeedNewPwd', 'Blocked'
  ]),
  /** Текст статуса счета */
  statusText: PropTypes.string,
  /** Сумма счета */
  amount: PropTypes.number,
  /** Контрольные цифры номера карты */
  control: PropTypes.number,
  /** Определяет стоимость одной звезды рейтинга */
  cost: PropTypes.number,
  /** Цифровое значение рейтинга */
  rating: PropTypes.number,
  /** Текстовая надпись платежного поручения */
  order: PropTypes.string,
  /** Изменяет цвет фона для активного состояния (выпадающего окна, доп. секции контента и т.п.) */
  active: PropTypes.bool,
  /** Помечает карту раздела как выбранную */
  favorite: PropTypes.exact({
    active: PropTypes.bool,
    onClick: PropTypes.func
  }),
  /** Добавляет нижнюю разделительную черту */
  bottomDivider: PropTypes.bool,
  /** Функция обработчик клика по карте раздела */
  onClick: PropTypes.func,
  /** Определяет пользовательское наполнение карты раздела */
  elements: PropTypes.any
};

UiCardSection.defaultProps = {
  type: 'card',
  title: 'Title',
  description: 'Description',
  active: false,
  bottomDivider: false
};

export default UiCardSection;