import React, { useEffect, useState } from 'react';
import {
  useOptions, useDate, UiCard, UiInput, UiSubsection,
} from '@compassplus/ui-mobicash';
import ModalStore from '../../../stores/ModalStore';
import OrderCard from '../../admin/OrdersPage/OrderCard';
import i18n from '../../../i18n';
import ShoppingCartItem from '../../../components/market/ShoppingCartItem';

const OrderData = ({ orderId }) => {
  const [order, setOrder] = useState(null);
  const [, { validateDateFormat }] = useDate();
  const isOptions = useOptions();
  useEffect(() => {
    fetch(`/api/v1/public/getOrder/${orderId}`, {
      method: 'GET',
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        ModalStore.openUnexpectedErrorModal();
      })
      .then((res) => {
        if (res) {
          setOrder(res);
        } else {
          ModalStore.openUnexpectedErrorModal();
        }
      });
  }, [orderId]);

  const renderItemsList = () => (
    <UiSubsection
      style={{ margin: '0.75rem 0 0.25rem' }}
      title={i18n.t('ItemList')}
    >
      {order.items.map((item, index) => (
        <React.Fragment key={item.id}>
          <ShoppingCartItem
            style={{ margin: '0.5rem 0 0' }}
            product={item}
            index={index}
            shadow
            disabled
          />
        </React.Fragment>
      ))}
    </UiSubsection>
  );

  const renderDeliveryData = () => {
    const deliveryType = order.deliveryData.type;
    const { deliveryDate } = order.deliveryData;
    const deliveryAddress = order.deliveryData.addressFields;
    const { entryField } = order.deliveryData;

    return (
      <UiSubsection
        style={{ margin: '0.75rem 0 0.25rem' }}
        title={i18n.t('Delivery')}
      >
        <UiCard
          style={{ margin: '0.5rem 0 0' }}
          shadow
        >
          {deliveryType ? (
            <UiInput
              type="readonly"
              color="#037AFF"
              description={i18n.t('DeliveryMethod')}
              value={deliveryType.Title}
              bottomDivider={deliveryAddress !== undefined || deliveryDate !== undefined || entryField !== undefined}
              disabled={order.status === 'Deleted'}
              readOnly
            />
          ) : null}
          {deliveryAddress ? (
            <UiInput
              type="readonly"
              color="#037AFF"
              description={i18n.t('Address')}
              value={deliveryAddress.map((el) => el.Value).join(', ')}
              bottomDivider={deliveryDate !== undefined || entryField !== undefined}
              disabled={order.status === 'Deleted'}
              readOnly
            />
          ) : null}
          {deliveryDate ? (
            <UiInput
              description={i18n.t('DeliveryDate')}
              defaultValue={validateDateFormat(deliveryDate, isOptions.language).dateString}
              value={validateDateFormat(deliveryDate, isOptions.language).dateString}
              bottomDivider={entryField !== undefined}
              disabled={order.status === 'Deleted'}
              readOnly
              type="readonly"
            />
          ) : null}
          {entryField ? (
            <UiInput
              type="readonly"
              color="#037AFF"
              description={isOptions.language === 'ru' ? entryField.Title : entryField.Name}
              value={entryField.Value}
              readOnly
            />
          ) : null}
        </UiCard>
      </UiSubsection>
    );
  };

  const renderOrderAmount = () => (
    <UiSubsection
      style={{ margin: '0.75rem 0 0.5rem' }}
      title={i18n.t('OrderAmount')}
    >
      <UiCard
        style={{ margin: '0.5rem 0' }}
        shadow
      >
        <UiInput
          description={i18n.t('OrderAmount')}
          value={(order.cartTotal / 100).toFixed(2)}
          readOnly
          bottomDivider
          type="readonly"
        />
        <UiInput
          description={i18n.t('CostOfDelivery')}
          value={order.deliveryCost !== 0 ? (order.deliveryCost / 100).toFixed(2) : i18n.t('Free')}
          readOnly
          bottomDivider
          type="readonly"
        />
        <UiInput
          description={i18n.t('TOTAL')}
          value={(order.amount).toFixed(2)}
          readOnly
          type="readonly"
        />
      </UiCard>
    </UiSubsection>
  );

  if (order) {
    return (
      <>
        <OrderCard
          style={{ margin: ' 0 0 0.25rem', position: 'relative' }}
          order={order}
        />
        {renderItemsList()}
        {renderDeliveryData()}
        {renderOrderAmount()}
      </>
    );
  }
  return null;
};

export default OrderData;
