import { useMemo, useState } from 'react';

import { useOptions } from '../context/OptionsProvider';

import { dateParser, validateDate, validateDateFormat } from '../utils/dateUtils';

/**
 * Кастомных хук для работы с датой
 * @param {Date|String|Number|Function} initialValue
 * @return {[{date: Date, valueFormat: {string: string, timeString: string, dateString: string}, value: string},{dateParser: function((Date|String|Number)): Date, setDate: function((Date|String|Number)): void, validateDateFormat: function((Date|String|Number), String): {string: string, timeString: string, dateString: string}, validateDate: function((Date|String|Number)): string}]}
 */
const useDate = (initialValue = new Date()) => {

  const { language } = useOptions();

  const [state, setState] = useState({
    value: validateDate(initialValue),
    valueFormat: validateDateFormat(initialValue, language),
    date: dateParser(initialValue)
  });

  const action = useMemo(() => {
    /**
     * Устанавливает дату
     * @public
     * @version 1.4.0
     * @param {Date|String|Number} date
     * @constructor
     * @example
     * import { useEffect } from 'react';
     *
     * import { useDate } from '@compassplus/ui-mobicash';
     *
     * const App = () => {
     *   const [date, { setDate }] = useDate();
     *
     *   useEffect(() => {
     *     console.log(date);
     *   }, [date]);
     *
     *   useEffect(() => {
     *     setDate(new Date());
     *   }, [setDate]);
     * }; */
    function setDate(date) {
      setState((state) => {
        const nextState = {
          value: validateDate(date),
          valueFormat: validateDateFormat(date, language),
          date: dateParser(date)
        };

        if (nextState.value !== state.value) {
          return nextState;
        }

        return state;
      });
    }

    return { setDate, validateDate, validateDateFormat, dateParser };
  }, [language]);

  return [state, action];
};

export default useDate;