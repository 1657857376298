import React from 'react';
import {Text} from "../../../../components/TemplateStyle";
import {CategoryImageCard} from "../style";
import MarketStore from "../../../../stores/MarketStore";

const MarketCurrentGroupCard = () => {
  const { currentGroup, currentGroups } = MarketStore;
  return (
    <>
      <Text typography="subsection-title" style={{ margin: '0.625rem 0 0' }} view="readonly">
        {currentGroup.title}
      </Text>
      <Text typography="note" view="readonly">
        {currentGroup.description?.length > 80 ? `${currentGroup.description.substr(0, 80)}...` : currentGroup.description}
      </Text>
      <CategoryImageCard style={currentGroups.length > 0 ? { margin: '0.625rem 0 0' } : {}}>
        <img src={currentGroup.image.url} />
      </CategoryImageCard>
    </>
  );
};

export default MarketCurrentGroupCard