import fonts from './fonts';
import frames from './frames';

const typography = {
  InputStyle: {
    ...fonts.input,
  },
  MemPadDigit: {
    ...fonts.regular,
    ...frames.level_7,
    'line-height': '2rem',
  },
  TopNavbarTitle: {
    ...fonts.semibold,
    ...frames.level_6,
    'line-height': '1.5rem',
  },
  CTAButton: {
    ...fonts.regular,
    ...frames.level_6,
    'line-height': '3rem',
  },
  CTAButtonBold: {
    ...fonts.semibold,
    ...frames.level_6,
    'line-height': '3rem',
  },
  SectionValue: {
    ...fonts.regular,
    ...frames.level_5,
    'line-height': '1.25rem',
  },
  SectionValueBold: {
    ...fonts.bold,
    ...frames.level_5,
    'line-height': '1.25rem',
  },
  DialogBoldText: {
    ...fonts.bold,
    ...frames.level_5,
    'line-height': '1.125rem',
  },
  SectionTitle: {
    ...fonts.semibold,
    ...frames.level_4,
    'line-height': '1.25rem',
  },
  SectionSecondString: {
    ...fonts.regular,
    'font-size': '0.688rem',
    'line-height': '1rem',
  },
  Keyboard: {
    ...fonts.regular,
    ...frames.level_4,
    'line-height': '1.125rem',
  },
  SectionDescriptionBold: {
    ...fonts.bold,
    ...frames.level_4,
    'line-height': '1rem',
  },
  SectionDescriptionSemibold: {
    ...fonts.semibold,
    ...frames.level_3,
    'line-height': '1rem',
  },
  SectionAmount: {
    ...fonts.regular,
    ...frames.level_3,
    'line-height': '1rem',
  },
  Amount: {
    ...fonts.bold,
    ...frames.level_4,
    'line-height': '1rem',
  },
  DialogTitle: {
    ...fonts.bold,
    ...frames.level_4,
    'line-height': '1.125rem',
  },
  SubsectionTitle: {
    ...fonts.semibold,
    ...frames.level_3,
    'line-height': '1rem',
    'text-transform': 'uppercase',
  },
  DialogButtonBold: {
    ...fonts.bold,
    ...frames.level_3,
    'line-height': '2rem',
  },
  DialogButton: {
    ...fonts.regular,
    ...frames.level_3,
    'line-height': '2rem',
  },
  TabsNotActive: {
    ...fonts.regular,
    ...frames.level_3,
    'line-height': '1.75rem',
  },
  TabsActive: {
    ...fonts.semibold,
    ...frames.level_3,
    'line-height': '1.75rem',
  },
  DialogText: {
    ...fonts.regular,
    ...frames.level_3,
    'line-height': '1.125rem',
  },
  SectionDescription: {
    ...fonts.regular,
    ...frames.level_3,
    'line-height': '1rem',
  },
  Note: {
    ...fonts.regular,
    ...frames.level_3,
    'line-height': '1rem',
  },
  DashboardDescription: {
    ...fonts.regular,
    ...frames.level_2,
    'line-height': '0.75rem',
  },
  DashboardName: {
    ...fonts.bold,
    ...frames.level_1,
    'line-height': '0.75rem',
  },
  Remark: {
    ...fonts.regular,
    ...frames.level_1,
    'line-height': '0.625rem',
  },
  Badge: {
    ...fonts.bold,
    ...frames.level_1,
    'line-height': '0.75rem',
    'text-transform': 'uppercase',
  }
};

export default typography;