import React from 'react';
import Statuses from './Statuses';
import DeliveryType from './DeliveryType';
import OrderAddressData from './OrderAddressData';
import OrderDeliveryDate from './OrderDeliveryDate';
import OrderPersonalData from './OrderPersonalData';
import OrderPhoneNumber from './OrderPhoneNumber';
import OrderEntryField from "./OrderEntryField";
import OrderBirthday from "./OrderBirthday";

const ModifyOrderData = ({ modifyingOrderData }) => {
  switch (modifyingOrderData) {
    case 'status':
      return (
        <Statuses />
      );
    case 'deliveryType':
      return (
        <DeliveryType />
      );
    case 'address':
      return (
        <OrderAddressData />
      );
    case 'deliveryDate':
      return (
        <OrderDeliveryDate />
      );
    case 'entryField':
      return (
        <OrderEntryField />
      );
    case 'personalData':
      return (
        <OrderPersonalData />
      );
    case 'phoneNumber':
      return (
        <OrderPhoneNumber />
      );
    case 'birthday':
      return (
        <OrderBirthday />
      );
    default: return null;
  }
};

export default ModifyOrderData;
