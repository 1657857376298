import React, { useState, useEffect } from 'react';
import { useOptions } from '@compassplus/ui-mobicash';
import { useHistory, useParams } from 'react-router';
import i18n from '../../../i18n';
import { getTitle } from '../../../Utils/requestUtil';

import { MContentDiv } from '../../../components/TemplateStyle';
import GlobalStateStore from '../../../stores/GlobalStateStore';

import Main from './Main';
import ActionsIcon from "../../../components/icons/ActionsIcon";
import AdminMarketStore from "../../../stores/AdminMarketStore";

const MainPage = (props) => {
  const params = useParams();
  const isOptions = useOptions();
  const [isCategory, setIsCategory] = useState(false);
  const history = useHistory();

  const [groupId, setGroupId] = useState(params.id || props.groupId);

  const getHeaderTitle = () => {
    if (getTitle() !== undefined && getTitle() !== null && getTitle() !== '') {
      return getTitle(isOptions.language);
    }

    return i18n.t('Catalog');
  };

  useEffect(() => {
    if (!AdminMarketStore.isCMS) {
      GlobalStateStore.setHeaderProperties({
        title: getHeaderTitle(),
        isBack: isCategory,
        onBack: isCategory ? () => history.goBack() : undefined,
        renderRightElement: () => (
          <ActionsIcon
            onClick={() => groupId ? history.push(`/select/${groupId}`) : history.push('/select')}
          />
        ),
      });
    } else {
      GlobalStateStore.setHeaderProperties({
        title: getHeaderTitle(),
        isBack: isCategory,
        onBack: isCategory ? () => history.goBack() : undefined,
      });
    }
  }, [isCategory]);

  useEffect(() => {
    setGroupId(params.id);
    if (params.id !== undefined) {
      setIsCategory(true);
    } else {
      setIsCategory(false);
    }
  }, [params]);

  return (
    <>
      <MContentDiv>
        <Main
          {...props}
          groupId={groupId}
          isShareLinkOpen={GlobalStateStore.isShareLinkOpen}
          isMarketShareLinkOpen={GlobalStateStore.isMarketShareLinkOpen}
        />
      </MContentDiv>
    </>
  );
};

export default MainPage;
