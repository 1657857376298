import PropTypes from 'prop-types';
import cn from 'classnames';
import styled from 'styled-components';

import { useTheme } from '../../../context/ThemeProvider';

import UiContainer from '../UiContainer';

import styles from './UiFrame.module.css';

const StyledAdditionalLeftContainer = styled(UiContainer)`
  box-shadow: ${ (props) => props.styles.shadows.leftFrame };
`;

const StyledAdditionalRightContainer = styled(UiContainer)`
  box-shadow: ${ (props) => props.styles.shadows.rightFrame };
`;

/**
 * Three column container
 * @public
 * @version 0.0.99
 * @param {String} className
 * @param {JSX.Element} primaryContent
 * @param {JSX.Element} secondaryContent
 * @param {JSX.Element} tertiaryContent
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiFrame />
 */
const UiFrame = ({
  className,
  primaryContent,
  secondaryContent,
  tertiaryContent,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <div
      { ...props }
      className={ cn(styles.frame, className) }
    >
      { primaryContent &&
        <StyledAdditionalLeftContainer
          className={ styles.primary }
          paddingTop="0"
          paddingRight="0.5rem"
          paddingBottom="0"
          paddingLeft="0.5rem"
          styles={ isTheme.styles }
        >
          <UiContainer className={ styles.container } overflow={ true } paddingTop="1.5rem">
            { primaryContent }
          </UiContainer>
        </StyledAdditionalLeftContainer>
      }
      <UiContainer
        className={ styles.secondary }
        paddingTop="0"
        paddingRight="0.5rem"
        paddingBottom="0"
        paddingLeft="0.5rem"
      >
        <UiContainer className={ styles.container } overflow={ true } paddingTop="1.5rem">
          { secondaryContent }
        </UiContainer>
      </UiContainer>
      { tertiaryContent &&
        <StyledAdditionalRightContainer
          className={ styles.tertiary }
          paddingTop="0"
          paddingRight="0.5rem"
          paddingBottom="0"
          paddingLeft="0.5rem"
          styles={ isTheme.styles }
        >
          <UiContainer className={ styles.container } overflow={ true } paddingTop="1.5rem">
            { tertiaryContent }
          </UiContainer>
        </StyledAdditionalRightContainer>
      }
    </div>
  );
};

UiFrame.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Определяет пользовательский контент левой части контейнера */
  primaryContent: PropTypes.any,
  /** Определяет пользовательский контент центральной части контейнера */
  secondaryContent: PropTypes.any,
  /** Определяет пользовательский контент правой части контейнера */
  tertiaryContent: PropTypes.any
};

export default UiFrame;