import React, { useEffect, useState, useReducer } from 'react';
import { observer } from 'mobx-react-lite';
import {
  UiCard, UiSection, UiCheckbox, useOptions, UiButton,
} from '@compassplus/ui-mobicash';
import { useHistory } from 'react-router';
import { action } from 'mobx';
import AttributesStore from '../../../stores/AttributesStore';
import CountInputs from './components/CountInputs';
import { SubContentStiky } from '../ModifyAttributePage/style';
import i18n from '../../../i18n';
import ItemStore from '../../../stores/ItemStore';
import { Text } from '../../../components/TemplateStyle';

const CountDependencies = observer(() => {
  const history = useHistory();
  const isOptions = useOptions();
  const [attributesWithDependencies, setAttributesWithDependencies] = useState([]);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [separatedCountAttributes, setSeparatedCountAttributes] = useState(AttributesStore.getSeparatedCountAttributes());

  useEffect(() => {
    const attributes = [...AttributesStore.getAttributesByType('radio'), ...AttributesStore.getAttributesByType('color')];
    if (attributes.length === 0) {
      history.goBack();
    }
    setAttributesWithDependencies(attributes);
  }, []);

  return (
    <>
      <Text
        typography="note"
        view="readonly"
        style={{ fontWeight: 600, textTransform: 'uppercase' }}
      >

        {i18n.t('AttributesWithDependenciesMessage')}
      </Text>
      <UiCard style={{ margin: '0.25rem 0' }} shadow>
        {
          attributesWithDependencies && attributesWithDependencies.map((attribute, index) => (
            <UiSection
              key={`${attribute.title.en}-${index}`}
              title={attribute.title[isOptions.language]}
              leftElement={(
                <UiCheckbox
                  checked={attribute.isSeparateCount}
                />
              )}
              onClick={action(() => {
                attribute.setIsSeparateCount(!attribute.isSeparateCount);
                setSeparatedCountAttributes(AttributesStore.getSeparatedCountAttributes());
                AttributesStore.setDefaultCountDependencies();
                forceUpdate();
              })}
              bottomDivider={index !== attributesWithDependencies.length - 1}
            />
          ))
        }
      </UiCard>
      <CountInputs separatedCountAttributes={separatedCountAttributes} />
      <SubContentStiky>
        <UiButton
          title={i18n.t('Save')}
          onClick={action(() => {
            AttributesStore.getAttributesTotalAmount();
            AttributesStore.isModified = true;
            ItemStore.fullAttributeNameCountDependencies = AttributesStore.fullAttributeNameCountDependencies;
            ItemStore.countDependencies = AttributesStore.countDependencies;
            history.goBack();
          })}
        />
      </SubContentStiky>
    </>
  );
});

export default CountDependencies;
