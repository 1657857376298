import React, { useEffect, useState } from 'react';
import {
  UiArrow, UiCard, UiIcon, UiSection,
} from '@compassplus/ui-mobicash';
import {
  getCashierAuth, getCashierLogin, getTitle, request,
} from '../../../../../Utils/requestUtil';
import i18n from '../../../../../i18n';
import { Input } from '../../../ModifyGroupPage/style';
import LinkSettings from '../../../../../components/Modals/LinkSettings';
import { Text } from '../../../../../components/TemplateStyle';
import Merchant from "../../../../../stores/Merchant";
import {observer} from "mobx-react-lite";

const MarketLinkSettings = observer(() => {
  const [linkSettingsOpen, setLinkSettingsOpen] = useState(false);

  useEffect(async () => {
    if (getCashierAuth()) {
      await Merchant.getLinks();
    }
  }, [getCashierAuth()]);

  return (
    <>
      <Text
        view="readonly"
        typography="note"
      >
        {i18n.t('MarketLink')}
      </Text>
      <UiCard style={{ margin: '0.25rem 0' }}>
        <Input
          description={i18n.t('LinkSettings')}
          value={Merchant.fullMarketUrl}
          readOnly
          rightElement={(
            <UiIcon
              icon="legacy-edit"
              setColor="#037AFF"
              onClick={() => {
                setLinkSettingsOpen(true);
              }}
            />
          )}
        />
      </UiCard>
      <LinkSettings active={linkSettingsOpen} setActive={setLinkSettingsOpen} />
    </>
  );
});

export default MarketLinkSettings;
