import React from 'react';
import { Route } from 'react-router-dom';
import MarketPageContainer from "../../components/PageContainers/MarketPageContainer";

function MarketRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <MarketPageContainer>
          <Component {...props} />
        </MarketPageContainer>
      )}
    />
  );
}

export default MarketRoute;
