(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@compassplus/js-utils"), require("classnames"), require("libphonenumber-js"), require("prop-types"), require("react"), require("react-dom"), require("styled-components"));
	else if(typeof define === 'function' && define.amd)
		define(["@compassplus/js-utils", "classnames", "libphonenumber-js", "prop-types", "react", "react-dom", "styled-components"], factory);
	else if(typeof exports === 'object')
		exports["@compassplus/ui-mobicash"] = factory(require("@compassplus/js-utils"), require("classnames"), require("libphonenumber-js"), require("prop-types"), require("react"), require("react-dom"), require("styled-components"));
	else
		root["@compassplus/ui-mobicash"] = factory(root["@compassplus/js-utils"], root["classnames"], root["libphonenumber-js"], root["prop-types"], root["react"], root["react-dom"], root["styled-components"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__compassplus_js_utils__, __WEBPACK_EXTERNAL_MODULE_classnames__, __WEBPACK_EXTERNAL_MODULE_libphonenumber_js__, __WEBPACK_EXTERNAL_MODULE_prop_types__, __WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE_react_dom__, __WEBPACK_EXTERNAL_MODULE_styled_components__) {
return 