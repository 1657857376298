import React, { useReducer } from 'react';
import { TableInput } from './style';
import AttributesStore from '../../../stores/AttributesStore';
import { action } from 'mobx';

const AttributeTableCell = ({ fullAttributeName }) => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const onInputChange = action((event) => {
    event.target.value = event.target.value.replace(/[^0-9]/g, '');
    AttributesStore.getArrValue(fullAttributeName).max = event.target.value;
    forceUpdate();
  })

  const onInputBlur = action((event) => {
    if (event.target.value === '') {
      AttributesStore.getArrValue(fullAttributeName).max = 0;
    } else {
      AttributesStore.getArrValue(fullAttributeName).max = Number(event.target.value)
    }
    forceUpdate();
  })

  return (
    <React.Fragment key={fullAttributeName}>
      <td>
        <TableInput
          value={AttributesStore.getArrValue(fullAttributeName)?.max}
          onChange={onInputChange}
          onBlur={onInputBlur}
        />
      </td>
    </React.Fragment>
  )
}

export default AttributeTableCell;