import React, { useReducer } from 'react';
import { observer } from 'mobx-react-lite';
import {
  UiCard, UiInput, useOptions,
} from '@compassplus/ui-mobicash';
import { action } from 'mobx';
import i18n from '../../../../i18n';
import DeliveryStore from '../../../../stores/DeliveryStore';
import OrderStore from '../../../../stores/OrderStore';
import {AnimationDiv, Text} from "../../../../components/TemplateStyle";

const AddressData = observer(() => {
  const isOptions = useOptions();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  return (
    <>
      {
        OrderStore.selectedDelivery.addressData && (
          <AnimationDiv>
            <Text style={{ textTransform: 'uppercase', margin: '0.5rem 0 0' }} view="readonly" typography="section-description-semibold">
              {i18n.t('DeliveryAddress')}
            </Text>
            <UiCard style={{ margin: '0.5rem 0 0' }} shadow>
              {OrderStore.selectedDelivery.addressData.fields.map((field, index) => (
                <UiInput
                  key={`${field.title.en}-${index}`}
                  description={field.title[isOptions.language]}
                  required={field.required}
                  value={field.value[isOptions.language]}
                  placeholder={i18n.t(`[${i18n.t('Text')}]`)}
                  onChange={action((event) => { field.setValue(isOptions.language, event.target.value); forceUpdate(); })}
                  bottomDivider={index !== DeliveryStore.form.addressData.fields.length - 1}
                />
              ))}
            </UiCard>
          </AnimationDiv>
        )
      }
    </>
  );
});

export default AddressData;
