import styled from 'styled-components';

import { useTheme } from '../../../context/ThemeProvider';

import styles from './RightDivider.module.css';

const Divider = styled.div`
  background: ${ (props) => props.styles.shadows.divider };
`;

const RightDivider = () => {

  const isTheme = useTheme();

  return (
    <Divider className={ styles.divider } styles={ isTheme.styles } />
  );
}

export default RightDivider;