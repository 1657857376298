import {makeAutoObservable, runInAction} from 'mobx';
import {getMerchantId, request} from "../Utils/requestUtil";
import ModalStore from "./ModalStore";
import i18n from "../i18n";

const defaultActivePeriods = {
  daysOfWeek: {
    status: false,
    days: [
      {
        code: 1,
        title: 'Monday',
        checked: false,
      },
      {
        code: 2,
        title: 'Tuesday',
        checked: false,
      },
      {
        code: 3,
        title: 'Wednesday',
        checked: false,
      },
      {
        code: 4,
        title: 'Thursday',
        checked: false,
      },
      {
        code: 5,
        title: 'Friday',
        checked: false,
      },
      {
        code: 6,
        title: 'Saturday',
        checked: false,
      },
      {
        code: 0,
        title: 'Sunday',
        checked: false,
      }]
  },
  dates: {
    status: false,
    subtype: 'period',
    periods: [
      {
        from: new Date((new Date()).getTime()).toISOString().split('T')[0],
        to: new Date((new Date()).getTime()).toISOString().split('T')[0]
      }
    ],
    dates: [
      new Date((new Date()).getTime()).toISOString().split('T')[0],
    ]
  },
  time: {
    status: false,
    periods: [
      {
        from: '12:00',
        to: '14:00'
      }
    ]
  }
};

class CategoryStore {
  isLoading = false;
  modeIsModify = false;
  merchantGuid = getMerchantId();
  currentGroup = null;
  type = '';
  title = { en: '', ru: '' };
  description = { en: '', ru: '' };
  status = 'Active';
  parentGroup = null;
  image = null;
  imageType = 'url';
  activePeriods = defaultActivePeriods;

  items = [];
  subgroups = [];

  constructor() {
    makeAutoObservable(this);
  }

  setGroup = (groupId) => {
    this.isLoading = true;
    request(`/api/v1/group/${groupId}`, 'GET').then(
      (response) => {
        runInAction(() => {
          this.isLoading = false;
          if (response.group && response.parentGroup) {
            this.modeIsModify = true;
            this.currentGroup = response.group;
            this.type = groupId;
            this.merchantGuid = response.group.merchantGuid;
            this.title = response.group.title;
            this.description = response.group.description;
            this.status = response.group.status ? 'Active' : 'Inactive'
            this.parentGroup = { key: response.parentGroup._id, value: response.parentGroup.title };
            this.image = response.group.image.url;
            this.imageType = 'url';
            if (response.group.activePeriods) {
              this.activePeriods = response.group.activePeriods;
            } else {
              this.activePeriods = defaultActivePeriods
            }
          }
        });
      },
      (error) => {
        ModalStore.openErrorWithMessage(i18n.t(error));
        runInAction(() => {
          this.isLoading = false;
        });
      },
    );
  }

  clear = () => {
    this.isLoading = false;
    this.modeIsModify = false;
    this.merchantGuid = getMerchantId();
    this.currentGroup = null;
    this.type = '';
    this.title = { en: '', ru: '' };
    this.description = { en: '', ru: '' };
    this.status = 'Active';
    this.parentGroup = null;
    this.image = null;
    this.imageType = 'url';
    this.activePeriods = defaultActivePeriods;

    this.items = [];
    this.subgroups = [];
  }

  getSelectedActivePeriods = () => {
    let result = '';
    if (this.activePeriods) {
      Object.keys(this.activePeriods).forEach((key) => {
        if (this.activePeriods[key].status) {
          result += `${i18n.t(key)} `;
        }
      })
    }
    return result;
  }

  checkActivePeriods = (periods) => {
    return checkDaysOfTheWeek(periods.daysOfWeek) && checkDates(periods.dates) && checkTime(periods.time)
  }
}

const checkDaysOfTheWeek = (daysOfWeek) => {
  if (daysOfWeek.status) {
    const evenOneDaySelected = daysOfWeek.days.some((day) => day.checked)
    return evenOneDaySelected;
  }
  return true;
}

const checkDates = (dates) => {
  if (dates.status) {
    if (dates.subtype === 'periods') {
      const everyPeriodFilled = dates.periods.every((period) => period.from !== '' && period.to !== '');
      return everyPeriodFilled;
    }
    if (dates.subtype === 'dates') {
      const everyDateFilled = dates.dates.every((date) => date !== '');
      return everyDateFilled;
    }
  }
  return true;
}

const checkTime = (time) => {
  if (time.status) {
    const everyPeriodFilled = time.periods.every((period) => period.from !== '' && period.to !== '');
    return everyPeriodFilled;
  }
  return true
}

export default new CategoryStore();
