import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { action } from 'mobx';
import { MContentDiv } from '../../../components/TemplateStyle';
import GlobalStateStore from '../../../stores/GlobalStateStore';
import ShoppingCart from './ShoppingCart';
import ShoppingCartStore from '../../../stores/ShoppingCartStore';

const ShoppingCartPage = observer(() => {
  useEffect(action(() => {
    GlobalStateStore.isMarket = true;
    GlobalStateStore.sidebarClassName = 'upper';
  }), [ShoppingCartStore.cart]);

  return (
    <>
      <MContentDiv>
        <ShoppingCart />
      </MContentDiv>
    </>
  );
});

export default ShoppingCartPage;
