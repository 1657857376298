import { useRef, useState } from 'react';
import cn from 'classnames';
import styled, { css } from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import { useTheme } from '../../../../context/ThemeProvider';

import styles from './SubsectionContainer.module.css';

const StyledContainer = styled.div`
  ${ (props) => css`
    ${ props.styles.typography.Note };
    color: ${ props.styles.colors.textReadOnly };
  ` }
`;

const SubsectionContainer = ({ active, children }) => {

  const isTheme = useTheme();

  const [height, setHeight] = useState(0);
  const containerRef = useRef(null);

  const getTransitionClass = (state) => {
    return state === 'entering' ?
      styles['activity']
      :
      state === 'entered' ?
        cn(styles['container'], styles['active'])
        :
        state === 'exiting' ?
          styles['activity']
          :
          styles['container']
  }

  const onEntering = () => {
    containerRef.current && setHeight(containerRef.current.scrollHeight);
  };

  const onEntered = () => {
    setHeight(0);
  };

  const onExit = () => {
    containerRef.current && setHeight(containerRef.current.scrollHeight)
  };

  const onExiting = () => {
    setHeight(0);
  };

  const onExited = () => {
    setHeight(0);
  };

  return (
    <CSSTransition
      in={ active }
      nodeRef={ containerRef }
      onEntering={ onEntering }
      onEntered={ onEntered }
      onExit={ onExit }
      onExiting={ onExiting }
      onExited={ onExited }
      timeout={ 500 }
    >
      { (state) => {
        const transitionClass = getTransitionClass(state);

        return (
          <StyledContainer
            className={ transitionClass }
            style={ height === 0 ? null : { height } }
            ref={ containerRef }
            styles={ isTheme.styles }
          >
            { children }
          </StyledContainer>
        );
      } }
    </CSSTransition>
  );
}

export default SubsectionContainer;