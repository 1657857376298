import {action, makeAutoObservable, runInAction} from 'mobx';
import i18n from "../i18n";
import Merchant from "./Merchant";
import ShoppingCart from "./ShoppingCartStore";
import {getCurrency, removeCart} from "../Utils/requestUtil";
import DeliveryFormClass from "../controllers/delivery/DeliveryFormClass";
import ModalStore from "./ModalStore";

// Ссылка на eInvoicing
import eInvoicingURL from "../eInvoicingURL";
import MerchantGuidsWithoutEInvoicingRedirecting from "../WebasystConfig";

class OrderStore {
  isLoading = false;
  deliverySettingsArr = [];
  selectedDeliveryType = -1;
  selectedPoint = -1;
  deliveryCostObj = { price: 0, dependence: { deliveryPrice: 0 }, message: i18n.t('Free') };
  costMessage = { index: -1 };
  minDeliveryDate = new Date();
  deliveryDate = new Date();
  selectedDelivery = new DeliveryFormClass({ type: 'Custom', attributes: [{ type: 'PersonalData' }, { type: 'Phone' }]});
  phoneNumber = {};
  birthday = '';
  comment = '';

  constructor() {
    makeAutoObservable(this);
  }

  getMerchantDeliverySettings = () => {
    this.isLoading = true;
    if (Merchant.guid !== '') {
      fetch(`/api/v1/public/deliveryObj/${Merchant.guid}`, { method: 'GET' })
        .then((response) => response.json())
        .then((response) => {
          runInAction(() => {
            this.deliverySettingsArr = response;
            this.isLoading = false;
            if (response !== undefined && response.length > 0) {
              if (response[0].date?.type === 'setByClient') {
                this.deliveryDate.setDate(this.deliveryDate.getDate() + response[0].date.period.delay - 1);
                this.minDeliveryDate = this.deliveryDate;
              }
            }
          });
        });
    }
  }

  getCostMessage = (costSettings) => {
    const currentDependence = this.deliveryCostObj;

    if (currentDependence.dependence.toOrderPrice !== '' && Number(currentDependence.dependence.deliveryPrice) !== 0) {
      const index = costSettings.costDependencies.findIndex((element) => element.fromOrderPrice === currentDependence.dependence.toOrderPrice);
      const needToBuy = (costSettings.costDependencies[index].fromOrderPrice - Number(ShoppingCart.totalCost)).toFixed(2);
      let newDeliveryPrice;
      if (Number(costSettings.costDependencies[index].deliveryPrice) === 0) {
        newDeliveryPrice = i18n.t('DeliveryBecomeFREE');
      } else {
        newDeliveryPrice = `${i18n.t('DeliveryCostBecome')} ${costSettings.costDependencies[index].deliveryPrice} ${i18n.t(getCurrency())}`;
      }
      return {
        index,
        message: `${i18n.t('MakePurchaseOn')} ${needToBuy} ${i18n.t(getCurrency())}, ${i18n.t('makePurchase_to')} ${newDeliveryPrice}`,
      };
    }
    return { index: -1 };
  };

  setSelectedDelivery = () => {
    if (this.selectedDeliveryType !== -1 && this.deliverySettingsArr.length > 0) {
      this.selectedDelivery = new DeliveryFormClass(this.deliverySettingsArr[this.selectedDeliveryType]);
      this.getDeliveryCost();
      if (this.selectedDelivery.type === 'Pickup') {
        this.selectedDelivery.attributes = [{ type: 'PersonalData' }, { type: 'Phone' }];
      }

      if (this.selectedDelivery.date?.type === 'setByClient') {
        if (Number(this.selectedDelivery.date.period.delay) !== 0) {
          this.deliveryDate.setDate(this.deliveryDate.getDate() + this.selectedDelivery.date.period.delay - 1);
          this.minDeliveryDate = (this.deliveryDate - 86400000);
        } else {
          this.minDeliveryDate = new Date(Date.now() - 86400000)
          this.deliveryDate = new Date();
        }
      }
    }
  }

  getDeliveryCost = () => {
    if (this.selectedDeliveryType !== -1) {
      fetch('/api/v1/public/getDeliveryCost', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          guid: Merchant.guid,
          shoppingCart: ShoppingCart.cart,
          selectedDeliveryId: this.selectedDeliveryType,
          isCMS: Merchant.isCMS
        })
      }).then((response) => {
        if (response.status === 200) {
          return response.json();
        }
      }).then(action((response) => {
        if (response) {
          if (response.price === 0) {
            response.message = i18n.t('Free');
          } else {
            response.message = `${response.price} ${i18n.t(getCurrency())}`;
          }
          this.deliveryCostObj = response;
          if (this.selectedDelivery.cost) {
            this.costMessage = this.getCostMessage(this.selectedDelivery.cost);
          }
        } else {
          this.deliveryCostObj = { price: 0, dependence: { deliveryPrice: 0 }, message: i18n.t('Free') }
        }
      }))
    }
  }

  isAttributeUsed = (type) => {
    return this.selectedDelivery.attributes.findIndex((el) => el.type === type) !== -1
  }

  checkRequiredFields = (currentLanguage) => {
    let flag = true;
    try {
      if (this.selectedDeliveryType !== -1 && this.deliverySettingsArr.length > 0) {
        if (this.isAttributeUsed('PersonalData')) {
          for (const field of this.selectedDelivery.personalData) {
            if (field.value[currentLanguage] === '' && field.required) {
              flag = false;
            }
          }
        }

        if (this.isAttributeUsed('Phone')) {
          if ((!this.phoneNumber.valid && (this.selectedDelivery.phone || this.phoneNumber.value !== '')) || Object.keys(this.phoneNumber).length === 0) {
            ModalStore.openWithMessage(i18n.t('PhoneIsNotValid'));
            return false;
          }
        }

        if (this.isAttributeUsed('Birthday')) {
          if (this.birthday === '' && this.selectedDelivery.birthday) {
            flag = false;
          }
        }

        if (this.isAttributeUsed('ClientAddress') && this.selectedDelivery.addressData.type === 'client') {
          for (const field of this.selectedDelivery.addressData.fields) {
            if (field.value[currentLanguage] === '' && field.required) {
              flag = false;
            }
          }
        }

        if (!flag) {
          ModalStore.openWithMessage(i18n.t('MustFillRequiredFields'))
        }
      } else {
        if (Object.keys(this.selectedDelivery).length !== 0) {
          if (this.isAttributeUsed('PersonalData')) {
            for (const field of this.selectedDelivery.personalData) {
              if (field.value[currentLanguage] === '' && field.required) {
                flag = false;
              }
            }
          }
          if (this.isAttributeUsed('Phone')) {
            if ((!this.phoneNumber.valid && (this.selectedDelivery.phone || this.phoneNumber.value !== '')) || Object.keys(this.phoneNumber).length === 0) {
              ModalStore.openWithMessage(i18n.t('PhoneIsNotValid'));
              return false;
            }
          }
        }
      }

      if (!flag) {
        ModalStore.openWithMessage(i18n.t('MustFillRequiredFields'))
      }
    } catch (e) {
      return false;
    }

    return flag;
  }

  getDataValue = (dateSettings) => {
    if (dateSettings.type === 'autoSet') {
      if (dateSettings.subtype === 'autoPeriod') {
        return `${i18n.t('from')} ${dateSettings.period.delay} ${i18n.t('to')} ${dateSettings.period.length} ${i18n.t('days')}`;
      }
      if (dateSettings.subtype === 'autoDate') {
        if (dateSettings.period.delay == 0) {
          return i18n.t('Today');
        }
        if (dateSettings.period.delay == 1) {
          return i18n.t('Tomorrow');
        }

        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + dateSettings.period.delay);
        return `${String(currentDate.getDate()).padStart(2, '00')}.${String(currentDate.getMonth()).padStart(2, '00')}.${currentDate.getFullYear()}`;
      }
    }

    if (dateSettings.type === 'setByClient') {
      if (dateSettings.period.delay == 0) {
        return i18n.t('Today');
      }
      if (dateSettings.period.delay == 1) {
        return i18n.t('Tomorrow');
      }

      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + dateSettings.period.delay);
      return `${String(currentDate.getDate()).padStart(2, '00')}.${String(currentDate.getMonth()).padStart(2, '00')}.${currentDate.getFullYear()}`;
    }
  }

  getDeliveryDataObject = (currentLanguage) => {
    if (this.selectedDeliveryType !== -1 && this.deliverySettingsArr.length > 0) {
      const dataObj = {
        DeliveryType: { Title: this.selectedDelivery.title[currentLanguage], Id: this.selectedDelivery.type },
      };

      if (this.selectedDelivery.type !== 'Pickup') {
        if (this.isAttributeUsed('PersonalData')) {
          dataObj.PersonalData = []
          for (let field of this.selectedDelivery.personalData) {
            dataObj.PersonalData.push({ Title: field.title[currentLanguage],  Value: field.value[currentLanguage], Name: field.title.en });
          }
        }

        if (this.isAttributeUsed('ClientAddress')) {
          dataObj.AddressFields = [];
          for (let field of this.selectedDelivery.addressData.fields) {
            dataObj.AddressFields.push({ Title: field.title[currentLanguage],  Value: field.value[currentLanguage], Name: field.title.en });
          }
        }

        if (this.isAttributeUsed('Phone') && Object.keys(this.phoneNumber).length !== 0 && this.phoneNumber.valid) {
          dataObj.PhoneNumber = this.phoneNumber.value;
          dataObj.BeautyPhone = this.phoneNumber.text;
        }

        if (this.isAttributeUsed('Birthday')) {
          dataObj.Birthday = this.birthday;
        }

        if (this.selectedDelivery.cost) {
          dataObj.Cost = this.deliveryCostObj.cost;
        }

        if (this.isAttributeUsed('DeliveryDate')) {
          if (this.selectedDelivery.date.type === 'autoSet') {
            dataObj.DeliveryDate = this.getDataValue(this.selectedDelivery.date);
          } else {
            dataObj.DeliveryDate = new Date(this.deliveryDate).toISOString().split('T')[0];
          }
        }

        if (this.comment !== '') {
          dataObj.EntryField = { Title: i18n.t('Comment'), Value: this.comment, Name: "Comment" };
        }
      } else {
        if (this.isAttributeUsed('ClientAddress') && this.selectedPoint !== -1) {
          dataObj.AddressFields = [];
          dataObj.DeliveryPoint = { Title: this.selectedDelivery.addressData.points[this.selectedPoint].title[currentLanguage], Id: `${this.selectedPoint}` };
          for (let field of this.selectedDelivery.addressData.points[this.selectedPoint].attributes) {
            dataObj.AddressFields.push({ Title: field.title[currentLanguage],  Value: field.value[currentLanguage], Name: field.title.en });
          }
        }

        if (this.isAttributeUsed('PersonalData')) {
          dataObj.PersonalData = []
          for (let field of this.selectedDelivery.personalData) {
            dataObj.PersonalData.push({ Title: field.title[currentLanguage],  Value: field.value[currentLanguage], Name: field.title.en });
          }
        }

        if (this.isAttributeUsed('Phone')  && Object.keys(this.phoneNumber).length !== 0) {
          dataObj.PhoneNumber = this.phoneNumber.value;
          dataObj.BeautyPhone = this.phoneNumber.text;
        }
      }
      return dataObj;
    }

    if (this.deliverySettingsArr.length === 0 && Object.keys(this.selectedDelivery).length !== 0) {
      const dataObj = {};
      dataObj.PersonalData = [];
      for (let field of this.selectedDelivery.personalData) {
        dataObj.PersonalData.push({ Title: field.title[currentLanguage],  Value: field.value[currentLanguage], Name: field.title.en });
      }

      if (Object.keys(this.phoneNumber).length !== 0) {
        dataObj.PhoneNumber = this.phoneNumber.value;
        dataObj.BeautyPhone = this.phoneNumber.text;
      }

      dataObj.DeliveryType = { Title: 'Custom', Id: 'Custom' };
      return dataObj;
    }
  }

  makeOrder = (language, history) => {
    if (this.checkRequiredFields(language)) {
      fetch('/api/v1/public/makeOrder', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          cart: ShoppingCart.cart,
          data: Merchant.marketLink,
          language: language,
          delivery: this.getDeliveryDataObject(language),
          deliveryObj: this.selectedDelivery,
          selectedDeliveryId: this.selectedDeliveryType,
        })
      }).then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          ModalStore.openUnexpectedErrorModal()
        }
      })
        .then((response) => {
          if (response !== undefined) {
            let successUrl = `${window.location.origin}/${Merchant.marketLink}?success`

            if (response.webasystOrderId) {
              successUrl += `#Webasyst=${response.webasystOrderId}`;
            } else {
              successUrl +=`#order=${response.orderId}`;
            }

            const cancelUrl = `${window.location.origin}/${Merchant.marketLink}/checkout`
            let eInvoicingPath = `${eInvoicingURL}${response.data.CreateBill.Id}&PaymentRid=${response.data.CreateBill.PaymentRid}`

            if (response.data.CreateBill.CustomerStatus !== 'None') {
              eInvoicingPath += `&phone=${this.phoneNumber.value}`
            }

            eInvoicingPath += `&success_url=${successUrl}&cancel_url=${cancelUrl}`;

            fetch('/api/v1/public/order/setLink', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                orderId: response.orderId,
                link: eInvoicingPath
              })
            });

            if (MerchantGuidsWithoutEInvoicingRedirecting.indexOf(Merchant.guid) !== -1 && Merchant.isCMS) {
              fetch('/api/v1/public/webasyst/comment', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  merchantGuid: Merchant.guid,
                  id: response.webasystOrderId,
                  text: eInvoicingPath
                })
              });

              ModalStore.isOpen = true;
              ModalStore.icon = 'merchant';
              ModalStore.title = i18n.t('MerchantService');
              ModalStore.mode = 'normal';
              ModalStore.text = Merchant.messageBeforeBillCreation;
              ModalStore.buttons = [
                {
                  text: i18n.t('Ok'),
                  mode: 'normal',
                  action: () => {
                      removeCart(Merchant.guid);
                      ShoppingCart.setCart([]);
                      ShoppingCart.setTotalCost('0.00');
                      ShoppingCart.setTotalCount(0);
                      ModalStore.setDefaultModal();
                      history.push(`/${Merchant.marketLink}`);
                    },
                },
              ]
            } else {
              window.location.href = eInvoicingPath;
            }
          }
        })
    }
  }
}

export default new OrderStore();
