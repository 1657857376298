import React from 'react';
import { UiTabBlock, useOptions } from '@compassplus/ui-mobicash';
import { observer } from 'mobx-react-lite';
import { DetailsText, DetailsTitleText } from '../../../pages/admin/ModifyGroupPage/style';
import i18n from '../../../i18n';
import { StyledTab } from './style';

const LanguageTabs = observer((props) => {
  const { title, setSelectedLanguage } = props;
  const isOptions = useOptions();
  const currentLanguage = isOptions.language;
  return (
    <>
      {title ? (
        <DetailsTitleText>
          {title}
        </DetailsTitleText>
      ) : null}
      <DetailsText>
        {i18n.t('LanguagesAnnotation')}
      </DetailsText>
      <StyledTab>
        <UiTabBlock
          title={i18n.t('RU')}
          active={currentLanguage === 'ru'}
          onClick={() => {
            if (setSelectedLanguage) {
              setSelectedLanguage('ru');
            }
          }}
        >
          {props.children('ru')}
        </UiTabBlock>
        <UiTabBlock
          title={i18n.t('EN')}
          active={currentLanguage === 'en'}
          onClick={() => {
            if (setSelectedLanguage) {
              setSelectedLanguage('en');
            }
          }}
        >
          {props.children('en')}
        </UiTabBlock>
      </StyledTab>
    </>
  );
});

export default LanguageTabs;
