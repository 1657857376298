import React, { useState } from 'react';
import OrdersRecordsStore from "../../../../stores/OrdersRecordsStore";
import { useHistory } from "react-router";
import { Text } from "../../../../components/TemplateStyle";
import i18n from '../../../../i18n';
import { SubContentStiky } from "../../ModifyAttributePage/style";
import { UiButton, UiCard, UiInputDate } from "@compassplus/ui-mobicash";

const OrderBirthday = () => {
  const { modifyingOrder } = OrdersRecordsStore;
  const history = useHistory();

  const [birthday, setBirthday] = useState({ value: modifyingOrder?.customer?.birthday });

  const renderBirthdayInput = () => (
    <UiInputDate
      record="partial"
      description={i18n.t('Birthday')}
      state={birthday}
      setState={setBirthday}
      defaultValue={birthday.value}
    />
  )

  return (
    <>
      <Text
        style={{ textTransform: 'uppercase', fontWeight: 600 }}
        view="readonly"
        typography="note"
      >
        {i18n.t('Birthday')}
      </Text>
      <UiCard
        style={{ margin: '0.5rem 0 0.25rem' }}
        shadow
      >
        {renderBirthdayInput()}
      </UiCard>
      <SubContentStiky>
        <UiButton
          title={i18n.t('Save')}
          onClick={() => {
            OrdersRecordsStore.modifyCustomerData({ birthday: birthday.text });
            history.replace(`/modifyOrder/${modifyingOrder._id}`);
          }}
        />
      </SubContentStiky>
    </>
  );
}

export default OrderBirthday;