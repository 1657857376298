import React from 'react';
import { observer } from 'mobx-react-lite';
import { UiCard, UiInput, UiText } from '@compassplus/ui-mobicash';
import { action } from 'mobx';
import i18n from '../../../../i18n';
import OrderStore from '../../../../stores/OrderStore';
import {AnimationDiv, Text} from "../../../../components/TemplateStyle";

const EntryField = observer(() => (
  <>
    {
        OrderStore.selectedDelivery.entryField && (
          <AnimationDiv>
            <Text style={{ textTransform: 'uppercase', margin: '0.5rem 0 0' }} view="readonly" typography="section-description-semibold">
              {i18n.t('Comment')}
            </Text>
            <UiCard style={{ margin: '0.5rem 0 0' }} shadow>
              <UiInput
                description={i18n.t('CommentAnnotation')}
                value={OrderStore.comment}
                placeholder={i18n.t(`[${i18n.t('Text')}]`)}
                onChange={action((event) => { OrderStore.comment = event.target.value; })}
              />
            </UiCard>
          </AnimationDiv>
        )
      }
  </>
));

export default EntryField;
