import React, { useEffect } from 'react';
import { useOptions } from '@compassplus/ui-mobicash';
import { useHistory } from 'react-router';
import { MContentDiv, SubContentDiv } from '../../../components/TemplateStyle';
import CountDependencies from './CountDependencies';
import GlobalStateStore from '../../../stores/GlobalStateStore';
import i18n from '../../../i18n';
import ModifyItem from '../ModifyItemPage/ModifyItem';
import ItemStore from '../../../stores/ItemStore';

const CountDependenciesPage = (props) => {
  const isOptions = useOptions();
  const history = useHistory();

  useEffect(() => {
    GlobalStateStore.setHeaderProperties({
      title: i18n.t('CountDependencies'),
      isBack: true,
    });
  }, []);

  return (
    <>
      {
        isOptions.screen === 'mobile' ? (
          <MContentDiv>
            <CountDependencies />
          </MContentDiv>
        ) : (
          <>
            <MContentDiv onClick={() => { history.goBack(); }}>
              <ModifyItem itemId={ItemStore.article} {...props} disableSave />
            </MContentDiv>
            <SubContentDiv>
              <CountDependencies />
            </SubContentDiv>
          </>
        )
      }
    </>
  );
};

export default CountDependenciesPage;
