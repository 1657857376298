import React, { useReducer } from 'react';
import { observer } from 'mobx-react-lite';
import {
  UiCard,
  UiSection,
  UiSwitch,
  UiInput,
  UiScrollArrow,
  UiArrow,
} from '@compassplus/ui-mobicash';
import i18n from '../../../../i18n';
import DatePeriod from './DatePeriod';
import DateInput from '../../../../components/admin/DateInput/DateInput';

const Dates = observer(({ attribute }) => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  return (
    <>
      <UiCard shadow style={{ margin: '0.25rem 0' }}>
        <UiSection
          title={i18n.t('Dates')}
          rightElement={(
            <UiSwitch
              checked={attribute.status}
              onClick={() => { attribute.status = !attribute.status; forceUpdate(); }}
            />
          )}
          description={attribute.status ? i18n.t('SelectDesiredDates') : null}
          bottomDivider={attribute.status}
        />
        {
          attribute.status ? (
            <>
              <UiInput
                description={i18n.t('Term')}
                value={i18n.t(attribute.subtype)}
                rightElement={(
                  <UiScrollArrow onClick={() => {
                    if (attribute.subtype === 'period') {
                      attribute.subtype = 'dates';
                    } else {
                      attribute.subtype = 'period';
                    }
                    forceUpdate();
                  }}
                  />
                )}
              />
            </>
          ) : null
        }
      </UiCard>
      {
        attribute.status ? (
          <>
            {attribute.subtype === 'period' ? (
              <>
                {attribute.periods.map((period, index) => (
                  <UiCard
                    key={`${period}`}
                    style={{ margin: '0.25rem 0' }}
                    shadow
                  >
                    <DatePeriod
                      period={period}
                      bottomDivider={index !== attribute.periods.length - 1}
                      onDelete={(event) => {
                        if (index !== 0) {
                          attribute.periods.splice(index, 1);
                          forceUpdate();
                        }
                        event.stopPropagation();
                      }}
                    />
                  </UiCard>
                ))}
                <UiCard style={{ margin: '0.25rem 0' }} shadow>
                  <UiSection
                    title={i18n.t('AddPeriod')}
                    rightElement={<UiArrow />}
                    onClick={() => {
                      attribute.periods.push({
                        from: new Date((new Date()).getTime()).toISOString().split('T')[0],
                        to: new Date((new Date()).getTime()).toISOString().split('T')[0],
                      });
                      forceUpdate();
                    }}
                  />
                </UiCard>
              </>
            ) : (
              <>
                {attribute.dates.map((date, index) => (
                  <UiCard style={{ margin: '0.25rem 0' }} shadow>
                    <DateInput
                      attr={date}
                      attribute={attribute}
                      index={index}
                      onDelete={(event) => {
                        if (index !== 0) {
                          attribute.dates.splice(index, 1);
                          forceUpdate();
                        }
                        event.stopPropagation();
                      }}
                    />
                  </UiCard>
                ))}
                <UiCard style={{ margin: '0.25rem 0' }} shadow>
                  <UiSection
                    title={i18n.t('AddDate')}
                    rightElement={<UiArrow />}
                    onClick={() => {
                      attribute.dates.push(new Date((new Date()).getTime()).toISOString().split('T')[0]);
                      forceUpdate();
                    }}
                  />
                </UiCard>
              </>
            )}
          </>
        ) : null
      }
    </>
  );
});

export default Dates;
