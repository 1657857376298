import PropTypes from 'prop-types';
import cn from 'classnames';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../context/ThemeProvider';

import styles from './UiCard.module.css';

import { StyledContainerItem } from '../../Functional/ContainerItem/ContainerItem';

export const StyledDiv = styled.div`
  background: ${ (props) => props.styles.colors.sectionBackground };
  ${ (props) => props.shadow && css`
    box-shadow: ${ props.styles.shadows.mainShadow };
  ` }

  & > ${ StyledContainerItem }:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  & > ${ StyledContainerItem }:last-child {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
`;

/**
 * Card component
 * @public
 * @version 0.0.90
 * @param {String} className
 * @param {Boolean} [shadow=false]
 * @param {Function|Object} [innerRef]
 * @param {Function} onClick
 * @param {*} children
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiCard>{ children }</UiCard>
 */
const UiCard = ({
  className,
  shadow,
  innerRef,
  onClick,
  children,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <StyledDiv
      { ...props }
      className={ cn(styles.card, className) }
      shadow={ shadow }
      onClick={ onClick }
      ref={ innerRef }
      styles={ isTheme.styles }
    >
      { children }
    </StyledDiv>
  );
}

UiCard.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Добавляет компоненту тень */
  shadow: PropTypes.bool,
  /** Устанавливает обработчик на компонент по нажатию */
  onClick: PropTypes.func,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  /** Контент компонента */
  children: PropTypes.any
};

UiCard.defaultProps = {
  shadow: false
};

export default UiCard;