import React, { useEffect, useState } from 'react';
import { getCashierLogin, request } from '../../../Utils/requestUtil';
import i18n from '../../../i18n';
import { Dialog, Text } from '../../TemplateStyle';
import Merchant from '../../../stores/Merchant';
import { SuccessLinkInput } from './style';

const SuccessLinkSettingsModal = ({ active, setActive }) => {
  const { successLink } = Merchant;
  const [link, setLink] = useState(successLink);
  const [mode, setMode] = useState('normal');
  const [message] = useState();

  useEffect(() => {
    setLink(successLink);
  }, [successLink, active]);

  const onLinkChange = (event) => {
    setLink(event.target.value);
  };

  const saveChanges = () => {
    const requestData = {
      login: getCashierLogin(),
      link,
    };
    Merchant.successLink = link;
    request('/api/v1/cashier/setSuccessURL', 'POST', requestData)
      .then(
        (error) => {
          console.log(error);
        },
      );
    setActive(false);
  };

  return (
    <>
      {
        active && (
          <Dialog
            title={i18n.t('LinkSettings')}
            text={message}
            mode={mode}
            buttons={[
              {
                text: i18n.t('Save'),
                mode: 'bold',
                action: () => {
                  if (link !== '') {
                    setMode('normal');
                    saveChanges();
                  } else {
                    setMode('warning');
                  }
                },
              },
              {
                text: i18n.t('Reset'),
                mode: '',
                action: () => { setLink(Merchant.fullMarketUrl); },
              },
              {
                text: i18n.t('Cancel'),
                mode: '',
                action: () => { setActive(false); },
              },
            ]}
          >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Text
                style={{ margin: '0.25rem 0' }}
                typography="note"
                view="readonly"
                position="center"
              >
                {i18n.t('CustomLinkMessage')}
              </Text>
              <SuccessLinkInput
                style={{ margin: '0 0.5rem' }}
                placeholder={`${window.location.origin}/${Merchant.marketLink}`}
                value={link}
                onChange={onLinkChange}
              />
            </div>
          </Dialog>
        )
      }
    </>
  );
};

export default SuccessLinkSettingsModal;
