import React, { useEffect } from 'react';
import { useOptions } from '@compassplus/ui-mobicash';
import { observer } from 'mobx-react-lite';
import { useHistory, useParams } from 'react-router';
import { action } from 'mobx';
import { MContentDiv, SubContentDiv } from '../../../components/TemplateStyle';
import ItemStore from '../../../stores/ItemStore';
import ProductCard from './components/ProductCard';
import Attributes from '../../../components/market/OrderAttributes';
import AddToCartButton from './components/AddToCartButton';
import GlobalStateStore from '../../../stores/GlobalStateStore';
import AttributesStore from "../../../stores/AttributesStore";
import Merchant from "../../../stores/Merchant";
import Breadcrumb from "../../../components/market/Breadcrumb";
import MarketStore from "../../../stores/MarketStore";

const OrderPage = observer(() => {
  const isOptions = useOptions();
  const params = useParams();
  const history = useHistory();

  useEffect(action(() => {
    GlobalStateStore.sidebarClassName = 'upper';
  }), []);

  useEffect(() => {
    if (params.id) {
      if (Merchant.guid !== '') {
        ItemStore.setItem(params.id, true);
      }
      AttributesStore.setDefaultActive();
    } else {
      history.goBack();
    }
  }, [params, Merchant.guid]);

  if (isOptions.screen === 'mobile') {
    return (
      <MContentDiv>
        <Breadcrumb
          style={{ margin: '0.25rem 0' }}
          links={MarketStore.breadcrumbs}
        />
        <ProductCard />
        <Attributes />
        <AddToCartButton />
      </MContentDiv>
    );
  }

  if (ItemStore.attributes.length === 0 && !ItemStore.isLoading) {
    return (
      <MContentDiv>
        <Breadcrumb
          style={{ margin: '0.25rem 0' }}
          links={MarketStore.breadcrumbs}
        />
        <ProductCard />
        <AddToCartButton />
      </MContentDiv>
    );
  }

  return (
    <div>
      <Breadcrumb
        style={{ margin: '1rem 0 0' }}
        links={MarketStore.breadcrumbs}
      />
      <div style={{ display: 'flex' }}>
        <MContentDiv>
          <ProductCard />
        </MContentDiv>
        <SubContentDiv>
          <Attributes />
          <AddToCartButton />
        </SubContentDiv>
      </div>
    </div>
  );
});

export default OrderPage;
