import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  UiCard, UiSection, UiSwitch, UiArrow, UiInput, useOptions,
} from '@compassplus/ui-mobicash';
import { action } from 'mobx';
import { useHistory } from 'react-router';
import AttributesStore from '../../../stores/AttributesStore';
import i18n from '../../../i18n';
import ItemStore from "../../../stores/ItemStore";

const CountDependenciesSwitch = observer(() => {
  const history = useHistory();
  const isOptions = useOptions();
  const { isCountDependsOnAttributes, countDependencies } = AttributesStore;
  const [attributesWithDependencies, setAttributesWithDependencies] = useState([]);

  useEffect(() => {
    setAttributesWithDependencies([...AttributesStore.getAttributesByType('radio'), ...AttributesStore.getAttributesByType('color')]);
  }, [AttributesStore.attributes]);

  const getSeparatedCountAttributesString = () => AttributesStore.getSeparatedCountAttributes().map((attr) => attr.title[isOptions.language]).join(', ');

  return (
    <>
      {
        attributesWithDependencies.length !== 0 ? (
          <>
            <UiCard style={{ margin: '0.25rem 0' }} shadow>
              <UiSection
                title={i18n.t('DifferentCount')}
                rightElement={(
                  <UiSwitch
                    checked={isCountDependsOnAttributes}
                    onChange={action(() => {
                      if (!isCountDependsOnAttributes) {
                        AttributesStore.setDefaultCountDependencies();
                      }
                      AttributesStore.isCountDependsOnAttributes = !isCountDependsOnAttributes;
                    })}
                  />
                )}
              />
            </UiCard>
            {
              isCountDependsOnAttributes ? (
                <UiCard style={{ margin: '0.25rem 0' }}>
                  <UiInput
                    description={i18n.t('CustomizeCount')}
                    value={Object.keys(countDependencies).length === 0 ? i18n.t('undefined') : getSeparatedCountAttributesString()}
                    onClick={(event) => {
                      if (!AttributesStore.isModified && Object.keys(ItemStore.countDependencies).length !== 0) {
                        AttributesStore.countDependencies = JSON.parse(JSON.stringify(ItemStore.countDependencies));
                        if (ItemStore.fullAttributeNameCountDependencies.length !== 0) {
                          AttributesStore.fullAttributeNameCountDependencies = ItemStore.fullAttributeNameCountDependencies;
                        }
                      }
                      AttributesStore.isModified = false;
                      history.push('/countDependencies', { rootGroup: ItemStore.rootGroup });
                      event.stopPropagation();
                    }}
                    rightElement={<UiArrow />}
                    readOnly
                  />
                </UiCard>
              ) : null
            }
          </>
        ) : null
      }
    </>
  );
});

export default CountDependenciesSwitch;
