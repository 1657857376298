import { useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../context/ThemeProvider';

import { isFunction } from '../../../utils/typeUtils';

import styles from './Element.module.css';

const StyledSvg = styled.svg`
  ${ (props) => css`
    color: ${ props.color };
    width: ${ props.setWidth };
    min-width: ${ props.setWidth };
    height: ${ props.setHeight };
    min-height: ${ props.setHeight };
  ` }
  
  ${ (props) => {
    if (props.disabled) {
      return css`
        color: ${ (props) => props.styles.colors.textDisable };
        cursor: default;
      `;
    } else {
      return css`
        cursor: pointer;
      `;
    }
  } }
`;

const Element = ({
  className,
  color,
  onClick,
  disabled,
  propagation,
  width,
  height,
  innerRef,
  children,
  ...props
}) => {

  const isTheme = useTheme();

  const clickHandler = useCallback((event) => {
    if (isFunction(onClick)) {
      if (!propagation) {
        event.stopPropagation();
      }

      onClick(event);
    }
  }, [propagation, onClick]);

  return (
    <StyledSvg
      { ...props }
      className={ cn(styles.element, className) }
      color={ color }
      disabled={ disabled }
      fill="currentColor"
      onClick={ !disabled ? clickHandler : undefined }
      setWidth={ width }
      setHeight={ height }
      ref={ innerRef }
      xmlns="http://www.w3.org/2000/svg"
      styles={ isTheme.styles }
    >
      { children }
    </StyledSvg>
  );
};

Element.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Устанавливает цвет заливки компонента */
  color: PropTypes.string,
  /** Обработчик события при клике */
  onClick: PropTypes.func,
  /** Компонент неактивен */
  disabled: PropTypes.bool,
  /** Разрешить/запретить всплытие обработчика onClick */
  propagation: PropTypes.bool,
  /** Определяет ширину компонента */
  width: PropTypes.string,
  /** Определяет высоту компонента */
  height: PropTypes.string,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

Element.defaultProps = {
  disabled: false,
  width: '1rem',
  height: '1rem'
};

export default Element;