import React from 'react';
import { UiHeader, useOptions } from '@compassplus/ui-mobicash';
import { BodyDiv, ContentDiv, MContentDiv } from '../../../components/TemplateStyle';
import Merchants from './Merchants';

const MerchantsPage = () => {
  const isOptions = useOptions();

  const getBodyDivStyle = () => {
    if (isOptions.screen === 'mobile') {
      return { background: '#FBFBFB' };
    }
    return { background: '#FBFBFB', padding: '4rem 0 0' };
  };

  return (
    <>
      <UiHeader text="MobiCash eMarketPlace" />
      <BodyDiv style={getBodyDivStyle()}>
        <ContentDiv>
          <MContentDiv style={isOptions.screen === 'desktop' ? { width: '41.5rem', maxWidth: '41.5rem', padding: '0' } : {}}>
            <Merchants />
          </MContentDiv>
        </ContentDiv>
      </BodyDiv>
    </>
  );
};

export default MerchantsPage;
