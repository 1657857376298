import styled from 'styled-components';

export const LoadingTitle = styled.div`
  height: 20px !important;
  width: 100% !important;
  color: transparent;
  background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%);
  background-size: 400%;
  animation: loading 1.2s ease-in-out infinite;
  margin: ${props => props.margin};
  
  @keyframes loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
`;

export const LoadingMarketSection = styled(LoadingTitle)`
  height: 2rem !important;
`

export const LoadingDescription = styled.div`
  margin: 0.25rem 0 0;
  height: 16px !important;
  width: 100% !important;
  color: transparent;
  background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%);
  background-size: 400%;
  animation: loading 1.2s ease-in-out infinite;
  
  @keyframes loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
`;

export const LoadingImage = styled.div`
  height: 48px !important;
  width: 48px !important;
  color: transparent;
  background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%);
  background-size: 400%;
  animation: loading 1.2s ease-in-out infinite;
  border-radius: 40px;
  
  @keyframes loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
`;

export const LoadingImg = styled(LoadingImage)`
  height: 100% !important;
  width: 100% !important;
  border-radius: 0;
`

export const LoadingCardImage = styled.div`
  height: 148px !important;
  width: 148px !important;
  color: transparent;
  background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%);
  background-size: 400%;
  animation: loading 1.2s ease-in-out infinite;
  
  mask-image: linear-gradient(to top,
  rgba(255,255,255,0.0001) 15.41%,
  rgba(255,255,255,0.152941) 18.18%,
  rgba(255,255,255,0.305882) 20.82%,
  rgba(255,255,255,0.458824) 23.62%,
  rgba(255,255,255,0.603922) 25.86%,
  rgba(255,255,255,0.729412) 27.85%,
  rgba(255,255,255,0.839216) 29.87%,
  rgba(255,255,255,0.92549) 31.21%,
  rgba(255,255,255,0.980392) 33.15%,
  #FFFFFF 100%);
  background-blend-mode: screen;
  
  @keyframes loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
`;