import {makeAutoObservable, runInAction} from 'mobx';
import {getCashierLogin, getTitle, request, setMerchantData} from '../Utils/requestUtil';
import ShoppingCart from "./ShoppingCartStore";

class Merchant {
  constructor() {
    this.guid = '';
    this.name = '';
    this.localName = '';
    this.logoColor = '';
    this.logoSymbol = '';
    this.photo = '';
    this.fullMarketUrl = undefined;
    this.marketLink = undefined;
    this.successLink = undefined;
    this.language = '';
    this.isLoading = false;
    this.cms = undefined;
    this.isCMS = false;
    this.messageBeforeBillCreation = '';
    makeAutoObservable(this);
  }

  clear() {
    this.guid = '';
    this.name = '';
    this.localName = '';
    this.logoColor = '';
    this.logoSymbol = '';
    this.photo = '';
    this.fullMarketUrl = undefined;
    this.marketLink = undefined;
    this.successLink = undefined;
    this.language = '';
    this.isLoading = false;
    this.cms = undefined;
    this.isCMS = false;
    this.messageBeforeBillCreation = '';
  }

  async setMerchantData(marketLink, currentLanguage) {
    if (this.marketLink !== marketLink || this.language !== currentLanguage) {
      this.isLoading = true;
      return fetch(`/api/v1/public/data/${marketLink}`, {
        method: 'GET',
        headers: {
          language: currentLanguage,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((response) => {
          if (response !== undefined) {
            runInAction(() => {
              this.name = response.name;
              this.localName = response.localName;
              this.logoColor = response.logoColor;
              this.logoSymbol = response.logoSymbol;
              this.photo = response.photo;
              this.guid = response.guid;
              this.marketLink = marketLink;
              this.language = currentLanguage;
              this.successLink = response.successUrl;
              this.messageBeforeBillCreation = response.messageBeforeBillCreation;
              setMerchantData(response.guid, response.currency);
              this.isLoading = false;

              if (response.cms && response.cms !== '') {
                this.cmsTitle = response.cms;
                this.isCMS = true;
              }
            })
            return true;
          }
          return false;
        });
    }
    await ShoppingCart.setCartByGuid(this.guid);
    return true;
  }

  getLinks = async () => {
    const marketLink = await request(`/api/v1/cashier/getMarketUrl/${getCashierLogin()}`, 'GET')
    if (marketLink !== '' || marketLink !== undefined) {
      const url = `${window.location.origin}/${marketLink}`;
      this.fullMarketUrl = url;
      this.marketLink = marketLink;
    } else {
      const defaultURL = getTitle().replace(' ', '');
      this.fullMarketUrl = `${window.location.origin}/${defaultURL}`;
      this.marketLink = defaultURL;

      const requestData = {
        login: getCashierLogin(),
        link: defaultURL,
      };

      request('/api/v1/cashier/setMarketURL', 'POST', requestData)
        .then(
          (error) => {
            console.log(error);
          },
        );
    }

    const successLink = await request(`/api/v1/cashier/getSuccessUrl/${getCashierLogin()}`, 'GET')
    if (successLink && successLink !== '') {
      this.successLink = successLink;
    } else {
      const defaultURL = this.fullMarketUrl
      this.successLink = defaultURL;
      const requestData = {
        login: getCashierLogin(),
        link: defaultURL,
      };

      request('/api/v1/cashier/setSuccessURL', 'POST', requestData)
        .then(
          (error) => {
            console.log(error);
          },
        );
    }
  }
}

export default new Merchant();
