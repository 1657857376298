import React, { useEffect } from 'react';
import Header from "../admin/Header";
import Sidebar from "../admin/SideMenu/SideMenu";
import {BodyDiv, ContentDiv} from "../TemplateStyle";
import Footer from "../admin/Footer";
import {useOptions} from "@compassplus/ui-mobicash";
import GlobalStateStore from "../../stores/GlobalStateStore";

const AdminPageContainer = (props) => {
  const isOptions = useOptions();
  useEffect(() => {
    GlobalStateStore.isSidebarOpen = isOptions.screen === 'desktop';
  }, [isOptions.screen])
  return (
    <>
      <Header />
      <Sidebar />
      <BodyDiv>
        <ContentDiv>
          {props.children}
        </ContentDiv>
        <Footer />
      </BodyDiv>
    </>
  );
};

export default AdminPageContainer;