import React, {useReducer, useState} from 'react';
import { UiInputDate, UiIcon } from '@compassplus/ui-mobicash';
import i18n from '../../../i18n';

const DateInput = ({ attr, onDelete, attribute, index }) => {
  const [, forceUpdate] = useReducer((x) => x+1, 0)
  const getTextDate = (date) => {
    const parsed = date.split('-');
    return `${parsed[2]}.${parsed[1]}.${parsed[0]}`;
  }

  const [date, setDate] = useState({ text: getTextDate(attr), value: attr });

  return (
    <>
      <UiInputDate
        state={date}
        description={i18n.t('Date')}
        setState={(value) => {
          setDate({ text: value.text.split(',')[0], value: value.value });
          attribute.dates[index] = value.value.split(' ')[0];
          forceUpdate();
        }}
        minDate={new Date((new Date()).getTime() - 24 * 60 * 60 * 1000).toISOString().split('T')[0]}
        rightElement={<UiIcon icon="cancel" setColor="#EC1310" onClick={onDelete} />}
      />
    </>
  )
}

export default DateInput;