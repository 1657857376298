import React from 'react';

const ActionsIcon = ({ style, onClick }) => {
  if (onClick) {
    if (style) {
      if (!style.cursor) {
        style.cursor = 'pointer';
      }
    } else {
      style = { cursor: 'pointer' };
    }
  }

  return (
    <svg
      onClick={onClick}
      style={style}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21.3333 4.8335H10.3333C9.32225 4.8335 8.5 5.65575 8.5 6.66683V17.6668C8.5 18.6779 9.32225 19.5002 10.3333 19.5002H21.3333C22.3444 19.5002 23.1667 18.6779 23.1667 17.6668V6.66683C23.1667 5.65575 22.3444 4.8335 21.3333 4.8335ZM10.3333 17.6668V6.66683H21.3333L21.3352 17.6668H10.3333Z" fill="#BE1E24" />
      <path d="M6.66634 10.3334H4.83301V21.3334C4.83301 22.3444 5.65526 23.1667 6.66634 23.1667H17.6663V21.3334H6.66634V10.3334ZM14.8549 13.5591L13.2728 11.9769L11.9766 13.2731L14.9778 16.2743L20.2046 10.0043L18.7948 8.8291L14.8549 13.5591Z" fill="#BE1E24" />
    </svg>
  );
};

export default ActionsIcon;
