import React, { useReducer } from 'react';
import {UiInput, UiScrollArrow} from '@compassplus/ui-mobicash';
import { action } from 'mobx';
import { observer } from 'mobx-react-lite';
import i18n from '../../../../i18n';
import AttributesStore from '../../../../stores/AttributesStore';
import {getCurrency} from "../../../../Utils/requestUtil";

const ListInputs = observer(({ language }) => {
  const [, forceUpdate] = useReducer((x) => x+1, 0);
  const listAttribute = AttributesStore.modifyAttribute;

  const getPriceChange = (value) => {
    if (value.priceChangeForm === 'percent') {
      value.priceChange = value.percent;
      return Number(value.percent) !== 0 ? value.percent : '';
    }

    return Number(value.priceChange) !== 0 ? value.priceChange : '';
  }

  return (
    <>
      <UiInput
        description={i18n.t('PointName')}
        placeholder={`[${i18n.t('Text')}]`}
        value={listAttribute.pointName[language]}
        onChange={action((event) => {
          listAttribute.pointName[language] = event.target.value;
          forceUpdate();
        })}
        bottomDivider
        required
      />
      <UiInput
        description={`${i18n.t('Price')}, ${listAttribute.priceChangeForm === 'value' ? i18n.t(getCurrency()) : '%'}`}
        value={getPriceChange(listAttribute)}
        placeholder="0.00"
        onChange={action((event) => {
          event.currentTarget.value = event.currentTarget.value.replace(/[^0-9.]/g, '');
          listAttribute.priceChange = event.currentTarget.value;
          if (listAttribute.priceChangeForm === 'percent') {
            listAttribute.percent = event.currentTarget.value;
          }
          forceUpdate();
        })}
        onBlur={action((event) => {
          if (event.currentTarget.value !== '') {
            event.currentTarget.value = Number(event.currentTarget.value).toFixed(2);
            listAttribute.priceChange = event.currentTarget.value;
            if (listAttribute.priceChangeForm === 'percent') {
              listAttribute.percent = event.currentTarget.value;
            }
            forceUpdate();
          } else {
            listAttribute.priceChange = '0.00';
            forceUpdate();
          }
        })}
        bottomDivider
        rightElement={(
          <UiScrollArrow
            onClick={action(() => {
              if (listAttribute.priceChangeForm === 'value') {
                listAttribute.priceChangeForm = 'percent';
              } else {
                listAttribute.priceChangeForm = 'value';
              }
              forceUpdate();
            })}
          />
        )}
      />
      <UiInput
        description={i18n.t('MaxPointsNumber')}
        placeholder="[999]"
        value={listAttribute.maxPointsNumber}
        onChange={action((event) => {
          listAttribute.maxPointsNumber = event.target.value;
          forceUpdate();
        })}
        required
      />
    </>
  );
});

export default ListInputs;
