import styled from 'styled-components';
import { MainText } from '../../../components/TemplateStyle';
import { UiInput, UiSection } from '@compassplus/ui-mobicash';

export const DetailsTitleText = styled(MainText)`
  width: 100%;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-transform: uppercase;
  margin: 0;
`;

export const DetailsText = styled(MainText)`
  display: flex;
  align-items: center;
  text-align: justify;
  font-feature-setting: 'pnum' on, 'lnum' on;
  margin: 1rem 0 0;
`;


export const Input = styled(UiInput)`
  ::placeholder {
    color: ${(props) => props.theme.textLink};
  }
`;

export const StatusDescriptionText = styled(MainText)`
  margin: 0 !important;
  font-size: 0.625rem;
  display: flex;
  align-items: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
  text-align: justify;
`;

export const AnnotationSection = styled(UiSection)`
  & > div {
    margin: 0;
  }
`