import UiText from '../../../UiText';

const Description = ({ description }) => {

  return (
    <UiText view="title" typography="dashboard-description" position="right">
      { description }
    </UiText>
  );
};

export default Description;