const frames = {
  level_1: {
    'font-size': '0.5rem'
  },
  level_2: {
    'font-size': '0.625rem'
  },
  level_3: {
    'font-size': '0.75rem'
  },
  level_4: {
    'font-size': '0.875rem'
  },
  level_5: {
    'font-size': '1rem'
  },
  level_6: {
    'font-size': '1.125rem'
  },
  level_7: {
    'font-size': '1.625rem'
  }
};

export default frames;