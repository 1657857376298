export const defaultColors = [
  {
    code: '#991408',
    title: { ru: 'Темно-красный', en: 'Dark red' },
  },
  {
    code: '#E21A0B',
    title: { ru: 'Красный', en: 'Red' }
  },
  {
    code: '#FF9900',
    title: { ru: 'Оранжевый', en: 'Orange' }
  },
  {
    code: '#FFFE1D',
    title: { ru: 'Желтый', en: 'Yellow' },
  },
  {
    code: '#B7E27C',
    title: { ru: 'Светло-зеленый', en: 'Light green' }
  },
  {
    code: '#4FAD5A',
    title: { ru: 'Зеленый', en: 'Green' }
  },
  {
    code: '#66C4FF',
    title: { ru: 'Голубой', en: 'Light blue' }
  },
  {
    code: '#0C68CD',
    title: { ru: 'Синий', en: 'Blue' }
  },
  {
    code: '#0B2F84',
    title: { ru: 'Темно-синий', en: 'Dark blue' }
  },
  {
    code: '#6A17BF',
    title: { ru: 'Фиолетовый', en: 'Purple' }
  },
  {
    code: '#FFFFFF',
    title: { ru: 'Белый', en: 'White' }
  },
  {
    code: '#000000',
    title: { ru: 'Черный', en: 'Black' }
  }
];