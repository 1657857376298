import styled from 'styled-components';
import { UiCard, UiInput, UiSection } from '@compassplus/ui-mobicash';

export const Table = styled.table`
  width: 100%;
`;

export const TableSection = styled(UiSection)`
  position: sticky;
  left: 0;
`;

export const TableColor = styled(UiInput)`
  position: sticky;
  left: 0;
  background: #fff;
  & {
    align-items: center;
    text-overflow: ellipsis;
  }
  & > div:first-child {
    margin: 0 0.5rem 0 0;
  }
`;

export const ColorCircle = styled.div`
   min-width: 1rem;
   height: 1rem;
   border-radius: 80px;
   background: ${(props) => props.code};
   border: 1px solid #EBEBEB;
`;

export const TableContainer = styled(UiCard)`
  margin-bottom: 10px;
  overflow-x: scroll;
  
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 2px;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 1px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
`;

export const TableHeader = styled.th`
  min-width: 3.625rem;
  max-width: 3.625rem;
  height: 32px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const TableRow = styled.tr`
  border-bottom: 0.063rem solid #00000033;
`;

export const TableInput = styled.input`
  text-align: center;
  width: 100%;
  border: none;
  background: none;
  color: ${(props) => props.theme.textTitle};
`;
