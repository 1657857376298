/**
 * Создаёт и возвращает экземляр объекта Date, представляющего собой момент времени
 * @param {Date|String|Number|Function} date
 * @return {Date}
 */
export function dateParser(date) {
  if (date instanceof Date) {
    return date;
  } else if (
    (typeof date === 'string' && !isNaN(Date.parse(date))) ||
    (typeof date === 'number' && new Date(date).toString() !== 'Invalid Date')
  ) {
    return new Date(date);
  } else if (typeof date === 'function') {
    return dateParser(date());
  } else {
    return new Date();
  }
}

/**
 * Возвращает логическое значения (false/true) о валидности экземпляра объекта Date
 * @param {Date|String|Number} date
 * @return {boolean}
 */
export function isValidDate(date) {
  return (date instanceof Date) || (typeof date === 'string' && !isNaN(Date.parse(date))) || (typeof date === 'number' && new Date(date).toString() !== 'Invalid Date');
}

/**
 * Возвращает отформатированную строку вида: "YYYY-MM-DD HH:MM:SS"
 * @param {Date|String|Number|Function} date
 * @return {string}
 */
export function validateDate(date) {
  date = dateParser(date);

  const day = ('0' + date.getDate()).slice(-2);
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();
  const hour = ('0' + date.getHours()).slice(-2);
  const minute = ('0' + date.getMinutes()).slice(-2);
  const second = ('0' + date.getSeconds()).slice(-2);
  return `${ year }-${ month }-${ day } ${ hour }:${ minute }:${ second }`;
}

/**
 * Создаёт и возвращает объект с языкозависимыми представлениями даты
 * @param {Date|String|Number|Function} date
 * @param {String} language
 * @return {{string: string, timeString: string, dateString: string}}
 */
export function validateDateFormat(date, language) {
  date = dateParser(date);

  return {
    dateString: date.toLocaleDateString(language),
    timeString: date.toLocaleTimeString(language),
    string: date.toLocaleString(language)
  };
}

/**
 * Создаёт и возвращает объект с определенными полями экземпляра даты
 * @param {Date|String|Number|Function} date
 * @return {{month: number, year: number, day: number}}
 */
export function getInformationFromDate(date) {
  date = dateParser(date);

  return {
    year: date.getFullYear(),
    month: date.getMonth(),
    day: date.getDate()
  };
}