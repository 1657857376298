import React, { useEffect, useState } from 'react';
import { UiCard, UiInput, UiButton } from '@compassplus/ui-mobicash';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react-lite';
import i18n from '../../../../i18n';
import ModalStore from '../../../../stores/ModalStore';
import AdminMarketStore from '../../../../stores/AdminMarketStore';

const WebasystConnection = observer(() => {
  const [webasystURL, setWebasystURL] = useState('');
  const history = useHistory();

  useEffect(() => {
    if (window.location.hash !== '') {
      AdminMarketStore.saveCMS('Webasyst', localStorage.getItem('WebasystURL'), window.location.hash.split('=')[1]);
      localStorage.removeItem('WebasystURL');
      history.replace('/settings');
    }
  }, []);

  const getConnection = async () => {
    const url = `https://${webasystURL}/api.php/auth?client_id=eMarketPlace&client_name=eMarketPlace&response_type=token&scope=shop&redirect_uri=${window.location.href}?cms=Webasyst&format=json`;

    if (webasystURL === '') {
      ModalStore.openWithMessage(i18n.t('EnterMarketUrl'));
    } else {
      try {
        const checkUrl = await fetch(url, {
          mode: 'no-cors',
          method: 'GET',
        });
        if (checkUrl) {
          localStorage.setItem('WebasystURL', webasystURL);
          window.location.href = url;
        }
      } catch (e) {
        ModalStore.openWithMessage(i18n.t('NoSuchShop'));
      }
    }
  };

  return (
    <>
      <UiCard style={{ margin: '0.25rem 0' }}>
        <UiInput
          description={i18n.t('MarketUrl')}
          value={webasystURL}
          placeholder="test.doma-prosto.ru"
          onChange={(event) => {
            setWebasystURL(event.target.value);
          }}
        />
        <UiButton title={i18n.t('Connect')} onClick={() => { getConnection(); }} />
      </UiCard>
    </>
  );
});

export default WebasystConnection;
