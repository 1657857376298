const shadows = {
  mainShadow: '0 0.063rem 0.063rem rgba(0, 0, 0, 0.12), 0 0 0.063rem rgba(0, 0, 0, 0.06)',
  stiky: '0 0 0.25rem rgba(0, 0, 0, 0.12), 0 0.063rem 0.188rem rgba(0, 0, 0, 0.1)',
  bankCard: '0 0 0.25rem rgba(0, 0, 0, 0.2), 0 0 0.125rem rgba(0, 0, 0, 0.4)',
  infoPanel: 'drop-shadow(0 0 0.25rem rgba(0, 0, 0, 0.2)) drop-shadow(0 0 0.125rem rgba(0, 0, 0, 0.4))',
  keyboard: '0 -0.125rem 0.188rem rgba(0, 0, 0, 0.3)',
  divider: 'radial-gradient(50% 50% at 50% 50%, #7F7F7F 0%, rgba(127, 127, 127, 0) 100%)',
  action: 'inset 0px -1px 0px rgba(0, 0, 0, 0.35)',
  memPad: 'drop-shadow(0 0.063rem 0.063rem rgba(0, 0, 0, 0.05)) drop-shadow(0.063rem 0.25rem 0.25rem rgba(0, 0, 0, 0.15))',
  fav: '0 0.063rem 0.125rem rgba(0, 0, 0, 0.3)',
  leftFrame: '0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.20)',
  rightFrame: '-0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.20)'
};

export default shadows;