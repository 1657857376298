import React, { useEffect, useReducer } from 'react';
import { observer } from 'mobx-react-lite';
import AttributesStore from '../../../stores/AttributesStore';
import Radio from './Radio';
import Checkbox from './Checkbox';
import TextField from './TextField';
import List from './List';
import Color from './Color';
import ItemStore from '../../../stores/ItemStore';
import LoadingMarketAttribute from '../../loadingCards/LoadingMarketAttribute';

const Attributes = observer((props) => {
  const { isLoading } = ItemStore;
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const isActive = ItemStore.checkIsActive();

  useEffect(() => {
    AttributesStore.setAllRadioFalse();
    AttributesStore.setAllCheckboxesFalse();
    AttributesStore.setDefaultActive();
    AttributesStore.checkAllRequiredAttributesSelected();
    forceUpdate();
  }, [AttributesStore.attributes]);

  const onSelectClick = (attribute) => {
    AttributesStore.getActiveAttributes(attribute);
    AttributesStore.checkAllRequiredAttributesSelected();
    forceUpdate();
  };

  if (!isLoading) {
    return (
      <div style={{ width: '100%', margin: '0 0 4rem' }}>
        <Radio onSelectClick={onSelectClick} isActive={isActive} />
        <Color onSelectClick={onSelectClick} isActive={isActive} />
        <Checkbox isActive={isActive} />
        <List isActive={isActive} />
        <TextField isActive={isActive} />
      </div>
    );
  }
  return (
    <>
      <LoadingMarketAttribute style={{ width: '100%' }} />
      <LoadingMarketAttribute style={{ margin: '0.5rem 0 0', width: '100%' }} />
      <LoadingMarketAttribute style={{ margin: '0.5rem 0 0', width: '100%' }} />
    </>
  );
});

export default Attributes;
