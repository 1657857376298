import PropTypes from 'prop-types';
import cn from 'classnames';

import UiSlider from '../UiSlider';
import UiCard from '../UiCard';
import UiImage from '../UiImage';

import './UiSliderImages.css';

/**
 * Слайдер изображений
 * @public
 * @version 1.9.0
 * @param {Object[]} images
 * @param {String} images[].className
 * @param {String} images[].src
 * @param {String} images[].alt
 * @param props
 * @return {JSX.Element|null}
 * @constructor
 * @example
 * <UiSlider />
 */
const UiSliderImages = ({
  images,
  ...props
}) => {
  return (
    <UiSlider {...props}>
      {images.map((image, index) => {
        const { id, className, src, alt, ...props } = image;

        return (
          <UiCard
            key={index}
            id={id}
            className={cn('ui-slider-images--content', className)}
            shadow={true}
          >
            <UiImage
              {...props}
              className="ui-slider-images--image"
              src={src}
              alt={alt}
            />
          </UiCard>
        )
      })}
    </UiSlider>
  );
};

UiSliderImages.propTypes = {
  /** Набор изображений слайдера */
  images: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      src: PropTypes.string,
      alt: PropTypes.string
    })
  )
};

UiSliderImages.defaultProps = {
  images: []
};

export default UiSliderImages;