import React from 'react';
import { useHistory } from 'react-router';
import {
  BreadcrumbLink, Breadcrumbs, BreadcrumbSeparator, BreadcrumbText,
} from './style';

const Breadcrumb = ({ links, separator, style }) => {
  const history = useHistory();
  const renderLinks = (link, index) => {
    if (index !== links.length - 1) {
      return (
        <React.Fragment key={link.href}>
          <BreadcrumbLink
            onClick={() => history.push(link.href)}
          >
            {link.title}
          </BreadcrumbLink>
          <BreadcrumbSeparator>
            {separator || '/'}
          </BreadcrumbSeparator>
        </React.Fragment>
      );
    }
    return (
      <BreadcrumbText key={link.href}>
        {link.title}
      </BreadcrumbText>
    );
  };

  return (
    <Breadcrumbs style={style}>
      {links && links.map(renderLinks)}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
