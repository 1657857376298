import React, { useReducer } from 'react';
import { UiInput, useOptions } from '@compassplus/ui-mobicash';
import { observer } from 'mobx-react-lite';
import { action } from 'mobx';
import i18n from '../../../../i18n';
import OrderStore from '../../../../stores/OrderStore';

const PersonalData = observer(() => {
  const isOptions = useOptions();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  return (
    <>
      {
        OrderStore.selectedDelivery.personalData !== undefined ? (
          <>
            {OrderStore.selectedDelivery.personalData.map((field, index) => (
              <UiInput
                key={`${field.title.en}-${index}`}
                description={field.title[isOptions.language]}
                required={field.required}
                value={field.value[isOptions.language]}
                placeholder={i18n.t(`[${i18n.t('Text')}]`)}
                onChange={action((event) => { field.setValue(isOptions.language, event.target.value); forceUpdate(); })}
                bottomDivider={
                  index !== OrderStore.selectedDelivery.personalData.length - 1
                  || OrderStore.selectedDelivery.attributes.findIndex((el) => el.type === 'Birthday') !== -1
                }
              />
            ))}
          </>
        ) : null
      }
    </>
  );
});

export default PersonalData;
