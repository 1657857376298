import { useMemo } from 'react';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../../../context/ThemeProvider';

import UiIcon from '../../../UiIcon';

import styles from './Panel.module.css';

const StyledComment = styled.span`
  ${ (props) => css`
    ${ props.styles.fonts.regular }
    font-size: 14px;
    line-height: 17px;
    color: ${ props.styles.colors.textTitle };
  ` }
`;

const StyledStatus = styled.div`
  border: 1px solid ${ (props) => props.styles.colors.textReadOnly };
  
  ${ (props) => props.active && css`
    background: ${ props.styles.colors.textReadOnly };
  ` }
`;

const Panel = ({
  comment,
  value,
  maxLength,
  onCancel
}) => {

  const isTheme = useTheme();

  const indicators = useMemo(() => Array.from({ length: maxLength }, (_, index) => value[index]), [maxLength, value]);

  return (
    <div className={ styles.panel }>
      <UiIcon
        onClick={ onCancel }
        setWidth="16px"
        setHeight="16px"
        viewBox="0 0 28 28"
        setColor={ isTheme.styles.colors.textTitle }
        icon="cancel"
      />
      <StyledComment className={ styles.comment } styles={ isTheme.styles }>
        { comment }
      </StyledComment>
      <div className={ styles.indicators }>
        { indicators.length <= 4 && indicators.map((indicator, index) => {
          return (
            <StyledStatus
              key={ index }
              className={ styles.indicator }
              active={ indicator !== undefined }
              styles={ isTheme.styles }
            />
          );
        }) }
      </div>
    </div>
  );
};

export default Panel;