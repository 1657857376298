import React from 'react';
import {
  UiCard, useOptions, UiDropdownSheet,
} from '@compassplus/ui-mobicash';
import i18n from '../../../i18n';

import { MobileActionSheet } from './style';

const ActionSheet = (props) => {
  const isOptions = useOptions();
  const { open, setOpen } = props;

  if (isOptions.screen === 'mobile') {
    return (
      <MobileActionSheet
        isOpened={open}
        cancelClick={() => setOpen()}
        cancelTitle={i18n.t('Cancel')}
      >
        <UiCard>
          {props.children}
        </UiCard>
      </MobileActionSheet>
    );
  }

  return (
    <UiDropdownSheet onClose={() => setOpen()} isOpened={open}>
      {props.children}
    </UiDropdownSheet>
  );
};

export default ActionSheet;
