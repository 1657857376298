import { makeAutoObservable, action } from 'mobx';
import ModalStore from './ModalStore';
import {getCurrency, getMerchantId} from '../Utils/requestUtil';
import i18n from "../i18n";
import AttributesStore from "./AttributesStore";
import Merchant from "./Merchant";
import MarketStore from "./MarketStore";

const STATUS_ACTIVE = 'Active';
const STATUS_INACTIVE = 'Inactive';

const packAttribute = (attribute) => {
  const response = { ...attribute };
  switch (attribute.type) {
    case 'radio':
    case 'color':
    case 'checkbox':
      response.complexValue = attribute.complexValue.filter((cValue) => cValue.value);
      return response;
    case 'list':
    case 'textField':
    default:
      return response;
  }
}

class ItemStore {
  isLoading = false;
  article = '';
  title = { en: '', ru: '' };
  price = '';
  currency = getCurrency();
  unit = { en: 'pc.', ru: 'шт.' };
  description = { en: '', ru: '' };
  attributes = [];
  images = [];
  attributeDependentPrice = [];
  countDependencies = {};
  fullAttributeNameCountDependencies = [];
  countOfEachSize = {};
  amounts = null;
  isInfiniteAmount = false;
  differentPriceFlag = false;
  status = STATUS_ACTIVE;
  availability = 'Available';
  totalAmount = 1;
  groupGuid = null;
  sku_id = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  clearItem() {
    this.isLoading = false;
    this.article = '';
    this.title = { en: '', ru: '' };
    this.price = '';
    this.currency = getCurrency();
    this.unit = { en: 'pc.', ru: 'шт.' };
    this.description = { en: '', ru: '' };
    this.attributes = [];
    this.images = [];
    this.countDependencies = {};
    this.fullAttributeNameCountDependencies = [];
    this.attributeDependentPrice = [];
    this.countOfEachSize = {};
    this.amounts = undefined;
    this.isInfiniteAmount = false;
    this.activeByTime = true;
    this.differentPriceFlag = false;
    this.status = STATUS_ACTIVE;
    this.availability = 'Available';
    this.totalAmount = 1;
    this.groupGuid = undefined;

    this.sku_id = undefined;

    if (AttributesStore.itemId) {
      AttributesStore.attributes = [];
      AttributesStore.itemId = null;
    }
  }

  setItem = (id, isMarket) => {
    this.isLoading = false;
    //if (this.article !== id) {
      this.isLoading = true;
      fetch(`/api/v1/public/item/${id}`, {
        method: 'GET',
        headers: {
          'merchantGuid': Merchant.guid || getMerchantId()
        }
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          ModalStore.openUnexpectedErrorModal();
        })
        .then(action((item) => {
          this.isLoading = false;
          this.article = item.article;
          this.title = item.title;
          this.description = item.description;
          this.currency = item.currency;
          this.totalAmount = item.maxvalue || 0;
          this.availability = item.availability ? 'Available' : 'NotAvailable';
          this.status = item.status ? STATUS_ACTIVE : STATUS_INACTIVE;
          this.isInfiniteAmount = item.isInfiniteAmount || false;
          this.price = (Number(item.price) / 100).toFixed(2);
          this.unit = item.quantity;
          this.attributeDependentPrice = [];
          this.attributes = item.attributes || [];
          this.groupGuid = item.groupGuid._id || item.groupGuid;
          this.activeByTime = item.activeByTime;
          this.amounts = item.amounts || [];
          this.countOfEachSize = item.countOfEachSize || {};
          this.countDependencies = item.countDependencies || {};
          this.fullAttributeNameCountDependencies = item.fullAttributeNameCountDependencies || [];

          if (MarketStore.breadcrumbs.length === 0) {
            MarketStore.setMarketData(Merchant.guid, this.groupGuid, i18n.language, item);
          } else {
            MarketStore.breadcrumbs.push({
              title: item.title[i18n.language],
              href: `/${Merchant.marketLink}/order/${item.article}`
            })
          }

          //webasyst
          this.sku_id = item.sku_id

          const imagesArr = [];
          item.imgs.map((image) => {
            if (image.isMain) {
              imagesArr.unshift(image);
            } else {
              imagesArr.push(image);
            }
          });
          this.images = imagesArr;

          if (item.attributeDependentPrice) {
            this.attributeDependentPrice = item.attributeDependentPrice.map((el) => {
              return {
                price: (Number(el.price) / 100).toFixed(2),
                size: el.size
              }
            });
            if (item.attributeDependentPrice.length !== 0) {
              this.differentPriceFlag = true;
            }
          }

          if (this.currency === '810') {
            this.currency = '643';
          }

          if (isMarket || !AttributesStore.itemId || AttributesStore.itemId !== item.article) {
            AttributesStore.setAttributes(item.attributes || []);
            if (Object.keys(this.countDependencies).length !== 0) {
              AttributesStore.isCountDependsOnAttributes = true;
              AttributesStore.countDependencies = this.countDependencies;
              AttributesStore.getFullNameAttributesArr();
              AttributesStore.getActiveAttributes();
            }
            AttributesStore.setItemId(item.article);
          }
        }));
    //}
  }

  onChangePrice = (event) => {
    event.currentTarget.value = event.currentTarget.value.replace(/[^0-9.]/g, '');
    this.price = event.currentTarget.value;
  }

  onPriceBlur = (event) => {
    if (event.currentTarget.value !== '') {
      event.currentTarget.value = Number(event.currentTarget.value).toFixed(2);
      this.price = event.currentTarget.value;
    }
  }

  onChangeTotalAmount = (event) => {
    const amount = Number(event.target.value);
    if (amount >= 0) {
      this.totalAmount = amount;
    }
    this.availability = (amount !== 0 ? 'Available' : 'NotAvailable');
  }

  getAttributesByType(type) {
    const filteredAttributes = this.attributes.filter((el) => el.type === type);
    if (filteredAttributes.length > 0) {
      return filteredAttributes[0].complexValue.map((el) => el.title);
    }
    return [];
  }

  async checkRequiredFields() {
    return new Promise((resolve, reject) => {
      if (!this.currency || !this.price) {
        if (!this.currency && !this.price) {
          return reject(new Error('currency, price'));
        } if (!this.currency) {
          return reject(new Error('currency'));
        }
        return reject(new Error('price'));
      }
      if ((i18n.language === 'ru' && this.title.ru === '') || (i18n.language === 'en' && this.title.en === '')) {
        return reject(new Error('title'));
      }

      if ((i18n.language === 'ru' && this.unit.ru === '') || (i18n.language === 'en' && this.unit.en === '')) {
        return reject(new Error('unit'));
      }

      return resolve();
    });
  }

  async fillRequestData() {
    return new Promise((resolve, reject) => {
      this.checkRequiredFields().then(
        () => {
          const requestData = {
            title: this.title,
            description: this.description,
            unit: this.unit,
            groupGuid: this.groupGuid,
            price: this.price,
            currency: this.currency,
            attributesAmount: this.amounts,
            totalAmount: this.totalAmount,
            isInfiniteAmount: this.isInfiniteAmount,
            attributeDependentPrice: (this.differentPriceFlag) ? this.attributeDependentPrice : [],
            attributes: AttributesStore.attributes,
            countDependencies: !this.isInfiniteAmount ? this.countDependencies : {},
            fullAttributeNameCountDependencies: !this.isInfiniteAmount ? this.fullAttributeNameCountDependencies : [],
          };

          requestData.status = this.status === STATUS_ACTIVE;
          requestData.availability = this.availability === 'Available';

          if (i18n.language === 'ru') {
            if (this.title.en === '') {
              requestData.title.en = this.title.ru;
            }
            if (this.description.en === '') {
              requestData.description.en = this.description.ru;
            }
            if (this.unit.en === '') {
              requestData.unit.en = this.unit.ru.value;
            }
          } else {
            if (this.title.ru === '') {
              requestData.title.ru = this.title.en;
            }
            if (this.description.ru.value === '') {
              requestData.description.ru = this.description.en.value;
            }
            if (this.unit.ru.value === '') {
              requestData.unit.ru = this.unit.en;
            }
          }

          if (this.amounts && this.amounts.length) {
            requestData.totalAmount = 0;
            this.amounts.forEach((el) => requestData.totalAmount += Number(el.amount));
          }

          if (this.attributeDependentPrice.length > 0) {
            const zeroPriceIndex = this.attributeDependentPrice.findIndex((element) => element.price == 0);
            if (zeroPriceIndex !== -1) {
              reject(new Error('Zero prices'));
            } else {
              resolve(requestData);
            }
          } else {
            resolve(requestData);
          }
        },
        (emptyFields) => {
          reject(emptyFields);
        },
      );
    });
  }

  checkIsActive = () => {
    return this.availability && (this.totalAmount > 0 || this.isInfiniteAmount) && this.activeByTime
  }

  getPackedOrderAttributes = () => {
    const attributes = AttributesStore.attributes;
    return attributes.map(packAttribute);
  }
}

export default new ItemStore();
