import PropTypes from 'prop-types';
import cn from 'classnames';
import { TransitionGroup } from 'react-transition-group';

import UiDropDownContainerItem from './UiDropDownContainerItem';

import './UiDropDownContainer.css';

/**
 * Компонент раскрывающегося контейнера
 * @public
 * @version 1.9.0
 * @param {String} className
 * @param innerRef
 * @param {*} children
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiDropDownContainer>
 *   { условие &&
 *     <UiDropDownContainer.Item>
 *       {контент}
 *     </UiDropDownContainer.Item>
 *   }
 * </UiDropDownContainer>
 */
const UiDropDownContainer = ({
  className,
  innerRef,
  children,
  ...props
}) => {

  return (
    <div
      {...props}
      className={cn('ui-drop-down-container', className)}
      ref={innerRef}
    >
      <TransitionGroup component={null}>
        {children}
      </TransitionGroup>
    </div>
  );
};

UiDropDownContainer.displayName = 'UiDropDownContainer';

UiDropDownContainer.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  /** Дочерние элементы */
  children: PropTypes.any
};

export default Object.assign(UiDropDownContainer, {
  Item: UiDropDownContainerItem
});