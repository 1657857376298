import UiPointer from '../../UiPointer';

import './Points.css';

const Points = ({
  items,
  slide,
  goToSlide
}) => {

  return (
    <div className="ui-slider--points">
      {items.map((point, index) => {
        return (
          <UiPointer
            key={index}
            active={slide === index}
            onClick={() => goToSlide(index)}
          />
        );
      })}
    </div>
  );
};

export default Points;