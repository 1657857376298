import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Element component
 * @public
 * @version 0.0.90
 * @param {Boolean} [show=false]
 * @param {Function} setShow
 * @param {Function} onClick
 * @param {Boolean} [disabled=false]
 * @param {Boolean} [propagation=true]
 * @param {Function|Object} [innerRef]
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiEye />
 */
const UiEye = ({
  show,
  setShow,
  onClick,
  disabled,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  const handlerClick = () => {
    setShow(!show);
    onClick && onClick();
  }

  return (
    <Element
      { ...props }
      color={ isTheme.styles.colors.textLink }
      disabled={ disabled }
      propagation={ false }
      onClick={ handlerClick }
      innerRef={ innerRef }
      width="1.5rem"
      height="1.25rem"
      viewBox="0 0 24 20"
    >
      { show ?
        <>
          <path fillRule="evenodd" clipRule="evenodd"
            d="M24 10.005C24 10.005 19.5 1.78003 12 1.78003C4.5 1.78003 0 10.005 0 10.005C0 10.005 4.5 18.23 12 18.23C19.5 18.23 24 10.005 24 10.005ZM1.7595 10.005C2.48478 11.104 3.31897 12.1275 4.2495 13.0602C6.18 14.9879 8.82 16.7346 12 16.7346C15.18 16.7346 17.8185 14.9879 19.752 13.0602C20.6825 12.1275 21.5167 11.104 22.242 10.005C21.5167 8.90606 20.6825 7.88251 19.752 6.94982C17.8185 5.02217 15.1785 3.27548 12 3.27548C8.82 3.27548 6.1815 5.02217 4.248 6.94982C3.31746 7.88251 2.48327 8.90606 1.758 10.005H1.7595Z" />
          <path fillRule="evenodd" clipRule="evenodd"
            d="M12 6.26645C11.0054 6.26645 10.0516 6.66034 9.34835 7.36147C8.64509 8.0626 8.25 9.01354 8.25 10.0051C8.25 10.9966 8.64509 11.9476 9.34835 12.6487C10.0516 13.3498 11.0054 13.7437 12 13.7437C12.9946 13.7437 13.9484 13.3498 14.6517 12.6487C15.3549 11.9476 15.75 10.9966 15.75 10.0051C15.75 9.01354 15.3549 8.0626 14.6517 7.36147C13.9484 6.66034 12.9946 6.26645 12 6.26645ZM6.75 10.0051C6.75 8.61692 7.30312 7.28561 8.28769 6.30403C9.27226 5.32244 10.6076 4.771 12 4.771C13.3924 4.771 14.7277 5.32244 15.7123 6.30403C16.6969 7.28561 17.25 8.61692 17.25 10.0051C17.25 11.3933 16.6969 12.7246 15.7123 13.7061C14.7277 14.6877 13.3924 15.2392 12 15.2392C10.6076 15.2392 9.27226 14.6877 8.28769 13.7061C7.30312 12.7246 6.75 11.3933 6.75 10.0051Z" />
        </>
        :
        <>
          <path
            d="M19.5411 14.3448C19.8204 14.6236 20.27 14.6373 20.5546 14.3638C22.7794 12.2261 23.9999 10 23.9999 10C23.9999 10 19.4999 1.77686 11.9999 1.77686C10.94 1.78049 9.88888 1.94342 8.8816 2.25829C8.35972 2.42143 8.23406 3.07428 8.62107 3.46054C8.83396 3.67301 9.14971 3.74027 9.43764 3.65336C10.2677 3.40282 11.1309 3.27409 11.9999 3.27198C15.1799 3.27198 17.8184 5.01828 19.7519 6.94549C20.6825 7.87798 21.5167 8.9013 22.2419 10C22.1549 10.1301 22.0589 10.2736 21.9494 10.4306C21.4469 11.1483 20.7044 12.1052 19.7519 13.0546C19.6896 13.1167 19.6266 13.1787 19.5629 13.2404C19.2528 13.5406 19.2356 14.0399 19.5411 14.3448Z" />
          <path
            d="M16.0103 10.8261C16.4455 11.2599 17.1742 11.0843 17.2302 10.4725C17.2798 9.93193 17.2449 9.38428 17.1243 8.84943C16.9063 7.8825 16.4174 6.99713 15.7143 6.29636C15.0113 5.59559 14.123 5.10822 13.1529 4.89096C12.6167 4.77088 12.0677 4.73605 11.5258 4.78524C10.9132 4.84085 10.7378 5.57072 11.1734 6.00495C11.3596 6.19049 11.622 6.27177 11.8847 6.26369C12.3251 6.25014 12.7656 6.31405 13.1857 6.45356C13.7382 6.63704 14.2403 6.94625 14.652 7.35669C15.0638 7.76713 15.374 8.26751 15.5581 8.8182C15.6976 9.23556 15.7618 9.67311 15.7487 10.1107C15.7408 10.3751 15.8229 10.6393 16.0103 10.8261ZM12.1159 13.7364C12.3783 13.7283 12.6404 13.8095 12.8263 13.9948C13.2614 14.4285 13.0862 15.1575 12.4744 15.2132C11.9319 15.2626 11.3824 15.2278 10.8457 15.1076C9.87558 14.8904 8.98733 14.403 8.28427 13.7022C7.58121 13.0015 7.09225 12.1161 6.87429 11.1492C6.75384 10.6148 6.71888 10.0678 6.76819 9.5277C6.82413 8.91511 7.55373 8.73934 7.9894 9.1736C8.17703 9.36062 8.2592 9.62515 8.25135 9.88995C8.23838 10.3273 8.30253 10.7647 8.44199 11.1819C8.62607 11.7326 8.93628 12.233 9.34806 12.6434C9.75984 13.0538 10.2619 13.3631 10.8143 13.5465C11.2346 13.6861 11.6753 13.75 12.1159 13.7364Z" />
          <path
            d="M4.46028 5.6554C4.76627 5.95996 4.75029 6.45814 4.43954 6.75784C4.37493 6.82015 4.31108 6.88273 4.248 6.9456C3.31747 7.8781 2.48328 8.90141 1.758 10.0001L2.0505 10.4307C2.553 11.1484 3.2955 12.1053 4.248 13.0547C6.1815 14.9819 8.8215 16.7282 12 16.7282C12.8989 16.7282 13.7537 16.5889 14.5626 16.3445C14.8503 16.2575 15.1656 16.3255 15.3783 16.538C15.7657 16.9251 15.6396 17.579 15.1168 17.7423C14.11 18.0569 13.0594 18.2196 12 18.2233C4.5 18.2233 0 10.0001 0 10.0001C0 10.0001 1.21896 7.77329 3.44434 5.63676C3.7296 5.36288 4.18 5.37642 4.46028 5.6554Z" />
          <path fillRule="evenodd" clipRule="evenodd"
            d="M20.9983 18.9725C20.7057 19.2641 20.2323 19.2641 19.9397 18.9725L3.00172 2.08954C2.70779 1.79656 2.70779 1.32053 3.00172 1.02755C3.29431 0.735914 3.76768 0.735914 4.06026 1.02755L20.9983 17.9105C21.2922 18.2034 21.2922 18.6795 20.9983 18.9725Z" />
        </>
      }
    </Element>
  );
};

UiEye.propTypes = {
  /** Флаг состояния */
  show: PropTypes.bool,
  /** Функция изменения состояния */
  setShow: PropTypes.func,
  /** Обработчик события при клике */
  onClick: PropTypes.func,
  /** Компонент неактивен */
  disabled: PropTypes.bool,
  /** Разрешить/запретить всплытие обработчика onClick */
  propagation: PropTypes.bool,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

UiEye.defaultProps = {
  show: false,
  setShow: () => {},
  disabled: false,
  propagation: true
};

export default UiEye;