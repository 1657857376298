import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Иконка легких платежей
 * @public
 * @version 1.5.0
 * @param {String} [width="3.25rem"]
 * @param {String} [height="3.25rem"]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * import { UiEasyPaymentsIcon, UiPlates, UiPlate } from '@compassplus/ui-mobicash';
 *
 * const App = () => {
 *
 *   return (
 *     <>
 *       <UiEasyPaymentsIcon />
 *       <UiPlates>
 *         <UiPlate view="2x1" image={ <UiEasyPaymentsIcon width="100%" height="100%" /> } />
 *       </UiPlates>
 *     </>
 *   );
 * }; */
const UiEasyPaymentsIcon = ({
  width,
  height,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      innerRef={ innerRef }
      width={ width }
      height={ height }
      viewBox="0 0 52 52"
    >
      <rect x="10" y="9" width="27.5" height="29" rx="1" fill={ isTheme.styles.colors.sectionBackground } />
      <path
        d="M43.5199 34.145C43.1978 33.4976 42.7423 32.9257 42.1835 32.467C42.3582 32.215 42.5064 31.9456 42.6256 31.6631C42.9312 30.9504 43.0728 30.1781 43.0398 29.4033C43.0068 28.6285 42.8 27.8711 42.4349 27.1869C42.0697 26.5028 41.5555 25.9095 40.9301 25.4509C40.3048 24.9922 39.5844 24.68 38.8221 24.5373C38.0599 24.3946 37.2753 24.425 36.5264 24.6263C35.7775 24.8276 35.0834 25.1947 34.4955 25.7003C33.9075 26.206 33.4407 26.8373 33.1296 27.5477C32.8185 28.258 32.6711 29.0292 32.6981 29.8042C31.929 29.6165 31.1268 29.6095 30.3545 29.7838C29.5822 29.9581 28.8608 30.3091 28.2469 30.809C27.1817 31.6419 26.4856 32.8593 26.3081 34.1997C26.1306 35.5401 26.4859 36.8967 27.2976 37.9781C28.1093 39.0595 29.3127 39.7794 30.6493 39.9833C31.986 40.1872 33.3493 39.8587 34.4465 39.0685C35.1587 40.1485 36.2502 40.9219 37.5051 41.2357C38.7601 41.5496 40.0872 41.3811 41.2239 40.7635C42.3605 40.1459 43.2241 39.1242 43.6436 37.9005C44.0632 36.6768 44.0083 35.3402 43.4897 34.155L43.5199 34.145Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path
        d="M15.4155 15.6164C16.0814 15.6164 16.6212 15.0766 16.6212 14.4106C16.6212 13.7447 16.0814 13.2049 15.4155 13.2049C14.7496 13.2049 14.2097 13.7447 14.2097 14.4106C14.2097 15.0766 14.7496 15.6164 15.4155 15.6164Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path
        d="M15.4155 23.0721C16.0814 23.0721 16.6212 22.5322 16.6212 21.8663C16.6212 21.2004 16.0814 20.6605 15.4155 20.6605C14.7496 20.6605 14.2097 21.2004 14.2097 21.8663C14.2097 22.5322 14.7496 23.0721 15.4155 23.0721Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path
        d="M15.3954 33.8637C15.3256 33.8648 15.2563 33.851 15.1922 33.8233C15.1281 33.7955 15.0707 33.7545 15.0237 33.7029L13.6672 32.256C13.6201 32.2093 13.5827 32.1537 13.5572 32.0925C13.5317 32.0312 13.5186 31.9656 13.5186 31.8992C13.5186 31.8329 13.5317 31.7673 13.5572 31.7061C13.5827 31.6448 13.6201 31.5893 13.6672 31.5426C13.713 31.4958 13.7677 31.4586 13.8281 31.4332C13.8885 31.4078 13.9533 31.3948 14.0188 31.3948C14.0844 31.3948 14.1492 31.4078 14.2096 31.4332C14.27 31.4586 14.3247 31.4958 14.3705 31.5426L15.3753 32.5976L18.1888 29.4626C18.2844 29.3699 18.4123 29.3181 18.5455 29.3181C18.6787 29.3181 18.8066 29.3699 18.9022 29.4626C18.9493 29.5093 18.9867 29.5649 19.0122 29.6261C19.0377 29.6873 19.0508 29.753 19.0508 29.8193C19.0508 29.8856 19.0377 29.9513 19.0122 30.0125C18.9867 30.0738 18.9493 30.1293 18.9022 30.176L15.7672 33.7029C15.7217 33.7537 15.666 33.7944 15.6036 33.8221C15.5412 33.8498 15.4737 33.864 15.4055 33.8637H15.3954Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path
        d="M15.4053 12.3106C15.472 12.3106 15.5359 12.2841 15.583 12.237C15.6301 12.1899 15.6565 12.126 15.6565 12.0594C15.6565 11.9928 15.6301 11.9289 15.583 11.8818C15.5359 11.8347 15.472 11.8082 15.4053 11.8082C14.8946 11.8082 14.3953 11.9596 13.9707 12.2434C13.546 12.5272 13.215 12.9305 13.0196 13.4023C12.8241 13.8742 12.773 14.3934 12.8726 14.8943C12.9723 15.3952 13.2182 15.8554 13.5794 16.2165C13.9405 16.5777 14.4006 16.8236 14.9016 16.9233C15.4025 17.0229 15.9217 16.9718 16.3936 16.7763C16.8654 16.5809 17.2687 16.2499 17.5525 15.8252C17.8362 15.4006 17.9877 14.9013 17.9877 14.3905C17.9877 14.3239 17.9612 14.26 17.9141 14.2129C17.867 14.1658 17.8031 14.1393 17.7365 14.1393C17.6699 14.1393 17.606 14.1658 17.5589 14.2129C17.5118 14.26 17.4853 14.3239 17.4853 14.3905C17.4853 14.8019 17.3633 15.204 17.1348 15.5461C16.9062 15.8881 16.5814 16.1547 16.2013 16.3122C15.8212 16.4696 15.403 16.5108 14.9996 16.4305C14.5961 16.3502 14.2255 16.1522 13.9346 15.8613C13.6437 15.5704 13.4456 15.1998 13.3654 14.7963C13.2851 14.3928 13.3263 13.9746 13.4837 13.5946C13.6412 13.2145 13.9077 12.8897 14.2498 12.6611C14.5918 12.4326 14.994 12.3106 15.4053 12.3106Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M15.4055 24.4185C16.0886 24.4185 16.7439 24.1478 17.2279 23.6657C17.7119 23.1836 17.9852 22.5293 17.9878 21.8462C17.9878 21.7796 17.9614 21.7157 17.9143 21.6686C17.8671 21.6215 17.8032 21.595 17.7366 21.595C17.67 21.595 17.6061 21.6215 17.559 21.6686C17.5119 21.7157 17.4854 21.7796 17.4854 21.8462C17.4854 22.2576 17.3634 22.6597 17.1349 23.0018C16.9063 23.3438 16.5815 23.6104 16.2014 23.7678C15.8214 23.9252 15.4032 23.9664 14.9997 23.8862C14.5962 23.8059 14.2256 23.6078 13.9347 23.3169C13.6439 23.026 13.4457 22.6555 13.3655 22.252C13.2852 21.8485 13.3264 21.4303 13.4839 21.0502C13.6413 20.6702 13.9079 20.3453 14.2499 20.1168C14.592 19.8882 14.9941 19.7663 15.4055 19.7663C15.4721 19.7663 15.536 19.7398 15.5831 19.6927C15.6302 19.6456 15.6567 19.5817 15.6567 19.5151C15.6567 19.4484 15.6302 19.3845 15.5831 19.3374C15.536 19.2903 15.4721 19.2639 15.4055 19.2639C14.7497 19.3048 14.1343 19.5942 13.6844 20.073C13.2346 20.5519 12.9841 21.1842 12.9841 21.8412C12.9841 22.4982 13.2346 23.1305 13.6844 23.6093C14.1343 24.0882 14.7497 24.3775 15.4055 24.4185Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M15.4154 34.8785C16.1002 34.8785 16.7571 34.6064 17.2413 34.1222C17.7256 33.6379 17.9977 32.981 17.9977 32.2962C17.9977 32.2295 17.9712 32.1656 17.9241 32.1185C17.877 32.0714 17.8131 32.045 17.7465 32.045C17.6799 32.045 17.616 32.0714 17.5689 32.1185C17.5218 32.1656 17.4953 32.2295 17.4953 32.2962C17.4953 32.7075 17.3733 33.1097 17.1448 33.4517C16.9162 33.7938 16.5914 34.0603 16.2113 34.2178C15.8313 34.3752 15.413 34.4164 15.0096 34.3361C14.6061 34.2559 14.2355 34.0578 13.9446 33.7669C13.6537 33.476 13.4556 33.1054 13.3754 32.7019C13.2951 32.2985 13.3363 31.8802 13.4937 31.5002C13.6512 31.1201 13.9178 30.7953 14.2598 30.5667C14.6018 30.3382 15.004 30.2162 15.4154 30.2162C15.482 30.2162 15.5459 30.1898 15.593 30.1426C15.6401 30.0955 15.6666 30.0316 15.6666 29.965C15.6666 29.8984 15.6401 29.8345 15.593 29.7874C15.5459 29.7403 15.482 29.7138 15.4154 29.7138C14.7305 29.7138 14.0736 29.9859 13.5894 30.4702C13.1051 30.9545 12.833 31.6113 12.833 32.2962C12.833 32.981 13.1051 33.6379 13.5894 34.1222C14.0736 34.6064 14.7305 34.8785 15.4154 34.8785Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M20.2688 13.2752H28.9201C28.9868 13.2752 29.0507 13.2487 29.0978 13.2016C29.1449 13.1545 29.1713 13.0906 29.1713 13.024C29.1713 12.9574 29.1449 12.8935 29.0978 12.8464C29.0507 12.7993 28.9868 12.7728 28.9201 12.7728H20.2688C20.2022 12.7728 20.1383 12.7993 20.0911 12.8464C20.044 12.8935 20.0176 12.9574 20.0176 13.024C20.0176 13.0906 20.044 13.1545 20.0911 13.2016C20.1383 13.2487 20.2022 13.2752 20.2688 13.2752Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M32.236 15.4154C32.236 15.3488 32.2095 15.2849 32.1624 15.2378C32.1153 15.1907 32.0514 15.1642 31.9848 15.1642H20.2688C20.2022 15.1642 20.1383 15.1907 20.0911 15.2378C20.044 15.2849 20.0176 15.3488 20.0176 15.4154C20.0176 15.4821 20.044 15.546 20.0911 15.5931C20.1383 15.6402 20.2022 15.6666 20.2688 15.6666H31.9848C32.0514 15.6666 32.1153 15.6402 32.1624 15.5931C32.2095 15.546 32.236 15.4821 32.236 15.4154Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M32.1154 20.4696C32.1154 20.403 32.089 20.3391 32.0418 20.292C31.9947 20.2449 31.9308 20.2184 31.8642 20.2184H20.2688C20.2022 20.2184 20.1383 20.2449 20.0911 20.292C20.044 20.3391 20.0176 20.403 20.0176 20.4696C20.0176 20.5362 20.044 20.6001 20.0911 20.6472C20.1383 20.6943 20.2022 20.7208 20.2688 20.7208H31.8642C31.9308 20.7208 31.9947 20.6943 32.0418 20.6472C32.089 20.6001 32.1154 20.5362 32.1154 20.4696Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M29.4929 22.851C29.4929 22.7844 29.4664 22.7205 29.4193 22.6734C29.3722 22.6262 29.3083 22.5998 29.2417 22.5998H20.2688C20.2022 22.5998 20.1383 22.6262 20.0911 22.6734C20.044 22.7205 20.0176 22.7844 20.0176 22.851C20.0176 22.9176 20.044 22.9815 20.0911 23.0286C20.1383 23.0757 20.2022 23.1022 20.2688 23.1022H29.2417C29.3083 23.1022 29.3722 23.0757 29.4193 23.0286C29.4664 22.9815 29.4929 22.9176 29.4929 22.851Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M20.2688 32.1957H23.8057C23.8723 32.1957 23.9362 32.1692 23.9833 32.1221C24.0304 32.075 24.0569 32.0111 24.0569 31.9445C24.0569 31.8778 24.0304 31.8139 23.9833 31.7668C23.9362 31.7197 23.8723 31.6933 23.8057 31.6933H20.2688C20.2022 31.6933 20.1383 31.7197 20.0911 31.7668C20.044 31.8139 20.0176 31.8778 20.0176 31.9445C20.0176 32.0111 20.044 32.075 20.0911 32.1221C20.1383 32.1692 20.2022 32.1957 20.2688 32.1957Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M20.2688 33.5723H25.1823C25.2489 33.5723 25.3128 33.5458 25.3599 33.4987C25.407 33.4516 25.4335 33.3877 25.4335 33.3211C25.4335 33.2544 25.407 33.1906 25.3599 33.1434C25.3128 33.0963 25.2489 33.0699 25.1823 33.0699H20.2688C20.2022 33.0699 20.1383 33.0963 20.0911 33.1434C20.044 33.1906 20.0176 33.2544 20.0176 33.3211C20.0176 33.3877 20.044 33.4516 20.0911 33.4987C20.1383 33.5458 20.2022 33.5723 20.2688 33.5723Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M35.3708 8H12.2C11.4925 7.99976 10.8129 8.27591 10.3061 8.76958C9.79931 9.26325 9.5054 9.93534 9.48706 10.6426V35.602C9.48706 36.3251 9.77362 37.0187 10.284 37.531C10.7944 38.0433 11.487 38.3324 12.2101 38.335H27.7745C27.6791 38.2497 27.5885 38.1591 27.5032 38.0637C27.3206 37.8343 27.1592 37.5888 27.0209 37.3302H12.2C11.7434 37.3276 11.3064 37.1443 10.9845 36.8205C10.6625 36.4967 10.4818 36.0586 10.4818 35.602V10.7331C10.4818 10.2764 10.6625 9.83836 10.9845 9.51454C11.3064 9.19071 11.7434 9.00746 12.2 9.0048H35.3708C35.8292 9.0048 36.2688 9.18687 36.5929 9.51098C36.917 9.83509 37.0991 10.2747 37.0991 10.7331V24.519C37.4318 24.4719 37.7681 24.4551 38.1039 24.4687V10.7331C38.1039 10.0082 37.8159 9.31304 37.3034 8.80049C36.7908 8.28794 36.0957 8 35.3708 8Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M43.6818 37.7713C43.3884 38.7295 42.7012 39.6483 41.9305 40.2887C41.1599 40.9292 40.3195 41.2557 39.3238 41.3688C38.3282 41.4819 37.5302 41.3047 36.5053 40.9204C35.6106 40.4691 34.9473 39.9163 34.4464 39.0484C33.4289 39.7566 32.1902 40.0742 30.9574 39.9432C29.7246 39.8121 28.5804 39.2412 27.7344 38.335H12.2101C11.4853 38.335 10.7901 38.0471 10.2776 37.5345C9.765 37.022 9.47706 36.3268 9.47706 35.602V16.2997C9.06556 16.445 8.70602 16.7084 8.44332 17.0568C8.18062 17.4053 8.02643 17.8235 8 18.259V38.7269C8.09041 39.3965 8.43765 40.0046 8.96832 40.4228C9.49899 40.8409 10.1715 41.0363 10.8436 40.9676H27.1013C28.093 41.7325 29.2851 42.1936 30.5333 42.2952C31.7816 42.3969 33.0326 42.1347 34.135 41.5403C34.9892 42.514 36.1253 43.1977 37.3856 43.4966C38.646 43.7955 39.9681 43.6947 41.1686 43.2083C43.6002 42.2136 44.556 39.7709 43.6818 37.7713Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path
        d="M33.2708 37.7221C32.5697 38.1227 31.7501 38.2644 30.9552 38.1225C30.1602 37.9806 29.4404 37.5641 28.9212 36.9456C28.402 36.3271 28.1165 35.546 28.1144 34.7385C28.1124 33.931 28.3939 33.1484 28.9099 32.5272C28.937 32.4929 28.957 32.4537 28.9689 32.4116C28.9807 32.3696 28.9842 32.3256 28.9791 32.2822C28.9739 32.2388 28.9603 32.1968 28.939 32.1587C28.9176 32.1206 28.889 32.0871 28.8547 32.06C28.8204 32.033 28.7811 32.0129 28.739 32.0011C28.697 31.9892 28.653 31.9858 28.6096 31.9909C28.5662 31.996 28.5243 32.0097 28.4862 32.031C28.448 32.0523 28.4145 32.081 28.3874 32.1153C27.7321 32.8549 27.3652 33.8059 27.354 34.794C27.3428 35.7821 27.688 36.7412 28.3263 37.4955C28.9647 38.2498 29.8535 38.7488 30.8298 38.9012C31.8062 39.0535 32.8048 38.849 33.6426 38.325C33.7184 38.2809 33.7736 38.2085 33.7962 38.1238C33.8188 38.039 33.8069 37.9488 33.7631 37.8728C33.7432 37.8283 33.714 37.7885 33.6775 37.7562C33.6409 37.7239 33.5979 37.6998 33.5512 37.6855C33.5046 37.6712 33.4554 37.6671 33.4071 37.6734C33.3587 37.6797 33.3122 37.6963 33.2708 37.7221Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M37.3201 33.2909C38.0571 32.9436 38.8918 32.8629 39.6817 33.0627C40.4715 33.2624 41.1675 33.7302 41.6508 34.3862C41.6941 34.4517 41.7599 34.4992 41.8358 34.5196C41.9117 34.5399 41.9925 34.5318 42.0628 34.4967H42.113C42.1486 34.4716 42.179 34.4398 42.2023 34.403C42.2257 34.3662 42.2415 34.3252 42.249 34.2822C42.2565 34.2393 42.2554 34.1953 42.2458 34.1528C42.2363 34.1103 42.2185 34.0701 42.1934 34.0345C41.6341 33.2489 40.8165 32.6851 39.8835 32.4415C38.9505 32.198 37.9615 32.2903 37.0897 32.7022C36.2179 33.1142 35.5187 33.8196 35.1144 34.695C34.7102 35.5705 34.6266 36.5602 34.8784 37.491C34.8879 37.5341 34.9058 37.5749 34.9313 37.611C34.9567 37.6471 34.9891 37.6777 35.0266 37.7011C35.064 37.7245 35.1057 37.7402 35.1493 37.7473C35.1929 37.7543 35.2374 37.7526 35.2803 37.7422C35.3232 37.7329 35.3638 37.7149 35.3995 37.6893C35.4352 37.6637 35.4653 37.6311 35.4879 37.5934C35.5105 37.5558 35.5251 37.5139 35.5309 37.4703C35.5367 37.4268 35.5335 37.3825 35.5215 37.3403C35.3133 36.5614 35.3782 35.7347 35.7055 34.9979C36.0328 34.2611 36.6026 33.6586 37.3201 33.2909Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M42.7662 35.1699C42.7406 35.0861 42.683 35.0158 42.6058 34.9744C42.5286 34.933 42.4381 34.9238 42.3542 34.9488C42.2711 34.9786 42.2023 35.0385 42.1613 35.1167C42.1204 35.1949 42.1103 35.2856 42.1331 35.3709C42.3675 36.1511 42.3221 36.9887 42.0047 37.739C41.6873 38.4893 41.1179 39.1053 40.3948 39.4805C39.6715 39.8232 38.8533 39.9105 38.074 39.7279C37.2946 39.5454 36.6003 39.104 36.1043 38.4757C36.0779 38.4412 36.0447 38.4125 36.0068 38.3912C35.969 38.3699 35.9271 38.3565 35.8839 38.3518C35.8407 38.3471 35.797 38.3512 35.7555 38.3638C35.7139 38.3765 35.6753 38.3975 35.6421 38.4255C35.5813 38.4891 35.5474 38.5736 35.5474 38.6616C35.5474 38.7496 35.5813 38.8342 35.6421 38.8977C36.2317 39.6602 37.0705 40.1913 38.0119 40.398C38.9533 40.6047 39.9374 40.474 40.7922 40.0286C41.647 39.5833 42.318 38.8516 42.688 37.9617C43.0581 37.0717 43.1035 36.08 42.8164 35.1599L42.7662 35.1699Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M34.1249 28.0056C33.8443 28.6889 33.754 29.4355 33.8636 30.166C34.1489 30.3081 34.4183 30.4798 34.6675 30.6784C34.3987 29.8925 34.4237 29.0358 34.7378 28.2669C35.0653 27.541 35.6289 26.9472 36.3368 26.5823C37.0446 26.2174 37.8552 26.1029 38.6365 26.2573C38.7231 26.2718 38.8119 26.2515 38.8835 26.2006C38.9551 26.1497 39.0035 26.0726 39.0183 25.986C39.027 25.9437 39.0271 25.9001 39.0186 25.8578C39.0101 25.8154 38.9931 25.7753 38.9687 25.7396C38.9443 25.704 38.913 25.6736 38.8767 25.6504C38.8403 25.6271 38.7996 25.6114 38.757 25.6042C37.8271 25.4217 36.8628 25.5598 36.0215 25.9959C35.1802 26.4321 34.5117 27.1405 34.1249 28.0056Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M41.0983 30.9598C41.003 31.1759 40.8851 31.3814 40.7466 31.5727C40.9576 31.6631 41.1686 31.7435 41.3696 31.854C41.4992 31.6519 41.6134 31.4403 41.7112 31.221C42.0741 30.3406 42.1255 29.3626 41.8569 28.449C41.5883 27.5354 41.0158 26.7409 40.2341 26.197C40.1985 26.1719 40.1583 26.1541 40.1158 26.1445C40.0733 26.135 40.0293 26.1339 39.9864 26.1414C39.9435 26.1489 39.9024 26.1647 39.8656 26.188C39.8288 26.2114 39.797 26.2417 39.7719 26.2774C39.7202 26.3481 39.6986 26.4364 39.7118 26.523C39.725 26.6096 39.7719 26.6875 39.8423 26.7396C40.5022 27.1938 40.9867 27.8602 41.2152 28.628C41.4437 29.3958 41.4024 30.2187 41.0983 30.9598Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M34.7478 32.1253C34.1363 31.4018 33.2966 30.908 32.3669 30.7254C31.4373 30.5427 30.4732 30.6821 29.6334 31.1205C29.555 31.1594 29.4952 31.2277 29.467 31.3105C29.4387 31.3934 29.4444 31.484 29.4827 31.5626C29.5009 31.6024 29.527 31.638 29.5594 31.6673C29.5918 31.6966 29.6299 31.7191 29.6713 31.7332C29.7127 31.7473 29.7565 31.7528 29.8001 31.7494C29.8436 31.746 29.8861 31.7337 29.9248 31.7134C30.6356 31.3473 31.45 31.2345 32.2336 31.3934C33.0172 31.5523 33.7233 31.9735 34.2354 32.5876C34.4309 32.8385 34.5899 33.1159 34.7076 33.4115C34.8509 33.2094 35.0088 33.018 35.1799 32.8388C35.061 32.5866 34.9162 32.3475 34.7478 32.1253Z"
        fill={ isTheme.styles.colors.textTitle } />
    </Element>
  );
};

UiEasyPaymentsIcon.propTypes = {
  /** Определяет ширину иконки */
  width: PropTypes.string,
  /** Определяет высоту иконки */
  height: PropTypes.string
};

UiEasyPaymentsIcon.defaultProps = {
  width: '3.25rem',
  height: '3.25rem'
};

export default UiEasyPaymentsIcon;