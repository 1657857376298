import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import UiInput from '../../Ui/UiInput';

import { useForkRef } from '../../../hooks';

import { cleanValue, formatValue, getSuffix, repositionCursor } from '../../../utils/amountUtils';
import { fixedDecimalValue, padTrimValue } from '../../../utils/numberUtils';
import { isFunction } from '../../../utils/typeUtils';

/**
 * Компонент поля ввода валюты
 * @public
 * @version 1.6.1
 * @param {Boolean} [allowDecimals=true]
 * @param {Boolean} [allowNegativeValue=true]
 * @param {Number} [decimalsLimit=2]
 * @param {Number} [fixedDecimalLength=0]
 * @param {String} [prefix]
 * @param {String} [suffix]
 * @param {'.'|','} [decimalSeparator='.']
 * @param {'.'|','|' '} [groupSeparator]
 * @param {Object} [intlConfig]
 * @param {Number} [userMaxLength:maxLength=20]
 * @param {Number|String} [defaultValue]
 * @param {Number|String} [userValue]
 * @param {Function} [onValueChange]
 * @param {Function} [onChange]
 * @param {Function} [onFocus]
 * @param {Function} [onBlur]
 * @param {Function} [onKeyDown]
 * @param {Function} [onKeyUp]
 * @param {Function} [transformRawValue]
 * @param {Boolean} [custom]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiInputAmount />
 */
const UiInputAmount = ({
  allowDecimals,
  allowNegativeValue,
  decimalsLimit,
  fixedDecimalLength,
  prefix,
  suffix,
  decimalSeparator,
  groupSeparator,
  intlConfig,
  maxLength: userMaxLength,
  defaultValue,
  value: userValue,
  onValueChange,
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  onKeyUp,
  transformRawValue = (value) => value,
  custom,
  innerRef,
  ...props
}) => {

  if (decimalSeparator && groupSeparator && decimalSeparator === groupSeparator) {
    throw new Error('decimalSeparator cannot be the same as groupSeparator');
  }

  const formattedValueOptions = {
    decimalSeparator,
    groupSeparator,
    prefix,
    suffix,
    intlConfig
  };

  const cleanValueOptions = {
    allowDecimals,
    allowNegativeValue,
    decimalsLimit: decimalsLimit || fixedDecimalLength,
    decimalSeparator,
    groupSeparator,
    prefix,
    transformRawValue
  };

  const formattedStateValue =
    defaultValue !== undefined && defaultValue !== null
      ? formatValue({ value: String(defaultValue), ...formattedValueOptions })
      : userValue !== undefined && userValue !== null
        ? formatValue({ value: String(userValue), ...formattedValueOptions })
        : '';

  const [stateValue, setStateValue] = useState(formattedStateValue);
  const [lastKeyPressed, setTheLastKeyPressed] = useState(null);
  const cursor = useRef(0);
  const inputRef = useRef(null);

  const processChange = (value, selectionStart) => {
    const { modifiedValue, cursorPosition } = repositionCursor({
      selectionStart,
      value,
      lastKeyPressed,
      stateValue,
      groupSeparator
    });

    const stringValue = cleanValue({ value: modifiedValue, ...cleanValueOptions });

    if (userMaxLength && stringValue.replace(/-/g, '').length > userMaxLength) {
      return;
    }

    if (stringValue === '' || stringValue === '-' || stringValue === decimalSeparator) {
      if (isFunction(onValueChange)) {
        onValueChange({
          float: null,
          formatted: '',
          value: ''
        });
      }

      setStateValue(stringValue);
      return;
    }

    const stringValueWithoutSeparator = decimalSeparator
      ? stringValue.replace(decimalSeparator, '.')
      : stringValue;
    const numberValue = parseFloat(stringValueWithoutSeparator);
    const formattedValue = formatValue({ value: stringValue, ...formattedValueOptions });

    if (cursorPosition !== undefined && cursorPosition !== null) {
      let newCursor = cursorPosition + (formattedValue.length - value.length);
      newCursor = newCursor <= 0 ? (prefix ? prefix.length : 0) : newCursor;

      cursor.current = newCursor;
    }

    setStateValue(formattedValue);

    if (isFunction(onValueChange)) {
      onValueChange({
        float: numberValue,
        formatted: formattedValue,
        value: stringValue
      });
    }
  };

  const changeHandler = (event) => {
    const { target: { value, selectionStart } } = event;

    processChange(value, selectionStart);

    if (isFunction(onChange)) {
      onChange(event);
    }
  };

  const focusHandler = (event) => {
    if (isFunction(onFocus)) {
      onFocus(event);
    }

    return stateValue ? stateValue.length : 0;
  };

  const blurHandler = (event) => {
    const { target: { value } } = event;

    const valueOnly = cleanValue({ value, ...cleanValueOptions });

    if (valueOnly === '-' || !valueOnly) {
      setStateValue('');

      if (isFunction(onBlur)) {
        onBlur(event);
      }

      return;
    }

    const fixedDecimals = fixedDecimalValue(valueOnly, decimalSeparator, fixedDecimalLength);
    const newValue = padTrimValue(fixedDecimals, decimalSeparator);
    const numberValue = parseFloat(newValue.replace(decimalSeparator, '.'));
    const formattedValue = formatValue({ value: newValue, ...formattedValueOptions });

    if (isFunction(onValueChange)) {
      onValueChange({
        float: numberValue,
        formatted: formattedValue,
        value: newValue
      });
    }

    setStateValue(formattedValue);

    if (isFunction(onBlur)) {
      onBlur(event);
    }
  };

  const keyDownHandler = (event) => {
    const { key } = event;

    setTheLastKeyPressed(key);

    if (isFunction(onKeyDown)) {
      onKeyDown(event);
    }
  };

  const keyUpHandler = (event) => {
    const { key, target: { selectionStart } } = event;

    if (key !== 'ArrowUp' && key !== 'ArrowDown' && stateValue !== '-') {
      const suffix = getSuffix(stateValue, { groupSeparator, decimalSeparator });

      if (!!suffix && selectionStart && selectionStart > stateValue.length - suffix.length) {
        if (inputRef.current) {
          const newCursor = stateValue.length - suffix.length;

          inputRef.current.setSelectionRange(newCursor, newCursor);
        }
      }
    }

    if (isFunction(onKeyUp)) {
      onKeyUp(event);
    }
  };

  useEffect(() => {
    if (stateValue !== '-' && stateValue !== decimalSeparator && inputRef.current && inputRef.current === document.activeElement) {
      inputRef.current.setSelectionRange(cursor.current, cursor.current);
    }
  }, [stateValue, decimalSeparator]);

  const getRenderValue = () => {
    if (userValue !== undefined && userValue !== null && stateValue !== '-' && (!decimalSeparator || stateValue !== decimalSeparator)) {
      return formatValue({ value: String(userValue), ...formattedValueOptions });
    }

    return stateValue;
  };

  const inputProps = {
    type: 'text',
    inputMode: 'decimal',
    value: getRenderValue(),
    onChange: changeHandler,
    onFocus: focusHandler,
    onBlur: blurHandler,
    onKeyDown: keyDownHandler,
    onKeyUp: keyUpHandler,
    ...props
  };

  const refs = useForkRef([innerRef, inputRef]);

  if (custom) {
    return <UiInput { ...inputProps } innerRef={ refs } />;
  }

  return <input { ...inputProps } ref={ refs } />;
};

UiInputAmount.propTypes = {
  /** Разрешить десятичные дроби */
  allowDecimals: PropTypes.bool,
  /** Разрешить отрицательное значение */
  allowNegativeValue: PropTypes.bool,
  /** Ограничить допустимую длину десятичных знаков */
  decimalsLimit: PropTypes.number,
  /** Значение всегда будет иметь указанную длину десятичных знаков */
  fixedDecimalLength: PropTypes.number,
  /** Включить префикс */
  prefix: PropTypes.string,
  /** Включить суффикс */
  suffix: PropTypes.string,
  /** Разделитель между целой частью и дробной частью значения */
  decimalSeparator: PropTypes.oneOf(['.', ',']),
  /** Разделитель между тысячами, миллионами и миллиардами */
  groupSeparator: PropTypes.oneOf(['.', ',', ' ']),
  /** Конфигурация международной локали */
  intlConfig: PropTypes.shape({
    /** Языковая метка BCP 47 */
    locale: PropTypes.string,
    /** Валюта, используемая при форматировании валют. Возможными значениями являются коды валют ISO 4217 */
    currency: PropTypes.string
  }),
  /** Максимальное количество символов, которое может ввести пользователь */
  maxLength: PropTypes.number,
  /** Значение по умолчанию, если не передано свойство value */
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Неформатированное значение */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Обработчик изменения значения */
  onValueChange: PropTypes.func,
  /** Пользовательский обработчик необработанного значения (Вызывается перед onChange. Необходимо вернуть строку) */
  transformRawValue: PropTypes.func,
  /** Кастомный компонент */
  custom: PropTypes.bool,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

UiInputAmount.defaultProps = {
  allowDecimals: true,
  allowNegativeValue: true,
  decimalsLimit: 2,
  fixedDecimalLength: 0,
  decimalSeparator: '.',
  groupSeparator: ' ',
  maxLength: 20,
  transformRawValue: (value) => value,
  custom: true
};

export default UiInputAmount;