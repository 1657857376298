import { makeAutoObservable } from 'mobx';
import React from 'react';
import i18n from '../i18n';
import { removeUserData } from '../Utils/requestUtil';
import { deleteGroup, deleteItem } from '../pages/admin/MainPage/RenderCards';

class ModalStore {
  constructor() {
    this.setDefaultModal();
    makeAutoObservable(this);
  }

  setDefaultModal() {
    this.isOpen = false;
    this.icon = 'merchant';
    this.title = i18n.t('MerchantService');
    this.mode = 'normal';
    this.text = i18n.t('UnexpectedError');
    this.buttons = [
      {
        text: i18n.t('Reload'),
        mode: 'normal',
        action: () => {
          this.isOpen = false;
          window.location.reload();
          this.setDefaultModal();
        },
      },
    ];
    this.body = null;
  }

  openUnexpectedErrorModal() {
    this.isOpen = true;
  }

  openWithMessage(text) {
    this.isOpen = true;
    this.icon = 'merchant';
    this.title = i18n.t('MerchantService');
    this.mode = 'normal';
    this.text = text;
    this.buttons = [
      {
        text: i18n.t('Ok'),
        mode: 'normal',
        action: () => {
          this.setDefaultModal();
        },
      },
    ];
  }

  openErrorWithMessage(text) {
    this.isOpen = true;
    this.icon = 'merchant';
    this.title = i18n.t('MerchantService');
    this.mode = 'warning';
    this.text = text;
    this.buttons = [
      {
        text: i18n.t('Ok'),
        mode: 'normal',
        action: () => {
          this.setDefaultModal();
        },
      },
    ];
  }

  openLogoutModal() {
    this.isOpen = true;
    this.icon = 'merchant';
    this.title = i18n.t('MerchantService');
    this.mode = 'normal';
    this.text = i18n.t('ConfirmExit');
    this.buttons = [
      {
        text: i18n.t('Logout'),
        mode: 'normal',
        action: () => {
          removeUserData();
          window.location.reload();
          this.setDefaultModal();
        },
      },
      {
        text: i18n.t('Cancel'),
        mode: 'normal',
        action: () => { this.setDefaultModal(); },
      },
    ];
  }

  openManyItemsDeleteConfirm(onDelete, itemsCount) {
    this.isOpen = true;
    this.icon = 'merchant';
    this.title = i18n.t('MerchantService');
    this.mode = 'normal';
    this.text = `${i18n.t('DeleteQuestion')} ${itemsCount}?`;
    this.buttons = [
      {
        text: i18n.t('Submit'),
        mode: 'normal',
        action: () => {
          onDelete();
          this.setDefaultModal();
        },
      },
      {
        text: i18n.t('Cancel'),
        mode: 'normal',
        action: () => { this.setDefaultModal(); },
      },
    ];
  }

  openDeleteConfirm(deleteObj, history) {
    this.isOpen = true;
    this.icon = 'merchant';
    this.title = i18n.t('MerchantService');
    this.mode = 'normal';
    this.text = `${i18n.t('DeleteQuestion')} ${i18n.t(deleteObj.type)}?`;
    this.buttons = [
      {
        text: i18n.t('Submit'),
        mode: 'normal',
        action: () => {
          if (deleteObj.type === 'group') {
            deleteGroup(deleteObj.id, history);
            this.setDefaultModal();
          } else if (deleteObj.type === 'item') {
            deleteItem(deleteObj.id, history);
            this.setDefaultModal();
          }
        },
      },
      {
        text: i18n.t('Cancel'),
        mode: 'normal',
        action: () => { this.setDefaultModal(); },
      },
    ];
  }

  openActionConfirm(text, action) {
    this.isOpen = true;
    this.icon = 'merchant';
    this.title = i18n.t('MerchantService');
    this.mode = 'normal';
    this.text = text;
    this.buttons = [
      {
        text: i18n.t('Submit'),
        mode: 'normal',
        action: () => {
          action();
        },
      },
      {
        text: i18n.t('Cancel'),
        mode: 'normal',
        action: () => { this.setDefaultModal(); },
      },
    ];
  }

  openAddToCart() {
    this.isOpen = true;
    this.icon = 'apply';
    this.title = i18n.t('Cart');
    this.text = i18n.t('AddedToCart');
    this.buttons = [
      {
        text: i18n.t('Ok'),
        mode: 'normal',
        action: () => { this.setDefaultModal(); },
      },
    ]

  }
}

export default new ModalStore();
