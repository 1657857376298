import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Element component
 * @public
 * @version 0.0.97
 * @param {Function} onClick
 * @param {Boolean} [disabled=false]
 * @param {Boolean} [propagation=true]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiPlusForPlates />
 */
const UiPlusForPlates = ({
  onClick,
  disabled,
  propagation,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      disabled={ disabled }
      propagation={ propagation }
      onClick={ onClick }
      innerRef={ innerRef }
      width="0.75rem"
      height="0.75rem"
      viewBox="0 0 12 12"
    >
      <circle cx="6" cy="6" r="6" fill={ isTheme.styles.colors.sectionBackground } />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 3C7 2.44772 6.55228 2 6 2C5.44772 2 5 2.44772 5 3V5H3C2.44772 5 2 5.44772 2 6C2 6.55228 2.44772 7 3 7H5V9C5 9.55228 5.44772 10 6 10C6.55228 10 7 9.55228 7 9V7H9C9.55228 7 10 6.55228 10 6C10 5.44772 9.55228 5 9 5H7V3Z"
        fill={ isTheme.styles.colors.textDisable }
      />
    </Element>
  );
};

UiPlusForPlates.propTypes = {
  /** Обработчик события при клике */
  onClick: PropTypes.func,
  /** Компонент неактивен */
  disabled: PropTypes.bool,
  /** Разрешить/запретить всплытие обработчика onClick */
  propagation: PropTypes.bool,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

UiPlusForPlates.defaultProps = {
  disabled: false,
  propagation: true
};

export default UiPlusForPlates;