import PropTypes from 'prop-types';
import cn from 'classnames';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../context/ThemeProvider';

import styles from './UiImage.module.css';

const StyledImg = styled.img`
  ${ (props) => css`
    ${ props.styles.typography.DialogText };
    color: ${ props.styles.colors.textReadOnly };

    &:after {
      background: ${ props.styles.colors.sectionBackground };
    }
  ` }
`;

/**
 * Image component
 * @public
 * @version 0.0.90
 * @param {String} className
 * @param {String} [src=]
 * @param {String} [alt=Alt]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiImage />
 */
const UiImage = ({
  className,
  src,
  alt,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <StyledImg
      { ...props }
      className={ cn(styles.image, className) }
      src={ src }
      alt={ alt }
      loading="lazy"
      ref={ innerRef }
      styles={ isTheme.styles }
    />
  );
};

UiImage.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Определяет URL-адрес до ресурса */
  src: PropTypes.string,
  /** Определяет альтернативное текстовое описание изображения */
  alt: PropTypes.string
};

UiImage.defaultProps = {
  src: '',
  alt: 'Alt'
};

export default UiImage;