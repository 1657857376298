import React from 'react';

import { UiTabBlock, } from '@compassplus/ui-mobicash';
import { observer } from 'mobx-react-lite';
import i18n from '../../../i18n';
import { Tab } from '../Authorization/styles';
import CashierAdministration from "./components/CashierAdministration/CashierAdministration";
import MarketAdministration from "./components/MarketAdministration/MarketAdministration";

const Administration = observer((props) => {
  const { location } = props;
  return (
    <>
      <Tab>
        <UiTabBlock
          title={i18n.t('Merchant')}
          active={location.state?.cashier === undefined}
        >
          <MarketAdministration location={location} />
        </UiTabBlock>
        <UiTabBlock
          title={i18n.t('Employee')}
          active={location.state?.cashier}
        >
          <CashierAdministration location={location} />
        </UiTabBlock>
      </Tab>
    </>
  )
});

export default Administration;