import styled from 'styled-components';
import {UiInput, UiStiky} from '@compassplus/ui-mobicash';

export const SubContentStiky = styled(UiStiky)`
  width: 31.5rem;
  max-width: 31.5rem;
  min-width: 320px;
  left: auto;
  
 @media(max-width: 1280px) {
   width: calc((80% - 0.5rem) / 2);
  max-width: calc((80% - 0.5rem) / 2);
  min-width: 320px;
 }
  
  @media (max-width: 810px) {
    max-width: 20rem;
  }
  
  @media(max-width: 663px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const ColorField = styled(UiInput)`
  & {
    text-overflow: ellipsis;
  }
  & > div:first-child {
    min-width: 48px;
  }
`;
