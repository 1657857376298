import { useMemo } from 'react';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../../../context/ThemeProvider';

import UiText from '../../../UiText';
import UiIcon from '../../../UiIcon';
import Expand from './Components/Expand';
import Calendar from '../CommonComponents/Calendar';
import Cell from '../CommonComponents/Cell';

import { calendarTypes } from '../../../../../constants';

import styles from './DaysCalendar.module.css';

const StyledHeader = styled.div`
  ${ (props) => {
    if (props.disabled) {
      return css`
        & > * {
          color: ${ props.styles.colors.textDisable };
          pointer-events: none;
          cursor: default;
        }

        color: ${ props.styles.colors.textDisable };
        pointer-events: none;
        cursor: default;
      `;
    } else {
      return css`
        cursor: pointer;
      `;
    }
  } }
`;

const StyledThead = styled.thead`
  ${ (props) => css`
    ${ props.styles.typography.Note }
    color: ${ props.styles.colors.textDisable };
  ` }
`;

const DaysCalendar = ({
  dateInformation,
  valueInformation,
  minValue,
  maxValue,
  calendar,
  setCalendar,
  days,
  months,
  setDate,
  setValue,
  onClose
}) => {

  const isTheme = useTheme();

  const table = useMemo(() => {
    const { year, month } = valueInformation;
    const table = [[]];

    const d = new Date(year, month);
    const p = new Date(year, month, -(getDay(d) - 1));
    const n = new Date(year, month + 1);

    for (let i = getDay(d) - 1; i >= 0; i--) {
      table[table.length - 1].push({ number: p.getDate(), active: false });

      p.setDate(p.getDate() + 1);
    }

    while (d.getMonth() === month) {
      const day = d.getDate();
      const month = d.getMonth();
      const year = d.getFullYear();

      table[table.length - 1].push({
        number: d.getDate(),
        active: d.valueOf() >= minValue.valueOf() && d.valueOf() <= maxValue.valueOf(),
        current: day === dateInformation.day && month === dateInformation.month && year === dateInformation.year
      });

      if (getDay(d) % 7 === 6) {
        table.push([]);
      }

      d.setDate(d.getDate() + 1);
    }

    if (getDay(d) !== 0) {
      for (let i = getDay(d); i < 7; i++) {
        table[table.length - 1].push({ number: n.getDate(), active: false });

        n.setDate(n.getDate() + 1);
      }
    }

    if (table.length === 5) {
      table.push([]);

      for (let i = 0; i < 7; i++) {
        table[table.length - 1].push({ number: n.getDate(), active: false });

        n.setDate(n.getDate() + 1);
      }
    }

    if (table[table.length - 1].length === 0) {
      for (let i = 0; i < 7; i++) {
        table[table.length - 1].push({ number: n.getDate(), active: false });

        n.setDate(n.getDate() + 1);
      }
    }

    return table;
  }, [dateInformation, valueInformation, maxValue, minValue]);
  const arrows = useMemo(() => {
    return {
      next: new Date(valueInformation.year, valueInformation.month + 1),
      prev: new Date(valueInformation.year, valueInformation.month - 1)
    };
  }, [valueInformation]);

  const setDateHandler = (day) => {
    const { year, month } = valueInformation;

    if (typeof setDate === 'function') {
      setDate(new Date(year, month, day));

      if (typeof onClose === 'function') {
        onClose();
      }
    }
  };

  return (
    <Calendar active={ calendar === calendarTypes.DAYS }>
      <div className={ styles.header }>
        <StyledHeader
          className={ styles.data }
          disabled={ arrows.prev.valueOf() < minValue.valueOf() && arrows.next.valueOf() > maxValue.valueOf() }
          onClick={ () => setCalendar(calendarTypes.MONTHS) }
          styles={ isTheme.styles }
        >
          <UiText view="title" typography="section-value">
            { `${ months[valueInformation.month] } ${ valueInformation.year }` }
          </UiText>
          <Expand />
        </StyledHeader>
        <div className={ styles.arrows }>
          <UiIcon
            icon="back"
            setWidth="1.375rem"
            setHeight="1.375rem"
            disabled={ arrows.prev.valueOf() < minValue.valueOf() }
            onClick={ () => setValue(arrows.prev) }
          />
          <UiIcon
            icon="back"
            setWidth="1.375rem"
            setHeight="1.375rem"
            disabled={ arrows.next.valueOf() > maxValue.valueOf() }
            onClick={ () => setValue(arrows.next) }
            style={ { transform: 'scale(-1, 1)' } }
          />
        </div>
      </div>
      <table className={ styles.table }>
        <StyledThead styles={ isTheme.styles }>
          <tr>
            { days.map((day, index) => {
              return (
                <th key={ index } className={ styles.th }>
                  { day }
                </th>
              );
            }) }
          </tr>
        </StyledThead>
        <tbody>
        { table.map((week, index) => {
          return <tr key={ index }>
            { week.map((day, index) => {
              const props = {
                key: index,
                className: styles.td,
                disabled: !day.active,
                free: index % 7 === 5 || index % 7 === 6,
                onClick: day.active ? () => setDateHandler(day.number) : undefined,
                styles: isTheme.styles
              };

              if (day.current) {
                props.active = true;
              }

              return (
                <Cell { ...props }>
                  { day.number }
                </Cell>
              );
            }) }
          </tr>;
        }) }
        </tbody>
      </table>
    </Calendar>
  );

  function getDay(date) {
    let day = date.getDay();

    if (day === 0) {
      day = 7;
    }

    return day - 1;
  }
};

export default DaysCalendar;