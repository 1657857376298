import styled from 'styled-components'

export const ModalContent = styled.div`
  padding: 1rem;
  border-radius: 0.25rem;
  background-color: #FFFFFF;
  width: 24.25rem;
  position: relative;
  height: 24.25rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.32), 0 0 8px rgba(0, 0, 0, 0.16);
  
  @media (max-width: 425px) {
    & {
      width: 20rem;
      height: 20rem;
    }
  }
  @media (max-width: 320px) {
    & {
      width: 18rem;
      height: 18rem;
    }
  }
`;

export const Modal = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,0.2);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0);
  z-index: 999999;
`

export const ArrowCircle = styled.div`
  position: absolute;
  right: -1.3rem;
  top: 11.125rem;
  background: #FFF;
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  opacity: 0.68;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.32);
`;

export const CloseCircle = styled.div`
  position: absolute;
  top: -1.2rem;
  right: -1.2rem;
  background: #FFFFFF;
  height: 1.875rem;
  width: 1.875rem;
  border-radius: 2rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.32);
`;