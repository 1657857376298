import React from 'react';
import { observer } from 'mobx-react-lite';
import AttributesStore from "../../../../stores/AttributesStore";
import { UiCard, UiMinus, UiSection, useOptions } from "@compassplus/ui-mobicash";
import {action} from "mobx";
import {Minus, Plus} from "../../../../components/TemplateStyle";
import AttributesTable from '../../../../components/admin/AttributesTable';

const CountInputs = observer(({ separatedCountAttributes }) => {
  const { fullAttributeNameCountDependencies } = AttributesStore;
  const { getArrValue } = AttributesStore;
  const isOptions = useOptions();

  const onChangeValue = action((attribute, value) => {
    const obj = getArrValue(attribute)
    obj.max = Number(obj.max) + value;
  })

  if (separatedCountAttributes.length !== 0 && separatedCountAttributes.length !== 2) {
    return (
      <>
        {
          fullAttributeNameCountDependencies.length !== 0 ? (
            <UiCard style={{ margin: '0.25rem 0 4rem'}} shadow>
              {
                fullAttributeNameCountDependencies.map((attribute, index) => (
                  <UiSection
                    key={`${attribute.en}`}
                    title={attribute[isOptions.language].split(';').join(' ')}
                    style={{ userSelect: 'none' }}
                    rightElement={
                      <div
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        {
                          (getArrValue(attribute.en)?.max > 0) ? (
                            <Minus onClick={() => onChangeValue(attribute.en, -1)} />
                          ) : (
                            <UiMinus />
                          )
                        }
                        <input
                          style={{ border: 'none', textAlign: 'center', maxWidth: '5rem', margin: '0 0.25rem' }}
                          value={getArrValue(attribute.en)?.max !== 0 ? getArrValue(attribute.en)?.max : ''}
                          placeholder="0"
                          onChange={action((event) => {
                            event.target.value = event.target.value.replace(/[^0-9]/g,'');
                            getArrValue(attribute.en).max = event.target.value;
                          })}
                          onBlur={action((event) => {
                            if (event.target.value === '') {
                              getArrValue(attribute.en).max = 0;
                            } else {
                              getArrValue(attribute.en).max = Number(event.target.value)
                            }
                          })}
                        />
                        <Plus onClick={() => onChangeValue(attribute.en, 1)} />
                      </div>
                    }
                    bottomDivider={index !== AttributesStore.fullAttributeNameCountDependencies.length - 1}
                  />
                ))
              }
            </UiCard>
          ) : null
        }
      </>
    );
  }
  if (separatedCountAttributes.length === 2) {
    return (
      <>
        {
          fullAttributeNameCountDependencies.length !== 0 ? (
            <AttributesTable style={{ margin: '0.25rem 0 4rem' }} separatedCountAttributes={separatedCountAttributes} />
          ) : null
        }
      </>
    )
  }
  return null;
});

export default CountInputs;
