import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Plate coupons icon
 * @public
 * @version 1.3.0
 * @param {String} width
 * @param {String} height
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiCouponsIcon />
 */
const UiCouponsIcon = ({
  width,
  height,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      innerRef={ innerRef }
      width={ width }
      height={ height }
      viewBox="0 0 52 52"
    >
      <path fillRule="evenodd" clipRule="evenodd"
        d="M43.5811 21.0974C44.2271 20.5234 44.2421 19.5544 43.6151 18.9624L34.9541 10.7744C34.4841 10.3304 33.8501 10.0814 33.1881 10.0814L12.1441 10.0664C11.0501 10.0654 10.1631 10.9084 10.1621 11.9494L10.1531 22.2834L8.61306 22.5604C7.53806 22.7534 6.83106 23.7394 7.03506 24.7624L10.1291 40.3154C10.3331 41.3374 11.3691 42.0104 12.4441 41.8164L32.8931 38.1364C33.5171 38.0234 34.0731 37.6894 34.4481 37.2014L41.6631 27.8164C42.1841 27.1384 42.0091 26.1834 41.2771 25.7124L39.6021 24.6354L43.5811 21.0974Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M38.2862 19.551C37.8892 19.551 37.5682 19.23 37.5682 18.833C37.5682 18.436 37.8892 18.114 38.2862 18.114C38.6822 18.114 39.0042 18.436 39.0042 18.833C39.0042 19.23 38.6822 19.551 38.2862 19.551ZM42.6322 17.594L34.6742 9.684C34.2432 9.255 33.6602 9.014 33.0522 9.014L13.7162 9C12.7112 8.999 11.8952 9.813 11.8942 10.819L11.8872 20.803L38.9452 23.075L42.6002 19.657C43.1942 19.102 43.2082 18.166 42.6322 17.594Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M32.7803 36.1189L13.9923 39.6749C13.0043 39.8609 12.0523 39.2119 11.8653 38.2239L9.02133 23.1979C8.83433 22.2099 9.48433 21.2579 10.4713 21.0709L29.4693 17.4749C30.0673 17.3619 30.6853 17.4899 31.1883 17.8299L40.4843 24.1159C41.1563 24.5709 41.3173 25.4939 40.8383 26.1489L34.2093 35.2159C33.8643 35.6879 33.3543 36.0099 32.7803 36.1189Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M17.6616 12.4885C18.5226 12.4885 19.2236 13.1895 19.2236 14.0505C19.2236 14.9125 18.5226 15.6135 17.6616 15.6135C16.8006 15.6135 16.0996 14.9125 16.0996 14.0505C16.0996 13.1895 16.8006 12.4885 17.6616 12.4885ZM17.6616 16.1135C18.7986 16.1135 19.7246 15.1885 19.7246 14.0505C19.7246 12.9135 18.7986 11.9885 17.6616 11.9885C16.5246 11.9885 15.5986 12.9135 15.5986 14.0505C15.5986 15.1885 16.5246 16.1135 17.6616 16.1135Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M40.4342 25.8537L33.8062 34.9207C33.5352 35.2917 33.1382 35.5417 32.6872 35.6277L13.8992 39.1837C13.1832 39.3147 12.4912 38.8457 12.3562 38.1317L9.51323 23.1047C9.37723 22.3897 9.84923 21.6977 10.5642 21.5617L29.5632 17.9667C29.6762 17.9447 29.7892 17.9347 29.9022 17.9347C30.2582 17.9347 30.6072 18.0407 30.9082 18.2447L40.2042 24.5297C40.4152 24.6737 40.5562 24.8917 40.6002 25.1437C40.6442 25.3957 40.5852 25.6467 40.4342 25.8537ZM41.5852 24.9717C41.4952 24.4497 41.2032 23.9987 40.7642 23.7017L31.4692 17.4157C30.8512 16.9997 30.1082 16.8447 29.3772 16.9837L21.5562 18.4637L25.9892 12.3857C26.0702 12.2737 26.0452 12.1177 25.9342 12.0367C25.8222 11.9557 25.6672 11.9797 25.5842 12.0907L20.8372 18.6007L10.3792 20.5797C9.76923 20.6947 9.24223 21.0407 8.89323 21.5517C8.54423 22.0637 8.41523 22.6817 8.53023 23.2907L11.3742 38.3167C11.5842 39.4287 12.5602 40.2067 13.6532 40.2067C13.7962 40.2067 13.9402 40.1927 14.0852 40.1657L32.8732 36.6097C33.5752 36.4777 34.1922 36.0867 34.6132 35.5107L41.2422 26.4437C41.5542 26.0167 41.6762 25.4937 41.5852 24.9717Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M16.2125 37.3718C16.1775 37.3718 16.1425 37.3648 16.1085 37.3498C15.9835 37.2918 15.9275 37.1438 15.9845 37.0178L22.9745 21.6708C23.0325 21.5448 23.1795 21.4898 23.3055 21.5468C23.4315 21.6038 23.4865 21.7518 23.4295 21.8778L16.4405 37.2258C16.3985 37.3178 16.3075 37.3718 16.2125 37.3718Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M23.9771 32.112C23.8721 32.112 23.7651 32.123 23.6591 32.144C23.2501 32.229 22.8991 32.467 22.6691 32.816C22.4391 33.164 22.3591 33.581 22.4431 33.99C22.5281 34.399 22.7661 34.75 23.1141 34.98C23.4631 35.21 23.8801 35.289 24.2891 35.206C24.6971 35.121 25.0491 34.883 25.2781 34.535C25.5081 34.186 25.5881 33.769 25.5041 33.361C25.4201 32.952 25.1821 32.6 24.8331 32.37C24.5751 32.2 24.2791 32.112 23.9771 32.112ZM23.9701 35.738C23.5701 35.738 23.1801 35.622 22.8391 35.397C22.3791 35.094 22.0651 34.63 21.9541 34.091C21.8431 33.551 21.9491 33 22.2521 32.54C22.5551 32.08 23.0191 31.766 23.5591 31.655C24.0981 31.543 24.6491 31.65 25.1091 31.953C25.5691 32.256 25.8831 32.72 25.9941 33.26C26.1051 33.799 25.9991 34.35 25.6961 34.81C25.3931 35.27 24.9291 35.584 24.3891 35.695C24.2491 35.724 24.1091 35.738 23.9701 35.738Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M15.6114 23.6232C15.5064 23.6232 15.3994 23.6342 15.2934 23.6552C14.8844 23.7402 14.5334 23.9782 14.3034 24.3272C14.0734 24.6752 13.9934 25.0922 14.0774 25.5012C14.1614 25.9102 14.4004 26.2612 14.7484 26.4912C15.0974 26.7212 15.5144 26.8012 15.9234 26.7172C16.3314 26.6322 16.6834 26.3942 16.9124 26.0452C17.1424 25.6972 17.2224 25.2802 17.1384 24.8712C17.0544 24.4622 16.8164 24.1112 16.4674 23.8812C16.2094 23.7112 15.9134 23.6232 15.6114 23.6232ZM15.6034 27.2492C15.2044 27.2492 14.8144 27.1332 14.4734 26.9082C14.0134 26.6052 13.6984 26.1412 13.5884 25.6022C13.3594 24.4882 14.0784 23.3952 15.1934 23.1662C15.7334 23.0532 16.2824 23.1612 16.7424 23.4642C17.2024 23.7672 17.5174 24.2312 17.6284 24.7702C17.7394 25.3102 17.6334 25.8612 17.3304 26.3212C17.0274 26.7812 16.5634 27.0952 16.0234 27.2062C15.8834 27.2352 15.7434 27.2492 15.6034 27.2492Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M36.4243 24.7668C35.7523 24.7668 35.2063 25.3128 35.2063 25.9848C35.2063 26.6568 35.7523 27.2038 36.4243 27.2038C37.0963 27.2038 37.6423 26.6568 37.6423 25.9848C37.6423 25.3128 37.0963 24.7668 36.4243 24.7668ZM36.4243 25.2668C36.8203 25.2668 37.1423 25.5888 37.1423 25.9848C37.1423 26.3818 36.8203 26.7038 36.4243 26.7038C36.0273 26.7038 35.7063 26.3818 35.7063 25.9848C35.7063 25.5888 36.0273 25.2668 36.4243 25.2668Z"
        fill={ isTheme.styles.colors.textTitle } />
    </Element>
  );
};

UiCouponsIcon.propTypes = {
  /** Определяет ширину иконки */
  width: PropTypes.string,
  /** Определяет высоту иконки */
  height: PropTypes.string
};

UiCouponsIcon.defaultProps = {
  width: '3.25rem',
  height: '3.25rem'
};

export default UiCouponsIcon;