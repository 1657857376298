import React from 'react';
import { observer } from 'mobx-react-lite';
import { UiInputMobile, UiCard } from '@compassplus/ui-mobicash';
import { action } from 'mobx';
import i18n from '../../../../i18n';
import OrderStore from '../../../../stores/OrderStore';
import {AnimationDiv, Text} from "../../../../components/TemplateStyle";

const Phone = observer(() => {
  const setPhoneNumber = action((value) => {
    OrderStore.phoneNumber = value;
  });

  return (
    <AnimationDiv>
      <Text style={{ textTransform: 'uppercase', margin: '0.25rem 0' }} view="readonly" typography="section-description-semibold">
        {i18n.t('Phone')}
      </Text>
      {
        OrderStore.selectedDelivery.phone !== undefined ? (
          <UiCard shadow style={{ margin: '0.25rem 0' }}>
            <UiInputMobile
              description={i18n.t('Phone')}
              required={OrderStore.selectedDelivery.phone}
              state={OrderStore.phoneNumber}
              setState={setPhoneNumber}
            />
          </UiCard>
        ) : null
      }
    </AnimationDiv>
  );
});

export default Phone;
