import React, { useEffect, useState } from 'react';
import { UiCard } from '@compassplus/ui-mobicash';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react-lite';
import i18n from '../../../i18n';
import { getCurrency } from '../../../Utils/requestUtil';
import { MerchantLogo, MerchantSection } from '../../../pages/market/MarketPage/style';
import { LoadingImage, LoadingTitle } from '../../../components/loadingCards/style';
import { MerchantWithCart } from './style';
import Merchant from '../../../stores/Merchant';
import ShoppingCart from '../../../stores/ShoppingCartStore';

const MerchantCard = observer(({ type }) => {
  const [currentLanguage] = useState(i18n.language);
  const history = useHistory();

  useEffect(() => {
    if (!Merchant.isCMS) {
      fetch('/api/v1/reserv/checkTime', { method: 'GET' });
    }
  }, []);

  const renderMerchantSection = () => (
    <MerchantSection
      title={currentLanguage === 'en' ? Merchant.name : Merchant.localName}
      onClick={() => { history.push(`/${Merchant.marketLink}`); }}
      leftElement={(
        <>
          {
            Merchant.photo !== '' ? (
              <img style={{ width: '48px', height: '48px' }} src={Merchant.photo} />
            ) : (
              <MerchantLogo
                style={{ background: Merchant.logoColor }}
              >
                {Merchant.logoSymbol}
              </MerchantLogo>
            )
          }
        </>
      )}
    />
  );

  const renderLoadingMerchantSection = () => (
    <MerchantSection
      title={<LoadingTitle />}
      leftElement={(
        <LoadingImage />
      )}
    />
  );

  const renderMerchantCard = () => (
    <UiCard shadow style={{ margin: '0 0 1rem' }}>
      {renderMerchantSection()}
    </UiCard>
  );

  const renderLoadingMerchantCard = () => (
    <UiCard shadow style={{ margin: '0 0 1rem' }}>
      {renderLoadingMerchantSection()}
    </UiCard>
  );

  const renderMerchantCardWithCart = () => (
    <UiCard shadow style={{ margin: '0 0 1rem' }}>
      <MerchantWithCart
        title={currentLanguage === 'en' ? Merchant.name : Merchant.localName}
        onClick={() => { history.push(`/${Merchant.marketLink}`); }}
        description={`${i18n.t('Cart')} (${ShoppingCart.totalCount} / ${ShoppingCart.totalCost} ${i18n.t(getCurrency())})`}
        leftElement={(
          <>
            {
              Merchant.photo !== '' ? (
                <img style={{ width: '48px', height: '48px' }} src={Merchant.photo} />
              ) : (
                <MerchantLogo
                  style={{ background: Merchant.logoColor }}
                >
                  {Merchant.logoSymbol}
                </MerchantLogo>
              )
            }
          </>
        )}
      />
    </UiCard>
  );

  const renderLoadingMerchantCardWithCart = () => (
    <UiCard shadow style={{ margin: '0 0 1rem' }}>
      <MerchantWithCart
        title={<LoadingTitle />}
        description={<LoadingTitle />}
        leftElement={(
          <LoadingImage />
        )}
      />
    </UiCard>
  );

  const renderCardByType = () => {
    switch (type) {
      case 'cart':
        return (
          <>
            {Merchant.isLoading ? renderLoadingMerchantCardWithCart() : renderMerchantCardWithCart()}
          </>
        );
      case 'header':
        return (
          <>
            {Merchant.isLoading ? renderLoadingMerchantSection() : renderMerchantSection()}
          </>
        );
      case 'card':
      default:
        return (
          <>
            {Merchant.isLoading ? renderLoadingMerchantCard() : renderMerchantCard()}
          </>
        );
    }
  };

  return (
    <>
      {renderCardByType()}
    </>
  );
});

export default MerchantCard;
