import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styled, { css } from 'styled-components';

import { useTheme } from '../../../context/ThemeProvider';

import styles from './UiProgressBar.module.css';

const StyledContainer = styled.div`
  background: ${ (props) => props.styles.colors.textDisable };
`;

const StyledContent = styled.div`
  ${ (props) => css`
    background: ${ props.styles.colors.applicationPrimaryColor };
    transition: ${ props.animation };
  ` }
`;

/**
 * Progress bar component
 * @public
 * @version 1.2.0
 * @param {String} className
 * @param {'percent'|'value'} [progress=percent]
 * @param {Number} [percent=0]
 * @param {Number} [precision=2]
 * @param {Number} [value=0]
 * @param {Number} [total=100]
 * @param {String} [animation=none]
 * @param {Function|Object} [innerRef]
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiProgressBar percent={ 50 } />
 */
const UiProgressBar = ({
  className,
  progress,
  percent,
  precision,
  value,
  total,
  animation,
  innerRef
}) => {

  const isTheme = useTheme();

  const contentRef = useRef(null);

  useEffect(() => {
    const content = contentRef.current;

    if (progress === 'value') {
      if (value > total) {
        content.style.width = '100%';
      } else {
        content.style.width = `${ (value / total).toFixed(precision) * 100 }%`;
      }
    } else {
      if (percent > 100) {
        content.style.width = '100%';
      } else {
        content.style.width = `${ percent }%`;
      }
    }
  }, [progress, percent, total, value, precision]);

  return (
    <StyledContainer className={ cn(styles.container, className) } ref={ innerRef } styles={ isTheme.styles }>
      <StyledContent className={ styles.content } animation={ animation } ref={ contentRef } styles={ isTheme.styles } />
    </StyledContainer>
  );
};

UiProgressBar.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Определяет вид прогресса */
  progress: PropTypes.oneOf(['percent', 'value']),
  /** Определяет текущий процент выполнения (progress=percent) */
  percent: PropTypes.number,
  /** Определяет точность десятичной точки для вычисляемого прогресса (progress=value) */
  precision: PropTypes.number,
  /** Определяет текущее значение выполнения. Вместе с total они будут вычислять процент. Взаимно исключает процент (progress=value) */
  value: PropTypes.number,
  /** Определяет максимальное значение выполнения. Вместе с value они будут вычислять процент. Взаимно исключает процент (progress=value) */
  total: PropTypes.number,
  /** Определяет время анимации заполнения значения выполнения ('none', '.1s', '.3s' и т.д.) */
  animation: PropTypes.string
};

UiProgressBar.defaultProps = {
  progress: 'percent',
  percent: 0,
  precision: 2,
  value: 0,
  total: 100,
  animation: 'none'
};

export default UiProgressBar;