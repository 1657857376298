import cn from 'classnames';

import UiImage from '../../../../../UiImage';

import styles from './Logo.module.css';

const Logo = ({
  className,
  src,
  alt,
  ...props
}) => {

  return (
    <div className={ styles.logo }>
      <UiImage
        { ...props }
        className={ cn(styles.image, className) }
        src={ src }
        alt={ alt }
      />
    </div>
  );
};

export default Logo;