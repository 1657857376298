import React, { useState, useEffect } from 'react';

import { useOptions } from '@compassplus/ui-mobicash';

import { useParams } from 'react-router';
import { MContentDiv, SubContentDiv } from '../../../components/TemplateStyle';
import Main from '../MainPage/Main';
import GlobalStateStore from '../../../stores/GlobalStateStore';
import ModifyGroup from './ModifyGroup';
import CategoryStore from '../../../stores/CategoryStore';

const ModifyGroupPage = (props) => {
  const { location } = props;
  const params = useParams();
  const isOptions = useOptions();

  const [groupId, setGroupId] = useState(params.id);

  const [modeIsModify, setModeIsModify] = useState(false);

  useEffect(() => {
    setGroupId(params.id);
    if (params.id) {
      setModeIsModify(true);
    } else {
      setModeIsModify(false);
    }
  }, [params]);

  useEffect(() => {
    GlobalStateStore.setHeaderProperties({
      title: modeIsModify ? 'EditingCategory' : 'AddingCategory',
      isBack: true,
    });
  }, [modeIsModify]);

  return (
    <>
      {
        isOptions.screen === 'mobile' ? (
          <MContentDiv>
            <ModifyGroup groupId={groupId} {...props} />
          </MContentDiv>
        ) : (
          <>
            <MContentDiv>
              <Main groupId={groupId || CategoryStore?.parentGroup?.key || location?.state?.parentGroup?.key} />
            </MContentDiv>
            <SubContentDiv>
              <ModifyGroup groupId={groupId} {...props} />
            </SubContentDiv>
          </>
        )
      }
    </>
  );
};

export default ModifyGroupPage;
