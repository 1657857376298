import styled from 'styled-components';

export const LinkInputDisabled = styled.input`
  background: #E7E7E7;
  border: 1px solid #A3AEBF;
  border-radius: 4px;
  height: 26px;
  width: 238px;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  margin: 0.5rem auto;
  :focus {
    border: 1px solid #A3AEBF;
    outline: none;
  }
`;

export const LinkInput = styled(LinkInputDisabled)`
  background: #FFF;
  color: #037AFF;
`;
