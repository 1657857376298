import cn from 'classnames';

import UiText from '../../../UiText';
import DateComponent from '../CommonComponents/DateComponent';

import styles from '../../UiCardSection.module.css';

const PaymentCardSection = ({
  title,
  description,
  text,
  date,
  rightElement,
  elements
}) => {

  return (
    <>
      <div className={ cn(styles.content, styles.contentPayment) }>
        <div>
          <UiText className={ styles.text } view="title" typography="section-title">
            { title }
          </UiText>
          { (date || text) &&
            <DateComponent className={ styles.date } text={ text } date={ date } />
          }
        </div>
        <UiText className={ styles.text } view="readonly" typography="section-description">
          { description }
        </UiText>
        { elements }
      </div>
      { rightElement &&
        <div className={ styles.rightElement }>
          { rightElement }
        </div>
      }
    </>
  );
};

export default PaymentCardSection;