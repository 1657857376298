import React, { useEffect, useReducer, useState } from 'react';
import {
  UiCard, UiInput, UiDropdown, UiSection, UiButton, useOptions,
} from '@compassplus/ui-mobicash';
import { observer } from 'mobx-react-lite';
import { action } from 'mobx';
import { useHistory } from 'react-router';
import LanguageTabs from '../../../components/admin/LanguageTabs';
import i18n from '../../../i18n';
import ActionSheet from '../../../components/admin/ActionSheet';
import AttributesStore from '../../../stores/AttributesStore';
import { SubContentStiky } from './style';
import { DeleteSection, Text } from '../../../components/TemplateStyle';
import RadioCheckboxValues from './components/RadioCheckboxValues';
import ColorValues from './components/ColorValues';
import ListInputs from './components/ListInputs';
import ModalStore from '../../../stores/ModalStore';

const ModifyAttribute = observer(() => {
  const isOptions = useOptions();
  const history = useHistory();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const attribute = AttributesStore.modifyAttribute;
  const [selectedLanguage, setSelectedLanguage] = useState(isOptions.language);
  const [isValidName, setIsValidName] = useState(true);

  const checkFormIsValid = () => Object.keys(attribute).length !== 0;

  useEffect(() => {
    if (!checkFormIsValid()) {
      history.goBack();
    }
  }, []);

  const checkAttributeNameIsUnique = (event) => {
    const { value } = event.target;
    let flag = true;
    AttributesStore.attributes.forEach((attribute, index) => {
      if (attribute.title.en === value || attribute.title.ru === value) {
        if (!(AttributesStore.modeIsModify && AttributesStore.id === index)) {
          flag = false;
        }
      }
    });
    setIsValidName(flag);
  };

  const checkAttributeIsValid = () => {
    if (isValidName) {
      if (attribute.complexValue) {
        for (const cValue of attribute.complexValue) {
          if (!cValue.isUniqueTitle) {
            return cValue.isUniqueTitle;
          }
        }
      }
    }
    return isValidName;
  };

  const renderTabsContent = (language) => (
    <>
      <UiCard style={{ margin: '0.25rem 0' }}>
        <UiInput
          description={i18n.t('Name')}
          value={attribute.title[language]}
          placeholder={`[${i18n.t('Text')}]`}
          onChange={action((event) => {
            attribute.setTitle(event.target.value, language);
            forceUpdate();
          })}
          onBlur={checkAttributeNameIsUnique}
          warning={!isValidName}
          bottomDivider
          required
        />
        <UiInput
          description={i18n.t('AttributeType')}
          value={i18n.t(attribute.type)}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          rightElement={<UiDropdown />}
          active={isDropdownOpen}
          bottomDivider
        />
        <ActionSheet
          open={isDropdownOpen}
          setOpen={() => { setIsDropdownOpen(false); }}
        >
          <UiSection
            title={i18n.t('Checkbox')}
            onClick={() => { attribute.setTypeAndValueByType('checkbox'); setIsDropdownOpen(false); forceUpdate(); }}
            view="link"
            bottomDivider
          />
          <UiSection
            title={i18n.t('Radiobutton')}
            onClick={() => { attribute.setTypeAndValueByType('radio'); setIsDropdownOpen(false); forceUpdate(); }}
            view="link"
            bottomDivider
          />
          <UiSection
            title={i18n.t('List')}
            onClick={() => { attribute.setTypeAndValueByType('list'); setIsDropdownOpen(false); forceUpdate(); }}
            view="link"
            bottomDivider
          />
          <UiSection
            title={i18n.t('TextField')}
            onClick={() => { attribute.setTypeAndValueByType('textField'); setIsDropdownOpen(false); forceUpdate(); }}
            view="link"
            bottomDivider={AttributesStore.getAttributesByType('color').length === 0}
          />
          {
            ((AttributesStore.getAttributesByType('color').length === 0)
              && (
                <UiSection
                  title={i18n.t('Color')}
                  onClick={() => { attribute.setTypeAndValueByType('color'); setIsDropdownOpen(false); }}
                  view="link"
                />
              )
            )
          }
        </ActionSheet>
        <UiInput
          description={i18n.t('Description')}
          value={attribute.description[language]}
          placeholder={`[${i18n.t('Text')}]`}
          onChange={(event) => {
            attribute.setDescription(event.target.value, language);
            forceUpdate();
          }}
          bottomDivider={attribute.type === 'list'}
        />
        { attribute.type === 'list' ? <ListInputs language={language} /> : null }
      </UiCard>
    </>
  );

  const renderContent = () => {
    switch (attribute.type) {
      case 'radio':
      case 'checkbox':
        return (
          <RadioCheckboxValues language={selectedLanguage} />
        );
      case 'color':
        return (
          <ColorValues language={selectedLanguage} />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {checkFormIsValid() ? (
        <>
          <div style={{ margin: '0 0 4rem' }}>
            <Text typography="note" view="readonly" style={{ textTransform: 'uppercase', fontWeight: 600 }}>{i18n.t('AttributeParams')}</Text>
            <LanguageTabs setSelectedLanguage={setSelectedLanguage}>
              {renderTabsContent}
            </LanguageTabs>
            {renderContent()}
            {AttributesStore.modeIsModify ? (
              <UiCard style={{ margin: '0.25rem 0' }} shadow>
                <DeleteSection
                  title={i18n.t('DeleteAttribute')}
                  onClick={() => {
                    AttributesStore.deleteModifyingAttribute();
                    history.goBack();
                  }}
                />
              </UiCard>
            ) : null}
          </div>
          <SubContentStiky>
            <UiButton
              title={i18n.t('Save')}
              onClick={() => {
                if (checkAttributeIsValid()) {
                  if (AttributesStore.modifyAttribute.checkIsFilled()) {
                    AttributesStore.saveAttribute();
                    history.goBack();
                  } else {
                    ModalStore.openWithMessage(i18n.t('MustFillRequiredFields'));
                  }
                } else {
                  ModalStore.openWithMessage(i18n.t('AttributeNameIsUsed'));
                }
              }}
            />
          </SubContentStiky>
        </>
      ) : null}
    </>
  );
});

export default ModifyAttribute;
