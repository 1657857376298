import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Plate merchants icon
 * @public
 * @version 1.3.0
 * @param {String} width
 * @param {String} height
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiMerchantsIcon />
 */
const UiMerchantsIcon = ({
  width,
  height,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      innerRef={ innerRef }
      width={ width }
      height={ height }
      viewBox="0 0 52 52"
    >
      <path fillRule="evenodd" clipRule="evenodd"
        d="M44.3009 39.8572V33.1442C45.3309 32.8722 46.4889 32.3922 46.5309 31.6052C46.5309 31.5682 46.5039 31.5322 46.4509 31.5012L41.6239 28.5832C41.2839 28.3782 40.6499 28.2502 39.9669 28.2502H11.5649C10.8819 28.2502 10.2469 28.3782 9.90887 28.5832L5.08087 31.5012C5.02887 31.5322 5.00087 31.5682 5.00087 31.6052C4.96287 32.3162 6.16988 32.8112 7.23787 33.1072C7.23688 33.1122 7.22887 33.1172 7.22887 33.1222V39.8572C7.22887 39.8572 7.36888 40.3152 8.40087 40.3152H31.8209H39.0589H43.1309C44.2419 40.3152 44.3009 39.8572 44.3009 39.8572Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M41.3946 22.494V39.327C41.3946 39.571 41.1996 39.767 40.9596 39.767H11.2926C11.0526 39.767 10.8586 39.571 10.8586 39.327V22.546"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M43.1432 39.2674H41.8942V22.4944C41.8942 22.2174 41.6712 21.9944 41.3942 21.9944C41.1182 21.9944 40.8942 22.2174 40.8942 22.4944L40.9602 39.2674H11.3582V22.5464C11.3582 22.2694 11.1352 22.0464 10.8582 22.0464C10.5822 22.0464 10.3582 22.2694 10.3582 22.5464V39.2674H8.38818C8.11118 39.2674 7.88818 39.4914 7.88818 39.7674C7.88818 40.0434 8.11118 40.2674 8.38818 40.2674H11.2932H40.9602H43.1432C43.4192 40.2674 43.6432 40.0434 43.6432 39.7674C43.6432 39.4914 43.4192 39.2674 43.1432 39.2674Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M11.6494 23.3587C12.1404 23.5267 12.6654 23.6207 13.2124 23.6207C15.0904 23.6207 16.7204 22.5427 17.5174 20.9727C18.3154 22.5427 19.9444 23.6207 21.8224 23.6207C23.7004 23.6207 25.3304 22.5427 26.1274 20.9727C26.9244 22.5427 28.5544 23.6207 30.4314 23.6207C32.3084 23.6207 33.9394 22.5427 34.7364 20.9727C35.5334 22.5427 37.1634 23.6207 39.0414 23.6207C39.5884 23.6207 40.1134 23.5267 40.6044 23.3587C40.6044 23.3587 43.8004 22.3217 43.8654 18.7967C43.8654 18.7057 43.8414 18.6167 43.7964 18.5377L39.6734 11.3217C39.3834 10.8147 38.8414 10.5007 38.2574 10.5007H13.9964C13.4124 10.5007 12.8704 10.8147 12.5814 11.3217L8.45742 18.5377C8.41242 18.6167 8.38842 18.7057 8.38842 18.7967C8.33142 21.9297 11.6494 23.3587 11.6494 23.3587Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M43.523 18.764C43.523 18.678 43.501 18.593 43.456 18.518L39.413 11.621C39.128 11.137 38.597 10.836 38.025 10.836H14.237C13.665 10.836 13.133 11.137 12.85 11.621L8.80601 18.518C8.76301 18.593 8.73901 18.678 8.73901 18.764C8.73901 18.772 43.523 18.783 43.523 18.764Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M27.3399 36.262H25.1139C24.9759 36.262 24.8639 36.15 24.8639 36.012C24.8639 35.874 24.9759 35.762 25.1139 35.762H27.3399C27.4419 35.762 27.5249 35.679 27.5249 35.577V26.444C27.5249 26.343 27.4419 26.26 27.3399 26.26H14.6359C14.5339 26.26 14.4509 26.343 14.4509 26.444V35.577C14.4509 35.679 14.5339 35.762 14.6359 35.762H22.3689C22.5069 35.762 22.6189 35.874 22.6189 36.012C22.6189 36.15 22.5069 36.262 22.3689 36.262H14.6359C14.2579 36.262 13.9509 35.955 13.9509 35.577V26.444C13.9509 26.067 14.2579 25.76 14.6359 25.76H27.3399C27.7169 25.76 28.0249 26.067 28.0249 26.444V35.577C28.0249 35.955 27.7169 36.262 27.3399 36.262Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M37.5342 39.7674H31.2482C31.0362 39.7674 30.8652 39.5954 30.8652 39.3834V26.2954C30.8652 26.0834 31.0362 25.9124 31.2482 25.9124H37.5342C37.7462 25.9124 37.9172 26.0834 37.9172 26.2954V39.3834C37.9172 39.5954 37.7462 39.7674 37.5342 39.7674Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M31.2485 26.1619C31.1745 26.1619 31.1145 26.2219 31.1145 26.2959V39.3839C31.1145 39.4569 31.1745 39.5169 31.2485 39.5169H37.5345C37.6075 39.5169 37.6675 39.4569 37.6675 39.3839V26.2959C37.6675 26.2219 37.6075 26.1619 37.5345 26.1619H31.2485ZM37.5345 40.0169H31.2485C30.8985 40.0169 30.6145 39.7329 30.6145 39.3839V26.2959C30.6145 25.9459 30.8985 25.6619 31.2485 25.6619H37.5345C37.8835 25.6619 38.1675 25.9459 38.1675 26.2959V39.3839C38.1675 39.7329 37.8835 40.0169 37.5345 40.0169Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M34.7363 20.473C34.9243 20.473 35.0963 20.579 35.1823 20.746C35.9253 22.211 37.4043 23.12 39.0413 23.12C39.5173 23.12 39.9883 23.041 40.4413 22.886C40.5653 22.845 43.3083 21.9 43.3653 18.787L39.2393 11.57C39.0383 11.219 38.6623 11 38.2573 11H13.9963C13.5913 11 13.2153 11.219 13.0153 11.569L8.8913 18.786C8.8403 21.456 11.6023 22.787 11.8333 22.893C12.2793 23.044 12.7443 23.12 13.2133 23.12C14.8493 23.12 16.3273 22.211 17.0713 20.746C17.2423 20.411 17.7923 20.411 17.9633 20.746C18.7073 22.211 20.1863 23.12 21.8223 23.12C23.4593 23.12 24.9373 22.211 25.6813 20.746C25.8513 20.411 26.4013 20.411 26.5723 20.746C27.3173 22.211 28.7953 23.12 30.4313 23.12C32.0683 23.12 33.5473 22.211 34.2903 20.746C34.3763 20.579 34.5483 20.473 34.7363 20.473ZM39.0413 24.12C37.3143 24.12 35.7303 23.298 34.7363 21.929C33.7423 23.298 32.1583 24.12 30.4313 24.12C28.7053 24.12 27.1213 23.298 26.1273 21.929C25.1333 23.298 23.5493 24.12 21.8223 24.12C20.0953 24.12 18.5113 23.298 17.5173 21.929C16.5243 23.298 14.9403 24.12 13.2133 24.12C12.6263 24.12 12.0453 24.023 11.4873 23.832C11.4753 23.827 11.4633 23.823 11.4513 23.818C11.3033 23.754 7.8253 22.217 7.8883 18.787C7.8883 18.62 7.9343 18.446 8.0223 18.292L12.1473 11.074C12.5243 10.411 13.2333 10 13.9963 10H38.2573C39.0203 10 39.7283 10.411 40.1073 11.073L44.2313 18.29C44.3193 18.444 44.3653 18.619 44.3653 18.796C44.2953 22.643 40.7933 23.823 40.7583 23.834C40.2083 24.023 39.6283 24.12 39.0413 24.12Z"
        fill={ isTheme.styles.colors.textTitle } />
    </Element>
  );
};

UiMerchantsIcon.propTypes = {
  /** Определяет ширину иконки */
  width: PropTypes.string,
  /** Определяет высоту иконки */
  height: PropTypes.string
};

UiMerchantsIcon.defaultProps = {
  width: '3.25rem',
  height: '3.25rem'
};

export default UiMerchantsIcon;