import React from 'react';
import {
  UiCard, UiInput, UiScrollArrow,
} from '@compassplus/ui-mobicash';
import i18n from '../../../../../i18n';
import { AddPriceTypeSection } from '../../styles';
import {Text} from "../../../../../components/TemplateStyle";

const ChangePricesBlock = ({
  priceAddition, setPriceAddition, priceAdditionForm, setPriceAdditionForm,
}) => (
  <>
    <Text
      style={{ textTransform: 'uppercase', margin: '0.25rem 0 0' }}
      view="readonly"
      typography="section-description-semibold"
    >
      {i18n.t('PriceChange')}
    </Text>
    <Text style={{ paddingTop: '0.5rem' }} view="readonly" typography="note">
      {i18n.t('PriceChangeAnnotation')}
    </Text>
    <UiCard style={{ margin: '0.25rem 0' }}>
      <AddPriceTypeSection
        title={i18n.t(priceAdditionForm)}
        type="one-string"
        onClick={() => {
          (priceAdditionForm === '%') ? setPriceAdditionForm('810') : setPriceAdditionForm('%');
        }}
        rightElement={<UiScrollArrow />}
        bottomDivider
      />
      <UiInput
        description={priceAdditionForm === '%' ? i18n.t('Percents') : i18n.t('Cost')}
        value={priceAddition}
        type="number"
        onChange={(event) => {
          setPriceAddition(event.target.value);
        }}
        placeholder="0.00"
      />
    </UiCard>
  </>
);

export default ChangePricesBlock;
