import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  UiCalendar, UiCard, UiInput,
} from '@compassplus/ui-mobicash';
import { action } from 'mobx';
import i18n from '../../../../i18n';
import OrderStore from '../../../../stores/OrderStore';
import {AnimationDiv, Text} from "../../../../components/TemplateStyle";

const DateFields = observer(() => {
  const [openCalendar, setOpenCalendar] = useState(false);
  const setDeliveryDate = action((value) => {
    OrderStore.deliveryDate = value;
  });

  return (
    <>
      {
        OrderStore.selectedDelivery.date !== undefined && (
          <AnimationDiv>
            <Text style={{ textTransform: 'uppercase', margin: '0.5rem 0 0' }} view="readonly" typography="section-description-semibold">{i18n.t('DeliveryDate')}</Text>
            {
              OrderStore.selectedDelivery.date.type === 'autoSet'
                ? <Text style={{ margin: '0.5rem 0 0' }} view="readonly" typography="note">{i18n.t('DeliveryDateAnnotation')}</Text>
                : null
            }
            <UiCard style={{ margin: '0.5rem 0 0' }} shadow>
              {
                OrderStore.selectedDelivery.date.type === 'autoSet' && (
                  <UiInput
                    description={i18n.t('OrderWillBeDelivered')}
                    value={OrderStore.getDataValue(OrderStore.selectedDelivery.date)}
                    readOnly
                  />
                )
              }
              {
                OrderStore.selectedDelivery.date.type === 'setByClient' && (
                  <>
                    <UiCalendar
                      minDate={OrderStore.minDeliveryDate}
                      isOpened={openCalendar}
                      onClose={() => setOpenCalendar(false)}
                      setDate={setDeliveryDate}
                    />
                    <UiInput
                      description={i18n.t('Date')}
                      onClick={() => { setOpenCalendar(true); }}
                      value={OrderStore.deliveryDate < OrderStore.minDeliveryDate
                        ? new Date(OrderStore.minDeliveryDate.getTime() - 5 * 60 * 60 * 1000).toISOString().split('T')[0]
                        : new Date(OrderStore.deliveryDate.getTime() + 5 * 60 * 60 * 1000).toISOString().split('T')[0]}
                      required
                      readOnly
                    />
                  </>
                )
              }
            </UiCard>
          </AnimationDiv>
        )
      }
    </>
  );
});

export default DateFields;
