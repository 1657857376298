import React from 'react';
import { UiInput, UiIcon } from '@compassplus/ui-mobicash';

const TimeInput = ({
  description, bottomDivider, value, setValue, onDelete,
}) => (
  <UiInput
    type="time"
    description={description}
    value={value}
    onChange={(event) => {
      setValue(event.target.value);
    }}
    bottomDivider={bottomDivider}
    rightElement={onDelete
      ? (
        <UiIcon
          icon="cancel"
          setColor="#EF1012"
          onClick={onDelete}
        />
      )
      : null}
  />
);

export default TimeInput;
