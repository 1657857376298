import React, { useEffect, useState } from 'react';
import { Text } from '../../../../components/TemplateStyle';
import i18n from '../../../../i18n';
import OrdersRecordsStore from '../../../../stores/OrdersRecordsStore';
import { UiButton, UiCard, UiInput, useOptions } from '@compassplus/ui-mobicash';
import { SubContentStiky } from '../../ModifyAttributePage/style';
import ModalStore from '../../../../stores/ModalStore';
import { useHistory } from 'react-router';

const OrderAddressData = () => {
  const { modifyingOrder } = OrdersRecordsStore;
  const history = useHistory();
  const isOptions = useOptions();
  const [currentAddressData, setCurrentAddressData] = useState([]);

  useEffect(() => {
    if (modifyingOrder) {
      setCurrentAddressData([...modifyingOrder?.deliveryData?.addressFields])
    }
  }, [modifyingOrder])

  const checkFieldsAreValid = () => {
    for (const field of currentAddressData) {
      if (field.Value === '') {
        return false;
      }
    }
    return true;
  }

  const modifyAddressData = () => {
    if (checkFieldsAreValid()) {
      OrdersRecordsStore.modifyDeliveryData({ addressFields: currentAddressData });
      history.replace(`/modifyOrder/${modifyingOrder._id}`);
    } else {
      ModalStore.openWithMessage(i18n.t('MustFillRequiredFields'));
    }
  }

  const renderAddressFields = () => (
    <>
      {
        currentAddressData?.map((field, index) => (
         <React.Fragment key={field.Name}>
           <UiInput
              description={isOptions.language === 'ru' ? field.Title : field.Name}
              value={field.Value}
              onChange={(event) => {
               let newData = [...currentAddressData];
               newData[index] = {
                 ...newData[index],
                 'Value': event.target.value
               };
               setCurrentAddressData(newData);
              }}
              placeholder={`[${i18n.t('Text')}]`}
              bottomDivider={index !== currentAddressData.length - 1}
           />
         </React.Fragment>
        ))
      }
    </>
  )
  return (
    <>
      <Text
        style={{ textTransform: 'uppercase', fontWeight: 600 }}
        view="readonly"
        typography="note"
      >
        {i18n.t('Address')}
      </Text>
      <UiCard
        style={{ margin: '0.5rem 0 0.25rem' }}
        shadow
      >
        {renderAddressFields()}
      </UiCard>
      <SubContentStiky>
        <UiButton
          title={i18n.t('Save')}
          onClick={() => { modifyAddressData(); }}
        />
      </SubContentStiky>
    </>
  );
};

export default OrderAddressData;