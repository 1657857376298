import React from 'react';
import { LoadingCardImage, LoadingTitle } from './style';
import {
  CardDescription, CardText, CardTitle, MarketCard,
} from '../../pages/market/MarketPage/style';
import { Text } from '../TemplateStyle';

const LoadingMarketItemCard = () => (
  <MarketCard shadow>
    <LoadingCardImage />
    <CardText>
      <CardTitle typography="section-title" style={{ textOverflow: 'ellipsis' }}><LoadingTitle /></CardTitle>
      <CardDescription><LoadingTitle /></CardDescription>
      <Text><LoadingTitle /></Text>
    </CardText>
  </MarketCard>
);

export default LoadingMarketItemCard;
