import React from 'react';
import {
  UiCard, UiIcon, UiSection, useOptions,
} from '@compassplus/ui-mobicash';
import { useHistory, useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import DeliveryStore from '../../../../stores/DeliveryStore';
import i18n from '../../../../i18n';

const AddAttributeButton = observer(({ type }) => {
  const history = useHistory();
  const params = useParams();
  const isOptions = useOptions();

  const checkFormIsValid = () => Object.keys(DeliveryStore.form).length !== 0;

  const renderButton = () => {
    switch (type) {
      case 'Pickup':
        return (
          <div style={isOptions.screen === 'mobile'
            ? { padding: '0 1rem 0', marginTop: '1rem', width: '100%' }
            : { padding: '0 0 0 6px', marginTop: '1rem', width: '100%' }}
          >
            <UiCard>
              <UiSection
                leftElement={<UiIcon setColor="#037AFF" icon="legacy-plus" />}
                title={i18n.t('AddIssuingCenter')}
                view="link"
                onClick={(event) => {
                  DeliveryStore.form.addressData.addPoint();
                  history.push('/administration/attribute',
                    {
                      type: 'PickupPoint',
                      id: DeliveryStore.form.addressData.points.length - 1,
                      deliveryId: params.id,
                      deliveryForm: DeliveryStore.form,
                    });
                  event.stopPropagation();
                }}
              />
            </UiCard>
          </div>
        );
      default:
        return (
          <>
            {(DeliveryStore.form.attributes.length <= 6) ? (
              <div style={isOptions.screen === 'mobile'
                ? { padding: '0 1rem 0', marginTop: '1rem', width: '100%' }
                : { padding: '0 0 0 6px', marginTop: '1rem', width: '100%' }}
              >
                <UiCard>
                  <UiSection
                    leftElement={<UiIcon setColor="#037AFF" icon="legacy-plus" />}
                    title={i18n.t('AddAttribute')}
                    view="link"
                    onClick={() => {
                      history.push('/administration/attribute', {
                        id: DeliveryStore.form.attributes.length,
                        modify: false,
                        deliveryId: params.id,
                        deliveryForm: DeliveryStore.form,
                      });
                    }}
                  />
                </UiCard>
              </div>
            ) : null}
          </>
        );
    }
  };

  return (
    <>
      {checkFormIsValid() ? renderButton() : null}
    </>
  );
});

export default AddAttributeButton;
