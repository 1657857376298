import styled from 'styled-components';

import { UiSidebar } from '@compassplus/ui-mobicash';

export const CustomSidebar = styled(UiSidebar)`
  & > div:last-child > div:last-child {
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.32), 0 0 8px rgba(0, 0, 0, 0.16);
  }
  
  & > div:last-child > div:last-child span {
    color: #be1e24 !important;
  }
  
  & div[class*="UiCardSection-module__text"] {
    color: black;
  }
  
  & div[class*="UiSection-module__rightElement"] svg {
    color: #000;
  }
  
  .market div[class*="upper"] > div:last-child > div:last-child {
    bottom: 5rem;
    display: grid !important;
  }
  
  @media (max-width: 663px) {
    & div[class*="UiSidebar-module__action"] {
      display: grid !important;
    }
  }
`;

export const LanguageButtonActive = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #BE1E24;
  border-radius: 4px;
  font-family: 'Roboto';
  font-style: normal;
  font-weigth: 400;
  font-size: 1rem;
  text-align: center;
  opacity: 0.5;
  color: #FFF;
  width: 31px;
  height: 34px;
  cursor: pointer;
  user-select: none;
`;

export const LanguageButtonDisabled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 31px;
  height: 34px;
  font-family: 'Roboto';
  font-style: normal;
  font-weigth: 400;
  font-size: 1rem;
  text-align: center;
  opacity: 0.5;
  color: #000;
  cursor: pointer;
  user-select: none;
`;
