import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Иконка информации
 * @public
 * @version 1.5.0
 * @param {String} [width="3.25rem"]
 * @param {String} [height="3.25rem"]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * import { UiInfoIcon, UiPlates, UiPlate } from '@compassplus/ui-mobicash';
 *
 * const App = () => {
 *
 *   return (
 *     <>
 *       <UiInfoIcon />
 *       <UiPlates>
 *         <UiPlate view="2x1" image={ <UiInfoIcon width="100%" height="100%" /> } />
 *       </UiPlates>
 *     </>
 *   );
 * }; */
const UiInfoIcon = ({
  width,
  height,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      innerRef={ innerRef }
      width={ width }
      height={ height }
      viewBox="0 0 52 52"
    >
      <mask id="mask0_82_15495" style={ { maskType: 'alpha' } } maskUnits="userSpaceOnUse" x="8" y="22" width="36" height="22">
        <path d="M8 22.0249H43.5699V43.4996H8V22.0249Z" fill={ isTheme.styles.colors.sectionBackground } />
      </mask>
      <g mask="url(#mask0_82_15495)">
        <path fillRule="evenodd" clipRule="evenodd"
          d="M25.785 22.0249C35.368 22.0249 43.57 26.9769 43.57 32.7619C43.57 38.5479 35.368 43.4999 25.785 43.4999C22.375 43.4999 18.772 42.8969 16.089 41.8719L8 43.4999L10.697 38.6159C8.998 36.9969 8 34.8209 8 32.7619C8 26.9769 16.202 22.0249 25.785 22.0249Z"
          fill={ isTheme.styles.colors.textDisable } />
      </g>
      <path fillRule="evenodd" clipRule="evenodd"
        d="M25.7429 7.49951C34.6729 7.49951 42.3159 15.1425 42.3159 24.0725C42.3159 33.0025 34.6729 40.6455 25.7429 40.6455C22.5659 40.6455 19.2079 39.7165 16.7079 38.1335L9.16992 40.6455L11.6829 33.1075C10.0989 30.6085 9.16992 27.2495 9.16992 24.0725C9.16992 15.1425 16.8129 7.49951 25.7429 7.49951Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M16.7747 37.584L16.9757 37.711C19.3837 39.236 22.6607 40.146 25.7427 40.146C34.4557 40.146 41.8167 32.785 41.8167 24.073C41.8167 15.36 34.4557 8 25.7427 8C17.0307 8 9.66966 15.36 9.66966 24.073C9.66966 27.156 10.5807 30.434 12.1057 32.84L12.2327 33.041L9.96066 39.855L16.7747 37.584ZM8.37866 41.437L11.1347 33.172C9.58766 30.633 8.66966 27.252 8.66966 24.073C8.66966 14.818 16.4877 7 25.7427 7C34.9977 7 42.8167 14.818 42.8167 24.073C42.8167 33.328 34.9977 41.146 25.7427 41.146C22.5647 41.146 19.1837 40.228 16.6437 38.682L8.37866 41.437Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M29.5971 32.5825V34.1295C29.5971 34.5575 29.2501 34.9035 28.8231 34.9035H22.6351C22.2071 34.9035 21.8611 34.5575 21.8611 34.1295V32.5825C21.8611 32.1555 22.2071 31.8095 22.6351 31.8095C23.0621 31.8095 23.4081 31.4625 23.4081 31.0355V24.8465C23.4081 24.4195 23.0621 24.0725 22.6351 24.0725C22.2071 24.0725 21.8611 23.7265 21.8611 23.2995V21.7515C21.8611 21.3245 22.2071 20.9785 22.6351 20.9785H27.2761C27.7031 20.9785 28.0501 21.3245 28.0501 21.7515V31.0355C28.0501 31.4625 28.3961 31.8095 28.8231 31.8095C29.2501 31.8095 29.5971 32.1555 29.5971 32.5825Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M28.0497 15.5635C28.0497 16.8455 27.0107 17.8845 25.7287 17.8845C24.4467 17.8845 23.4077 16.8455 23.4077 15.5635C23.4077 14.2815 24.4467 13.2425 25.7287 13.2425C27.0107 13.2425 28.0497 14.2815 28.0497 15.5635Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
    </Element>
  );
};

UiInfoIcon.propTypes = {
  /** Определяет ширину иконки */
  width: PropTypes.string,
  /** Определяет высоту иконки */
  height: PropTypes.string
};

UiInfoIcon.defaultProps = {
  width: '3.25rem',
  height: '3.25rem'
};

export default UiInfoIcon;