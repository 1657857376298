import { useState } from 'react';
import cn from 'classnames';
import styled, { css } from 'styled-components';

import UiText from '../../UiText';
import UiQuestions from '../../UiQuestions';
import UiExpand from '../../UiExpand';
import BottomDivider from './BottomDivider';

import styles from './SubsectionHeader.module.css';

const StyledSubsectionHeader = styled.div`
  ${ (props) => props.onClick && css`
    cursor: pointer;
  ` };
`;

const SubsectionHeader = ({
  title,
  badge,
  help,
  onHelp,
  bottomDivider,
  active,
  setActive,
  expand,
  onExpand
}) => {

  const [rotate, setRotate] = useState(active ? 180 : 0);

  const handlerActive = () => {
    if (active) {
      setActive(!active);
      setRotate(rotate + 180);

      if (typeof onExpand === 'function') {
        onExpand();
      }
    } else {
      setActive(!active);
      setRotate(rotate + 180);
    }
  }

  return (
    <StyledSubsectionHeader
      className={ cn(styles.subsectionHeader, bottomDivider && styles.bottomDivider) }
      onClick={ expand ? handlerActive : undefined }
    >
      { badge &&
        <div className={ styles.badge }>
          { badge }
        </div>
      }
      <UiText className={ styles.title } typography="subsection-title" view="readonly">
        { title }
        { help && <UiQuestions className={ styles.question } propagation={ false } onClick={ onHelp } /> }
      </UiText>
      { expand &&
        <div className={ styles.expand }>
          <UiExpand className={ styles.uniyes } style={ { transform: `rotate(${ rotate }deg)` } } />
        </div>
      }
      { bottomDivider && <BottomDivider className={ cn(styles.divider, !active && styles.active) } /> }
    </StyledSubsectionHeader>
  );
}

export default SubsectionHeader;