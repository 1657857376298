import { Children, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import styled, { css } from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import { useOptions } from '../../../context/OptionsProvider';
import { useTheme } from '../../../context/ThemeProvider';

import BottomDivider from '../../Functional/BottomDivider';
import Modal from '../../Functional/Modal';
import BackgroundModal from '../../Functional/BackgroundModal';
import Element from '../../Functional/Element';
import UiExpand from '../UiExpand';
import MenuItem from './Components/MenuItem';

import styles from './UiInfoPanel.module.css';

const StyledContent = styled.div`
  ${ (props) => css`
    background: ${ props.styles.colors.sectionBackground };

    ${ props.styles.typography.Note };
    color: ${ props.styles.colors.textReadOnly };
  ` }
`;

const StyledRectangle = styled.div`
  background: ${ (props) => props.styles.colors.sectionBackground };
`;

const StyledExpand = styled(UiExpand)`
  ${ (props) => props.active && css`
    transform: rotate(180deg);
  ` }
`;

const UiInfoPanel = ({ children }) => {

  const isOptions = useOptions();
  const isTheme = useTheme();

  const [bottom, setBottom] = useState(0);
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const blindRef = useRef(null);

  const onTouchStart = (event) => {
    const Y = event.touches[0].pageY;
    let touchEnd = false;

    const onTouchMove = (event) => {
      const container = containerRef.current;
      const content = contentRef.current;
      const contentHeight = content.clientHeight;

      if (isOptions.info.active) {
        if (Y - event.touches[0].pageY < contentHeight && Y - event.touches[0].pageY >= 0) {
          container.style.bottom = `${ -(contentHeight - (Y - event.touches[0].pageY)) / parseInt(getComputedStyle(document.documentElement).fontSize) }rem`;
          touchEnd = (Y - event.touches[0].pageY) > (contentHeight / 2);
        }
      } else {
        if (event.touches[0].pageY - Y < contentHeight && event.touches[0].pageY - Y >= 0) {
          container.style.bottom = `${ (-(event.touches[0].pageY - Y)) / parseInt(getComputedStyle(document.documentElement).fontSize) }rem`;
          touchEnd = (event.touches[0].pageY - Y) > (contentHeight / 2);
        }
      }
    };

    const onTouchEnd = () => {
      const container = containerRef.current;

      if (isOptions.info.active) {
        if (touchEnd) {
          isOptions.setInfo({ active: false });
        } else {
          const content = contentRef.current;
          const contentHeight = content.clientHeight;
          container.style.bottom = `${ -contentHeight }px`;

          setTimeout(() => {
            if (contentHeight === content.clientHeight) {
              container.style.bottom = null;
            }
          }, 300);
        }
      } else {
        if (touchEnd) {
          isOptions.setInfo({ active: true });
        } else {
          container.style.bottom = 0;
        }
      }

      document.removeEventListener('touchmove', onTouchMove);
      document.removeEventListener('touchend', onTouchEnd);
    };

    document.addEventListener('touchmove', onTouchMove, { passive: false });
    document.addEventListener('touchend', onTouchEnd);
  };

  const onEnter = () => {
    containerRef.current && setBottom(-contentRef.current.clientHeight);
  };

  const onEntered = () => {
    setBottom(null)
  };

  const onExit = () => {
    containerRef.current && setBottom(-contentRef.current.clientHeight);
  };

  const onExiting = () => {
    setBottom(0);
  };

  const onExited = () => {
    isOptions.setInfo({ menu: 'all' });
  };

  useEffect(() => {
    const root = document.getElementById('root');
    const container = containerRef.current;
    const content = contentRef.current;
    const blind = blindRef.current;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentRect) {
          if (container && content && blind) {
            content.style.maxHeight = `${ (entry.contentRect.height - container.parentNode.parentNode.clientHeight - blind.clientHeight) * 0.8 }px`;
          }
        }
      }
    });

    resizeObserver.observe(root);

    return () => {
      resizeObserver.unobserve(root);
    };
  }, []);

  return (
    <div className={ styles.infoPanel }>
      <CSSTransition
        in={ isOptions.info.active }
        onEnter={ onEnter }
        onEntered={ onEntered }
        onExit={ onExit }
        onExiting={ onExiting }
        onExited={ onExited }
        timeout={ 300 }
        nodeRef={ containerRef }
      >
        <div className={ styles.container } style={ bottom !== null ? { bottom } : null } ref={ containerRef }>
          <StyledContent className={ styles.content } ref={ contentRef } styles={ isTheme.styles }>
            <BottomDivider className={ styles.divider } />
            { Children.map(children, (child, index) => {
              if (isOptions.info.menu === 'all') {
                return (
                  <MenuItem
                    item={ child }
                    bottomDivider={ index !== Children.toArray(children).length - 1 }
                  />
                );
              } else if (isOptions.info.menu.split(' ').includes(child.props.id)) {
                return (
                  <MenuItem
                    item={ child }
                    bottomDivider={ isOptions.info.menu.split(' ').indexOf(child.props.id) !== isOptions.info.menu.split(' ').length - 1 }
                  />
                );
              } else {
                return null;
              }
            }) }
          </StyledContent>
          <div
            className={ styles.blind }
            onClick={ () => isOptions.setInfo({ active: !isOptions.info.active }) }
            onTouchStart={ onTouchStart }
            ref={ blindRef }
          >
            <StyledRectangle className={ styles.rectangle } styles={ isTheme.styles } />
            <Element
              className={ styles.substrate }
              propagation={ true }
              width="3.625rem"
              height="0.875rem"
              viewBox="0 0 58 14"
              color={ isTheme.styles.colors.sectionBackground }
            >
              <path
                d="M0 0V4H8.69034C10.1754 4 11.5383 4.82281 12.23 6.13702L15.2437 11.863C15.9354 13.1772 17.2982 14 18.7834 14H39.2166C40.7018 14 42.0646 13.1772 42.7563 11.863L45.77 6.13702C46.4617 4.82281 47.8246 4 49.3097 4H58V0H0Z"
              />
            </Element>
            <StyledExpand
              className={ cn(styles.substrate, styles.expand) }
              active={ isOptions.info.active }
              width="0.75rem"
              height="0.75rem"
            />
          </div>
        </div>
      </CSSTransition>
      { isOptions.info.active &&
        <Modal>
          <BackgroundModal className={ styles.backgroundModal } onClick={ () => isOptions.setInfo({ active: false }) } />
        </Modal>
      }
    </div>
  );
};

export default UiInfoPanel;