import UiMemPad from '../../../UiMemPad';
import UiDigiPad from '../../../UiDigiPad';
import UiNumPad from '../../../UiNumPad';

const Keyboard = ({
  type,
  value,
  maxLength,
  keyboardProps
}) => {

  const props = {
    ...keyboardProps,
    value,
    maxLength
  }

  if (type === 'memPad') {
    return <UiMemPad { ...props } />;
  } else if (type === 'pin') {
    return <UiDigiPad { ...props } />;
  } else {
    return <UiNumPad { ...props } />;
  }
};

export default Keyboard;