import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Иконка пункта продажи (покупки)
 * @public
 * @version 1.5.0
 * @param {String} [width="3.25rem"]
 * @param {String} [height="3.25rem"]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * import { UiPosIcon, UiPlates, UiPlate } from '@compassplus/ui-mobicash';
 *
 * const App = () => {
 *
 *   return (
 *     <>
 *       <UiPosIcon />
 *       <UiPlates>
 *         <UiPlate view="2x1" image={ <UiPosIcon width="100%" height="100%" /> } />
 *       </UiPlates>
 *     </>
 *   );
 * }; */
const UiPosIcon = ({
  width,
  height,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      innerRef={ innerRef }
      width={ width }
      height={ height }
      viewBox="0 0 52 52"
    >
      <path fillRule="evenodd" clipRule="evenodd"
        d="M38.691 19.3043L37.284 19.9853C37.017 20.1143 36.732 19.8733 36.797 19.5843C36.967 18.8383 37.06 18.0643 37.06 17.2683C37.06 11.2903 32.066 6.4403 25.902 6.4403V6.4343C19.74 6.4343 14.744 11.2553 14.744 17.2013C14.744 18.3043 14.921 19.3653 15.241 20.3663L14.286 20.8283L10.195 22.8103C10.075 22.8673 10 22.9893 10 23.1223V44.9843C10 45.2403 10.267 45.4083 10.498 45.2963L20.476 40.4673C20.571 40.4203 20.683 40.4203 20.778 40.4673L31.103 45.4653C31.198 45.5113 31.31 45.5113 31.405 45.4653L41.686 40.4883C41.806 40.4303 41.882 40.3093 41.882 40.1763V18.3143C41.882 18.0583 41.614 17.8903 41.383 18.0013L38.691 19.3043Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M20.9464 16.3531L15.0324 19.2951L11.0344 21.2851V42.9781L20.9454 38.0471L30.8564 42.9781L40.7664 38.0471V16.3541L37.7914 17.8341L30.8564 21.2841L20.9464 16.3531Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M20.9454 37.5475C21.0214 37.5475 21.0974 37.5655 21.1684 37.5995L30.8564 42.4195L40.2664 37.7375V17.1615L31.0794 21.7315C30.9384 21.8005 30.7744 21.8005 30.6334 21.7315L20.9464 16.9115L11.5344 21.5945V42.1705L20.7224 37.5995C20.7934 37.5655 20.8694 37.5475 20.9454 37.5475ZM30.8564 43.4775C30.7794 43.4775 30.7034 43.4605 30.6334 43.4255L20.9454 38.6065L11.2574 43.4255C11.1024 43.5015 10.9174 43.4945 10.7714 43.4025C10.6244 43.3115 10.5344 43.1515 10.5344 42.9775V21.2845C10.5344 21.0945 10.6424 20.9215 10.8124 20.8375L20.7234 15.9055C20.8634 15.8365 21.0284 15.8365 21.1694 15.9055L30.8564 20.7255L40.5434 15.9065C40.6984 15.8295 40.8824 15.8385 41.0294 15.9295C41.1764 16.0205 41.2664 16.1815 41.2664 16.3545V38.0475C41.2664 38.2375 41.1584 38.4105 40.9884 38.4955L31.0784 43.4255C31.0084 43.4605 30.9324 43.4775 30.8564 43.4775Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M21.1016 16.1593L21.1006 37.8533L31.0106 42.7843L31.0116 21.0893L21.1016 16.1593Z" fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M25.8645 33.422L17.3975 21.816C16.1785 20.127 15.4595 18.056 15.4595 15.819C15.4595 10.12 20.1185 5.5 25.8645 5.5V5.505C31.6135 5.505 36.2695 10.153 36.2695 15.883C36.2695 18.13 35.5545 20.213 34.3315 21.913L25.8645 33.422Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M25.5342 6.005C20.2242 6.178 15.9592 10.514 15.9592 15.819C15.9592 17.88 16.5962 19.852 17.8032 21.524L25.8662 32.576L33.9292 21.617C35.1322 19.944 35.7692 17.96 35.7692 15.883C35.7692 10.436 31.3262 6.005 25.8642 6.005H25.5342ZM25.8622 34.268L16.9932 22.111C15.6622 20.266 14.9592 18.091 14.9592 15.819C14.9592 9.853 19.8512 5 25.8642 5L26.3642 5.016C32.1462 5.278 36.7692 10.052 36.7692 15.883C36.7692 18.171 36.0672 20.357 34.7372 22.205L25.8622 34.268Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M34.0617 16.2589C34.0617 20.7419 30.4277 24.3769 25.9447 24.3769C21.4607 24.3769 17.8267 20.7419 17.8267 16.2589C17.8267 11.7759 21.4607 8.14191 25.9447 8.14191C30.4277 8.14191 34.0617 11.7759 34.0617 16.2589Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M25.9444 8.39169C21.6064 8.39169 18.0764 11.9207 18.0764 16.2587C18.0764 20.5977 21.6064 24.1267 25.9444 24.1267C30.2824 24.1267 33.8114 20.5977 33.8114 16.2587C33.8114 11.9207 30.2824 8.39169 25.9444 8.39169ZM25.9444 24.6267C21.3304 24.6267 17.5764 20.8727 17.5764 16.2587C17.5764 11.6447 21.3304 7.89169 25.9444 7.89169C30.5584 7.89169 34.3114 11.6447 34.3114 16.2587C34.3114 20.8727 30.5584 24.6267 25.9444 24.6267Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M30.3755 20.5304H21.3185C21.1075 20.5304 20.9355 20.3584 20.9355 20.1464V15.0224C20.9355 14.8844 21.0475 14.7724 21.1855 14.7724C21.3235 14.7724 21.4355 14.8844 21.4355 15.0224V20.0304H30.2585V15.0074C30.2585 14.8684 30.3705 14.7574 30.5085 14.7574C30.6465 14.7574 30.7585 14.8684 30.7585 15.0074V20.1464C30.7585 20.3584 30.5865 20.5304 30.3755 20.5304Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M21.4274 15.2706C21.5774 15.3226 21.7374 15.3506 21.9054 15.3506C22.4774 15.3506 22.9754 15.0216 23.2194 14.5426C23.4624 15.0216 23.9604 15.3506 24.5324 15.3506C25.1064 15.3506 25.6044 15.0216 25.8474 14.5426C26.0904 15.0216 26.5884 15.3506 27.1614 15.3506C27.7344 15.3506 28.2324 15.0216 28.4754 14.5426C28.7194 15.0216 29.2164 15.3506 29.7894 15.3506C29.9564 15.3506 30.1174 15.3226 30.2664 15.2706C30.2664 15.2706 31.2424 14.9546 31.2624 13.8776C31.2624 13.8506 31.2554 13.8226 31.2414 13.7986L29.9834 11.5956C29.8944 11.4406 29.7284 11.3456 29.5504 11.3456H22.1434C21.9654 11.3456 21.8004 11.4406 21.7114 11.5956L20.4524 13.7986C20.4394 13.8226 20.4314 13.8506 20.4314 13.8776C20.4144 14.8346 21.4274 15.2706 21.4274 15.2706Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M28.7276 19.3839H27.7056C27.5666 19.3839 27.4556 19.2719 27.4556 19.1339C27.4556 18.9959 27.5666 18.8839 27.7056 18.8839H28.6776V16.3309L22.9426 16.3299V18.8829L26.4446 18.8839C26.5826 18.8839 26.6946 18.9959 26.6946 19.1339C26.6946 19.2719 26.5826 19.3839 26.4446 19.3839H22.8926C22.6406 19.3839 22.4426 19.2159 22.4426 19.0009V16.2129C22.4426 15.9979 22.6406 15.8299 22.8926 15.8299H28.7276C28.9796 15.8299 29.1776 15.9979 29.1776 16.2129V19.0009C29.1776 19.2159 28.9796 19.3839 28.7276 19.3839Z"
        fill={ isTheme.styles.colors.textTitle } />
    </Element>
  );
};

UiPosIcon.propTypes = {
  /** Определяет ширину иконки */
  width: PropTypes.string,
  /** Определяет высоту иконки */
  height: PropTypes.string
};

UiPosIcon.defaultProps = {
  width: '3.25rem',
  height: '3.25rem'
};

export default UiPosIcon;