import React, { useEffect, useState } from 'react';
import { Dialog, Text } from '../../TemplateStyle';
import i18n from '../../../i18n';
import { LinkInput, LinkInputDisabled } from './style';
import { getCashierLogin, request } from '../../../Utils/requestUtil';

const LinkSettings = ({ active, setActive, merchantLink = '' }) => {
  const [link, setLink] = useState(merchantLink);
  const [mode, setMode] = useState('normal');
  const [message, setMessage] = useState();

  useEffect(() => {
    request(`/api/v1/cashier/getMarketUrl/${getCashierLogin()}`, 'GET').then(
      (response) => {
        if (response !== '' || response !== undefined) {
          setLink(response);
        }
      },
    );
  }, [merchantLink]);

  const onLinkChange = (event) => {
    event.target.value = event.target.value.replace(/[^0-9.a-zA-Z-]/g, '');
    setLink(event.target.value);
  };

  const saveChanges = () => {
    const requestData = {
      login: getCashierLogin(),
      link,
    };

    request('/api/v1/cashier/setMarketURL', 'POST', requestData)
      .then(
        (response) => {
          window.location.reload();
          setActive(false);
        },
        (error) => {
          if (error === 'URL_IS_USED') {
            setMode('warning');
            setMessage(i18n.t('LinkIsUsed'));
          }
        },
      );
  };

  return (
    <>
      {
        active && (
          <Dialog
            title={i18n.t('LinkSettings')}
            text={message}
            mode={mode}
            buttons={[
              {
                text: i18n.t('Save'),
                mode: 'bold',
                action: () => {
                  if (link !== '') {
                    setMode('normal');
                    saveChanges();
                  } else {
                    setMode('warning');
                    setMessage(i18n.t());
                  }
                },
              },
              {
                text: i18n.t('Cancel'),
                mode: '',
                action: () => { setActive(false); },
              },
            ]}
          >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <LinkInputDisabled value={`${window.location.origin}/`} disabled />
              <Text typography="note" view="readonly" position="center">{i18n.t('CustomLinkMessage')}</Text>
              <LinkInput placeholder="market" value={link} onChange={onLinkChange} />
            </div>
          </Dialog>
        )
      }
    </>
  );
};

export default LinkSettings;
