import React, { useEffect } from 'react';
import { useOptions } from '@compassplus/ui-mobicash';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router';
import { MContentDiv, SubContentDiv } from '../../../components/TemplateStyle';
import AddAttribute from './ModifyAttribute';
import GlobalStateStore from '../../../stores/GlobalStateStore';
import i18n from '../../../i18n';
import ModifyItem from "../ModifyItemPage/ModifyItem";
import ItemStore from "../../../stores/ItemStore";

const ModifyAttributePage = observer((props) => {
  const isOptions = useOptions();
  const history = useHistory();
  useEffect(() => {
    GlobalStateStore.setHeaderProperties({
      title: i18n.t('Attribute'),
      isBack: true,
      onBack: () => { history.goBack(); },
    });
  }, []);

  return (
    <>
      {
        isOptions.screen === 'mobile' ? (
          <MContentDiv>
            <AddAttribute />
          </MContentDiv>
        ) : (
          <>
            <MContentDiv onClick={() => { history.goBack(); }}>
              <ModifyItem {...props} itemId={ItemStore.article} disableSave />
            </MContentDiv>
            <SubContentDiv>
              <AddAttribute />
            </SubContentDiv>
          </>
        )
      }
    </>
  );
});

export default ModifyAttributePage;
