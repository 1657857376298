import PropTypes from 'prop-types';
import cn from 'classnames';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

import styles from './UiRadioButton.module.css';

/**
 * Radiobutton component
 * @public
 * @version 0.0.90
 * @param {String} className
 * @param {String} name
 * @param {Boolean} [checked=false]
 * @param {Boolean} [defaultChecked=false]
 * @param {Boolean} [disabled=false]
 * @param {Boolean} [required=false]
 * @param {Function} onClick
 * @param {Function} onChange
 * @param {Function} onFocus
 * @param {Function} onBlur
 * @param {Function|Object} [innerRef]
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiRadioButton />
 */
const UiRadioButton = ({
  className,
  name,
  checked,
  defaultChecked,
  disabled,
  required,
  onClick,
  onChange,
  onFocus,
  onBlur,
  innerRef
}) => {

  const isTheme = useTheme();

  const handlerEvent = (event, func) => {
    event.stopPropagation();
    func && func(event);
  }

  return (
    <label className={ cn(styles.container, className) }>
      <input
        type="radio"
        className={ styles.radiobutton }
        name={ name }
        checked={ defaultChecked ? undefined : checked }
        defaultChecked={ undefined }
        disabled={ disabled }
        required={ required }
        onClick={ (event) => handlerEvent(event, onClick) }
        onChange={ (event) => handlerEvent(event, onChange) }
        onFocus={ (event) => handlerEvent(event, onFocus) }
        onBlur={ (event) => handlerEvent(event, onBlur) }
        ref={ innerRef }
      />
      <span className={ styles.button }>
        <Element
          color={ isTheme.styles.colors.applicationPrimaryColor }
          disabled={ disabled }
          width="1rem"
          height="1rem"
          viewBox="0 0 16 16"
        >
          <path className={ styles.select } fillRule="evenodd" clipRule="evenodd"
            d="M0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8ZM15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z" />
          <path className={ styles.active }
            d="M8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16ZM8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8 13C5.23858 13 3 10.7614 3 8C3 5.23858 5.23858 3 8 3C10.7614 3 13 5.23858 13 8C13 10.7614 10.7614 13 8 13Z" />
        </Element>
      </span>
    </label>
  );
};

UiRadioButton.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Уникальное имя элемента формы */
  name: PropTypes.string,
  /** Определяет, помечен ли заранее чекбокс */
  checked: PropTypes.bool,
  /** Делает чекбокс неуправляемым компонентом */
  defaultChecked: PropTypes.bool,
  /** Делает чекбокс неактивным */
  disabled: PropTypes.bool,
  /** Чекбокс обязателен для выделения */
  required: PropTypes.bool,
  /** Слушатель на клик по чекбоксу */
  onClick: PropTypes.func,
  /** Слушатель при смене состояния чекбокса */
  onChange: PropTypes.func,
  /** Слушатель по фокусу */
  onFocus: PropTypes.func,
  /** Слушатель по потере фокуса */
  onBlur: PropTypes.func,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

UiRadioButton.defaultProps = {
  checked: false,
  defaultChecked: false,
  disabled: false,
  required: false,
  onChange: () => {}
};

export default UiRadioButton;