import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { MContentDiv, Text } from '../../../components/TemplateStyle';

import GlobalStateStore from '../../../stores/GlobalStateStore';
import LanguageSelector from './LanguageSelector';
import { getAuth } from '../../../Utils/requestUtil';
import CMSConnections from '../../../components/admin/CMSConnections/CMSConnections';
import i18n from '../../../i18n';

const Settings = observer(() => {
  useEffect(() => {
    if (getAuth()) {
      GlobalStateStore.setHeaderProperties({
        title: 'Settings',
        isBack: true,
        help: true,
        content: () => (
          <div id="block-one">
            <Text typography="note" view="readonly" position="center">
              {i18n.t('CMS_HelpMessage')}
            </Text>
          </div>
        ),
      });
    } else {
      GlobalStateStore.setHeaderProperties({
        title: 'Settings',
        isBack: true,
      });
    }
  }, [getAuth()]);

  return (
    <>
      <MContentDiv>
        <LanguageSelector />
        {
          getAuth() ? (
            <CMSConnections />
          ) : null
        }
      </MContentDiv>
    </>
  );
});

export default Settings;
