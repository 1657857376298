import cn from 'classnames';
import styled from 'styled-components';

import { useTheme } from '../../../context/ThemeProvider';

import styles from './BottomDivider.module.css';

const StyledBottomDivider = styled.div`
  border: none;
  border-top: 0.063rem solid ${ (props) => props.styles.colors.overlay_zero_point_two };
`;

const BottomDivider = ({
  className,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <StyledBottomDivider
      { ...props }
      className={ cn(styles.divider, className) }
      styles={ isTheme.styles }
    />
  );
}

export default BottomDivider;