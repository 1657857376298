import PropTypes from 'prop-types';

/**
 * Tab block component
 * @public
 * @version 0.0.90
 * @param {String} className
 * @param {String} title
 * @param {Boolean} active
 * @param {Boolean} disabled
 * @param {Function} onClick
 * @param {*} children
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiTabBlock />
 */
const UiTabBlock = ({
  className,
  title,
  active,
  disabled,
  onClick,
  children,
  ...props
}) => {

  return (
    <div
      { ...props }
      className={ className }
      title={ title }
      active={ active }
      disabled={ disabled }
      onClick={ onClick }
    >
      { children }
    </div>
  );
};

UiTabBlock.propTypes = {
  /** Классы CSS */
  className: PropTypes.string,
  /** Заголовок контейнера */
  title: PropTypes.string.isRequired,
  /** Флаг для установки активной вкладки */
  active: PropTypes.bool,
  /** Блокирует заголовок и блок контейнера */
  disabled: PropTypes.bool,
  /** Обработчик клика на заголовок таба */
  onClick: PropTypes.func,
  /** Контент блока контейнера */
  children: PropTypes.any
};

export default UiTabBlock;