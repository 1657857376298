import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Иконка социальной сети "Instagram"
 * @public
 * @version 1.5.0
 * @param {String} [width="3.25rem"]
 * @param {String} [height="3.25rem"]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * import { UiInstaIcon, UiPlates, UiPlate } from '@compassplus/ui-mobicash';
 *
 * const App = () => {
 *
 *   return (
 *     <>
 *       <UiInstaIcon />
 *       <UiPlates>
 *         <UiPlate view="2x1" image={ <UiInstaIcon width="100%" height="100%" /> } />
 *       </UiPlates>
 *     </>
 *   );
 * }; */
const UiInstaIcon = ({
  width,
  height,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      innerRef={ innerRef }
      width={ width }
      height={ height }
      viewBox="0 0 52 52"
    >
      <path fillRule="evenodd" clipRule="evenodd"
        d="M35.6 28.8003H16.3C11.7 28.8003 8 30.5003 8 32.5003V41.1003C8 43.1003 11.7 44.8003 16.3 44.8003H35.6C40.2 44.8003 43.9 43.1003 43.9 41.1003V32.5003C43.9 30.5003 40.1 28.8003 35.6 28.8003Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M35.1 8.5H17C12.7 8.5 9.19995 12 9.19995 16.3V34.4C9.19995 38.7 12.7 42.2 17 42.2H35.1C39.4 42.2 42.9 38.7 42.9 34.4V16.3C42.9 12 39.3 8.5 35.1 8.5Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M17 9C12.975 9 9.69995 12.275 9.69995 16.3V34.4C9.69995 38.425 12.975 41.7 17 41.7H35.1C39.125 41.7 42.4 38.425 42.4 34.4V16.3C42.4 12.343 39.057 9 35.1 9H17ZM35.1 42.7H17C12.423 42.7 8.69995 38.977 8.69995 34.4V16.3C8.69995 11.724 12.423 8 17 8H35.1C39.599 8 43.4 11.801 43.4 16.3V34.4C43.4 38.977 39.677 42.7 35.1 42.7Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M32.5999 12.8999H19.1999C15.9999 12.8999 13.3999 15.4999 13.3999 18.6999V31.9999C13.3999 35.1999 15.9999 37.7999 19.1999 37.7999H32.5999C35.7999 37.7999 38.3999 35.1999 38.3999 31.9999V18.5999C38.2999 15.4999 35.6999 12.8999 32.5999 12.8999Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M19.1997 13.1499C16.1397 13.1499 13.6497 15.6399 13.6497 18.6999V31.9999C13.6497 35.0609 16.1397 37.5499 19.1997 37.5499H32.5997C35.6597 37.5499 38.1497 35.0609 38.1497 31.9999V18.5999C38.0527 15.5989 35.5627 13.1499 32.5997 13.1499H19.1997ZM32.5997 38.0499H19.1997C15.8637 38.0499 13.1497 35.3359 13.1497 31.9999V18.6999C13.1497 15.3639 15.8637 12.6499 19.1997 12.6499H32.5997C35.8297 12.6499 38.5437 15.3159 38.6497 18.5919V31.9999C38.6497 35.3359 35.9357 38.0499 32.5997 38.0499Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M34.1995 16C33.1995 16 32.4995 16.8 32.4995 17.7C32.4995 18.7 33.2995 19.4 34.1995 19.4C35.0995 19.4 35.8995 18.6 35.8995 17.7C35.8995 16.8 35.1995 16 34.1995 16Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M25.8998 18C29.9998 18 33.2998 21.3 33.2998 25.4C33.2998 29.5 29.9998 32.8 25.8998 32.8C21.7998 32.8 18.4998 29.5 18.4998 25.4C18.6218 20.267 22.4998 18 25.8998 18Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M26.1747 30.7188C25.5237 30.7188 24.8687 30.5988 24.2357 30.3588C22.8707 29.8398 21.7827 28.8258 21.1727 27.5048C20.5657 26.1898 20.5187 24.7098 21.0407 23.3368C21.5597 21.9708 22.5737 20.8828 23.8947 20.2728C25.0387 19.7538 26.3057 19.6438 27.4657 19.9588C27.5987 19.9958 27.6767 20.1328 27.6407 20.2658C27.6047 20.3988 27.4687 20.4788 27.3337 20.4418C26.2847 20.1558 25.1387 20.2578 24.1037 20.7278C22.9027 21.2818 21.9807 22.2718 21.5077 23.5138C21.0347 24.7608 21.0767 26.1038 21.6267 27.2948C22.1817 28.4968 23.1717 29.4198 24.4137 29.8918C25.6607 30.3658 27.0037 30.3238 28.1947 29.7728C30.2167 28.8548 31.3497 26.6218 30.9537 24.3438C30.9297 24.2068 31.0207 24.0778 31.1567 24.0538C31.2897 24.0288 31.4217 24.1208 31.4457 24.2578C31.8817 26.7608 30.6307 29.2158 28.4037 30.2278C27.6947 30.5548 26.9377 30.7188 26.1747 30.7188Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M25.7996 33.0503C24.1926 33.0503 22.6216 32.5503 21.2576 31.6053C21.1436 31.5273 21.1156 31.3713 21.1946 31.2573C21.2736 31.1433 21.4276 31.1163 21.5426 31.1943C22.8226 32.0813 24.2946 32.5503 25.7996 32.5503C29.7416 32.5503 32.9496 29.3423 32.9496 25.4003C32.9496 21.4573 29.7416 18.2503 25.7996 18.2503C22.6106 18.2503 19.7256 20.5253 18.9426 23.6603C18.9086 23.7943 18.7706 23.8733 18.6386 23.8423C18.5046 23.8093 18.4236 23.6733 18.4566 23.5393C19.3096 20.1313 22.3286 17.7503 25.7996 17.7503C30.0176 17.7503 33.4496 21.1813 33.4496 25.4003C33.4496 29.6183 30.0176 33.0503 25.7996 33.0503Z"
        fill={ isTheme.styles.colors.textTitle } />
    </Element>
  );
};

UiInstaIcon.propTypes = {
  /** Определяет ширину иконки */
  width: PropTypes.string,
  /** Определяет высоту иконки */
  height: PropTypes.string
};

UiInstaIcon.defaultProps = {
  width: '3.25rem',
  height: '3.25rem'
};

export default UiInstaIcon;