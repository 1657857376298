import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Element component
 * @public
 * @version 0.0.90
 * @param {Function} onClick
 * @param {Boolean} [disabled=false]
 * @param {Boolean} [propagation=true]
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiScrollArrow />
 */
const UiScrollArrow = ({
  onClick,
  disabled,
  propagation,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      color={ isTheme.styles.colors.textLink }
      disabled={ disabled }
      propagation={ propagation }
      onClick={ onClick }
      innerRef={ innerRef }
      width="1rem"
      height="1rem"
      viewBox="0 0 16 16"
    >
      <path
        d="M8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16ZM8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM11.7896 8.50838L8.50998 11.7893C8.22914 12.0702 7.7752 12.0702 7.49436 11.7893C7.21352 11.5083 7.21352 11.0542 7.49436 10.7733L9.55217 8.7146H4.71826C4.32106 8.7146 4 8.39269 4 7.99605C4 7.59869 4.32106 7.2775 4.71826 7.2775L9.54356 7.2775L7.49436 5.22675C7.3543 5.08663 7.28391 4.90268 7.28391 4.71873C7.28391 4.53478 7.3543 4.35083 7.49436 4.21072C7.77448 3.92976 8.22914 3.92976 8.50998 4.21072L11.7896 7.49234C11.9246 7.62671 12 7.80922 12 8.00036C12 8.19078 11.9246 8.37329 11.7896 8.50838Z" />
    </Element>
  );
};

UiScrollArrow.propTypes = {
  /** Обработчик события при клике */
  onClick: PropTypes.func,
  /** Компонент неактивен */
  disabled: PropTypes.bool,
  /** Разрешить/запретить всплытие обработчика onClick */
  propagation: PropTypes.bool,
  /** Ссылка на узел DOM */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

UiScrollArrow.defaultProps = {
  disabled: false,
  propagation: true
};

export default UiScrollArrow;