import { useOptions } from '@compassplus/ui-mobicash';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Text } from '../../TemplateStyle';

const Footer = observer(() => {
  const isOptions = useOptions();
  if (isOptions.screen === 'mobile') {
    return (
      <div style={{ padding: '0.5rem' }}>
        <Text view="readonly" typography="note">
          Powered by MobiCash.
          <br />
          Copyright © 2023 Compass Plus Ltd.. All rights reserved.
        </Text>
      </div>
    );
  }
  return null;
});

export default Footer;
