import styled from 'styled-components';
import { UiSubsection, UiCard } from '@compassplus/ui-mobicash';
import { MainText } from '../../TemplateStyle';

export const Subsection = styled(UiSubsection)`
  margin: 1rem 0 0;
  & > div > div:first-child > div {
    width: auto !important; 
  }
`;

export const ImageSubsection = styled(UiSubsection)`
  margin: 1rem 0 5rem;
  & > div:last-child {
    --containerHeight: auto !important; 
  }
`;

export const AddCard = styled(UiCard)`
  cursor: pointer;
  margin: 0.5rem 0.5rem 0 0;
  width: 4.375rem;
  height: 4.375rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

export const MiniImageCard = styled(UiCard)`
  margin: 0.5rem 0.5rem 0 0;
  width: 4.375rem;
  height: 4.375rem;
  position: relative;
  cursor: pointer;
`;

export const DeleteButton = styled.div`
  position: absolute;
  width: 0.793125rem;
  height: 0.793125rem;
  border-radius: 30px !important;
  background: #fff;
  cursor: pointer;
  margin: 0.131875rem 0 0 0.131875rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AddText = styled(MainText)`
  margin: 0;
  color: ${(props) => props.theme.textLink};
  font-size: 0.5625rem;
  line-height: 0.6875rem;
  text-align: center;
`;

export const ImageCard = styled(UiCard)`
  width: 320px;
  height: 320px;
  margin: 1rem auto 0;
  display: flex;
  position: relative;
  
`;
