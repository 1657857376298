import React, {  useState } from 'react';
import { UiCard, UiRadioButton, useOptions } from '@compassplus/ui-mobicash';
import { Text } from "../../../TemplateStyle";
import AttributesStore from "../../../../stores/AttributesStore";
import i18n from '../../../../i18n';
import { ColorSection } from "../style";
import { action } from "mobx";
import ItemStore from "../../../../stores/ItemStore";
import MarketStore from "../../../../stores/MarketStore";

const Color = ({ onSelectClick, isActive }) => {
  const isOptions = useOptions();
  const attributes = AttributesStore.getAttributesByType('color');
  const [prevSelected, setPrevSelected] = useState(null);

  const onClick = action((color, index) => {
    const selectedIndex = AttributesStore.selectedAttributesNamesArr.indexOf(prevSelected);
    setImageLinkedToColor(color);
    if (selectedIndex === -1) { setPrevSelected(null); }
    if (color.active) {
      attributes[0].changeValue(index, !color.value);
      if (prevSelected) {
        AttributesStore.selectedAttributesNamesArr.splice(selectedIndex, 1);
        setPrevSelected(null);
      }
      if (color.value) {
        setPrevSelected(color.title.en);
        AttributesStore.selectedAttributesNamesArr.push(color.title.en);
        onSelectClick(attributes[0]);
      } else {
        AttributesStore.setDefaultActive();
        setPrevSelected(null);
        onSelectClick();
      }
    } else {
      if (color.disabled) {
        if (prevSelected) {
          attributes[0].changeValue(index, false);
          AttributesStore.selectedAttributesNamesArr.splice(AttributesStore.selectedAttributesNamesArr.indexOf(prevSelected), 1);
          onSelectClick();
          setPrevSelected(null);
        }
      } else {
        AttributesStore.setAllRadioFalse();
        attributes[0].changeValue(index, !color.value);
        setPrevSelected(null);
        AttributesStore.selectedAttributesNamesArr = [];
        setPrevSelected(color.title.en);
        AttributesStore.selectedAttributesNamesArr.push(color.title.en);
        AttributesStore.setDefaultActive();
        onSelectClick(attributes[0]);
      }
    }
  })

  const setImageLinkedToColor = action((color) => {
    if (ItemStore.images && ItemStore.images.length !== 0) {
      const index = ItemStore.images.findIndex((el) => el.color && el.color === color.code)
      if (index !== -1) {
        MarketStore.selectedImageIndex = index;
      }
    }
  });

  const getColorOption = (color, index) => {
    return (
      <ColorSection
        style={color.disabled ? { opacity: 0.8 } : {}}
        title={color.title[isOptions.language]}
        bottomDivider={index !== attributes[0].complexValue.length - 1}
        onClick={() => onClick(color, index)}
        disabled={!isActive}
        leftElement={(
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          >
            <UiRadioButton
              disabled={!color.active || color.disabled || !isActive}
              checked={color.value}
            />
            <div style={{
              width: '38px', height: '38px', marginLeft: '12px', background: color.code, borderRadius: '40px', border: '0.5px solid #200B15',
            }}
            />
          </div>
        )}
      />
    )
  }

  const renderColor = (attribute) => {
    return (
      <>
        <Text style={{ margin: '0.5rem 0' }} typography="subsection-title" view="readonly" id="color-attribute-title">
          {i18n.t(attribute.title[isOptions.language])}
        </Text>
        <Text
          style={{ margin: '0.5rem 0' }}
          typography="note"
          view="readonly"
        >
          {attribute.description[isOptions.language]}
        </Text>
        <UiCard shadow>
          {attribute?.complexValue.map(getColorOption)}
        </UiCard>
      </>
    )
  }

  return (
    <>
      {attributes.map(renderColor)}
    </>
  );
};

export default Color;