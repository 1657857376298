import React, {useState} from 'react';
import { Text } from '../../../../components/TemplateStyle';
import i18n from '../../../../i18n';
import {SubContentStiky} from "../../ModifyAttributePage/style";
import {UiButton, UiCard, UiInput, useOptions} from "@compassplus/ui-mobicash";
import OrdersRecordsStore from "../../../../stores/OrdersRecordsStore";
import {useHistory} from "react-router";

const OrderPersonalData = () => {
  const { modifyingOrder } = OrdersRecordsStore;
  const isOptions = useOptions();
  const history = useHistory();

  const [personalData, setPersonalData] = useState(modifyingOrder?.customer?.personalData);

  const renderPersonalDataField = () => (
    <>
      {personalData?.map((field, index) => (
        <React.Fragment key={field.Name}>
          <UiInput
            description={isOptions.language === 'ru' ? field.Title : field.Name}
            value={field.Value}
            placeholder={`[${i18n.t('Text')}]`}
            onChange={(event) => {
              let newData = [...personalData];
              newData[index] = {
                ...newData[index],
                'Value': event.target.value
              };
              setPersonalData(newData);
            }}
            bottomDivider={index !== personalData.length - 1}
          />
        </React.Fragment>
      ))}
    </>
  )

  return (
    <>
      <Text
        style={{ textTransform: 'uppercase', fontWeight: 600 }}
        view="readonly"
        typography="note"
      >
        {i18n.t('PersonalData')}
      </Text>
      <UiCard
        style={{ margin: '0.5rem 0 0.25rem' }}
        shadow
      >
        {renderPersonalDataField()}
      </UiCard>
      <SubContentStiky>
        <UiButton
          title={i18n.t('Save')}
          onClick={() => {
            OrdersRecordsStore.modifyCustomerData({ personalData });
            history.replace(`/modifyOrder/${modifyingOrder._id}`);
          }}
        />
      </SubContentStiky>
    </>
  );
}

export default OrderPersonalData;