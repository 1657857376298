import React from 'react';
import {Route, Switch} from 'react-router';

import { ThemeProvider } from 'styled-components';

import './App.less';

// Default components
import Modal from './Modals';

// Pages for routes
import MainPage from '../pages/admin/MainPage';
import Authorization from '../pages/admin/Authorization';
import ModifyGroupPage from '../pages/admin/ModifyGroupPage';
import ModifyItemPage from '../pages/admin/ModifyItemPage';
import AdministrationPage from '../pages/admin/AdministrationPage';
import NoMatch from '../pages/admin/NoMatch';
import Order from '../pages/market/OrderPage';
import Market from '../pages/market/MarketPage';
import ShoppingCartPage from '../pages/market/ShoppingCartPage';
import CheckoutPage from '../pages/market/CheckoutPage';
import CashierAuth from '../pages/admin/CashierAuthPage';
import DeliveryForm from '../pages/admin/DeliveryModifyPage';
import DeliveryAttributePage from '../pages/admin/DeliveryAttributeModifyPage';
import SettingsPage from '../pages/admin/SettingsPage';
import SelectItemsPage from '../pages/admin/SelectItemsPage';
import AddAttributePage from '../pages/admin/ModifyAttributePage';

// PageContainers
import PrivateRoute from '../Utils/Routes/PrivateRoute';
import AdminPageRoute from '../Utils/Routes/AdminPageRoute';
import MarketRoute from '../Utils/Routes/MarketRoute';

// Theme
import { lightTheme, darkTheme } from './Theme/theme';
import { useDarkMode } from './Theme/useDarkMode';
import { GlobalStyles } from './Theme/globalStyles';
import CountDependenciesPage from "../pages/admin/CountDependenciesPage";
import CategoryActivePage from "../pages/admin/CategoryActivePeriodPage";
import OrdersPage from "../pages/admin/OrdersPage";
import ModifyOrderPage from "../pages/admin/ModifyOrderPage";
import OrderStatusPage from "../pages/market/OrderStatusPage";
import MerchantsPage from "../pages/market/MerchantsPage";

const App = () => (
  <ThemeProvider theme={useDarkMode().isDarkTheme ? darkTheme : lightTheme}>
    <GlobalStyles />
    <Switch>
      <AdminPageRoute exact path="/settings" component={SettingsPage} />
      <AdminPageRoute exact path="/authorization" component={Authorization} />
      <PrivateRoute exact path="/uploadItem" component={ModifyItemPage} />
      <PrivateRoute exact path="/uploadGroup" component={ModifyGroupPage} />
      <PrivateRoute exact path="/main/:id?" component={MainPage} />
      <PrivateRoute exact path="/select/:id?" component={SelectItemsPage} />
      <PrivateRoute exact path="/modifyGroup/:id?" component={ModifyGroupPage} />
      <PrivateRoute exact path="/activePeriod" component={CategoryActivePage} />
      <PrivateRoute exact path="/modifyItem/:id?" component={ModifyItemPage} />
      <PrivateRoute exact path="/countDependencies" component={CountDependenciesPage} />
      <PrivateRoute exact path="/addAttribute" component={AddAttributePage} />
      <PrivateRoute exact path="/administration" component={AdministrationPage} />
      <PrivateRoute exact path="/administration/cashierAuth" component={CashierAuth} />
      <PrivateRoute exact path="/administration/delivery/:id?" component={DeliveryForm} />
      <PrivateRoute exact path="/administration/attribute/:id?" component={DeliveryAttributePage} />
      <PrivateRoute exact path="/orders" component={OrdersPage} />
      <PrivateRoute exact path="/modifyOrder/:orderId/:attribute?" component={ModifyOrderPage} />
      <Route exact path="/merchants" component={MerchantsPage} />
      <Route exact path="/order/:orderId?" component={OrderStatusPage} />
      <MarketRoute exact path="/:merchant/order/:id" component={Order} />
      <MarketRoute exact path="/:merchant/shoppingCart" component={ShoppingCartPage} />
      <MarketRoute exact path="/:merchant/checkout" component={CheckoutPage} />
      <MarketRoute path="/:merchant/:id?" component={Market} />
      <PrivateRoute component={NoMatch} />
    </Switch>
    <Modal />
  </ThemeProvider>
);

export default App;
