import { AsYouType } from 'libphonenumber-js';

import { regexps } from '../constants';

import countries from '../resources/json/countries.json';

/**
 * Создаёт и возвращает объект номера телефона
 * @param [mobile=]
 * @return {{phoneCountryCode: string, valueFormat: (string|string), valueValid: boolean, alpha2: (String|*|string), value: string}}
 */
export function createMobile(mobile = '') {
  return {
    value: validateMobile(clearMobile(mobile)),
    valueFormat: formatMobile(mobile) || '+',
    valueValid: isValidMobile(clearMobile(validateMobile(mobile))),
    alpha2: findCountry(mobile)?.alpha2 || 'default',
    phoneCountryCode: findCountry(mobile)?.phoneCountryCode || ''
  };
}

/**
 * Возвращает очищенный номер телефона
 * @param {String} [mobile=]
 * @return {string}
 */
export function clearMobile(mobile = '') {
  return String(mobile).replace(regexps.MOBILE, '');
}

/**
 * Возвращает найденные данные страны номера телефона (длина, телефоный код страны, alpha2, код страны)
 * @param {String} [mobile=]
 * @return {null|*}
 */
export function findCountry(mobile = '') {
  for (const country in countries) {
    if (
      countries.hasOwnProperty(country) && clearMobile(mobile).startsWith(countries[country].phoneCountryCode) &&
      (
        (countries[country].hasOwnProperty('priority') && countries[country].priority === '1') ||
        !countries[country].hasOwnProperty('priority'))
      )
    {
      return { ...countries[country] };
    }
  }

  return null;
}

/**
 * Возвращает форматированное значение номера телефона (+NNNNNNNNNNNN)
 * @param {String} [mobile=]
 * @return {string}
 */
export function validateMobile(mobile = '') {
  mobile = clearMobile(mobile);

  while (mobile.length > 3 && findCountry(mobile) === null) {
    mobile = mobile.slice(0, -1);
  }

  if (findCountry(mobile) !== null) {
    while (mobile.length > (findCountry(mobile).phoneLength + findCountry(mobile).phoneCountryCode.length)) {
      mobile = mobile.slice(0, -1);
    }
  }

  if (mobile.length > 0 && !mobile.startsWith('+')) {
    mobile = '+' + mobile;
  }

  return mobile;
}

/**
 * Возвращает форматированное значение номера телефона (+NN NNN NNN NN NN)
 * @param {String} [mobile=]
 * @return {string}
 */
export function formatMobile(mobile = '') {
  return new AsYouType().input(validateMobile(clearMobile(mobile)));
}

/**
 * Возвращает логическое значения (false/true) о валидности значения номера телефона
 * @param {String} [mobile=]
 * @return {boolean}
 */
export function isValidMobile(mobile = '') {
  mobile = clearMobile(mobile);

  const countryMobile = findCountry(mobile);

  if (countryMobile === null) {
    return false;
  }

  return mobile.length === (countryMobile.phoneLength + countryMobile.phoneCountryCode.length);
}