import React from 'react';
import { UiCard, UiInput, UiArrow } from '@compassplus/ui-mobicash';
import i18n from '../../../i18n';
import { useHistory } from 'react-router';
import CategoryStore from "../../../stores/CategoryStore";
import {observer} from "mobx-react-lite";

const CategoryActiveAttribute = observer(() => {
  const history = useHistory();
  return (
    <UiInput
      description={i18n.t('ActivePeriod')}
      value={CategoryStore.getSelectedActivePeriods() !== '' ? CategoryStore.getSelectedActivePeriods() : i18n.t('Always')}
      readOnly
      onClick={(event) => {
        history.push('/activePeriod')
        event.stopPropagation();
      }}
      rightElement={<UiArrow />}
    />
  );
});

export default CategoryActiveAttribute;