import styled from 'styled-components';
import {AnimationCard} from "../../../components/TemplateStyle";

export const MarketMerchantCard = styled(AnimationCard)`
  width: 156px;
  height: 177px;
  position: relative;
  cursor: pointer;
  margin: 0.25rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.13);
  
  @media(max-width: 483px) {
    margin: 0.25rem 0.25rem;
  }
  
  @media(max-width: 346px) {
    margin: 0.25rem 0.125rem;
    width: 148px;
    height: 170px;
  }
`;

export const MarketMerchantLogo = styled.img`
  width: 134px;
  height: 134px;
  border-radius: 50px;
  margin: auto;
`
