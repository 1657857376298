import React, { useEffect } from 'react';
import { useOptions } from '@compassplus/ui-mobicash';
import { MContentDiv, SubContentDiv } from '../../../components/TemplateStyle';

import Main from "../MainPage/Main";
import Administration from "../AdministrationPage/Administration";
import {observer} from "mobx-react-lite";
import GlobalStateStore from "../../../stores/GlobalStateStore";
import ItemStore from "../../../stores/ItemStore";
import CashierAuth from "./CashierAuth";

const CashierAuthPage = observer((props) => {
  const isOptions = useOptions();
  const { history, location } = props;

  useEffect(() => {
    if (location?.state?.type === 'item' && !ItemStore.groupGuid) {
      history.goBack();
    }

    GlobalStateStore.setHeaderProperties({
      title: 'Administration',
      isBack: true
    });
  }, []);

  const renderContentDivs = () => {
    if (isOptions.screen === 'mobile') {
      return (
        <MContentDiv>
          <CashierAuth location={location} />
        </MContentDiv>
      )
    } else {
      if (location?.state?.getLink) {
        return (
          <>
            <MContentDiv>
              {
                (location?.state?.type === 'market' || location?.state?.type === 'group') ? (
                  <Main groupId={location.state.id} />
                ) : (
                  <Main groupId={ItemStore.groupGuid._id} />
                )
              }
            </MContentDiv>
            <SubContentDiv>
              <CashierAuth location={location} />
            </SubContentDiv>
          </>
        )
      }

      return (
        <>
          <MContentDiv>
            <Administration location={location} isPage={false} />
          </MContentDiv>
          <SubContentDiv>
            <CashierAuth location={location} />
          </SubContentDiv>
        </>
      )
    }
  }

  return (
    <>
      {renderContentDivs()}
    </>
  );
});

export default CashierAuthPage;
