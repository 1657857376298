import React, { useState } from 'react';
import { UiCardSection, UiDots } from '@compassplus/ui-mobicash';
import i18n from '../../../i18n';
import ActionSheet from '../../../components/admin/ActionSheet';
import { AnimationCard, DeleteSection } from '../../../components/TemplateStyle';

const OrderCard = ({
  order, rightElement, onClick, style, active, isDeleteSection, onDelete,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const getOrderCartStatus = (status) => {
    switch (status) {
      case 'Created':
      case 'New':
        return 'Preparing';
      case 'Payed':
      case 'InDelivery':
      case 'ReadyForIssue':
      case 'Processing':
      case 'Shipped':
        return 'Active';
      case 'Canceled':
      case 'Refunded':
        return 'Refused';
      case 'Completed':
        return 'Closed';
      case 'Deleted':
      default:
        return 'Blocked';
    }
  };

  if (order) {
    return (
      <AnimationCard style={style} shadow>
        <UiCardSection
          type="amount"
          title={(
            <>
              {`${i18n.t('Bill')} #${order.eInvoicingOrder.Id} `}
              {order.isNew ? (
                <div style={{ height: '11px' }}>
                  <div style={{
                    width: '7px', height: '7px', borderRadius: '10px', background: '#1560BD',
                  }}
                  />
                </div>
              ) : null}
            </>
          )}
          description={`${order.customer.personalData ? order.customer.personalData.map((el) => el.Value).join(' ') : ''} ${order.customer.phoneNumber}`}
          text={`${i18n.t('CreatedOn')}`}
          date={order.creationTime}
          status={getOrderCartStatus(order.status)}
          statusText={i18n.t(order.status)}
          amount={order.amount}
          currency={{
            sign: '₽',
            signLocation: 'right',
            decimalDelimiter: ',',
            thousandsDelimiter: ' ',
            precision: 2,
          }}
          active={active || isMenuOpen}
          onClick={onClick}
          rightElement={isDeleteSection ? <UiDots onClick={() => { setIsMenuOpen(!isMenuOpen); }} /> : rightElement}
        />
        <ActionSheet
          open={isMenuOpen}
          setOpen={() => { setIsMenuOpen(!isMenuOpen); }}
        >
          <DeleteSection
            title={i18n.t('Delete')}
            onClick={() => {
              onDelete();
              setIsMenuOpen(!isMenuOpen);
            }}
          />
        </ActionSheet>
      </AnimationCard>
    );
  }
  return null;
};

export default OrderCard;
