import React from 'react';

const MinusIcon = ({ style, color, onClick }) => {
  if (onClick) {
    if (style) {
      if (!style.cursor) {
        style.cursor = 'pointer';
      }
    } else {
      style = { cursor: 'pointer' };
    }
  }

  return (
    <svg
      onClick={onClick}
      style={style}
      width="10"
      height="3"
      viewBox="0 0 10 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.728516" y="0.400024" width="8.45714" height="2.11429" rx="1.05714" fill={color || "#D6D6D6"} />
    </svg>
  );
};

export default MinusIcon;