import PropTypes from 'prop-types';

import { useTheme } from '../../../context/ThemeProvider';

import Element from '../../Functional/Element';

/**
 * Plate big issued bill icon
 * @public
 * @version 1.3.0
 * @param {String} width
 * @param {String} height
 * @param {Function|Object} [innerRef]
 * @param props
 * @return {JSX.Element}
 * @constructor
 * @example
 * <UiBigIssuedBillIcon />
 */
const UiBigIssuedBillIcon = ({
  width,
  height,
  innerRef,
  ...props
}) => {

  const isTheme = useTheme();

  return (
    <Element
      { ...props }
      innerRef={ innerRef }
      width={ width }
      height={ height }
      viewBox="0 0 100 100"
    >
      <path
        d="M27.4899 40.36C27.0081 40.3573 26.5452 40.5468 26.2036 40.8866C25.862 41.2263 25.6699 41.6882 25.6699 42.17V66.58C25.6754 67.5757 26.4842 68.38 27.4799 68.38H32.2599V40.38L27.4899 40.36Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path
        d="M71.6699 40.36H66.8799V68.36H71.6699C72.6617 68.3545 73.4644 67.5518 73.4699 66.56V42.17C73.4699 41.1742 72.6656 40.3655 71.6699 40.36Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path d="M66.97 28C66.97 26.6359 65.8642 25.53 64.5 25.53C63.1359 25.53 62.03 26.6359 62.03 28V32.94H66.97V28Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path
        d="M73.47 58.29V66.6C73.47 67.5957 72.6657 68.4045 71.67 68.41H66.89V40.41H62.12V59.97H37.39V40.38H32.39V58.1H32.29V68.41H27.51C27.0248 68.418 26.5567 68.2309 26.2107 67.8906C25.8647 67.5503 25.6699 67.0853 25.67 66.6V58.36C25.26 58.54 25 58.79 25 59.07V70.66C25 71.2 26 71.66 27.1 71.66H72.23C73.39 71.66 74.33 71.22 74.33 70.66V59.07C74.33 58.73 73.99 58.47 73.47 58.29Z"
        fill={ isTheme.styles.colors.textDisable } />
      <path
        d="M55.55 30.08H43.77C43.052 30.08 42.47 30.662 42.47 31.38V36.66C42.47 37.378 43.052 37.96 43.77 37.96H55.55C56.2679 37.96 56.85 37.378 56.85 36.66V31.38C56.85 30.662 56.2679 30.08 55.55 30.08Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path
        d="M39.78 25.53C39.1232 25.5273 38.4924 25.7864 38.027 26.2499C37.5616 26.7134 37.3 27.3432 37.3 28V60.14H62.03V28C62.03 26.6359 63.1359 25.53 64.5 25.53H39.78ZM56.86 36.66C56.86 37.0048 56.7231 37.3354 56.4793 37.5792C56.2355 37.823 55.9048 37.96 55.56 37.96H43.78C43.0604 37.96 42.4755 37.3796 42.47 36.66V31.38C42.4755 30.6604 43.0604 30.08 43.78 30.08H55.56C56.2758 30.0855 56.8546 30.6643 56.86 31.38V36.66Z"
        fill={ isTheme.styles.colors.sectionBackground } />
      <path
        d="M71.76 40.11H62.53V33.44H67.47V28C67.47 26.3431 66.1269 25 64.47 25H39.78C38.1232 25 36.78 26.3431 36.78 28V40.11H27.57C26.4323 40.11 25.51 41.0323 25.51 42.17V66.58C25.5425 67.6942 26.4553 68.5805 27.57 68.58H71.76C72.8747 68.5805 73.7875 67.6942 73.82 66.58V42.17C73.82 41.6237 73.603 41.0997 73.2166 40.7134C72.8303 40.327 72.3064 40.11 71.76 40.11ZM62.53 28C62.3923 27.2142 62.7349 26.4217 63.4016 25.9835C64.0683 25.5454 64.9317 25.5454 65.5984 25.9835C66.2651 26.4217 66.6077 27.2142 66.47 28V32.44H62.53V28ZM32.11 68.13H27.57C26.7123 68.13 26.0155 67.4377 26.01 66.58V42.17C26.01 41.3084 26.7084 40.61 27.57 40.61H32.11V68.13ZM37.8 28C37.8 26.8954 38.6954 26 39.8 26H62.3C62.25 26 62.21 26.12 62.16 26.18L62.03 26.36C61.97 26.46 61.92 26.56 61.87 26.66C61.8308 26.72 61.7973 26.7837 61.77 26.85C61.7242 26.967 61.6875 27.0874 61.66 27.21C61.6387 27.2686 61.622 27.3288 61.61 27.39C61.5697 27.5807 61.5496 27.7751 61.55 27.97V59.64H37.8V28ZM66.72 68.13H32.61V40.61H36.8V59.61H34.67C34.3961 59.6153 34.1753 59.8361 34.17 60.11C34.17 60.3861 34.3939 60.61 34.67 60.61H64.67C64.9462 60.61 65.17 60.3861 65.17 60.11C65.17 59.8339 64.9462 59.61 64.67 59.61H62.57V40.61H66.76L66.72 68.13ZM73.32 66.58C73.3145 67.4377 72.6177 68.13 71.76 68.13H67.22V40.61H71.76C72.6216 40.61 73.32 41.3084 73.32 42.17V66.58Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M46.04 42.62H59.46C59.5981 42.62 59.71 42.5081 59.71 42.37C59.71 42.2319 59.5981 42.12 59.46 42.12H46.04C45.902 42.12 45.79 42.2319 45.79 42.37C45.79 42.5081 45.902 42.62 46.04 42.62Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M39.8701 46.15H59.4601C59.5982 46.15 59.7101 46.0381 59.7101 45.9C59.7101 45.762 59.5982 45.65 59.4601 45.65H39.8701C39.732 45.65 39.6201 45.762 39.6201 45.9C39.6201 46.0381 39.732 46.15 39.8701 46.15Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M39.8701 48.26H59.4601C59.5982 48.26 59.7101 48.1481 59.7101 48.01C59.7101 47.8719 59.5982 47.76 59.4601 47.76H39.8701C39.732 47.76 39.6201 47.8719 39.6201 48.01C39.6201 48.1481 39.732 48.26 39.8701 48.26Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M39.8701 50.34H55.0001C55.1382 50.34 55.2501 50.2281 55.2501 50.09C55.2501 49.952 55.1382 49.84 55.0001 49.84H39.8701C39.732 49.84 39.6201 49.952 39.6201 50.09C39.6201 50.2281 39.732 50.34 39.8701 50.34Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M39.8701 52.41H55.0001C55.1382 52.41 55.2501 52.298 55.2501 52.16C55.2501 52.0219 55.1382 51.91 55.0001 51.91H39.8701C39.732 51.91 39.6201 52.0219 39.6201 52.16C39.6201 52.298 39.732 52.41 39.8701 52.41Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M44.8101 55.51H39.8701C39.732 55.51 39.6201 55.6219 39.6201 55.76C39.6201 55.8981 39.732 56.01 39.8701 56.01H44.8101C44.9482 56.01 45.0601 55.8981 45.0601 55.76C45.0601 55.6219 44.9482 55.51 44.8101 55.51Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M59.5301 55.51H54.5901C54.452 55.51 54.3401 55.6219 54.3401 55.76C54.3401 55.8981 54.452 56.01 54.5901 56.01H59.5301C59.6682 56.01 59.7801 55.8981 59.7801 55.76C59.7801 55.6219 59.6682 55.51 59.5301 55.51Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M39.8701 42.62H43.9901C44.1282 42.62 44.2401 42.5081 44.2401 42.37C44.2401 42.2319 44.1282 42.12 43.9901 42.12H39.8701C39.732 42.12 39.6201 42.2319 39.6201 42.37C39.6201 42.5081 39.732 42.62 39.8701 42.62Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M28.6699 54.23C28.9461 54.23 29.1699 54.0062 29.1699 53.73V50.83C29.1699 50.5539 28.9461 50.33 28.6699 50.33C28.3938 50.33 28.1699 50.5539 28.1699 50.83V53.73C28.1699 54.0062 28.3938 54.23 28.6699 54.23Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M70.27 50.29C69.9939 50.29 69.77 50.5138 69.77 50.79V53.73C69.77 54.0061 69.9939 54.23 70.27 54.23C70.5462 54.23 70.77 54.0061 70.77 53.73V50.83C70.7812 50.6906 70.7335 50.5529 70.6385 50.4503C70.5435 50.3477 70.4099 50.2895 70.27 50.29Z"
        fill={ isTheme.styles.colors.textTitle } />
      <path
        d="M23.9507 87.26H17.5807C12.6748 87.2545 8.70068 83.2759 8.70068 78.37V21.97C8.70068 17.0641 12.6748 13.0855 17.5807 13.08H26.7307C27.283 13.08 27.7307 13.5277 27.7307 14.08C27.7307 14.6323 27.283 15.08 26.7307 15.08H17.5807C13.7793 15.0855 10.7007 18.1687 10.7007 21.97V78.37C10.7007 82.1714 13.7793 85.2545 17.5807 85.26H23.9507C24.503 85.26 24.9507 85.7077 24.9507 86.26C24.9507 86.8123 24.503 87.26 23.9507 87.26Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path
        d="M83.3607 87.26H34.0107C33.4585 87.26 33.0107 86.8123 33.0107 86.26C33.0107 85.7077 33.4585 85.26 34.0107 85.26H83.3607C87.1621 85.2545 90.2407 82.1713 90.2407 78.37V41.79C90.2407 41.2377 90.6885 40.79 91.2407 40.79C91.793 40.79 92.2407 41.2377 92.2407 41.79V78.37C92.2407 83.2759 88.2667 87.2545 83.3607 87.26Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path
        d="M91.2408 32.94C90.6885 32.94 90.2408 32.4923 90.2408 31.94V21.94C90.2408 18.1386 87.1621 15.0555 83.3608 15.05H37.2708C36.7185 15.05 36.2708 14.6023 36.2708 14.05C36.2708 13.4977 36.7185 13.05 37.2708 13.05H83.3608C88.2667 13.0555 92.2408 17.0341 92.2408 21.94V31.94C92.2408 32.4923 91.793 32.94 91.2408 32.94Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
      <path opacity="0.1"
        d="M88.0008 6H12.8008C6.77263 6.10945 1.95593 11.051 2.00039 17.08V83.21C1.95042 89.2429 6.7687 94.1905 12.8008 94.3H88.0008C94.0329 94.1905 98.8511 89.2429 98.8012 83.21V17.08C98.8456 11.051 94.0289 6.10945 88.0008 6ZM92.1709 31.9C92.1682 32.2399 91.9932 32.5552 91.7061 32.7371C91.4191 32.9191 91.0592 32.9428 90.7508 32.8V40.87C91.0625 40.7257 91.4264 40.7515 91.7146 40.9382C92.0028 41.125 92.1751 41.4466 92.1709 41.79V78.33C92.1653 83.232 88.1928 87.2045 83.2908 87.21H33.9408C33.3885 87.21 32.9408 86.7623 32.9408 86.21H24.8708C24.8708 86.7623 24.4231 87.21 23.8708 87.21H17.5108C12.6088 87.2045 8.63629 83.232 8.63078 78.33V21.93C8.63078 17.0241 12.6049 13.0455 17.5108 13.04H26.6608C27.2131 13.04 27.6608 13.4877 27.6608 14.04H36.1708C36.1708 13.4877 36.6185 13.04 37.1708 13.04H83.2608C85.622 13.0347 87.8884 13.969 89.5599 15.6367C91.2314 17.3045 92.1709 19.5688 92.1709 21.93V31.9Z"
        fill={ isTheme.styles.colors.applicationPrimaryColor } />
    </Element>
  );
};

UiBigIssuedBillIcon.propTypes = {
  /** Определяет ширину иконки */
  width: PropTypes.string,
  /** Определяет высоту иконки */
  height: PropTypes.string
};

UiBigIssuedBillIcon.defaultProps = {
  width: '6.25rem',
  height: '6.25rem'
};

export default UiBigIssuedBillIcon;