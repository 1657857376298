import React from 'react';
import { UiCardSection } from '@compassplus/ui-mobicash';
import { useHistory, useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import { action } from 'mobx';
import MarketStore from '../../../../stores/MarketStore';
import {CardsContainer, CategoryMarketCard} from '../style';
import LoadingMarketGroupCard from '../../../../components/loadingCards/LoadingMarketGroupCard';

const Groups = observer(({ setGroupId }) => {
  const { currentGroups, isLoading } = MarketStore;
  const history = useHistory();
  const params = useParams();
  const merchantURL = params.merchant;

  const getGroupDescription = (description) => {
    if (description) {
      if (description.length > 80) {
        return `${description.substr(0, 80)}...`;
      }
      return description;
    }
    return '';
  };

  if (isLoading) {
    return (
      <CardsContainer>
        <LoadingMarketGroupCard />
        <LoadingMarketGroupCard />
        <LoadingMarketGroupCard />
        <LoadingMarketGroupCard />
      </CardsContainer>
    );
  }

  if (currentGroups && currentGroups.length !== 0) {
    return (
      <CardsContainer>
        {
          currentGroups.map((group, index) => (
            <React.Fragment key={`${group.title}-${index}`}>
              <CategoryMarketCard
                key={`${group.title}-${index}`}
                shadow
              >
                <UiCardSection
                  title={group.title}
                  description={getGroupDescription(group.description)}
                  leftElement={(
                    <img
                      style={{ width: '4.75rem', height: '3rem', objectFit: 'cover' }}
                      src={group.image.url}
                    />
                  )}
                  onClick={action(() => {
                    MarketStore.isLoading = true;
                    history.push(`/${merchantURL}/${group.type}`, { goBack: true });
                    MarketStore.setCurrentGroup(group.type, true);
                    setGroupId(group.type);
                  })}
                  style={!group.activeByTime ? { opacity: '0.6' } : {}}
                />
              </CategoryMarketCard>
            </React.Fragment>
          ))
        }
      </CardsContainer>
    );
  }
  return null;
});

export default Groups;
