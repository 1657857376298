import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useTheme } from '../../../../../../context/ThemeProvider';

import styles from './Text.module.css';

const StyledAmountText = styled.span`
  color: ${ (props) => props.styles.colors.textReadOnly };
  ${ (props) => props.styles.typography.Keyboard }
`;

const Text = ({
  text
}) => {

  const isTheme = useTheme();

  return (
    <StyledAmountText className={ styles.amountText } styles={ isTheme.styles }>
      { text }
    </StyledAmountText>
  );

};

Text.propTypes = {
  text: PropTypes.string
};

export default Text;