import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import AdminMerchantCard from '../MainPage/components/MerchantCard';
import OrdersRecordsStore from '../../../stores/OrdersRecordsStore';
import ActionSheet from '../../../components/admin/ActionSheet';
import { UiCard, UiDropdown, UiInput, UiSection, UiSubsection, UiArrow } from '@compassplus/ui-mobicash';
import i18n from '../../../i18n';
import { action } from 'mobx';
import { useHistory } from 'react-router';
import OrderCard from './OrderCard';

const Orders = observer(({ activeOrderId }) => {
  const { statuses, allOrders, selectedStatus, displayedOrders } = OrdersRecordsStore;
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    OrdersRecordsStore.getOrders();
  }, []);

  const renderStatusDropdown = () => (
    <UiCard style={{ position: 'relative', margin: '0.25rem 0' }}>
      <UiInput
        description={i18n.t('DisplayedOrders')}
        value={i18n.t(selectedStatus)}
        rightElement={<UiDropdown />}
        readOnly
        active={isStatusDropdownOpen}
        onClick={() => {
          setIsStatusDropdownOpen(!isStatusDropdownOpen);
        }}
      />
      <ActionSheet
        open={isStatusDropdownOpen}
        setOpen={() => { setIsStatusDropdownOpen(!isStatusDropdownOpen); }}
      >
        <UiSection
          title={`${i18n.t('All')}(${allOrders.length})`}
          onClick={action(() => {
            OrdersRecordsStore.setStatus('All');
            setIsStatusDropdownOpen(false);
          })}
          bottomDivider
        />
        {statuses.map((status, index) => (
          <React.Fragment key={status}>
            <UiSection
              title={`${i18n.t(status)}(${allOrders.filter((el) => el.status === status).length})`}
              onClick={action(() => {
                OrdersRecordsStore.setStatus(status);
                setIsStatusDropdownOpen(false);
              })}
              bottomDivider={index !== statuses.length - 1}
            />
          </React.Fragment>
        ))}
      </ActionSheet>
    </UiCard>
  );

  const renderOrderCard = (order) => (
    <React.Fragment key={order._id}>
      <OrderCard
        order={order}
        onClick={() => {
          if (order.isNew) {
            OrdersRecordsStore.setViewed(order._id)
          }
          OrdersRecordsStore.setModifyingOrder(order);
          history.push(`/modifyOrder/${order._id}`);
        }}
        rightElement={<UiArrow />}
        style={{ margin: '0.25rem 0' }}
        active={order._id === activeOrderId}
      />
    </React.Fragment>
  );

  return (
    <>
      <AdminMerchantCard />
      {renderStatusDropdown()}
      <UiSubsection
        style={{ margin: '0.5rem 0' }}
        title={i18n.t('Orders')}
      >
        {displayedOrders.map(renderOrderCard)}
      </UiSubsection>

    </>
  );
});

export default Orders;
